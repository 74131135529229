import React from 'react';
import ajax from '../ajax';
import { MeModel } from '../interfaces/backend_model';
import localization from '../localization';
import settingsStore from '../stores/settings_store';
import IconLogout from './icon_logout';

interface Props {
  onLogoutClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

interface State {
  request: boolean;
  me: MeModel;
}

export default class Status extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      request: false,
      me: settingsStore.getMe()
    };
  }

  onRequests = () => {
    this.setState({
      request: true
    });
  };

  offRequests = () => {
    this.setState({
      request: false
    });
  };

  componentDidMount() {
    ajax.on('requests-on', this.onRequests);
    ajax.on('requests-off', this.offRequests);
  }

  componentWillUnmount() {
    ajax.off('requests-on', this.onRequests);
    ajax.off('requests-off', this.offRequests);
  }

  render() {
    return (
      <div
        className="flex-0-0-auto p-025em flex-row-around"
        onClick={this.props.onLogoutClick}
        style={{ cursor: 'pointer' }}
      >
        <div className="flex-col-around">{localization.t('LOGOUT')}</div>
        <div className="flex-col-around">
          <button type="button" className="form-btn-transparent">
            <IconLogout />
          </button>
        </div>
      </div>
    );
  }
}
