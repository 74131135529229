import React from 'react';

export default class IconExpand extends React.Component {
  render() {
    return (
      <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.82845 10.1716C5.63319 9.97633 5.3166 9.97633 5.12134 10.1716L1.02515 14.2678L1.02515 11.5C1.02515 11.2239 0.80129 11 0.525147 11C0.249005 11 0.0251465 11.2239 0.0251468 11.5L0.0251465 15.4749C0.0251465 15.751 0.249005 15.9749 0.525147 15.9749L4.49998 15.9749C4.77612 15.9749 4.99998 15.751 4.99998 15.4749C4.99998 15.1987 4.77612 14.9749 4.49998 14.9749L1.73225 14.9749L5.82845 10.8787C6.02371 10.6834 6.02371 10.3669 5.82845 10.1716Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.1716 10.1716C10.3668 9.97633 10.6834 9.97633 10.8787 10.1716L14.9749 14.2678L14.9749 11.5C14.9749 11.2239 15.1987 11 15.4749 11C15.751 11 15.9749 11.2239 15.9749 11.5L15.9749 15.4749C15.9749 15.751 15.751 15.9749 15.4749 15.9749L11.5 15.9749C11.2239 15.9749 11 15.751 11 15.4749C11 15.1987 11.2239 14.9749 11.5 14.9749L14.2677 14.9749L10.1716 10.8787C9.97629 10.6834 9.97629 10.3669 10.1716 10.1716Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.1716 5.82841C10.3668 6.02367 10.6834 6.02367 10.8787 5.82841L14.9749 1.73221L14.9749 4.49999C14.9749 4.77613 15.1987 4.99999 15.4749 4.99999C15.751 4.99999 15.9749 4.77613 15.9749 4.49999L15.9749 0.525106C15.9749 0.248964 15.751 0.0251055 15.4749 0.0251055L11.5 0.0251083C11.2239 0.0251083 11 0.248967 11 0.525109C11 0.801251 11.2239 1.02511 11.5 1.02511L14.2677 1.02511L10.1716 5.1213C9.97629 5.31656 9.97629 5.63314 10.1716 5.82841Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.82845 5.82841C5.63319 6.02367 5.3166 6.02367 5.12134 5.82841L1.02515 1.73221L1.02515 4.49999C1.02515 4.77613 0.80129 4.99999 0.525147 4.99999C0.249005 4.99999 0.0251465 4.77613 0.0251468 4.49999L0.0251465 0.525106C0.0251465 0.248964 0.249005 0.0251055 0.525147 0.0251055L4.49998 0.0251083C4.77612 0.0251083 4.99998 0.248967 4.99998 0.525109C4.99998 0.801251 4.77612 1.02511 4.49998 1.02511L1.73225 1.02511L5.82845 5.1213C6.02371 5.31656 6.02371 5.63314 5.82845 5.82841Z" fill="black"/>
      </svg>
    );
  }
}
