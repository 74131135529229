import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  AbsenceReasonsModel,
  UserAbsenceModel,
  UserAlarmHistoryModel,
  UserDetailsModel,
  UserModel
} from '../../interfaces/backend_model';
import AxiosHelper from '../../utilities/axiosApiHelper';

const endpoints = require('../../../json/endpoints.json');

const API = {
  /**
   */
  getUsers: (): Promise<UserModel[]> =>
    axios
      .get(endpoints.USERS['url'], {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as UserModel[])
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  getUser: (userId: string, alarmId: string): Promise<UserDetailsModel> =>
    axios
      .get(`${endpoints.USERS['url']}/${userId}?alarmid=${alarmId}`, {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as UserDetailsModel)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  getUserAbsence: (userId: string, alarmId: string): Promise<UserAbsenceModel> =>
    axios
      .get(`${endpoints.USERS['url']}/${userId}/absencecalendar?alarmid=${alarmId}`, {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as any)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  createAbsence: (
    userId: string,
    alarmId: string,
    absence: AbsenceReasonsModel,
    onClose: (type?: string) => void
  ) =>
    axios
      .post(
        `${endpoints.USERS['url']}/${userId}/absence?alarmid=${alarmId}`,
        JSON.stringify(absence),
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader(),
            'content-type': 'application/json'
          }
        },
      )
      .then(() => {
        onClose('CREATE');
      })
      .catch((err: AxiosError) => {
        return err.response?.data;
      }),

  endAbsence: (
    userId: string,
    alarmId: string,
    absenceId: number,
    onClose: (type?: string) => void
  ) =>
    axios
      .put(
        `${endpoints.USERS['url']}/${userId}/endabsences/${absenceId}?alarmid=${alarmId}`,
        {},
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        },
      )
      .then(() => {
        onClose('DELETE');
      })
      .catch((err: AxiosError) => {
        Promise.reject(AxiosHelper.errorHandler(err));
      }),

  getUserHistory: (userId: string, alarmId: string): Promise<UserAlarmHistoryModel> =>
    axios
      .get(`${endpoints.USERS['url']}/${userId}/history?alarmid=${alarmId}`, {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as UserAlarmHistoryModel)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err)))
};

export default API;
