import { ICellRendererParams } from "ag-grid-community";
import moment from "moment/moment";
import React from "react";
import constants from "../../../../json/constants.json";
import localization from "../../../localization";
import { Spacer } from "../../spacer";

const maxTextLength = 15;

const LogItem = (props: { log: string }) => {
  if (!props.log?.length) {
    return null;
  }

  const alarmT = localization.useNSt(constants.NS_ALARM);
  const isTextLarge = props.log.length > maxTextLength;

  const [showAll, setShowAll] = React.useState(false);

  if (!isTextLarge) {
    return <span>{props.log}</span>;
  }

  return (
    <>
      {showAll ? `${props.log} ` : `${props.log.slice(0, maxTextLength)}... `}
      <span
        className="alarm-log-text-more"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowAll(!showAll);
        }}
      >
        {showAll ? alarmT("LESS") : alarmT("MORE")}
      </span>
    </>
  );
};

export const LogCellRenderer = (props: ICellRendererParams) => {
  const renderLogs = () => {
    const lastLog = props.value.sort((a: string, b: string) => {
      const date1Number = moment(a);
      const date2Number = moment(b);

      if (date1Number.isBefore(date2Number)) {
        return -1;
      }
      if (date1Number.isAfter(date2Number)) {
        return 1;
      }

      return 0;
    })[props.value.length - 1];

    return (
      <span key={lastLog}>
        <LogItem log={lastLog} />
        <Spacer size={8} />
      </span>
    )
  }

  return (
    <div className="flex-col">
      {renderLogs()}
    </div>
  );
}