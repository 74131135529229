import React from "react";
import constants from "../../../../json/constants.json";
import localization from "../../../localization";

const alarmT = localization.useNSt(constants.NS_ALARM);

export const ExternalInformation = ({
  link,
  hasContent,
  widgetSettings,
  isFullscreen,
}: {
  link: string;
  hasContent: boolean;
  widgetSettings: any;
  isFullscreen: boolean;
}) => {
  const allow = widgetSettings.allow ?? 'fullscreen';
  const height = widgetSettings.height ?? undefined;
  const fullscreenHeight = widgetSettings.fullscreenHeight ?? "calc(100vh - 200px)";
  const sandbox = widgetSettings.sandbox ?? undefined;
  const seamless = widgetSettings.seamless ?? undefined;
  const srcDoc = widgetSettings.srcDoc ?? undefined;
  const width = widgetSettings.width ?? '100%';
  const frameBorder = widgetSettings.frameBorder ?? '0';
  const scrolling = widgetSettings.scrolling ?? 'auto';

  return (
    <div className="d-flex w-100p">
      {
        hasContent ? (
          <iframe
            title="External Information"
            allow={allow}
            sandbox={sandbox}
            seamless={seamless}
            src={link}
            srcDoc={srcDoc}
            width={width}
            frameBorder={frameBorder}
            scrolling={scrolling}
            style={{ height: isFullscreen ? fullscreenHeight : height }}
          />
        ) : (
          <div className="p-r-l-1em item-ver flex-row-between">
            <div className="note">
              {alarmT('NO_CONTENT_TO_SHOW')}
            </div>
          </div>
        )
      }
    </div>
  );
}