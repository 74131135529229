import axios, {AxiosError, AxiosResponse} from "axios";
import AxiosHelper from '../../utilities/axiosApiHelper';

const API = {
  getIntegrationData: (url: string): Promise<any> =>
    axios
      .get(url, {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as any)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),
}

export default API;