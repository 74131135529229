import ajax from './ajax';
import event_bus from './event_bus';
import Storage from './storage';

const endpoints = require('../json/endpoints.json');
const constants = require('../json/constants.json');

class Authentication extends Storage {
  currentUserId: string | null;
  error: string[] | string | null;
  failedTries: number;
  failedThreshold: number;

  constructor() {
    super();
    this.currentUserId = null;
    this.failedTries = 0;
    this.failedThreshold = 3; /* How many times refresh of access token can fail
                                 before logout of user and refresh of page */
    this.error = null;
  }

  getAudioSettings(level: string | number) {
    return {
      [constants.AUDIO_SETTING_PLAY]: this.getUserLSData([constants.AUDIO_SETTING_PLAY, level]),
      [constants.AUDIO_SETTING_VOLUME]: this.getUserLSData([constants.AUDIO_SETTING_VOLUME, level])
    };
  }

  /**
   * get local storage data by logged in user
   */
  getUserLSData(key: string | [string, number | string]) {
    const userId = this.getUserId();

    if (userId) {
      if (Array.isArray(key)) {
        return this.ls_get([userId, ...key]);
      }
      return this.ls_get([userId, key]);
    }
    return null;
  }

  /**
   * set local storage data by logged in user
   */
  setUserLSData(key: string | string[], value: string) {
    let userId = this.getUserId();
    if (userId) {
      if (Array.isArray(key)) {
        this.ls_set([userId, ...key], value);
      } else {
        this.ls_set([userId, key], value);
      }
    }
  }

  /**
   * remove local storage data by logged in user
   */
  removeUserLSData(key: string) {
    let userId = this.getUserId();
    if (userId) {
      this.ls_remove([userId, key]);
    }
  }

  /**
   * Remove all necessary local storage data by logged in user
   */
  clearLocalStorage(username?: string) {
    if (!username) {
      username = this.getUserId();
    }
    if (username) {
      this.ls_remove([username, constants.EXPIRES_IN_KEY]);
      this.ls_remove([username, constants.TOKEN_TYPE_KEY]);
      this.ls_remove([username, constants.RENEWAL_KEY]);
      this.ls_remove([username, constants.ACCESS_TOKEN_KEY]);

      this.ls_remove([username, constants.TELEPHONY_KEY]);
      this.ls_remove([username, constants.LS_DEVICE_TOKEN]);
      this.ls_remove([username, constants.PHONE_SELECTED_KEY]);
      this.ls_remove([username, constants.PHONE_NUMBER_KEY]);

      this.ls_remove([username, constants.ME_READ_ONLY]);
      this.ls_remove([username, constants.ME_SIMPLE_LOGIN]);
      this.ls_remove([username, constants.ME_SIMPLE_LOGIN_AUTH]);
      this.ls_remove([username, constants.ME_ADMIN_ID]);

      // this one needs to be last as it affects the getUserId method in Authentication
      // if it isn't last then the upcomming removeUserLSData will try to remove info about user 'null'
      this.ls_remove([username, constants.EXPIRES_INITIAL_KEY]);
      this.removeUserId();
    }
  }

  /**
   * get and remove storage data by logged in user
   */
  getOnceLSData(key: string) {
    const userId = this.getUserId();

    if (userId) {
      return this.ls_get_once([userId, key]);
    }

    return null;
  }

  /**
   * calculate if local storage data is valid according to the retrieved expires time
   */
  isOutOfTime(userId: string) {
    let outOfTime = true;
    if (userId) {
      let expires_initial_datetime = this.ls_get([userId, 'expires_initial_datetime']);
      if (expires_initial_datetime) {
        let expiresInitialDatetime = parseInt(expires_initial_datetime, 10);
        if (expiresInitialDatetime && expiresInitialDatetime >= Date.now()) {
          outOfTime = false;
        }
      }
    }

    return outOfTime;
  }

  /**
   * Fetch a new renewal token
   */
  renewToken() {
    let userName = this.getUserId();

    // check if the user is logged in to prevent tries to fetch the new token
    if (this.getUserId() && this.isValidAdmin()) {
      return (
        ajax
          .getByDescPromise(endpoints.RENEWAL_TOKEN)
          .then((response: any) => {
            this.failedTries = 0;
            this.error = null;
            this.ls_set([userName, constants.RENEWAL_KEY], response.data.description);
            return Promise.resolve();
          })
          // check if the same error has occured a few times in a row,
          // if it has trigger a UNAUTHORIZED event to force logout of the user
          // and reload the page
          .catch(error => {
            if (error == this.error) {
              this.failedTries += 1;
            } else {
              this.error = error;
              this.failedTries = 1;
            }

            if (this.failedTries < this.failedThreshold) {
              // this is how you have to do it to have a timeout return a promise
              // we need a promise so that we can do a fetch of access token after
              // a sucessful update of renewal token
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.renewToken()
                    .then(() => resolve(''))
                    .catch(() => reject(''));
                }, 1000);
              });
            } else {
              this.failedTries = 0; // triggering an UNAUTHORIZED event will force a relogin, so when that happens we need to reset the counter so its ready for the next login attempt
              event_bus.trigger(constants.UNAUTHORIZED);
              return Promise.reject('unauthorized');
            }
          })
      );
    }
    return Promise.reject('Not logged in');
  }

  /**
   * Use the renewal token we have stored to fetch a new access token
   * Endpoint returns a new renewal token as well so this is stored to be used later
   */
  refreshToken() {
    let userName = this.getUserId();
    // check if the user is logged in to prevent tries to fetch the new token
    if (userName && this.isValidAdmin()) {
      ajax
        .postByDescPromise(endpoints.REFRESH_TOKEN, {
          token: this.ls_get([this.getUserId(), constants.RENEWAL_KEY])
        })
        .then((response: any) => {
          this.failedTries = 0;
          this.ls_set([userName, constants.ACCESS_TOKEN_KEY], response.data.access_token);
          this.ls_set([userName, constants.EXPIRES_IN_KEY], response.data.expires_in);
          this.ls_set([userName, constants.RENEWAL_KEY], response.data.renewal_token);

          this.ls_set(
            [userName, constants.EXPIRES_INITIAL_KEY],
            Date.now() + response.data.expires_in * 1000
          );
          const timeToRefreshToken = (response.data.expires_in / 2) * 1000;
          setTimeout(() => this.refreshToken(), timeToRefreshToken);
        })
        .catch(({ err }) => {
          if (err === this.error) {
            this.failedTries += 1;
          } else {
            this.error = err;
            this.failedTries = 1;
          }
          if (this.failedTries < this.failedThreshold) {
            setTimeout(() => this.refreshToken(), 1000);
          } else {
            this.failedTries = 0; // triggering an UNAUTHORIZED event will force a relogin, so when that happens we need to reset the counter so its ready for the next login attempt
            event_bus.trigger(constants.UNAUTHORIZED);
          }
        });
    }
  }

  /**
   * is user is fully logged in (credentials, phone and groups are specified)
   */
  isLoggedIn() {
    return this.isPhoneSelected();
  }

  /**
   * is user is logged in by credentials and phone
   */
  isPhoneSelected() {
    return this.getUserId() && this.ls_get([this.getUserId(), constants.PHONE_SELECTED_KEY]);
  }

  /**
   * return current user ID
   */
  getUserId() {
    const userId = this.currentUserId || this.ls_get('user_id');
    if (this.isOutOfTime(userId)) {
      return null;
    }

    return userId;
  }

  /**
   * set current user ID
   */
  setUserId(userId: string) {
    this.ls_set('user_id', userId);
  }

  /**
   * remove current user ID
   */
  removeUserId() {
    this.currentUserId = null;
    this.ls_remove('user_id');
  }

  /**
   * Determines if user has logged in through api/urllogin and only has access to view one alarm
   */
  isSimpleLogin() {
    const simpleLogin = this.ls_get([this.getUserId(), constants.ME_SIMPLE_LOGIN]);
    if (simpleLogin) {
      return simpleLogin.toLowerCase() === 'true';
    }
    // Logged in like an ordinary respondent
    return false;
  }

  /**
   * Determines if user only has read access
   * This is typically an admin with a SimplifiedOperator role
   * This admin does not belong to a respondent group or ARC
   * In other words no point to place requests that require these
   */
  isReadOnly() {
    const readOnly = this.ls_get([this.getUserId(), constants.ME_READ_ONLY]);
    if (readOnly) {
      return readOnly.toLowerCase() === 'true';
    }
    // Perhaps a little contradictory to not default to read only access but this is a new feature.
    // Do not want to break existing features in production
    return false;
  }

  /**
   * Determines if user is authenticated through the url login
   * This is typically an admin with a SimplifiedOperator role
   * Gives the possibility to clear an alarm
   */
  isAuthenticated() {
    const simpleAuthLogin = authStore.ls_get([authStore.getUserId(), constants.ME_SIMPLE_LOGIN_AUTH]);
    return simpleAuthLogin?.toLowerCase() === "true";
  }

  /**
   * Determines if user is logged in with an existing Admin
   */
  isValidAdmin() {
    const adminId = this.ls_get([this.getUserId(), constants.ME_ADMIN_ID]);
    // We only set this value if logged in through AutoRegisterSimple, 
    // that is why an empty adminId is valid
    return !adminId || (adminId > 0);
  }
}

export const username = 'temporary-user-from-autoregister';
const authStore = new Authentication();
export default authStore;
