import AppDispatcher from '../app_dispatcher';
import { SendExternalLog } from '../stores/externalLogging/externalLogActions';

const constants = require('../../json/constants.json');

export function logToPapertrail(
    logMessage?: string,
) {
    AppDispatcher.dispatch({
        action: {
            type: constants.SEND_EXTERNAL_LOG,
            logMessage,
        } as SendExternalLog
    });
}

export default {
    logToPapertrail
};
