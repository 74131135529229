import React from 'react';

interface Props {
  className?: string;
  strokeColor?: string;
}

export class PlayIcon extends React.Component<Props> {
  render() {
    return (
      <svg width="32" height="32" viewBox="0 0 24 24" className={this.props.className}>
        <path d="M8 5v14l11-7z" fill="#ffffff" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }
}

export class PlayBannedIcon extends React.Component<Props> {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={this.props.className}
      >
        <g fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <polygon fill="#FFF" fillRule="nonzero" points="8 5 8 19 19 12" />
          <path
            fill="#FFF"
            fillRule="nonzero"
            stroke={this.props.strokeColor}
            d="M16.9342672,3.29289322 L18.7071068,5.06573284 L5.06573284,18.7071068 L3.29289322,16.9342672 L16.9342672,3.29289322 Z"
          />
        </g>
      </svg>
    );
  }
}
