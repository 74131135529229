import { Snackbar } from 'material-ui';
import { inject } from 'mobx-react';
import moment from "moment";
import React from 'react';
import NotificationSystem from 'react-notification-system';
import { Link } from 'react-router';
import { loadAlarmInfo } from '../actions/alarm';
import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import authentication from '../authentication';
import dateTime from '../date_time';
import { AlarmState } from '../enums/alarm';
import event_bus from '../event_bus';
import { AlarmModel, PushDataModel, SortingByTabModel } from '../interfaces/backend_model';
import localization from '../localization';
import AlarmHelpers from '../stores/alarms/alarm_helpers';
import alarmListStore from '../stores/alarms/alarm_list_store_actions';
import { StorageStore } from '../stores/mobx/StorageStore';
import settingsStore from '../stores/settings_store';
import Utils from '../utils';
import Grid from './grid';
import IconClock from './icon_clock';
import IconUser from './icon_user';
import IconBuilding from './icons/icon_building';
import { MobxUISnackbar } from './mobx_ui_snackbar';
import SpinnerOverlay from './spinner_overlay';
import { TruncatedText } from "./truncatedText";

const endpoints = require('../../json/endpoints.json');
const constants = require('../../json/constants.json');
const audioAlarmURL = require('../../audio/alarm.mp3');
const KEYBOARD_CODES = require('../../json/key_codes.json');

const tabActive = {
  currentTab: constants.TAB_ACTIVE,
  currentLocalization: 'ACTIVE',
  className: 'active-tab'
};

const tabDeactivated = {
  currentTab: constants.TAB_DEACTIVATED,
  currentLocalization: 'DEACTIVATED',
  className: 'deactivated-tab'
};

const tabMy = {
  currentTab: constants.TAB_MY,
  currentLocalization: 'MY',
  className: 'my-tab'
};

const tabResponded = {
  currentTab: constants.TAB_RESPONDED,
  currentLocalization: 'RESPONDED',
  className: 'responded-tab'
};

const singleListTabs = [tabActive, tabDeactivated];
const multiListTabs = [tabActive, tabMy, tabResponded, tabDeactivated];

const SignificantUpdateAtDefaultValue = '0001-01-01T00:00:00Z';

interface TabModel {
  currentTab: string;
  currentLocalization: string;
  className: string;
}

interface Props {
  params: { alarm_id: string; template_type: string };
  onAlarmSelect?: Function;
  storage?: StorageStore;
  mobile?: boolean;
  readonly?: boolean;
}

interface State {
  notification: string | null;
  errorMessage: boolean | string | null;
  overlay: boolean;
  alarmsByTabs: {
    [x: string]: AlarmModel[];
  };
  activeTab: string;
  newMergedAlarmInMyTab: boolean;
  mergedAlarms: { [key: string]: { SignificantUpdateAt: string; showDot: boolean } };
  isClicked: boolean;
  audioLoaded: boolean;
}

@inject('storage')
export default class AlarmsList extends React.Component<Props, State> {
  _grid: Grid | null = null;
  userId: string;
  viewedAlarmsData: {
    date: string;
    alarms: string[];
  };
  audioAlarm_1: HTMLAudioElement;
  audioAlarm_2: HTMLAudioElement;
  audioAlarm_3: HTMLAudioElement;
  audioAlarm_4: HTMLAudioElement;
  used_alarm_ids: { [key: string]: boolean };
  notificationSystem: NotificationSystem.System;

  constructor(props: Props) {
    super(props);

    this.userId = authentication.getUserId();
    this.viewedAlarmsData = JSON.parse(localStorage.getItem(`${this.userId}-viewed-alarms`) || '{}');
    // check if more than week has passed since first viewed alarm to clear viewed alarms
    if (this.viewedAlarmsData.date && moment().diff(moment(new Date(this.viewedAlarmsData.date)), 'days') > 7) {
      this.viewedAlarmsData = { date: '', alarms: [] };
      localStorage.removeItem(`${this.userId}-viewed-alarms`);
    }

    this.state = {
      notification: null,
      errorMessage: false,
      overlay: true,
      alarmsByTabs: alarmListStore.getAlarmsByTabs(),
      activeTab: alarmListStore.activeTab,
      newMergedAlarmInMyTab: false,
      mergedAlarms: {},
      isClicked: false,
      audioLoaded: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleDocumentKeyDown, false);

    alarmListStore.on(constants.ALARMS_CHANGE, this.onChange);
    alarmListStore.on(constants.CHANGE_ALARMS_TAB, this.onChangeTab);
    alarmListStore.on(constants.ALARMS_SELECT_NEXT, this.onSelectNextAlarm);
    alarmListStore.on(constants.ACTION_ACCEPT_ALARM, () => {
      this.notificationSystem.addNotification({
        title: localization.t('NEW_ALARM_ACCEPTED'),
        level: 'info',
        autoDismiss: 3, // seconds
        position: 'br'
      });
    });
    appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_ALARMS });
    appDispatcher.handleServerAction({ type: constants.START_UPDATING_SERVER_ALARMS });
    event_bus.on(constants.WS_PUSH_ALARM_UPDATE, this.handlePushUpdate, this);
    event_bus.on(constants.WS_PUSH_ALARM_FULL_REFRESH, this.handlePushFullRefresh, this);
    event_bus.on(constants.WS_PUSH_ALARM_REMOVE, this.pushRemoveAlarm, this);

    this.audioAlarm_1 = this.initializeAudio(constants.ID_AUDIO_ALARM_1, '1');
    this.audioAlarm_2 = this.initializeAudio(constants.ID_AUDIO_ALARM_2, '2');
    this.audioAlarm_3 = this.initializeAudio(constants.ID_AUDIO_ALARM_3, '1');
    this.audioAlarm_4 = this.initializeAudio(constants.ID_AUDIO_ALARM_4, '2');

    this.setState({ audioLoaded: false });

    this.used_alarm_ids = { __empty: true }; // __empty defines state, so there is no need to check new alarms
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleDocumentKeyDown, false);

    alarmListStore.off(constants.ALARMS_CHANGE, this.onChange);
    alarmListStore.off(constants.CHANGE_ALARMS_TAB, this.onChangeTab);
    alarmListStore.off(constants.ALARMS_SELECT_NEXT, this.onSelectNextAlarm);
    event_bus.off(constants.WS_PUSH_ALARM_FULL_REFRESH, this.handlePushFullRefresh);
    event_bus.off(constants.WS_PUSH_ALARM_UPDATE, this.handlePushUpdate);
    event_bus.off(constants.WS_PUSH_ALARM_REMOVE, this.pushRemoveAlarm);
    appDispatcher.handleServerAction({ type: constants.STOP_UPDATING_SERVER_ALARMS });

    this.audioAlarm_1?.pause();
    this.audioAlarm_2?.pause();
    this.audioAlarm_3?.pause();
    this.audioAlarm_4?.pause();
    this.setState({ audioLoaded: false });

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {

    // as sounds that are not already loaded will not be loaded in the case of the tab in the 
    // browser being inactive thus we need to preload the sounds so that they can be played
    if (settingsStore.userApproved && !this.state.audioLoaded) {

      this.audioAlarm_1?.load();
      this.audioAlarm_1.muted = true;
      this.audioAlarm_2?.load();
      this.audioAlarm_2.muted = true;

      Promise.all([
        this.audioAlarm_1.play()
          .then(() => {
            this.audioAlarm_1.load();
            this.audioAlarm_1.muted = false;
          }),
        this.audioAlarm_2.play()
          .then(() => {
            this.audioAlarm_2.load();
            this.audioAlarm_2.muted = false;
          })
      ])
        .then(() => {
          console.log("Audio loaded");
        }).catch(e =>
          console.log(e)
        );
      this.setState({ audioLoaded: true });
    }

    const { alarmsByTabs } = this.state;
    let newMergedAlarms = false;
    const updatedMergedAlarms = { ...prevState.mergedAlarms };

    if (alarmsByTabs[constants.TAB_MY]) {
      alarmsByTabs[constants.TAB_MY]
        .filter((el: AlarmModel) => !el.SignificantUpdateAt.match(SignificantUpdateAtDefaultValue))
        .forEach((el: AlarmModel) => {
          if (
            !prevState.mergedAlarms[el.AlarmId] ||
            !prevState.mergedAlarms[el.AlarmId].SignificantUpdateAt.match(el.SignificantUpdateAt)
          ) {
            newMergedAlarms = true;
            updatedMergedAlarms[el.AlarmId] = {
              SignificantUpdateAt: el.SignificantUpdateAt,
              showDot: true
            };
          }
        });

      if (
        !!prevState.alarmsByTabs[constants.TAB_MY].length &&
        !this.state.alarmsByTabs[constants.TAB_MY].length
      ) {
        this.setState({ newMergedAlarmInMyTab: false });
        if (
          !!prevState.alarmsByTabs[constants.TAB_MY].length &&
          !this.state.alarmsByTabs[constants.TAB_MY].length
        ) {
          this.setState({ newMergedAlarmInMyTab: false });
        }
      }

      if (newMergedAlarms) {
        this.setState({
          mergedAlarms: updatedMergedAlarms,
          newMergedAlarmInMyTab: newMergedAlarms
        });
      }
    }
  }

  clearMergedAlarmInMyTab() {
    this.setState({ newMergedAlarmInMyTab: false });
  }

  clearMergedAlarmForSpecificAlarm(alertId: string) {
    if (this.state.mergedAlarms[alertId]) {
      this.setState(prevState => {
        const alarms = { ...prevState.mergedAlarms };
        alarms[alertId].showDot = false;
        return { mergedAlarms: alarms, newMergedAlarmInMyTab: false };
      });
    }
  }

  // Shortcuts
  handleDocumentKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey) {
      switch (event.keyCode) {
        case KEYBOARD_CODES.A:
          event.preventDefault();
          this.takeActiveAlarmAtTop();
          break;
        case KEYBOARD_CODES.down: {
          const nextAlarm = alarmListStore.getNextTabAlarm(this.props.params.alarm_id);
          event.preventDefault();

          if (nextAlarm) {
            appHistory.replace(this.calcAlarmLink(nextAlarm));
          }
          break;
        }
        case KEYBOARD_CODES.up: {
          const prevAlarm = alarmListStore.getPrevTabAlarm(this.props.params.alarm_id);
          event.preventDefault();

          if (prevAlarm) {
            appHistory.replace(this.calcAlarmLink(prevAlarm));
          }
          break;
        }
      }
    }
  };

  takeActiveAlarmAtTop = () => {
    const { alarmsByTabs } = this.state;
    this.setState({ notification: localization.t('TAKE_ACTIVE_ALARM_AT_TOP') });
    const alarm = alarmListStore.getAlarmAtTop(constants.TAB_ACTIVE);

    if (alarm) {
      if (alarmsByTabs[constants.TAB_ACTIVE].length === 1) {
        this.removePulsationFromActiveTab(0);
      }
      this.makeLinkClick(alarm)();
    }
  };

  onSelectNextAlarm = (_: any, alarm?: AlarmModel) => {
    if (alarm) {
      appHistory.replace(this.calcAlarmLink(alarm));
    }
  };

  handleRequestClose = () => {
    this.setState({
      notification: null
    });
  };

  initializeAudio = (id: string, level: string) => {
    const audio = document.getElementById(id) as HTMLAudioElement;
    const audioSettings = authentication.getAudioSettings(level);
    const audioSettingVolume = audioSettings[constants.AUDIO_SETTING_VOLUME];

    if (Number.isFinite(audioSettingVolume) && audioSettingVolume <= 1) {
      audio.volume = parseFloat(audioSettingVolume);
    } else {
      audio.volume = 1;
    }

    return audio;
  };

  handlePushFullRefresh() {
    appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_ALARMS });
  }

  handlePushUpdate(e: Event, data?: PushDataModel) {
    if (data && !data.planningview) {
      const alarm_id = data.globalAlarmId;

      if (alarm_id) {
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
          alarm_id
        });
      }
    }
  }

  calcAlarmLink(alarm: AlarmModel) {
    return '/dashboard/template/' + alarm.Template + '/' + alarm.AlarmId;
  }

  isAlarmClicked() {
    this.setState({ isClicked: true });
    const acceptLimit = settingsStore.getValueByKey('LIMIT_ACCEPT', 'number');
    //check if LIMIT_ACCEPT rule is in use
    if (acceptLimit) {
      console.log('Limit accept active for ' + acceptLimit + ' milliseconds');
      setTimeout(() => {
        this.setState({ isClicked: false });
      }, acceptLimit);
    } else {
      //if not change by default
      this.setState({ isClicked: false });
    }
  }

  makeLinkClick = (alarm: AlarmModel) => {
    return (e?: React.FormEvent) => {
      e?.preventDefault();
      // Bussiness logic: If alarm doesnt have ResponentId -> send ajax /accept call, except when ui is on readonly state
      // If error -> someone already take an alarm, so lets show info about that
      if (!alarm.RespondentId && !this.context.readonly && !this.state.isClicked) {
        this.isAlarmClicked();
        ajax.postByDesc(
          false,
          endpoints.ACCEPT,
          { alarm_id: alarm.AlarmId, force: null },
          (err: string | null, xhr: XMLHttpRequest) => {
            if ((xhr.status === 406) || (xhr.status === 404)) {
              this.setState({
                notification: localization.t('UNABLE_TAKE_ALARM', {
                  ns: constants.NS_ALARM
                }),
                errorMessage: err
              });
            }
            loadAlarmInfo(alarm);
            // TODO: left until the new setting is presented
            // if new alarm accepted from active tab, change tab to my
            // if (this.state.activeTab === constants.TAB_ACTIVE && !this.isSingleListMode()) {
            //   appDispatcher.dispatch({
            //     action: {
            //       type: constants.ACTION_CHANGE_ALARMS_TAB,
            //       tab: "TAB_MY"
            //     }
            //   });
            // }
            appHistory.push(this.calcAlarmLink(alarm));
          }
        );
        //If new alarm is accepted before acceptLimit runs out
      } else if (settingsStore.getValueByKey('LIMIT_ACCEPT', 'number') && this.state.isClicked) {
        console.log(
          `New alarm acceptance blocked for ${settingsStore.getValueByKey(
            'LIMIT_ACCEPT',
            'number'
          )} ms for alarm: ${alarm.AlarmId}`
        );
        this.notificationSystem.addNotification({
          title: localization.t('LIMIT_ACCEPT_ACTIVE'),
          level: 'error',
          autoDismiss: 5, // seconds
          position: 'br'
        });
      } else {
        loadAlarmInfo(alarm);

        appHistory.push(this.calcAlarmLink(alarm));
      }

      this.props.onAlarmSelect && this.props.onAlarmSelect();
    };
  };

  onLinkHover = (e: any, alarm: AlarmModel) => {
    const element = e.currentTarget;
    // saving alarm id to local storage to mark it as viewed
    if (element.classList.contains('not-viewed')) {
      if (!this.viewedAlarmsData.date) {
        this.viewedAlarmsData.date = new Date().toString();
        this.viewedAlarmsData.alarms = [];
      }
      this.viewedAlarmsData.alarms.push(alarm.AlarmId);
      localStorage.setItem(`${this.userId}-viewed-alarms`, JSON.stringify(this.viewedAlarmsData));
      element.classList.remove('not-viewed');
    }
  }

  renderAlarm = (alarm: AlarmModel) => {
    const { mergedAlarms } = this.state;
    const dateAndTime = dateTime.toLocaleDateAndTime(alarm.ServerAlarmTime);
    const isSingleListMode = this.isSingleListMode();
    const settingTimestamp = settingsStore.getValueByKey('TIMESTAMPS', 'string');
    let alarmTitle: string | JSX.Element =
      alarm.AlarmText + (alarm.RespondentInitials ? ' (' + alarm.RespondentInitials + ')' : '');
    let alarmDescription = alarm.TextMessage;
    const alarmLook = settingsStore.getValueByKey('ALARMLISTLOOK');
    const alarmType = AlarmHelpers.getAlarmClass({
      active: alarm.Active,
      respondentId: alarm.RespondentId,
      respondentOwn: alarm.RespondentOwn
    });
    let className = `flex-0-0-auto flex-row form-list-item m-tb-3px-rl-8px ${alarmType}`;

    // add class for not viewed alarms
    if (alarmType === "alarm-not-responded" && !this.viewedAlarmsData.alarms?.includes(alarm.AlarmId)) {
      className += ' not-viewed';
    }

    let alarmDate = dateAndTime.date;
    let iconClock: JSX.Element | null = (
      <div className="flex-col-around">
        <IconClock />
      </div>
    );

    switch (settingTimestamp) {
      case 'TODAY':
        alarmDate = Utils.dateToShow(alarm.ServerAlarmTime, dateAndTime.date);
        iconClock = null;
        break;
    }
    switch (alarmLook) {
      case 'alarmcentric': // id: 0
        alarmTitle =
          alarm.AlarmText + (alarm.RespondentInitials ? ' (' + alarm.RespondentInitials + ')' : '');

        alarmDescription = alarm.Name;
        break;
      case 'objectcentric': // id: 1
        alarmTitle = <strong>{alarm.Name}</strong>;
        alarmDescription = alarm.AlarmText;
        break;
      case 'objectcentrictext': // id: 2
        alarmTitle = alarm.Name;
        alarmDescription = alarm.TextMessage;
        break;
      case 'anonymous': // id: 3
        alarmTitle = <strong>{alarm.AlarmText}</strong>;
        alarmDescription = ' ';
        break;
    }

    const alarmTab = AlarmHelpers.defineAlarmTab({
      active: alarm.Active,
      respondentId: alarm.RespondentId,
      respondentOwn: alarm.RespondentOwn
    });

    if ((alarmTab === constants.TAB_MY || (isSingleListMode && alarmTab === constants.TAB_ACTIVE)) &&
      dateTime.isTime(alarm.NotifyAfter)) {
      // The notify reminder perceives unstable:
      // Resolution is to enforce that sound and visual are either enabled or disabled together
      if (this.props.storage!.getItem('isNotifyReminderEnabled')) {
        className += ' box-pulse';
      }
    }

    const mergedAlarm =
      alarmTab === constants.TAB_MY &&
      mergedAlarms[alarm.AlarmId] &&
      mergedAlarms[alarm.AlarmId].showDot;

    return (
      <Link
        onClick={this.makeLinkClick(alarm)}
        to={this.calcAlarmLink(alarm)}
        activeClassName='active'
        className={className}
        key={alarm.AlarmId}
        onMouseEnter={(e) => this.onLinkHover(e, alarm)}
      >
        <div role='button' onClick={() => this.clearMergedAlarmForSpecificAlarm(alarm.AlarmId)} style={{ width: '100%' }}>
          <div className='flex-row'>
            <div className='form-list-icon p-05em relative'>
              {mergedAlarm && <span className="dot" />}
              <div
                className={`${false && alarm.State === AlarmState.My.toString() ? 'active-circle' : ''
                  }`}
              />
              {alarmListStore.getAlarmIcon(alarm)}
            </div>
            <div className='w-100p p-r-05em'>
              <div className='flex-row-between align-center'>
                <div className='alarm-text'>
                  {alarmTitle}
                </div>
                <div className='alarm-datetime'>
                  <div className='flex-row f-15'>
                    {iconClock}
                    <div className='flex-col-around'>
                      <div className='alarm-time'>{dateAndTime.time}</div>
                    </div>
                  </div>
                </div>
              </div>
              {alarmLook !== 'alarmcentric' && (<div className='alarm-date text-a-end'>{alarmDate}</div>)}
              <div className={
                `flex-row-between align-center ${alarmLook === 'objectcentrictext' ? 'alarm-details' : ''}`
              }>
                <div className={`alarm-name ${alarmLook !== 'alarmcentric' ? 'w-max-100p' : ''}`}>
                  {alarmLook === 'objectcentrictext' ?
                    <TruncatedText position='right center' text={alarmDescription} maxlength={70} /> : alarmDescription}
                </div>
                {alarmLook === 'alarmcentric' && (<div className='alarm-date'>{alarmDate}</div>)}
              </div>

              <div>
                {(alarm.Organization && alarmLook === 'alarmcentric') && (
                  <div className='additional-information-container'>
                    <div className='icon-user'>
                      <IconBuilding />
                    </div>

                    <div className='alarm-additional-information'>
                      {alarm.Organization}
                    </div>
                  </div>
                )}
                {alarm.AdditionalInformation ? (
                  <div className='additional-information-container'>
                    <div className='icon-user'>
                      <IconUser />
                    </div>

                    <div className='alarm-additional-information'>
                      {alarm.AdditionalInformation}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  changeActiveTab = (e: React.FormEvent<HTMLButtonElement>, hasMergedAlarms: boolean) => {
    const element = e.currentTarget;
    if (element.dataset && element.dataset.click) {
      appDispatcher.dispatch({
        action: {
          type: constants.ACTION_CHANGE_ALARMS_TAB,
          tab: element.dataset.click
        }
      });
    }

    if (hasMergedAlarms) {
      this.clearMergedAlarmInMyTab();
    }
  };

  calcActiveAlarmTab = (props: Props) => {
    let { alarm_id } = props.params;
    if (alarm_id) {
      let alarm = alarmListStore.getAlarm(alarm_id);
      if (alarm) {
        return AlarmHelpers.defineAlarmTab({
          active: alarm.Active,
          respondentId: alarm.RespondentId,
          respondentOwn: alarm.RespondentOwn
        });
      }
    }
    return null;
  };

  detectAlarmAudio = (alarmsByTabs: SortingByTabModel | any) => {
    const activeTabAlarms = alarmsByTabs[constants.TAB_ACTIVE];

    if (this.used_alarm_ids.__empty) {
      activeTabAlarms.forEach((alarm: AlarmModel) => (this.used_alarm_ids[alarm.AlarmId] = true));
      this.used_alarm_ids.__empty = false;
      return;
    }

    activeTabAlarms.forEach(async (alarm: AlarmModel) => {
      if (!this.used_alarm_ids[alarm.AlarmId]) {
        this.used_alarm_ids[alarm.AlarmId] = true;
        const level = alarm.Level;

        if (level === 1 || level === 2) {
          const audioSettings = authentication.getAudioSettings(level);
          let audioDefault: HTMLAudioElement;
          let audioDynamic: HTMLAudioElement;

          switch (level) {
            case 1:
              audioDefault = this.audioAlarm_1;
              audioDynamic = this.audioAlarm_3;
              break;
            case 2:
              audioDefault = this.audioAlarm_2;
              audioDynamic = this.audioAlarm_4;
              break;
          }

          const audioPlaySetting = audioSettings[constants.AUDIO_SETTING_PLAY];
          const isAllowedToPlay = !audioPlaySetting || audioPlaySetting === 'true';
          const isCustomAlarmSoundEnabled = this.props.storage!.getItem(
            'isCustomAlarmSoundEnabled'
          );

          if (isAllowedToPlay && AlarmHelpers.playAlarmSound()) {
            if (audioDynamic && alarm.UseSound && isCustomAlarmSoundEnabled) {
              // audioDynamic.src = 'https://rc-dev.skygd.net/static/' + alarm.UseSound + '.mp3/';
              audioDynamic.src = 'static/' + alarm.UseSound + '.mp3/';
              try {
                audioDynamic.load();
                await audioDynamic.play();
              } catch {
                audioDefault.load();
                audioDefault.play();
              }
            } else if (audioDefault) {
              audioDefault.load();
              audioDefault.play();
            }
          }
        }
      }
    });
  };

  removePulsationFromActiveTab = (time?: number) => {
    setTimeout(() => {
      document.querySelector('.active-tab')?.getElementsByClassName("circle-number")[0].classList.remove("pulsating");
    }, time ?? 3000);
  };

  onChange = () => {
    const alarmsByTabs = alarmListStore.getAlarmsByTabs();

    if (alarmsByTabs[constants.TAB_ACTIVE].length > this.state.alarmsByTabs[constants.TAB_ACTIVE].length) {
      const newAlarms = alarmsByTabs[constants.TAB_ACTIVE].filter(
        (alarm: AlarmModel) =>
          !this.state.alarmsByTabs[constants.TAB_ACTIVE].some(
            (oldAlarm: AlarmModel) => oldAlarm.AlarmId === alarm.AlarmId
          )
      );
      
      // clear used alarm ids for new alarms or the ones that returned to active tab
      newAlarms.forEach((alarm: AlarmModel) => {
        this.used_alarm_ids[alarm.AlarmId] = false;
      });

      document.querySelector('.active-tab')?.getElementsByClassName("circle-number")[0].classList.add("pulsating");
      if (alarmListStore.activeTab === constants.TAB_ACTIVE) {
        this.removePulsationFromActiveTab();
      }
    }

    this.setState({
      alarmsByTabs,
      activeTab: alarmListStore.activeTab,
      overlay: false
    });
    this.detectAlarmAudio(alarmsByTabs);
  };

  onChangeTab = () => {
    if (this.props.mobile) {
      window.scrollTo(0, 0);
    }
    this.onChange();
  };

  getMyAlarms = (): AlarmModel[] => {
    return alarmListStore.alarms.filter((a: AlarmModel) => a.State === AlarmState.My.toString());
  };

  pushRemoveAlarm(e: Event, data: PushDataModel) {
    this.handleAlarmToRemove(data.globalAlarmId);
  }

  handleAlarmToRemove(alarmId: string) {
    const alarmsByTabs = alarmListStore.getAlarmsByTabs();
    const activeTabAlarms = alarmsByTabs[constants.TAB_ACTIVE];
    const removedAlarm = activeTabAlarms.find((alarm: AlarmModel) => alarm.AlarmId === alarmId);

    if (removedAlarm) {
      this.used_alarm_ids[removedAlarm.AlarmId] = false;
    }
  }

  isSingleListMode() {
    return settingsStore.getValueByKey('SINGLELIST', 'boolean');
  }

  renderTabs(config: TabModel, alarmT: Function) {
    const { alarmsByTabs, activeTab, newMergedAlarmInMyTab } = this.state;
    const { currentTab } = config;
    const { TAB_ACTIVE, TAB_MY } = constants;
    const isActive = activeTab === currentTab;
    const tabAlarms = alarmsByTabs[currentTab];
    const length = tabAlarms.length;
    let wrapperClassName = 'flex-col-center-center flex-0-0-auto p-rel';
    let needNotify = false;

    if (isActive) {
      wrapperClassName += ' active';
    } else if ((currentTab === TAB_ACTIVE && length) || currentTab === TAB_MY) {
      // The notify reminder perceives unstable:
      // Resolution is to enforce that sound and visual are either enabled or disabled together
      if (this.props.storage!.getItem('isNotifyReminderEnabled')) {
        needNotify = tabAlarms.some((alarm: AlarmModel) => dateTime.isTime(alarm.NotifyAfter));
      }
    }

    const hasMergedAlarms = currentTab === TAB_MY && newMergedAlarmInMyTab;

    return (
      <div className={wrapperClassName} key={config.currentTab}>
        <button
          type="button"
          onClick={e => {
            if (currentTab === TAB_ACTIVE) {
              this.removePulsationFromActiveTab();
            }
            this.changeActiveTab(e, hasMergedAlarms);
          }}
          className={`${config.className} tab-btn p-rel  ${isActive ? 'active' : ''}`}
          data-click={currentTab}
          data-cy={currentTab}
        >
          &nbsp;
          <div className="abs-flex-center">
            <div className={`circle-number size relative ${needNotify ? 'size-pulse' : ''}`}>
              {currentTab === TAB_MY && hasMergedAlarms && <span className="dot" />}
              <div
                className={`${false && currentTab === TAB_MY && this.getMyAlarms().length > 0
                  ? 'active-circle'
                  : ''
                  }`}
              />
            </div>
          </div>
          <span className="p-abs abs-flex-center alarm-quantity">{length}</span>
        </button>
        <div className="flex-col-around flex-0-0-auto alarm-tab-name">
          {alarmT(config.currentLocalization)}
        </div>
        {!!isActive && (
          <div className="flex-center w-100p bottom-0-left-0 p-abs">
            <div className="pointer_active_tab" />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { notification, activeTab, alarmsByTabs, overlay } = this.state;
    const isSingleListMode = this.isSingleListMode();
    const alarmT = localization.useNSt(constants.NS_ALARM);
    const tabs = isSingleListMode ? singleListTabs : multiListTabs;
    const isSortEnabled = settingsStore.getValueByKey('ALARMLISTALLOWSORT') === 'True';
    const headers = isSortEnabled ? [
      {
        key: 'Priority',
        content: alarmT('PRIORITY')
      },
      {
        key: 'Name',
        content: alarmT('NAME')
      },
      {
        key: 'ServerAlarmTime',
        content: alarmT('TIMESTAMP')
      }
    ] : [];
    let bodyStyles = {};

    if (this.props.mobile) {
      bodyStyles = {
        overflowY: 'scroll',
        height: 'calc(100vh - 156px)',
        backgroundColor: '#f0f4f7'
      };
    }

    const grid = (
      <Grid
        ref={el => (this._grid = el)}
        headers={headers}
        sortable={isSortEnabled}
        activeTab={activeTab}
        isSingleListMode={isSingleListMode}
        scroll={true}
        mobileScroll={this.props.mobile}
        rows={alarmsByTabs[activeTab]}
        renderRow={this.renderAlarm}
        sortOnDefault
      />
    );

    return (
      <div className="flex-col flex-1-1-0px p-rel" data-cy="alarmList">
        <div className="p-abs">
          <audio preload="auto" id={constants.ID_AUDIO_ALARM_1}>
            <source src={audioAlarmURL} type="audio/mpeg" />
          </audio>
          <audio preload="auto" id={constants.ID_AUDIO_ALARM_2}>
            <source src={audioAlarmURL} type="audio/mpeg" />
          </audio>
          <audio id={constants.ID_AUDIO_ALARM_3} />
          <audio id={constants.ID_AUDIO_ALARM_4} />
        </div>
        <div className="flex-0-0-auto flex-row-around tabs-container">
          {tabs.map(config => this.renderTabs(config, alarmT))}
        </div>
        {this.props.mobile ? <div style={bodyStyles}>{grid}</div> : grid}
        {!!overlay && <SpinnerOverlay />}
        <NotificationSystem
          ref={(node: NotificationSystem.System) => (this.notificationSystem = node)}
        />
        <Snackbar
          open={!!notification}
          message={notification || ''}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
        <MobxUISnackbar />
      </div>
    );
  }
}
