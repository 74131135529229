import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import localization from '../localization';
import Utils from '../utils';
import IconArrow from './icon_arrow';

export interface ExtraSettingsModel {
  Order: number;
  TitleKey: string;
  CommandId: number;
  OnClick: { DisableVideoPlayback: boolean; DisableCameraRestart: boolean; DisableTimeOut: number };
}

interface Props {
  camera: any;
  fullscreen: boolean;
  alarmId: string;
  cameraId: number;
  disabled: boolean;
  onCommandSend: (commandId: number, id: number) => void;
  onDisable?: (commands: any) => void;
  onVideoEnd?: (commands: any, cameraType: any) => void;
  extraOpened?: boolean;
  toggleExtra: () => void;
}

interface State {
  showContent: boolean;
}

@observer
export default class ExtraSettings extends React.Component<Props, State> {
  @observable showContent = observable.box(this.props.extraOpened);
  @observable showConfirmModal = observable.box(false);
  currentCommand: [number, string] | null = null;

  toggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.toggleExtra();
    this.showContent.set(!this.showContent.get());
  };

  onClickRestart = (e: React.MouseEvent<HTMLButtonElement>, commandId: number, commands?: any) => {
    e.preventDefault();

    if (commands) {
      this.props.onDisable && this.props.onDisable(commands);

      if (this.props.onVideoEnd) {
        const cameraType = this.props.camera && this.props.camera.CameraType;
        cameraType && this.props.onVideoEnd(commands, cameraType);
      }
    }

    this.props.onCommandSend(commandId, this.props.camera.Id);
    return;
  };

  renderCommands = (extra: ExtraSettingsModel) => {
    return (
      <div key={extra.CommandId + extra.Order} className="m-tb-02em-rl-05em flex-center-w">
        {extra.OnClick && (
          <button
            key={extra.CommandId}
            className="m-tb-02em-rl-05em button-small-blue"
            style={{ whiteSpace: 'nowrap' }}
            onClick={e => this.onClickRestart(e, extra.CommandId, extra.OnClick)}
            disabled={this.props.disabled}
          >
            {localization.t(extra.TitleKey)}
          </button>
        )}
      </div>
    );
  };

  render() {
    const { fullscreen, camera } = this.props;
    const fullscreenMode = fullscreen ? 'fullscreen-presets' : '';

    const extras = camera && camera.Extras;

    if (camera && camera.Id !== this.props.cameraId) {
      return null;
    }

    if (!extras || (extras && !extras.peek().length)) {
      return null;
    }

    return (
      <div className={`section ${fullscreenMode}`}>
        <div className="flex-row flex-row-between section-header bold">
          <div className="p-1em flex-1-1-0px">{localization.t('EXTRA_SETTINGS')}</div>
          <div className="flex-row flex-0-0-auto section-icon">
            <button type="button" className="button-small-blue" onClick={this.toggle}>
              {this.showContent.get() ? <IconArrow icon="up" /> : <IconArrow icon="down" />}
            </button>
          </div>
        </div>
        {this.showContent.get() && (
          <div className="p-1em flex-row-around flex-row-wrap  bg-c-white-primary">
            {extras
              .sort(Utils.sortByOrder)
              .map((extra: ExtraSettingsModel) => this.renderCommands(extra))}
          </div>
        )}
      </div>
    );
  }
}
