import React from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import LabelValue from './LabelValue';

export default class VeoliaAddressSection extends React.Component {
  render() {
    const { userDetails, alarmT, instance, widget } = this.props;

    return (
      <Section
        widget={widget}
        addBodyClassName="body-hor p-t0-l0-1"
        headerLocKey="ADDRESSES"
        headerLocOptions={{ ns: constants.NS_ALARM }}
        childrenLocOptions={{ ns: constants.NS_ALARM }}
      >
        {userDetails.Addresses.length
          ? userDetails.Addresses.sort((address_a, address_b) => {
              if (address_a.Order === address_b.Order) {
                return 0;
              }
              return address_a.Order < address_b.Order ? -1 : 1;
            }).map((address, index) => {
              return (
                <div key={index} className="p-t-1em flex-col">
                  <LabelValue
                    alarmT={alarmT}
                    position="col"
                    colon={true}
                    loc_key={'DESCRIPTION'}
                    value={address.Description}
                    linkify={instance.linkifyValue}
                  />
                  <Section
                    widget={widget}
                    childrenLocOptions={{ ns: constants.NS_ALARM }}
                    sectionClassName="flex-row-wrap"
                    bodyClassName="flex-row-wrap"
                  >
                    {[
                      {
                        label: 'STREET',
                        value: instance.linkifyValue(address.StreetAddress),
                        skip: true
                      },
                      {
                        label: 'APARTMENT_NUMBER',
                        value: address.ApartmentNumber,
                        skip: true
                      },
                      {
                        label: 'POSTCODE',
                        value: instance.linkifyValue(address.PostalCode),
                        skip: true
                      },
                      {
                        label: 'CITY',
                        value: instance.linkifyValue(address.City),
                        skip: true
                      },
                      {
                        label: 'COUNTRY',
                        value: instance.linkifyValue(address.Coutry),
                        skip: true
                      }, // questionable (to define with David)
                      {
                        label: 'DOOR_CODE',
                        value: instance.linkifyValue(address.DoorCode),
                        skip: true
                      },
                      {
                        label: 'HOUSE_ALARM_CODE',
                        value: instance.linkifyValue(address.AlarmCode),
                        skip: true
                      },
                      {
                        label: 'TELEPHONE_PRIMARY',
                        value: address.Telephone1,
                        telephone_format: 'yes',
                        skip: true
                      },
                      {
                        label: 'TELEPHONE_SECONDARY',
                        value: address.Telephone2,
                        telephone_format: 'yes',
                        skip: true
                      },
                      {
                        label: 'TELEPHONE_THIRD',
                        value: address.Telephone3,
                        telephone_format: 'yes',
                        skip: true
                      }
                    ]}
                  </Section>
                </div>
              );
            })
          : null}
      </Section>
    );
  }
}
