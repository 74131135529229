import cx from 'classnames';
import { Snackbar } from 'material-ui';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import NotificationSystem from 'react-notification-system';
import MediaQuery from 'react-responsive';
import { Popup as ReactPopup } from 'reactjs-popup';
import { registerTelephoneAction } from '../actions_creator';
import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import dateTime from '../date_time';
import { AlarmState } from '../enums/alarm';
import event_bus from '../event_bus';
import {
  AlarmAttachments,
  AlarmDetailsModel,
  PushDataModel,
  SettingModel,
  TemplateModel, UserAbsenceModel,
  UserDetailsModel
} from '../interfaces/backend_model';
import localization from '../localization';
import { ScheduledReminderPopup } from '../raw_templates/components';
import {
  AbsenceController,
  AbsenceControllerActions
} from '../raw_templates/components/AbsenceSection/AbsenceController';
import { AcknowledgeModal } from '../raw_templates/components/AcknowledgeModal';
import alarmActiveStore from '../stores/alarms/alarm_active_store_actions';
import AlarmHelpers from '../stores/alarms/alarm_helpers';
import alarmListStore from '../stores/alarms/alarm_list_store_actions';
import planningViewStore from '../stores/alarms/planning_view_store_actions';
import settingsStore from '../stores/settings_store';
import templatesStore from '../stores/templates_store';
import twillioStore from '../stores/twillio_store';
import usersStore from '../stores/users/users_store_actions';
import { lazyRetry } from '../utilities/lazyRetry';
import { tokenizer } from './admin_token';
import { SentryErrorBoundary } from './errorBoundaries/error_boundary';
import IconAlarm from './icon_alarm';
import IconClock from './icon_clock';
import IconDoor from './icon_door';
import IconDots from './icon_dots';
import IconUser from './icon_user';
import IconCalendarWeek from './icons/icon_calendar_week';
import IconCancelCalendar from './icons/icon_cancel_calendar';
import IconHistory from './icons/icon_history';
import IconPen from './icons/icon_pen';
import { PopupConfirm } from './popups/popup_confirm';
import PopupError from './popups/popup_error';
import { Spacer } from './spacer';
import SpinnerOverlay from './spinner_overlay';

const DynamicTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/dynamic.jsx'), 'dynamic'));
const DefaultTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/default_v2.jsx'), 'default_v2'));
const BetaTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/beta_v2.jsx'), 'beta_v2'));
const CameraCareTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/camera_care_v2.jsx'), 'camera_care'));
const CameraTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/camera_v2.jsx'), 'camera_v2'));
const CustomTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/custom_v2.jsx'), 'custom_v2'));
const DoroarcTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/doroarc_v2.jsx'), 'doroarc_v2'));
const GoteborgTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/goteborg_v2.jsx'), 'gotebord_v2'));
const KristiansundTemplate = React.lazy(
  () => lazyRetry(() => import('../raw_templates/kristiansund_v2.jsx'), 'kristiansund_v2')
);
const DoronorwayTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/doronorway.jsx'), 'doronorway'));
const MyTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/my_v2.jsx'), 'my_v2'));
const StanleyTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/stanley_v2.jsx'), 'stanley_v2'));
const VeoliaTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/veolia_v2.jsx'), 'veolia_v2'));
const VisitTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/visit_v2.jsx'), 'visit_v2'));
const VisitnocamTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/visitnocam_v2.jsx'), 'visitnocam_v2'));
const CurenaTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/curena_v2.jsx'), 'curena_v2'));
const CurenaB2CTemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/curena_b2c.jsx'), 'curena_2bc'));
const CaverionSETemplate = React.lazy(() => lazyRetry(() => import('../raw_templates/caverion_se.jsx'), 'caverion'));
const ProtectedDataStoreTemplate = React.lazy(
  () => lazyRetry(() => import('../raw_templates/protecteddatastoredemo.jsx'), 'protecteddatastoredemo')
);
const DefaultPlanningTemplate = React.lazy(
  () => lazyRetry(() => import('../raw_templates/planning_view/default_planning.jsx'), 'planning_view')
);

const endpoints = require('../../json/endpoints.json');
const constants = require('../../json/constants.json');

interface Props {
  params: {
    alarm_id: string;
    template_type: string;
    open_type?: string;
  };
  errorMessage: any;
  unauthorizedErrorMessage: any;
}

interface State {
  alarmDetails: AlarmDetailsModel | null;
  template: TemplateModel | null;
  userDetails: UserDetailsModel | null;
  userAbsenceDetails: UserAbsenceModel | null;
  alarmAttachments: AlarmAttachments;
  overlay: boolean;
  removedOnPushId: boolean | any;
  removedOnPushMessage: boolean | any;
  clearError: string;
  askTakeOverId: any | null;
  notification: string | null;
  pendingTakeOverId: string | null;
  errorMessage: boolean | any;
  settings: SettingModel | null;
  showPlannedTimeModal: boolean;
  acknowledgementModalOpen: boolean;
  acknowledgeMap: {
    [key: string]: boolean;
  }
}

export default class Template extends React.Component<Props, State> {
  notificationSystem: NotificationSystem.System;

  static contextTypes = {
    readonly: PropTypes.bool
  };

  static childContextTypes = {
    alarmId: PropTypes.string,
    templateType: PropTypes.string,
    open_type: PropTypes.string
  };

  node: HTMLDivElement | null = null;
  isMobile: boolean = false;
  absenceControllerRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      notification: null,
      template: templatesStore.getCurrentTemplate(),
      pendingTakeOverId: null,
      askTakeOverId: null,
      errorMessage: false,
      overlay: false,
      alarmDetails: null,
      userAbsenceDetails: null,
      alarmAttachments: {} as AlarmAttachments,
      userDetails: null,
      clearError: '',
      removedOnPushId: false,
      removedOnPushMessage: false,
      settings: settingsStore.getSettings(),
      showPlannedTimeModal: false,
      acknowledgementModalOpen: false,
      acknowledgeMap: {}
    };
    this.absenceControllerRef = React.createRef<AbsenceControllerActions>();
  }

  getChildContext() {
    let AlarmId;
    if (this.state.alarmDetails) {
      AlarmId = this.state.alarmDetails.AlarmId;
    }

    return {
      alarmId: AlarmId || '',
      templateType: (this.props.params || {}).template_type
    };
  }

  componentDidMount() {
    settingsStore.on(constants.SETTINGS_CHANGE, this._onChange);
    event_bus.on(constants.REGISTER_TELEPHONE_NUMBER, this.triggerRegisterTelephone, this);
    event_bus.on(constants.WS_PUSH_ALARM_UPDATE, this.handlePushUpdate, this);
    event_bus.on(constants.WS_PUSH_ALARM_REMOVE, this.handlePushRemove, this);

    let { template_type, alarm_id } = this.props.params;

    this.onListeners(alarm_id);
    let state = this.calcState(this.props);
    let has_data = this.pendingByState(state, alarm_id, template_type);
    alarmActiveStore.setCurrentAlarmId(alarm_id);
    this.setState({
      alarmDetails: state.alarmDetails,
      userDetails: state.userDetails,
      alarmAttachments: state.alarmAttachments,
      overlay: !has_data
    });
    this.updateStylesGoteborg();
  }

  componentDidUpdate() {
    this.updateStylesGoteborg();
    templatesStore.loadTemplate(this.props.params.template_type);
  }

  onListeners(alarm_id: string) {
    usersStore.on(constants.USER_DETAILS_CHANGE, this._onChange);
    alarmListStore.on(constants.ALARMS_CHANGE, this._onChange);
    alarmActiveStore.on(constants.ALARM_DETAILS_CHANGE, this.updateDetails);
    alarmActiveStore.on(constants.ALARM_ATTACHMENTS_CHANGE, this._onChange);

    alarmActiveStore.on(constants.ALARM_DETAILS_FETCHING, () => {
      this.setState({ overlay: true });
    });
    alarmListStore.on(constants.ALARM_DETAILS_BASIC_CHANGE, this._onChange);
    alarmListStore.on(constants.ALARM_CLEAR_POSTING, () => {
      this.setState({ overlay: true });
    });
    alarmActiveStore.on(constants.ALARM_CLEAR_POSTING, () => {
      this.setState({ overlay: true });
    });
    alarmActiveStore.on(constants.ALARM_CLEAR_ERROR, this._onClearError);

    templatesStore.on(constants.TEMPLATE_CHANGE, this._onChange);

    appDispatcher.handleServerAction({
      type: constants.START_UPDATING_SERVER_ALARM_DETAILS,
      alarm_id
    });
  }

  offListeners() {
    usersStore.off(constants.USER_DETAILS_CHANGE, this._onChange);
    alarmListStore.off(constants.ALARMS_CHANGE, this._onChange);
    alarmActiveStore.off(constants.ALARM_DETAILS_CHANGE, this.updateDetails);
    alarmListStore.off(constants.ALARM_DETAILS_BASIC_CHANGE, this._onChange);
    alarmActiveStore.off(constants.ALARM_ATTACHMENTS_CHANGE, this._onChange);

    alarmActiveStore.off(constants.ALARM_DETAILS_FETCHING);
    alarmActiveStore.off(constants.ALARM_CLEAR_POSTING);
    alarmListStore.off(constants.ALARM_CLEAR_POSTING);
    alarmActiveStore.off(constants.ALARM_CLEAR_ERROR);

    templatesStore.off(constants.TEMPLATE_CHANGE, this._onChange);

    appDispatcher.handleServerAction({ type: constants.STOP_UPDATING_SERVER_ALARM_DETAILS });
  }

  componentWillMount() {
    templatesStore.on(constants.TEMPLATE_CHANGE, this.onTemplateChange);
    appDispatcher.handleServerAction({
      type: constants.TEMPLATE_GET,
      template_source: this.isMobile ? 'mobile' : 'desktop',
      template_type: this.props.params.template_type
    });
  }

  componentWillUnmount() {
    settingsStore.off(constants.SETTINGS_CHANGE, this._onChange);
    templatesStore.off(constants.TEMPLATE_CHANGE, this.onTemplateChange);
    event_bus.off(constants.REGISTER_TELEPHONE_NUMBER, this.triggerRegisterTelephone);
    event_bus.off(constants.WS_PUSH_ALARM_UPDATE, this.handlePushUpdate);
    event_bus.off(constants.WS_PUSH_ALARM_REMOVE, this.handlePushRemove);

    alarmActiveStore.setCurrentAlarmId('');

    this.offListeners();
  }

  updateDetails = () => {
    this._onChange();
    this.getGroupRespondents();
  }

  getGroupRespondents() {
    const { alarmDetails } = this.state;
    if (alarmDetails && alarmDetails.DynamicDetails?.RespondentGroup) {
      appDispatcher.handleServerAction({
        type: constants.LOAD_RESPONDENT_GROUP_DETAILS,
        respondent_group_id: alarmDetails.DynamicDetails.RespondentGroup
      });
    }
  }

  updateStylesGoteborg = () => {
    if (this.props.params.template_type === 'goteborg' && this.node) {
      Array.from(this.node.getElementsByClassName('f-15')).forEach((element: any) => {
        element.style.fontSize = '1rem';
      });
      Array.from(this.node.getElementsByClassName('f-22')).forEach((element: any) => {
        element.style.fontSize = '1.4375rem';
      });
    }
  };

  handlePushUpdate(e: Event, data?: PushDataModel) {
    const { alarmDetails } = this.state;

    if (data) {
      const alarm_id = data.globalAlarmId;

      if (alarm_id) {
        const alarm = alarmListStore.getAlarm(alarm_id);
        if (alarm && alarm.UserId) {
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_USER_HISTORY,
            alarm_id: alarm_id,
            user_id: alarm.UserId
          });
          // if a new alarm that arrives is connected to the active user we want to update the history
          // this extra check is needed as the alarm fetched from the alarmListStore doesn't exist yet
        } else if (usersStore.getActiveUserId()?.toString() === data.userId) {
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_USER_HISTORY,
            alarm_id: alarm_id,
            user_id: data.userId
          });
        }

        // loading alarm details basic will put the alarm in the active/my/respondent alarm list,
        // for planning view alarms we only want that when the alarm is assigned to a respondent
        if (!data.planningview || data.respondentid !== '') {
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
            alarm_id
          });
        }

        if (alarmDetails && alarmDetails.AlarmId === alarm_id) {
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARM_DETAILS,
            alarm_id
          });
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
            alarm_id
          });
        }
      }
    }
  }

  /**
   * update information according to the event description
   */
  handlePushRemove(e: Event, data: PushDataModel) {
    if (data) {
      let alarm_id = data.globalAlarmId;
      if (alarm_id) {
        let alarm = alarmListStore.getAlarm(alarm_id);
        if (alarm && alarm.UserId) {
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_USER_HISTORY,
            alarm_id: alarm_id,
            user_id: alarm.UserId
          });
        }
        if (alarm_id === this.props.params.alarm_id) {
          const { alarmDetails } = this.state;

          // loading alarm details basic will put the alarm in the active/my/respondent alarm list,
          // for planning view alarms we only want that when the alarm is assigned to a respondent
          if (!data.planningview || data.respondentid !== '') {
            appDispatcher.handleServerAction({
              type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
              alarm_id
            });
          }

          if (alarmDetails && alarmDetails.AlarmId === alarm_id) {
            appDispatcher.handleServerAction({
              type: constants.LOAD_SERVER_ALARM_DETAILS,
              alarm_id
            });
            appDispatcher.handleServerAction({
              type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
              alarm_id
            });
          }

          this.setState({
            removedOnPushId: alarm_id,
            removedOnPushMessage: localization.t('ALARM_HAS_BEEN_TRANSFERRED', {
              ns: constants.NS_ALARM
            })
          });
        }
      }
    }
  }

  /**
   * add alarm_id to the event and dispatch event to the stores
   */
  triggerRegisterTelephone(e: Event, data: { telephone_number: string; contactId?: number }) {
    appDispatcher.handleViewAction(
      registerTelephoneAction(data.telephone_number, this.props.params.alarm_id, data.contactId)
    );
  }

  /**
   * create actions for stores to clear cashed data
   */
  clearStores(alarm_id: string) {
    appDispatcher.handleServerAction({ type: constants.CLEAR_SERVER_ALARM_DETAILS, alarm_id });
    appDispatcher.handleServerAction({
      type: constants.CLEAR_SERVER_ALARM_DETAILS_UPDATES,
      alarm_id
    });
  }

  /**
   * pending server only if data is not avaliable
   */
  pendingByState(state: State, alarm_id: string, template_type: string) {
    let { alarmDetails, userDetails, alarmAttachments: alarmAttachments } = state;
    let has_data = alarmDetails && userDetails && alarmAttachments;

    if (usersStore.forceStopRequestPending) {
      return;
    }

    if (!has_data) {
      if (!alarmDetails) {
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_DETAILS,
          alarm_id
        });
      } else if (!userDetails && alarmDetails.UserId && alarmDetails.AlarmId) {
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_USER_DETAILS,
          user_id: alarmDetails.UserId,
          alarm_id: alarmDetails.AlarmId
        });

        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_USER_HISTORY,
          alarm_id: alarm_id,
          user_id: alarmDetails.UserId
        });
      }

      if (!alarmAttachments) {
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
          alarm_id
        });
      }
    }
    return has_data;
  }

  /**
   * calculate data from stores
   */
  calcState(props: Props): State {
    let { alarm_id } = props.params;
    let alarmDetails: AlarmDetailsModel = alarmActiveStore.getAlarmDetails(alarm_id),
      userDetails =
        alarmDetails && alarmDetails.UserId ? usersStore.getUserDetails(alarmDetails.UserId) : null,
      userAbsenceDetails =
        alarmDetails && alarmDetails.UserId ? usersStore.getUserAbsenceDetails(alarmDetails.UserId) : null,
      alarmAttachments = alarmActiveStore.getAlarmAttachments(alarm_id);

    return { ...this.state, alarmDetails, userDetails, userAbsenceDetails, alarmAttachments };
  }

  /**
   * https://github.com/reactjs/react-router/issues/527
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.errorMessage || nextProps.unauthorizedErrorMessage) {
      return; // avoid infinity cycle
    }
    let { alarm_id } = nextProps.params;
    this.pruneAlarmAndUserData(alarm_id);
  }

  pruneAlarmAndUserData = (nextAlarmId: string) => {
    if (this.state.alarmDetails && nextAlarmId != this.state.alarmDetails.AlarmId) {
      appDispatcher.dispatch({
        action: {
          type: constants.ACTION_PRUNE_LOCAL_DATA_BY_ID,
          alarm_id: nextAlarmId,
          user_id: this.state.alarmDetails.UserId
        }
      });
    }
  };

  askTakeOver = () => {
    this.setState({ askTakeOverId: this.props.params.alarm_id });
  };

  takeOverWorkflow(state: State, fromDialog: boolean) {
    let { alarmDetails, notification } = state;
    if (!alarmDetails || this.state.pendingTakeOverId === alarmDetails.AlarmId) {
      return;
    }
    let alarm_id = alarmDetails.AlarmId,
      user_id = alarmDetails.UserId;
    this.setState({ overlay: true, askTakeOverId: null, pendingTakeOverId: alarm_id });
    ajax.postByDesc(
      false,
      endpoints.ACCEPT,
      {
        alarm_id,
        force: fromDialog ? true : null
      },
      (err: string | null, xhr: XMLHttpRequest) => {
        let denied = false;
        if (err) {
          if (xhr.status === 406) {
            denied = true;
            if (!fromDialog) {
              err = null;
            }
          }
          this.setState({
            notification:
              !fromDialog && denied
                ? localization.t('UNABLE_TAKE_ALARM', { ns: constants.NS_ALARM })
                : notification,
            errorMessage: localization.t('TEMPLATE_ERROR_HEADER') + ' ' + err,
            overlay: false,
            pendingTakeOverId: null
          });
          if (!fromDialog) {
            this.clearStores(alarm_id);
            appDispatcher.handleServerAction({
              type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
              alarm_id
            });
            if (user_id) {
              appDispatcher.handleServerAction({
                type: constants.LOAD_SERVER_USER_HISTORY,
                user_id,
                alarm_id: alarm_id,
                force: true
              });
            }
          }
        } else {
          this.clearStores(alarm_id);
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
            alarm_id
          });
          if (user_id) {
            appDispatcher.handleServerAction({
              type: constants.LOAD_SERVER_USER_HISTORY,
              user_id,
              alarm_id: alarm_id,
              force: true
            });
          }
          this.setState({
            pendingTakeOverId: null
          });
        }
      }
    );
  }

  handleDocumentClick = (e: React.MouseEvent<HTMLDivElement>, canTake: boolean) => {
    if (canTake && this.props.params.template_type === 'goteborg') {
      e.preventDefault();
      this.setState({ errorMessage: localization.t('NOT_OWNER_ALARM') });
    }
  };

  handleTakeOverConfirmClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let alarmId = (this.state.alarmDetails && this.state.alarmDetails.AlarmId) || '';
    let element = e.target;
    if (element.dataset.click === 'close') {
      this.setState({ askTakeOverId: null });
    } else if (element.dataset.click === 'continue') {
      //check if user takes planning view alarm
      //we need to remove this part to avoid alarm editing
      window.location.href =
        (window.location.href.includes('/edit') && window.location.href.replace('/edit', '')) ||
        window.location.href;

      this.takeOverWorkflow(this.state, true);

      if (planningViewStore.getAlarmIndexById(alarmId) !== -1) {
        appDispatcher.handleServerAction({
          type: constants.PLANNED_ALARMS_GET_ONE,
          alarm_id: alarmId
        });
      }
    }
  };

  handlePopupErrorClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset.click === 'close') {
      this.setState({
        errorMessage: false
      });
    }
  };

  handleRemovedOnPushClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset.click === 'close') {
      this.setState({
        removedOnPushMessage: false,
        removedOnPushId: false
      });
      appHistory.replace(constants.PATH_DASHBOARD);
    }
  };

  handleRequestClose = () => {
    this.setState({
      notification: null
    });
  };

  closeAlarm = () => {
    let alarmId = (this.state.alarmDetails && this.state.alarmDetails.AlarmId) || '';
    if (planningViewStore.getPlannedAlarmById(alarmId)) {
      appHistory.push(constants.PATH_PLANNING_VIEW);
    } else {
      appHistory.push(constants.PATH_DASHBOARD);
    }
  };

  onTemplateChange = () => {
    this.setState({
      template: templatesStore.getCurrentTemplate()
    });
  };

  showErrorInTemplate = (error: string) => {
    this.setState({ errorMessage: error });
  };

  useNewTemplates() {
    return settingsStore.getValueByKey('USE_NEW_TEMPLATES', 'boolean');
  }

  getTemplate(
    matches: boolean,
    alarmDetails: AlarmDetailsModel | null,
    userDetails: UserDetailsModel | null,
    userAbsenceDetails: UserAbsenceModel | null,
    alarmAttachments: AlarmAttachments,
    canTake: boolean,
    alarmDeactivated: boolean,
    template: TemplateModel | null
  ) {
    switch (this.props.params.template_type) {
      case 'beta':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <BetaTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'camera':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <CameraTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'camera_care':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <CameraCareTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'custom':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <CustomTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'doroarc':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <DoroarcTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'goteborg':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <GoteborgTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              canTake={canTake}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'kristiansund':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <KristiansundTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );
      case 'doronorway':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <DoronorwayTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
              templateType={this.props.params.template_type}
            />
          </React.Suspense>
        );

      case 'my':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <MyTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'stanley':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <StanleyTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'veolia':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <VeoliaTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'visit':
      case 'visitalarm':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <VisitTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'visitnocam':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <VisitnocamTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );

      case 'curena':
      case 'curena-callcenter':
      case 'curena-alarm':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <CurenaTemplate
              context={this.getChildContext()}
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );
      case 'curena-b2c':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <CurenaB2CTemplate
              context={this.getChildContext()}
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );
      case 'caverion-se':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <CaverionSETemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              templateType={this.props.params.template_type}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );
      case 'protecteddatastoredemo':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <ProtectedDataStoreTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              templateType={this.props.params.template_type}
              showErrorInTemplate={this.showErrorInTemplate}
            />
          </React.Suspense>
        );
      case 'defaultPlanning':
      case 'defaultplanning':
        return (
          <React.Suspense fallback={<SpinnerOverlay />}>
            <DefaultPlanningTemplate
              alarmDetails={alarmDetails}
              userDetails={userDetails}
              twillioStore={twillioStore}
              alarmAttachments={alarmAttachments}
              isMobile={matches}
              readonly={this.context.readonly}
              clearError={this.state.clearError}
              alarmDeactivated={alarmDeactivated}
              node={this.node}
              showErrorInTemplate={this.showErrorInTemplate}
              templateType={this.props.params.template_type}
              open_type={this.props.params.open_type}
            />
          </React.Suspense>
        );
    }
    if (
      (!this.useNewTemplates() && !this.state.template) ||
      this.props.params.template_type === 'defaultalarmdemo'
    ) {
      return (
        <React.Suspense fallback={<SpinnerOverlay />}>
          <DefaultTemplate
            alarmDetails={alarmDetails}
            userDetails={userDetails}
            twillioStore={twillioStore}
            alarmAttachments={alarmAttachments}
            isMobile={matches}
            readonly={this.context.readonly}
            clearError={this.state.clearError}
            alarmDeactivated={alarmDeactivated}
            node={this.node}
            templateType={this.props.params.template_type}
            showErrorInTemplate={this.showErrorInTemplate}
          />
        </React.Suspense>
      );
    } else {
      return (
        <React.Suspense fallback={<SpinnerOverlay />}>
          <DynamicTemplate
            template={this.state.template}
            alarmDetails={alarmDetails}
            userDetails={userDetails}
            userAbsenceDetails={userAbsenceDetails}
            twillioStore={twillioStore}
            alarmAttachments={alarmAttachments}
            isMobile={matches}
            readonly={this.context.readonly}
            clearError={this.state.clearError}
            alarmDeactivated={alarmDeactivated}
            node={this.node}
            showErrorInTemplate={this.showErrorInTemplate}
            templateType={this.props.params.template_type}
            notificationSystem={this.notificationSystem}
          />
        </React.Suspense>
      );
    }
  }

  schedulePlanningAlarmTime = (time: string) => {
    let alarmId = this.state.alarmDetails && this.state.alarmDetails.AlarmId;

    alarmId && planningViewStore.updatePlannedAlarmTime(alarmId, time);
    this.setState({
      showPlannedTimeModal: false
    });
  };

  getPlanningMaxDate = () => {
    //possibility to schedule alarm in 3 month range
    return moment(new Date())
      .add(36, 'M')
      .toDate();
  };

  renderPlannedTimeModal = () => {
    const { alarmDetails } = this.state;
    const plannedTime = (alarmDetails && alarmDetails.PlannedTime) || new Date().setSeconds(0);

    return (
      <ScheduledReminderPopup
        alarmId={(alarmDetails && alarmDetails.AlarmId) || ''}
        show={this.state.showPlannedTimeModal}
        onClose={() => this.setState({ showPlannedTimeModal: false })}
        instance={this}
        preSelectedDate={moment(plannedTime).toDate()}
        hideCommentSection={true}
        dateRange={{ maxDate: this.getPlanningMaxDate() }}
        scheduleReminderType="AlarmPlannedTime"
        skipWeekendDays={false}
      />
    );
  };

  renderAbsenceActionButtons = (userAbsenceDetails: UserAbsenceModel, alarmT: (key: string) => string): ReactNode => {
    const absenceActionButtons = (
      <>
        <div className='flex-row'>
          <IconCalendarWeek />
          <Spacer size={8} />
          <button
            type='button'
            className='form-btn-transparent list-button'
            onClick={this.absenceControllerRef.current?.openCreateAbsence}
          >
            {alarmT('SCHEDULE_ABSENCE')}
          </button>
        </div>
        {userAbsenceDetails?.Ongoing.length === 1 && (
          <>
            <Spacer size={6} />
            <div className='flex-row'>
              <IconCancelCalendar />
              <Spacer size={8} />
              <button
                type='button'
                className='form-btn-transparent list-button'
                onClick={() => {
                  this.absenceControllerRef.current?.setAbsenceIdToDelete(userAbsenceDetails.Ongoing[0].Id);
                }}
              >
                {alarmT('TERMINATE_SCHEDULED_ABSENCE')}
              </button>
            </div>
          </>
        )}
      </>
    );

    return (
      <>
        {absenceActionButtons}
        <Spacer size={6} />
        <div className='flex-row'>
          <IconHistory />
          <Spacer size={8} />
          <button
            type='button'
            className='form-btn-transparent list-button'
            onClick={this.absenceControllerRef.current?.openAbsenceHistory}
          >
            {alarmT('SCHEDULED_ABSENCES')}
          </button>
        </div>
      </>
    )
  }

  render() {
    let {
      alarmDetails,
      notification,
      askTakeOverId,
      removedOnPushId,
      removedOnPushMessage,
      userDetails,
      userAbsenceDetails,
      alarmAttachments: alarmAttachments,
      errorMessage,
      overlay
    } = this.state;

    const reminderLog = alarmAttachments?.Logs?.filter(log => log.PredefinedId === 2 && log.LogEventCode === 8).sort((a, b) => {
      return new Date(b.Created).getTime() - new Date(a.Created).getTime();
    })[0];
    let alarmClass = alarmDetails
      ? AlarmHelpers.getAlarmClass({
        active: alarmDetails.DynamicDetails.Active,
        respondentId: alarmDetails.DynamicDetails.RespondentId,
        respondentOwn: alarmDetails.DynamicDetails.RespondentOwn
      })
      : '';

    let { alarm_id, open_type } = this.props.params,
      takeOverRespMessage = localization.t('TAKE_OVER_FROM_RESPONDENT_CONFIRM'),
      takeOverMessage = localization.t('TAKE_OVER_CONFIRM'),
      defaultTemplateHeaderClassName = 'flex-row-wrap flex-1-1-auto template-header ',
      defaultTemplateSubHeaderClassName = 'flex-row-wrap flex-1-1-auto template-sub-header ',
      templateHeaderClassName = alarmDetails
        ? defaultTemplateHeaderClassName + alarmClass
        : defaultTemplateHeaderClassName,
      templateSubHeaderClassName = alarmDetails
        ? defaultTemplateSubHeaderClassName + alarmClass
        : defaultTemplateSubHeaderClassName,
      headerText = '',
      canTake = false,
      hasRespondent = false,
      templateTypeName = this.props.params.template_type,
      changeFontFamilyClass = '';

    const { isTemplateOnly } = templatesStore;
    const isScheduleAbsenceEnabled =
      settingsStore.getValueByKey('USER_ABSENCES', 'boolean');

    const alarmT = localization.useNSt(constants.NS_ALARM);

    if (alarmDetails) {
      let { DynamicDetails } = alarmDetails;
      let headerName =
        (DynamicDetails.RespondentName ? DynamicDetails.RespondentName : '') +
        (DynamicDetails.RespondentInitials ? ' (' + DynamicDetails.RespondentInitials + ')' : '');
      if (DynamicDetails.Active) {
        if (DynamicDetails.RespondentId) {
          headerText = `${localization.t('HANDLED_BY')}: ${headerName}`;
        } else {
          headerText = localization.t('NOT_TAKEN_BY_ANYONE');
        }
        if (!DynamicDetails.RespondentOwn) {
          canTake = true;
          let respondent_name =
            (DynamicDetails.RespondentName ? DynamicDetails.RespondentName : '') +
            (DynamicDetails.RespondentInitials
              ? ' (' + DynamicDetails.RespondentInitials + ')'
              : '');
          if (
            DynamicDetails.RespondentId &&
            (DynamicDetails.RespondentName || DynamicDetails.RespondentInitials)
          ) {
            hasRespondent = true;
            takeOverRespMessage = takeOverRespMessage.replace('{RespondentName}', respondent_name);
          }
        }
      } else {
        headerText = `${localization.t('HANDLED_BY_DEACTIVATED')}: ${headerName}`;
      }
    }

    let alarmDeactivated = false;
    if (alarmDetails) {
      if (
        AlarmHelpers.defineAlarmState({
          active: alarmDetails.DynamicDetails.Active,
          respondentId: alarmDetails.DynamicDetails.RespondentId,
          respondentOwn: alarmDetails.DynamicDetails.RespondentOwn
        }) === AlarmState.Deactivated
      ) {
        alarmDeactivated = true;
      }
    }

    if (templateTypeName === 'goteborg') {
      changeFontFamilyClass = 'font-goteborg';
    }

    const objectLink = tokenizer.calcFullAdminURL(settingsStore.getSettings(), settingsStore.getMe(), `objects/${alarmDetails?.UserId}`);

    return (
      <MediaQuery maxDeviceWidth={1224}>
        {matches => {
          this.isMobile = matches;
          return (
            <div
              ref={node => (this.node = node)}
              className={`flex-1-1-auto bg-c-white-primary ${matches ? '' : 'y-scroll-on'
                } p-rel template-section ${changeFontFamilyClass}`}
            >
              <div className="form-list flex-col flex-1-1-auto p-1em">
                <div className="template-full-header">
                  <div className={templateHeaderClassName}>
                    <div className="flex-row-wrap flex-1-1-auto">
                      <div className="flex-col-around flex-0-0-auto f-22 p-05em">
                        {alarmDetails ? (
                          <IconAlarm
                            icon={alarmDetails.IconRef}
                            fallbackIcon={alarmDetails.IconRefFallback}
                          />
                        ) : null}
                      </div>
                      {alarmDetails ? (
                        <div className="flex-col-around flex-0-0-auto f-22 p-025em">
                          {alarmDetails.AlarmText + (userDetails ? ',' : '')}
                        </div>
                      ) : null}
                      <div className="flex-col-around f-22 p-025em">
                        {userDetails ?
                          <a className='link-to-object' target="_blank" href={objectLink!}>
                            {userDetails.Name}
                          </a>
                          :
                          ''
                        }
                      </div>

                      {!!userAbsenceDetails?.Ongoing.length ? (
                        <div
                          className="flex-col-around flex-0-0-auto f-22 p-025em"
                          style={{ color: '#DB9326' }}
                        >
                          ({alarmT('ABSENT')})
                        </div>
                      ) : null}

                      <div className="flex-col-around flex-1-1-auto f-22 p-025em">
                        {userDetails?.DateOfDeath ? (
                          <div className="flex-col-around flex-0-0-auto f-22 p-025em deceased">
                            ({localization.t('DECEASED', {
                              ns: constants.NS_ALARM
                            })})
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="flex-col-wrap flex-0-0-auto" style={{ marginLeft: '11px' }}>
                      <div className="flex-row-end">
                        <div className="flex-col-around flex-0-0-auto f-15 p-025em">
                          <IconClock />
                        </div>
                        <div className="flex-col-around flex-0-0-auto p-05em">
                          {localization.t('TIME_OF_ALARM', {
                            ns: constants.NS_MAP
                          })}
                          :
                        </div>
                        {alarmDetails ? (
                          <div className="flex-col-around flex-0-0-auto p-05em">
                            {dateTime.toLocaleDateTime(alarmDetails.ServerAlarmTime)}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex-row-end">
                        <div className="flex-col-center-center flex-0-0-auto p-05em">
                          {localization.t('PLANNING_VIEW_PLANNED_TIME')}:
                        </div>
                        {alarmDetails ? (
                          <div className="flex-col-center-center flex-0-0-auto p-05em">
                            {dateTime.toLocaleDateTime(alarmDetails.PlannedTime) ||
                              localization.t('PLANNING_VIEW_NO_DATA')}
                          </div>
                        ) : null}
                      </div>
                      {this.state.showPlannedTimeModal && this.renderPlannedTimeModal()}
                    </div>
                    {open_type === 'edit' ? (
                      <div className="flex-center-w align-end" style={{ height: '55px', margin: '0 4px' }}>
                        <button
                          className="form-btn-transparent"
                          onClick={() => this.setState({ showPlannedTimeModal: true })}
                        >
                          <IconPen />
                        </button>
                      </div>
                    ) : null}
                    {!isTemplateOnly && (
                      <div className="flex-col-around flex-0-0-auto">
                        <button
                          type="button"
                          className={'form-btn-transparent'}
                          onClick={this.closeAlarm}
                        >
                          <IconDoor />
                        </button>
                        {isScheduleAbsenceEnabled && (
                          <ReactPopup
                            trigger={open => (
                              <button className='button-small-outlined'>
                                <IconDots />
                              </button>
                            )}
                            position="bottom right"
                            closeOnDocumentClick={true}
                            closeOnEscape={true}
                            className='absence-popup'
                          >
                            <div>
                              {this.renderAbsenceActionButtons(userAbsenceDetails!, alarmT)}
                            </div>
                          </ReactPopup>
                        )}
                      </div>
                    )}
                  </div>
                  {reminderLog && (
                    <div
                      className={`${templateSubHeaderClassName} reminder-block`}
                    >
                      {`${alarmT("REMINDER_TEXT")}: ${reminderLog.LogText || "(" + alarmT("NO_COMMENT_ADDED") + ")"}`}
                    </div>
                  )}
                  <div
                    className={templateSubHeaderClassName}
                    style={userDetails?.Color ? {
                      borderBottomWidth: '20px',
                      borderBottomStyle: 'solid',
                      borderBottomColor: userDetails?.Color,
                    } : {}}
                  >
                    <div className="flex-col-around f-15 p-05em flex-0-0-auto">
                      <IconUser />
                    </div>
                    <div className="flex-col-around f-15 p-05em flex-1-1-auto">{headerText}</div>
                    {canTake ? (
                      <div className="flex-col-around flex-0-0-auto p-05em">
                        <button
                          type="button"
                          className={cx('button-small-secondary', {
                            ['readonly-hidden']: this.context.readonly
                          })}
                          onClick={this.askTakeOver}
                        >
                          {localization.t('TAKE_OVER')}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="flex-0-0-auto template-full-body"
                  onClick={e => this.handleDocumentClick(e, canTake)}
                >
                  <SentryErrorBoundary template={true}>
                    {this.getTemplate(
                      matches,
                      alarmDetails,
                      userDetails,
                      userAbsenceDetails,
                      alarmAttachments,
                      canTake,
                      alarmDeactivated,
                      this.state.template
                    )}
                  </SentryErrorBoundary>
                </div>
                {overlay ? <SpinnerOverlay /> : null}
              </div>
              <AbsenceController
                ref={this.absenceControllerRef}
                notificationSystem={this.notificationSystem}
                userDetails={userDetails!}
                alarmDetails={alarmDetails!}
                absenceData={userAbsenceDetails!}
              />
              <AcknowledgeModal
                alarmId={alarmDetails?.AlarmId}
                acknowledgement={alarmDetails?.Acknowledgement}
                isOpen={this.state.acknowledgementModalOpen}
                onClose={() => {
                  const newAcknowledgeMap = { ...this.state.acknowledgeMap };
                  newAcknowledgeMap[alarmDetails?.AlarmId ?? ''] = true;
                  this.setState({ acknowledgeMap: newAcknowledgeMap, acknowledgementModalOpen: false });
                }}
              />
              <PopupConfirm
                onClick={this.handleTakeOverConfirmClick}
                no="NO"
                yes="YES"
                message={hasRespondent ? takeOverRespMessage : takeOverMessage}
                show={askTakeOverId && askTakeOverId === alarm_id}
              />
              <PopupError
                onClick={this.handleRemovedOnPushClick}
                message={removedOnPushMessage}
                show={removedOnPushId && removedOnPushId === alarm_id}
              />
              <PopupError
                onClick={this.handlePopupErrorClick}
                message={errorMessage}
                show={!!errorMessage}
              />
              <Snackbar
                open={!!notification}
                message={notification ? notification : ''}
                autoHideDuration={4000}
                onRequestClose={this.handleRequestClose}
              />
              <NotificationSystem
                ref={(node: NotificationSystem.System) => (this.notificationSystem = node)}
              />
            </div>
          );
        }}
      </MediaQuery>
    );
  }

  _onClearError = (alarm_code: string, alarm_id: string | number, clearError: string) => {
    this.setState({
      overlay: false,
      clearError
    });
  };

  _onChange = () => {
    if (this.state.errorMessage || this.props.errorMessage || this.props.unauthorizedErrorMessage) {
      return;
    }

    const { template_type, alarm_id } = this.props.params;
    const state = this.calcState(this.props);
    const has_data = this.pendingByState(state, alarm_id, template_type);
    alarmActiveStore.setCurrentAlarmId(alarm_id);
    this.setState({
      alarmDetails: state.alarmDetails,
      userDetails: state.userDetails,
      userAbsenceDetails: state.userAbsenceDetails,
      alarmAttachments: state.alarmAttachments,
      overlay: !has_data,
      settings: settingsStore.getSettings(),
      template: templatesStore.getCurrentTemplate(),
      // need to check if alarm is already acknowledged to skip modal, before the push notification is received
      acknowledgementModalOpen: !!state.alarmDetails?.RequiresAcknowledgement && !state.acknowledgeMap[state.alarmDetails?.AlarmId ?? ''],
    });
  };
}
