import Utils from './utils';

class Validation {
  static reg_exp = {
    empty: /^$/,
    phone_number: /^\+[0-9]{7,15}$/,
    hours: /^(2[0-3]|[01]?[0-9])$/,
    minutes: /^(5[0-9]|[0-5]?[0-9])$/,
    volume: /^([01]|[0]?\.[0-9])$/
  };
  /**
   * calculate count of all errors on the current model
   */
  static calcErrors(model: any, options: any) {
    if (!model.errors) {
      model.errors = [];
    }
    if (options.invalid === false && model.errors.length) {
      // delete error message
      model.errors = model.errors.filter((error: any) => {
        return error.id !== options.id;
      });
    } else if (options.invalid === true) {
      let found_error;
      if (model.errors.length) {
        found_error = Utils.find_obj(model.errors, 'id', options.id);
      }
      if (!found_error) {
        model.errors.push(options);
      }
    }
  }

  /**
   * add error class name if invalid
   */
  handleErrorClassName<T>(model: any, options: T) {
    if (!model.classList) {
      model.classList = [];
    }
    Validation.calcErrors(model, options);
    if (model.errors.length) {
      model.classList.push(model.errors[0].message);
    } else {
    }
  }

  validate<V>(model: string, config: any, value: V) {
    if (Array.isArray(config.validations)) {
      config.validations.forEach((validation_config: any) => {
        switch (validation_config.validation_type) {
          case 'empty':
            Validation.prototype.validate_empty_value(model);
            break;
        }
      });
    }
  }

  /**
   * validate value if it is empty or not
   */
  validate_empty_value(value: string) {
    let reg_exp = Validation.reg_exp.empty;
    reg_exp.lastIndex = 0;
    return reg_exp.test(value);
  }

  /**
   * validate value if it is correct phone number
   */
  validate_phone_number(value: string) {
    let reg_exp = Validation.reg_exp.phone_number;
    reg_exp.lastIndex = 0;
    return !reg_exp.test(value);
  }

  /**
   * validate value if it is correct hours value
   */
  validate_hours_value(value: string) {
    let reg_exp = Validation.reg_exp.hours;
    reg_exp.lastIndex = 0;
    return !reg_exp.test(value);
  }

  /**
   * validate value if it is correct minutes value
   */
  validate_minutes_value(value: string) {
    let reg_exp = Validation.reg_exp.minutes;
    reg_exp.lastIndex = 0;
    return !reg_exp.test(value);
  }

  /**
   * validate value if it is correct volume value
   */
  validate_volume_value(value: string) {
    let reg_exp = Validation.reg_exp.volume;
    reg_exp.lastIndex = 0;
    return !reg_exp.test(value);
  }
}

export default new Validation();
