import authStore from '../../authentication';
import { Event } from '../../event';
import { UserAbsenceModel, UserAlarmHistoryModel, UserDetailsModel, UserModel } from '../../interfaces/backend_model';
import websocket from '../../websocket';
import alarmActiveStore from '../alarms/alarm_active_store_actions';
import settingsStore from '../settings_store';
import API from './api';

const constants = require('../../../json/constants.json');

class UserStore extends Event {
  users: UserModel[];
  userDetails: { [key: string]: UserDetailsModel } | {};
  userAbsenceDetails: { [key: string]: UserAbsenceModel } | {};
  userHistory: { [key: string]: UserAlarmHistoryModel[] } | {};

  forceStopRequestPending: boolean;

  userHistoryIntervalHandler: number;

  constructor() {
    super();

    this.getInitialData();

    this.forceStopRequestPending = false;
  }

  getInitialData() {
    this.users = [];
    this.userDetails = {};
    this.userAbsenceDetails = {};
    this.userHistory = {};
    this.userHistoryIntervalHandler = 0;
  }

  getUsers() {
    return this.users;
  }

  /**
   * Stop requests and display Service unavailable for the user.
   * Confirmation of the error will allow requests again.
   */
  stopRequestPending() {
    this.forceStopRequestPending = true;
  }

  /**
   * The user har confirmed the error message and requests are allowed again.
   */
  allowRequestPending() {
    this.forceStopRequestPending = false;
  }

  getUserDetails(user_id: number | string) {
    return this.userDetails[user_id];
  }

  clearUserDetails(user_id: number | string) {
    this.userDetails[user_id] = null;
  }

  getUserAbsenceDetails(user_id: number | string) {
    return this.userAbsenceDetails[user_id];
  }

  clearUserAbsenceDetails(user_id: number | string) {
    this.userAbsenceDetails[user_id] = null;
  }

  getUserHistory(user_id: number | string) {
    return this.userHistory[user_id];
  }

  deleteUserHistory(user_id: number | string) {
    console.log('delete user history', user_id, this.userHistory[user_id]);
    this.userHistory[user_id] = null;
  }

  getActiveUserId(): string {
    return alarmActiveStore?.getAlarmDetails(alarmActiveStore?.currentAlarmId)?.UserId;
  }

  loadUsers() {
    API.getUsers()
      .then(users => {
        this.users = users;
        this.trigger(constants.USERS_CHANGE);
      })
      .catch((err: any) => {
        if (err !== constants.REQUEST_BLOCKED) {
          // we want to distinguish between errors appearing on the first fetch and on any consecutive fetches
          if (Array.isArray(this.users) && this.users.length) {
            this.trigger(constants.USERS_SYNC_ERROR, err.statusText, err.status);
          } else {
            this.trigger(constants.USERS_SERVER_ERROR, err.statusText, err.status);
          }
        }
      });
  }

  loadUserDetails(userId: string, alarmId: string) {
    API.getUser(userId, alarmId || '')
      .then(user => {
        this.userDetails[userId] = user;
        this.trigger(constants.USER_DETAILS_CHANGE);
      })
      .catch((err: any) => {
        if (err !== constants.REQUEST_BLOCKED) {
          // we want to distinguish between errors appearing on the first fetch and on any consecutive fetches
          if (Array.isArray(this.users) && this.users.length) {
            this.trigger(constants.USER_DETAILS_SYNC_ERROR, err.statusText, err.status);
          } else {
            this.trigger(constants.USER_DETAILS_SERVER_ERROR, err.statusText, err.status);
          }
        }
      });
  }

  loadUserAbsenceDetails(userId: string, alarmId: string) {
    API.getUserAbsence(userId, alarmId)
      .then(userAbsence => {
        this.userAbsenceDetails[userId] = userAbsence;
        this.trigger(constants.USER_DETAILS_CHANGE);
      })
      .catch((err: any) => {
        if (err !== constants.REQUEST_BLOCKED) {
          // we want to distinguish between errors appearing on the first fetch and on any consecutive fetches
          if (Array.isArray(this.users) && this.users.length) {
            this.trigger(constants.USER_DETAILS_SYNC_ERROR, err.statusText, err.status);
          } else {
            this.trigger(constants.USER_DETAILS_SERVER_ERROR, err.statusText, err.status);
          }
        }
      });
  }

  loadUserHistory(userId: string, alarmId: string) {
    API.getUserHistory(userId, alarmId || '')
      .then(data => {
        this.userHistory[userId] = data;

        this.trigger(constants.USER_HISTORY_CHANGE);
      })
      .catch((err: any) => {
        if (err !== constants.REQUEST_BLOCKED) {
          // we want to distinguish between errors appearing on the first fetch and on any consecutive fetches
          if (Array.isArray(this.users) && this.users.length) {
            this.trigger(constants.USER_HISTORY_SYNC_ERROR, err.statusText, err.status);
          } else {
            this.trigger(constants.USER_HISTORY_SERVER_ERROR, err.statusText, err.status);
          }
        }
      });
  }

  subscribeForUserHistoryUpdates() {
    let setting_interval = settingsStore.getSettingInterval('ALARM_REFRESH_FAST');
    if (setting_interval !== null) {
      this.unSubscribeForUserHistoryUpdates();
      this.userHistoryIntervalHandler = window.setInterval(() => {
        const activeUser = this.getActiveUserId();
        if (authStore.getUserId() && !authStore.isSimpleLogin()) {
          if (activeUser) {
            websocket.checkWebSocket(this, this.loadUserHistory, activeUser)();
          }
        } else {
          console.warn('No user logged in with correct access, will not run \'loadUserHistory\'');
          this.unSubscribeForUserHistoryUpdates();
        }
      }, setting_interval);
    }
  }

  unSubscribeForUserHistoryUpdates() {
    clearInterval(this.userHistoryIntervalHandler);
  }
}

export default UserStore;
