import axios from 'axios';
import * as Logger from 'js-logger';
import React from 'react';
import Section from '../../components/section';
import { CurenaPersonalDetails, LinkedModel, Widget } from '../../interfaces/backend_model';
import localization from '../../localization';
import settingsStore from '../../stores/settings_store';
const jsonata = require('jsonata/jsonata-es5');
const constants = require('../../../json/constants.json');

interface PersonalDetailsSectionProps {
  template: string;
  readonly: boolean;
  instance: any;
  toggleState?: string;
  Linked: LinkedModel;
  widget: Widget;
}

interface SensitiveData {
  category: string;
  data: string;
}

const alarmT = localization.useNSt(constants.NS_ALARM);

export function changeVarsInString(data: string): string {
  const valueToGetFromSettings = data.slice(data.indexOf('{') + 1, data.indexOf('}'));

  const variableToSet = settingsStore.getValueByKey(valueToGetFromSettings, 'string') || '';

  var re = '${' + valueToGetFromSettings + '}';
  const newData = data.split(re).join(variableToSet);

  if (newData.indexOf('{') !== -1) {
    return changeVarsInString(newData);
  }
  return newData;
}

export default function PersonalDetailsSection(props: PersonalDetailsSectionProps) {
  const [personalDetails, setPersonalDetails] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(
    () => {
      const { Linked } = props;

      if (Linked) {
        loadCurenaPersonalDetails(Linked);
      }
    },
    [props.Linked]
  );

  const loadCurenaPersonalDetails = async (Linked: LinkedModel) => {
    let headerValue = '';
    if (Linked.HeaderValue) {
      headerValue = changeVarsInString(Linked.HeaderValue);
    }

    const response = await getDetailsData({
      URL: Linked.URL,
      Header: Linked.Header,
      HeaderValue: headerValue
    });

    try {
      const jsonDictionary = jsonata(props.Linked.DocumentFilter);
      const linkedResult = jsonDictionary.evaluate(response);
      setPersonalDetails(normalizeSensitiveData(linkedResult.sensetiveData));
    } catch (ex) {
      Logger.error(ex);
    }
    setIsLoading(false);

  };

  const getDetailsData = (data: CurenaPersonalDetails) => {
    let header = { 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*' };

    if (data.Header) {
      header[data.Header] = data.HeaderValue;
    }

    if (process.env.NODE_ENV !== 'production') {
      data.URL = `/v1/skyresponse/secure/customers/`;
    }

    return axios
      .get(data.URL, {
        headers: header
      })
      .then(response => {
        return response.data;
      })
      .catch((error: any) => {
        setIsLoading(false);
        setHasError(true);
        Logger.error(error);
      });
  };

  const normalizeSensitiveData = (data: SensitiveData[]) => {
    const normalizedData: any = [];

    if (data) {
      data.map((el: SensitiveData) => normalizedData.push({ label: el.category, value: el.data }));
    }
    return normalizedData;
  };

  const { toggleState, Linked, widget } = props;

  if (!Linked || !Linked.DocumentFilter) {
    return null;
  }

  const getContent = () => {
    if (isLoading) {
      return <div className="flex-col-start item-ver">{alarmT('LOADING_DATA')}</div>;
    }
    if (hasError) {
      return <div className="flex-col-start item-ver">{alarmT('FAILED_LOAD_DATA')}</div>;
    }
    return personalDetails;
  };

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={constants.ENABLED_TOGGLE}
      toggleState={toggleState ? toggleState : constants.OPENED}
      headerLocKey={alarmT('PERSONAL_DETAILS_SECTION')}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      {getContent()}
    </Section>
  );
}
