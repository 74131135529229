import React from 'react';
import localization from '../localization';
import settingsStore from '../stores/settings_store';
import { Popup } from './popups/popup';

interface Props {
  onClick: any;
  show: boolean;
  message?: string;
  resource: any;
  selectedNumber: string;
}

interface State {
  message: string;
  selectedNumber: string;
  availableAmountOfCharacters: number;
  availablePhoneNumbers: { value: string; text: string }[];
}

const smsMaxLength = 500;

export class SendMessagePopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      message: props.message || this.getDefaultMessage(),
      selectedNumber: props.selectedNumber,
      availableAmountOfCharacters: smsMaxLength,
      availablePhoneNumbers: []
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onSelectNumber = this.onSelectNumber.bind(this);
  }

  componentDidMount() {
    const { Telephone1, Telephone2, Telephone3, Name, IsResource } = this.props.resource;
    const phoneList = [Telephone1, Telephone2, Telephone3]
      .filter(el => !!el)
      .map(el => ({
        value: el,
        text: `${Name} / ${IsResource
            ? localization.t('Send_SMS_Modal_Resource')
            : localization.t('Send_SMS_Modal_User')
          } / ${el}`
      }));
    this.setState({
      availableAmountOfCharacters: smsMaxLength - this.state.message.length,
      availablePhoneNumbers: phoneList
    });
  }

  getDefaultMessage() {
    return `\n\n\n\n${localization.t('Send_SMS_Modal_Cannot_Reply')}`;
  }

  onSave(e: React.MouseEvent<HTMLButtonElement>) {
    const { message, selectedNumber } = this.state;

    this.props.onClick(e, {
      message,
      telephone: selectedNumber
    });

    this.clearData();
  }

  onCancel(e: React.MouseEvent<HTMLButtonElement>) {
    this.props.onClick(e);
    this.clearData();
  }

  clearData() {
    let message = this.getDefaultMessage();
    let availableAmountOfCharacters = smsMaxLength - message.length;
    this.setState({ message, availableAmountOfCharacters });
  }

  onMessageChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    let message = e.target.value;
    let availableAmountOfCharacters = smsMaxLength - message.length;

    if (availableAmountOfCharacters < 0) {
      message = message.slice(0, smsMaxLength);
      availableAmountOfCharacters = 0;
    }

    this.setState({ message, availableAmountOfCharacters });
  }

  onSelectNumber(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ selectedNumber: e.target.value });
  }

  getContent() {
    const modalHeader = this.props.resource.IsResource
      ? `${localization.t('Send_SMS_Modal_Header')} ${localization.t('Send_SMS_Modal_Resource')}`
      : `${localization.t('Send_SMS_Modal_Header')} ${localization.t('Send_SMS_Modal_User')}`;

    const selectOptions = this.state.availablePhoneNumbers.map(({ value, text }, index) => (
      <option key={value + index} value={value}>
        {text}
      </option>
    ));

    return (
      <div className="flex-container sendSms">
        <div className="flex title">
          <h3 className="left w-100p">{modalHeader}</h3>
        </div>

        <form className="flex-col-around">
          <div className="flex-row-wrap">
            <div className="form-field-group">
              <label htmlFor="sms-from">{localization.t('Send_SMS_Modal_From')}</label>
              <input
                id="sms-from"
                disabled
                value={settingsStore.me.RcDisplayName || settingsStore.me.RcName}
                type="text"
              />
            </div>

            <div className="form-field-group">
              <label htmlFor="selected-sms-number">{localization.t('Send_SMS_Modal_To_Name')}</label>
              {!this.state.availablePhoneNumbers.length ? (
                <>
                  {this.props.selectedNumber}
                </>
              ) : (
                <select
                  id="selected-sms-number"
                  value={this.state.selectedNumber}
                  disabled={this.state.availablePhoneNumbers.length === 1}
                  onChange={this.onSelectNumber}
                >
                  {selectOptions}
                </select>
              )}
            </div>
          </div>

          <div className="flex-row-wrap">
            <div className="form-field-group message">
              <label htmlFor="message-area">{localization.t('Send_SMS_Modal_Message')}</label>
              <textarea
                id="message-area"
                className="message-field"
                value={this.state.message}
                onChange={this.onMessageChange}
              />
              <span className="characters-counter">
                {this.state.availableAmountOfCharacters}/500
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { show } = this.props;

    const customFooterContent = (
      <div className="footer-button-block">
        <button
          className="form-btn-bg-dark-text-white margin-0-2-0-05"
          type="button"
          data-click="confirm"
          onClick={this.onSave}
        >
          {localization.t('Send_SMS_Modal_Button_Send_SMS')}
        </button>
        <button
          className="form-btn-transparent-text-blue"
          type="button"
          data-click="close"
          onClick={this.onCancel}
        >
          {localization.t('CANCEL')}
        </button>
      </div>
    );

    return (
      <Popup
        show={show}
        body={{ className: 'f-15 bold', content: this.getContent() }}
        footer={{ className: 'border-none align-content-left', content: customFooterContent }}
      />
    );
  }
}
