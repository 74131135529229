import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import authStore from '../authentication';
import { Event } from '../event';
import { GroupsModel, MeModel } from '../interfaces/backend_model';
import Utils from '../utils';
import planningViewStore from './alarms/planning_view_store_actions';
import settingsStore from './settings_store';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

class GroupsStore extends Event {
  groups: GroupsModel[] | [];
  groupDetails: { [key: number]: MeModel[] };
  groupUsers: MeModel[];
  groupsUpdateHandler: number;
  respondentGroupUpdateHandler: number;
  constructor() {
    super();
    this.groups = [];
    this.groupDetails = {};
    this.groupUsers = [];
    this.groupsUpdateHandler = 0;
    this.respondentGroupUpdateHandler = 0;
  }

  getInitialData() {
    /**
     * store list groups
     */
    this.groups = [];
    /**
     * store detail information about each respondent group
     */
    this.groupDetails = {};
    /**
     * Store all users from all groups
     * @type {Array}
     */
    this.groupUsers = [];
  }

  getGroups() {
    return this.groups;
  }

  getGroupsUsers() {
    return this.groupUsers;
  }

  getGroupsPlanningViewSetting() {
    return this.groups.find(group => group.UsingPlanningView && group.Selected);
  }

  getUserByTwilioId(twilioId: number | string) {
    return this.getGroupsUsers().find(user => user.TwilioUserName === twilioId);
  }

  isLoadGroupsSuccess(err: string | null, xhr: XMLHttpRequest, data: GroupsModel[]) {
    return Utils.isNotArrayError(
      this.groups,
      constants.RESPONDENT_GROUPS_SERVER_ERROR,
      constants.RESPONDENT_GROUPS_SYNC_ERROR,
      constants.RESPONDENT_GROUPS_SYNC_SUCCESS,
      err,
      xhr,
      data
    );
  }

  loadGroups() {
    const wasUsingPlanningView = this.getGroupsPlanningViewSetting();
    ajax.getByDesc(
      false,
      endpoints.RESPONDENT_GROUPS,
      (err: string | null, xhr: XMLHttpRequest, data: GroupsModel[]) => {
        if (this.isLoadGroupsSuccess(err, xhr, data)) {
          this.groups = data;
          this.trigger(constants.RESPONDENT_GROUPS_CHANGE);
          const shouldUsePlanningView = this.getGroupsPlanningViewSetting();
          if (
            !planningViewStore.getPlannedAlarms().length &&
            !wasUsingPlanningView &&
            shouldUsePlanningView &&
            settingsStore.getValueByKey('USING_PLANNING_VIEW', 'boolean')
          ) {
            planningViewStore.loadPlannedAlarms();
          }
        }
      }
    );
  }

  setSelectedGroups(groups_ids: string | number) {
    ajax.postByDesc(
      false,
      endpoints.SET_RESPONDENT_GROUPS,
      { groups_ids },
      (err: string | null, xhr: XMLHttpRequest, data: any) => {
        if (err) {
          this.trigger(constants.SET_RESPONDENT_GROUPS_SERVER_ERROR, err, xhr);
        } else if (!data) {
          this.trigger(constants.SET_RESPONDENT_GROUPS_SERVER_ERROR, !data, xhr);
        } else {
          this.loadGroups();
          appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_ALARMS });
        }
      }
    );
  }

  subscribeForGroupsUpdates() {
    let setting_interval = settingsStore.getSettingInterval('RESPONDENT_REFRESH');
    if (setting_interval !== null) {
      this.unSubscribeForGroupsUpdates();
      this.groupsUpdateHandler = window.setInterval(this.loadGroups.bind(this), setting_interval);
    }
  }

  unSubscribeForGroupsUpdates() {
    clearInterval(this.groupsUpdateHandler);
  }

  isLoadRespondentGroupDetailsSuccess(
    respondent_group_id: string,
    err: string | null,
    xhr: XMLHttpRequest,
    data: MeModel[]
  ) {
    return Utils.isNotObjError(
      this.groupDetails[respondent_group_id],
      constants.RESPONDENT_GROUP_DETAILS_LOAD_ERROR,
      constants.RESPONDENT_GROUP_DETAILS_SYNC_ERROR,
      constants.RESPONDENT_GROUP_DETAILS_SYNC_SUCCESS,
      err,
      xhr,
      data
    );
  }

  loadRespondentGroupDetails(respondent_group_id: string) {
    ajax.getByDescWithData(
      false,
      endpoints.RESPONDENT_GROUP_DETAILS,
      { respondent_group_id },
      (err: string | null, xhr: XMLHttpRequest, data: MeModel[]) => {
        if (this.isLoadRespondentGroupDetailsSuccess(respondent_group_id, err, xhr, data)) {
          this.groupDetails[respondent_group_id] = data;

          // Decouple users from all groups
          // Reset groups users
          this.groupUsers = [];
          // Cycle throught all groups
          Object.keys(this.groupDetails)
            .map(key => this.groupDetails[key])
            .forEach(users => {
              // Since it [[]] we need to check it
              if (Array.isArray(users)) {
                // Add all users
                users.forEach(user => this.groupUsers.push(user));
              }
            });
          this.trigger(constants.RESPONDENT_GROUP_DETAILS_CHANGE);
        }
      }
    );
  }

  subscribeForRespondentGroupDetailsUpdates(respondent_group_id: string) {
    let setting_interval = settingsStore.getSettingInterval('RESPONDENT_REFRESH');
    if (setting_interval !== null) {
      this.unSubscribeForRespondentGroupDetailsUpdates();
      this.respondentGroupUpdateHandler = window.setInterval(
        this.loadRespondentGroupDetails.bind(this, respondent_group_id),
        setting_interval
      );
    }
  }

  unSubscribeForRespondentGroupDetailsUpdates() {
    clearInterval(this.respondentGroupUpdateHandler);
  }

  getRespondentGroupDetails(respondent_group_id: string) {
    return this.groupDetails[respondent_group_id];
  }
}

export interface ActionModel {
  type: string;
  groups_ids?: string | number;
  respondent_group_id?: string;
}

const groupsStore = new GroupsStore();
appDispatcher.register((payload: { action: ActionModel; source?: string }) => {
  let action = payload.action;

  switch (action.type) {
    case constants.LOAD_SERVER_RESPONDENT_GROUPS:
      groupsStore.loadGroups();
      break;
    case constants.SET_SERVER_RESPONDENT_GROUPS:
      action.groups_ids && groupsStore.setSelectedGroups(action.groups_ids);
      break;
    case constants.START_UPDATING_SERVER_RESPONDENT_GROUPS:
      groupsStore.subscribeForGroupsUpdates();
      break;
    case constants.STOP_UPDATING_SERVER_RESPONDENT_GROUPS:
      groupsStore.unSubscribeForGroupsUpdates();
      break;
    case constants.LOGOUT_CLEAR:
      groupsStore.getInitialData();
      break;
    case constants.LOAD_RESPONDENT_GROUP_DETAILS:
      if (action.respondent_group_id && !authStore.isSimpleLogin()) {
        groupsStore.loadRespondentGroupDetails(action.respondent_group_id);
      }
      break;
    case constants.START_UPDATING_RESPONDENT_GROUP_DETAILS:
      if (action.respondent_group_id) {
        groupsStore.subscribeForRespondentGroupDetailsUpdates(action.respondent_group_id);
      }
      break;
    case constants.STOP_UPDATING_RESPONDENT_GROUP_DETAILS:
      groupsStore.unSubscribeForRespondentGroupDetailsUpdates();
      break;
  }
});

export default groupsStore;
