import { IObservableArray } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import { MeModel, SettingModel } from '../interfaces/backend_model';
import localization from '../localization';
import { default as SettingsStore, default as settingsStore } from '../stores/settings_store';
import { AdminURL } from './admin_token';
import IconClose from './icon_close';
import IconMenuCircle from './icon_menu_circle';
import Status from './status';

const constants = require('../../json/constants.json');

interface Props {
  permissions?: { getMap: Function; list: IObservableArray<string> };
  mobile?: boolean;
  handleLanguageChange: Function;
  onLogoutClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  me?: MeModel;
}

interface State {
  toggleState: string;
  settings: SettingModel[];
  me: MeModel;
}

@inject('permissions')
@observer
export default class MenuPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggleState: constants.CLOSED,
      settings: settingsStore.getSettings(),
      me: settingsStore.getMe()
    };
  }

  handlePanelToggle = (loc?: string) => {
    let toggleState = constants.CLOSED;
    switch (this.state.toggleState) {
      case constants.CLOSED:
        toggleState = constants.OPENED;
        break;
    }

    this.setState({ toggleState });
    if (loc) {
      const el = document.getElementById(loc as string) as any;
      if (el) {
        el.click();
      }
    }
  };

  renderLink = (to: string, loc: string) => {
    return (
      <div className="p-025em flex-center" key={to}>
        <Link
          onClick={() => this.handlePanelToggle(loc)}
          to={to}
          activeClassName="active"
          className="nav-link p-05em"
        >
          {localization.t(loc)}
        </Link>
      </div>
    );
  };

  renderPermissionLink = (permission: string) => {
    const map = this.props.permissions!.getMap();
    switch (permission) {
      case map.skygdSystemAdmin:
        return this.renderLink('/dashboard/admin/support', 'SUPPORT');
      case map.centerTestalarm:
        return this.renderLink('/dashboard/admin/test', 'TEST_ALARMS');
      default:
        return null;
    }
  };

  render() {
    const { toggleState, settings, me } = this.state;
    const { permissions, mobile } = this.props;
    const isPlanningViewEnable =
      !mobile && SettingsStore.getValueByKey('USING_PLANNING_VIEW', 'boolean');

    const useOldAdmin: boolean = SettingsStore.getValueByKey('UseOldAdmin', 'boolean');

    let panelClassName =
      'flex-col panel p-menu left ' + (toggleState === constants.CLOSED ? 'out' : 'in');

    return (
      <div className="flex-0-0-auto flex-col-around donotprint">
        <button
          type="button"
          className="form-btn-transparent"
          data-cy="openMenuButton"
          onClick={() => this.handlePanelToggle()}
        >
          <IconMenuCircle />
        </button>
        <div className={panelClassName}>
          <button
            type="button"
            className="form-btn-transparent"
            onClick={() => this.handlePanelToggle()}
          >
            <IconClose />
          </button>
          <div className="flex-col-around flex-0-0-auto" data-cy="pMenu">
            <AdminURL
              settings={settings}
              me={me}
              path={useOldAdmin ? 'users' : 'objects'}
              loc_id="ADMINISTRATION"
              onClick={this.handlePanelToggle}
            />
            <AdminURL
              settings={settings}
              me={me}
              path={
                useOldAdmin
                  ? `admins/view?id=${me.RemoteId}`
                  : me.IdentityId
                  ? `identity/${me.IdentityId}`
                  : `administrators/${me.RemoteId}`
              }
              loc_id="MY_DETAILS"
              onClick={this.handlePanelToggle}
            />
            {!mobile && this.renderLink('/dashboard/groups', 'GROUPS')}
            {!mobile && this.renderLink('/dashboard/settings', 'SETTINGS')}
            {isPlanningViewEnable && this.renderLink('/dashboard/planning-view', 'PLANNING_VIEW')}
            {this.renderLink('/dashboard/users/search', 'SEARCH_USER')}
            {!mobile && permissions!.list.map(this.renderPermissionLink)}
          </div>
          <Status
            // handleLanguageChange={this.props.handleLanguageChange}
            onLogoutClick={this.props.onLogoutClick}
          />
        </div>
      </div>
    );
  }
}
