import React from 'react';
import Popup from 'reactjs-popup';
import localization from '../../../js/localization';
import './groupedButtons.css';

const constants = require('../../../json/constants.json');
const alarmT = localization.useNSt(constants.NS_ALARM);

export interface GroupedButtonsProps {
    /** PopupPosition from reactjs-popup */
    position?:
    | "top left"
    | "top center"
    | "top right"
    | "right top"
    | "right center"
    | "right bottom"
    | "bottom left"
    | "bottom center"
    | "bottom right"
    | "left top"
    | "left center"
    | "left bottom"
    | "center center";

    /** Function that returns element that triggers tooltip.
     * Note: if doesn't work for custom component, then try to wrap in div, for instance
     */
    trigger?: () => JSX.Element;
    groupedButtons?: JSX.Element[];
}

const defaultTrigger = (buttonText: string): JSX.Element => {
    return (<button className="button-small-secondary"> {buttonText} </button>);
}

const GroupedButtons = ({
    position = 'bottom left',
    trigger = () => { return defaultTrigger(alarmT('IN_CALL_COMMANDS_MORE')) },
    groupedButtons = [],
}: GroupedButtonsProps) => {

    return (
        <Popup
            trigger={() => trigger()}
            position={position}
            nested
            keepTooltipInside
        >
            <span className='flex-row-wrap'>
                {groupedButtons}
            </span>
        </Popup>
    );
}
export default GroupedButtons;
