import { format } from 'date-fns';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import moment from 'moment';
import React, { ReactElement, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import settingsStore from '../../../js/stores/settings_store';
import { sendCommand } from '../../actions/alarm';
import { Spacer } from '../../components/spacer';
import { useEscapeKey } from '../../hooks';
import { AlarmDetailsModel, CommandModel } from '../../interfaces/backend_model';
import localization from '../../localization';
import alarmActiveStore from '../../stores/alarms/alarm_active_store_actions';
import { isCommandUsed, setCommandsState } from '../../utilities/commandButtonStateManager';
import { useForceUpdate } from '../../utilities/forceUpdate';

const constants = require('../../../json/constants.json');
const alarmT = localization.useNSt(constants.NS_ALARM);

interface CommandButtonDialogProps {
  isLoading: boolean;
  errorMessage?: string;
  type: CommandInputType;
  onClose(): void;
  onSave(text: string, texts?: any, props?: CommandButtonDialogProps): void;
  predefinedValue?: string;
  alarmDetails?: AlarmDetailsModel;
  data?: any;
}

function CommandButtonDialog(props: CommandButtonDialogProps) {
  const textValue =
    props.type === CommandInputType.MandatorySelect ? localization.t('NOT_SELECTED') : '';
  const [text, setText] = React.useState(props.predefinedValue || (textValue as string));
  const [emergencyLevel, setEmergencyLevel] = React.useState('2');
  const productsList = text.split(',').map(p => p.trim());
  if (
    props.data &&
    !props.data.find((option: string) => option === localization.t('NOT_SELECTED'))
  ) {
    props.data.unshift(localization.t('NOT_SELECTED'));
  }
  const productsObjects = productsList.map(productStr => {
    return { key: productStr, value: productStr };
  });

  let [products, setProducts] = React.useState(productsObjects);
  const [error, setError] = React.useState('');
  const [startDate, setStartDate] = React.useState<Date>();
  const forceUpdate = useForceUpdate();
  const dateFormat = settingsStore.getDateFormat().toUpperCase();
  useEscapeKey(props.onClose);

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    props.onSave(text);
  }

  useEffect(() => {
    if (props.type === CommandInputType.WorkPackageForwarding) {
      setText(props.alarmDetails?.TextMessage || '');
    }
  }, []);

  // disables save button if the conditions are not met
  function isSaveButtonDisabled() {
    if (props.isLoading) {
      return true;
    }
    if (
      props.type === CommandInputType.MandatoryInput ||
      props.type === CommandInputType.MandatoryText
    ) {
      return text.length === 0;
    }
    if (
      props.type === CommandInputType.MandatoryPicker ||
      props.type === CommandInputType.MandatoryDateTimePicker
    ) {
      return !startDate;
    }
    return false;
  }
  let buttons = [
    <FlatButton
      label={localization.t('OK')}
      disabled={isSaveButtonDisabled() || props.isLoading}
      primary={true}
      autoFocus={props.type === CommandInputType.ConfirmWithOK}
      onClick={onClick}
    />,
    <FlatButton
      label={localization.t('CLOSE')}
      primary={false}
      disabled={props.isLoading}
      onClick={() => props.onClose()}
    />
  ];

  if (props.type === CommandInputType.WorkPackageForwarding) {
    buttons = [
      <button
        style={{ float: 'left' }}
        type="button"
        disabled={props.isLoading}
        className="button-small-blue"
        onClick={props.onClose}
      >
        {localization.t('CANCEL')}
      </button>,
      <button
        type="button"
        disabled={props.isLoading}
        className="button-small-blue"
        onClick={onClick}
      >
        {localization.t('FORWARD_ALARM')}
      </button>,
    ]
  }

  if (props.type === CommandInputType.MandatorySelect) {
    buttons.unshift(
      <FlatButton
        className="left-button"
        label={localization.t('ADD_MORE')}
        primary={false}
        onClick={addMore}
      />
    );
  }

  const handleEmergencyLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmergencyLevel(event.target.value);
  };

  let content: JSX.Element | null = null;
  let modalTitle = alarmT('COMMAND_USER_MODAL_TITLE');
  let contentStyle: any = {};

  switch (props.type) {
    case CommandInputType.ConfirmWithOK:
      content = null;
      modalTitle = alarmT('COMMAND_USER_MODAL_WITH_OK');
      break;
    case CommandInputType.OptionalText:
    case CommandInputType.OptionalInput:
      content = (
        <form onSubmit={onSubmit} autoComplete="off">
          <TextField
            multiLine={true}
            rows={2}
            autoFocus
            style={{ width: '600px' }}
            value={text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length <= 1000) {
                setText(event.target.value);
                setError('');
              } else {
                setError(alarmT('COMMAND_USER_MODAL_INPUT_VALIDATION_ERROR'));
              }
            }}
            floatingLabelText={alarmT('COMMAND_USER_MODAL_INPUT_LABEL')}
            errorText={error}
            autoComplete="off"
          />
        </form>
      );
      break;
    case CommandInputType.MandatoryText:
    case CommandInputType.MandatoryInput:
      content = (
        <form onSubmit={text.length === 0 ? undefined : onSubmit}>
          <TextField
            multiLine={true}
            rows={2}
            autoFocus
            style={{ width: '600px' }}
            value={text}
            required={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length <= 1000) {
                setText(event.target.value);
                setError('');
              } else {
                setError(alarmT('COMMAND_USER_MODAL_INPUT_VALIDATION_ERROR'));
              }
            }}
            floatingLabelText={alarmT('COMMAND_USER_MODAL_INPUT_LABEL')}
            errorText={error}
            autoComplete="off"
          />
        </form>
      );
      break;
    case CommandInputType.OptionalPicker:
    case CommandInputType.OptionalDateTimePicker:
    case CommandInputType.MandatoryPicker:
    case CommandInputType.MandatoryDateTimePicker:
      modalTitle = alarmT('COMMAND_USER_MODAL_DATE_TIME_PICKER');

      content = (
        <div className="scheduled-picker p-t-b-05em flex-row ">
          <div className="date-time-picker">
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              showTimeSelect
              dateFormat={settingsStore.getDateFormat()}
              locale={localization.getLanguage()}
              timeIntervals={15}
              timeCaption={localization.t('TIME')}
              timeFormat="HH:mm"
              placeholderText={alarmT('COMMAND_USER_MODAL_DATE_TIME_INPUT_LABEL')}
            />
          </div>
        </div>
      );
      break;
    // renders a modal with the alarm info and a form to forward the alarm
    case CommandInputType.WorkPackageForwarding:
      contentStyle = { width: '500px' };
      const reason = alarmActiveStore.getAlarmReason(props.alarmDetails?.AlarmId);
      modalTitle = (
        <div className='forward-package-header'>{alarmT('ALARM_FORWARDING_MODAL_TITLE')}</div>
      );
      content = (
        <form onSubmit={onSubmit} autoComplete="off" className='forward-package-content'>
          <div className='data-columns'>
            <div className='data-row'>
              <div className='title-column'>{alarmT('TYPE')}:</div>
              <div className='data-column'>
                <div className='d-flex'>
                  <span>{props.alarmDetails?.AlarmText}</span>
                  <Spacer size={16} />
                  <span>{moment(props.alarmDetails?.ServerAlarmTime).format(`HH:mm:ss, ${dateFormat}`)}</span>
                </div>
              </div>
            </div>
            {reason && (
              <div className='data-row'>
                <div className='title-column'>{alarmT('REASON')}:</div>
                <div className='data-column'>{reason.LogText}</div>
              </div>
            )}
            <div className='data-row'>
              <div className='title-column'>{alarmT('MESSAGE')}:</div>
              <div className='data-column'>
                <textarea
                  style={{ width: '100%', height: '100px' }}
                  onChange={(event) => {
                    if (event.target.value.length <= 1000) {
                      setText(event.target.value);
                      setError('');
                    } else {
                      setError(alarmT('COMMAND_USER_MODAL_INPUT_VALIDATION_ERROR'));
                    }
                  }}
                  value={text}
                />
              </div>
            </div>
            <div className='data-row'>
              <div className='title-column'>{alarmT('EMERGENCY_LEVEL')}:</div>
              <div className='data-column'>
                <div>
                  <input
                    className='emergency-level'
                    type='radio'
                    id='high'
                    name={alarmT('HIGH_LEVEL_EMERGENCY')}
                    value='3'
                    checked={emergencyLevel === '3'}
                    onChange={handleEmergencyLevelChange}
                  />
                  <label className='level-emergency high-level-emergency' htmlFor='high'>
                    {alarmT('HIGH_LEVEL_EMERGENCY')}
                  </label>
                  <span className='dot-emergency high-dot-emergency' />
                </div>

                <div>
                  <input
                    className='emergency-level'
                    type='radio'
                    id='mid'
                    name={alarmT('MID_LEVEL_EMERGENCY')}
                    value='2'
                    checked={emergencyLevel === '2'}
                    onChange={handleEmergencyLevelChange}
                  />
                  <label className='level-emergency mid-level-emergency' htmlFor='mid'>
                    {alarmT('MID_LEVEL_EMERGENCY')}
                  </label>
                  <span className='dot-emergency mid-dot-emergency' />
                </div>

                <div>
                  <input
                    className='emergency-level'
                    type='radio'
                    id='low'
                    name={alarmT('LOW_LEVEL_EMERGENCY')}
                    value='1'
                    checked={emergencyLevel === '1'}
                    onChange={handleEmergencyLevelChange}
                  />
                  <label className='level-emergency low-level-emergency' htmlFor='low'>
                    {alarmT('LOW_LEVEL_EMERGENCY')}
                  </label>
                  <span className='dot-emergency low-dot-emergency' />
                </div>
              </div>
            </div>
            {props.errorMessage && (
              <div className='data-row'>
                <div className='title-column' />
                <div className='data-column'>
                  <div className='tellu-error-message-box'>
                    <div className='tellu-error-header'>{alarmT('TELLU-WARNING')}</div>
                    <div>{props.errorMessage}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      );
      break;
    case CommandInputType.MandatorySelect:
      modalTitle = localization.t('PLANNING_VIEW_PRODUCT_SELECTION_MESSAGE');

      content = (
        <div className="products-dialog">
          <form onSubmit={onSubmit} autoComplete="off">
            {products.map((product, i) => {
              return (
                <div className="select-container">
                  <select
                    className="select-input"
                    value={product.value}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      if (event.target.value.length <= 1000) {
                        if (products[i]) {
                          products[i] = { key: event.target.value, value: event.target.value };
                        } else {
                          products.push({ key: event.target.value, value: event.target.value });
                        }

                        setProducts(products);
                        forceUpdate();
                        setError('');
                      } else {
                        setError(alarmT('COMMAND_USER_MODAL_INPUT_VALIDATION_ERROR'));
                      }
                    }}
                  >
                    {props.data.map((option: string) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <span
                    style={{ cursor: 'pointer', fontFamily: 'Segoe UI Symbol' }}
                    onClick={() => removeProduct(i)}
                  >
                    &#10060;
                  </span>
                </div>
              );
            })}
          </form>
        </div>
      );
      break;
    default:
      break;
  }

  function removeProduct(index: number) {
    if (products && products.length > 1) {
      products.splice(index, 1);
      setProducts(products);
      forceUpdate();
    }
  }

  function addMore(e: React.MouseEvent<HTMLButtonElement>) {
    products.push({ key: localization.t('NOT_SELECTED'), value: localization.t('NOT_SELECTED') });
    setProducts(products);
    forceUpdate();
  }

  function onClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const message = getDate();

    switch (props.type) {
      case CommandInputType.OptionalPicker:
      case CommandInputType.OptionalDateTimePicker:
        props.onSave(message);
        break;
      case CommandInputType.MandatoryPicker:
      case CommandInputType.MandatoryDateTimePicker:
        // send dateTime to endpoint
        message && props.onSave(message);
        break;
      case CommandInputType.WorkPackageForwarding:
        props.onSave(`${emergencyLevel} - ${text}`, products, props);
        break;
      default:
        // send text from input to endpoint
        props.onSave(text, products, props);
        break;
    }
  }

  function getDate() {
    if (!startDate) {
      return '';
    }
    return format(startDate, 'yyyy-MM-dd HH:mm:ss');
  }

  return (
    <Dialog
      title={modalTitle}
      actions={buttons}
      modal={true}
      open={true}
      contentStyle={contentStyle}
      contentClassName={props.type === CommandInputType.WorkPackageForwarding ? 'forward-package-content' : ''}
      actionsContainerClassName={props.type === CommandInputType.WorkPackageForwarding ? 'forward-package-actions' : ''}
    >
      {content}
    </Dialog>
  );
}

enum CommandInputType {
  NoModal = 0,
  ConfirmWithOK = 1,
  OptionalText = 2,
  MandatoryText = 3,
  OptionalInput = 4,
  MandatoryInput = 5,
  OptionalPicker = 6,
  OptionalDateTimePicker = 7,
  MandatoryPicker = 8,
  MandatoryDateTimePicker = 9,
  WorkPackageForwarding = 10,
  MandatorySelect = 100
}

interface CommandButtonProps {
  command: CommandModel;
  alarmDetails?: AlarmDetailsModel;
  alarmId: string;
  className?: string;
  buttonClassName?: string;
  saveFunction?: (message: string) => void;
  elementToDisplay?: ReactElement;
  commandDialogExtraProps?: object;
  dropdown: any | null;
  changeStateOnAction?: boolean;
  commandNameToSave?: string;
}

export function CommandButton(props: CommandButtonProps) {
  const {
    command,
    saveFunction,
    elementToDisplay,
    commandDialogExtraProps,
    alarmDetails,
    commandNameToSave = '',
  } = props;
  const [isModalShowed, showModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [commandInProgress, setCommandInProgress] = React.useState(false);
  const commonT = localization.useNSt(constants.NS_DEFAULT);
  const userName = localStorage.getItem('user_id');

  function onCommandClick(event: React.FormEvent) {
    event.preventDefault();

    switch (command.InputType) {
      case CommandInputType.NoModal:
        setCommandInProgress(true);
        sendCommand(props.alarmId, command.CommandId!, command.TriggerArgs!, undefined, command.Id);

        // if the button is form a dropdown, close it
        if (props.dropdown != null) {
          props.dropdown.setState({ opened: false });
        }
        setCommandsState(userName!, props.alarmId, commandNameToSave || `${command.Id}`);
        break;
      default:
        showModal(true);
        break;
    }
  }

  const onError = (name: string, message: string) => {
    setErrorMessage(message);
    setIsLoading(false);
    setCommandInProgress(false);
  };

  const onSuccess = () => {
    setIsLoading(false);
    setCommandInProgress(false);
    showModal(false);
  }

  useEffect(() => {
    setCommandInProgress(false);
  }, [alarmActiveStore.commandActionFinishTrigger]);

  useEffect(() => {
    alarmActiveStore.on(constants.ALARM_COMMAND_ERROR_MESSAGE, onError);
    alarmActiveStore.on(constants.ALARM_COMMAND_SUCCESS, onSuccess);
    return () => {
      alarmActiveStore.off(constants.ALARM_COMMAND_ERROR_MESSAGE, onError);
      alarmActiveStore.off(constants.ALARM_COMMAND_SUCCESS, onSuccess);
    }
  }, []);

  function onSave(message: string, messages?: any[], dialogProps?: CommandButtonDialogProps) {
    setIsLoading(true);
    setCommandInProgress(true);
    if (messages && dialogProps && dialogProps.type === CommandInputType.MandatorySelect) {
      messages = messages.filter(m => m.key !== localization.t('NOT_SELECTED'));
      message = messages.map(m => m.value.trim()).join(',');
    }
    const encodedMessage = encodeURIComponent(message);
    if (saveFunction) {
      saveFunction(encodedMessage);
    } else {
      sendCommand(props.alarmId, command.CommandId!, command.TriggerArgs!, encodedMessage, command.Id);

      // if the button is form a dropdown, close it
      if (props.dropdown != null) {
        props.dropdown.setState({ opened: false });
      }
    }
    if (command.InputType !== CommandInputType.WorkPackageForwarding) {
      showModal(false);
    }
    setCommandsState(userName!, alarmDetails?.AlarmId!, `${command.Id}`);
  }

  let isUsed = '';
  if (props.changeStateOnAction) {
    isUsed = isCommandUsed(userName!, alarmDetails?.AlarmId!, `${command.Id}`) ? ' used-command' : '';
  }

  return (
    <>
      <div className='flex-row-center'>
        <div className='flex-col-around' style={{ marginRight: '12px', marginBottom: '8px' }}>
          {commandInProgress || isLoading ? (
            <button
              className='button-small-loading w-max-content'
              onClick={onCommandClick}
              disabled={true}
            >
              {commonT('LOADING')}
            </button>
          ) : (
            <>
              {elementToDisplay ? (
                <button
                  className={(props.buttonClassName ? props.buttonClassName : 'button-small-secondary') + ' w-max-content' + isUsed}
                  onClick={onCommandClick}
                  disabled={isLoading}
                >
                  {elementToDisplay}
                </button>
              ) : (
                <button
                  className={(props.buttonClassName ? props.buttonClassName : 'button-small-blue') + ' w-max-content' + isUsed}
                  onClick={onCommandClick}
                  disabled={isLoading}
                >
                  {command.TriggerIdentifier}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {isModalShowed ? (
        <CommandButtonDialog
          type={command.InputType}
          onSave={onSave}
          onClose={() => showModal(false)}
          alarmDetails={alarmDetails}
          {...commandDialogExtraProps}
          isLoading={isLoading}
          errorMessage={errorMessage}
        />
      ) : null}
    </>
  );
}
