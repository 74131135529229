export const isEqual = (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
        let obj1 = arr1[i];
        let obj2 = arr2[i];

        for (let key in obj1) {
            if (obj1[key] !== obj2[key]) return false;
        }

        for (let key in obj2) {
            if (obj1[key] !== obj2[key]) return false;
        }
    }

    return true;
}