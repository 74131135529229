import * as Logger from 'js-logger';
import React from 'react';
import Section from '../../components/section';
import date_time from '../../date_time';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { MeasurmentModel, Widget } from '../../interfaces/backend_model';
import dictionariesStore from '../../stores/dictionaries_store';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

const constants = require('../../../json/constants.json');

interface ProductMeasurementsSectionProps {
  toggleType?: string;
  toggleState?: string;
  fullscreenMode?: boolean;
  productMeasurements: MeasurmentModel[];
  widget: Widget;
}

function formatEnum(key: string, value?: number) {
  const dictionary = dictionariesStore.getLocalUserMeasurmentsDictionary();
  const keyValue = value ? key + value : key;

  if (!dictionary) {
    Logger.error('Measurments dictionary missing!');
    return keyValue;
  }

  const item = dictionary[keyValue];
  return item ? item : keyValue;
}

function ProductMeasurementsWidget(props: ProductMeasurementsSectionProps) {
  const { toggleType, toggleState, productMeasurements, widget, fullscreenMode } = props;

  const renderRows = (measurment: MeasurmentModel, index: number) => {
    const dateAndTime = date_time.toLocaleDateTime(measurment.RecordedAt);
    let value;

    switch (measurment.DataType) {
      case 'timestamp':
        value = date_time.toLocaleDateTime(measurment.Value);
        break;
      case 'string':
      case 'int':
        value = measurment.Value;
        break;
    }

    return (
      <tr key={measurment.TransmitterId + '_' + index}>
        <td>
          <div className="flex-col-around flex-0-0-auto p-l-05em">
            {formatEnum(measurment.Measurment)}
          </div>
        </td>
        <td>
          <div className="flex-col-around flex-0-0-auto p-l-05em">{value}</div>
        </td>
        <td>
          <div className="flex-col-around flex-0-0-auto p-l-05em">{dateAndTime}</div>
        </td>
      </tr>
    );
  };

  const hasContent = (productMeasurements && productMeasurements.length) ? true : false;
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "ProductMeasurementsSection");
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocKey={formatEnum('MEASUREMENT_LABEL')}
      bodyClassName="flex-row-wrap bg-c-white-primary"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      <div className="w-100p">
        <div>
          <table className="form-table w-100p">
            <thead className="grid-header">
              <tr>
                <th>
                  <div className="flex-row">
                    <div className="flex-col-around">{formatEnum('MEASUREMENT_COL_NAME')}</div>
                  </div>
                </th>
                <th>
                  <div className="flex-row">
                    <div className="flex-col-around">{formatEnum('MEASUREMENT_COL_VALUE')}</div>
                  </div>
                </th>
                <th>
                  <div className="flex-row">
                    <div className="flex-col-around">
                      {formatEnum('MEASUREMENT_COL_REPORTED_AT')}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="grid-body">{productMeasurements.map(renderRows)}</tbody>
          </table>
        </div>
      </div>
    </Section>
  );
}

export default function ProductMeasurementsSection (props: ProductMeasurementsSectionProps) {
  return (
    <WidgetErrorBoundary>
      <ProductMeasurementsWidget {...props} />
    </WidgetErrorBoundary>
  );
};
