import cx from 'classnames';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import SelectField from 'material-ui/SelectField';
import StartTest from 'material-ui/svg-icons/av/playlist-add-check';
import Refresh from 'material-ui/svg-icons/navigation/refresh';
import TextField from 'material-ui/TextField';
import { action, autorun, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { fromPromise, PENDING } from 'mobx-utils';
import React from 'react';
import ajax from '../../ajax';
import appHistory from '../../app_history';
import { DataStatusModel } from '../../interfaces/backend_model';
import l from '../../localization';
import { PermissionsStore } from '../../stores/mobx/PermissionsStore';
import settingsStore from '../../stores/settings_store';

const endpoint = require('../../../json/endpoints.json');
const constants = require('../../../json/constants.json');

const style = {
  refresh: {
    display: 'inline-block',
    position: 'relative'
  }
};

interface PropsSupportContainer {
  permissions: PermissionsStore;
}

@inject('permissions')
export default class SupportContainer extends React.Component<PropsSupportContainer> {
  dispose: () => void;

  componentDidMount() {
    const map = this.props.permissions.getMap();

    this.dispose = autorun(() => {
      if (!this.props.permissions.checkPermission(map.skygdSystemAdmin)) {
        appHistory.replace(constants.PATH_DASHBOARD);
      }
    });
  }

  componentWillUnmount() {
    this.dispose();
  }

  render() {
    return (
      <div className="flex-1-1-auto ">
        <SystemStatus />
        <SubscriptionStatus />
        <SendDocument />
      </div>
    );
  }
}

interface PropsSendDocument { }

@observer
class SendDocument extends React.Component<PropsSendDocument> {
  @observable status = '';
  @observable isLoading = false;
  @observable selectedDocument = 7;
  @observable numberOrEmail = '';

  constructor(props: PropsSendDocument) {
    super(props);
  }

  @action
  sendDocument = () => {
    this.status = '';
    this.isLoading = true;
    ajax
      .postByDescPromise(endpoint.SUPPORT_SEND_DOCUMENT, {
        DocumentId: this.selectedDocument,
        Destination: this.numberOrEmail
      })
      .then(
        action(() => {
          this.status = 'success';
          this.isLoading = false;
          setTimeout(
            action(() => {
              this.status = '';
              this.numberOrEmail = '';
              this.selectedDocument = 7;
            }),
            2000
          );
        })
      )
      .catch(
        action(() => {
          this.status = 'failed';
          this.isLoading = false;
        })
      );
  };

  @action
  handleChange = (e: React.ChangeEvent<HTMLSelectElement>, i: number, v: number) =>
    (this.selectedDocument = v);

  @action
  onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    (this.numberOrEmail = target.value);

  getErrorText() {
    if (this.status === 'failed') {
      return l.t('DOCUMENT_SENT_FAIL');
    }

    if (this.isSendSuccess()) {
      return l.t('DOCUMENT_SENT_SUCCESS');
    }
  }

  getSendButtonLabel() {
    if (this.isLoading) {
      return l.t('SENDING') + '...';
    }

    if (this.isSendSuccess()) {
      return l.t('SYSTEM_STATUS_SUCCESS');
    }
    return l.t('SEND');
  }

  isSendSuccess() {
    return this.status === 'success';
  }

  render() {
    return (
      <div>
        <div className="flex-center-w">
          <h1 style={{ paddingLeft: '16px' }}>{l.t('SEND_DOCUMENTS_LINKS')}</h1>
        </div>
        <p style={{ paddingLeft: '16px', margin: 0 }}>{l.t('SEND_DOCUMENTS_LINKS_DESCRIPTION')}</p>
        <div>
          <TextField
            value={this.numberOrEmail}
            floatingLabelText={l.t('EMAIL_OR_PHONE')}
            errorText={this.getErrorText()}
            errorStyle={{ color: this.isSendSuccess() ? 'green' : null }}
            style={{ marginLeft: '16px', padding: '' }}
            onChange={this.onChange}
          />
          <br />
          <SelectField
            floatingLabelText={l.t('SEND_DOCUMENTS_AVAILABLE')}
            value={this.selectedDocument}
            style={{ marginLeft: '16px' }}
            onChange={this.handleChange}
          >
            <MenuItem value={7} primaryText={'Användarsidorna (SV)'} />
            <MenuItem value={1} primaryText={'Felsökning datatrafik (SV)'} />
            <MenuItem value={9} primaryText={'Quickstart Android (EN)'} />
            <MenuItem value={8} primaryText={'Quickstart iPhone (EN)'} />
            <MenuItem value={5} primaryText={'Safeclick (SV)'} />
            <MenuItem value={6} primaryText={'Safeclick (EN)'} />
            <MenuItem value={4} primaryText={'Snabbstart Android (SV)'} />
            <MenuItem value={3} primaryText={'Snabbstart iPhone (SV)'} />
            <MenuItem value={2} primaryText={'Troubleshooting data traffic (EN)'} />
          </SelectField>
          <br />
          <RaisedButton
            label={this.getSendButtonLabel()}
            disabled={this.numberOrEmail.length < 4 || this.isLoading}
            className={this.isSendSuccess() ? 'button-green-override' : null}
            primary={true}
            style={{ marginLeft: '10px' }}
            onClick={this.sendDocument}
          />
        </div>
      </div>
    );
  }
}

interface PropsSubscriptionStatus { }

@observer
class SubscriptionStatus extends React.Component<PropsSubscriptionStatus> {
  fetchResult: any;
  @observable isLoading = false;
  @observable isMatched = false;
  @observable data = null;
  @observable subscription: string | boolean = false;

  constructor(props: PropsSubscriptionStatus) {
    super(props);
    this.fetchSubscription = debounce(this.fetchSubscription, 1000);
  }

  @action
  fetchSubscription = (identifier: string) => {
    this.isMatched = false;
    when(
      () => this.fetchResult.state !== PENDING,
      action(() => {
        this.isLoading = false;
        if (!this.fetchResult.value.NotFound) {
          this.isMatched = true;
        }
      })
    );

    this.fetchResult = fromPromise(
      ajax.getByDescWithDataPromise(endpoint.SUPPORT_SUBSCRIPTION_STATUS, {
        identifier: identifier.replace('+', '')
      })
    );
  };

  @action
  onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.isLoading = true;
    this.subscription = target.value;
    this.fetchSubscription(target.value);
  };

  render() {
    return (
      <div>
        <div className="flex-center-w">
          <h1 style={{ paddingLeft: '16px' }}>{l.t('SUBSCRIPTION_STATUS_HEADING')}</h1>
        </div>
        <p style={{ paddingLeft: '16px', margin: 0 }}>{l.t('SUBSCRIPTION_STATUS_DESCRIPTION')}</p>
        <div style={{ display: this.isMatched ? 'flex' : 'block' }}>
          <TextField
            className="w-25em"
            floatingLabelText={l.t('SUBSCRIPTION_FLOATING_LABEL')}
            errorText={this.isLoading ? l.t('SUBSCRIPTION_FLOATING_SEARCHING') : ''}
            errorStyle={{ color: '#ccc' }}
            style={{ marginLeft: '16px', padding: '' }}
            onChange={this.onChange}
          />
          {!this.isLoading && this.subscription && !this.isMatched && (
            <div style={{ marginLeft: '16px' }}>
              {l.t('SUBSCRIPTION_NOT_FOUND_FOR_NUMBER')} : {this.subscription}
            </div>
          )}
          {!this.isLoading && this.subscription && this.isMatched && (
            <Card className="w-25em" style={{ marginLeft: '25px', marginTop: '14px' }}>
              <CardHeader
                title={l.t('SUBSCRIPTION')}
                subtitle={`${l.t('TELEPHONE_NUMBER')}: ${this.fetchResult.value.TelephoneNumber}`}
              />
              <CardText>
                <b>{l.t('EMAIL')}:</b> {this.fetchResult.value.Email}
                <br />
                <b>{l.t('EMERGENCY_CENTER')}:</b> {this.fetchResult.value.EmergencyCenter}
                <br />
                <b>{l.t('NAME')}:</b> {this.fetchResult.value.Name}
                <br />
                <b>{l.t('IS_SUBSCRIPTION_VALID')}:</b>{' '}
                {this.fetchResult.value.SubscriptionValid.toString()}
                <br />
                <b>{l.t('SUBSCRIPTION_VALID_TO')}:</b>{' '}
                {this.fetchResult.value.SubscriptionValidTo
                  ? format(
                    this.fetchResult.value.SubscriptionValidTo,
                    settingsStore.getDateFormat()
                  )
                  : '-'}
                <br />
              </CardText>
            </Card>
          )}
        </div>
      </div>
    );
  }
}

@observer
class SystemStatus extends React.Component {
  @observable fetchResult: any;
  @observable isTestStarted = false;
  @observable allowTooltip = true;
  interval: any;

  componentWillMount() {
    this.refreshStatus();
  }

  @action
  refreshStatus = () => {
    when(
      () => this.fetchResult.state !== PENDING,
      action(() => {
        if ((this.fetchResult.value || []).every((item: any) => !item.Ongoing)) {
          this.isTestStarted = false;
          clearInterval(this.interval);
          setTimeout(action(() => (this.allowTooltip = true)), 1000);
        }
      })
    );
    this.fetchResult = fromPromise(ajax.getByDescPromise(endpoint.SUPPORT_GET_STATUS));
  };

  @action
  startTests = () => {
    ajax.postByDescPromise(endpoint.SUPPORT_UPDATE_STATUS, {}).then(
      action(() => {
        this.allowTooltip = false;
        this.isTestStarted = true;

        this.refreshStatus();
        this.interval = setInterval(this.refreshStatus, 5000);
      })
    );
  };

  render() {
    return (
      <div>
        <div className="flex-center-w">
          <h1 style={{ paddingLeft: '16px' }}>{l.t('SYSTEM_STATUS_HEADING')}</h1>
          <IconButton
            tooltip={this.allowTooltip && l.t('UPDATE_STATUS')}
            className={cx({ ['rotate-refresh']: this.isTestStarted })}
            onClick={this.isTestStarted ? () => { } : this.refreshStatus}
          >
            <Refresh />
          </IconButton>
          <IconButton
            disabled={this.isTestStarted}
            tooltip={this.allowTooltip && l.t('START_SYSTEM_TEST')}
            onClick={this.startTests}
          >
            <StartTest />
          </IconButton>
        </div>
        <div>
          {this.fetchResult.case({
            pending: () => (
              <div style={{ paddingTop: '15px' }} className="flex-center">
                <RefreshIndicator
                  style={style.refresh}
                  size={40}
                  left={10}
                  top={0}
                  status="loading"
                />
              </div>
            ),
            rejected: (err: any) => <div>{JSON.stringify(err)}</div>,
            fulfilled: (data: DataStatusModel[]) => (
              <table className="form-table w-100p system-status">
                <thead className="grid-header">
                  <tr className="header-row">
                    <th>{l.t('SYSTEM_STATUS_TEST_DESC')}</th>
                    <th>{l.t('SYSTEM_STATUS_LAST_EXECUTED')}</th>
                    <th>{l.t('STATUS')}</th>
                    <th>{l.t('SYSTEM_STATUS_TEST_OUTCOME')}</th>
                  </tr>
                </thead>
                <tbody className="grid-body">
                  {data.map((item: DataStatusModel, idx: number) => (
                    <tr
                      key={idx}
                      className={cx({
                        ['status-failed']: !item.Successful,
                        ['status-success']: item.Successful,
                        ['status-pending']: item.Ongoing
                      })}
                    >
                      <td>{item.Description}</td>
                      <td>{item.Executed}</td>
                      <td>
                        {!item.Ongoing ? l.t('SYSTEM_STATUS_DONE') : l.t('SYSTEM_STATUS_PENDING')}
                      </td>
                      <td>
                        {this.isTestStarted
                          ? '-'
                          : item.Successful
                            ? l.t('SYSTEM_STATUS_SUCCESS')
                            : l.t('SYSTEM_STATUS_FAILED')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          })}
        </div>
      </div>
    );
  }
}
