import React from 'react';

export default class IconHistory extends React.Component {
  render() {
    return (
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.91841 0.827908C6.77951 0.817663 6.63995 0.8125 6.5 0.8125V0C6.65994 0 6.81943 0.00590037 6.97817 0.0176086L6.91841 0.827908ZM9.65981 1.77102C9.54344 1.69326 9.42454 1.62002 9.30336 1.55138L9.70384 0.844433C9.84234 0.92289 9.97823 1.00659 10.1112 1.09545C10.2442 1.1843 10.3735 1.27782 10.499 1.37574L9.99914 2.01628C9.88934 1.9306 9.77617 1.84877 9.65981 1.77102ZM11.15 3.22509C10.9892 2.99674 10.8118 2.78052 10.6192 2.57824L11.2076 2.01799C11.4277 2.24917 11.6305 2.49627 11.8143 2.75724L11.15 3.22509ZM11.7546 4.32349C11.701 4.19419 11.6428 4.06723 11.5802 3.94283L12.306 3.57753C12.3775 3.7197 12.444 3.8648 12.5052 4.01256C12.5664 4.16032 12.622 4.30993 12.6719 4.46107L11.9004 4.71594C11.8568 4.5837 11.8081 4.45279 11.7546 4.32349ZM12.1858 6.36042C12.1789 6.0812 12.1515 5.80285 12.1038 5.52765L12.9043 5.38875C12.9589 5.70326 12.9902 6.02137 12.998 6.34048L12.1858 6.36042ZM12.0782 7.60958C12.1055 7.47231 12.1277 7.33443 12.1447 7.19621L12.9511 7.29567C12.9316 7.45365 12.9063 7.61123 12.8751 7.76809C12.8439 7.92495 12.807 8.08022 12.7645 8.23363L11.9815 8.01693C12.0186 7.88271 12.0509 7.74684 12.0782 7.60958ZM11.3051 9.5428C11.4545 9.30683 11.5864 9.06015 11.6996 8.80481L12.4424 9.13407C12.313 9.42589 12.1623 9.7078 11.9916 9.97748L11.3051 9.5428ZM10.5217 10.5217C10.6206 10.4227 10.7157 10.3204 10.8066 10.2149L11.4219 10.7456C11.3179 10.8662 11.2093 10.9831 11.0962 11.0962L10.5217 10.5217Z" fill="black"/>
        <path d="M6.5 0.8125C5.56469 0.8125 4.64381 1.04317 3.81893 1.48407C2.99406 1.92498 2.29065 2.56251 1.77102 3.34019C1.25138 4.11788 0.931565 5.01172 0.839889 5.94253C0.748212 6.87334 0.887507 7.81239 1.24544 8.67651C1.60337 9.54063 2.16888 10.3031 2.89189 10.8965C3.6149 11.4899 4.47308 11.8957 5.39043 12.0782C6.30777 12.2607 7.25595 12.2141 8.151 11.9426C9.04604 11.6711 9.8603 11.183 10.5217 10.5217L11.0962 11.0962C10.3403 11.852 9.40975 12.4098 8.38685 12.7201C7.36395 13.0304 6.28031 13.0836 5.23191 12.8751C4.18352 12.6666 3.20274 12.2027 2.37645 11.5246C1.55015 10.8464 0.903847 9.97501 0.494785 8.98744C0.0857224 7.99988 -0.0734727 6.92667 0.0313009 5.86289C0.136075 4.7991 0.501583 3.77758 1.09545 2.88879C1.68932 2.00001 2.49321 1.2714 3.43592 0.767512C4.37864 0.263621 5.43107 0 6.5 0V0.8125Z" fill="black"/>
        <path d="M6.09375 2.4375C6.31812 2.4375 6.5 2.61938 6.5 2.84375V7.07674L9.13906 8.58478C9.33386 8.69609 9.40154 8.94425 9.29023 9.13906C9.17891 9.33386 8.93075 9.40154 8.73594 9.29022L5.8922 7.66522C5.76562 7.59289 5.6875 7.45829 5.6875 7.3125V2.84375C5.6875 2.61938 5.86939 2.4375 6.09375 2.4375Z" fill="black"/>
      </svg>
    );
  }
}
