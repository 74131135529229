import React, { useEffect } from "react";
import NotificationSystem from "react-notification-system";
import Spinner from "../../../components/spinner";
import WidgetsAPI from "../../../stores/widgets/api";

export const IntegrationInformation = ({
  link,
  notificationSystem,
}: {
  link: string;
  notificationSystem: NotificationSystem.System;
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isMarkdown, setIsMarkdown] = React.useState<boolean>(false);
  const [internalData, setInternalData] = React.useState<any>(null);

  // if (xhr.getResponseHeader("Content-Type") === "text/html" || xhr.getResponseHeader("Content-Type") === "text/markdown") {
  //   return reject({ err, content: xhr.responseText });
  // }

  const fetchIntegrationData = async () => {
    setIsLoading(true);
    WidgetsAPI.getIntegrationData(link)
      .then(response => {
        setInternalData(response);
      })
      .catch(error => {
        const contentType = error.headers["content-type"];
        // if the error response header type is text/html or text/markdown, then we can render it as the widget data
        if (
          contentType.includes('text/html') ||
          contentType.includes('text/markdown')
        ) {
          setInternalData(error.data);
          setIsMarkdown(contentType.includes('text/markdown'));
        } else {
          notificationSystem.addNotification({
            title: error.data.errors[0].description,
            autoDismiss: 10, // seconds
            position: 'br',
            level: 'error',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (link) {
      setIsMarkdown(link.includes('markdown'));
      fetchIntegrationData();
    }
  }, [link]);

  if (isLoading) {
    return (
      <div className="flex-row-center flex-1-1-auto">
        <Spinner />
      </div>
    );
  }

  return isMarkdown ? (
    <pre style={{ margin: 0 }}>{internalData}</pre>
  ) : (
    // need to use dangerouslySetInnerHTML to render html that came from the server
    <div dangerouslySetInnerHTML={{ __html: internalData }} />
  )
}