import React from "react";
import {MeModel, SettingModel, UserAlarmHistoryModel} from "../../interfaces/backend_model";
import {tokenizer} from "../admin_token";
import localization from "../../localization";
import IconBoxArrowRight from "../icons/icon_box_arrow_right";
import IconOpenWindow from "../icons/icon_open_window";
import {Spacer} from "../spacer";
import {TruncatedText} from "../truncatedText";
import {AlarmHistoryModal} from "./alarm_history_modal";

interface AlarmListHeaderProps {
  path: string;
  me: MeModel;
  settings: SettingModel[];
  userHistory: UserAlarmHistoryModel[];
  alarmId: string;
}
export const AlarmListHeader = ({
  path,
  me,
  settings,
  userHistory,
  alarmId,
}: AlarmListHeaderProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const historyURL = tokenizer.calcFullAdminURL(settings, me, path);

  return (
    <>
      <AlarmHistoryModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        userHistory={userHistory || []}
        alarmId={alarmId}
      />
      <div className="alarm-list-links-wrapper flex-row-between tabs-container">
        <p>{localization.t("ALARM_HISTORY")}</p>
        <div className="flex-row alarm-links">
          {/*open modal window*/}
          <TruncatedText
            text={localization.t("OPEN_MODAL_WINDOW")}
            trigger={
              <span>
                <button className="form-btn-transparent" onClick={() => setModalOpen(true)}>
                  <IconOpenWindow />
                </button>
              </span>
            }
          />
          <Spacer size={24} />
          {/*link to the new admin*/}
          <TruncatedText
            text={localization.t("OPEN_IN_NEW_TAB")}
            trigger={
              <span>
                <a target="_blank" href={historyURL!}>
                  <IconBoxArrowRight />
                </a>
              </span>
            }
          />
        </div>
      </div>
    </>
  )
}