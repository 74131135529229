import localization from '../../../localization';
import constants from '../../../../json/constants.json';
import { ModalWindow } from '../../../components/modal_window';
import React from 'react';
import {
  AbsenceHistoryModel,
  UserAbsenceModel,
} from '../../../interfaces/backend_model';
import Grid from '../../../components/grid';
import { uuid } from 'uuidv4';
import { TruncatedText } from '../../../components/truncatedText';
import settingsStore from '../../../stores/settings_store';
import moment from 'moment';
import classNames from 'classnames';
import IconBan from '../../../components/icons/icon_ban';
import IconX from '../../../components/icons/icon_x';
import { Spacer } from '../../../components/spacer';

export const AbsenceHistory = (
  {
    isOpen,
    onClose,
    absenceData = {
      Ongoing: [],
      Upcoming: [],
      Previous: [],
    },
    setAbsenceIdToDelete,
  } : {
    isOpen: boolean;
    onClose: () => void;
    absenceData?: UserAbsenceModel;
    setAbsenceIdToDelete: (absenceId: number | undefined) => void;
  }) => {
  const alarmT = localization.useNSt(constants.NS_ALARM);
  const commonT = localization.useNSt(constants.NS_DEFAULT);
  const dateFormat = settingsStore.getDateFormat().toUpperCase();
  const headers = [
    {
      key: 'Reason',
      content: alarmT('REASON'),
    },
    {
      key: 'StartDate',
      content: alarmT('START'),
    },
    {
      key: 'EndDate',
      content: alarmT('END'),
    },
    {
      key: 'Actions',
      content: ''
    }
  ];

  const renderHeader = () => (
    <thead className='grid-header t-a-l'>
      <tr>
        {headers.map((header, index) => {
          return (
            <th key={index} className='v-a-t'>
              {header.content}
            </th>
          );
        })}
      </tr>
    </thead>
  );

  const renderTruncatedDot = (absence: AbsenceHistoryModel) => {
    if (absence.Type !== 'Previous') {
      return (
        <TruncatedText
          text={
            absence.Type === 'Ongoing' ? alarmT('ONGOING') : alarmT('UPCOMING')
          }
          trigger={(
            <span className={classNames({
              ['user-status']: true,
              ['previous-event']: false,
              ['ongoing-event']: absence.Type === 'Ongoing',
            })} />
          )}
        />
      )
    }
    return (
      <span className={classNames({
        ['user-status']: true,
        ['previous-event']: true,
        ['ongoing-event']: false,
      })} />
    );
  }

  const renderRows = (absence: AbsenceHistoryModel) => (
    <tr key={uuid()} className='table-row'>
      {headers.map((header) => {
        if (header.key === 'Reason') {
          return (
            <td key={header.key + uuid()}>
              {renderTruncatedDot(absence)}
              <TruncatedText text={absence.Reason} maxlength={25} />
            </td>
          );
        }
        if (header.key === 'StartDate') {
          return (
            <td key={header.key + uuid()}>
              {`${moment(absence.StartDate).format(dateFormat)} ${absence.StartTime}`}
            </td>
          );
        }
        if (header.key === 'EndDate' && absence.EndDate) {
          return (
            <td key={header.key + uuid()}>
              {`${moment(absence.EndDate).format(dateFormat)} ${absence.EndTime}`}
            </td>
          );
        }
        if (header.key === 'Actions') {
          if (absence.Type === 'Ongoing') {
            return (
              <td key={header.key + uuid()}>
                <TruncatedText
                  position='top center'
                  text={alarmT('TERMINATE')}
                  trigger={
                    <button
                      type="button"
                      className="form-btn-transparent icon-blue"
                      onClick={() => {
                        setAbsenceIdToDelete(absence.Id);
                      }}
                    >
                      <IconBan />
                    </button>
                  }
                />
              </td>
            );
          } else if (absence.Type === 'Upcoming') {
            return (
              <td key={header.key + uuid()}>
                <TruncatedText
                  position='top center'
                  text={alarmT('DELETE_ABSENCE')}
                  trigger={
                    <button
                      type="button"
                      className="form-btn-transparent icon-blue"
                      onClick={() => {
                        setAbsenceIdToDelete(absence.Id);
                      }}
                    >
                      <IconX />
                    </button>
                  }
                />
              </td>
            )
          }
        }
        return <td key={header.key + uuid()} />;
      })}
    </tr>
  );

  return (
    <ModalWindow
      popupClassName='absence-modal'
      isOpen={isOpen}
      onClose={onClose}
      title={alarmT('ABSENCE_HISTORY')}
      body={(
        <>
          <Spacer size={16} />
          <Grid
            headers={headers}
            table={true}
            wrapperClassName="w-100p"
            tableClassName="form-table w-100p absence-history-table"
            header={renderHeader() as any}
            renderRow={renderRows as any}
            rows={absenceData ? [
              ...absenceData.Ongoing.map((absence) => ({
                ...absence, Type: 'Ongoing'
              })),
              ...absenceData.Upcoming.map((absence) => ({
                ...absence, Type: 'Upcoming'
              })),
              ...absenceData.Previous.map((absence) => ({
                ...absence, Type: 'Previous'
              })),
            ]?.sort((a, b) => b.StartDate.localeCompare(a.StartDate)) : []}
          />
          <Spacer size={16} />
        </>
      )}
      footer={(
        <>
          <button
            className='button-large button-large-secondary'
            onClick={onClose}
          >
            {commonT('CLOSE')}
          </button>
        </>
      )}
      footerClassName='flex-row-center p-t-b-1em'
    />
  )};
