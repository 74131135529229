import { addMonths, getMonth } from 'date-fns';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
// Include styles datepicker
import 'react-datepicker/dist/react-datepicker.css';
import * as Popups from '../../components/popups/popup';
import date_time from '../../date_time';
import localization from '../../localization';
import settingsStore from '../../stores/settings_store';
import { useForceUpdate } from '../../utilities/forceUpdate';

const constants = require('../../../json/constants.json');
const alarmT = localization.useNSt(constants.NS_ALARM);

interface ScheduledReminderProps {
  alarmId: string;
  onClose(): void;
  show: boolean;
  instance: any;
  readonly?: boolean;
  scheduleReminderType?: string;
  preSelectedDate?: Date;
  dateRange?: {
    minDate?: Date;
    maxDate?: Date;
  };
  hideCommentSection?: boolean;
  skipWeekendDays?: boolean | true;
}

function ScheduledReminderPopup(props: ScheduledReminderProps) {
  const [startDate, setStartDate] = React.useState(props.preSelectedDate || new Date());
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  const forceUpdate = useForceUpdate();

  const sheduledError = props.instance && props.instance.state.scheduledError;
  const scheduleReminderType = props.scheduleReminderType || 'NotifyAt';

  React.useEffect(
    () => {
      if (sheduledError) {
        setError(sheduledError);
      }
    },
    [sheduledError]
  );

  const handleNotifyMeConfirmClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset.click === 'close') {
      setError('');
      setStartDate(new Date());
      setMessage('');
      props.onClose();
    } else if (element.dataset.click === 'apply') {
      switch (scheduleReminderType) {
        case 'AlarmPlannedTime':
          let timeDiff = startDate ? Math.round((startDate.getTime() - Date.now()) / 1000) : 0;

          if (!timeDiff || timeDiff <= 0) {
            setError('Planned time must not be in the past');
            return;
          }

          props.instance.schedulePlanningAlarmTime(
            moment(startDate)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          );

          break;
        case 'NotifyAt':
        default:
          let messageToSend = '';
          let seconds = startDate ? Math.round((startDate.getTime() - Date.now()) / 1000) : 0;

          if (!seconds || seconds <= 0) {
            setError('SCHEDULED_REMINDER_ERROR');
            return;
          }

          if (message && message.length <= 200) {
            if (message.length === 200) {
              setError('SCHEDULED_REMINDER_ERROR_COMMENT');
            }
            messageToSend = message;
            setError('');
          } else {
            if (message.length > 200) {
              setError('SCHEDULED_REMINDER_ERROR_COMMENT');
              return;
            }
          }

          if (seconds && seconds > 0) {
            props.instance.scheduledRemind(seconds, messageToSend);
          }
          break;
      }
    }
  };

  const onChangeMessage = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const messageLength = target.value.length;
    if (messageLength >= 200) {
      setError('SCHEDULED_REMINDER_ERROR_COMMENT');
    }
    if (messageLength < 200) {
      setError('');
    }
    setMessage(target.value);
  };

  const isWeekday = (date: Date) => {
    if (!props.skipWeekendDays) {
      return true;
    }
    const day = getMonth(date);
    return day !== 0 && day !== 6;
  };

  const getMinDate = () => {
    return (props.dateRange && props.dateRange.minDate) || new Date();
  };

  const getMaxDate = () => {
    return (props.dateRange && props.dateRange.maxDate) || addMonths(new Date(), 3); // From story(CUR-20): "At least 1 month is allowed in the future, preferably 3 months."
  };

  const saveEnteredTime = (date: Date) => {
    if (date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      let newDate = startDate;
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      setStartDate(newDate);
      // need to re-render displayed time
      forceUpdate();
    }
  };

  function getBody() {
    console.log(getMaxDate());
    return (
      <div className="scheduled-picker p-t-b-05em flex-row">
        <DatePicker
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
          filterDate={isWeekday}
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          calendarStartDay={1}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat={settingsStore.getDateFormat()}
          locale={localization.getLanguage()}
          inline
        />
        <div
          style={{
            marginLeft: '20px',
            marginTop: '35px'
          }}
          className="flex-col-start"
        >
          <div className="p-t-b-05em">
            {startDate && date_time.toLocaleDateTime(startDate.toISOString())}
          </div>
          <div className="time-picker">
            <DatePicker
              selected={startDate}
              onChange={saveEnteredTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption={localization.t('TIME')}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />
            {error && <p className="error-text error-scheduled">{alarmT(error)}</p>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Popups.Popup
      onClick={handleNotifyMeConfirmClick}
      show={props.show}
      onClose={() => props.onClose()}
      ref={element => {
        props.instance.RNotify = element;
      }}
      body={{
        className: '',
        content: getBody()
      }}
      footer={{
        className: '',
        content: (
          <div className="flex-row-around w-100p p-t-b-05em">
            {props.hideCommentSection ? null : (
              <div className="flex-col-around flex-2-1-0px m-r-l-1em">
                <input
                  name="message"
                  className="flex-0-1-100p p-tb-02em-rl-05em message-scheduled"
                  value={message}
                  onChange={onChangeMessage}
                  placeholder={alarmT('SCHEDULED_REMINDER_COMMENT')}
                  type="text"
                  maxLength={200}
                  autoComplete="off"
                  required
                />
              </div>
            )}
            <div className="flex-row-around flex-1-2-0px">
              <div className="flex-col-around">
                <button className="button-small-secondary" type="button" data-click="close">
                  {alarmT('CANCEL')}
                </button>
              </div>
              <div className="flex-col-around">
                <button className="button-small-blue" type="button" data-click="apply" data-focus="1">
                  {alarmT('SAVE')}
                </button>
              </div>
            </div>
          </div>
        )
      }}
    />
  );
}

export default React.memo(ScheduledReminderPopup);
