import * as Logger from 'js-logger';
import Snackbar from 'material-ui/Snackbar';
import React from 'react';
import { fetchActiveCalls } from '../actions/calls';
import { hangupTwilioCall, holdCurrentCall, leaveCall, sendDTMF } from '../actions/twilio';
import appDispatcher from '../app_dispatcher';
import authentication from '../authentication';
import { CallModel } from '../interfaces/backend_model';
import localization from '../localization';
import CallsStore from '../stores/calls/calls_store';
import SettingsStore from '../stores/settings_store';
import TwilioStore, { TwilioState } from '../stores/twillio_store';
import CallInfo from './calls_info';
import CallList from './calls_list';
import IconTelephone from './icon_telephone';
import IconDialpad from './icons/icon_dialpad';
import { TwilioDialpad } from './twilio_dialpad';
import TwilioDialpadWithInput from './twillio_dialpad_with_input';

const constants = require('../../json/constants.json');

function getLSTelephony() {
  return {
    telephony_integration: authentication.ls_get([
      authentication.getUserId(),
      constants.TELEPHONY_KEY
    ]),
    access_token: authentication.getUserLSData(constants.ACCESS_TOKEN_KEY)
  };
}

interface Props { }

interface State {
  notification: string | null;
  twillio: {};
  onHoldCalls: CallModel[];
  showDailpad: boolean;
  showSendDtmfSignal: boolean;
}

export default class Telephony extends React.Component<Props, State> {
  hangupRHref: HTMLElement | null;

  state = {
    notification: null,
    twillio: { isbusy: false } as TwilioState,
    onHoldCalls: [],
    showDailpad: false,
    showSendDtmfSignal: true
  };

  componentDidMount() {
    const telephonyIntegration = getLSTelephony().telephony_integration;

    switch (telephonyIntegration) {
      case constants.TELEPHONY_LOCAL:
        document.addEventListener('keydown', this.handleDocumentKeyDown, false);
        break;
      case constants.TELEPHONY_CLOUD:
        document.addEventListener('keydown', this.handleDocumentKeyDown, false);
        TwilioStore.addEventListener(this.onTwillioDataChange);
        CallsStore.addEventListener(this.onCallsDataChange);
        break;
    }
    appDispatcher.handleServerAction({ type: constants.START_UPDATING_CALLS });

    fetchActiveCalls();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleDocumentKeyDown, false);
    TwilioStore.removeEventListener(this.onTwillioDataChange);
    CallsStore.removeEventListener(this.onCallsDataChange);
    appDispatcher.handleServerAction({ type: constants.STOP_UPDATING_CALLS });
  }

  onTwillioDataChange = () => {
    const twillioState = TwilioStore.getState();

    if (twillioState.isbusy) {
      this.setState({
        twillio: twillioState
      });
    } else {
      this.setState({
        twillio: twillioState,
        showSendDtmfSignal: !twillioState.isbusy
      });
    }
  };

  onCallsDataChange = () => {
    this.setState({
      onHoldCalls: CallsStore.getOnHoldCalls()
    });
  };

  onTwilioSendDTMF = (signal: number) => (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    // only when call is running
    if (TwilioStore.getState().isbusy) {
      sendDTMF(signal);
    }
  };

  onLeaveCall = () => (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    const activeCall = CallsStore.getActiveCall();
    if (activeCall) {
      activeCall.JoinCallSid = activeCall.CallSid;
      leaveCall(activeCall);
    }
  };

  onTwilioHangUp = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    Logger.info('Click onTwilioHangUp');

    hangupTwilioCall();
  };

  onTwilioHoldOn = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    Logger.info('Click onTwilioHoldOn');

    const twillioState = TwilioStore.getState();

    if (twillioState.isbusy && !twillioState.isHoldPending) {
      Logger.info('if isbusy && !isHoldPending holdCurrentCall');
      holdCurrentCall();
    }
  };

  onClickShowDialPad = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      showDailpad: !this.state.showDailpad
    });
  };

  onDialPadClick = (button: { symbol: string; alias?: string }) => {
    // only when call is running
    if (TwilioStore.getState().isbusy) {
      sendDTMF(button.symbol);
    }
  };

  hangupActiveCall = () => {
    const telephonyIntegration = getLSTelephony().telephony_integration;

    switch (telephonyIntegration) {
      case constants.TELEPHONY_LOCAL:
        if (this.hangupRHref) {
          this.hangupRHref.click();
          this.setState({ notification: localization.t('HANG_UP') });
        }

        break;
      case constants.TELEPHONY_CLOUD:
        Logger.info('CTRL+G hangup call');
        if (this.state.twillio.isbusy) {
          hangupTwilioCall();
          this.setState({ notification: localization.t('HANG_UP') });
          Logger.info('CTRL+G hangup call not busy. Invoke hangupTwilioCall');
        }

        break;
    }
  };

  handleDocumentKeyDown = (e: KeyboardEvent) => {
    if (e.ctrlKey) {
      switch (e.keyCode) {
        case 71: // Ctrl+G
          e.preventDefault();
          e.stopPropagation();

          this.hangupActiveCall();
      }
    }
  };

  handleRequestClose = () => {
    this.setState({ notification: null });
  };

  // Show/hide hot keys in call
  onShowSendDigit = (show: boolean) => {
    this.setState({ showSendDtmfSignal: show });
  };

  render() {
    const { notification, showSendDtmfSignal } = this.state;
    const lsTelephony = getLSTelephony();
    const access_token = lsTelephony.access_token;
    const dtmfSignal = SettingsStore.getValueByKey('DTMFSENDDIGIT', 'number');

    const snackbar = (
      <Snackbar
        open={!!notification}
        message={notification ? notification : ''}
        autoHideDuration={4000}
        onRequestClose={this.handleRequestClose}
      />
    );

    switch (lsTelephony.telephony_integration) {
      case constants.TELEPHONY_LOCAL:
        return (
          <div className="flex-0-0-auto flex-col telephony-section f-15 p-05em" data-cy="telephony">
            <div className="flex-row-around p-05em">
              <a
                className="flex-row-center button-small-red button-with-icon flex-1-1-100p"
                href={'skygd:hangup?token=' + access_token}
                ref={obj => (this.hangupRHref = obj)}
              >
                <span className="icon">
                  <IconTelephone />
                </span>
                {localization.t('HANG_UP')}
              </a>
            </div>
            <div className="flex-row-between">
              <div className="p-05em flex-1-1-50p">
                <a className="flex-row-center button-small-blue button-with-icon" href={'skygd:hold?token=' + access_token}>
                  <span className="icon">
                    <IconTelephone />
                  </span>
                  {localization.t('HOLD')}
                </a>
              </div>
              <div className="p-05em flex-1-1-50p">
                <a
                  className="flex-row-center button-small-green button-with-icon flex-1-1-50p"
                  href={'skygd:showphone?token=' + access_token}
                >
                  <span className="icon">
                    <IconTelephone />
                  </span>
                  {localization.t('SHOW_SOFT_PHONE')}
                </a>
              </div>
            </div>
            {snackbar}
          </div>
        );
      case constants.TELEPHONY_CLOUD:
        return (
          <div className="flex-0-0-auto flex-col telephony-section f-15 p-05em" data-cy="telephony">
            <CallInfo />
            {!this.state.twillio.isbusy && <TwilioDialpadWithInput />}
            {this.state.twillio.isbusy && (
              <div>
                <div>
                  <div className="flex-row-between">
                    <div className="p-05em flex-1-1-15p">
                      <a className="flex-row-center button-small-blue button-with-icon" onClick={this.onClickShowDialPad}>
                        <span className="icon">
                          <IconDialpad />
                        </span>
                      </a>
                    </div>
                    <div className="p-05em">
                      <a className="flex-row-center button-small-blue button-with-icon" onClick={this.onTwilioHoldOn}>
                        <span className="icon">
                          <IconTelephone />
                        </span>
                        {localization.t('HOLD')}
                      </a>
                    </div>
                    <div className="p-05em">
                      <a
                        className="flex-row-center button-small-red button-with-icon flex-1-1-100p"
                        onClick={this.onTwilioHangUp}
                      >
                        <span className="icon">
                          <IconTelephone />
                        </span>
                        {localization.t('HANG_UP')}
                      </a>
                    </div>
                  </div>
                  {this.state.showDailpad && (
                    <div className="flex-0-0-auto flex-col telephony-section f-15">
                      <TwilioDialpad onClick={this.onDialPadClick} />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="voice-leave-section">
              {!!dtmfSignal && showSendDtmfSignal && (
                <div className="flex-row-between">
                  <div className="p-05em flex-1-1-50p">
                    <a
                      className="flex-row-center button-small-green button-with-icon flex-1-1-50p"
                      onClick={this.onTwilioSendDTMF(dtmfSignal)}
                    >
                      <span className="icon">
                        <IconTelephone />
                      </span>
                      {`${localization.t('TWILIO_SEND_DTMF')} ${dtmfSignal}`}
                    </a>
                  </div>
                </div>
              )}
              {CallsStore.getActiveCall()?.LeaveConferenceAllowed && (
                <div className="flex-row-between">
                  <div className="p-05em flex-1-1-50p">
                    <a
                      className="flex-row-center button-small-orange button-with-icon flex-1-1-50p"
                      onClick={this.onLeaveCall()}
                    >
                      <span className="icon">
                        <IconTelephone />
                      </span>
                      {`${localization.t('LEAVE_CALL')}`}
                    </a>
                  </div>
                </div>
              )}
            </div>
            {snackbar}
            {!!this.state.onHoldCalls.length && (
              <CallList callInProgress={this.state.twillio.isbusy} />
            )}
          </div>
        );
      default:
        return null;
    }
  }
}
