import { AlarmDetailsModel } from 'development/js/interfaces/backend_model';
import * as Logger from 'js-logger';
import React from 'react';
import ajax from '../../ajax';
import Spinner from '../../components/spinner';
import localization from '../../localization';
import UsersSearchStore, { UserModel, UsersDataModel } from '../../stores/mobx/UsersSearchStore';

const constants = require('../../../json/constants.json');
const endpoints = require('../../../json/endpoints.json');

const alarmT = localization.useNSt(constants.NS_ALARM);

interface AssociateWithObjectProps {
  alarmDetails: AlarmDetailsModel;
  onShowSearchUser: () => void;
  errorText: string;
}

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref && ref.current && ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default function AssociateWithObject(props: AssociateWithObjectProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [openedTop, setOpenedTop] = React.useState(null);
  const [openedLeft, setOpenedLeft] = React.useState(null);
  const [objects, setObjects] = React.useState<any>([]);
  const refAssociate = React.useRef<HTMLDivElement>(null);

  React.useEffect(
    () => {
      loadAssociateWithObject();
    },

    [props.alarmDetails]
  );

  useOutsideClick(refAssociate, () => {
    if (isOpen) {
      handleGlobalClick();
    }
  });

  async function loadAssociateWithObject(value?: string) {
    try {
      setObjects([]);
      setIsLoading(true);
      const response = await ajax.getByDescWithDataPromise<UsersDataModel[]>(
        endpoints.LINKED_CONTACTS,
        {
          alarmId: props.alarmDetails.AlarmId
        }
      );

      setObjects(
        ((Array.isArray(response) && response) || []).map(
          (user: UsersDataModel) => new UserModel(user)
        )
      );
    } catch (e) {
      setIsLoading(false);
      if (typeof e === 'string') {
        setErrorMessage(e);
      } else {
        setErrorMessage(`${e}`);
      }
      Logger.error(e);
    }

    setIsLoading(false);
  }

  const handleGlobalClick = () => {
    setIsOpen(false);
    setErrorMessage(undefined);
    setOpenedTop(null);
    setOpenedLeft(null);
  };

  const toggleItems = (e: any) => {
    let target = e.currentTarget;
    e.stopPropagation();
    setIsOpen(!isOpen);
    setOpenedTop(target.offsetTop + target.offsetHeight);
    setOpenedLeft(target.offsetLeft);
    setErrorMessage(undefined);
  };

  const stopClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const onAssociateUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, el: UserModel) => {
    e.preventDefault();
    handleGlobalClick();
    UsersSearchStore.assignUser(el, props.alarmDetails.AlarmId, true).catch(() =>
      setErrorMessage(alarmT('ASSOCIATE_WITH_OTHER_OBJECT_ERROR'))
    );
  };

  return (
    <div className="flex-1-1-0px flex-row-end">
      <div className="flex-col-around">
        <div ref={refAssociate} className="p-rel flex-row" onClick={handleGlobalClick}>
          {errorMessage ? (
            <button className="form-btn-bg-red" type="button" onClick={toggleItems}>
              {errorMessage}
            </button>
          ) : (
            <button className="button-small-blue" type="button" onClick={toggleItems}>
              {alarmT('ASSOCIATE_WITH_OTHER_OBJECT')}
            </button>
          )}
          {isLoading ? (
            <div className="flex-col-around">
              <Spinner width={1} height={1} widthUnits="em" heightUnits="em" />
            </div>
          ) : null}
          {isOpen ? (
            <div
              className="p-abs flex-col filter-window"
              style={{ left: openedLeft + 'px', top: openedTop + 'px', minWidth: '150px' }}
              onClick={stopClick}
            >
              <div className="item-border" style={{ marginBottom: '2px' }}>
                <button className="button-small-blue w-100p t-a-l" onClick={props.onShowSearchUser}>
                  {props.errorText
                    ? { className: 'error', content: localization.t('ERROR') }
                    : null}
                  {alarmT('SEARCH_CONTACT')}
                </button>
              </div>
              {Array.isArray(objects) &&
                objects.map(el => (
                  <div className="item-border" style={{ marginBottom: '2px' }} key={el.userId}>
                    <button
                      className="button-small-secondary text-left w-100p flex-row-between flex-w"
                      onClick={e => onAssociateUser(e, el)}
                    >
                      {el.name}
                      {(el.additionalInformation.length &&
                        el.additionalInformation.map(
                          (
                            info: { Key: string; Value: string },
                            index: number,
                            array: { Key: string; Value: string }[]
                          ) => {
                            if (info.Key === 'CUSTOMER') {
                              return (
                                <span key={info.Value + index}>
                                  {info.Value}
                                  {index !== array.length - 1 ? ', ' : null}
                                </span>
                              );
                            }
                            return '';
                          }
                        )) ||
                        ''}
                    </button>
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
