import React from 'react';

import localization from '../../localization';
import { Popup } from './popup';

interface Props {
  onClick: any;
  show: boolean;
  message: string | boolean | JSX.Element;
}

export default class PopupError extends React.Component<Props> {
  render() {
    return (
      <Popup
        onClick={this.props.onClick}
        show={this.props.show}
        title={{ className: 'error', content: localization.t('ERROR') }}
        body={{ className: 'f-15 bold', content: this.props.message }}
        footer={{
          className: '',
          content: (
            <div className="flex-col-around">
              <button className="button-small-red" type="button" data-click="close" autoFocus>
                {localization.t('CLOSE')}
              </button>
            </div>
          )
        }}
      />
    );
  }
}
