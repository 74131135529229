import { AlarmModel } from "../interfaces/backend_model";
import settingsStore from "../stores/settings_store";

export const sortAlarms = (alarms: AlarmModel[], propertyName?: string, descending?: boolean) => {
  const me = settingsStore.getMe();
  const myAlarms = alarms.filter(alarm => +alarm.RespondentId === +me.RespondentId);
  const otherAlarmsWithId = alarms.filter(alarm => alarm.RespondentId && +alarm.RespondentId !== +me.RespondentId);
  const untakenAlarms = alarms.filter(alarm => !alarm.RespondentId);

  const sortFunc = (a: AlarmModel, b: AlarmModel) => {
    if (a[propertyName!] < b[propertyName!]) return descending ? 1 : -1;
    if (a[propertyName!] > b[propertyName!]) return descending ? -1 : 1;
    return 0;
  }

  if (propertyName && descending !== undefined) {
    myAlarms.sort(sortFunc);
    otherAlarmsWithId.sort(sortFunc);
    untakenAlarms.sort(sortFunc);
  }

  return [...myAlarms, ...untakenAlarms, ...otherAlarmsWithId];
}

export const defaultAlarmSorting = (alarms: AlarmModel[], propertyName: string, descending: boolean) => {
  return alarms.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) return descending ? 1 : -1;
    if (a[propertyName] > b[propertyName]) return descending ? -1 : 1;
    return 0;
  });
};