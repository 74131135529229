import React from 'react';

export default class IconMenuCircle extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-menu-circle"
        x="0px"
        y="0px"
        width="70px"
        height="70px"
        viewBox="0 0 70 70"
      >
        <path
          d={`M35,70C15.7,70,0,54.3,0,35S15.7,0,35,0c19.3,0,35,15.7,35,35S54.3,70,35,70z M35,2.5C17.1,2.5,2.5,17.1,2.5,35
        c0,17.9,14.6,32.5,32.5,32.5c17.9,0,32.5-14.6,32.5-32.5C67.5,17.1,52.9,2.5,35,2.5z M21.3,44.8h27.3v2.5H21.3V44.8z M21.3,34.9
        h27.3v2.5H21.3V34.9z M21.3,25h27.3v2.5H21.3V25z`}
        />
      </svg>
    );
  }
}
