import * as Logger from 'js-logger';
import React from 'react';
import authentication from './authentication';
import { Popup } from './components/popups/popup';
import localization from './localization';
import settingsStore from './stores/settings_store';
import { logoutFlow } from './utilities/logoutFlow';

const audioAlarmURL = require('./../audio/alarm.mp3');

const UserInactivity: React.FC = () => {
  let [showInactivityModal, setShowInactivityModal] = React.useState(false);

  let [modalTimer, setModalTimer] = React.useState(
    settingsStore.getValueByKey('InactivityModalTimeout') || 30
  );
  let userInactivityTimer = settingsStore.getValueByKey('InactivityCheckInterval');
  let inactivityTimeout: NodeJS.Timeout;
  let countDownTimeout: NodeJS.Timeout;
  const inactivityFlowEnabled = !!userInactivityTimer;
  const alarmAudio = new Audio(audioAlarmURL);

  React.useEffect(() => {
    if (showInactivityModal) {
      if (modalTimer > 0) {
        countDownTimeout = setTimeout(() => {
          setModalTimer(--modalTimer);
        }, 1000);
      } else {
        handleLogOut();
      }
    }
    return () => clearTimeout(countDownTimeout);
  });

  React.useEffect(() => {
    return () => {
      removeWindowListeners();
    };
  });

  const addWindowListeners = () => {
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
  };

  const removeWindowListeners = () => {
    window.removeEventListener('click', handleUserActivity);
    window.removeEventListener('scroll', handleUserActivity);
  };

  const handleUserActivity = () => {
    clearTimeout(inactivityTimeout);
    createInactivityTimeout();
  };

  const createInactivityTimeout = () => {
    inactivityTimeout = setTimeout(() => {
      if (authentication.getUserId()) {
        removeWindowListeners();
        alarmAudio.play();
        setShowInactivityModal(true);
      }
    }, userInactivityTimer * 1000);
  };

  if (inactivityFlowEnabled && !showInactivityModal) {
    createInactivityTimeout();
    addWindowListeners();
  }

  const renderBody = () => {
    return (
      <div className="flex-row-around flex-col w-100p">
        <p className="f-15 bold">{localization.t('INACTIVE_TEXT_BODY')}</p>

        <p>
          {localization.t('INACTIVE_SUBTEXT_BODY')} <span className="bold">{modalTimer}</span>{' '}
          {localization.t('INACTIVE_SUBTEXT_SECONDS')}
        </p>
      </div>
    );
  };

  const handleContinueSession = () => {
    clearTimeout(countDownTimeout);
    setModalTimer(settingsStore.getValueByKey('INACTIVITY_MODAL_TIMEOUT') || 30);
    removeWindowListeners();
    setShowInactivityModal(false);
  };

  const handleLogOut = () => {
    logoutFlow(handleLogoutError);
    setShowInactivityModal(false);
  };

  const handleLogoutError = (err: String) => {
    Logger.info(err);
  };

  const renderFooter = () => {
    return (
      <div className="flex-row-around w-100p">
        <div className="flex-col-around">
          <button
            className="button-small-secondary"
            type="button"
            data-click="continue"
            onClick={handleLogOut}
          >
            {localization.t('INACTIVE_BUTTON_LOGOUT')}
          </button>
        </div>
        <div className="flex-col-around">
          <button
            className="button-small-green"
            type="button"
            data-click="close"
            onClick={handleContinueSession}
          >
            {localization.t('INACTIVE_BUTTON_CONTINUE')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Popup
        show={showInactivityModal}
        title={{
          className: 'border-bottom-header f-15 bold',
          content: localization.t('INACTIVE_SESSION')
        }}
        body={{ className: '', content: renderBody() }}
        footer={{ className: '', content: renderFooter() }}
      />
    </>
  );
};

export default UserInactivity;
