import React from 'react';

export default class IconBell extends React.Component {
  render() {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 14C7.9665 14 8.75 13.2165 8.75 12.25H5.25C5.25 13.2165 6.0335 14 7 14Z" fill="#297B41"/>
        <path d="M7 1.67847L6.30245 1.81935C4.70358 2.14225 3.50002 3.55655 3.50002 5.25C3.50002 5.79935 3.38245 7.17259 3.09851 8.52439C2.95756 9.19546 2.76897 9.89417 2.51833 10.5H11.4817C11.231 9.89417 11.0425 9.19545 10.9015 8.52439C10.6176 7.17259 10.5 5.79934 10.5 5.25C10.5 3.55654 9.29644 2.14224 7.69755 1.81934L7 1.67847ZM12.4419 10.5C12.6372 10.8915 12.8638 11.2009 13.125 11.375H0.875C1.13621 11.2009 1.36277 10.8915 1.55811 10.5C2.34432 8.92445 2.62502 6.01927 2.62502 5.25C2.62502 3.13192 4.13018 1.36539 6.12924 0.961662C6.12643 0.933154 6.125 0.904245 6.125 0.875C6.125 0.391751 6.51675 0 7 0C7.48325 0 7.875 0.391751 7.875 0.875C7.875 0.904242 7.87357 0.933149 7.87076 0.961654C9.86984 1.36537 11.375 3.13191 11.375 5.25C11.375 6.01927 11.6557 8.92445 12.4419 10.5Z" fill="#297B41"/>
      </svg>
    );
  }
}
