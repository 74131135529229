import React from 'react';
import localization from '../../localization';
import { Popup } from './popup';

interface Props {
  no?: string;
  yes?: string;
  onClick: any;
  show: boolean;
  message?: string;
  withoutCancel?: boolean;
}

export class PopupConfirm extends React.Component<Props> {
  render() {
    let { no, yes, onClick, show, message, withoutCancel } = this.props;

    if (!show) {
      return null;
    }

    let defaultNo = no ?? 'CLOSE',
      defaultYes = yes ?? 'CONTINUE',
      footerContent = (
        <div className="flex-row-around w-100p">
          {!withoutCancel ? (
            <div className="flex-col-around">
              <button className="button-small-secondary" type="button" data-click="close">
                {localization.t(defaultNo)}
              </button>
            </div>
          ) : null}
          <div className="flex-col-around">
            <button className="button-small-green" type="button" data-click="continue">
              {localization.t(defaultYes)}
            </button>
          </div>
        </div>
      );
    return (
      <Popup
        onClick={onClick}
        show={show}
        body={{ className: 'f-15 bold', content: message ?? '' }}
        footer={{ className: '', content: footerContent ?? '' }}
      />
    );
  }
}
