import Checkbox from 'material-ui/Checkbox';
import LinearProgress from 'material-ui/LinearProgress';
import React from 'react';
import { callResource, deleteResource } from '../actions/resources';
import ajax from '../ajax';
import { DataModel, ResourceModel } from '../interfaces/backend_model';
import localization from '../localization';
// import to subscribe handlers
import '../stores/resources_store';
import ShareIcon from './icon_share';
import { Popup } from './popups/popup';

const endpoints = require('../../json/endpoints.json');

const actionsEnum = {
  close: 'close',
  send: 'send',
  recall: 'recall',
  update: 'update'
};

interface Props {
  alarmId: string;
  resource: ResourceModel;
  alarmT: Function;
  noEmptyReturn?: boolean;
}

interface State {
  message: string;
  popupOpen: boolean;
  isFetching: boolean;
  isError: boolean;
  deactivate: boolean;
  notify: boolean;
  data: DataModel | null;
}

export default class CallResource extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onShare = this.onShare.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPopupClick = this.onPopupClick.bind(this);

    this.state = {
      popupOpen: false,
      isFetching: false,
      isError: false,
      deactivate: false,
      notify: false,
      message: '',
      data: null,
    };
  }

  componentWillUnmount() {
    this.deattachGlobalHandler();
  }

  callResourceFetch(alarmId: string, resource: string) {
    this.setState({ isError: false, isFetching: true, popupOpen: true });

    ajax.getByDescWithData(
      false,
      endpoints.CALL_RESOURCE_FETCH,
      { alarmId, resource },
      (
        err: string | null,
        xhr: XMLHttpRequest,
        response: { data: DataModel; errors: string | string[] | null }
      ) => {
        if (err || !response.data) {
          this.setState({ isError: true, isFetching: false });
          return;
        }

        this.setState({
          data: response.data,
          isFetching: false,
          message: response.data.Message,
          notify: response.data.NotifyOnUpdate,
          deactivate: response.data.DeactivateAlarmWhenAllDone
        });
      }
    );
  }

  onShare() {
    this.callResourceFetch(this.props.alarmId, this.props.resource.Resource);
  }

  sendResource() {
    if (this.state.message) {
      callResource(
        this.props.alarmId,
        this.state.message,
        this.props.resource.Resource,
        this.state.notify,
        this.state.deactivate
      );
      this.closePopup();
    }
  }

  onSubmit(e: React.KeyboardEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  closePopup() {
    this.setState({ popupOpen: false });
  }

  onPopupClick({ target }: { target: HTMLButtonElement }) {
    if (!target) {
      return;
    }

    switch (target.dataset.click) {
      case actionsEnum.close:
        this.closePopup();
        break;
      case actionsEnum.send:
      case actionsEnum.update:
        this.sendResource();
        break;
      case actionsEnum.recall:
        deleteResource(this.props.alarmId, this.props.resource.Resource);
        this.closePopup();
        break;
    }
  }

  getResult(resource: ResourceModel) {
    if (resource.Resource === '') {
      return null;
    }

    if (!resource.LoggedIn) {
      return { component: <ShareIcon />, classname: 'disabled', listener: null };
    }

    if (resource.Active) {
      return { component: <ShareIcon />, classname: 'green', listener: this.onShare };
    }

    return { component: <ShareIcon />, classname: 'black', listener: this.onShare };
  }

  getContent() {
    if (this.state.isError) {
      return <div />;
    }

    if (this.state.isFetching && !this.state.isError) {
      return <LinearProgress mode="indeterminate" />;
    }

    return (
      <form onSubmit={this.onSubmit} autoComplete="off">
        <div className="flex-row-wrap">
          <label style={{ paddingBottom: '10px' }}>
            {this.props.alarmT('ASSIGN_RESOURCE_TITLE')}
          </label>
          <div className="flex-col" style={{ width: '100%' }}>
            <div>
              <label>
                {this.props.alarmT('ASSIGN_RESOURCE_MESSAGE')}
                <input
                  autoFocus
                  name="message"
                  className="flex-0-1-100p"
                  style={{ width: '100%' }}
                  value={this.state.message}
                  onChange={({ target }) => this.setState({ message: target.value })}
                  type="text"
                  maxLength={200}
                  autoComplete="off"
                  required
                />
              </label>
            </div>
            <div style={{ padding: '5px 0px 0px 0px' }}>
              <Checkbox
                label={this.props.alarmT('ASSIGN_RESOURCE_NOTIFY_ON_UPDATES')}
                checked={this.state.notify}
                onCheck={(e: Event, notify: boolean) => this.setState({ notify })}
              />
            </div>
            <div style={{ padding: '5px 0px 0px 0px' }}>
              <Checkbox
                label={this.props.alarmT('ASSIGN_RESOURCE_DEACTIVATE_ON_UPDATES')}
                checked={this.state.deactivate}
                onCheck={(e: Event, deactivate: boolean) => this.setState({ deactivate })}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }

  getFooter() {
    if (this.state.isFetching || this.state.isError) {
      return (
        <div className="flex-row-around w-100p">
          <div className="flex-col-around">
            <button className="button-small-secondary" type="button" data-click={actionsEnum.close}>
              {localization.t('CANCEL')}
            </button>
          </div>
        </div>
      );
    }

    if (this.state.data && this.state.data.CalledAt) {
      return (
        <div className="flex-row-around w-100p">
          <div className="flex-col-around">
            <button className="button-small-secondary" type="button" data-click={actionsEnum.close}>
              {localization.t('CANCEL')}
            </button>
          </div>
          <div className="flex-col-around">
            <button className="button-small-red" type="button" data-click={actionsEnum.recall}>
              {localization.t('RECALL')}
            </button>
          </div>
          <div className="flex-col-around">
            <button className="button-small-green" type="button" data-click={actionsEnum.update}>
              {localization.t('UPDATE')}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="flex-row-around w-100p">
        <div className="flex-col-around">
          <button className="button-small-secondary" type="button" data-click={actionsEnum.close}>
            {localization.t('CANCEL')}
          </button>
        </div>
        <div className="flex-col-around">
          <button className="button-small-green" type="submit" data-click={actionsEnum.send}>
            {localization.t('SEND')}
          </button>
        </div>
      </div>
    );
  }

  // Shortcuts
  handleKeyDown = ({ keyCode }: { keyCode: number }) => {
    switch (keyCode) {
      case 27: // Escape
        this.closePopup();
        break;
      case 13: // Enter
        this.sendResource();
        break;
    }
  };

  handleGlobalKeyDown = (e: KeyboardEvent) => {
    this.handleKeyDown({ keyCode: e.keyCode ? e.keyCode : e.which });
  };
  attachGlobalHandler = () => window.addEventListener('keydown', this.handleGlobalKeyDown);
  deattachGlobalHandler = () => window.removeEventListener('keydown', this.handleGlobalKeyDown);

  render() {
    const { resource, noEmptyReturn } = this.props;
    const result = this.getResult(resource);

    if (this.state.popupOpen) {
      this.attachGlobalHandler();
    } else {
      this.deattachGlobalHandler();
    }

    if (!result) {
      return noEmptyReturn ? <div style={{ backgroundColor: 'transparent' }} /> : null;
    }

    const { component, classname, listener } = result;

    return (
      <div onKeyDown={this.handleKeyDown}>
        <div className={`call-resource ${classname}`} onClick={listener as (() => void)}>
          {component}
        </div>
        {this.state.popupOpen && (
          <Popup
            onClick={this.onPopupClick}
            show={true}
            body={{ className: '', content: this.getContent() }}
            footer={{ className: '', content: this.getFooter() }}
          />
        )}
      </div>
    );
  }
}
