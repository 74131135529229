
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logToPapertrail } from '../actions/externalLogging';
import authentication from '../authentication';
import event_bus from '../event_bus';
import externalLogStore from '../stores/externalLogging/externalLogActions';

const constants = require('../../json/constants.json');

var activeRequests = new Map<string, any>();
var queuedRequests = new Set<string>();

/**
 * Create an promise object which can be resolved/rejected outside of the promise.
 */
function createDeferredPromise() {
    let resolve;
    let reject;

    const promise = new Promise((thisResolve, thisReject) => {
        resolve = thisResolve
        reject = thisReject
    })

    return Object.assign(promise, { resolve, reject })
}

/**
 * This runs just before an axios request is sent
 * The 'return config' part is what will trigger the actual request to be sent.
 */
axios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
    if (config.method === 'get') {
        const activeRequest = activeRequests.has(config.url!);
        const queuedRequest = queuedRequests.has(config.url!);

        // if we both have an active request and a queued up one then there is no reason to do anything more, so just skip it
        if (activeRequest && queuedRequest) {
            return Promise.reject({ config, code: constants.REQUEST_BLOCKED } as AxiosError);

            // if we have an active request but NOT a queued up one then we add the request to the queue
        } else if (activeRequest) {
            // step 2: add request to queue and wait for active request to finish
            queuedRequests.add(config.url!);

            return activeRequests.get(config.url!)?.then(() => {    // step 4: when the active request is done then start the queued up request and remove it from the queue
                queuedRequests.delete(config.url!);
                activeRequests.set(config.url!, createDeferredPromise()); // step 5: create a new active request, then the flow returns to step 2 on new requests
                return config;
            });
            // step 1: if we dont have an active request, just create one
        } else {
            activeRequests.set(config.url!, createDeferredPromise());
            return config;
        }
    } else {
        return config;
    }
});

/**
 * This runs when a axios request has finished and returns with a value
 */
axios.interceptors.response.use((response: AxiosResponse<any>) => {
    if (response.config.method === 'get') {
        // step 3: if we have a queued up request, then we need to resolve the active request so that the queued up can start
        if (queuedRequests.has(response.config.url!)) {
            activeRequests.get(response.config.url!)!.resolve();

            // if we dont have a queued up request, we dont care about resolving the active one as there is nothing waiting 
            // for it, so just clean it up from the active request map
        } else {
            activeRequests.delete(response.config.url!);
        }
    }
    return response;
}, (error) => {
    if (error.code !== constants.REQUEST_BLOCKED && error.config.method === 'get') {
        // if we get an 404 or 500 error from the backend then we need to clear it from the queues
        // as we dont want to redo a failed call immediately 
        if (error && error.response && [404, 500].includes(error!.response!.status)) {
            activeRequests.delete(error.config.url!);
            queuedRequests.delete(error.config.url!);
        } else if (queuedRequests.has(error.config.url!)) {
            // for other errors a retry might solve the issue
            activeRequests.get(error.config.url!)!.resolve();
        } else {
            activeRequests.delete(error.config.url!);
        }
    }
    return Promise.reject(error);
});

const AxiosHelper = {
    getAuthenticationHeader: () => {
        let accessToken = authentication.getUserLSData(constants.ACCESS_TOKEN_KEY),
            tokenType = authentication.getUserLSData(constants.TOKEN_TYPE_KEY);
        return tokenType + ' ' + accessToken;
    },
    errorHandler: (err: AxiosError): any => {
        // this is a custom code as a request hasn't been done, it has been intercepted and 
        // blocked due to the same call already being sent and with one in queue
        if (err.code === constants.REQUEST_BLOCKED) {
            // Logger.info(`Call to ${err.config.url} was blocked due to active requeset`);
        } else if (!err.response || err.response.status === 0) {
            event_bus.trigger(constants.INTERNET_CONNECTION_ABORT, err.response?.statusText);
        }

        // if we get a server error status >= 502 trigger unavailable
        // we want to return a status 500 so that we can get more customizable error handling for those errors
        else if (err.response?.status! >= 502) {
            event_bus.trigger(constants.SERVICE_UNAVAILABLE, err.response?.statusText, err.response?.status);
        } else if (err.response?.status === 401) {
            event_bus.trigger(constants.UNAUTHORIZED, err.response?.statusText);
        }

        if (err.code !== constants.REQUEST_BLOCKED && externalLogStore?.enhancedLoggingEnabled()) {
            logToPapertrail('ERROR: Got HTTP status ' + err.response?.status + ' for ' + JSON.stringify(err?.config?.url));
        }

        if (err.response) return err.response!;
        return err.code!;
    },
}

export default AxiosHelper;