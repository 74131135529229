import React from 'react';
import constants from '../../../../json/constants.json';
import Grid from '../../../components/grid';
import Section from '../../../components/section';
import TelephoneNumber from '../../../components/telephone_number';
import { evalToggleState, hideWidget } from '../../../enums/VisibilityState';
import { calcContactClassName, isContactActive } from '../calcContactClassName';
import { WidgetErrorBoundary } from '../../../components/errorBoundaries/widgetErrorBoundary';
import settingsStore from '../../../stores/settings_store';
import classNames from 'classnames';

class ContactsWidget extends React.Component {
  isAdvanceScheduleHide = settingsStore.getValueByKey(
    'ADVANCED_SCHEDULE_HIDE',
    'boolean',
  );
  renderRow(headers, key) {
    return (resource, row_index) => {
      const isActive = isContactActive(resource);
      return (
        <tr key={resource[key] + '_' + row_index} className={
          classNames({
            'bg-c-gray-primary': !isActive,
            'hide-inactive-table-row': this.isAdvanceScheduleHide && !isActive,
          })
        }>
          {headers.map(header => {
            let value;
            switch (header.key) {
              case 'NameAndEmail':
                const email = `mailto:${resource.Email}`;
                value = (
                  <a href={email} target="_blank">
                    <span>{resource.Name}</span>
                    <br />
                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{resource.Email}</span>
                  </a>
                );
                break;
              case 'Telephone1':
                value = [resource.Telephone1, resource.Telephone2, resource.Telephone3]
                  .filter(telephone => !!telephone)
                  .map((telephone, telephone_index) => (
                    <TelephoneNumber
                      key={header.key + '_' + telephone + '_' + telephone_index}
                      value={telephone}
                      contactId={resource.ContactId}
                      resource={resource}
                    />
                  ));
                break;
              default:
                value = Grid.formatValue(header, resource, resource[header.key]);
                break;
            }
            return <td key={header.key}>{value}</td>;
          })}
        </tr>
      );
    };
  }

  renderMoreDetailsButton() {
    return !!this.props.contacts.find(contact => contact.ScheduleItems &&
      contact.ScheduleItems.length || (contact.StartTime && contact.EndTime));
  }

  render() {
    const {
      instance,
      contacts,
      customContactsHeaders,
      contactRowKey,
      toggleType,
      toggleState,
      widget,
      alarmT
    } = this.props;

    const defaultContactsHeaders = [
      {
        key: 'Name',
        content: alarmT('NAME')
      },
      {
        key: 'Relation',
        content: alarmT('RELATIONSHIP')
      },
      {
        key: 'Telephone1',
        telephone_format: 'yes',
        content: alarmT('TELEPHONE')
      },
      {
        key: 'Address',
        content: alarmT('ADDRESS')
      }
    ];

    const contactsHeaders = !customContactsHeaders ? defaultContactsHeaders : customContactsHeaders;

    const hasContent = contacts && contacts.length ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'ContactsSection'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        widget={widget}
        addSectionClassName="flex-0-0-auto"
        headerLocKey="CONTACTS"
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        headerLocOptions={{ ns: constants.NS_ALARM }}
        showExtraButton={this.renderMoreDetailsButton()}
        showExtraButtonLocKey="MORE_DETAILS"
        onExtraButtonClick={instance ? instance.showContactsSchedule : () => { }}
        headerContent={this.renderMoreDetailsButton() ? <></> : null}
      >
        {contacts.length ? (
          <Grid
            headers={contactsHeaders}
            table={true}
            wrapperClassName="w-100p"
            tableClassName="form-table  w-100p"
            rowKey={contactRowKey}
            scroll
            renderRow={this.renderRow(contactsHeaders, contactRowKey)}
            rows={contacts.sort((contact_a, contact_b) => {
              if (contact_a.Order === contact_b.Order) {
                return 0;
              }
              return contact_a.Order < contact_b.Order ? -1 : 1;
            })}
          />
        ) : null}
      </Section>
    );
  }
}

export default class ContactsSection extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <ContactsWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
