import React from 'react';
import { logToPapertrail } from '../../actions/externalLogging';
import localization from '../../localization';
import externalLogStore from '../../stores/externalLogging/externalLogActions';
import Section from '../section';
import { Widget } from '../../interfaces/backend_model';
import constants from '../../../json/constants.json';

export class WidgetErrorBoundary extends React.Component {
  state = { error: false, errorMessage: null };
  alarmT = localization.useNSt(constants.NS_ALARM);

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error: true, errorMessage: error.message });

    if (externalLogStore?.enhancedLoggingEnabled()) {
      logToPapertrail('ERROR: ' + JSON.stringify(error));
      logToPapertrail('errorInfo: ' + JSON.stringify(errorInfo));
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Section widget={{} as Widget} addSectionClassName={''}>
          <div className='p-05em'>
            <p className='bold'>{this.alarmT('WIDGET_ERROR')}</p>
            <p>{this.alarmT('WIDGET_ERROR_MESSAGE')}</p>
            { this.state.errorMessage ? (
              <p style={{ color: 'darkred' }}>{this.state.errorMessage}</p>
            ) : null }
          </div>
        </Section>
      )
    } else {
      return this.props.children;
    }
  }
}
