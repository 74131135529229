import * as Logger from 'js-logger';
import { UserDetailsModel } from './interfaces/backend_model';
import dictionariesStore from './stores/dictionaries_store';

class EnumDictionary {
  formatEnum(key: string, value: number, splitter = '') {
    const dictionary = dictionariesStore.getLocalUserEnumsDictionary();
    const keyValue = `${key}${splitter}${value}`;

    if (!dictionary) {
      Logger.error('Dictionary missing!');
      return keyValue;
    }

    const item = dictionary[keyValue];
    return item ? item : '';
  }

  format(obj: UserDetailsModel, key: string) {
    return this.formatEnum(key, obj[key]);
  }

  // Now used only for Gender translation keys: Gender01, Gender02, Gender03
  formatWithAdditional(obj: UserDetailsModel, key: string) {
    const additionalValue = '0';

    // Gender00 translation is "--choose--", no need to show it
    if (obj[key] === 0) {
      return '';
    }

    return this.formatEnum(key + additionalValue, obj[key]);
  }
}

export default new EnumDictionary();
