import React from 'react';

export default class IconCancelCalendar extends React.Component {
  render() {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.60984 6.10983C4.75628 5.96339 4.99372 5.96339 5.14016 6.10983L6 6.96967L6.85983 6.10983C7.00628 5.96339 7.24372 5.96339 7.39017 6.10983C7.53661 6.25628 7.53661 6.49372 7.39017 6.64017L6.53033 7.5L7.39017 8.35983C7.53661 8.50628 7.53661 8.74372 7.39017 8.89017C7.24372 9.03661 7.00628 9.03661 6.85983 8.89017L6 8.03033L5.14016 8.89017C4.99372 9.03661 4.75628 9.03661 4.60984 8.89017C4.46339 8.74372 4.46339 8.50628 4.60984 8.35983L5.46967 7.5L4.60984 6.64017C4.46339 6.49372 4.46339 6.25628 4.60984 6.10983Z" fill="#333333"/>
        <path d="M2.625 0C2.83211 0 3 0.167893 3 0.375V0.75H9V0.375C9 0.167893 9.16789 0 9.375 0C9.58211 0 9.75 0.167893 9.75 0.375V0.75H10.5C11.3284 0.75 12 1.42157 12 2.25V10.5C12 11.3284 11.3284 12 10.5 12H1.5C0.671573 12 0 11.3284 0 10.5V2.25C0 1.42157 0.671573 0.75 1.5 0.75H2.25V0.375C2.25 0.167893 2.41789 0 2.625 0ZM1.5 1.5C1.08579 1.5 0.75 1.83579 0.75 2.25V10.5C0.75 10.9142 1.08579 11.25 1.5 11.25H10.5C10.9142 11.25 11.25 10.9142 11.25 10.5V2.25C11.25 1.83579 10.9142 1.5 10.5 1.5H1.5Z" fill="#333333"/>
        <path d="M1.875 3C1.875 2.79289 2.04289 2.625 2.25 2.625H9.75C9.95711 2.625 10.125 2.79289 10.125 3V3.75C10.125 3.95711 9.95711 4.125 9.75 4.125H2.25C2.04289 4.125 1.875 3.95711 1.875 3.75V3Z" fill="#333333"/>
      </svg>
    );
  }
}
