/* global Twilio */
import { Call, Device } from '@twilio/voice-sdk';
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';
import log from '@twilio/voice-sdk/es5/twilio/log';
import EventEmitter from 'events';
import * as Logger from 'js-logger';
import { fetchActiveCalls } from '../actions/calls';
import { completedTwilioCall } from '../actions/twilio';
import ajax from '../ajax';
import AppDispatcher from '../app_dispatcher';
import AuthStore from '../authentication';
import {
  CallDetailsModel,
  CallModel,
  CallerByUsernameModel,
  DeviceModel,
  MessageModel,
  TwilioCallDetailsModel,
  TwilioPayloadModel,
  UnholdCallModel
} from '../interfaces/backend_model';
import {
  isUsername,
  pruneUsername
} from '../utilities/user';
import CallsStore, { reservedIncallCommandTypes } from './calls/calls_store';
import GroupsStore from './groups_store';
import storageStore from './mobx/StorageStore';
import SettingsStore from './settings_store';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');
const KEYBOARD_CODES = require('../../json/key_codes.json');

const callsMap = {};

const silentAudio = require('../../audio/100msSilent.wav');
const RINGING_SOUND = {
  DEFAULT: '',
  SILENT: silentAudio
}

export const storageKeysMap = {
  RINGTONE_SELECTED_DEVICE: 'currentTwilioRingtoneDevice',
  SPEAKER_SELECTED_DEVICE: 'currentTwilioSpeakerDevice',
  TWILIO_DISCONNECT_SOUND_ENABLED: 'twillioDisconnectSoundEnabled',
  AUTO_GAIN_CONTROL: 'AUTO_GAIN_CONTROL',
  NOISE_SUPPRESSION: 'NOISE_SUPPRESSION',
  TWILIO_CODEC_LIST: 'TWILLIO_CODEC_LIST'
};

const TWILIO_ERROR_CODES = {
  JWT_EXPIRED: 31205,
  DENIED_MICRO_OR_CAMERA: 31402,
  CANT_ESTABLISH_CONNECTION: 31000,
  ICE_ERROR: 53405,
  CANNOT_REGISTER_TOKEN_NOT_VALIDATED: 31204
};

/**
 * Get username or sip username if this is username or sip username
 * If not -> return number
 * @param numberOrUsername
 * @returns {*}
 */
export function getUsernameOrNumber(numberOrUsername: string) {
  if (isUsername(numberOrUsername)) {
    const username = pruneUsername(numberOrUsername);
    const UserByTwilio = GroupsStore.getUserByTwilioId(username);

    if (!UserByTwilio) {
      return username;
    }

    return UserByTwilio.Name;
  }

  return numberOrUsername;
}

function isRightControlKey(event: KeyboardEvent) {
  return (
    event.keyCode === KEYBOARD_CODES.ctrl && (event.code === 'ControlRight' || event.location === 2)
  );
}

export interface TwilioState {
  ready: boolean;
  error: boolean;
  errorMsg: string;
  isbusy: boolean;
  onHold: boolean;
  isCanceled: boolean;
  pendingAccept: boolean;
  lastDialedNumber: string;
  settings: {
    DTMF_PTT_TALK: number | null;
    DTMF_PTT_LISTEN: number | null;
    PERIODIC_DTMF_TONE: number | null;
    PERIODIC_DTMF_INTERVAL: number | null;
    AUTO_GAIN_CONTROL: boolean | true;
    NOISE_SUPPRESSION: boolean | true;
    TWILIO_CODEC_LIST: string | 'opus';
  };
  isDeniedOnBackend: boolean;
  isHoldTransaction: boolean;
  isHoldPending: boolean;
  needUpdateCallsList: boolean;
  failedEstablishCall: boolean;
}

class TwilioStore extends EventEmitter {
  callDetails: CallDetailsModel;
  isDevMode: boolean;
  isDebugMode: boolean;
  dispatchToken: string;
  state: TwilioState;
  periodicDTMFUpdateHandler: number;
  device: Device;
  keyDownRef: any;
  keyUpRef: any;
  availableAudioDevices: DeviceModel[] = [];

  constructor() {
    super();

    // initialize default state
    this.getInitialData();

    this.isDebugMode = window.location.href.includes('twillioDebug=true') ? true : false;

    // subscribe to external stores

    // register to app dispatcher
    this.dispatchToken = AppDispatcher.register(this.onDispatch.bind(this));
  }

  /**
   * Get TwilioStore state
   * @returns {{ready: boolean, error: boolean, errorMsg: string, isbusy: boolean, pendingAccept: boolean}|*}
   */
  getState() {
    return this.state;
  }

  getInitialData() {
    this.state = {
      ready: false,
      error: false,
      errorMsg: '',
      isbusy: false,
      onHold: false,
      isCanceled: false,
      pendingAccept: false,
      lastDialedNumber: '',
      settings: {
        DTMF_PTT_TALK: null,
        DTMF_PTT_LISTEN: null,
        PERIODIC_DTMF_TONE: null,
        PERIODIC_DTMF_INTERVAL: null,
        AUTO_GAIN_CONTROL: true,
        NOISE_SUPPRESSION: true,
        TWILIO_CODEC_LIST: 'opus'
      },
      isDeniedOnBackend: false,
      isHoldTransaction: false, // used to track hold state between ajax calls and Twilio disconnect
      isHoldPending: false, // used to track ajax request state (pending)
      needUpdateCallsList: false, // used for update calls list ("api/calls")
      failedEstablishCall: false
    };

    this.callDetails = {
      sid: undefined,
      parentSid: undefined,
      started: null, // js date
      ended: null, // js date
      destination: undefined, // string
      from: null, // string
      elapsedTime: null, // seconds,

      twilioCall: null, // twilio object for current connection
      isCurrent: false, // is
      autoHangUpAt: null,
      status: undefined // updated only on call disconnect event
    };
  }

  /**
   * Mark that twilio is denied on backend
   */
  setAsDenied() {
    this.state.isDeniedOnBackend = true;
  }

  /**
   * Mark that twilio is allowed on backend
   */
  setAsAllowed() {
    this.state.isDeniedOnBackend = false;
  }

  /**
   * Get current call details
   * @returns {{started: null, ended: null, destination: null, from: null, elapsedTime: null, twilioConnection: null, isCurrent: boolean}|*}
   */
  getCallDetails() {
    return this.callDetails;
  }

  /**
   * Handler for AppDispatcher events
   * @param payload
   */
  onDispatch({ action: payload }: { action: TwilioPayloadModel }) {
    switch (payload.type) {
      case constants.TWILIO_CALL_START:
        this.startCall(payload);
        break;
      case constants.TWILIO_CALL_STOP:
        Logger.info('onDispatch TWILIO_CALL_STOP');
        this.stopCall();
        break;
      case constants.TWILIO_ACCEPT_CURRENT_CALL:
        this.acceptCurrentCall();
        break;
      case constants.TWILIO_DECLINE_CURRENT_CALL:
        this.declineCurrentCall();
        break;
      case constants.TWILIO_HOLD_CURRENT_CALL:
        Logger.info('onDispatch store TWILIO_HOLD_CURRENT_CALL');
        if (this.isCloudIntegration()) {
          Logger.info('if isCloudIntegration holdCurrentCall');
          this.holdCurrentCall();
        }
        break;
      case constants.TWILIO_SEND_DTMF:
        this.sendDTMF(payload.dtmf);
        break;
      case constants.TWILIO_JOIN_CALL:
        this.joinCall(payload.call);
        break;
      case constants.TWILIO_LEAVE_CALL:
        this.leaveCall(payload.call);
        break;
      case constants.TWILIO_UNHOLD_CALL:
        this.unHoldCallBySid(payload.callSid);
        break;
      case constants.TAKE_ALARM_CALL:
        // send only if there are active call
        if (this.callDetails.isCurrent) {
          if (this.isCloudIntegration()) {
            this.holdCurrentCall();
          }
        }
        this.takeAlarmCall(payload.alarmId);
        break;
      case constants.ACTION_CLEAR_ALARM:
        Logger.info('ACTION_CLEAR_ALARM and if payload.hang_up');
        if (payload.hang_up) {
          Logger.info('stopCall in action ACTION_CLEAR_ALARM');
          this.stopCall();
        }
        break;
      case constants.LOGOUT_CLEAR:
        try {
          this.device?.destroy();
        } catch (e) {
          Logger.error('Failed to terminate connections or Twilio object error', e);
        }
        this.getInitialData();
        break;
      case constants.TWILIO_CLOSE_ERROR_POPUP:
        this.state.error = false;
        this.state.errorMsg = '';
        break;
      case constants.TWILIO_SEND_SMS:
        this.sendSMS(payload.dtmf, payload.alarmId);
        break;
    }
  }

  checkForInCallCommandsToSend() {
    let lastEvent: KeyboardEvent | null;
    const heldKeys = {};
    const { settings } = this.state;

    const call = CallsStore.calls.find(c => c.CallSid === this.getCallDetails().sid);

    if (call && call.DtmfPeriodic && call.DtmfPeriodicInterval) {
      if (settings.PERIODIC_DTMF_TONE && settings.PERIODIC_DTMF_INTERVAL) {
        if (settings.PERIODIC_DTMF_INTERVAL > 0) {
          this.unSubscribeForPeriodicDTMF();
        }
      }
      if (+call.DtmfPeriodicInterval > 0) {
        const period = Number(call.DtmfPeriodic);
        if (!isNaN(period)) {
          this.subscribeForPeriodicDTMF(Number(call.DtmfPeriodicInterval), period);
        }
      }
    }

    // Sends ptt_talk on keydown event, is setup when a call is running
    const ptt_talk = call?.InCallCommands?.find(cmd => cmd.Type === reservedIncallCommandTypes.PTT_TALK);
    if (call && ptt_talk) {
      // Remove possible event listeners to avoid multiple calls to the backend
      document.removeEventListener('keydown', this.keyDownRef);
      this.keyDownRef = (event: KeyboardEvent) => {
        if (lastEvent && lastEvent.keyCode === event.keyCode) {
          return;
        }

        lastEvent = event;
        heldKeys[event.keyCode] = true;

        // Shortcuts
        // ignore all events that is not right ctrl key
        if (isRightControlKey(event)) {
          // send only if there are active call
          if (this.callDetails.isCurrent) {
            if (ptt_talk.DTMF) {
              Logger.log('CTRL pressed and there are an active call, sending this.sendDTMF(', ptt_talk.DTMF, ')');
              this.sendDTMF(ptt_talk.DTMF);
            } else {
              Logger.log('CTRL pressed and there are an active call, sending AlarmsStore.sendCustomTransmitterCommand(', call.GlobalAlarmId, ',', ptt_talk.CommandId, ',', ptt_talk.Command, ')');
              CallsStore.sendCustomTransmitterCommand(call.GlobalAlarmId, ptt_talk.CommandId, ptt_talk.Command);
            }
          }
        }
      };
      document.addEventListener('keydown', this.keyDownRef);
    }

    // Sends ptt_listen on keyup event, is setup when a call is running
    const ptt_listen = call?.InCallCommands?.find(cmd => cmd.Type === reservedIncallCommandTypes.PTT_LISTEN);
    if (call && ptt_listen) {
      // Remove possible event listeners to avoid multiple calls to the backend
      document.removeEventListener('keyup', this.keyUpRef);
      this.keyUpRef = (event: KeyboardEvent) => {
        lastEvent = null;
        delete heldKeys[event.keyCode];

        // Shortcuts
        // ignore all events that is not right ctrl key
        if (isRightControlKey(event)) {
          // send only if there are active call
          if (this.callDetails.isCurrent) {
            if (ptt_listen.DTMF) {
              Logger.log('CTRL released and there are an active call, sending this.sendDTMF(', ptt_listen.DTMF, ')');
              this.sendDTMF(ptt_listen.DTMF);
            } else {
              Logger.log('CTRL released and there are an active call, sending AlarmsStore.sendCustomTransmitterCommand(', call.GlobalAlarmId, ',', ptt_listen.CommandId, ',', ptt_listen.Command, ')');
              CallsStore.sendCustomTransmitterCommand(call.GlobalAlarmId, ptt_listen.CommandId, ptt_listen.Command);
            }
          }
        }
      };
      document.addEventListener('keyup', this.keyUpRef);
    }
  }

  sendDTMF(digits: string | number | undefined | null) {
    digits = digits?.toString();
    Logger.log('Sending DTMF', digits);

    if (!this.getCallDetails().twilioCall || !this.callDetails.isCurrent) {
      Logger.error(
        'Twilio Call does not exist. Probably call is interrupted or does not start'
      );
      this.state.error = true;
      this.state.errorMsg = `TWILIO_ERROR_DTMF_WHILE_NOT_CONNECTED`;
      this.notify(constants.TWILIO_CALL_ERROR);
      return;
    }

    if (digits) {
      // https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callsenddigitsdigits
      this.getCallDetails().twilioCall?.sendDigits(digits);
    } else {
      Logger.info('Digits are not defined, will not play any DTMF tones through Twilio (', digits, ')');
    }
  }

  updateAutoHangUp(data: UnholdCallModel) {
    const date = new Date(Date.parse(data.AutoHangUpAt));
    if (!isNaN(date.getTime())) {
      this.callDetails.autoHangUpAt = date;
      return;
    }
    Logger.warn(`Can't parse "AutoHangUpAt" property: ${data.AutoHangUpAt}`);
  }

  takeAlarmCall(alarmId: string) {
    ajax.postByDesc(
      false,
      endpoints.TAKE_ALARM_CALL,
      { alarmId },
      (err: string | null, xhr: XMLHttpRequest, data: any) => {
        if (err || !data || !data.Success) {
          Logger.error('Error during takeAlarmCall', err, data);
          this.state.error = true;
          this.state.errorMsg = `TWILIO_ERROR_TAKE_CALL`;
          this.notify(constants.TWILIO_CALL_ERROR);
          return;
        }

        callsMap[data.CallerId] = data.CallerText;
        if (data.CallerText) {
          this.callDetails.from = data.CallerText;
        }
        this.state.error = false;
        this.state.errorMsg = '';

        this.updateAutoHangUp(data);
      }
    );
  }

  /**
   * Unhold call by sid
   * @param sid
   */
  unHoldCallBySid(sid: string) {
    ajax.postByDesc(
      false,
      endpoints.CALL_UNHOLD,
      { sid },
      (err: string | null, xhr: XMLHttpRequest, data: UnholdCallModel) => {
        if (err || !data || !data.Success) {
          Logger.error('Error during unHoldCallBySid ', err, data);
          this.state.error = true;
          this.state.errorMsg = `TWILIO_ERROR_UNHOLD`;
          this.notify(constants.TWILIO_CALL_ERROR);

          // CallerId, CallerText
          return;
        }

        this.state.isHoldTransaction = true;

        callsMap[data.CallerId] = data.CallerText;
        if (data.CallerText) {
          this.callDetails.from = data.CallerText;
        }
        this.state.error = false;
        this.state.errorMsg = '';

        this.updateAutoHangUp(data);
      }
    );
  }

  /**
   * Join call
   * @param call
   */
  joinCall(call: CallModel) {
    ajax.postByDesc(
      false,
      endpoints.CALL_JOIN,
      { callsid: call.CallSid, conference: call.JoinConference },
      (err: string | null, xhr: XMLHttpRequest, data: UnholdCallModel) => {
        if (err) {
          Logger.error('Error during joinCall ', err, data);
          this.state.error = true;
          this.state.errorMsg = `TWILIO_ERROR_JOIN`;
          this.notify(constants.TWILIO_CALL_ERROR);

          return;
        }

        this.state.error = false;
        this.state.errorMsg = '';

        this.updateAutoHangUp(data);
      }
    );
  }

  /**
  * Get call details from Twilio
  * Created in order to get the possible 'busy' status of a completed call
  * @param call
  */
  getCallDetailsFromTwilio(call: Call) {
    ajax.getByDescWithData(
      false,
      endpoints.FETCH_CALL_DETAILS,
      { callsid: call.parameters.CallSid, conferencename: call.customParameters.get('GlobalAlarmId') ?? '' },
      (
        err: string | null,
        xhr: XMLHttpRequest,
        response: { data: TwilioCallDetailsModel; errors: string | string[] | null }
      ) => {
        if (err) {
          Logger.error('Error during getCallDetailsFromTwilio ', err, response);
          return;
        }

        this.callDetails.status = response.data.CallStatus;
        this.callDetails.sid = response.data.CallSid;
        this.callDetails.parentSid = response.data.ParentCallSid;

        if (this.callDetails.status === 'busy') {
          Logger.info('notify TWILIO_CALL_BUSY');
          this.notify(constants.TWILIO_CALL_BUSY);
        } else if (this.callDetails.status === 'completed') {
          completedTwilioCall();
        }
      }
    );
  }

  /**
   * Leave call
   * @param call
   */
  leaveCall(call: CallModel) {
    ajax.postByDesc(
      false,
      endpoints.CALL_LEAVE,
      { callsid: call.JoinCallSid, conference: call.Conference },
      (err: string | null, xhr: XMLHttpRequest, data: UnholdCallModel) => {
        if (err) {
          Logger.error('Error during leaveCall ', err, data);
          this.state.error = true;
          this.state.errorMsg = `TWILIO_ERROR_LEAVE`;
          this.notify(constants.TWILIO_CALL_ERROR);

          return;
        }

        this.state.error = false;
        this.state.errorMsg = '';

        this.updateAutoHangUp(data);
      }
    );
  }

  /**
   * Hold current call if in call
   */
  holdCurrentCall() {
    this.state.isHoldPending = true;
    Logger.info('function holdCurrentCall before ajax');

    ajax.postByDesc(
      false,
      endpoints.CALL_HOLD,
      {},
      (err: string | null, xhr: XMLHttpRequest, data: any) => {
        this.state.isHoldPending = false;
        Logger.info('isHoldPending = false, response');

        if (err || !data) {
          this.state.error = true;
          this.state.errorMsg = 'Error while hold call'; // FIXME: localization support
          this.notify(constants.TWILIO_CALL_ERROR);
          Logger.info('response has error and notify TWILIO_CALL_ERROR');

          return;
        }
        Logger.info('response ok, stopCall, isHoldTransaction = true');

        this.state.isHoldTransaction = true;
        this.stopCall();

        CallsStore.fetchActiveCalls();
      }
    );
  }

  /**
   * Accept current call if pending
   */
  acceptCurrentCall() {
    if (this.callDetails.isCurrent && this.state.pendingAccept) {
      this.state.pendingAccept = false;
      this.state.isHoldTransaction = false;
      this.callDetails.twilioCall?.accept();
      this.notify(constants.TWILIO_ACCEPT_CURRENT_CALL);
    }
  }

  /**
   * Reject current call if pending
   */
  declineCurrentCall() {
    if (this.callDetails.isCurrent && this.state.pendingAccept) {
      this.state.pendingAccept = false;
      this.state.isHoldTransaction = false;
      this.callDetails.twilioCall?.reject();
    }
  }

  /**
   * Triggers call to number or user
   * @param payload
   */
  async startCall(payload: {
    globalAlarmId: string;
    number: string;
    type: string;
    userId: string | number;
  }) {
    if (this.state.isDeniedOnBackend) {
      this.state.error = true;
      this.state.errorMsg = 'TWILIO_ERROR_ON_INIT';
      this.notify(constants.TWILIO_INIT_CONNECTION_ERROR);
      return;
    }

    if (this.state.isbusy) {
      if (payload.number && this.state.lastDialedNumber !== payload.number) {
        const params = {
          To: payload.number,
          GlobalAlarmId: payload.globalAlarmId
        };
        this.state.failedEstablishCall = true;
        ajax.postByDesc(
          false,
          endpoints.CALL_ESTABLISH_FAILED,
          params,
          (err: string | null, xhr: XMLHttpRequest, data: any) => {
            if (err || !data) {
              Logger.error('Failed to start a call: ', err);
            }
          }
        );
      }
      return;
    }

    this.state.isbusy = true;
    let connectOptions: Device.ConnectOptions = {};
    if (payload.number) {
      this.state.lastDialedNumber = payload.number;
      connectOptions = {
        params: {
          To: payload.number,
          GlobalAlarmId: payload.globalAlarmId
        }
      };
    } else {
      connectOptions = {
        params: {
          To: payload.userId.toString(),
          GlobalAlarmId: payload.globalAlarmId
        }
      };
    }

    this.addEventListenersToCall(await this.device.connect(connectOptions));
  }

  /**
   * Add event listeners to the Call object
   */
  addEventListenersToCall(call: Call) {
    call.on('cancel', () => { this.onCancel() });
    call.on('disconnect', c => { this.onDisconnect(c) });
    call.on('accept', c => { this.onAccept(c) });
  }

  /**
   * Remove event listeners from the Call object
   */
  removeEventListenersFromCall(call: Call) {
    call.off('cancel', () => { this.onCancel() });
    call.off('disconnect', c => { this.onDisconnect(c) });
    call.off('accept', c => { this.onAccept(c) });
  }
  /**
   * Stop any active call
   */
  stopCall() {
    Logger.info('function stopCall');

    if (this.isCloudIntegration()) {
      const call = CallsStore.calls.find(c => c.CallSid === this.callDetails.sid);
      let setDTMF = SettingsStore.getValueByKey('PLAYDIGIT_0_ONHANGUP_CONFERENCE', 'number');

      //check if CurenaUser setting is in use
      if (SettingsStore.getValueByKey('CurenaUser', 'boolean')) {
        setDTMF = 2;
      } else if (setDTMF != null) { //if not Curena then use the input digit from settings
        if (setDTMF < 1) {
          setDTMF = 1;
        } else if (setDTMF > 5) {
          setDTMF = 5;
        }
      }

      if (call && call.Dtmf) {
        Logger.log('Stopping call with DTMF tone', call);
        this.sendDTMF(call.Dtmf);
        setTimeout(() => this.device.disconnectAll(), 1000);
        Logger.info('call && call.dtmf Timeout disconnectAll');
        return;
      }

      if (setDTMF && this.state.isbusy && !this.state.isHoldPending) {
        Logger.log('Stopping call with DTMF tone 0');
        this.sendDTMF(0);
        setTimeout(() => this.device.disconnectAll(), setDTMF * 1000);
        Logger.info('DTMF 0, Timeout disconnectAll');
        return;
      }

      this.unSubscribeForPeriodicDTMF();
      Logger.warn('Regular stop call');
      Logger.info('unSubscribe && Device.disconnectAll');
      this.device.disconnectAll();
    }
  }

  /**
   * Send SMS
   * @param SMSData
   */
  sendSMS(SMSData: MessageModel, alarmId: string) {
    ajax.postByDesc(
      false,
      endpoints.SEND_SMS,
      { alarmId, ...SMSData },
      (err: string | null, xhr: XMLHttpRequest) => {
        if (err) {
          Logger.error('Error during sendSMS ', err);
          this.state.error = true;
          this.state.errorMsg = `SEND_SMS_ERROR`;
          this.notify(constants.TWILIO_SEND_SMS_ERROR);

          return;
        }

        this.state.error = false;
        this.state.errorMsg = '';
      }
    );
  }

  isCloudIntegration() {
    if (this.state.isDeniedOnBackend) {
      return false;
    }

    return (
      AuthStore.ls_get([AuthStore.getUserId(), constants.TELEPHONY_KEY]) ===
      constants.TELEPHONY_CLOUD
    );
  }

  /**
   * Get system available output devices
   * If twilio is not enabled -> null returned
   * @returns [{label: string, id: string}] or null
   */
  getAvailableDevices() {
    const devices: DeviceModel[] = [];
    if (this.isCloudIntegration()) {
      this.device?.audio?.availableOutputDevices?.forEach((device, key) => {
        devices.push({
          label: device.label,
          id: key
        });
      });
    }
    return devices;
  }

  /**
  * Check if device is available
  * If twilio is not enabled -> false returned
  * @returns boolean
  */
  isAvailableDevice(id: string) {
    if (this.isCloudIntegration()) {
      return this.getAvailableDevices()?.some(device => device.id === id);
    }
    return false;
  }

  restoreLastSelectedMediaDevices() {
    // restore last selected ringtone & speaker devices
    const lastSelectedRingtoneDeviceId = storageStore.getItem(
      storageKeysMap.RINGTONE_SELECTED_DEVICE
    );
    const lastSelectedSpeakerDeviceId = storageStore.getItem(
      storageKeysMap.SPEAKER_SELECTED_DEVICE
    );

    if (lastSelectedRingtoneDeviceId) {
      this.setRingtoneDevice(lastSelectedRingtoneDeviceId);
    }
    if (lastSelectedSpeakerDeviceId) {
      this.setSpeakerDevice(lastSelectedSpeakerDeviceId);
    }
  }


  /**
   * Get current Twilio ringtone device
   * If twilio is not enabled -> null returned
   * @returns {label: string, id: string} or null
   */
  getCurrentRingtoneDevice() {
    if (this.isCloudIntegration()) {
      let foundedDevice = null;
      let isDeviceFound = false;
      const lastSelectedRingtoneDeviceId = storageStore.getItem(
        storageKeysMap.RINGTONE_SELECTED_DEVICE
      );

      // case: we get default selected device
      this.device?.audio?.ringtoneDevices.get().forEach((device: any, id: any) => {
        if (!isDeviceFound) {
          foundedDevice = {
            label: device.label,
            id: device.deviceId
          };

          isDeviceFound = true;
        }
      });

      // case: we should restore last selected device from localStorage
      if (lastSelectedRingtoneDeviceId) {
        // get all devices
        const availableDevices = this.getAvailableDevices();

        let matchedDevice;
        if (availableDevices) {
          // found in available devices
          matchedDevice = availableDevices.find(
            device => device.id === lastSelectedRingtoneDeviceId
          );
        }

        // if match -> restore
        if (matchedDevice) {
          foundedDevice = matchedDevice;
        }
      }

      return foundedDevice;
    }

    return null;
  }

  /**
   * Sets ringtone device by ID
   * If twilio is not enabled -> do not do nothing
   */
  setRingtoneDevice(id: string) {
    if (this.isCloudIntegration()) {
      if (this.isAvailableDevice(id)) {
        storageStore.setItem(storageKeysMap.RINGTONE_SELECTED_DEVICE, id);
        this.device.audio?.ringtoneDevices.set(id);
      } else {
        storageStore.removeItem(storageKeysMap.RINGTONE_SELECTED_DEVICE);
      }
    }
  }

  /**
   * Test ringtone device
   */
  testRingtoneDevice() {
    this.device.audio?.ringtoneDevices.test();
  }

  /**
   * Get current Twilio speaker device
   * If twilio is not enabled -> null returned
   * @returns {label: string, id: string} or null
   */
  getCurrentSpeakerDevice() {
    if (this.isCloudIntegration()) {
      let foundedDevice = null;
      let isDeviceFound = false;

      const lastSelectedSpeakerDeviceId = storageStore.getItem(
        storageKeysMap.SPEAKER_SELECTED_DEVICE
      );

      // case: we get default selected device
      this.device?.audio?.speakerDevices.get().forEach((device: any, id: any) => {
        if (!isDeviceFound) {
          foundedDevice = {
            label: device.label,
            id: device.deviceId
          };

          isDeviceFound = true;
        }
      });

      // case: we should restore last selected device from localStorage
      if (lastSelectedSpeakerDeviceId) {
        // get all devices
        const availableDevices = this.getAvailableDevices();

        let matchedDevice;
        if (availableDevices) {
          // found in available devices
          matchedDevice = availableDevices.find(
            device => device.id === lastSelectedSpeakerDeviceId
          );
        }

        // if match -> restore
        if (matchedDevice) {
          foundedDevice = matchedDevice;
        }
      }

      return foundedDevice;
    }

    return null;
  }

  /**
   * Sets speaker device by ID
   * If twilio is not enabled -> do not do nothing
   */
  setSpeakerDevice(id: string) {
    if (this.isCloudIntegration()) {
      if (this.isAvailableDevice(id)) {
        storageStore.setItem(storageKeysMap.SPEAKER_SELECTED_DEVICE, id);
        this.device.audio?.speakerDevices.set(id);
      } else {
        storageStore.removeItem(storageKeysMap.SPEAKER_SELECTED_DEVICE);
      }
    }
  }

  /**
   * Test speaker device
   */
  testSpeakerDevice() {
    this.device.audio?.speakerDevices.test();
  }

  /**
   * Set twillio disconnect audio
   */
  toggleDisconnectSound() {
    // TODO: Remove this ugly peace of code when Twilio has added support for disconnect sound in a call:
    // https://github.com/twilio/twilio-voice.js/issues/14
    const currentRingtoneDeviceId = this.getCurrentRingtoneDevice()?.id ?? '';
    const currentSpeakerDeviceId = this.getCurrentSpeakerDevice()?.id ?? '';
    // NOTE: Calling on updateOptions() will remove ALL available audio devices
    // hence we need to return the deviceIds to be able to set the same devices
    this.device.updateOptions(this.getDeviceOptions());
    return [currentRingtoneDeviceId, currentSpeakerDeviceId];
  }

  /**
   * Get Twilio device options
   */
  getDeviceOptions() {
    const twillioDisconnectSoundEnabled = storageStore.getItem(
      storageKeysMap.TWILIO_DISCONNECT_SOUND_ENABLED
    );
    const twillioCodecList =
      SettingsStore.getValueByKey('TWILLIO_CODEC_LIST', 'string') || 'opus';

    // Empty string sets sound to the default songs
    let sound = RINGING_SOUND.DEFAULT;
    if (!twillioDisconnectSoundEnabled) {
      // Workaround because of missing functionality in current Twilio SDK version:
      // https://github.com/twilio/twilio-voice.js/issues/14
      sound = RINGING_SOUND.SILENT;
    }

    const deviceOptions: Device.Options = {
      closeProtection: true,
      codecPreferences: [twillioCodecList],
      logLevel: this.isDebugMode ? log.levels.DEBUG : undefined,
      sounds: {
        incoming: RINGING_SOUND.SILENT,
        outgoing: RINGING_SOUND.SILENT,
        disconnect: sound
      },
      tokenRefreshMs: 30000 //Time between 'tokenWillExpire' event is sent and token expires
    }
    return deviceOptions;
  }

  userApprovedInteraction = () => {
    try {
      this.setup();
      SettingsStore.on(constants.SETTINGS_CHANGE, this.setup.bind(this));
    } catch (ex) {
      Logger.log(ex);
    }
  };

  /**
   * Initialize twilio client
   */
  setup() {
    const { settings } = this.state;
    const token = SettingsStore.getValueByKey('TWILIO_TOKEN');
    const autoanswerFrom = SettingsStore.getValueByKey('TWILIO_AUTOANSWER_FROM');
    const isCloudIntegration = this.isCloudIntegration();

    settings.AUTO_GAIN_CONTROL = SettingsStore.getValueByKey('AUTO_GAIN_CONTROL', 'boolean');
    settings.NOISE_SUPPRESSION = SettingsStore.getValueByKey('NOISE_SUPPRESSION', 'boolean');
    settings.TWILIO_CODEC_LIST = SettingsStore.getValueByKey('TWILLIO_CODEC_LIST', 'string');
    settings.DTMF_PTT_TALK = SettingsStore.getValueByKey('DTMF_PTT_TALK', 'number');
    settings.DTMF_PTT_LISTEN = SettingsStore.getValueByKey('DTMF_PTT_LISTEN', 'number');
    settings.DTMF_PTT_TALK = SettingsStore.getValueByKey('DTMF_PTT_TALK', 'number');
    settings.PERIODIC_DTMF_TONE = SettingsStore.getValueByKey('PERIODIC_DTMF_TONE', 'number');
    settings.PERIODIC_DTMF_INTERVAL = SettingsStore.getValueByKey(
      'PERIODIC_DTMF_INTERVAL',
      'number'
    );
    Logger.log('DTMF Settings for Twilio', settings);

    // Try to setup twilio if token is ok & this is cloud integration mode
    if (token && isCloudIntegration) {
      if ((this.device?.state === 'registered' || this.device?.state === 'registering') && (this.device?.token !== token)) {
        // This Twilio device seems to be working OK, we just need to update the token that is about to expire
        this.device.updateToken(token);
        Logger.debug('Twilio token was about to expire and has been updated');
      } else if (!(this.device?.state === 'registered' || this.device?.state === 'registering')) {
        Logger.debug('Registering new twilio device');
        // Most likely we are initializing Twilio for the first time
        this.device = new Device(token, this.getDeviceOptions());

        // Register events on the device
        this.device.on(Device.EventName.Registered, () => this.onReady());
        this.device.on(Device.EventName.Error, (err: TwilioError) => this.onError(err));
        this.device.on(Device.EventName.TokenWillExpire, () => {
          SettingsStore.loadSettings();
        });
        this.device.on(Device.EventName.Incoming, (call: Call) =>
          this.onIncoming(call, autoanswerFrom)
        );

        // Register events on the AudioHelper
        this.device.audio?.on('deviceChange', (lostActiveDevices: MediaDeviceInfo[]) => this.onAudioDeviceChange(lostActiveDevices))

        this.device.register();
      } else {
        Logger.debug('Twiliostore Setup has run but we dont need to update this.device');
      }

      this.setAsAllowed();
      const autoGainControl =
        storageStore.getItem(storageKeysMap.AUTO_GAIN_CONTROL) === null
          ? true
          : storageStore.getItem(storageKeysMap.AUTO_GAIN_CONTROL);
      const noiseSuppression =
        storageStore.getItem(storageKeysMap.NOISE_SUPPRESSION) === null
          ? true
          : storageStore.getItem(storageKeysMap.NOISE_SUPPRESSION);
      this.device.audio?.setAudioConstraints({ autoGainControl, noiseSuppression });
    }

    // If no token in settings, but this is cloud intergation -> show error modal
    if (!token && isCloudIntegration) {
      this.setAsDenied();

      this.state.error = true;
      this.state.errorMsg = 'TWILIO_ERROR_ON_INIT';

      this.notify(constants.TWILIO_INIT_CONNECTION_ERROR);
    }
  }

  /**
   * Handle when an audio device is found or lost
   * Update availableAudioDevices with available audio devices
   * https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice/device-audio#outputdevicecollection
   */
  onAudioDeviceChange(lostActiveDevices: MediaDeviceInfo[]) {
    // Keeping this event since already setup, not needed currently
  }

  /**
   * Handle call cancel situation
   * https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#cancel-event
   */
  onCancel() {
    this.state.pendingAccept = false;
    this.state.error = false;
    this.state.errorMsg = '';
    this.state.isbusy = false;
    this.state.isCanceled = true;
    this.callDetails.isCurrent = false;
    this.callDetails.autoHangUpAt = null;
    this.callDetails.twilioCall = null;
    Logger.info('onCancel notify TWILIO_CALL_CANCELED');
    this.notify(constants.TWILIO_CALL_CANCELED);
  }

  /**
   * Used to fetch user model when incoming call & update callDetails
   * @param sid
   */
  fetchCallerByUsername(sid: string) {
    ajax.getByDescWithData(
      false,
      endpoints.FETCH_CALLER_BY_ID,
      { sid },
      (err: string | null, xhr: XMLHttpRequest, data: CallerByUsernameModel) => {
        if (err || !data) {
          Logger.error('Map username failed');
          return;
        }

        if (this.state.pendingAccept) {
          this.callDetails.from = data.AdminName;
          this.notify(constants.TWILIO_CALL_PENDING_ACCEPT);
        }
      }
    );
  }

  /**
   * Handle incoming call
   * @param connection
   * @param autoanswerFrom
   */
  onIncoming(call: Call, autoanswerFrom: string) {

    this.addEventListenersToCall(call);

    const fromNumber = call.parameters.From;
    if (callsMap[fromNumber]) {
      this.callDetails.from = callsMap[fromNumber];
    }

    if (!callsMap[fromNumber] && isUsername(fromNumber)) {
      this.fetchCallerByUsername(pruneUsername(fromNumber));
    }

    this.callDetails.from = callsMap[fromNumber] || getUsernameOrNumber(fromNumber);
    this.callDetails.destination = undefined;
    this.callDetails.isCurrent = true;
    this.callDetails.twilioCall = call;

    this.state.isCanceled = false;
    // update call list for incoming calls also with autoanswer
    this.state.needUpdateCallsList = true;
    if (autoanswerFrom && fromNumber === autoanswerFrom) {
      this.state.pendingAccept = false;
      call.accept();
    } else {
      this.callDetails.autoHangUpAt = null;
      this.state.onHold = false;
      this.state.pendingAccept = true;
      this.notify(constants.TWILIO_CALL_PENDING_ACCEPT);
    }
  }

  /**
   * Callback when call is established
   * @param call
   */
  onAccept(call: Call) {
    const { settings } = this.state;

    this.state.isbusy = true;

    this.callDetails.sid = call.parameters.CallSid;
    this.callDetails.destination = call.customParameters.get('To')
      ? getUsernameOrNumber(call.customParameters.get('To') ?? '')
      : getUsernameOrNumber(this.callDetails.from!);
    this.callDetails.started = new Date();
    this.callDetails.isCurrent = true;
    this.callDetails.ended = null;
    this.callDetails.twilioCall = call;

    this.state.errorMsg = '';
    this.state.error = false;
    this.state.isCanceled = false;
    this.state.onHold = false;
    this.state.ready = true;

    if (settings.PERIODIC_DTMF_TONE && settings.PERIODIC_DTMF_INTERVAL) {
      if (settings.PERIODIC_DTMF_INTERVAL > 0) {
        this.subscribeForPeriodicDTMF(settings.PERIODIC_DTMF_INTERVAL, settings.PERIODIC_DTMF_TONE);
      }
    }

    if (this.state.isHoldTransaction) {
      this.state.isHoldTransaction = false;
      this.notify(constants.TWILIO_CALL_RESUMED);
      return;
    }

    this.notify(constants.TWILIO_CALL_STARTED);

    if (this.state.needUpdateCallsList) {
      this.state.needUpdateCallsList = false;
      fetchActiveCalls();
    }
  }

  subscribeForPeriodicDTMF(interval: number, tone: number) {
    this.unSubscribeForPeriodicDTMF();
    this.periodicDTMFUpdateHandler = window.setInterval(() => {
      this.sendDTMF(tone || this.state.settings.PERIODIC_DTMF_TONE);
    }, interval * 1000);
  }

  unSubscribeForPeriodicDTMF() {
    clearInterval(this.periodicDTMFUpdateHandler);
  }

  /**
   * Callback when the media session associated with the Call instance is disconnected.
   * https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#disconnect-event
   * @param call
   */
  onDisconnect(call: Call) {
    Logger.info('onDisconnect');

    this.unSubscribeForPeriodicDTMF();
    document.removeEventListener('keydown', this.keyDownRef);
    this.removeEventListenersFromCall(call);

    // In case of CANT_ESTABLISH_CONNECTION
    if (!this.state.ready) {
      Logger.info('!this.state.ready => return');
      return;
    }

    if (!this.state.isbusy) {
      Logger.info('!this.state.isbusy => return');
      return;
    }

    if (!this.callDetails.started) {
      Logger.warn(
        'Twilio abnormal case. onDisconnect raised, without onConnection.',
        this.state,
        this.callDetails,
        call
      );

      this.callDetails.started = new Date();
    }

    this.callDetails.ended = new Date();
    this.callDetails.elapsedTime = Math.round(
      (this.callDetails.ended.valueOf() - this.callDetails.started.valueOf()) / 1000
    ); // seconds
    this.callDetails.isCurrent = false;
    this.callDetails.autoHangUpAt = null;
    this.callDetails.twilioCall = call;

    // Reset that state 
    this.state.isbusy = false;
    this.state.pendingAccept = false;
    this.state.isCanceled = false;
    this.state.onHold = false;

    if (this.state.isHoldTransaction) { // Call has been placed on hold
      this.state.isHoldTransaction = false;
      this.state.onHold = true;
      Logger.info('notify TWILIO_CALL_HOLDED');
      this.notify(constants.TWILIO_CALL_HOLDED);
      CallsStore.fetchActiveCalls();
      return;
    }

    // Will collect call status from Twilio and notify if it equals to busy
    this.getCallDetailsFromTwilio(call);

    Logger.info('notify TWILIO_CALL_STOPPED');
    this.notify(constants.TWILIO_CALL_STOPPED);
    // Notify for show Notes modal after end alarm call
    if (!this.state.failedEstablishCall) {
      this.notify(constants.SHOW_CALL_NOTES_MODAL);
    }

    CallsStore.fetchActiveCalls();
    this.state.failedEstablishCall = false;
  }

  /**
   * Callback when twilio connected to server
   */
  onReady() {
    const { settings } = this.state;

    this.state.ready = true;
    this.state.error = false;

    this.restoreLastSelectedMediaDevices();

    let lastEvent: any;
    const heldKeys = {};

    /** It is setup when telephone loaded and ready.
     * Does not work on a new call if it is not installed when the page loads
     * And same for DTMF_PTT_LISTEN below
     * */
    if (settings.DTMF_PTT_TALK) {
      Logger.log('Setup event listener for CTRL key for DTMF_PTT_TALK');
      document.addEventListener('keydown', event => {
        if (lastEvent && lastEvent.keyCode === event.keyCode) {
          return;
        }
        const call = CallsStore.calls.find(c => c.CallSid === this.getCallDetails().sid);

        if (call?.InCallCommands?.find(cmd => cmd.Type === reservedIncallCommandTypes.PTT_TALK)) {
          return;
        }

        lastEvent = event;
        heldKeys[event.keyCode] = true;

        // Shortcuts
        // ignore all events that is not right ctrl key
        if (isRightControlKey(event)) {
          // send only if there are active call
          if (this.callDetails.isCurrent) {
            Logger.log('CTRL pressed and there are active call. Sending ', settings.DTMF_PTT_TALK);
            this.sendDTMF(settings.DTMF_PTT_TALK);
          }
        }
      });
    }

    if (settings.DTMF_PTT_LISTEN) {
      Logger.log('Setup event listener for CTRL key for DTMF_PTT_LISTEN');
      document.addEventListener('keyup', event => {
        lastEvent = null;
        delete heldKeys[event.keyCode];

        const call = CallsStore.calls.find(c => c.CallSid === this.getCallDetails().sid);

        if (call?.InCallCommands?.find(cmd => cmd.Type === reservedIncallCommandTypes.PTT_LISTEN)) {
          return;
        }

        // Shortcuts
        // ignore all events that is not right ctrl key
        if (isRightControlKey(event)) {
          // send only if there are active call
          if (this.callDetails.isCurrent) {
            Logger.log(
              'CTRL pressed and there are active call. Sending ',
              settings.DTMF_PTT_LISTEN
            );
            this.sendDTMF(settings.DTMF_PTT_LISTEN);
          }
        }
      });
    }

    SettingsStore.initialized = true;
    this.notify(constants.TWILIO_INIT_READY);
    Logger.log('The device is ready to receive incoming calls.');
  }

  /**
   * Callback when twilio connection failed
   * @param err
   */
  onError(err: TwilioError) {
    Logger.error(err);

    this.state.errorMsg = err.message || 'unknown error happens';
    this.state.error = true;

    // Refresh token if specific error code received from twilio
    // https://www.twilio.com/docs/api/errors/reference
    switch (err.code) {
      case TWILIO_ERROR_CODES.CANT_ESTABLISH_CONNECTION:
      case TWILIO_ERROR_CODES.CANNOT_REGISTER_TOKEN_NOT_VALIDATED:
        this.state.pendingAccept = false;
        this.state.isbusy = false;
        this.state.error = false;
        this.state.errorMsg = '';
        break;
      case TWILIO_ERROR_CODES.DENIED_MICRO_OR_CAMERA:
        this.state.pendingAccept = false;
        this.state.isbusy = false;
        this.state.error = true;
        this.state.errorMsg = 'TWILIO_ERROR_ACCESS_TO_MIC';
        break;
      case TWILIO_ERROR_CODES.ICE_ERROR:
        this.state.pendingAccept = false;
        this.state.isbusy = false;
        this.state.error = true;
        this.state.errorMsg = 'TWILIO_ICE_ERROR';
        break;
      case TWILIO_ERROR_CODES.JWT_EXPIRED:
        SettingsStore.loadSettings();
        break;
    }

    if (err.code !== TWILIO_ERROR_CODES.JWT_EXPIRED) {
      this.notify(constants.TWILIO_CALL_STOPPED);
    }
  }

  /**
   * Subscribe for store updates
   * @param listener
   */
  addEventListener(listener: EventListener) {
    this.addListener(constants.TWILIO_NOTIFY, listener);
  }

  /**
   * Un-subscribe listener from store update
   * @param listener
   */
  removeEventListener(listener: EventListener) {
    this.removeListener(constants.TWILIO_NOTIFY, listener);
  }

  /**
   * Notify listeners about update
   */
  notify(type: string) {
    this.emit(constants.TWILIO_NOTIFY, { type });
  }
}

export default new TwilioStore();
