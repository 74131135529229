import React from 'react';
import Linkify from 'react-linkify';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import dateTime from '../../date_time';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import localization from '../../localization';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

class RedBoxWidget extends React.Component {
  render() {
    const { alarmAction, userDetails, toggleType, toggleState, widget, fullscreenMode } = this.props;

    const hasContent = alarmAction ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'RedBox'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        addSectionClassName="flex-0-0-auto red-box"
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        fullscreenMode={fullscreenMode}
        widget={widget}
        headerContent={
          <div className="flex-row-wrap section-header f-15 bold">
            <div className="p-1em flex-1-1-0px">
              <div>{localization.t('OBS', { ns: constants.NS_ALARM })}</div>
            </div>
            <div style={{ marginRight: '0.5em', fontSize: '0.75rem' }}>
              <div>{userDetails.AlarmActionModifiedBy}</div>
              <div>
                {userDetails.AlarmActionModifiedAt
                  ? dateTime.toLocaleDateTime(userDetails.AlarmActionModifiedAt)
                  : null}
              </div>
            </div>
          </div>
        }
      >
        <div className="w-100p red-box">
          <div className="flex-row-wrap bg-c-white-primary p-05em">
            <Linkify
              properties={{ target: '_blank', style: { color: 'blue', fontWeight: 'bold' } }}
            >
              {alarmAction}
            </Linkify>
          </div>
        </div>
      </Section>
    );
  }
}

export class RedBox extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <RedBoxWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
