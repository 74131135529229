/**
 * Format seconds to HH:MM:SS string
 * @param secs {Number}
 * @returns {string}
 */
export function formatSecondsAsTime(secs: number) {
  let hr: number | string = Math.floor(secs / 3600);
  let min: number | string = Math.floor((secs - hr * 3600) / 60);
  let sec: number | string = Math.floor(secs - hr * 3600 - min * 60);

  if (hr < 10) hr = '0' + hr;
  if (min < 10) min = '0' + min;
  if (sec < 10) sec = '0' + sec;
  if (hr) hr = '00';

  return hr + ':' + min + ':' + sec;
}

/**
 * Extracts time (HH:MM) from Javascript Date object to string -> HH:MM
 * @param date {Date}
 * @param separator
 * @returns {string}
 */
export function getTime(date = new Date(), separator = ':') {
  const h = date.getHours(),
    m = date.getMinutes();

  if (isNaN(h) || isNaN(m)) {
    return '--:--';
  }

  return (h < 10 ? '0' + h : h) + separator + (m < 10 ? '0' + m : m);
}

/**
 * Replaces YYYY -> yyyy and DD -> dd in format string because date-fns are using Unicode Tokens
 * https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
 * When backend has been adapted to use lowercase y and d this method can be removed
 * @param format {string}
 * @returns {string}
 */
export function adaptToUnicodeTokens(format: string) {
  if (!format) return;

  var reYYYY = /YYYY/;
  var reDD = /DD/;

  format = format.replace(reYYYY, "yyyy");
  format = format.replace(reDD, "dd");

  return format;
}