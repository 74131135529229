import React from 'react';

export default class IconDoor extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-door"
        x="0px"
        y="0px"
        width="70px"
        height="70px"
        viewBox="0 0 70 70"
      >
        <path
          d={`M51.999,60.499V13.751H17.998l25.5,8.501V69l-25.5-8.501H5.249V1
          h59.502v59.499H51.999z`}
        />
      </svg>
    );
  }
}
