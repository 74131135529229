import React from 'react';
import appDispatcher from '../app_dispatcher';
import localization from '../localization';
import usersStore from '../stores/users/users_store_actions';
import SpinnerOverlay from './spinner_overlay';

const constants = require('../../json/constants.json');

interface Props { }

interface State {
  users: any;
  overlay: boolean;
}

export default class UsersList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      overlay: false,
      users: usersStore.getUsers()
    };
  }

  componentDidMount() {
    usersStore.on(constants.USER_DETAILS_CHANGE, this._onChange);
    appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_USERS });
    if (!this.state.users.length) {
      this.setState({ overlay: true });
    }
  }

  componentWillUnmount() {
    usersStore.off(constants.USER_DETAILS_CHANGE, this._onChange);
  }

  /**
   * render each respondent group
   */
  renderUser(user: { UserId: string; Name: string; TelephoneNumber: string }) {
    let user_id = user.UserId.toString();
    return (
      <label className="flex-row-between form-list-item" key={user_id}>
        <div className="flex-0-0-50p flex-col-around">{user.Name}</div>
        <div className="flex-0-0-50p flex-col-around">{user.TelephoneNumber}</div>
      </label>
    );
  }

  render() {
    return (
      <div className="form-list flex-col p-rel">
        <div className="flex-center form-header-red">{localization.t('USERS')}</div>
        <div className="flex-col">{this.state.users.map(this.renderUser)}</div>
        {this.state.overlay ? <SpinnerOverlay /> : null}
      </div>
    );
  }

  _onChange = () => {
    this.setState({
      users: usersStore.getUsers(),
      overlay: false
    });
  };
}
