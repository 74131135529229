import * as Logger from 'js-logger';

export const filterWidgetSettings = (settingsToCheck: any, handledSettings: string[], widgetName: string): any => {
  if (!settingsToCheck) {
    return {};
  }
  const settings = {...settingsToCheck};

  Object.keys(settings).forEach(key => {
    if (!handledSettings.includes(key)) {
      Logger.warn('There is currently no support for the setting', key, 'in widget', widgetName);
      delete settings.key;
    }
  });
  return settings;
}

export const getSpecificSetting = (settingsToCheck: any, settingToGet: string, widgetName: string) => {
  if (!settingsToCheck) {
    Logger.warn('There are no special settings for the widget', widgetName);
  }

  if (settingsToCheck.hasOwnProperty(settingToGet)) {
    return settingsToCheck[settingToGet];
  } else {
    Logger.warn('There is no setting ', settingToGet, 'in the settings for the widget widget', widgetName);
  }

}
