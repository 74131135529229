import React from "react";
import { Link } from "react-router";
import dateTime from "../../date_time";
import { AlarmModel } from "../../interfaces/backend_model";
import localization from '../../localization';
import AlarmHelpers from "../../stores/alarms/alarm_helpers";
import alarmListStore from "../../stores/alarms/alarm_list_store_actions";
import settingsStore from "../../stores/settings_store";
import Utils from '../../utils';
import IconClock from "../icon_clock";
import { Spacer } from "../spacer";
import { AlarmLogItem } from "./alarm_log_item";

const constants = require('../../../json/constants.json');

interface AlarmHistoryItemProps {
  alarm: AlarmModel;
  alarmId: string;
  templateType: string;
  onLinkClick: (alarm: AlarmModel) => void;
}
export const AlarmHistoryItem = (
  {
    alarm,
    alarmId,
    templateType,
    onLinkClick,
  }: AlarmHistoryItemProps) => {
  const baseURL = `/dashboard/template`;
  const newTemplate = alarm.Template;
  const newId = alarm.AlarmId;

  const to = `${baseURL}/${newTemplate}/${newId}?fromId=${alarmId}&fromTemplate=${templateType}`;
  const alarmT = localization.useNSt(constants.NS_ALARM);
  const alarmType = AlarmHelpers.getAlarmClass({
    active: alarm.Active,
    respondentId: alarm.RespondentId,
    respondentOwn: alarm.RespondentOwn
  });
  const dateAndTime = dateTime.toLocaleDateAndTime(alarm.ServerAlarmTime);
  const className = 'flex-0-0-auto flex-col form-list-item alarm-history-item ';
  const alarmLogs = (alarm.Logs || []).sort((alarmLog_a, alarmLog_b) => {
    if (alarmLog_a?.LastUpdated === alarmLog_b?.LastUpdated) {
      return 0;
    }
    return alarmLog_a?.LastUpdated < alarmLog_b?.LastUpdated ? -1 : 1;
  });
  const settingTimestamp = settingsStore.getValueByKey('TIMESTAMPS', 'string');
  let alarmDate = dateAndTime.date;

  let respondentInitials = alarm.RespondentInitials ? ' (' + alarm.RespondentInitials + ')' : '';
  let iconClock: JSX.Element | null = (
    <div className="alarm-clock-icon">
      <IconClock />
    </div>
  );

  switch (settingTimestamp) {
    case 'TODAY':
      alarmDate = Utils.dateToShow(alarm.ServerAlarmTime, dateAndTime.date);
      iconClock = null;
      break;
  }

  const renderLogs = () => {
    if (!alarmLogs || !alarmLogs.length) {
      return (
        <Spacer size={8} />
      );
    }
    // render logs for alarm
    return Array.isArray(alarmLogs) && alarmLogs.length ? (
      <>
        <div className="horizontal-rule" />
        {alarmLogs.map((alarmLog, index) => (
          <AlarmLogItem key={index} alarmLog={alarmLog} iconClock={iconClock} />
        ))}
      </>
    ) : null;
  };

  return (
    <div className="p-rel flex-0-0-auto m-tb-02em-rl-05em" key={alarm.AlarmId}>
      <Link
        to={to}
        activeClassName="active"
        className={
          className +
          AlarmHelpers.getAlarmClass({
            active: alarm.Active,
            respondentId: alarm.RespondentId,
            respondentOwn: alarm.RespondentOwn
          }) +
          ` ${alarm.AlarmId === alarmId ? 'active-history-alarm' : ''}`
        }
        onClick={() => onLinkClick(alarm)}
      >
        <div className="flex-row">
          <div className="p-t-b-05em alarm-icon-container" style={{ paddingTop: "14px" }}>
            {alarmListStore.getAlarmIcon(alarm)}
          </div>
          <div className="flex-col alarm-data-header w-100p">
            <div className="flex-col">
              <div className="flex-row-between margin-top-05em">
                <div className="flex-col">
                  <div className="alarm-history-text">{alarm.AlarmText + respondentInitials}</div>
                  <div className={`flex-row-between align-center alarm-list-description ${alarmType}`}>

                    ({alarmT(alarmType)})

                  </div>
                </div>
                <div className="flex-col align-end">
                  <div className="flex-row">
                    {iconClock}
                    <div className="alarm-history-time">{dateAndTime.time}</div>
                  </div>
                  <div className="alarm-history-date">{alarmDate}</div>
                </div>
              </div>

              {renderLogs()}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}