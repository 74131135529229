import axios from 'axios';
import * as Logger from 'js-logger';
import { IObservableArray } from 'mobx';
import React from 'react';
import Section from '../../components/section';
import {
  AlarmDetailsModel,
  LinkedModel,
  UserDetailsModel,
  Widget
} from '../../interfaces/backend_model';
import localization from '../../localization';
import settingsStore from '../../stores/settings_store';
import { changeVarsInString } from './CurenaPersonalDetailsSection';

const constants = require('../../../json/constants.json');

const alarmT = localization.useNSt(constants.NS_ALARM);

interface DocumentsModel {
  displayName: string;
  uri: string;
}

interface DeviceListProps {
  alarmDevices: IObservableArray<DocumentsModel>;
  alarmId: string;
  userExternalId: string;
}

// It uses only for Curena template and request for devices places in DynamicsUserInfoSection
export function DevicesList(props: DeviceListProps) {
  const [devices, setDevices] = React.useState<IObservableArray<DocumentsModel>>();
  React.useEffect(
    () => {
      let mounted = true;
      const { alarmDevices, alarmId } = props;
      if (mounted && alarmId && alarmDevices) {
        setDevices(alarmDevices);
      }
      return () => {
        mounted = false;
      };
    },
    [props.alarmDevices]
  );

  const { userExternalId } = props;

  // When the operator clicks the links, they are opened in a new browser window
  const onClickLink = (e: any, url: string) => {
    e.preventDefault();
    const windowWidth = settingsStore.getValueByKey('NEW_WINDOW_WIDTH', 'number') || 1340;
    const windowHeight = settingsStore.getValueByKey('NEW_WINDOW_HEIGHT', 'number') || 1340;

    window.open(
      url,
      '_blank',
      `location=yes,height=${windowHeight},width=${windowWidth},scrollbars=yes,status=yes`
    );
  };

  return (
    <div className="flex-col-start item-ver">
      {userExternalId ? (
        <div className="bold">{alarmT('ADMINISTRATION_EXTERNAL')}</div>
      ) : null
      }
      {userExternalId ? (
        <div key={userExternalId} className="p-t0-r05-25">
          <a
            href={`https://as-dynamics-api.azurewebsites.net/v1/skyresponse/users/${userExternalId}/redirect`}
            target="_blank"
            style={{ color: 'rgb(0, 0, 238)', textDecoration: 'underline' }}
            onClick={e =>
              onClickLink(
                e,
                `https://as-dynamics-api.azurewebsites.net/v1/skyresponse/users/${userExternalId}/redirect`
              )
            }
          >
            {alarmT('JUMP_TO_USER_IN_DYNAMICS')}
          </a>
        </div>
      ) : null}

      {userExternalId && devices &&
        devices.map((el: DocumentsModel, index: number) => (
          <div key={index} className="p-t0-r05-25">
            <a
              href={el.uri}
              style={{ color: 'rgb(0, 0, 238)', textDecoration: 'underline' }}
              onClick={e => onClickLink(e, el.uri)}
              target="_blank"
            >
              {alarmT('JUMP_TO_DEVICE_IN_DYNAMICS').replace('{displayName}', el.displayName)}
            </a>
          </div>
        ))}
    </div>
  );
}

interface DynamicsUserInfoSectionProps {
  toggleState?: string;
  userDetails: UserDetailsModel;
  alarmDetails: AlarmDetailsModel;
  instance: any;
  Linked: LinkedModel;
  widget: Widget;
}

export default function DynamicsUserInfoSection(props: DynamicsUserInfoSectionProps) {
  const [customerInfo, setCustomerInfo] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(
    () => {
      const { userDetails } = props;

      if (userDetails.ExternalId) {
        loadCurenaCustomerInfo(userDetails.ExternalId);
      }
    },
    [props.userDetails]
  );

  const loadCurenaCustomerInfo = async (ExternalId: string) => {
    const { alarmDetails } = props;

    const me = settingsStore.getMe();
    let additionalUrl = '';

    if (alarmDetails) {
      const transmitterExternalId =
        alarmDetails.TransmitterDetails && alarmDetails.TransmitterDetails.externalid;
      if (transmitterExternalId) {
        additionalUrl = 'deviceExternalId=' + transmitterExternalId;
      }
      if (me && me.ExternalId) {
        if (additionalUrl.length) {
          additionalUrl = additionalUrl + '&respondentExternalId=' + me.ExternalId;
        } else {
          additionalUrl = 'respondentExternalId=' + me.ExternalId;
        }
      }
    }

    let usersUrl = `https://as-dynamics-api.azurewebsites.net/v1/skyresponse/users/${ExternalId}?${additionalUrl}`;

    if (process.env.NODE_ENV !== 'production') {
      usersUrl = `/v1/skyresponse/users/${ExternalId}?${additionalUrl}`;
    }

    const response = await getDetailsData({
      URL: usersUrl
    });

    if (response && response.devices && response.devices.length) {
      props.instance.alarmDevices.set(props.alarmDetails.AlarmId, response.devices);
    }

    setCustomerInfo(response);
    setIsLoading(false);
  };

  const getDetailsData = (data: { URL: string }) => {
    const { Linked } = props;

    let header = { 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*' };

    const curenaUserId = settingsStore.getValueByKey('CurenaUserId', 'string') || '';
    const curenaAuth = settingsStore.getValueByKey('CurenaAuth', 'string') || '';

    if (Linked && Linked.HeaderValue && Linked.Header) {
      header[Linked.Header] = changeVarsInString(Linked.HeaderValue);
    } else if (curenaUserId && curenaAuth) {
      header['Authorization'] = 'DYN-CUR-SHA256 ' + curenaUserId + ' ' + curenaAuth;
    }

    return axios
      .get(data.URL, {
        headers: header
      })
      .then(response => {
        return response.data;
      })
      .catch((error: any) => {
        setIsLoading(false);
        setHasError(true);
        Logger.error(error);
      });
  };

  const { toggleState, userDetails, widget } = props;
  if (!userDetails || !userDetails.ExternalId) {
    return null;
  }

  const getContent = () => {
    if (isLoading) {
      return <div className="flex-col-start item-ver">{alarmT('LOADING_DATA')}</div>;
    }
    if (hasError || !customerInfo) {
      return <div className="flex-col-start item-ver">{alarmT('FAILED_LOAD_DATA')}</div>;
    }

    return (
      <div className="d-flex w-100p">
        <div className="p-r-l-1em item-ver" style={{ flex: '1' }}>
          <div className="flex-col-start bold">{alarmT('MEDICAL_DATA')}</div>
          {customerInfo.sensitiveData.length
            ? customerInfo.sensitiveData.map(
              (el: { category: string; data: string }, index: number) => (
                <div key={index} className="p-025em">
                  <div style={{ fontWeight: 'bold' }}>{el.category}:</div>
                  <div>{el.data}</div>
                </div>
              )
            )
            : null}
          <div className="p-t-b-05em">
            <div className="flex-col-start bold">{alarmT('USER_DOCUMENTS')}</div>
            {customerInfo.documents.length
              ? customerInfo.documents.map((el: DocumentsModel, index: number) => (
                <div key={index} className="p-025em">
                  <a
                    href={el.uri}
                    style={{ color: 'rgb(0, 0, 238)', textDecoration: 'underline' }}
                    target="_blank"
                  >
                    {el.displayName}
                  </a>
                </div>
              ))
              : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={constants.ENABLED_TOGGLE}
      toggleState={toggleState ? toggleState : constants.OPENED}
      headerLocKey={alarmT('USER_INFO_SECTION')}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      {getContent()}
    </Section>
  );
}
