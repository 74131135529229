import { Snackbar } from 'material-ui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import localization from '../localization';

interface Props {
  uiStore?: {
    hideSnackbar(): void;
    isSnackbarShow: () => boolean;
    snackbarMessage: string;
  };
}

interface State { }

@inject('uiStore')
@observer
export class MobxUISnackbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  hideSnackbar = () => this.props.uiStore!.hideSnackbar();

  render() {
    const { isSnackbarShow, snackbarMessage } = this.props.uiStore!;

    return (
      <Snackbar
        open={isSnackbarShow}
        message={localization.t(snackbarMessage)}
        autoHideDuration={4000}
        onRequestClose={this.hideSnackbar}
      />
    );
  }
}
