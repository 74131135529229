import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColDef } from "ag-grid-community";
import 'ag-grid-enterprise';
import React, { useMemo } from "react";
import constants from "../../../json/constants.json";
import dateTime from "../../date_time";
import { UserAlarmHistoryModel } from "../../interfaces/backend_model";
import localization from "../../localization";
import SettingsStore from "../../stores/settings_store";
import { dateComparator } from "../../utilities/dateComparator";
import { ModalWindow } from "../modal_window";
import { DateCellRenderer } from "./table_cell_renderers/date_cell_renderer";
import { EventCellRenderer } from './table_cell_renderers/event_cell_renderer';
import { LogCellRenderer } from "./table_cell_renderers/log_cell_renderer";

interface AlarmHistoryModalProps {
  open: boolean;
  onClose: () => void;
  userHistory: UserAlarmHistoryModel[];
  alarmId: string;
}

interface RowDataModel {
  time: {
    date: string;
    time: string;
  };
  event: string;
  reason: string[];
  action: string[];
  note: string[];
  from: string;
  id: string;
}

export const AlarmHistoryModal = (
  {
    open,
    onClose,
    userHistory,
    alarmId,
  }: AlarmHistoryModalProps) => {
  LicenseManager.setLicenseKey(SettingsStore.getValueByKey('AG_GRID_LICENSE'));
  const alarmT = localization.useNSt(constants.NS_ALARM);

  const [rowData, setRowData] = React.useState<RowDataModel[]>([]);
  const [columnDefs,] = React.useState<(ColDef)[]>([
    {
      field: 'time',
      headerName: alarmT('TIME'),
      cellRenderer: "agDateCellRenderer",
      comparator: dateComparator,
      maxWidth: 120,
      minWidth: 120,
    },
    {
      field: 'event',
      headerName: alarmT('ALARM_EVENT'),
      cellRenderer: "agEventCellRenderer"
    },
    {
      field: 'reason',
      headerName: alarmT('REASON'),
      cellRenderer: "agLogCellRenderer"
    },
    {
      field: 'action',
      headerName: alarmT('ACTION'),
      cellRenderer: "agLogCellRenderer"
    },
    {
      field: 'note',
      headerName: alarmT('NOTE'),
      cellRenderer: "agLogCellRenderer"
    },
    {
      field: 'from',
      headerName: alarmT('FROM'),
    }
  ]);

  const defaultColDef = useMemo<any>(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { wordBreak: 'break-word' },
    }
  }, []);
  const autoGroupColumnDef = useMemo<any>(() => {
    return {
      minWidth: 200,
    };
  }, []);

  const mapHistoryToRowData = () => {
    // mapping history data to rowData to be able to render logs in multiple columns
    // depending on the log type
    const rowData = userHistory.map((historyItem) => (
      {
        time: dateTime.toLocaleDateAndTime(historyItem.ServerAlarmTime),
        event: historyItem.AlarmText,
        reason: historyItem.Logs.map((log) => log.LogEventCode === 1 ? log.LogText : null).filter((log) => log),
        action: historyItem.Logs.map((log) => log.LogEventCode === 2 ? log.LogText : null).filter((log) => log),
        note: historyItem.Logs.map((log) => log.LogEventCode !== 1 && log.LogEventCode !== 2 ?
          log.LogText : null).filter((log) => log),
        from: historyItem.RespondentName,
        id: historyItem.AlarmId,
      }
    ));

    setRowData(rowData);
  }

  const onGridReady = () => {
    mapHistoryToRowData();
  }

  const onCellClicked = (e: any) => {
    if (e.column.getColId() === 'event') {
      let newLocationLink = window.location.href.split("/");
      newLocationLink[newLocationLink.length - 1] = e.data?.id as string;
      onClose();
      window.location.href = newLocationLink.join("/");
    }
  }

  return (
    <ModalWindow
      popupClassName='alarm-history-modal'
      title={alarmT('ALARM_HISTORY')}
      body={(
        <div className="ag-theme-alpine alarm-history-ag-grid">
          <AgGridReact
            modules={[
              ClientSideRowModelModule,
              MenuModule,
              SetFilterModule,
              MultiFilterModule,
              ColumnsToolPanelModule,]}
            rowData={rowData}
            frameworkComponents={{
              agDateCellRenderer: DateCellRenderer,
              agLogCellRenderer: LogCellRenderer,
              agEventCellRenderer: EventCellRenderer,
            }}
            columnDefs={columnDefs as any[]}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            onCellClicked={onCellClicked}
            gridOptions={{
              domLayout: 'normal'
            }}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',

                  toolPanelParams: {
                    suppressPivotMode: true,
                    suppressPivots: true,
                    suppressValues: true,
                    suppressRowGroups: true,
                    suppressMenus: true,
                  },
                },
              ],
              defaultToolPanel: '',
            }}
            onGridReady={onGridReady}
            onRowDataUpdated={(e) => {
              e.api.forEachNode((rowNode, index) => {
                if (rowNode.data?.id === alarmId) {
                  rowNode.setSelected(true);
                }
              });
            }}
            detailRowAutoHeight={true}
            localeText={{
              columns: alarmT('COLUMNS'),
              searchOoo: alarmT('SEARCH'),
            }}
          />
        </div>
      )}
      isOpen={open}
      onClose={onClose}
    />
  );
}