import cx from 'classnames';
import Hls from 'hls.js';
import * as Logger from 'js-logger';
import { computed, observable } from 'mobx';
import { Observer } from 'mobx-react';
import 'offline-js';
import PropTypes from 'prop-types';
import React from 'react';
import endpoints from '../../../json/endpoints.json';
import ajax from '../../ajax';
import ExtraSettings from '../../components/extra_settings';
import * as playIcons from '../../components/play_icon';
import PresetPosition from '../../components/preset_position';
import SpinnerOverlay from '../../components/spinner_overlay';
import localization from '../../localization';
import * as cameraApi from '../../utilities/camera_api';
import { sendChoosenCommand } from '../../utilities/camera_api';
import Utils from '../../utils';

function FullScreenIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
        fill="#ffffff"
      />
    </svg>
  );
}

function CameraControls({ alarmId, cameraId, className, disabled, onCameraMoveTo }) {
  return (
    <div className={cx('camera-controls', className)}>
      <button
        className="up"
        onMouseDown={() => onCameraMoveTo(alarmId, cameraId, 0, 1.5, 0)}
        onMouseUp={() => onCameraMoveTo(alarmId, cameraId, 0, 0, 0)}
        disabled={disabled}
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path fill="#fff" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>

      <div className="mid">
        <button
          className="left"
          onMouseDown={() => onCameraMoveTo(alarmId, cameraId, -1.5, 0, 0)}
          onMouseUp={() => onCameraMoveTo(alarmId, cameraId, 0, 0, 0)}
          disabled={disabled}
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill="#fff" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
            <path fill="none" d="M0 0h24v24H0V0z" />
          </svg>
        </button>

        <div className="zoom">
          <button
            className="out"
            onMouseDown={() => onCameraMoveTo(alarmId, cameraId, 0, 0, -1.5)}
            onMouseUp={() => onCameraMoveTo(alarmId, cameraId, 0, 0, 0)}
            disabled={disabled}
          >
            -
          </button>
          <button
            className="in"
            onMouseDown={() => onCameraMoveTo(alarmId, cameraId, 0, 0, 1.5)}
            onMouseUp={() => onCameraMoveTo(alarmId, cameraId, 0, 0, 0)}
            disabled={disabled}
          >
            +
          </button>
        </div>

        <button
          className="right"
          onMouseDown={() => onCameraMoveTo(alarmId, cameraId, 1.5, 0, 0)}
          onMouseUp={() => onCameraMoveTo(alarmId, cameraId, 0, 0, 0)}
          disabled={disabled}
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill="#fff" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
            <path fill="none" d="M0 0h24v24H0V0z" />
          </svg>
        </button>
      </div>

      <button
        className="down"
        onMouseDown={() => onCameraMoveTo(alarmId, cameraId, 0, -1.5, 0)}
        onMouseUp={() => onCameraMoveTo(alarmId, cameraId, 0, 0, 0)}
        disabled={disabled}
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path fill="#fff" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
          <path fill="none" d="M0 0h24v24H0V0z" />
        </svg>
      </button>
    </div>
  );
}

class CommandButton extends React.PureComponent {
  onClick = () => this.props.onClick(this.props.commandId);

  render() {
    const { children } = this.props;

    return (
      <button className="button-small-blue" onClick={this.onClick} disabled={this.props.disabled}>
        {children}
      </button>
    );
  }
}

class ReactHls extends React.Component {
  @observable videoPlaying = false;
  @observable commandSending = false;
  @observable hasError = false;
  @observable isLoading = false;
  @observable videoElement = null;
  @observable retries = 0;
  @observable videoRetryError = false;
  @observable errorText = '';
  @observable isOffline = false;

  constructor(props) {
    super(props);

    this.localState = observable({
      playerId: Date.now(),
      elapsedTime: 0,
      canPlay: false,
      fullscreen: false
    });
    this.videoFormat = this.defineVideoByPriority(this.props);
    this.hls = null;
    window.Offline.check();
  }

  @computed
  get progress() {
    return parseInt((this.localState.elapsedTime / this.props.ViewingWindowShortPeriod) * 100, 10);
  }

  componentDidUpdate() {
    if (this.videoFormat === 'Hls') {
      this._initPlayer();
    }

    if (this.props.alarmDeactivated) {
      this.stopVideoPlayback();
    }
  }

  componentDidMount() {
    document.addEventListener('fullscreenchange', this.onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', this.onFullscreenChange);
    document.addEventListener('mozfullscreenchange', this.onFullscreenChange);
    this.checkToPlayVideo();

    window.Offline.on('down', () => {
      this.isOffline = true;
    });
    window.Offline.on('up', () => {
      this.isOffline = false;
    });
  }

  componentWillUnmount() {
    let { hls } = this;

    if (hls) {
      hls.destroy();
    }

    this.stopVideoPlayback();

    document.removeEventListener('fullscreenchange', this.onFullscreenChange);
    document.removeEventListener('webkitfullscreenchange', this.onFullscreenChange);
    document.removeEventListener('mozfullscreenchange', this.onFullscreenChange);

    window.Offline.off('down', this.isOffline);
    window.Offline.off('up', this.isOffline);
  }

  stopVideoPlayback = commands => {
    if (!this.videoElement || !this.videoElement.src) {
      this.causeAnError();
      return;
    }
    if (this.intervalDispose) {
      clearInterval(this.intervalDispose);
      this.videoElement.src = '';
      this.videoPlaying = false;
      this.props.onVideoEnd(commands, this.props.CameraType);
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  onCommandSend = (commandId, cameraId) => {
    this.commandSending = true;

    const { alarmId } = this.props;
    const Id = cameraId || this.props.Id;

    ajax
      .postByDescPromise(endpoints.CAMERA_COMMANDS, { alarmId, Id, commandId })
      .then(() => {
        this.commandSending = false;
      })
      .catch(error => {
        this.commandSending = false;
        Logger.error(error.err);
      });
  };

  onSendPresetPosition = (alarmId, cameraId, command) => {
    this.commandSending = true;
    sendChoosenCommand(alarmId, cameraId, command)
      .then(() => {
        this.commandSending = false;
      })
      .catch(error => {
        this.commandSending = false;
        Logger.error(error.err);
      });
  };

  onCameraMoveTo = (alarmId, cameraId, xspeed, yspeed, zspeed) => {
    this.commandSending = true;
    cameraApi
      .cameraMoveTo(alarmId, cameraId, xspeed, yspeed, zspeed)
      .then(() => {
        this.commandSending = false;
      })
      .catch(error => {
        this.commandSending = false;
        Logger.error(error.err);
      });
  };

  defineVideoByPriority = cameraData => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (cameraData.WebMPath && isChrome) {
      return 'Webm';
    } else if (cameraData.MjpegPath) {
      return 'Mjpeg';
    } else if (cameraData.HlsPath) {
      this._initPlayer();
      return 'Hls';
    }
    return null;
  };

  _initPlayer() {
    if (this.hls) {
      this.hls.destroy();
    }

    let { CameraAddress, hlsConfig, HlsPath } = this.props;

    const videoConfig = {
      xhrSetup(xhr, CameraAddress) {
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa('demo:nxwitness'));
      }
    };
    let hls = new Hls({ ...hlsConfig, ...videoConfig });

    this.localState.canPlay = false;

    hls.loadSource(CameraAddress + HlsPath);
    hls.attachMedia(this.video);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      this.localState.canPlay = true;
    });
    hls.on(Hls.Events.ERROR, (...args) => {
      Logger.error('HLS error', args);
      this.onError();
    });

    this.hls = hls;
  }

  toggleFullScreen = () => {
    if (this.localState.fullscreen) {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    } else if (this.container && this.container.requestFullscreen) {
      this.container.requestFullscreen();
    } else if (this.container && this.container.mozRequestFullScreen) {
      this.container.mozRequestFullScreen();
    } else if (this.container && this.container.webkitRequestFullscreen) {
      this.container.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  };

  controlTimeVideoPlaying = () => {
    const startTime = new Date().getTime();

    this.intervalDispose = setInterval(() => {
      const now = new Date().getTime();
      const elapsed = parseInt((now - startTime) / 1000, 10);

      if (this.props.ViewingWindowShortPeriod < elapsed) {
        clearInterval(this.intervalDispose);

        if (this.hls) {
          this.videoElement.pause();
          this.hls.stopLoad();
        }
        if (!this.videoElement || !this.videoElement.src) {
          this.causeAnError();
          return;
        }
        if (this.videoFormat !== 'Hls' && this.videoElement) {
          this.videoElement.src = '';

          if (this.videoFormat === 'Mjpeg') {
            this.videoElement.style = 'display: none';
          }
        }

        this.videoPlaying = false;
        this.props.onVideoEnd();
        return;
      }

      this.localState.elapsedTime = elapsed;
    }, 1000);
  };

  tryRestartVideo = () => {
    if (this.videoPlaying) {
      return;
    }

    if (this.retries <= this.props.VideoPlaybackNumRetries) {
      if (!this.videoElement || !this.videoElement.src) {
        this.causeAnError();
        return;
      }
      if (this.videoFormat === 'Hls') {
        this.videoRetryError = true;
        this.errorText = localization.t('VIDEO_PLAYBACK_FAILED');
        this._initPlayer();
      } else {
        this.videoElement.src = '';
      }
      setTimeout(this.onClickPlay, 1000);
      return;
    } else if (this.retries > this.props.VideoPlaybackNumRetries) {
      this.videoElement.src = '';
      this.causeAnError();
      return;
    }
  };

  onClickPlay = () => {
    if (!this.props.ViewingPermitted || !this.props.ViewingWindowShortPeriod) {
      return;
    }

    if (!this.videoFormat) {
      this.isLoading = false;
      this.hasError = true;
      this.props.videoError('NO_VIDEO_STREAM');
      return;
    }

    if (this.videoFormat === 'Mjpeg' && this.Mjpeg) {
      this.videoElement = this.Mjpeg;
    } else if (this.video) {
      this.videoElement = this.video;
    }

    if (this.videoElement && this.Mjpeg) {
      this.videoElement.style = '';
      this.videoElement.src = this.props.CameraAddress + this.props.MjpegPath;
    } else if (this.videoElement && this.videoElement.paused && this.videoFormat === 'Webm') {
      this.videoElement.style = '';
      this.videoElement.src = this.props.CameraAddress + this.props.WebMPath;
    } else if (this.videoElement && this.videoElement.paused && this.localState.canPlay) {
      this.videoElement.play();
      this.videoIsPlaying();
    }

    this.retries = this.retries + 1;
    this.timeout = setTimeout(this.tryRestartVideo, this.props.VideoPlaybackTimeout * 1000);
  };

  onFullscreenChange = () => {
    this.localState.fullscreen = !!(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    );
  };

  causeAnError = () => {
    this.isLoading = false;
    this.hasError = true;

    if (typeof this.props.CameraType === 'number') {
      this.props.videoError('VIDEO_CANT_PLAYED_' + this.props.CameraType);
      return;
    }

    if (this.Mjpeg) {
      this.props.videoError('MJPEG_VIDEO_CANT_PLAYED');
      return;
    }

    this.props.videoError();
    return;
  };

  onError = e => {
    if (this.retries <= this.props.VideoPlaybackNumRetries) {
      this.videoRetryError = true;
      this.errorText = localization.t('VIDEO_PLAYBACK_FAILED');

      if (!this.timeout) {
        this.tryRestartVideo();
      }
      return;
    }

    if ((this.videoElement && this.videoElement.src) || this.videoPlaying) {
      this.causeAnError();
    }
  };

  videoIsPlaying = () => {
    this.isLoading = false;
    this.videoPlaying = true;
    this.videoRetryError = false;
    this.errorText = '';
  };

  renderVideo = format => {
    if (format === 'Webm') {
      return (
        <video
          ref={node => (this.video = node)}
          className="hls-player"
          id={`react-hls-${this.localState.playerId}`}
          controls={false}
          type="video/webm; codecs='vp8, vorbis'"
          style={{ opacity: 0 }}
          onLoadedMetadata={() => {
            this.videoElement.play();
            this.videoIsPlaying();
          }}
          onError={this.onError}
          {...this.props.videoProps}
          src=""
        />
      );
    } else if (format === 'Mjpeg') {
      return (
        <img
          id={`Mjpeg-${this.props.Id}`}
          ref={node => (this.Mjpeg = node)}
          onLoad={() => {
            this.videoIsPlaying();
          }}
          onError={this.onError}
          src=""
          style={{ display: 'none' }}
        />
      );
    } else if (format === 'Hls') {
      return (
        <video
          ref={node => (this.video = node)}
          className="hls-player"
          id={`react-hls-${this.localState.playerId}`}
          controls={false}
          onError={this.onError}
          {...this.props.videoProps}
        />
      );
    }

    return <div />;
  };

  checkToPlayVideo = () => {
    if (!this.videoPlaying && !this.localState.elapsedTime && !this.hasError) {
      this.isLoading = true;
      this.controlTimeVideoPlaying();
      this.onClickPlay();
      return;
    }
    return;
  };

  render() {
    return (
      <div
        key={this.localState.playerId}
        className="player-area"
        ref={node => (this.container = node)}
        onContextMenu={this.props.onContextMenu}
      >
        <Observer>
          {() => this.videoRetryError && <p className="error-text-input">{this.errorText}</p>}
        </Observer>
        <div className="scale-video" style={{ backgroundColor: '#000000' }}>
          {this.videoFormat ? this.renderVideo(this.videoFormat) : null}
          <Observer>{() => (this.isLoading || this.isOffline) && <SpinnerOverlay />}</Observer>

          <Observer>
            {() =>
              !this.videoPlaying &&
              !this.localState.elapsedTime &&
              !this.isLoading && <playIcons.PlayIcon className="fullscreen-play-btn c-p" />
            }
          </Observer>

          <Observer>
            {() =>
              this.videoPlaying &&
              this.props.PTZControl && (
                <CameraControls
                  alarmId={this.props.alarmId}
                  cameraId={this.props.Id}
                  disabled={this.commandSending}
                  onCameraMoveTo={this.onCameraMoveTo}
                />
              )
            }
          </Observer>
        </div>
        <Observer>
          {() => (
            <div
              className={this.localState.fullscreen ? 'fullscreen-toolbar' : ''}
              style={{ position: this.localState.fullscreen ? 'absolute' : 'relative' }}
            >
              <div className="video-toolbar">
                {this.localState.elapsedTime !== 0 ? (
                  <div style={{ width: 28, textAlign: 'center', fontSize: '0.875rem' }}>
                    {this.props.ViewingWindowShortPeriod - this.localState.elapsedTime}s
                  </div>
                ) : (
                  !this.isLoading && (
                    <button
                      type="button"
                      onClick={() => this.checkToPlayVideo()}
                      title="Play stream"
                      disabled={this.hasError}
                    >
                      <playIcons.PlayIcon />
                    </button>
                  )
                )}
                <div
                  style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <div className="progress-container">
                    <div className="progress" style={{ width: this.progress + '%' }} />
                  </div>
                </div>

                <button
                  type="button"
                  onClick={this.toggleFullScreen}
                  title="Toggle fullscreen mode"
                >
                  <FullScreenIcon />
                </button>
              </div>
              <div className="flex-row-wrap" style={{ backgroundColor: '#fff' }}>
                {this.props.Commands &&
                  this.props.Commands.sort(Utils.sortByOrder).map((command, idx) => (
                    <div className="flex-1-1-0px flex-row-center p-025em" key={idx}>
                      <div className="flex-col-around">
                        <Observer>
                          {() =>
                            this.videoPlaying && (
                              <CommandButton
                                onClick={this.onCommandSend}
                                commandId={command.CommandId}
                                disabled={this.commandSending}
                              >
                                {localization.t(command.TitleKey)}
                              </CommandButton>
                            )
                          }
                        </Observer>
                      </div>
                    </div>
                  ))}
              </div>
              <Observer>
                {() =>
                  this.videoPlaying && (
                    <PresetPosition
                      presets={this.props.Presets}
                      fullscreen={this.localState.fullscreen}
                      alarmId={this.props.alarmId}
                      cameraId={this.props.Id}
                      disabled={this.commandSending}
                      onSendPresetPosition={this.onSendPresetPosition}
                    />
                  )
                }
              </Observer>
              <Observer>
                {() => (
                  <ExtraSettings
                    extraOpened={this.props.extraOpened}
                    camera={this.props}
                    fullscreen={this.localState.fullscreen}
                    alarmId={this.props.alarmId}
                    cameraId={this.props.Id}
                    disabled={this.commandSending}
                    onCommandSend={this.onCommandSend}
                    onDisable={this.stopVideoPlayback}
                    toggleExtra={this.props.toggleExtra}
                  />
                )}
              </Observer>
            </div>
          )}
        </Observer>
      </div>
    );
  }
}

ReactHls.propTypes = {
  CameraAddress: PropTypes.string,
  ViewingWindowShortPeriod: PropTypes.number,
  hlsConfig: PropTypes.object,
  poster: PropTypes.string,
  videoProps: PropTypes.object,
  alarmId: PropTypes.string,
  VideoPlaybackNumRetries: PropTypes.number,
  VideoPlaybackTimeout: PropTypes.number
};

ReactHls.defaultProps = {
  hlsConfig: {},
  ViewingWindowShortPeriod: 60,
  onVideoEnd: () => void 0,
  VideoPlaybackTimeout: 10,
  VideoPlaybackNumRetries: 2
};

export default ReactHls;
