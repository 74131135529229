import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  AlarmAttachments,
  AlarmDetailsModel,
  AlarmModel,
  PlannedAlarm
} from 'development/js/interfaces/backend_model';
import AxiosHelper from '../../utilities/axiosApiHelper';

const endpoints = require('../../../json/endpoints.json');

const API = {
  /**
   * Loads all regular (non planningview) alarms the operator has access to
   */
  getAlarms: (onlyActive?: boolean): Promise<AlarmModel[]> =>
    axios
      .get(endpoints.ALARMS['url'], {
        params: {
          onlyActive
        },
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as AlarmModel[])
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  /**
   * Load a single regular alarm the operator has access to
   */
  getAlarm: (alarmId: string): Promise<AlarmModel> =>
    axios
      .get(`${endpoints.ALARMS['url']}/${alarmId}/basicv2`, {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as AlarmModel)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  /**
   * Load an alarm with all the details on it
   */
  getAlarmDetails: (alarmId: string): Promise<AlarmDetailsModel> =>
    axios
      .get(`${endpoints.ALARMS['url']}/${alarmId}`, {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as AlarmDetailsModel)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  getAlarmAttachments: (alarmId: string, latestTimestamp: string): Promise<AlarmAttachments> =>
    axios
      .get(`${endpoints.ALARMS['url']}/${alarmId}/attachments`, {
        params: {
          latest: latestTimestamp || null
        },
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as AlarmAttachments)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  sendAcknowledgement: (alarmId: string): Promise<AxiosResponse> =>
    axios
      .post(
        `${endpoints.ALARMS['url']}/${alarmId}/acknowledgement`,
        {},
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        }
      )
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),
  /**
   * Clears an alarm
   * @param alarmId
   * @returns
   */
  clearAlarm: (alarmId: string): Promise<AxiosResponse> =>
    axios
      .post(
        `${endpoints.ALARMS['url']}/${alarmId}/clear`,
        {},
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        }
      )
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  /**
   * Loads all planningview alarms the operator has access to
   */
  getPlanningViewAlarms: (): Promise<PlannedAlarm[]> =>
    axios
      .get(endpoints.PLANNED_ALARMS_GET['url'], {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as PlannedAlarm[])
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  /**
   * Load a single planning view alarm the operator has access to
   */
  getPlannedAlarm: (alarmId: string): Promise<PlannedAlarm> =>
    axios
      .get(`${endpoints.PLANNED_ALARMS_GET['url']}/${alarmId}`, {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      })
      .then((res: AxiosResponse) => res.data as PlannedAlarm)
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  updatePlannedAlarmTime: (alarmId: string, value: string) =>
    axios
      .post(
        `${endpoints.ALARMS['url']}/${alarmId}/task`,
        {
          DueDate: value
        },
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        }
      )
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  updatePlannedAlarmProduct: (alarmId: string, value: string) =>
    axios
      .post(
        `${endpoints.ALARMS['url']}/${alarmId}/task`,
        {
          TaskType: value
        },
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        }
      )
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  updatePlannedAlarmNote: (alarmId: string, value: string) =>
    axios
      .post(
        `${endpoints.ALARMS['url']}/${alarmId}/task`,
        {
          Note: value
        },
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        }
      )
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),

  sendAlarmLog: (alarmId: string, log: string) =>
    axios
      .post(
        `api/alarms/${alarmId}/operatorinformation`,
        log,
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader(),
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err))),
};

export default API;
