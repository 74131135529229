import createReactClass from 'create-react-class';
import React from 'react';
import constants from '../../../json/constants.json';
import Spinner from '../../components/spinner';
import alarmActiveStore from '../../stores/alarms/alarm_active_store_actions';
import { isCommandUsed } from '../../utilities/commandButtonStateManager';
import Utils from '../../utils';
import { CommandButton } from './CommandButton';

let sectionClassName = 'p-t-10 p-l-05em';
let commandButtonClassName = 'button-small-secondary text-left';
let wrapButtonsClassName = 'item-border';
let dropdownClassName = 'p-abs flex-col filter-window mw-8em';

export default createReactClass({
  getInitialState() {
    return {
      errorMessage: null,
      pendingItem: null,
      opened: false,
      opened_top: 0,
      opened_left: 0
    };
  },

  componentDidMount() {
    alarmActiveStore.on(constants.ALARM_COMMAND_ERROR, this._onError);
    alarmActiveStore.on(constants.SEND_ALARM_COMMAND, this._onSuccess);
  },

  componentWillUnmount() {
    alarmActiveStore.off(constants.ALARM_COMMAND_ERROR, this._onError);
    alarmActiveStore.off(constants.SEND_ALARM_COMMAND, this._onSuccess);
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetStateOn && this.props.resetStateOn !== nextProps.resetStateOn) {
      this.setState(this.getInitialState());
    }
  },

  _onError(errorText) {
    this.setState({
      pendingItem: null,
      opened: false,
      errorMessage: errorText
    });
  },

  _onSuccess() {
    this.setState(this.getInitialState());
  },

  toggleItems(e) {
    let target = e.currentTarget;
    e.stopPropagation();
    this.setState({
      ...this.getInitialState(),
      opened: !this.state.opened,
      opened_top: target.offsetTop + target.offsetHeight,
      opened_left: target.offsetLeft,
      errorMessage: null
    });
  },

  getCommandButtons(
    commands,
    typeOfButton,
    alarmT,
    isLoading,
    errorMessageInCallCommand,
    onCommandClick
  ) {
    let buttons = [];
    for (let i = 0; i < commands.length; i++) {
      let command = commands[i];
      if (typeOfButton === 'button') {
        buttons.push(
          <div className="flex-col-around" key={i}>
            {errorMessageInCallCommand ? (
              <button
                className="form-btn-bg-red"
                type="button"
                onClick={e => onCommandClick(e, command)}
              >
                {errorMessageInCallCommand}
              </button>
            ) : (
              <button
                className='button-small-blue'
                onClick={e => onCommandClick(e, command)}
                disabled={isLoading}
              >
                {alarmT(command.TranslationKey)}
              </button>
            )}
          </div>
        );
      } else {
        buttons.push(
          <CommandButton
            key={command.Id + '-' + i}
            className={wrapButtonsClassName}
            buttonClassName={commandButtonClassName}
            alarmId={this.props.alarm_id}
            command={command}
            dropdown={this}
            commandNameToSave={this.props.name}
          />
        );
      }
    }

    return (
      <div
        className={dropdownClassName}
        style={{ left: this.state.opened_left + 'px', top: this.state.opened_top + 'px' }}
      >
        {buttons}
      </div>
    );
  },

  render() {
    let {
      name,
      commands,
      childRef,
      typeOfButton,
      alarmT,
      isLoading,
      errorMessageInCallCommand,
      onCommandClick
    } = this.props;
    let { pendingItem, errorMessage, opened } = this.state;
    
    const userName = localStorage.getItem('user_id');
    const isUsed = isCommandUsed(userName, this.props.alarm_id, this.props.name)
      ? 'used-command'
      : '';
    return (
      <div className="flex-col-around" onClick={this.handleGlobalClick}>
        {errorMessage || errorMessageInCallCommand ? (
          <button
            className="form-btn-bg-red"
            type="button"
            {...Utils.renderRef(childRef)}
            onClick={this.toggleItems}
          >
            {errorMessage || errorMessageInCallCommand}
          </button>
        ) : (
          <button
            className={`button-small-blue ${isUsed}`}
            type="button"
            {...Utils.renderRef(childRef)}
            {...Utils.renderDisabled()}
            onClick={this.toggleItems}
            disabled={isLoading}
          >
            {pendingItem ? pendingItem : name}
          </button>
        )}
        {pendingItem ? (
          <div className="flex-col-around">
            <Spinner width="1" height="1" widthUnits="em" heightUnits="em" />
          </div>
        ) : null}
        {opened
          ? this.getCommandButtons(
              commands,
              typeOfButton,
              alarmT,
              isLoading,
              errorMessageInCallCommand,
              onCommandClick
            )
          : null}
      </div>
    );
  }
});
