import { addHours, isBefore, parseISO } from 'date-fns';
import * as Logger from 'js-logger';
import React from 'react';
import ajax from '../../ajax';
import authStore from '../../authentication';
import Grid from '../../components/grid';
import IconAlarm from '../../components/icon_alarm';
import dateTime from '../../date_time';
import { AlarmState } from '../../enums/alarm';
import { AlarmModel, SortingByTabModel } from '../../interfaces/backend_model';
import localization from '../../localization';
import { sortAlarms } from '../../utilities/sortAlarms';
import { Utils } from '../../utils';
import websocket from '../../websocket';
import settingsStore from '../settings_store';
import AlarmHelpers from './alarm_helpers';
import AlarmAPI from './api';
import planningViewStore from './planning_view_store_actions';

const constants = require('../../../json/constants.json');
const endpoints = require('../../../json/endpoints.json');

class AlarmListStore extends Utils {
  alarms: AlarmModel[];
  activeTab: string;
  sorting_by_tab: SortingByTabModel | {};
  alarmsUpdateHandler: number;
  alarmsRefreshHandler: number;
  alarmDetailsUpdateHandler: number;

  constructor() {
    super();
    this.getInitialData();
    this.alarmsUpdateHandler = 0;
    this.alarmsRefreshHandler = 0;
    this.alarmDetailsUpdateHandler = 0;
  }

  getInitialData() {
    /**
     * store list alarms
     */
    this.alarms = [];
    this.activeTab = constants.TAB_ACTIVE;
    this.sorting_by_tab = {};

    // subscribe to external stores
    settingsStore.on(constants.SETTINGS_CHANGE, this.onSettingsLoaded.bind(this));
  }

  onSettingsLoaded() {
    this.sorting_by_tab = this.getDefaultGridSorting();
  }

  /**
   * get alarm icon
   */
  getAlarmIcon(alarm: AlarmModel): JSX.Element {
    return <IconAlarm icon={alarm.IconRef} fallbackIcon={alarm.IconRefFallback} />;
  }

  /**
   * Check if there is currently any other alarm that fulfills:
   * Same UserId, RespondentId set to an integer, RespondentOwn set to false and both are active.
   * Present an additional line of text:
   * 'Operator X is currently handling another alarm from this object'
   * @param alarm
   * @returns
   */
  displayAdditionalInfo(alarm: AlarmModel): string {
    const alarmHandledByAnotherAdmin = this.alarms.find(
      a =>
        a.UserId === alarm.UserId && // do both alarms belong to the same user
        a.AlarmId !== alarm.AlarmId && // are they different alarms
        a.Active && // are both alarms active
        alarm.Active &&
        !isNaN(parseInt(a.RespondentId)) && // is the already existing alarm taken by anyone
        isNaN(parseInt(alarm.RespondentId)) && // is the incomming alarm not taken by anyone
        !a.RespondentOwn // does the alarm belong to another respondent
    );

    const hideOtherRespondentsHandlingAlarms = settingsStore.getValueByKey(
      'HIDE_OTHER_RESPONDENTS_HANDLING_ALARM'
    );
    let showMessage = true;

    if (hideOtherRespondentsHandlingAlarms) {
      let arr = eval(hideOtherRespondentsHandlingAlarms);
      arr = arr.map((e: any) => e.toString());
      if (arr.indexOf(alarm.RespondentGroup) > -1) {
        showMessage = false;
      }
    }

    let additionalInformation = '';
    if (alarmHandledByAnotherAdmin && showMessage) {
      additionalInformation = `${localization.t(
        'ALARM_OPERATOR'
      )} ${alarmHandledByAnotherAdmin.RespondentName ||
      alarmHandledByAnotherAdmin.RespondentInitials} ${localization.t(
        'ALARM_CURRENTLY_HANDLING'
      )}`;
    }

    return additionalInformation;
  }

  getAlarm(alarm_id: string): AlarmModel {
    return this.find_obj(this.alarms, 'AlarmId', alarm_id);
  }

  getAlarms(): AlarmModel[] {
    return this.alarms;
  }

  /**
   * index all alarms by categories
   */
  getAlarmsByState(): { [state: string]: AlarmModel } {
    const alarmsByState: { [state: string]: AlarmModel } = {};

    this.alarms.forEach(alarm => {
      alarmsByState[
        AlarmHelpers.defineAlarmState({
          active: alarm.Active,
          respondentId: alarm.RespondentId,
          respondentOwn: alarm.RespondentOwn
        })
      ] = alarm;
    });

    return alarmsByState;
  }

  /**
   * index all alarms by tabs
   */
  getAlarmsByTabs(): {
    [x: string]: AlarmModel[];
  } {
    const alarmByTabs = settingsStore.getValueByKey('SINGLELIST', 'boolean')
      ? {
        [constants.TAB_ACTIVE]: [] as AlarmModel[],
        [constants.TAB_DEACTIVATED]: [] as AlarmModel[]
      }
      : {
        [constants.TAB_ACTIVE]: [] as AlarmModel[],
        [constants.TAB_MY]: [] as AlarmModel[],
        [constants.TAB_RESPONDED]: [] as AlarmModel[],
        [constants.TAB_DEACTIVATED]: [] as AlarmModel[]
      };

    this.alarms.forEach(alarm => {
      const alarmTab: string = AlarmHelpers.defineAlarmTab({
        active: alarm.Active,
        respondentId: alarm.RespondentId,
        respondentOwn: alarm.RespondentOwn
      });

      alarm.AdditionalInformation = this.displayAdditionalInfo(alarm);

      if (alarmTab) {
        alarmByTabs[alarmTab].push(alarm);
      }
    });

    alarmByTabs[constants.TAB_ACTIVE] = sortAlarms(alarmByTabs[constants.TAB_ACTIVE]);

    return alarmByTabs;
  }

  getActiveAlarms() {
    return this.alarms.filter(alarm => alarm.Active);
  }

  getUserAlarms(user_id: string) {
    return this.alarms.filter(alarm => alarm.UserId === user_id);
  }

  getUserAlarmsByAlarmId(alarm_id: string) {
    const alarm = this.getAlarm(alarm_id);

    if (alarm && alarm.UserId) {
      return this.getUserAlarms(alarm.UserId);
    }

    return [];
  }

  acknowledgeAlarm(data: AlarmModel) {
    // SR-8716: Delivery report can cause a lot of API calls
    if (data.DoDeliveryConfirmation) {
      const AlarmId: string = data.AlarmId;
      ajax.postByDescPromise(endpoints.DELIVERY_REPORT, { AlarmId }).catch(e => {
        Logger.error(e);
      });
    }
  }

  /**
   * Loads all regular (non planning view) alarms the operator has access to
   */
  loadAlarms(keep_unique_alarms?: boolean) {
    if (authStore.getUserId()) {
      AlarmAPI.getAlarms(false)
        .then(data => {
          this.mergeAlarms(data, keep_unique_alarms);
          this.trigger(constants.ALARMS_CHANGE);
          this.trigger(constants.ALARMS_SYNC_SUCCESS);
        })
        .catch((err: any) => {
          if (err !== constants.REQUEST_BLOCKED) {
            // we want to distinguish between errors appearing on the first fetch and on any consecutive fetches
            if (Array.isArray(this.alarms) && this.alarms.length) {
              this.trigger(constants.ALARMS_SYNC_ERROR, err.statusText, err.status);
            } else {
              this.trigger(constants.ALARMS_SERVER_ERROR, err.statusText, err.status);
            }
          }
        });
    }
  }

  loadSingleAlarm(alarmId: string) {
    AlarmAPI.getAlarm(alarmId)
      .then(alarm => {
        this.updateAlarmList(alarm);
        this.trigger(constants.ALARMS_CHANGE);
      })
      .catch((err: any) => {
        if (err !== constants.REQUEST_BLOCKED) {
          // we want to distinguish between errors appearing on the first fetch and on any consecutive fetches
          if (Array.isArray(this.alarms) && this.alarms.length) {
            this.trigger(constants.ALARMS_SYNC_ERROR, err.statusText, err.status);
          } else {
            this.trigger(constants.ALARMS_SERVER_ERROR, err.statusText, err.status);
          }
        }
      });
  }

  updateAlarmList(newAlarm: AlarmModel) {
    let alarmIndex = this.alarms.findIndex(a => a.AlarmId === newAlarm.AlarmId);
    const newAlarmState: AlarmState = AlarmHelpers.defineAlarmState({
      active: newAlarm.Active,
      respondentId: newAlarm.RespondentId,
      respondentOwn: newAlarm.RespondentOwn
    });
    // if the alarm does not exists in the alarm list and is not a planning view alarm then it
    // has just arrived and should be acknowledged and added
    if (
      alarmIndex === -1 &&
      (!planningViewStore.getPlannedAlarmById(newAlarm.AlarmId) || newAlarm.RespondentId !== '')
    ) {
      this.acknowledgeAlarm(newAlarm);

      // if the alarm arrives as in a accepted state then notify the operator
      if (newAlarmState === AlarmState.My) {
        this.trigger(constants.ACTION_ACCEPT_ALARM);
      }
      this.alarms.push(newAlarm);
    } else {
      const oldAlarmState: AlarmState = AlarmHelpers.defineAlarmState({
        active: this.alarms[alarmIndex].Active,
        respondentId: this.alarms[alarmIndex].RespondentId,
        respondentOwn: this.alarms[alarmIndex].RespondentOwn
      });

      // if the alarm goes from a "not accepted by the current operator"
      // state to an accepted state then notify the operator
      if (newAlarmState == AlarmState.My && oldAlarmState !== AlarmState.My) {
        this.trigger(constants.ACTION_ACCEPT_ALARM);
      }

      this.alarms[alarmIndex] = newAlarm;
    }
  }

  /**
   * If an alarm exist in both frontend and backend we take the last updated
   * Alarms that only exists in frontend or backend are saved
   * @param data
   * @param keep_unique_alarms
   */
  mergeAlarms(data: AlarmModel[], keep_unique_alarms: boolean = true) {
    // key = AlarmId, value = alarm object
    const dataDict = Object.assign({}, ...data.map(x => ({ [x.AlarmId]: x })));
    const updatedAlarms: AlarmModel[] = [];

    this.alarms.forEach(a => {
      if (a.AlarmId in dataDict) {
        updatedAlarms.push(dataDict[a.AlarmId]);
      } else if (!keep_unique_alarms) {
        // We do not want to keep alarms that only exists in the frontend
      } else if (!a.Active && isBefore(addHours(parseISO(a.LastUpdated), 1), Date.now())) {
        // The alarm does not exist in backend, is inactive and has been so for over an hour
        // Time to remove it from the frontend also
      } else {
        updatedAlarms.push(a); // Exist only in the frontend
      }
    });

    const updatedAlarmsIds = updatedAlarms.map(a => a.AlarmId);
    data.forEach(a => {
      if (!updatedAlarmsIds.includes(a.AlarmId)) {
        // Add all alarms that only exist in the backend, i.e. new alarms
        this.acknowledgeAlarm(a);
        updatedAlarms.push(a);
      }
    });
    // Update list of alarms in frontend with list of updated alarms
    this.alarms = updatedAlarms;
  }

  clearAlarms() {
    this.alarms = [];
  }

  _clearAlarm(alarmId: string) {
    this.trigger(constants.ALARM_CLEAR_POSTING);
    this.loadSingleAlarm(alarmId);
  }

  removeAlarm(alarmId: string) {
    const index = this.alarms.findIndex(a => a.AlarmId === alarmId);
    this.alarms.splice(index, 1);

    this.trigger(constants.ALARMS_CHANGE);
  }

  subscribeForAlarmsUpdates() {
    let setting_interval = settingsStore.getSettingInterval('ALARM_REFRESH_FAST');

    if (setting_interval !== null) {
      this.unSubscribeForAlarmsUpdates();
      this.alarmsUpdateHandler = window.setInterval(() => {
        if (authStore.getUserId() && !authStore.isSimpleLogin()) { // If user is logged in
          websocket.checkWebSocket(this, this.loadAlarms)();
        } else {
          console.warn('No user logged in with correct access, will not run \'loadAlarms\'');
          this.unSubscribeForAlarmsUpdates();
        }
      }, setting_interval);
    }
  }

  unSubscribeForAlarmsUpdates() {
    clearInterval(this.alarmsUpdateHandler);
  }

  getDefaultTabSorting() {
    // can contain: "priority", "time", "alphabetically"
    const sortType = settingsStore.getValueByKey('ALARMLISTSORTBY');

    // can contain string "True" or string "False"
    const isAsc = !(settingsStore.getValueByKey('ALARMLISTDEFAULTSORTASCENDING') === 'False');

    switch (sortType) {
      case 'priority':
        return [{ Priority: isAsc ? constants.ASCENDING : constants.DESCENDING }];
      case 'time':
        return [{ ServerAlarmTime: isAsc ? constants.ASCENDING : constants.DESCENDING }];
      case 'alphabetically':
        return [{ Name: isAsc ? constants.ASCENDING : constants.DESCENDING }];
      default:
        return [];
    }
  }

  getDefaultGridSorting(): SortingByTabModel {
    let default_sorting_by_tab: SortingByTabModel = { TAB_ACTIVE: [], TAB_DEACTIVATED: [] };
    default_sorting_by_tab[constants.TAB_ACTIVE] = this.getDefaultTabSorting();
    default_sorting_by_tab[constants.TAB_MY] = this.getDefaultTabSorting();
    default_sorting_by_tab[constants.TAB_RESPONDED] = this.getDefaultTabSorting();
    default_sorting_by_tab[constants.TAB_DEACTIVATED] = this.getDefaultTabSorting();
    return default_sorting_by_tab;
  }

  _changeSorting(sorting_by_tab: SortingByTabModel) {
    this.sorting_by_tab = sorting_by_tab;
  }

  getAlarmAtTop = (tab: string): AlarmModel | null => {
    let alarms = this.getSortedAlarmsByTab(tab);
    return alarms.length ? alarms[0] : null;
  };

  getSortedAlarmsByTab = (tab: string): AlarmModel[] => {
    let alarms = this.getAlarmsByTabs()[tab];
    if (tab === constants.TAB_ACTIVE) {
      if (this.sorting_by_tab[tab].length === 0) {
        return sortAlarms(alarms);
      }
      const [sortKey, sortValue] = Object.entries(this.sorting_by_tab[tab][0])[0];
      return sortAlarms(alarms, sortKey, sortValue === constants.DESCENDING);
    }
    let fakeGrid = {
      state: {
        active_tab: tab.toString(),
        sorting_by_tab: this.sorting_by_tab
      }
    };
    return alarms.sort((a: AlarmModel, b: AlarmModel) => Grid.sortWorkflow(fakeGrid, a, b));
  };

  getNextTabAlarm<T>(alarm_id: string, prev_if_no_next?: T) {
    let alarm = this.getAlarm(alarm_id);
    let tab: string = AlarmHelpers.defineAlarmTab({
      active: alarm.Active,
      respondentId: alarm.RespondentId,
      respondentOwn: alarm.RespondentOwn
    });
    let alarms = this.getSortedAlarmsByTab(tab);

    let index = alarms.findIndex((a: AlarmModel) => {
      return a.AlarmId === alarm_id;
    });
    if (index !== -1) {
      index = index + 1 < alarms.length ? index + 1 : prev_if_no_next && index > 0 ? index - 1 : -1;
    }
    return index !== -1 ? alarms[index] : null;
  }

  getPrevTabAlarm<T>(alarm_id: string, next_if_no_prev?: T) {
    let alarm = this.getAlarm(alarm_id);
    let tab = AlarmHelpers.defineAlarmTab({
      active: alarm.Active,
      respondentId: alarm.RespondentId,
      respondentOwn: alarm.RespondentOwn
    });
    let alarms = this.getSortedAlarmsByTab(tab);

    let index = alarms.findIndex((a: AlarmModel) => {
      return a.AlarmId === alarm_id;
    });
    if (index !== -1) {
      index = index > 0 ? index - 1 : next_if_no_prev && alarms.length > 1 ? 0 : -1;
    }
    return index !== -1 ? alarms[index] : null;
  }

  isActiveAlarmsToNotifyAfter() {
    let tabAlarms: AlarmModel[];
    let tabMy: AlarmModel[] = this.getAlarmsByTabs()[constants.TAB_MY];

    if (tabMy && tabMy.length) {
      tabAlarms = this.getAlarmsByTabs()[constants.TAB_MY];
    } else {
      tabAlarms = this.getAlarmsByTabs()[constants.TAB_ACTIVE];
    }
    const activeAlarms = tabAlarms.some((alarm: AlarmModel) => dateTime.isTime(alarm.NotifyAfter));

    return activeAlarms;
  }

  /**
   * Check if given alarm exist in the array that should represent visible alarms in Central
   * @param alarmId 
   * @returns boolean
   */
  alarmExist(alarmId: string) {
    const index = this.alarms.findIndex(a => a.AlarmId === alarmId);
    if (index !== -1) {
      return true;
    }
    return false;
  }
}

export default AlarmListStore;
