import appDispatcher from '../../app_dispatcher';
import { Event } from '../../event';
import { PlannedAlarm } from '../../interfaces/backend_model';
import storageStore from '../mobx/StorageStore';
import alarmActiveStore from './alarm_active_store_actions';
import AlarmAPI from './api';

const constants = require('../../../json/constants.json');

export class PlanningViewStore extends Event {
  plannedAlarms: PlannedAlarm[];

  constructor() {
    super();
    this.getInitialData();
  }

  getInitialData() {
    this.plannedAlarms = [];
  }

  getPlannedAlarms(): PlannedAlarm[] {
    return this.plannedAlarms;
  }

  getPlannedAlarmById(id: string) {
    return Object.values(this.plannedAlarms).find(alarm => alarm.AlarmId === id) || null;
  }

  getAlarmIndexById = (id: string) => {
    return this.plannedAlarms.findIndex(alarm => alarm.AlarmId === id);
  };

  getItemFromStorage = (key: string): string => {
    let userId: string = localStorage.getItem('user_id')!;
    return storageStore.getItem(`${userId}-${key}`);
  };

  setItemInStorage = (key: string, value: string) => {
    let userId: string = localStorage.getItem('user_id')!;
    storageStore.setItem(`${userId}-${key}`, value);
  };

  loadPlannedAlarms() {
    AlarmAPI.getPlanningViewAlarms().then(data => {
      this.plannedAlarms = data;
      this.plannedAlarms = this.plannedAlarms.map(alarm => {
        return {
          ...alarm,
          TaskType: !!alarm.TaskType ? alarm.TaskType.split(',').join(', ') : ''
        } as any;
      });
      this.trigger(constants.PLANNED_ALARMS_LOADED);
    });
  }

  loadPlannedAlarm(globalAlarmId: string) {
    AlarmAPI.getPlannedAlarm(globalAlarmId).then(alarm => {
      if (alarm.Active) {
        alarm.TaskType = alarm.TaskType ? alarm.TaskType.split(',').join(', ') : '';
        this.updateAlarm(globalAlarmId, alarm);
      } else {
        this.removeAlarmFromStore(globalAlarmId);
      }
      this.trigger(constants.PLANNED_ALARMS_LOADED);
    });
  }

  updateAlarm = (alarmId: string, plannedAlarmData: PlannedAlarm) => {
    let alarmIndex = this.getAlarmIndexById(alarmId);

    if (!plannedAlarmData) {
      return;
    }

    if (alarmIndex === -1) {
      this.plannedAlarms[this.plannedAlarms.length] = plannedAlarmData;
    } else {
      this.plannedAlarms[alarmIndex] = {
        ...this.plannedAlarms[alarmIndex],
        ...plannedAlarmData
      };
    }
    alarmActiveStore.updateAlarmDetailsForPlanningView(alarmId, plannedAlarmData);
  };

  removeAlarmFromStore = (alarmId: string) => {
    const alarmToDeleteIndex = this.getAlarmIndexById(alarmId);
    this.plannedAlarms.splice(alarmToDeleteIndex, 1);
  };

  updatePlannedAlarmProduct = (alarmId: string, product: string) => {
    AlarmAPI.updatePlannedAlarmProduct(alarmId, product).then(() => {
      alarmActiveStore.trigger(constants.ALARM_COMMAND_SUCCESS, alarmId);
      appDispatcher.handleServerAction({
        type: constants.PLANNED_ALARMS_GET_ONE,
        alarm_id: alarmId
      })
    });
  };
  updatePlannedAlarmNote = (alarmId: string, note: string) => {
    AlarmAPI.updatePlannedAlarmNote(alarmId, note).then(() => {
      alarmActiveStore.trigger(constants.ALARM_COMMAND_SUCCESS, alarmId);
      appDispatcher.handleServerAction({
        type: constants.PLANNED_ALARMS_GET_ONE,
        alarm_id: alarmId
      })
    });
  };
  updatePlannedAlarmTime = (alarmId: string, time: string) => {
    AlarmAPI.updatePlannedAlarmTime(alarmId, time).then(() =>
      appDispatcher.handleServerAction({
        type: constants.PLANNED_ALARMS_GET_ONE,
        alarm_id: alarmId
      })
    );
  };
}

export default PlanningViewStore;
