import axios, { AxiosError } from 'axios';
import EventEmitter from 'events';
import * as Logger from 'js-logger';
import ajax from '../../ajax';
import AppDispatcher from '../../app_dispatcher';
import authStore from '../../authentication';
import { CallDetailsModel, CallModel, InCallCommandsModel, Participant } from '../../interfaces/backend_model';
import localization from '../../localization';
import AxiosHelper from '../../utilities/axiosApiHelper';
import websocket from '../../websocket';
import alarmActiveStore from '../alarms/alarm_active_store_actions';
import settingsStore from '../settings_store';
import TwilioStore from '../twillio_store';

const constants = require('../../../json/constants.json');
const endpoints = require('../../../json/endpoints.json');

export const reservedIncallCommandTypes = {
  PTT_TALK: 'ptt_talk',
  PTT_LISTEN: 'ptt_listen',
  KEEP_ALIVE: 'keepalive'
};

interface PayloadModel {
  type: string;
  alarm_id?: string;
  template_type?: string;
  callSid?: string;
  user_id?: number;
  call: CallModel;
}

class CallsStore extends EventEmitter {
  errors: { [key: string]: any };
  loading: { [key: string]: any };
  calls: CallModel[];
  customTransmitterCommandStatus: string;
  callUpdateHandler: number;


  constructor() {
    super();

    this.errors = new Map();
    this.loading = new Map();

    /**
     * Fetched array of Call's
     * @type {Array.<Call>}
     */
    this.calls = [];

    this.customTransmitterCommandStatus = '';

    // register to app dispatcher
    AppDispatcher.register(this.onDispatch.bind(this));
  }

  /**
   * Handler for AppDispatcher events
   * @param payload
   */
  onDispatch({ action: payload }: { action: PayloadModel }) {
    switch (payload.type) {
      case constants.FETCH_ACTIVE_CALLS:
        this.fetchActiveCalls();
        break;
      case constants.TWILIO_UNHOLD_CALL:
        AppDispatcher.waitFor([TwilioStore.dispatchToken]);
        this.removeCallBySid(payload.callSid);
        this.fetchActiveCalls();
        break;
      case constants.TWILIO_JOIN_CALL:
        AppDispatcher.waitFor([TwilioStore.dispatchToken]);
        this.fetchActiveCalls();
        break;
      case constants.TWILIO_LEAVE_CALL:
        AppDispatcher.waitFor([TwilioStore.dispatchToken]);
        this.fetchActiveCalls();
        break;
      case constants.TWILIO_HOLD_CURRENT_CALL:
        Logger.info('onDispatch TWILIO_HOLD_CURRENT_CALL waitFor');
        AppDispatcher.waitFor([TwilioStore.dispatchToken]);
        this.fetchActiveCalls();
        break;
      case constants.TWILIO_CALL_COMPLETED:
        this.removeCallOnCompleted(TwilioStore.getCallDetails());
        break;
      case constants.START_UPDATING_CALLS:
        this.subscribeForCallUpdates();
        break;
      case constants.STOP_UPDATING_CALLS:
        this.unSubscribeForCallUpdates();
        break;
    }
  }

  /**
   * Retrieve store state
   * @returns {{errors: Map, loading: Map, calls: (*|Array)}}
   */
  getState() {
    return {
      errors: this.errors,
      loading: this.loading,
      calls: this.calls
    };
  }

  removeCallBySid(sid?: string) {
    this.calls = this.calls.filter(call => call.CallSid !== sid);
    this.notify();
  }

  removeCallOnCompleted(callDetails: CallDetailsModel) {
    // The call is completed so time to keep the CallsStore up to date
    // E.g. make sure that outdated inCallCommands are not displayed 
    if (this.getCallBySid(callDetails.sid!)) {
      //Remove call from this.calls
      this.removeCallBySid(callDetails.sid);
    } else {
      //Call does not exist in this.calls, lets try if it is the parent call we are referencing
      this.removeCallBySid(callDetails.parentSid);
    }
  }

  /**
   * Fetch current active on calls
   */
  fetchActiveCalls() {
    if (TwilioStore.isCloudIntegration()) {
      this.errors.set('fetch', false);
      this.loading.set('fetch', true);
      this.notify();

      axios.get(endpoints.FETCH_CALLS['url'],
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        })
        .then(resp => {
          this.loading.set('fetch', false);

          if (!resp.data) {
            this.errors.set('fetch', 'null received from backend instead of array');
            this.notify();
            return;
          }

          this.calls = resp.data;

          this.notify();
          TwilioStore.checkForInCallCommandsToSend();
        }).catch((err: AxiosError) => {
          console.error('fetchActiveCalls got an error:', err);
          // REQUEST_BLOCKED is the error thrown when we block our own request when an active one is already going
          if (err.code !== constants.REQUEST_BLOCKED) {
            this.errors.set('fetch', err);
            this.notify();
          }
        });
    }
    else {
      console.log('Twilio is not available, will not fetchActiveCalls');
    }
  }

  getActiveCall(): CallModel | undefined {
    return this.calls.find(сall => !сall.OnHold);
  }

  getCallBySid(sid: string): CallModel | undefined {
    return this.calls.find(call => call.CallSid === sid);
  }

  getOnHoldCalls() {
    return this.calls
      .filter(call => call.OnHold)
      .sort((callA, callB) => {
        callA.InitiatedAt = new Date(callA.InitiatedAt);
        callB.InitiatedAt = new Date(callB.InitiatedAt);

        const a = callA.InitiatedAt.getTime() ?? 0;
        const b = callB.InitiatedAt.getTime() ?? 0;
        return b - a;
      });
  }

  endAllCalls() {
    this.calls.forEach(call => {
      this.removeCallBySid(call.CallSid);
    });
  }

  getFilteredInCallCommands(): InCallCommandsModel[] {
    const activeCall = this.calls.find(call => !call.OnHold);
    const filteredInCallCommands = activeCall?.InCallCommands?.filter(cmd => !Object.values(reservedIncallCommandTypes).includes(cmd.Type));
    return filteredInCallCommands ? filteredInCallCommands : ([] as InCallCommandsModel[]);
  }

  getCustomTransmitterCommandStatus() {
    return this.customTransmitterCommandStatus;
  }

  setCustomTransmitterCommandStatus(status: string = '', widgetName = "") {
    this.customTransmitterCommandStatus = status;
    switch (widgetName) {
      case "TransmitterCommandsSection":
        this.notify(constants.CALL_TRANSMITTER_CMD_NOTIFY);
        break;
      default:
        this.notify(constants.CALL_CMD_NOTIFY);
        break;
    }
  }

  sendCustomTransmitterCommand(
    alarm_id: string,
    command_id: number | string,
    message?: string,
    optional_transmitter_id?: number,
    widgetName?: string
  ): Promise<string> {
    this.setCustomTransmitterCommandStatus(localization.t('CMD_SENDING'), widgetName);

    let transmitter_id: number | undefined = optional_transmitter_id;
    if (!optional_transmitter_id) {
      transmitter_id = alarmActiveStore.getAlarmingTransmitterByAlarmId(alarm_id)?.TransmitterId;
    }
    if (transmitter_id) {
      return ajax
        .postByDescPromise(endpoints.CALL_CUSTOM_COMMAND, {
          alarm_id,
          transmitter_id,
          command_id,
          message
        })
        .then(() => {
          this.setCustomTransmitterCommandStatus('[' + message + ']', widgetName);
          return;
        })
        .catch(error => {
          this.setCustomTransmitterCommandStatus(localization.t('CMD_SENDING_ERROR') + ' [' + message + ']', widgetName);
          return error;
        });
    } else {
      return Promise.reject('Could not locate TransmitterId ' + transmitter_id);
    }
  }

  /**
   * Subscribe for store updates
   * @param listener
   */
  addEventListener(listener: EventListener) {
    this.addListener(constants.CALLS_NOTIFY, listener);
  }

  /**
   * Un-subscribe listener from store update
   * @param listener
   */
  removeEventListener(listener: EventListener) {
    this.removeListener(constants.CALLS_NOTIFY, listener);
  }

  /**
   * Notify listeners about update
   */
  notify(type: string = constants.CALLS_NOTIFY) {
    this.emit(constants.CALLS_NOTIFY, { type });
  }

  getParticipantIdentification(call: CallModel) {

    // if we have one or more participants fetch the one that we initially called to. 
    if (call.Participants.length > 1) {
      return call.Participants.filter(p => p.TelephoneNumber == call.CallTo || p.TelephoneNumber == call.CallFrom || p.TwilioUserName == call.CallFrom || p.TwilioUserName == call.CallTo)![0];
    }
    else if (call.Participants.length === 1) {
      return call.Participants[0];
    }
    else {
      console.error(call);
      var tel = call.CallTo;
      if (call.IsConference) {
        // Must be a incoming call since all outgoing shold be non-conference
        tel = call.CallFrom;
      }
      return {
        "TelephoneNumber": tel
      } as Participant;
    }
  }

  getParticipantDisplayName(participant: Participant) {
    if (participant.DisplayName && participant.DisplayName !== participant.TelephoneNumber) {
      return [participant.DisplayName, participant.TelephoneNumber];
    }
    return [participant.TelephoneNumber];
  }

  unSubscribeForCallUpdates() {
    clearInterval(this.callUpdateHandler);
  }

  /**  
  * This is used for when the websocket is down
  */
  subscribeForCallUpdates() {
    let setting_interval = settingsStore.getSettingInterval('ALARM_REFRESH_FAST');

    if (setting_interval !== null) {
      this.unSubscribeForCallUpdates();
      this.callUpdateHandler = window.setInterval(() => {
        if (authStore.getUserId() && !authStore.isSimpleLogin()) { // If user is logged in
          websocket.checkWebSocket(this, this.fetchActiveCalls)();
        }
        else {
          console.warn('No user logged in with correct access, will not run \'fetchActiveCalls\'');
          this.unSubscribeForCallUpdates();
        }
      }, setting_interval);
    }
  }
}



export default new CallsStore();
