import cx from 'classnames';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from 'react-images';
import constants from '../../../json/constants.json';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
import IconBuilding from '../../components/icons/icon_building';
import IconExpand from '../../components/icons/icon_expand';
import IconPen from '../../components/icons/icon_pen';
import Section from '../../components/section';
import Spinner from '../../components/spinner';
import dateTime from '../../date_time';
import enumDictionary from '../../enum_dictionary';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import localization from '../../localization';
import planningViewStore from '../../stores/alarms/planning_view_store_actions';
import { getFileContent, isNewUploadEnabled } from '../../utilities/fileFetchHelper';
import { filterWidgetSettings } from '../../utilities/filterWidgetSettingsHelper';
import { CommandButton } from './CommandButton';
import { DevicesList } from './DynamicsUserInfoSection';
const endpoints = require('../../../json/endpoints.json');

const handledSettings = ['structuredView', 'fieldsToExclude'];
class UserInfoWidgetUK extends React.Component {
  static contextTypes = {
    templateType: PropTypes.string
  };

  dropdownRef;
  widgetSettings;
  newUpdaloadEnabled = isNewUploadEnabled();

  constructor(props) {
    super(props);

    this.state = { isPreviewOpen: false, isSharedObjectDropdownOpened: false, profileImage: null, profileThumbImage: null };
    this.dropdownRef = React.createRef(null);
    this.fullscreenRef = React.createRef(null);

    this.onOpenPreview = this.onOpenPreview.bind(this);
    this.onClosePreview = this.onClosePreview.bind(this);
    this.widgetSettings = filterWidgetSettings(
      this.props.settings,
      handledSettings,
      this.props.widget ? this.props.widget.widgetName : 'UserInfoSectionUK'
    );
  }

  handleClickOutside = event => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isSharedObjectDropdownOpened: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.newUpdaloadEnabled) {
      this.fetchProfileImage();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  
  fetchProfileImage = async () => {
    const profileImage = await getFileContent(this.props.userDetails.Image);
    const profileThumbImage = await getFileContent(this.props.userDetails.ImageThumb);
    this.setState({ profileImage, profileThumbImage });
  }

  onOpenPreview(e) {
    e.preventDefault();

    this.setState({ isPreviewOpen: true });
  }

  onClosePreview() {
    this.setState({ isPreviewOpen: false });
  }

  savePlanningViewProduct = product => {
    const {
      alarmDetails: { AlarmId },
      updateAlarm
    } = this.props;
    planningViewStore.updatePlannedAlarmProduct(AlarmId, product);
  };

  swapAlarm = () => {
    const {
      swapSharedError,
      canSwapShared,
      alarmT,
      pendingSwapShared,
      userDetails,
      closeSwapSharedError,
      askSwapShared,
      readonly
    } = this.props;

    const sharedObjects = userDetails.ShareAlarmWithObjects;
    if (swapSharedError) {
      // Got an error when trying to swap user on the alarm
      return (
        <div className="flex-col-around flex-0-0-auto">
          <button type="button" className="form-btn-bg-red" onClick={closeSwapSharedError}>
            {swapSharedError}
          </button>
        </div>
      );
    } else if (canSwapShared && sharedObjects) {
      // We have the option to change user on the alarm
      const multipleObjects = sharedObjects.length > 1;
      return (
        <div className="flex-row">
          <div className="flex-col-around flex-0-0-auto">
            <button
              type="button"
              className={cx('button-small-blue', {
                ['readonly-hidden']: readonly
              })}
              onClick={() => {
                if (multipleObjects) {
                  this.setState({
                    isSharedObjectDropdownOpened: !this.state.isSharedObjectDropdownOpened
                  });
                } else {
                  askSwapShared(sharedObjects[0].NationalId);
                }
              }}
            >
              {multipleObjects
                ? alarmT('SWITCH_TO_ANOTHER_OBJECT')
                : alarmT('ALARM_SHARED_WITH').replace(
                    '{ShareAlarmWith}',
                    userDetails.ShareAlarmWith
                  )}
            </button>
            <div>
              {this.state.isSharedObjectDropdownOpened && (
                <ul className="dropdown-list" ref={this.dropdownRef}>
                  {sharedObjects.map(object => (
                    <li
                      key={object.NationalId}
                      onClick={() => askSwapShared(object.NationalId)}
                      className="dropdown-list-item"
                    >
                      {object.DisplayName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      );
    } else if (pendingSwapShared) {
      // We are currently swapping user on the alarm
      return (
        <div className="flex-col-around">
          <Spinner width="1" height="1" widthUnits="em" heightUnits="em" />
        </div>
      );
    } else {
      // Do not show the option of swapping objects
      return null;
    }
  };

  renderProductEditingField = () => {
    const { alarmDetails, isPlanningView, open_type } = this.props;

    return isPlanningView && open_type === 'edit' ? (
      <span>
        <CommandButton
          alarmId={alarmDetails.AlarmId}
          command={{
            InputType: 100
          }}
          elementToDisplay={<IconPen />}
          saveFunction={this.savePlanningViewProduct}
          commandDialogExtraProps={{
            predefinedValue: alarmDetails.TaskType || '',
            data: alarmDetails.DynamicDetails.TaskPlanningTypes
          }}
        />
      </span>
    ) : null;
  };

  render() {
    const showExtraFields = !this.context.templateType;
    const {
      alarmDetails,
      widget,
      swapSharedError,
      canSwapShared,
      alarmT,
      pendingSwapShared,
      userDetails,
      closeSwapSharedError,
      askSwapShared,
      readonly,
      instance,
      isPlanningView,
      toggleType,
      toggleState,
      fullscreenMode
    } = this.props;
    let { headerLocKey } = this.props;
    headerLocKey = alarmT(headerLocKey);
    let callCenterName = '';
    let addWidth = '';
    let addBodyClassName = '';
    let childrenClassName = '';
    let addWrapImageClassName = 'p-05em';
    let addWrapLightboxClassName = '';
    let addLabelWrapClassName = '';
    let labelPosition = 'col';
    let addTextClassName;
    let userInfo = false;
    let devicesListExternalId = userDetails.ExternalId;
    if (this.widgetSettings.structuredView) {
      addWidth = ' w-100p';
      addBodyClassName = 'b-b-c-gray' + addWidth;
      childrenClassName = 'flex-row item-ver b-b-c-gray section-children-w50 p-05-0';
      addWrapImageClassName = 'p-0 w-100p b-b-c-gray';
      addWrapLightboxClassName = 'p-l-05em';
      addLabelWrapClassName = 'w-100p';
      labelPosition = 'row';
      addTextClassName = 'p-l-05em';
      userInfo = true;
    }

    let contactSection = [
      {
        label: 'CUSTOMER_NAME',
        value: instance.linkifyValue(userDetails.Customer.Name),
        skip: true,
        icon: <IconBuilding />
      },
      {
        label: 'CONTACT_PERSON',
        value: instance.linkifyValue(userDetails.Customer.ContactPerson),
        skip: true
      },
      {
        label: 'PIN',
        value: instance.linkifyValue(userDetails.Pin),
        skip: true
      },
      {
        label: 'KEY_PLACEMENT',
        value: showExtraFields ? instance.linkifyValue(userDetails.KeyPlacement) : '',
        skip: true
      },
      {
        label: 'EXTERNAL_ID',
        value: instance.linkifyValue(userDetails.ExternalId),
        skip: true
      }
    ];

    if (isPlanningView) {
      contactSection.push({
        label: localization.t('PLANNING_VIEW_PRODUCT'),
        value:
          instance.linkifyValue(alarmDetails.TaskType) || localization.t('PLANNING_VIEW_NO_DATA'),
        additionalReactElement: this.renderProductEditingField(),
        contentWrapperClassName: 'flex-row'
      });
    }

    const hasContent = true; //Alawys have content
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'UserInfoSectionUK',
      true
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        widget={widget}
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        fullscreenMode={fullscreenMode}
        fullscreenRef={this.fullscreenRef}
        headerContent={
          <div
            className="flex-row-wrap section-header"
            style={{
              backgroundColor:
                widget && widget.headerBackgroundColor ? widget.headerBackgroundColor : ''
            }}
          >
            <div className="p-1em f-15 bold ">{headerLocKey}</div>
            {userDetails.DateOfDeath ? (
              <div className="p-1em bordered">
                <span className="f-15 bold">{alarmT('DECEASED')}</span>
                <span> - </span>
                <span>{dateTime.toLocaleDate(userDetails.DateOfDeath)}</span>
              </div>
            ) : (
              <div className="p-1em f-15 bold flex-1-1-0px" />
            )}

            <div className="flex-row p-05em flex-0-0-auto">
              {fullscreenMode && (
                <button
                  type="button"
                  className="button-small-blue small-button-arrow-icon"
                  ref={this.fullscreenRef}
                >
                  <IconExpand />
                </button>
              )}
              {this.swapAlarm(askSwapShared)}
            </div>
          </div>
        }
        headerLocKey="USER_INFO"
        headerLocOptions={{ ns: constants.NS_ALARM }}
        childrenLocOptions={{ ns: constants.NS_ALARM }}
      >
        {userDetails.ProtectedIdentidy ? (
          <div className="p-05em bold">{alarmT('PROTECTED_IDENTITY')}!</div>
        ) : null}
        <div className={'flex-row-wrap' + addWidth}>
          <Section
            widget={widget}
            sectionClassName="flex-row-wrap"
            bodyClassName={'flex-row-wrap' + addWidth}
            addSectionClassName={'' + addWidth}
            addBodyClassName={addBodyClassName}
            childrenClassName={childrenClassName}
            childrenLocOptions={{ ns: constants.NS_ALARM }}
          >
            {userDetails.ImageThumb && userDetails.Image ? (
              <div className={'flex-col-around flex-0-0-auto ' + addWrapImageClassName}>
                <div className={'profile-picture ' + addWrapLightboxClassName}>
                  {(!this.newUpdaloadEnabled || this.state.profileImage) && (
                    <>
                      <Lightbox
                        isOpen={this.state.isPreviewOpen}
                        backdropClosesModal={true}
                        showImageCount={false}
                        onClose={this.onClosePreview}
                        images={[{ src: this.state.profileImage || '/media/file?filename=' + userDetails.Image }]}
                      />
                      <img
                        className="image"
                        src={this.state.profileThumbImage || '/media/file?filename=' + userDetails.ImageThumb}
                        onClick={this.onOpenPreview}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : null}
            {[
              {
                label: 'CUSTOMER_NAME',
                value: instance.linkifyValue(callCenterName),
                skip: true,
                icon: <IconBuilding />
              },
              { label: 'NAME', value: instance.linkifyValue(userDetails.Name), skip: true },
              { label: 'TITLE', value: instance.linkifyValue(userDetails.Title), skip: true },
              {
                label: 'BIRTH_DATE',
                value: dateTime.toLocaleDate(userDetails.DateOfBirth),
                skip: true
              },
              { label: 'AGE', value: dateTime.getAge(userDetails.DateOfBirth), skip: true },
              {
                label: 'FIRST_LANGUAGE',
                value: instance.linkifyValue(userDetails.FirstLanguage),
                skip: true
              },
              {
                label: 'SECOND_LANGUAGE',
                value: instance.linkifyValue(userDetails.SecondLanguage),
                skip: true
              },
              {
                label: 'THIRD_LANGUAGE',
                value: instance.linkifyValue(userDetails.ThirdLanguage),
                skip: true
              },
              {
                label: 'GENDER',
                value: enumDictionary.formatWithAdditional(userDetails, 'Gender'),
                skip: true
              }
            ].filter(field =>
              field ? !(this.widgetSettings.fieldsToExclude || []).includes(field.label) : false
            )}
          </Section>
        </div>
        {userDetails.Addresses.length
          ? userDetails.Addresses.sort((address_a, address_b) => {
              if (address_a.Order === address_b.Order) {
                return 0;
              }
              return address_a.Order < address_b.Order ? -1 : 1;
            }).map((address, index) => {
              const source = {
                Name: userDetails.Name,
                Telephone1: address.Telephone1,
                Telephone2: address.Telephone2,
                Telephone3: address.Telephone3
              };
              return (
                <div key={index} className={'flex-col ' + addLabelWrapClassName}>
                  <Section
                    widget={widget}
                    childrenLocOptions={{ ns: constants.NS_ALARM }}
                    sectionClassName="flex-row-wrap"
                    bodyClassName="flex-row-wrap"
                    addSectionClassName={'' + addWidth}
                    addBodyClassName={addBodyClassName}
                    childrenClassName={childrenClassName}
                    dataSource={source}
                  >
                    {[
                      {
                        label: 'APARTMENT_NUMBER',
                        value: instance.linkifyValue(address.ApartmentNumber),
                        skip: true
                      },
                      {
                        label: 'STREET',
                        value: instance.linkifyValue(address.StreetAddress),
                        skip: true
                      },
                      {
                        label: 'CITY',
                        value: instance.linkifyValue(address.City),
                        skip: true
                      },
                      {
                        label: 'POSTCODE',
                        value: instance.linkifyValue(address.PostalCode),
                        skip: true
                      },
                      {
                        label: 'COUNTRY',
                        value: instance.linkifyValue(address.Country),
                        skip: true
                      }, // questionable (to define with David)
                      {
                        label: 'CUSTOMER_SPECIFIC_1',
                        value: instance.linkifyValue(address.CustomerSpecific1),
                        skip: true
                      },
                      {
                        label: 'CUSTOMER_SPECIFIC_2',
                        value: instance.linkifyValue(address.CustomerSpecific2),
                        skip: true
                      },
                      {
                        label: 'DESCRIPTION',
                        value: instance.linkifyValue(address.Description),
                        skip: true
                      },
                      {
                        label: 'DOOR_CODE',
                        value: instance.linkifyValue(address.DoorCode),
                        skip: true
                      },
                      {
                        label: 'HOUSE_ALARM_CODE',
                        value: instance.linkifyValue(address.AlarmCode),
                        skip: true
                      },
                      {
                        label: 'TELEPHONE_PRIMARY',
                        value: address.Telephone1,
                        telephone_format: 'yes',
                        skip: true
                      },
                      {
                        label: 'TELEPHONE_SECONDARY',
                        value: address.Telephone2,
                        telephone_format: 'yes',
                        skip: true
                      },
                      {
                        label: 'TELEPHONE_THIRD',
                        value: address.Telephone3,
                        telephone_format: 'yes',
                        skip: true
                      }
                    ].filter(field =>
                      field ? !(this.widgetSettings.fieldsToExclude || []).includes(field.label) : false
                    )}
                  </Section>
                </div>
              );
            })
          : null}
        <Section
          widget={widget}
          sectionClassName="flex-row-wrap"
          childrenLocOptions={{ ns: constants.NS_ALARM }}
          addSectionClassName={'' + addWidth ? addWidth + ' p-0' : ''}
          addBodyClassName={addBodyClassName}
          childrenClassName={childrenClassName}
          bodyClassName={this.props.showAlarmDevices ? 'flex-row-wrap bg-c-white-primary' : ''}
        >
          {contactSection.filter(field =>
            field ? !(this.widgetSettings.fieldsToExclude || []).includes(field.label) : false
          )}
          {this.props.showAlarmDevices && (
            <Observer>
              {() =>
                this.props.alarmId ? (
                  <DevicesList
                    alarmDevices={instance.alarmDevices.get(this.props.alarmId)}
                    alarmId={this.props.alarmId}
                    userExternalId={devicesListExternalId}
                  />
                ) : null
              }
            </Observer>
          )}
        </Section>
      </Section>
    );
  }
}

export default class UserInfoSectionUK extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <UserInfoWidgetUK {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
