const constants = require('../../json/constants.json');

enum AlarmState {
  Deactivated = constants.ALARM_STATE_DEACTIVATED,
  Assigned = constants.ALARM_STATE_ASSIGNED,
  Respondent = constants.ALARM_STATE_RESPONDED,
  My = constants.ALARM_STATE_MY,
  NotRespondent = constants.ALARM_STATE_NOT_RESPONDED
}

export { AlarmState };
