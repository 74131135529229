/* global vxgplayer */
import Logger from 'js-logger';
import React, { Component } from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import '../../utilities/vxgplayer-1.8.20.min.js';

const playerId = 'vxg_media_player_';

export default class CameraSection extends Component {
  getRtspUrl(props = this.props) {
    const { camera } = props;

    if (!camera) return;

    const ip = camera.CameraAddress;
    const port = camera.CameraPort;
    const rtspPath = camera.RtspPath;
    return `rtsp://${ip}:${port}${rtspPath}`;
  }

  componentWillMount() {
    this._id = playerId + Date.now();
  }

  componentDidMount() {
    !!this.getRtspUrl() && this.initializePlayer();
  }

  componentWillReceiveProps(nextProps) {
    if (this.getRtspUrl(nextProps) !== this.getRtspUrl()) {
      try {
        this.disposePlayer();
      } catch (e) {
        Logger.error(`[Camera (${this._id})] error while destroy player since URL is changed`);
      }
    }
  }

  componentDidUpdate() {
    !!this.getRtspUrl() && this.initializePlayer();
  }

  shouldComponentUpdate(nextProps) {
    return this.getRtspUrl(nextProps) !== this.getRtspUrl();
  }

  initializePlayer() {
    try {
      const player = vxgplayer(this._id);
      player.play();

      player.onError(err =>
        Logger.error(`[Camera (${this._id})] Generic error: ${JSON.stringify(err)}`)
      );
      player.onBandwidthError(err =>
        Logger.error(`[Camera (${this._id})] Bandwidth error" ${JSON.stringify(err)}`)
      );
      player.onStateChange(state => Logger.warn(`[Camera (${this._id})] State is now: ${state}`));
      player.onReadyStateChange(state =>
        Logger.info(`[Camera (${this._id})] on ready state: ${state}`)
      );
    } catch (e) {
      Logger.error(`[Camera (${this._id})] Failed to initialize player`);
    }
  }

  disposePlayer() {
    try {
      const player = vxgplayer(this._id);
      player.dispose();
    } catch (e) {
      Logger.error(`[Camera (${this._id})] Failed to dispose player`);
    }
  }

  getPlayerInnerHtml(url, id) {
    return {
      __html: `<div url="${url}" aspect-ratio latency="3000000" controls autostart avsync debug class="vxgplayer" id="${id}"></div>`.trim()
    };
  }

  render() {
    const url = this.getRtspUrl();

    if (url) {
      return (
        <Section
          widget={this.props.widget}
          addBodyClassName="body-hor"
          headerLocKey={(this.props.camera || {}).Title || 'Title not set'}
          toggleType={constants.ENABLED_TOGGLE}
          toggleState={constants.OPENED}
          onToggleStart={state => state === constants.CLOSED && this.disposePlayer()}
          onToggleDone={state => state === constants.OPENED && this.initializePlayer()}
        >
          <div dangerouslySetInnerHTML={this.getPlayerInnerHtml(url, this._id)} />
        </Section>
      );
    }

    return null;
  }
}
