import React from 'react';
import { logToPapertrail } from '../../actions/externalLogging';
import localization from '../../localization';
import externalLogStore from '../../stores/externalLogging/externalLogActions';
interface Props {
  template?: boolean;
}
export class SentryErrorBoundary extends React.Component<Props> {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error });
    // TODO: Not working, make it work or remove all together
    // Sentry.withScope(scope => {
    //   Object.keys(errorInfo).forEach(key => {
    //     scope.setExtra(key, errorInfo[key]);
    //   });
    //   Sentry.captureException(error);
    // });

    // Log error to our backend
    if (externalLogStore?.enhancedLoggingEnabled()) {
      logToPapertrail('ERROR: ' + JSON.stringify(error));
      logToPapertrail('errorInfo: ' + JSON.stringify(errorInfo));
    }
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div>
          <p>
            {this.props.template
              ? localization.t('TEMPLATE_ERROR_HEADER')
              : "We are sorry — something's went wrong."}
          </p>
          <p>
            {this.props.template
              ? localization.t('TEMPLATE_ERROR_TEXT')
              : 'Try to reload the page and see if the problem goes away. If the problem still continues please contact the support'}
          </p>
        </div>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
