import React from 'react';
import { startTwilioCallToNumber } from '../actions/twilio';
import localization from '../localization';
// import TwilioStore from '../stores/twillio_store';
import alarmActiveStore from '../stores/alarms/alarm_active_store_actions';
import settingsStore from '../stores/settings_store';
import IconTelephone from './icon_telephone';
import IconDialpad from './icons/icon_dialpad';
import PopupError from './popups/popup_error';
import { TwilioDialpad } from './twilio_dialpad';

interface Props { }

interface State {
  value: string;
  showDialPad: boolean;
  hasError: boolean;
}

export default class TwilioDialpadWithInput extends React.Component<Props, State> {
  static defaultProps = {
    callInProgress: false
  };

  state = {
    value: '',
    showDialPad: false,
    hasError: false
  };

  parsePhoneNumber = (value: string) => {
    const telephonePrefix = settingsStore.getValueByKey(
      'TELEPHONE_PREFIX',
      'string'
    );

    const start = value[0] === '+' ? 1 : 0;
    value = value.substring(0, start) + value.substring(start).replace(/\D/g, '');
    value = value.substring(0, start ? 16 : 15);

    // replace 075-123 456 to ${prefix}75123456
    if (telephonePrefix && value === "0" && value.length === 1) {
      value = telephonePrefix;
    } else if (telephonePrefix && value[0] === "0" && value.length > 1) {
      value = telephonePrefix + value.substring(1);
    }
    value = value.replace(/[-\s]/g, '');

    return value;
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }) => {
    const value = this.parsePhoneNumber(event.target.value);

    if (this.state.value !== value) {
      this.setState({
        value
      });
    }
  };

  onCall = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (this.state.value) {
      startTwilioCallToNumber(this.state.value, alarmActiveStore.getCurrentAlarmId());
    } else {
      this.setState({ hasError: true });
    }
  };

  onErorModalClose = () => {
    this.setState({ hasError: false });
  };

  onClickShowDialPad = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    this.setState({
      showDialPad: !this.state.showDialPad
    });
  };

  onDialPadClick = (button: { symbol: string; alias?: string }) => {
    this.onChange({
      target: {
        value: `${this.state.value}${button.symbol}`
      }
    });
  };

  onReset = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    this.setState({ value: '' });
  };

  render() {
    const { value, showDialPad } = this.state;
    return (
      <div
        style={{
          position: 'relative'
        }}
      >
        <input
          style={{
            width: '100%',
            fontSize: '1.25rem',
            padding: '5px 20px 5px 5px',
            color: '#4d4d4d',
            marginBottom: '5px',
            boxSizing: 'border-box'
          }}
          onChange={this.onChange}
          type="text"
          value={value}
          autoComplete="off"
        />

        {!!value && (
          <a
            href="#"
            onClick={this.onReset}
            style={{
              fontWeight: 'bold',
              fontSize: '1.875rem',
              textDecoration: 'none',
              color: '#4d4d4d',
              right: '7px',
              position: 'absolute'
            }}
          >
            &times;
          </a>
        )}

        {!!showDialPad && (
          <div className="flex-0-0-auto flex-col telephony-section f-15">
            <TwilioDialpad onClick={this.onDialPadClick} />
          </div>
        )}
        <div className="flex-0-0-auto flex-col telephony-section f-15">
          <div className="flex-row-between">
            <div className="p-05em flex-0-0-15p">
              <a className="flex-row-center button-small-green button-with-icon" onClick={this.onClickShowDialPad}>
                <span className="icon">
                  <IconDialpad />
                </span>
              </a>
            </div>
            <div className="p-05em flex-1-1-0px">
              <a className="flex-row-center button-small-green button-with-icon flex-1-1-100p" onClick={this.onCall}>
                <span className="icon">
                  <IconTelephone />
                </span>
                {localization.t('call')}
              </a>
            </div>
          </div>
        </div>
        <PopupError
          onClick={this.onErorModalClose}
          message={<span style={{ color: '#ff0000' }}>{localization.t('noNumberToDial')}</span>}
          show={this.state.hasError}
        />
      </div>
    );
  }
}
