import React from 'react';
import { joinCall, leaveCall, unHoldCall } from '../actions/twilio';
import IconTelephone from '../components/icon_telephone';
import { CallModel } from '../interfaces/backend_model';
import localization from '../localization';
import CallsStore from '../stores/calls/calls_store';

interface Props {
  call: CallModel;
  isFirst: boolean;
}

interface State { }

export default class CallItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onJoinClick = this.onJoinClick.bind(this);
    this.onLeaveClick = this.onLeaveClick.bind(this);
  }

  onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    unHoldCall(this.props.call.CallSid);
  };

  onJoinClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    joinCall(this.props.call);
  };

  onLeaveClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const activeCall = CallsStore.getActiveCall();
    if (activeCall) {
      this.props.call.JoinCallSid = activeCall.CallSid;
      leaveCall(this.props.call);
    }
  };

  render() {
    const { call } = this.props;
    const participant = CallsStore.getParticipantIdentification(call);
    const displayName = CallsStore.getParticipantDisplayName(participant);
    return (
      <div className={'hold-call-container'}>
        <div className={'resume'}>
          <div className={'hold-call-row'}>
            <div className="icon">
              <IconTelephone />
            </div>
            {displayName.length > 1
              ? <div className="name">{displayName[0]} <span>({displayName[1]})</span></div>
              : <div className="name">{displayName[0]}</div>
            }
            <div>{call.OnHoldAtTime}</div>
            <div className="overlay-container">
              <div className="overlay number">{participant.TelephoneNumber}</div>
              <div
                className={'overlay resume ' + (!call.JoinConferenceAllowed ? 'full-width' : '')}
                onClick={this.onClick}
              >
                {localization.t('RESUME')}
              </div>
              {call.JoinConferenceAllowed && (
                <div className="overlay join" onClick={this.onJoinClick}>
                  {localization.t('JOIN')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
