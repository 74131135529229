import React from 'react';
import constants from '../../../../json/constants.json';
import { ModalWindow } from '../../../components/modal_window';
import { Spacer } from '../../../components/spacer';
import localization from '../../../localization';
import API from '../../../stores/users/api';

export const CancelAbsenceModal = ({
  isEnd,
  isOpen,
  onClose,
  userId,
  userName,
  alarmId,
  absenceId,
}: {
  isEnd: boolean;
  isOpen: boolean;
  onClose: (type: string) => void;
  userId: string;
  userName: string;
  alarmId: string;
  absenceId?: number;
}) => {
  const alarmT = localization.useNSt(constants.NS_ALARM);
  const textKey = isEnd ? 'END' : 'CANCEL';

  const onCancel = async () => {
    API.endAbsence(userId, alarmId, absenceId!, () => {
      onClose('cancel')
    });
  }

  return (
    <ModalWindow
      popupClassName='cancel-absence-modal'
      isOpen={isOpen}
      onClose={() => { onClose('close') }}
      title={userName ?
        alarmT(`${textKey}_ABSENCE`).replace('{object_name}', userName) :
        alarmT(`${textKey}_ABSENCE`)}
      body={(
        <p className='cancel-modal-text'>{alarmT(`ABSENCE_${textKey}_DESCRIPTION`)}</p>
      )}
      footer={(
        <>
          <button
            className='button-large button-large-primary'
            onClick={onCancel}
          >
            {alarmT(`${textKey}_ABSENCE_PERIOD`)}
          </button>
          <Spacer size={8} />
          <button
            className='button-large button-large-secondary'
            onClick={() => onClose('close')}
          >
            {alarmT('KEEP_ABSENCE_PERIOD')}
          </button>
        </>
      )}
      footerClassName='flex-row-center p-1em'
    />
  )
}
