import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import LinearProgress from 'material-ui/LinearProgress';
import MenuItem from 'material-ui/MenuItem';
import Popover from 'material-ui/Popover';
import SelectField from 'material-ui/SelectField';
import AccountCircle from 'material-ui/svg-icons/action/account-circle';
import Search from 'material-ui/svg-icons/action/search';
import Location from 'material-ui/svg-icons/communication/location-on';
import PersonAdd from 'material-ui/svg-icons/social/person-add';
import TextField from 'material-ui/TextField';
import { action, IObservableArray, IObservableValue, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import appHistory from '../app_history';
import IconClose from '../components/icon_close';
import localization from '../localization';
import dictionariesStore from '../stores/dictionaries_store';
import { PermissionsStore } from '../stores/mobx/PermissionsStore';
import { LocationDataModel, UserModel, UsersSearchStore } from '../stores/mobx/UsersSearchStore';
import { SettingsStore } from '../stores/settings_store';

const constants = require('../../json/constants.json');

interface Props {
  usersSearch: UsersSearchStore;
  assignMode: boolean;
  onChangeUserRequestError: () => void;
  alarmId: string;
}

const approvedKeysUser = ['CITY', 'POSTCODE', 'STREET'];

@inject('usersSearch')
export default class UserSearchContainer extends React.Component<Props> {
  componentWillUnmount() {
    this.props.usersSearch.resetSearch();
  }

  render() {
    return (
      <div className="container-users-search flex-1-1-auto form-list flex-col">
        <SearchField />
        <UsersList
          assignMode={this.props.assignMode}
          alarmId={this.props.alarmId}
          onChangeUserRequestError={this.props.onChangeUserRequestError}
        />
      </div>
    );
  }
}

interface PropsAssociateUser {
  usersSearch: UsersSearchStore;
  assignUserMode?: boolean;
  onClose: () => void;
  onChangeUserRequestError: () => void;
  alarmId: string;
  isSearchShowed: IObservableValue<boolean>;
}

@inject('usersSearch')
export class AssociateUserSearchContainer extends React.Component<PropsAssociateUser> {
  componentDidUpdate() {
    if (!this.props.isSearchShowed.get()) {
      this.props.usersSearch.resetSearch();
    }
  }

  componentWillUnmount() {
    this.props.usersSearch.resetSearch();
  }

  render() {
    return (
      <div className="container-users-search flex-1-1-auto form-list flex-col">
        <div className="container-search search-user">
          <div
            className="f-15 bold flex-1-1-0px"
            style={{ whiteSpace: 'nowrap', paddingRight: '0.5em' }}
          >
            {localization.t('ASSOCIATE_WITH_USER')}
          </div>
          <SearchField />
          <button
            type="button"
            className="form-btn-transparent icon-close"
            onClick={this.props.onClose}
          >
            <IconClose />
          </button>
        </div>
        <UsersList
          assignMode={false}
          assignUserMode={this.props.assignUserMode}
          alarmId={this.props.alarmId}
          onChangeUserRequestError={this.props.onChangeUserRequestError}
          onClose={this.props.onClose}
        />
      </div>
    );
  }
}

interface PropsSearchField {
  usersSearch?: UsersSearchStore;
}

@inject('usersSearch')
@observer
class SearchField extends React.Component<PropsSearchField> {
  onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    this.props.usersSearch!.searchFor(target.value);

  render() {
    const { usersSearch } = this.props;

    return (
      <div className="container-search">
        <Search />
        <input
          autoFocus
          type="search"
          className="search f-15 flex-1-1-auto"
          placeholder={localization.t(
            usersSearch!.isFreeSearchType() ? 'SEARCH_PLACEHOLDER_FREE' : 'SEARCH_PLACEHOLDER'
          )}
          onChange={this.onChange}
          value={usersSearch!.searchField}
          autoComplete="off"
          data-focus="1"
        />
      </div>
    );
  }
}

interface PropsUserProfileButton {
  flags?: { key: number; value: string }[];
  store: UsersSearchStore;
  user: UserModel;
  tooltip: string;
}

interface StateUserProfileButton {
  open: boolean;
  anchorEl: SVGElement | null;
  selectedFlag: number | null;
  text: string;
  error: string;
}

@observer
class UserProfileButton extends React.Component<PropsUserProfileButton, StateUserProfileButton> {
  constructor(props: PropsUserProfileButton) {
    super(props);
    const { flags = [] } = this.props;
    this.state = {
      open: false,
      anchorEl: null,
      selectedFlag: (flags[0] || {}).key,
      text: '',
      error: ''
    };
  }

  componentWillReceiveProps() {
    if (!this.state.selectedFlag) {
      const { flags = [] } = this.props;
      this.setState({
        selectedFlag: (flags[0] || {}).key
      });
    }
  }

  onProfileRequest = () => this.props.store.requestUserInfo(this.props.user.userId);
  getToolTip() {
    return this.props.store.isUserInfoLoading
      ? localization.t('PENDING_REQUEST_PROFILE')
      : localization.t('REQUEST_PROFILE', { username: this.props.tooltip });
  }

  onProfileRequestWithFlags = (event: React.ChangeEvent<SVGElement>) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleSubmit = () => {
    if (!this.state.selectedFlag) {
      return this.setState({ error: localization.t('SELECT_FLAG_BEFORE_SUBMIT') });
    }

    this.props.store.requestUserInfo(
      this.props.user.userId,
      this.state.selectedFlag,
      this.state.text
    );
  };

  handleRequestClose = () =>
    this.setState({ open: false, text: '', anchorEl: null, error: '' });

  handleChange = (event: Event, index: number, value: number) =>
    this.setState({ selectedFlag: value, error: '' });

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ text: event.currentTarget.value });

  render() {
    const { isUserInfoLoading } = this.props.store;
    const { flags = [] } = this.props;
    const isFlagsEnabled = flags.length;
    const style = isUserInfoLoading ? { cursor: 'not-allowed' } : {};

    if (!isFlagsEnabled) {
      return (
        <IconButton
          style={style}
          tooltip={this.getToolTip()}
          onClick={isUserInfoLoading ? () => { } : this.onProfileRequest}
        >
          <AccountCircle />
        </IconButton>
      );
    }

    return (
      <div style={{ cursor: 'pointer' }}>
        <AccountCircle onClick={this.onProfileRequestWithFlags} />
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handleRequestClose}
        >
          <div
            style={{
              paddingLeft: '25px',
              paddingRight: '25px',
              paddingBottom: '25px',
              paddingTop: '10px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div style={{ width: '100%' }}>
              <SelectField
                floatingLabelText={localization.t('FLAG') + ' *'}
                value={this.state.selectedFlag}
                onChange={this.handleChange}
                errorText={this.state.error}
              >
                {flags.map(flag => (
                  <MenuItem value={flag.key} primaryText={flag.value} />
                ))}
              </SelectField>
            </div>
            <div style={{ width: '100%' }}>
              <TextField
                value={this.state.text}
                onChange={this.handleInputChange}
                floatingLabelText={localization.t('OPTIONAL_TEXT')}
                autoComplete="off"
              />
            </div>
            <div style={{ width: '100%', textAlign: 'right', paddingTop: '25px' }}>
              <FlatButton
                label={
                  this.props.store.isUserInfoLoading
                    ? localization.t('LOADING') + '...'
                    : localization.t('SUBMIT')
                }
                disabled={this.props.store.isUserInfoLoading}
                onClick={this.props.store.isUserInfoLoading ? undefined : this.handleSubmit}
              />
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

interface PropsLocateUserButton {
  store: UsersSearchStore;
  onComplete: (id: number | string, data: LocationDataModel, err?: string | null) => void;
  user: UserModel;
  tooltip: string;
}

class LocateUserButton extends React.Component<PropsLocateUserButton> {
  onLocate = () => {
    this.props.store.requestUserLocation(
      this.props.user.userId,
      (err: string | null, xhr: XMLHttpRequest, data: LocationDataModel) => {
        return this.props.onComplete(this.props.user.userId, data, err);
      }
    );
  };

  render() {
    return (
      <IconButton tooltip={this.props.tooltip} onClick={this.onLocate}>
        <Location />
      </IconButton>
    );
  }
}

const style = { overflow: 'initial' };

interface PropsUserRow {
  flags: { key: number; value: string }[];
  user: UserModel;
  permissions?: PermissionsStore;
  assignMode: boolean;
  assignUserMode?: boolean;
  tooltip?: string;
  store: UsersSearchStore;
  alarmId: string;
  onChangeUserRequestError: () => void;
  onComplete: (id: number | string, data: LocationDataModel, err?: string | null) => void;
  onClose?: () => void;
}

@inject('permissions')
class UserRow extends React.Component<PropsUserRow> {
  render() {
    const alarmT = localization.useNSt(constants.NS_ALARM);
    const { flags = [] } = this.props;
    const { name, telephoneNumber, positioningEnabled, additionalInformation } = this.props.user;
    // const map = this.props.permissions!.getMap();
    let nameCell = <td>{name}</td>; // can be overiden with `additionalInformation`

    if (this.props.assignMode) {
      return (
        <tr>
          <td>{name}</td>
          <td>{telephoneNumber}</td>
          <td style={style}>
            <IconButton
              tooltip={this.props.tooltip}
              onClick={() => {
                this.props.store
                  .assignUser(this.props.user, this.props.alarmId)
                  .catch(() => this.props.onChangeUserRequestError());
              }}
            >
              <PersonAdd />
            </IconButton>
          </td>
        </tr>
      );
    }

    if (this.props.assignUserMode) {
      let address;
      let customer;
      if (additionalInformation.length) {
        address = (
          <td style={{ whiteSpace: 'pre-line' }}>
            {additionalInformation.map(
              (
                info: { Key: string; Value: string },
                index: number,
                array: { Key: string; Value: string }[]
              ) => {
                if (approvedKeysUser.includes(info.Key)) {
                  return (
                    <span>
                      {alarmT(info.Key)}:&nbsp;{info.Value}
                      {index !== array.length - 1 ? ', ' : null}
                    </span>
                  );
                }
                return '';
              }
            )}
          </td>
        );
        customer = (
          <td style={{ whiteSpace: 'pre-line' }}>
            {additionalInformation.map(
              (
                info: { Key: string; Value: string },
                index: number,
                array: { Key: string; Value: string }[]
              ) => {
                if (info.Key === 'CUSTOMER') {
                  return (
                    <span>
                      {info.Value}
                      {index !== array.length - 1 ? ', ' : null}
                    </span>
                  );
                }
                return '';
              }
            )}
          </td>
        );
      }
      return (
        <tr>
          <td>
            <p>{name}</p>
            <p>
              {localization.t('TELEPHONE')}: {telephoneNumber}
            </p>
          </td>
          <td>{telephoneNumber}</td>
          {address || <td />}
          {customer || <td />}
          <td>
            <button
              className="button-small-blue"
              type="button"
              onClick={() => {
                this.props.store
                  .assignUser(this.props.user, this.props.alarmId, true)
                  .then(() => (this.props.onClose ? this.props.onClose() : null))
                  .catch(() => this.props.onChangeUserRequestError());
              }}
            >
              {localization.t('SELECT')}
            </button>
          </td>
        </tr>
      );
    }

    if (additionalInformation.length) {
      nameCell = (
        <td style={{ whiteSpace: 'pre-line' }}>
          <b>{name}</b>
          {'\n\n'}
          {additionalInformation.map(
            (
              info: { Key: string; Value: string },
              index: number,
              array: { Key: string; Value: string }[]
            ) => {
              return (
                <span>
                  {alarmT(info.Key)}:&nbsp;{info.Value}
                  {index !== array.length - 1 ? ', ' : null}
                </span>
              );
            }
          )}
        </td>
      );
    }

    return (
      <tr>
        {nameCell}
        <td>{telephoneNumber}</td>
        <td style={style}>
          <UserProfileButton
            tooltip={name}
            user={this.props.user}
            store={this.props.store}
            flags={flags}
          />
        </td>
        <td style={style}>
          {positioningEnabled ? (
            <LocateUserButton
              onComplete={this.props.onComplete}
              tooltip={localization.t('LOCATE_USER_POSITION')}
              user={this.props.user}
              store={this.props.store}
            />
          ) : (
            '-'
          )}
        </td>
      </tr>
    );
  }
}

const ReactiveDialogWrapper = observer(({ isOpen, children, bodyText, ...rest }) => (
  <Dialog {...rest} open={isOpen.get()}>
    {localization.t(bodyText.get())}
  </Dialog>
));

interface PropsUsersList {
  usersSearch?: UsersSearchStore | any;
  settings?: SettingsStore;
  assignMode: boolean;
  assignUserMode?: boolean;
  alarmId: string;
  onChangeUserRequestError: () => void;
  onClose?: () => void;
}

@inject('usersSearch', 'settings', 'constants')
@observer
class UsersList extends React.Component<PropsUsersList> {
  dialogState = observable({
    open: observable.box(false),
    text: observable.box(''),
    selectedId: null,
    alarmId: '',
    template: ''
  });

  renderSpinner(isLoading: boolean) {
    return (
      <tr>
        <td colSpan={4} style={{ padding: 0, display: isLoading ? '' : 'none' }}>
          <LinearProgress />
        </td>
      </tr>
    );
  }

  renderNoResults(isLoading: boolean, users: IObservableArray<string | undefined>) {
    return (
      !isLoading &&
      users.length === 0 && (
        <tr>
          <td colSpan={3}>
            <div>{localization.t('NO_USERS_MATCHED')}</div>
          </td>
        </tr>
      )
    );
  }

  @action
  onComplete = (id: number | any, data: LocationDataModel, err?: string | null) => {
    if (err) {
      this.dialogState.open.set(true);
      this.dialogState.text.set('USER_LOCATING_FAIL');
      this.dialogState.selectedId = null;
      return;
    }

    this.dialogState.open.set(true);
    this.dialogState.text.set('USER_LOCATING_SUCCESS');
    this.dialogState.selectedId = id;
    this.dialogState.alarmId = data?.AlarmId;
    this.dialogState.template = data?.Template;
  };

  @action
  handleClose = () => {
    const redirectToPositioningAlarm = () => {
      if (this.dialogState.alarmId && this.dialogState.template) {
        // requestUserLocation returned a new alarm, lets go there!
        appHistory.replace('/dashboard/template/' + this.dialogState.template + '/' + this.dialogState.alarmId);
      }
    };

    this.dialogState.open.set(false);
    setTimeout(redirectToPositioningAlarm, 500); // wait for animation
  };

  render() {
    const { isListLoading, isListError, users } = this.props.usersSearch!;
    const availableAlarmsFlags = this.props.settings!.getValueByKey('AVAILABLE_ALARM_FLAGS');

    let procesedAlarmFlags: [] | { key: number; value: string }[] = [];

    if (availableAlarmsFlags) {
      // flags example -> 63:ALARM_VALUE,62:ALARMVALUEANOTHER,61:ALARMVALUEANOTHER...
      const flags = availableAlarmsFlags;

      procesedAlarmFlags = flags.split(',').map((flag: any) => {
        const [key, value] = flag.split(':');
        let parsedKey = key;

        try {
          parsedKey = parseInt(key, 10);
        } catch (e) { }

        return {
          key: parsedKey,
          value: dictionariesStore.getLocalUserEnumsDictionary()[value] || value
        };
      });
    }

    if (isListError) {
      return <div>{localization.t('ERROR_WHILE_FETCHING_USER_LIST')}</div>;
    }

    const actions = [<FlatButton
      label="OK" primary={true} onClick={this.handleClose} />];

    return (
      <table className="form-table w-100p container-list">
        <thead className="grid-header">
          {this.props.assignMode ? (
            <tr className="header-row">
              <th>{localization.t('NAME')}</th>
              <th>{localization.t('NATIONAL_ID_NUMBER')}</th>
              <th />
            </tr>
          ) : this.props.assignUserMode ? (
            <tr className="header-row">
              <th>{localization.t('USER_AND_PHONE')}</th>
              <th>{localization.t('DEVICE_NUMBER')}</th>
              <th>{localization.t('ADDRESS')}</th>
              <th>{localization.t('CUSTOMER')}</th>
              <th />
            </tr>
          ) : (
            <tr className="header-row">
              <th>{localization.t('NAME')}</th>
              <th>{localization.t('NATIONAL_ID_NUMBER')}</th>
              <th>{localization.t('PROFILE')}</th>
              <th>{localization.t('LOCATION')}</th>
            </tr>
          )}
        </thead>
        <tbody className="grid-body">
          {this.renderSpinner(isListLoading)}
          {users.map((user: any) => (
            <UserRow
              key={user.userId}
              user={user}
              assignMode={this.props.assignMode}
              assignUserMode={this.props.assignUserMode}
              alarmId={this.props.alarmId}
              flags={procesedAlarmFlags}
              store={this.props.usersSearch!}
              onComplete={this.onComplete}
              onChangeUserRequestError={this.props.onChangeUserRequestError}
              onClose={this.props.onClose}
            />
          ))}
          {isListLoading && this.renderNoResults(isListLoading, users)}
        </tbody>
        <ReactiveDialogWrapper
          isOpen={this.dialogState.open}
          title={localization.t('LOCATE_USER_POSITION')}
          actions={actions}
          modal={true}
          bodyText={this.dialogState.text}
        />
      </table>
    );
  }
}
