enum CameraType {
    GENERIC = 0,
    YOYOMOTION = 1,
    CAMERAPROXY = 2,
    APEXIS = 3,
    SUMPPLE = 4,
    FOSCAM = 5,
    ROOMMATE_TRANSMITTER = 6,
    CAMERAPROXY_SECURITY = 7
}
export { CameraType };

