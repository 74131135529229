import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import { Event } from '../event';
import localization from '../localization';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

export interface ActionModel {
  type: string;
  alarm_id?: string;
  template_type?: string;
  callSid?: string;
  user_id?: number;
}

class DictionariesStore extends Event {
  dictionaries: { [key: string]: { [key: string]: string } } | {};
  constructor() {
    super();

    this.dictionaries = {};
  }

  getInitialData() {
    /**
     * store dictionaries information for each dictionary
     */
    this.dictionaries = {};
  }

  getLocalUserLogsDictionary() {
    return this.getUserDictionary(constants.LOG_EVENT, localization.getLanguage());
  }

  getLocalUserEnumsDictionary() {
    return this.getUserDictionary(constants.COMMON_ENUM, localization.getLanguage());
  }

  getLocalUserMeasurmentsDictionary() {
    return this.getUserDictionary(constants.PRODUCT_MEASUREMENT, localization.getLanguage());
  }

  getUserDictionary(dictionary_type: string, language: string) {
    return this.dictionaries[dictionary_type + ':' + language];
  }

  loadInitialDictionaries(language: string) {
    ajax.getByDescWithData(
      false,
      endpoints.DICTIONARY,
      {
        dictionary_type: constants.LOG_EVENT,
        language
      },
      (err: string | null, xhr: XMLHttpRequest, log_event_data: { [key: string]: string }) => {
        if (err) {
          this.trigger(constants.DICTIONARY_SERVER_ERROR, err, xhr);
        } else if (!log_event_data) {
          this.trigger(constants.DICTIONARY_SERVER_ERROR, !log_event_data, xhr);
        } else {
          ajax.getByDescWithData(
            false,
            endpoints.DICTIONARY,
            {
              dictionary_type: constants.COMMON_ENUM,
              language
            },
            (
              err: string | null,
              xhr: XMLHttpRequest,
              common_enum_data: { [key: string]: string }
            ) => {
              if (err) {
                this.trigger(constants.DICTIONARY_SERVER_ERROR, err, xhr);
              } else if (!common_enum_data) {
                this.trigger(constants.DICTIONARY_SERVER_ERROR, !common_enum_data, xhr);
              } else {
                ajax.getByDescWithData(
                  false,
                  endpoints.DICTIONARY,
                  {
                    dictionary_type: constants.PRODUCT_MEASUREMENT,
                    language
                  },
                  (
                    err: string | null,
                    xhr: XMLHttpRequest,
                    product_measurments_data: { [key: string]: string }
                  ) => {
                    if (err) {
                      this.trigger(constants.DICTIONARY_SERVER_ERROR, err, xhr);
                    } else if (!product_measurments_data) {
                      this.trigger(
                        constants.DICTIONARY_SERVER_ERROR,
                        !product_measurments_data,
                        xhr
                      );
                    } else {
                      this.dictionaries[
                        constants.PRODUCT_MEASUREMENT + ':' + language
                      ] = product_measurments_data;
                    }
                    this.dictionaries[constants.LOG_EVENT + ':' + language] = log_event_data;
                    this.dictionaries[constants.COMMON_ENUM + ':' + language] = common_enum_data;
                    this.trigger(constants.DICTIONARY_CHANGE);
                  }
                );
              }
            }
          );
        }
      }
    );
  }
}

const dictionariesStore = new DictionariesStore();
appDispatcher.register((payload: { action: ActionModel; source: string }) => {
  let { action } = payload;
  switch (action.type) {
    case constants.LOAD_SERVER_LOG_EVENT_DICTIONARY:
      dictionariesStore.loadInitialDictionaries(localization.getLanguage());
      break;
    case constants.LOGOUT_CLEAR:
      dictionariesStore.getInitialData();
      break;
  }
});

export default dictionariesStore;
