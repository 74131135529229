import * as Logger from 'js-logger';
import React from 'react';
import ajax from '../../ajax';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import {
  AlarmDetailsModel,
  ObjectTransmitterModel,
  ProductCommandsModel,
  UserDetailsModel,
  Widget
} from '../../interfaces/backend_model';
import localization from '../../localization';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
const constants = require('../../../json/constants.json');
const endpoints = require('../../../json/endpoints.json');
const alarmT = localization.useNSt(constants.NS_ALARM);

interface DeviceMaintenanceSectionProps {
  toggleType?: string;
  toggleState?: string;
  userDetails: UserDetailsModel;
  alarmDetails: AlarmDetailsModel;
  instance: any;
  widget: Widget;
  fullscreenMode?: boolean;
}

function DeviceMaintenanceWidget(props: DeviceMaintenanceSectionProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenCommand, setIsOpenCommand] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [openedTop, setOpenedTop] = React.useState(null);
  const [openedLeft, setOpenedLeft] = React.useState(null);
  const refDevices = React.useRef<HTMLDivElement>(null);
  const [currentDevice, setCurrentDevice] = React.useState<ObjectTransmitterModel>();
  const [currentMessage, setCurrentMessage] = React.useState('');
  const [commandMessage, setCommandMessage] = React.useState('');
  const [currentCommand, setCurrentCommand] = React.useState<ProductCommandsModel>();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(
    () => {
      let mounted = true;
      const { alarmDetails } = props;

      if (mounted && alarmDetails.ObjectTransmitters && alarmDetails.ObjectTransmitters.length) {
        const currentTransmitter = alarmDetails.ObjectTransmitters.find(
          el => el.IsAlarmingTransmitter
        );
        currentTransmitter && setCurrentDevice(currentTransmitter);
        setCurrentMessage('');
        setCommandMessage('');
      }

      return () => {
        mounted = false;
      };
    },
    [props.alarmDetails.ObjectTransmitters]
  );

  const useOutsideClick = (ref: React.RefObject<HTMLDivElement>, callback: () => void) => {
    const handleClick = (e: any) => {
      if (ref && ref.current && ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    React.useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };

  const toggleDevice = (e: any) => {
    let target = e.currentTarget;
    e.stopPropagation();
    setIsOpen(!isOpen);
    setOpenedTop(target.offsetTop + target.offsetHeight);
    setOpenedLeft(target.offsetLeft);
    setErrorMessage('');
  };

  const toggleProducts = (e: any, component?: boolean) => {
    let target = e.currentTarget;
    e.stopPropagation();
    setIsOpenCommand(!isOpenCommand);
    setOpenedTop(target.offsetTop + target.offsetHeight);
    setOpenedLeft(target.offsetLeft);
    setErrorMessage('');
  };

  const stopClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleGlobalClick = () => {
    setIsOpen(false);
    setIsOpenCommand(false);
    setErrorMessage('');
    setOpenedTop(null);
    setOpenedLeft(null);
  };

  const onChooseDevice = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    el: ObjectTransmitterModel
  ) => {
    e.preventDefault();
    handleGlobalClick();

    if (!currentDevice || el.TransmitterId !== currentDevice.TransmitterId) {
      setCurrentDevice(el);
      setCurrentMessage('');
      setCommandMessage('');
    }
  };

  const onChooseCommand = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    el: ProductCommandsModel
  ) => {
    e.preventDefault();
    setIsOpenCommand(false);
    setCurrentMessage(el.TranslationKey || el.Command);
    setCommandMessage(el.Command);
    setCurrentCommand(el);
  };

  useOutsideClick(refDevices, () => {
    if (isOpen || isOpenCommand) {
      handleGlobalClick();
    }
  });

  function handleChange(e: React.FormEvent<HTMLTextAreaElement>) {
    const newValue = e.currentTarget.value;

    if (newValue.length <= 200) {
      setCommandMessage(newValue);
    }
  }

  const onSendCommand = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!currentCommand) {
      return;
    }
    const alarm_id = alarmDetails.AlarmId;
    const transmitter_id = currentDevice?.TransmitterId;
    const command_id = currentCommand.ProductCommandId;
    if (!commandMessage.length || typeof command_id !== 'number') {
      setErrorMessage(alarmT('PRODUCT_COMMAND_ERROR'));
      return;
    }
    setErrorMessage('');
    setIsLoading(true);
    ajax
      .postByDescPromise(endpoints.ALARM_CUSTOM_COMMAND, {
        alarm_id,
        transmitter_id,
        command_id,
        message: commandMessage
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        setErrorMessage(error.err);
        setIsLoading(false);
        Logger.error(error.err);
      });
  };

  const { toggleType, toggleState, alarmDetails, widget, fullscreenMode } = props;

  if (!alarmDetails || !alarmDetails.ObjectTransmitters) {
    return null;
  }

  const transmitters = alarmDetails.ObjectTransmitters;

  if (!transmitters || !transmitters.length) {
    return null;
  }

  const getAvailableDevices = () => {
    const { ObjectTransmitters } = props.alarmDetails;

    if (ObjectTransmitters && ObjectTransmitters.length) {
      const transmitters = ObjectTransmitters.filter(
        el =>
          el.ProductCommands &&
          el.ProductCommands.length &&
          el.ProductCommands.find(el => el.CommandType === 6 || el.CommandType === 7)
      );

      if (!currentDevice || !currentDevice.TransmitterId) {
        return transmitters;
      }

      const transmitter =
        transmitters.filter(el => el.TransmitterId !== currentDevice.TransmitterId) || [];

      if (transmitter.length) {
        return transmitter;
      }
    }
    return [];
  };

  const getProductCommands = (commands: ProductCommandsModel[]) => {
    const availableComands = commands.filter(el => el.CommandType === 6 || el.CommandType === 7);

    return availableComands.map((el: ProductCommandsModel) => {
      return (
        <div className="item-border" style={{ marginBottom: '2px' }} key={el.ProductCommandId}>
          <button
            className="button-small-secondary text-left w-100p flex-row-between flex-w"
            disabled={isLoading}
            onClick={e => onChooseCommand(e, el)}
            type="button"
          >
            {localization.t(el.TranslationKey) || ''}
          </button>
        </div>
      );
    });
  };

  const getContent = (transmitterProductName: string | undefined) => {
    return (
      <div className="d-flex w-100p flex-col-around">
        <div className="item-ver flex-row-around" style={{ flex: '1' }}>
          <div className="flex-col-start bold" ref={refDevices} onClick={handleGlobalClick}>
            <div className="p-rel flex-row">
              <button className="button-small-blue" type="button" onClick={toggleDevice}>
                {alarmT('CHANGE_DEVICE')}
              </button>
              {isOpen ? (
                <div
                  className="p-abs flex-col filter-window"
                  style={{ left: openedLeft + 'px', top: openedTop + 'px', minWidth: '150px' }}
                  onClick={stopClick}
                >
                  {getAvailableDevices().map(el => (
                    <div className="item-border" style={{ marginBottom: '2px' }} key={el.ProductId}>
                      <button
                        className="button-small-secondary text-left w-100p flex-col flex-w"
                        type="button"
                        onClick={e => onChooseDevice(e, el)}
                      >
                        <span>{el.ProductName}</span>
                        <span>{el.TransmitterIdentifier || ''}</span>
                      </button>
                    </div>
                  )) || null}
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex-1-1-0px flex-row-end">
            <div className="flex-col-around p-rel">
              <button className="button-small-blue" type="button" onClick={toggleProducts}>
                {alarmT('PRODUCT_COMMAND_SELECT')}
              </button>
              {isOpenCommand ? (
                <div
                  className="p-abs flex-col filter-window"
                  style={{ right: openedLeft + 'px', top: openedTop + 'px', minWidth: '150px' }}
                  onClick={stopClick}
                >
                  {currentDevice && currentDevice.ProductCommands.length
                    ? getProductCommands(currentDevice.ProductCommands)
                    : ''}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="item-ver flex-row-between" style={{ flex: '1' }}>
          <div>
            <div className="bold">{alarmT('CURRENT_DEVICE')}</div>
            <div>{transmitterProductName}</div>
          </div>
          <div>
            <div className="bold">{alarmT('PRODUCT_COMMAND_CURRENT')}</div>
            <div>{currentMessage ? alarmT(currentMessage) : ''}</div>
          </div>
        </div>
        <div className="item-ver flex-row-between" style={{ flex: '1', alignItems: 'flex-end' }}>
          <div className="flex-col-start" style={{ width: '65%' }}>
            <div className="bold" style={{ paddingBottom: '0.25em' }}>
              {alarmT('PRODUCT_COMMAND_MESSAGE')}
            </div>
            <textarea
              style={{
                width: 'calc( 100% - 6px )',
                resize: 'none',
                height: '40px',
                fontSize: '12px'
              }}
              value={commandMessage}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
          <div className="flex-col-around">
            <div className="p-rel flex-row-end" style={{ marginLeft: '2px' }}>
              {errorMessage ? (
                <button className="form-btn-bg-red" type="button" onClick={onSendCommand}>
                  {errorMessage}
                </button>
              ) : (
                <button
                  className="button-small-green"
                  type="button"
                  onClick={onSendCommand}
                  disabled={isLoading}
                >
                  {alarmT('PRODUCT_COMMAND_SEND')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const hasContent = true; //Alawys have content
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "DeviceMaintenanceSection", true);
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocKey={alarmT('PRODUCT_COMMANDS_HEADER')}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      {getContent(currentDevice?.ProductName)}
    </Section>
  );
}

export default class DeviceMaintenanceSection extends React.Component<DeviceMaintenanceSectionProps> {
  render() {
    return (
      <WidgetErrorBoundary>
        <DeviceMaintenanceWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
