import React from 'react';
import Section from '../../components/section';
import { Widget } from '../../interfaces/backend_model';
import localization from '../../localization';

const constants = require('../../../json/constants.json');
const alarmT = localization.useNSt(constants.NS_ALARM);

interface ExternalSectionProps {
  toggleState?: string;
  widget: Widget;
  url: string;
  headerLocKey: string;
}

export default function ExternalSection(props: ExternalSectionProps) {
  const { toggleState, widget, url, headerLocKey } = props;

  const getContent = () => {
    return (
      <div className="d-flex w-100p flex-col-around">
        <div className="item-ver flex-row-around" style={{ flex: '1' }}>
          <iframe src={url} className="external-iframe" />
        </div>
      </div>
    );
  };

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={constants.ENABLED_TOGGLE}
      toggleState={toggleState ? toggleState : constants.OPENED}
      headerLocKey={alarmT(headerLocKey || 'CONTACT_EXTERNAL_INFO')}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      {getContent()}
    </Section>
  );
}
