import React from 'react';
import { pruneCaller } from '../utilities/user';
import Utils from '../utils';
import IconMessage from './icon_message';
import IconTelephone from './icon_telephone';

interface NumberRenderer {
  tel: string;
  href: string;
  childRef?: Function;
  handleOnPhoneClick: React.MouseEventHandler<HTMLAnchorElement>;
  handleOnMessageClick: React.MouseEventHandler<HTMLAnchorElement>;
  className: string;
  showMessageButton?: boolean;
  typeDiv?: JSX.Element;
}

const PhoneNumberRenderer = ({
  className,
  showMessageButton,
  href,
  childRef,
  handleOnPhoneClick,
  handleOnMessageClick,
  tel,
  typeDiv
}: NumberRenderer) => {
  const [isClickable, setIsClickable] = React.useState(true);
  const timerRef = React.useRef({} as NodeJS.Timeout);

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    eventHandler: React.MouseEventHandler<HTMLAnchorElement>
  ) => {
    if (isClickable) {
      setIsClickable(false);
      eventHandler(event);

      // some components using this instance does not rerender when clicked, thus we need to reenable the
      // click a safe amount of time after the click has happened to prevent bad doubleclicks
      timerRef.current = setTimeout(() => {
        setIsClickable(true);
      }, 1000);
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const classNames = `${className} hover-icon`;
  return (
    <>
      {showMessageButton ? (
        <div className="d-flex">
          {typeDiv}
          <div className="flex-col-around p-l-05em">{pruneCaller(tel)}</div>
          <div className="flex-row-between telephone margin-side-05">
            <a
              title={tel}
              data-tel={tel}
              className="flex-col-around hover-icon"
              {...Utils.renderRef(childRef)}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                handleClick(event, handleOnPhoneClick)
              }
              href={href}
            >
              <IconTelephone />
            </a>

            <a
              title={tel}
              data-tel={tel}
              className="flex-col-around hover-icon"
              {...Utils.renderRef(childRef)}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                handleClick(event, handleOnMessageClick)
              }
              href={href}
            >
              <IconMessage />
            </a>
          </div>
        </div>
      ) : (
        <a
          title={tel}
          data-tel={tel}
          {...Utils.renderRef(childRef)}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
            handleClick(event, handleOnPhoneClick)
          }
          className={classNames}
          href={href}
        >
          {typeDiv}
          <div className="flex-col-around">
            <IconTelephone />
          </div>
          <div className="flex-col-around p-l-05em">{pruneCaller(tel)}</div>
        </a>
      )}
    </>
  );
};

export default PhoneNumberRenderer;
