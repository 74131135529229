import React from 'react';
import localization from '../../localization';

interface Props {
  show: boolean;
  onClose?: Function;
  message: string;
  onClick: any;
}

export default class PopupConfirmPreset extends React.Component<Props> {
  popupEl: HTMLElement | null = null;
  prevFocusedElement: HTMLElement | null = null;

  componentDidMount() {
    this.prevFocusedElement = null;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.show !== prevProps.show) {
      if (this.props.show) {
        this.attachGlobalHandler();
        this.prevFocusedElement = document.activeElement as HTMLElement;

        if (this.popupEl) {
          let el = this.popupEl.querySelector('[data-focus="1"]') as HTMLElement;
          el && el.focus();
        }
      } else {
        this.deattachGlobalHandler();
        if (this.prevFocusedElement) {
          this.prevFocusedElement.focus();
          if (document.activeElement) {
            (document.activeElement as HTMLElement).blur();
          }
        }
      }
    }
  }

  attachGlobalHandler = () => document.addEventListener('keydown', this.onKeyPress, false);
  deattachGlobalHandler = () => document.removeEventListener('keydown', this.onKeyPress);

  onKeyPress = (ev: KeyboardEvent) => {
    if (ev.code === 'Tab') {
      ev.preventDefault();
    } else if (ev.code === 'Escape') {
      ev.preventDefault();
      this.props?.onClose?.();
    }
  };

  render() {
    let commonClassName = 'flex-outer-container fx-wh-100vwh popup ',
      fullClassName = commonClassName + (this.props.show ? 'in' : 'hidden-popup');

    if (!this.props.show) {
      return null;
    }

    return (
      <button
        type='button'
        className={fullClassName}
        style={{ cursor: 'default' }}
        onClick={this.props.onClick}
        ref={node => (this.popupEl = node)}
      >
        <div className="flex-center flex-col popup-message">
          <div className={'w-100p p-t-b-05em f-15 bold'}>
            <div className="p-r-l-1em">{this.props.message || ''}</div>
          </div>
          <div className="flex-center p-t-b-05em border-top-footer w-100p">
            <div className="flex-row-around w-100p">
              <div className="flex-col-around">
                <button className="button-small-secondary" type="button" data-click="close">
                  {localization.t('CLOSE')}
                </button>
              </div>
              <div className="flex-col-around">
                <button className="button-small-green" type="button" data-click="continue">
                  {localization.t('CONTINUE')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </button>
    );
  }
}
