import axios from 'axios';

// en
import en_GB_common from './en/GB/common';
import en_GB_login from './en/GB/login';
import en_GB_datetime from './en/GB/datetime';
import en_GB_alarm from './en/GB/alarm';
import en_GB_map from './en/GB/map';
// nb
import nb_NO_common from './nb/NO/common';
import nb_NO_login from './nb/NO/login';
import nb_NO_datetime from './nb/NO/datetime';
import nb_NO_alarm from './nb/NO/alarm';
import nb_NO_map from './nb/NO/map';
// sv
import sv_SE_common from './sv/SE/common';
import sv_SE_login from './sv/SE/login';
import sv_SE_datetime from './sv/SE/datetime';
import sv_SE_alarm from './sv/SE/alarm';
import sv_SE_map from './sv/SE/map';
// sv person
import sv_SE_person_common from './sv/SE/person/common';
import sv_SE_person_login from './sv/SE/person/login';
import sv_SE_person_datetime from './sv/SE/person/datetime';
import sv_SE_person_alarm from './sv/SE/person/alarm';
import sv_SE_person_map from './sv/SE/person/map';
// sv trygg
import sv_SE_trygg_common from './sv/SE/trygg/common';
import sv_SE_trygg_login from './sv/SE/trygg/login';
import sv_SE_trygg_datetime from './sv/SE/trygg/datetime';
import sv_SE_trygg_alarm from './sv/SE/trygg/alarm';
import sv_SE_trygg_map from './sv/SE/trygg/map';
// sv trygg sys
import sv_SE_trygg_sys_common from './sv/SE/trygg/sys/common';
import sv_SE_trygg_sys_login from './sv/SE/trygg/sys/login';
import sv_SE_trygg_sys_datetime from './sv/SE/trygg/sys/datetime';
import sv_SE_trygg_sys_alarm from './sv/SE/trygg/sys/alarm';
import sv_SE_trygg_sys_map from './sv/SE/trygg/sys/map';
// sv trygg temp
import sv_SE_trygg_temp_common from './sv/SE/trygg/temp/common';
import sv_SE_trygg_temp_login from './sv/SE/trygg/temp/login';
import sv_SE_trygg_temp_datetime from './sv/SE/trygg/temp/datetime';
import sv_SE_trygg_temp_alarm from './sv/SE/trygg/temp/alarm';
import sv_SE_trygg_temp_map from './sv/SE/trygg/temp/map';
// fi
import fi_FI_common from './fi/FI/common';
import fi_FI_datetime from './fi/FI/datetime';
import fi_FI_login from './fi/FI/login';
import fi_FI_map from './fi/FI/map';
import fi_FI_alarm from './fi/FI/alarm';

const constants = require('../../json/constants.json');

export default {
  [constants.DEFAULT_LANGUAGE]: {
    [constants.NS_DEFAULT]: en_GB_common,
    [constants.NS_LOGIN]: en_GB_login,
    [constants.NS_DATETIME]: en_GB_datetime,
    [constants.NS_ALARM]: en_GB_alarm,
    [constants.NS_MAP]: en_GB_map
  },
  [constants.LANGUAGE_NB_NO]: {
    [constants.NS_DEFAULT]: nb_NO_common,
    [constants.NS_LOGIN]: nb_NO_login,
    [constants.NS_DATETIME]: nb_NO_datetime,
    [constants.NS_ALARM]: nb_NO_alarm,
    [constants.NS_MAP]: nb_NO_map
  },
  [constants.LANGUAGE_SV_SE]: {
    [constants.NS_DEFAULT]: sv_SE_common,
    [constants.NS_LOGIN]: sv_SE_login,
    [constants.NS_DATETIME]: sv_SE_datetime,
    [constants.NS_ALARM]: sv_SE_alarm,
    [constants.NS_MAP]: sv_SE_map
  },
  [constants.LANGUAGE_SV_SE_PERSON]: {
    [constants.NS_DEFAULT]: sv_SE_person_common,
    [constants.NS_LOGIN]: sv_SE_person_login,
    [constants.NS_DATETIME]: sv_SE_person_datetime,
    [constants.NS_ALARM]: sv_SE_person_alarm,
    [constants.NS_MAP]: sv_SE_person_map
  },
  [constants.LANGUAGE_SV_SE_TRYGG]: {
    [constants.NS_DEFAULT]: sv_SE_trygg_common,
    [constants.NS_LOGIN]: sv_SE_trygg_login,
    [constants.NS_DATETIME]: sv_SE_trygg_datetime,
    [constants.NS_ALARM]: sv_SE_trygg_alarm,
    [constants.NS_MAP]: sv_SE_trygg_map
  },
  [constants.LANGUAGE_SV_SE_TRYGG_SYS]: {
    [constants.NS_DEFAULT]: sv_SE_trygg_sys_common,
    [constants.NS_LOGIN]: sv_SE_trygg_sys_login,
    [constants.NS_DATETIME]: sv_SE_trygg_sys_datetime,
    [constants.NS_ALARM]: sv_SE_trygg_sys_alarm,
    [constants.NS_MAP]: sv_SE_trygg_sys_map
  },
  [constants.LANGUAGE_SV_SE_TRYGG_TEMP]: {
    [constants.NS_DEFAULT]: sv_SE_trygg_temp_common,
    [constants.NS_LOGIN]: sv_SE_trygg_temp_login,
    [constants.NS_DATETIME]: sv_SE_trygg_temp_datetime,
    [constants.NS_ALARM]: sv_SE_trygg_temp_alarm,
    [constants.NS_MAP]: sv_SE_trygg_temp_map
  },
  [constants.LANGUAGE_FI]: {
    [constants.NS_DEFAULT]: fi_FI_common,
    [constants.NS_LOGIN]: fi_FI_login,
    [constants.NS_DATETIME]: fi_FI_datetime,
    [constants.NS_ALARM]: fi_FI_alarm,
    [constants.NS_MAP]: fi_FI_map
  }
};

export const availableLanguages = [
  constants.DEFAULT_LANGUAGE,
  constants.LANGUAGE_NB_NO,
  constants.LANGUAGE_SV_SE,
  constants.LANGUAGE_SV_SE_PERSON,
  constants.LANGUAGE_SV_SE_TRYGG,
  constants.LANGUAGE_SV_SE_TRYGG_SYS,
  constants.LANGUAGE_SV_SE_TRYGG_TEMP,
  constants.LANGUAGE_FI
];

export const availablesNamespaces = [
  constants.NS_DEFAULT,
  constants.NS_LOGIN,
  constants.NS_DATETIME,
  constants.NS_ALARM,
  constants.NS_MAP
];

export function performRequests({ useLocize, locizeProjectId, version, languages }) {
  const requests = [];

  languages.forEach(lang => {
    availablesNamespaces.forEach(namespace => {
      let url;

      if (useLocize) {
        url = `https://api.locize.io/${locizeProjectId}/${version}/${lang}/${namespace}`;
      } else {
        url = `/media/dict?type=${namespace}&language=${lang}&version=${version}`;
      }

      requests.push(
        axios.get(url).then(response => ({
          data: Object.keys(response.data).length === 0 ? {} : response.data,
          namespace,
          lang
        }))
      );
    });
  });

  return requests;
}

const cachedFallbacksMap = {};

export function getFallbackLanguages(language) {
  const defaultLanguage = 'en-GB';
  const defaultFallbacks = ['en-GB'];

  const languageByKeys = language.split('-');
  const separatorIdx = languageByKeys.indexOf('x');
  const mainLanguage = languageByKeys.slice(0, separatorIdx);
  const additionalLanguages = languageByKeys.slice(separatorIdx + 1);
  const languageFallbacks = [];

  if (language === defaultLanguage) {
    return [defaultLanguage];
  }

  if (!language.includes('x')) {
    return [defaultLanguage];
  }

  for (let iterator = additionalLanguages.length; --iterator >= 0; ) {
    const languageFallback = languageByKeys.slice(
      0,
      languageByKeys.indexOf(additionalLanguages[iterator])
    );

    if (languageFallback[languageFallback.length - 1] === 'x') {
      languageFallbacks.push(languageFallback.slice(0, languageFallback.length - 1).join('-'));
    } else {
      languageFallbacks.push(languageFallback.join('-'));
    }
  }

  const fallbacks = languageFallbacks
    .concat(defaultFallbacks)
    .filter((item, pos, self) => self.indexOf(item) === pos);

  if (!cachedFallbacksMap[language]) {
    cachedFallbacksMap[language] = fallbacks;
  }

  return cachedFallbacksMap[language];
}
