import ajax from '../ajax';

const endpoints = require('../../json/endpoints.json');

export function sendChoosenCommand(
  alarmId: string,
  cameraId: number,
  currentCommand: [number, string]
) {
  const [presetId, command] = currentCommand;

  switch (command) {
    case 'apply':
      return ajax.postByDescPromise(endpoints.CAMERA_GO_TO_PRESET, {
        alarmId,
        cameraId,
        presetId
      });

    case 'reset':
      return ajax.postByDescPromise(endpoints.CAMERA_CONFIGURE_PRESET, {
        alarmId,
        cameraId,
        presetId
      });
    default:
      return Promise.resolve();
  }
}

export function cameraMoveTo(
  alarmId: string,
  cameraId: number,
  xspeed: number,
  yspeed: number,
  zspeed: number
) {
  return ajax.postByDescPromise(endpoints.CAMERA_MOVE_TO, {
    alarmId,
    cameraId,
    xspeed,
    yspeed,
    zspeed
  });
}
