import { SortingByTabModel } from 'development/js/interfaces/backend_model';
import * as Logger from 'js-logger';
import appDispatcher from '../../app_dispatcher';
import AlarmListStore from './alarm_list_store';

const constants = require('../../../json/constants.json');

const alarmListStore = new AlarmListStore();

export interface ActionChangeAlarmsTab {
  type: 'ACTION_CHANGE_ALARMS_TAB';
  tab: string;
}
export interface ActionClearAlarm {
  type: 'ACTION_CLEAR_ALARM';
  alarm_id: string;
}

export interface ActionChangeSorting {
  type: 'ACTION_CHANGE_SORTING';
  sorting_by_tab: SortingByTabModel;
}
export interface LoadServerAlarms {
  type: 'LOAD_SERVER_ALARMS';
  keep_unique_alarms?: boolean;
}
export interface ClearServerAlarms {
  type: 'CLEAR_SERVER_ALARMS';
}

export interface StartUpdatingServerAlarms {
  type: 'START_UPDATING_SERVER_ALARMS';
}
export interface StopUpdatingServerAlarms {
  type: 'STOP_UPDATING_SERVER_ALARMS';
}

export interface LoadServerAlarmDetailsBasic {
  type: 'LOAD_SERVER_ALARM_DETAILS_BASIC';
  alarm_id: string;
}

export interface LogoutClear {
  type: 'LOGOUT_CLEAR';
}
export type Actions =
  | ActionChangeAlarmsTab
  | ActionClearAlarm
  | ActionChangeSorting
  | LoadServerAlarms
  | ClearServerAlarms
  | StartUpdatingServerAlarms
  | StopUpdatingServerAlarms
  | LoadServerAlarmDetailsBasic
  | LogoutClear;

appDispatcher.register((payload: { source: string; action: Actions }) => {
  let action = payload.action;

  switch (action.type) {
    case 'ACTION_CHANGE_ALARMS_TAB':
      alarmListStore.activeTab = action.tab;
      alarmListStore.trigger(constants.CHANGE_ALARMS_TAB);
      break;

    case 'ACTION_CLEAR_ALARM':
      Logger.info('alarm_list_store => ACTION_CLEAR_ALARM => _clearAlarm');
      alarmListStore._clearAlarm(action.alarm_id);
      break;

    case 'ACTION_CHANGE_SORTING':
      alarmListStore._changeSorting(action.sorting_by_tab);
      break;

    case 'LOAD_SERVER_ALARMS':
      alarmListStore.loadAlarms(action.keep_unique_alarms);
      break;

    case 'CLEAR_SERVER_ALARMS':
      alarmListStore.clearAlarms();
      break;

    case 'START_UPDATING_SERVER_ALARMS':
      alarmListStore.subscribeForAlarmsUpdates();
      break;

    case 'STOP_UPDATING_SERVER_ALARMS':
      alarmListStore.unSubscribeForAlarmsUpdates();
      break;

    case 'LOAD_SERVER_ALARM_DETAILS_BASIC':
      // loading alarm basics for a planning view alarm pushes it to the active field
      // it is not an active alarm so we dont want it there.
      if (action.alarm_id) {
        alarmListStore.loadSingleAlarm(action.alarm_id);
      }
      break;

    case 'LOGOUT_CLEAR':
      alarmListStore.getInitialData();
      break;
  }
});

export default alarmListStore;
