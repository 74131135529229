import { ModalWindow } from '../../components/modal_window';
import localization from '../../localization';
import constants from '../../../json/constants.json';
import React from 'react';
import AlarmAPI from '../../stores/alarms/api';
import Logger from 'js-logger';

interface AcknowledgeModalProps {
  alarmId?: string;
  acknowledgement?: string;
  isOpen: boolean;
  onClose: () => void;
}
export const AcknowledgeModal = ({
  alarmId,
  acknowledgement,
  isOpen,
  onClose,
}: AcknowledgeModalProps) => {
  const alarmT = localization.useNSt(constants.NS_ALARM);
  const commonT = localization.useNSt(constants.NS_DEFAULT);

  const sendAcknowledge = async () => {
    try {
      await AlarmAPI.sendAcknowledgement(alarmId!);
      onClose();
    } catch (error) {
      Logger.error(error);
    }
  }

  return (
    <ModalWindow
      popupClassName='acknowledge-modal'
      isOpen={isOpen}
      onClose={onClose}
      title={alarmT('ACKNOWLEDGE_MODAL_TITLE')}
      body={(
        <div className='p-t-b-1em'>
          {acknowledgement}
        </div>
      )}
      footer={(
        <button
          className='button-large button-large-primary'
          onClick={sendAcknowledge}
        >
          {commonT('GOT_IT')}
        </button>
      )}
      footerClassName='flex-row-center p-05em'
      contentStyle={{
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.75)'
      }}
      withCloseButton={false}
      closeOnEscape={false}
    />
  )
}
