import React from 'react';
import endpoints from '../../../json/endpoints.json';
import ajax from '../../ajax';
import CallResource from '../../components/call_resource';
import Grid from '../../components/grid';
import Section from '../../components/section';
import TelephoneNumber from '../../components/telephone_number';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { isContactActive } from './calcContactClassName';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
class ResourcesWidget extends React.PureComponent {
  state = {
    resources: []
  };
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps;

    if (nextProps.showResources) {
      this.getResourcesWorkflow();
    } else {
      // if we dont want to fetch resources we need to set the resources in state to undefined to prevent
      // showing resources in the wrong alarm
      this.setState({
        getResourcesError: null,
        pendingGetResources: null,
        resources: []
      });
    }
  }

  getResourcesWorkflow = () => {
    const alarm_id = this.props.alarmId;

    this.setState({ pendingGetResources: true });

    ajax.getByDescWithData(true, endpoints.RESOURCES, { alarm_id }, (err, xhr, data) => {
      if (err) {
        this.setState({
          getResourcesError: err,
          pendingGetResources: null
        });
      } else {
        this.setState({
          getResourcesError: err,
          pendingGetResources: null,
          resources: data
        });
      }
    });
  };

  renderRow(headers, key) {
    return (resource, row_index) => (
      <tr key={resource[key] + '_' + row_index} className={isContactActive(resource) ? '' : 'bg-c-gray-primary'}>
        {headers.map(header => {
          let value;

          switch (header.key) {
            case 'Resource':
              value = (
                <CallResource
                  resource={resource}
                  alarmId={this.props.alarmId}
                  alarmT={this.props.alarmT}
                />
              );
              break;
            case 'Telephone1':
              value = [resource.Telephone1, resource.Telephone2, resource.Telephone3]
                .filter(telephone => !!telephone)
                .map((telephone, telephone_index) => (
                  <div key={header.key + '_' + telephone + '_' + telephone_index}>
                    <TelephoneNumber
                      value={telephone}
                      contactId={resource.ContactId}
                      resource={resource}
                    />
                  </div>
                ));
              break;
            default:
              value = Grid.formatValue(header, resource, resource[header.key]);
              break;
          }
          return <td key={header.key}>{value}</td>;
        })}
      </tr>
    );
  }

  render() {
    const {
      toggleType,
      toggleState,
      instance,
      alarmT,
      commonT,
      readonly,
      showHideButton,
      widget,
      showHideButtonLocKey
    } = this.props;
    let resources = this.props.resources;
    const resourcesRowKey = 'Order';
    let headers = [
      {
        key: 'Name',
        content: alarmT('NAME')
      },
      {
        key: 'Relation',
        content: alarmT('RELATIONSHIP')
      },
      {
        key: 'Telephone1',
        telephone_format: 'yes',
        content: alarmT('TELEPHONE')
      },
      {
        key: 'Address',
        content: alarmT('ADDRESS')
      },
      {
        key: 'Resource',
        content: commonT('ASSIGN')
      }
    ];

    if (readonly) {
      headers = [
        {
          key: 'Name',
          content: alarmT('NAME')
        },
        {
          key: 'Relation',
          content: alarmT('RELATIONSHIP')
        },
        {
          key: 'Telephone1',
          telephone_format: 'yes',
          content: alarmT('TELEPHONE')
        }
      ];
    }

    const hasContent = (resources && resources.length) ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'ResourcesSection'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        widget={widget}
        addSectionClassName="flex-0-0-auto"
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        headerLocKey={toggleType ? alarmT('RESOURCES') : null}
        showHideButton={showHideButton}
        onClickShow={instance.showResources}
        headerContent={
          <div
            className="flex-row-wrap section-header"
            style={{ backgroundColor: (widget && widget.headerBackgroundColor) ? widget.headerBackgroundColor : '' }}
          >
            <div className="p-1em f-15 bold flex-1-1-0px">{alarmT('RESOURCES')}</div>
            <div className="flex-row p-05em flex-0-0-auto">
              <div className="flex-col-around flex-0-0-auto">
                <button type="button" className="button-small-blue" onClick={instance.showResources}>
                  {showHideButtonLocKey ? alarmT(showHideButtonLocKey) : alarmT('MORE_DETAILS')}
                </button>
              </div>
            </div>
          </div>
        }
      >
        {resources && resources.length ? (
          <Grid
            headers={headers}
            table={true}
            wrapperClassName="w-100p"
            tableClassName="form-table  w-100p"
            rowKey={resourcesRowKey}
            scroll
            renderRow={this.renderRow(headers, resourcesRowKey)}
            rows={resources.sort((contact_a, contact_b) => {
              if (contact_a.Order === contact_b.Order) {
                return 0;
              }
              return contact_a.Order < contact_b.Order ? -1 : 1;
            })}
          />
        ) : null}
      </Section>
    );
  }
}

export default class ResourcesSection extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <ResourcesWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
