import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import EnumDictionary from '../../enum_dictionary';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { filterWidgetSettings } from '../../utilities/filterWidgetSettingsHelper';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
const handledSettings = ['fieldsToExclude'];

class AdditionalUserInfoWidget extends React.Component {
  static contextTypes = {
    templateType: PropTypes.string
  };

  widgetSettings;

  constructor(props) {
    super(props);

    this.widgetSettings = filterWidgetSettings(
      this.props.settings,
      handledSettings,
      this.props.widget ? this.props.widget.widgetName : 'AdditionalUserInfoSection'
    );
  }

  render() {
    const { userDetails, toggleType, toggleState, template, instance, widget, fullscreenMode } = this.props;
    const showExtraFields =
      !this.context.templateType ||
      this.context.templateType !== 'kristiansund' ||
      this.context.templateType !== 'doronorway';

    let nationalIdNumber = {
      label: 'NATIONAL_ID_NUMBER',
      value: showExtraFields ? instance.linkifyValue(userDetails.NationalIdNumber) : null,
      skip: true
    };

    switch (template) {
      case 'goteborg':
      case 'goteborg_v2':
        nationalIdNumber = null;
    }

    const hasContent = userDetails ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'AdditionalUserInfoSection'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        addBodyClassName="body-hor p-t0-l0-1"
        widget={widget}
        headerLocKey={'ADDITIONAL_USER_INFO'}
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        fullscreenMode={fullscreenMode}
        headerLocOptions={{ ns: constants.NS_ALARM }}
        childrenLocOptions={{ ns: constants.NS_ALARM }}
      >
        {[
          {
            label: 'PROFESSION',
            value: instance.linkifyValue(userDetails.Profession),
            skip: true
          },
          {
            label: 'CITIZENSHIP',
            value: instance.linkifyValue(userDetails.CitizenShip),
            skip: true
          },
          {
            label: 'CARE_PLAN',
            value: instance.linkifyValue(userDetails.CarePlan),
            skip: true
          },
          {
            label: 'MEDICAL_CONDITION',
            value: instance.linkifyValue(userDetails.MedicalCondition),
            skip: true
          },
          {
            label: 'SUPPORTING_AIDS',
            value: instance.linkifyValue(userDetails.SupportingAids),
            skip: true
          },
          {
            label: 'MEDICATION',
            value: instance.linkifyValue(userDetails.Medication),
            skip: true
          },
          {
            label: 'ADDITIONAL_INFO',
            value: instance.linkifyValue(userDetails.AdditionalInfo),
            skip: true
          },
          { label: 'EMAIL', value: instance.linkifyValue(userDetails.Email), skip: true },
          nationalIdNumber,
          {
            label: 'INSURANCE',
            value: instance.linkifyValue(userDetails.Insurance),
            skip: true
          },
          {
            label: 'HEIGHT_CM',
            value: instance.linkifyValue(userDetails.Height),
            skip: true
          },
          {
            label: 'WEIGHT_KG',
            value: instance.linkifyValue(userDetails.Weight),
            skip: true
          },
          {
            label: 'HAIR_COLOR',
            value: instance.linkifyValue(
              EnumDictionary.formatEnum('HairColour', userDetails.HairColour)
            ),
            skip: true
          },
          {
            label: 'EYE_COLOR',
            value: instance.linkifyValue(
              EnumDictionary.formatEnum('EyeColour', userDetails.EyeColour)
            ),
            skip: true
          },
          {
            label: 'MARTIAL_STATUS',
            value: instance.linkifyValue(
              EnumDictionary.formatEnum('MaritalStatus', userDetails.MaritalStatus, '_')
            ),
            skip: true
          },
          {
            label: 'DEBTOR_NUMBER',
            value: instance.linkifyValue(userDetails.DebtorNumber),
            skip: true
          },
          {
            label: 'PERSONAL_OTHER',
            value: instance.linkifyValue(userDetails.PersonalOther),
            skip: true
          },
          {
            label: 'REFERAL_NOTES',
            value: instance.linkifyValue(userDetails.ReferalNotes),
            skip: true
          },
          {
            label: 'GENERAL_NOTES',
            value: instance.linkifyValue(userDetails.GeneralNotes),
            skip: true
          }
        ].filter(field =>
          field ? !(this.widgetSettings.fieldsToExclude || []).includes(field.label) : false
        )}
      </Section>
    );
  }
}

export default class AdditionalUserInfoSection extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <AdditionalUserInfoWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
