import moment from "moment/moment";
import settingsStore from "../stores/settings_store";

export const dateComparator = (date1: any, date2: any) => {
  let date1Number;
  let date2Number;
  const format = settingsStore.getValueByKey('DATE_FORMAT', 'string') || 'YYYY-MM-DD';

  if (!date1 && date2) {
    return -1;
  }
  if (!date2 && date1) {
    return 1;
  }

  if (typeof date1 === 'string' && typeof date2 === 'string') {
    date1Number = moment(date1, `${format} HH:mm:ss`);
    date2Number = moment(date2, `${format} HH:mm:ss`);
  } else {
    date1Number = moment(`${date1?.date} ${date1?.time}`, `${format} HH:mm:ss`);
    date2Number = moment(`${date2?.date} ${date2?.time}`, `${format} HH:mm:ss`);
  }

  if (date1Number.isBefore(date2Number)) {
    return -1;
  }
  if (date1Number.isAfter(date2Number)) {
    return 1;
  }

  return 0;
}