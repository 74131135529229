import alarmActiveStore from "../stores/alarms/alarm_active_store_actions";

export const getCommandsState = (userName: string, alarmId: string): string[] => (
    JSON.parse(localStorage.getItem(`${userName}-${alarmId}-usedCommands`) || '[]')
);

export const setCommandsState = (userName: string, alarmId: string, usedCommand: string): void => {
    const alarmDetails = alarmActiveStore.getAlarmDetails(alarmId);
    // If the alarm is active, save the command state
    if (alarmDetails?.DynamicDetails?.Active) {
        let commandState = getCommandsState(userName, alarmId);
        commandState.push(usedCommand);
        localStorage.setItem(`${userName}-${alarmId}-usedCommands`, JSON.stringify(commandState));
    }
}

export const resetCommandsState = (userName: string, alarmId: string): void => { 
    localStorage.removeItem(`${userName}-${alarmId}-usedCommands`);
}

export const isCommandUsed = (userName: string, alarmId: string, commandId: string): boolean => {
    let commandState = getCommandsState(userName, alarmId);
    return commandState.includes(commandId);
}

export const clearAllCommandsState = (): void => { 
    for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);

        if (key && key.includes("usedCommands")) {
            localStorage.removeItem(key);
            i--;
        }
    }
}