import React from 'react';
import localization from '../localization';

const constants = require('../../json/constants.json');

interface Props {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

const LanguageSelect = (props: Props) => {
  let defaultValue;
  const loginT = localization.useNSt(constants.NS_LOGIN);
  const options = [
    {
      text: loginT('LANGUAGE_EN'),
      value: constants.DEFAULT_LANGUAGE
    },
    {
      text: loginT('LANGUAGE_SV'),
      value: constants.LANGUAGE_SV_SE
    },
    {
      text: loginT('LANGUAGE_NB'),
      value: constants.LANGUAGE_NB_NO
    },
    {
      text: loginT('LANGUAGE_FI'),
      value: constants.LANGUAGE_FI
    }
  ];
  const language = localization.getLanguage();
  const selectOptions = options.map((option, idx) => {
    const { value, text } = option;
    if (language === value || language.indexOf(value) > -1) {
      defaultValue = value;
    }

    return (
      <option key={idx} value={value}>
        {text}
      </option>
    );
  });

  return (
    <select defaultValue={defaultValue} onChange={props.onChange}>
      {selectOptions}
    </select>
  );
};

export default LanguageSelect;
