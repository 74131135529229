import { formatDistance } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import React from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
class AlarmRepeatedWidget extends React.Component {
  render() {
    const { toggleType, toggleState, widget, repeated = [], fullscreenMode } = this.props;

    const hasContent = repeated && repeated.length ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'AlarmRepeatedSection'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        widget={widget}
        addSectionClassName="flex-0-0-auto"
        headerLocKey="ALARM_REPEATED"
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        fullscreenMode={fullscreenMode}
        headerLocOptions={{ ns: constants.NS_ALARM }}
      >
        <ul className="w-100p">
          {repeated.map((item, i) => {
            return (
              <li key={i}>
                {formatDistance(new Date(), new Date(item.LastUpdated), {
                  includeSeconds: true,
                  addSuffix: true,
                  locale: (window.__localeId__ || '').includes('en') ? enGB : sv
                })}
              </li>
            );
          })}
        </ul>
      </Section>
    );
  }
}

export default class AlarmRepeatedSection extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <AlarmRepeatedWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
