import { observable, action, computed } from 'mobx';

class UIStore {
  @observable
  snackbar = {
    show: false,
    message: ''
  };

  @computed
  get isSnackbarShow() {
    return this.snackbar.show;
  }

  @computed
  get snackbarMessage() {
    return this.snackbar.message;
  }

  @action
  hideSnackbar() {
    this.snackbar.show = false;
  }

  @action
  showSnackbar(msg = '') {
    this.snackbar.show = true;
    this.snackbar.message = msg;
  }
}

export default new UIStore();
