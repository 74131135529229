import React from 'react';

import CircularProgress from 'material-ui/CircularProgress';
import FlipMove from 'react-flip-move';

import CallsStore from '../stores/calls/calls_store';
import GroupsStore from '../stores/groups_store';
import CallItem from './call_item';

import localization from '../localization';
const constants = require('../../json/constants.json');

interface Props {
  callInProgress: boolean;
}

interface State {
  error: boolean;
  loading: boolean;
  isGroupsLoaded: boolean;
}

export default class CallList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      isGroupsLoaded: false
    };
  }

  componentDidMount() {
    CallsStore.addEventListener(this.onChange);
    GroupsStore.on(constants.RESPONDENT_GROUP_DETAILS_CHANGE, this.onGroupsChange);
  }

  componentWillUnmount() {
    CallsStore.removeEventListener(this.onChange);
    GroupsStore.off(constants.RESPONDENT_GROUP_DETAILS_CHANGE, this.onGroupsChange);
  }

  onGroupsChange = () => {
    this.forceUpdate();
  };

  onChange = () => {
    const { errors, loading } = CallsStore.getState();

    if (loading.get('fetch')) {
      this.setState({ error: false, loading: true });
      return;
    }

    if (errors.get('fetch')) {
      this.setState({ error: true, loading: false });
      return;
    }

    this.setState({ loading: false, error: false });
  };

  render() {
    const calls = CallsStore.getOnHoldCalls();

    if (this.state.loading && !calls.length) {
      return (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.error) {
      return <div style={{ textAlign: 'center' }}>{localization.t('ERROR_CALL_FETCH')}</div>;
    }

    if (!calls.length) {
      return null;
    }

    return (
      <div className={`${calls.length > 6 ? 'overflow' : ''} hold-calls-list`}>
        <FlipMove enterAnimation="elevator" leaveAnimation="elevator">
          {calls.map((call, idx) => (
            <CallItem key={call.CallSid} call={call} isFirst={idx === 0} />
          ))}
        </FlipMove>
      </div>
    );
  }
}
