import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColumnApi } from 'ag-grid-community';
import React from 'react';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import event_bus from '../event_bus';
import { PushDataModel } from '../interfaces/backend_model';
import localization from '../localization';
import planningViewStore from '../stores/alarms/planning_view_store_actions';
import SettingsStore from '../stores/settings_store';
import { dateComparator } from "../utilities/dateComparator";
import { dateGridFormatter } from '../utilities/dateGridFormatter';
import { isEqual } from '../utilities/isEqual';
import AgGridTooltip from './ag-grid-tooltip';
import IconDoor from './icon_door';
import PlanningButtonRenderer from './planning_button_renderer';

const constants = require('../../json/constants.json');
const PlanningView = () => {
  LicenseManager.setLicenseKey(SettingsStore.getValueByKey('AG_GRID_LICENSE'));
  const [planningViewData, setPlanningViewData] = React.useState(planningViewStore.getPlannedAlarms());
  const gridRef = React.useRef<HTMLDivElement | null>(null);
  const gridData = React.useRef({
    gridApi: null,
    gridColumnApi: null
  });

  const filtersLocalisation = {
    filterOoo: localization.t('PLANNING_VIEW_FILTER_INPUT'),
    contains: localization.t('PLANNING_VIEW_FILTER_CONTAINS'),
    notContains: localization.t('PLANNING_VIEW_FILTER_NOT_CONTAINS'),
    startsWith: localization.t('PLANNING_VIEW_FILTER_STARTS_WITH'),
    endsWith: localization.t('PLANNING_VIEW_FILTER_ENDS_WITH'),
    equals: localization.t('PLANNING_VIEW_FILTER_EQUALS'),
    notEqual: localization.t('PLANNING_VIEW_FILTER_NOT_EQUAL'),
    noRowsToShow: localization.t('PLANNING_VIEW_NO_ROWS_TO_SHOW'),
    empty: ''
  };

  React.useEffect(() => {
    event_bus.on(constants.WS_PUSH_ALARM_UPDATE, handlePushUpdate);
    planningViewStore.on(constants.PLANNED_ALARMS_LOADED, onChange);
    appDispatcher.handleServerAction({ type: constants.PLANNED_ALARMS_GET_ALL });

    return () => {
      event_bus.off(constants.WS_PUSH_ALARM_UPDATE, handlePushUpdate);
      planningViewStore.off(constants.PLANNED_ALARMS_LOADED, onChange);
    };
  }, []);

  const onChange = () => {
    const newPlanningViewData = planningViewStore.getPlannedAlarms();
    if (!isEqual(planningViewData, newPlanningViewData)) {
      setPlanningViewData(newPlanningViewData);
      let gridApi = gridData.current.gridApi as any;
      if (gridApi) {
        gridApi.setRowData(newPlanningViewData);
      }
    }
  };

  const handlePushUpdate = (e: Event, data?: PushDataModel) => {
    if (data && data.planningview && gridData.current.gridApi) {
      appDispatcher.handleServerAction({
        type: constants.PLANNED_ALARMS_GET_ONE,
        alarm_id: data.globalAlarmId
      });
    }
  };

  const onGridReady = (params: any) => {
    gridData.current.gridApi = params.api;
    gridData.current.gridColumnApi = params.columnApi;
    autoSizeAll(true);
    applyStoredColumns();
    applyStoredFilters();
  };

  const applyStoredColumns = React.useCallback(() => {
    const columnsState = JSON.parse(
      planningViewStore.getItemFromStorage('columnStates')!
    );
    if (columnsState !== null) {
      let gridColumnApi: ColumnApi = gridData.current.gridColumnApi!;
      gridColumnApi.applyColumnState({ state: columnsState, applyOrder: true });
    }
  }, []);

  const applyStoredFilters = () => {
    let filterStates: any = JSON.parse(planningViewStore.getItemFromStorage('filterStates')!);
    let gridApi = gridData.current.gridApi as any;
    if (filterStates && gridApi) {
      gridApi.setFilterModel(filterStates);
    }
  };

  const autoSizeAll = (skipHeader = false) => {
    let gridColumnApi = gridData.current.gridColumnApi as any;
    if (gridColumnApi) {
      let allColumnIds: number[] = [];
      gridColumnApi.getColumnState().forEach(function (column: any) {
        allColumnIds.push(column.colId);
      });
      gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  };

  const clearFilters = () => {
    let gridApi = gridData.current.gridApi as any;
    if (gridApi == null) return;

    gridApi.setFilterModel(null);
    addDataIntoCache('filterStates', gridApi.getFilterModel());

    // clear sorting on columns
    let gridColumnApi = gridData.current.gridColumnApi as any;
    if (gridColumnApi == null) return;

    gridColumnApi.getColumnState().forEach(function (column: any) {
      column.sort = null;
    });

    gridColumnApi.applyColumnState({ state: gridColumnApi.getColumnState() });
    addDataIntoCache('columnStates', gridColumnApi.getColumnState());
  };

  const renderTableActionSection = () => {
    return (
      <div className="table-action-section">
        <button className="button-small-blue" onClick={clearFilters}>
          {localization.t('PLANNING_VIEW_CLEAR_FILTERS')}
        </button>
      </div>
    );
  };

  const closePlanningView = () => {
    appHistory.push(constants.PATH_DASHBOARD);
  };

  const addDataIntoCache = (storageName: string, data: any) => {
    planningViewStore.setItemInStorage(storageName, JSON.stringify(data));
  };

  const onColumnEvent = (event: any) => {
    const allowedSources = ["autosizeColumns", "flex", "uiColumnDragged", "contextMenu"];
    if (allowedSources.includes(event.source) || (!event.source && event.type === "sortChanged")) {
      const columnState = event.columnApi.getColumnState();
      addDataIntoCache('columnStates', columnState);
    } else {
      applyStoredColumns();
    }
  };

  const onFilterEvent = (event: any) => {
    addDataIntoCache('filterStates', event.api.getFilterModel());
  };

  return (
    <div className="flex-1-1-auto form-list flex-col" ref={gridRef}>
      <div className="flex-row-between planning-view-table-header">
        <p className="bold">{localization.t('PLANNING_VIEW')}</p>
        <button type="button" className={'form-btn-transparent'} onClick={closePlanningView}>
          <IconDoor />
        </button>
      </div>

      <div className="ag-theme-alpine planning-view-table">
        {renderTableActionSection()}

        <AgGridReact
          modules={[MultiFilterModule, SetFilterModule, ClientSideRowModelModule]}
          localeText={filtersLocalisation}
          frameworkComponents={{
            buttonWrapperRenderer: PlanningButtonRenderer,
            customTooltip: AgGridTooltip
          }}
          defaultColDef={{
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
            tooltipComponent: 'customTooltip',
            lockVisible: true // prevent hiding of columns
          }}
          rowData={planningViewData as any}
          tooltipShowDelay={2000}
          onGridReady={onGridReady}
          suppressScrollOnNewData={true}
          suppressColumnVirtualisation={true}
          onSortChanged={onColumnEvent}
          onColumnResized={onColumnEvent}
          onColumnMoved={onColumnEvent}
          onPinnedRowDataChanged={onColumnEvent}
          onFilterChanged={onFilterEvent}
          onFilterModified={onFilterEvent}
          onRowDataChanged={applyStoredFilters}
        >
          <AgGridColumn
            field="AlarmText"
            tooltipField="AlarmText"
            filter="agMultiColumnFilter"
            headerName={localization.t('PLANNING_VIEW_ALARM_CODE')}
            headerTooltip={localization.t('PLANNING_VIEW_ALARM_CODE')}
            tooltipComponentParams={{
              fieldToDisplay: 'AlarmText',
              headerLocKey: 'PLANNING_VIEW_ALARM_CODE'
            }}
          />

          <AgGridColumn
            field="ServerAlarmTime"
            tooltipField="ServerAlarmTime"
            filter="agTextColumnFilter"
            headerName={localization.t('PLANNING_VIEW_CREATED_TIME')}
            headerTooltip={localization.t('PLANNING_VIEW_CREATED_TIME')}
            valueGetter={params =>
              params.data.ServerAlarmTime && dateGridFormatter(params.data.ServerAlarmTime)
            }
            tooltipComponentParams={{
              useValueFormatter: true,
              valueFormatter: dateGridFormatter,
              fieldToDisplay: 'ServerAlarmTime',
              headerLocKey: 'PLANNING_VIEW_CREATED_TIME'
            }}
            comparator={dateComparator}
          />
          <AgGridColumn
            field="PlannedTime"
            tooltipField="PlannedTime"
            filter="agTextColumnFilter"
            headerName={localization.t('PLANNING_VIEW_PLANNED_TIME')}
            headerTooltip={localization.t('PLANNING_VIEW_PLANNED_TIME')}
            valueGetter={params =>
              params.data.PlannedTime && dateGridFormatter(params.data.PlannedTime)
            }
            tooltipComponentParams={{
              useValueFormatter: true,
              valueFormatter: dateGridFormatter,
              fieldToDisplay: 'PlannedTime',
              headerLocKey: 'PLANNING_VIEW_PLANNED_TIME'
            }}
            comparator={dateComparator}
          />
          <AgGridColumn
            field="Name"
            tooltipField="Name"
            filter="agMultiColumnFilter"
            headerName={localization.t('PLANNING_VIEW_NAME')}
            headerTooltip={localization.t('PLANNING_VIEW_NAME')}
            tooltipComponentParams={{
              fieldToDisplay: 'Name',
              headerLocKey: 'PLANNING_VIEW_NAME'
            }}
          />
          <AgGridColumn
            field="Comment"
            tooltipField="Comment"
            filter="agTextColumnFilter"
            headerName={localization.t('PLANNING_VIEW_COMMENT')}
            headerTooltip={localization.t('PLANNING_VIEW_COMMENT')}
            tooltipComponentParams={{
              fieldToDisplay: 'Comment',
              headerLocKey: 'PLANNING_VIEW_COMMENT'
            }}
          />
          <AgGridColumn
            field="Street"
            filter="agMultiColumnFilter"
            tooltipField="Street"
            headerName={localization.t('PLANNING_VIEW_STREET')}
            headerTooltip={localization.t('PLANNING_VIEW_STREET')}
            tooltipComponentParams={{
              fieldToDisplay: 'Street',
              headerLocKey: 'PLANNING_VIEW_STREET'
            }}
          />
          <AgGridColumn
            filter="agMultiColumnFilter"
            field="PostalCode"
            tooltipField="PostalCode"
            headerName={localization.t('PLANNING_VIEW_POSTAL_CODE')}
            headerTooltip={localization.t('PLANNING_VIEW_POSTAL_CODE')}
            tooltipComponentParams={{
              fieldToDisplay: 'PostalCode',
              headerLocKey: 'PLANNING_VIEW_POSTAL_CODE'
            }}
          />
          <AgGridColumn
            field="City"
            tooltipField="City"
            filter="agMultiColumnFilter"
            headerName={localization.t('PLANNING_VIEW_CITY')}
            headerTooltip={localization.t('PLANNING_VIEW_CITY')}
            tooltipComponentParams={{
              fieldToDisplay: 'City',
              headerLocKey: 'PLANNING_VIEW_CITY'
            }}
          />
          <AgGridColumn
            field="RespondentGroupName"
            filter="agMultiColumnFilter"
            tooltipField="RespondentGroupName"
            headerName={localization.t('PLANNING_VIEW_RESPONSE_GROUP')}
            headerTooltip={localization.t('PLANNING_VIEW_RESPONSE_GROUP')}
            tooltipComponentParams={{
              fieldToDisplay: 'RespondentGroupName',
              headerLocKey: 'PLANNING_VIEW_RESPONSE_GROUP'
            }}
          />
          <AgGridColumn
            filter="agMultiColumnFilter"
            field="Organization"
            tooltipField="Organization"
            headerName={localization.t('PLANNING_VIEW_ORGANIZATION')}
            headerTooltip={localization.t('PLANNING_VIEW_ORGANIZATION')}
            tooltipComponentParams={{
              fieldToDisplay: 'Organization',
              headerLocKey: 'PLANNING_VIEW_ORGANIZATION'
            }}
          />
          <AgGridColumn
            filter="agMultiColumnFilter"
            field="TaskType"
            tooltipField="TaskType"
            headerName={localization.t('PLANNING_VIEW_PRODUCT')}
            headerTooltip={localization.t('PLANNING_VIEW_PRODUCT')}
            tooltipComponentParams={{
              fieldToDisplay: 'TaskType',
              headerLocKey: 'PLANNING_VIEW_PRODUCT'
            }}
          />
          <AgGridColumn
            filter="agMultiColumnFilter"
            field="RespondentName"
            tooltipField="RespondentName"
            headerName={localization.t('PLANNING_VIEW_RESPONDENT')}
            headerTooltip={localization.t('PLANNING_VIEW_RESPONDENT')}
            tooltipComponentParams={{
              fieldToDisplay: 'RespondentName',
              headerLocKey: 'PLANNING_VIEW_RESPONDENT'
            }}
          />
          <AgGridColumn
            field="TextMessage"
            tooltipField="TextMessage"
            filter="agTextColumnFilter"
            headerName={localization.t('PLANNING_VIEW_ALARM_TEXT')}
            headerTooltip={localization.t('PLANNING_VIEW_ALARM_TEXT')}
            tooltipComponentParams={{
              fieldToDisplay: 'TextMessage',
              headerLocKey: 'PLANNING_VIEW_ALARM_TEXT'
            }}
          />
          <AgGridColumn
            sortable={false}
            filter={false}
            cellRenderer="buttonWrapperRenderer"
            cellClass={['planning-extension-cell']}
            pinned="right"
            suppressMovable
            maxWidth={100}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

export default React.memo(PlanningView);
