import i18next from "i18next";
import Checkbox from 'material-ui/Checkbox';
import { inject } from 'mobx-react';
import React from 'react';
import constants from '../../../../json/constants.json';
import { WidgetErrorBoundary } from '../../../components/errorBoundaries/widgetErrorBoundary';
import Section from '../../../components/section';
import dateTime from '../../../date_time';
import { evalToggleState, hideWidget } from '../../../enums/VisibilityState';
import { LogModel, Widget } from "../../../interfaces/backend_model";
import { filterWidgetSettings } from '../../../utilities/filterWidgetSettingsHelper';
import { LogTableWrapper } from "./LogTableWrapper";

interface Props {
  settings: any;
  widget: Widget;
  toggleType: string;
  toggleState: string;
  fullscreenMode: boolean;
  readonly?: boolean;
  alarmT: (key: string | string[], options?: i18next.TranslationOptions<object>) => any;
  logs: LogModel[];
  filterType: string;
  canTake: boolean;
  storage: any;
}

export interface LogsHeaders {
  key: string;
  content: string;
  log_format?: string;
  date_and_time?: boolean;
}

const allowed = [
  [0, 0],
  [2, 0],
  [2, 1],
  [2, 2],
  [2, 3],
  [2, 4],
  [3, 0],
  [6, 0],
  [6, 1],
  [6, 2],
  [6, 3],
  [6, 4],
  [6, 5],
  [6, 6],
  [6, 7],
  [7, 5],
  [7, 9],
  [7, 10],
  [7, 11]
];

const handledSettings = ['compactView', 'ascendingOrder'];

function filterForCompactView(log: LogModel) {
  let shouldInclude = false;

  allowed.forEach(el => {
    if (el[0] === log.PredefinedId && el[1] === log.LogEventCode) {
      shouldInclude = true;
    }
  });

  return shouldInclude;
}

@inject('storage')
class ResourcesWidget extends React.Component<Props> {
  state = {
    isExtendedLogsEnabled: this.props.storage.getItem('isExtendedLogsEnabled')
  };
  widgetSettings;

  constructor(props: Props) {
    super(props);

    this.widgetSettings = filterWidgetSettings(
      this.props.settings,
      handledSettings,
      this.props.widget ? this.props.widget.widgetName : 'LogsSection'
    );
  }

  onCheck = () => {
    const newState = !this.state.isExtendedLogsEnabled;

    this.props.storage.setItem('isExtendedLogsEnabled', newState);
    this.setState({ isExtendedLogsEnabled: newState });
  };

  groupLogsByDay(logs: LogModel[]): {
    [key: string]: {
      date: string;
      logs: LogModel[];
    }[]
  } {
    const groupedLogs: { [key: string]: any[] } = {};

    logs.forEach(log => {
      const dayTime = dateTime.toLocaleDateAndTime(log.Created);

      if (!groupedLogs[dayTime.date]) {
        groupedLogs[dayTime.date] = [log];
      } else {
        groupedLogs[dayTime.date].push(log);
      }
    });


    // the default order is descending, so we reverse the array
    // but if the widget is set to ascending order, or the filterType is goteborg, we don't reverse the array
    if (
      this.props.filterType === 'goteborg' ||
      this.props.filterType === 'goteborg_v2' ||
      this.widgetSettings.ascendingOrder) {
      return groupedLogs;
    }

    const sortedKeys = Object.keys(groupedLogs).sort((a, b) => b.localeCompare(a));
    const sortedLogs: { [key: string]: any[] } = {};

    sortedKeys.forEach(key => {
      sortedLogs[key] = groupedLogs[key].reverse();
    });

    return sortedLogs;
  }

  render() {
    const { logs, alarmT, toggleType, toggleState, canTake, widget, fullscreenMode, readonly } = this.props;
    let filteredLogs = logs.slice();
    let checkbox;
    let headers = [
      {
        key: 'Created',
        time_format: true,
        content: alarmT('TIME')
      },
      {
        key: 'LogEventCode',
        log_format: 'formatLogText',
        content: alarmT('ACTION')
      }
    ] as LogsHeaders[];

    const headerFrom: LogsHeaders = {
      key: 'RespondentName',
      content: alarmT('FROM')
    };

    if (this.props.filterType === 'goteborg' || this.props.filterType === 'goteborg_v2' || this.widgetSettings.compactView) {
      checkbox = (
        <div className="flex-0-0-auto flex-col-around">
          <Checkbox
            label={'Extended'}
            labelStyle={{ fontWeight: 500, marginRight: '20px' }}
            iconStyle={{ marginRight: '5px' }}
            disabled={readonly || canTake}
            checked={this.state.isExtendedLogsEnabled}
            onCheck={this.onCheck}
          />
        </div>
      );
    }

    if (!this.state.isExtendedLogsEnabled) {
      filteredLogs = logs.filter(filterForCompactView);
    }
    const reducedLogs = this.groupLogsByDay(filteredLogs) || [];

    headers.push(headerFrom);

    const hasContent = filteredLogs && filteredLogs.length ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'LogsSection'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        widget={widget}
        addSectionClassName="flex-0-0-auto"
        headerLocKey="LOGS"
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        fullscreenMode={fullscreenMode}
        headerLocOptions={{ ns: constants.NS_ALARM }}
        checkbox={checkbox}
      >
        <div className="w-100p">
          {Object.keys(reducedLogs).map((key, index) => (
            <LogTableWrapper
              key={index}
              logData={{
                date: key,
                logs: reducedLogs[key] as any
              }}
              headers={headers}
              filterType={this.props.filterType}
            />
          ))}
        </div>
      </Section>
    );
  }
}

export default class ResourcesSection extends React.Component<Props> {
  render() {
    return (
      <WidgetErrorBoundary>
        <ResourcesWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
