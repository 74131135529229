import createReactClass from 'create-react-class';
import React from 'react';
import Utils from '../../utils';

export default createReactClass({
  stopClick(e) {
    e.stopPropagation();
  },

  componentDidMount() {
    this.RInput.select();
    this.searchFocus();
  },

  // Shortcuts
  handleKeyDown(e) {
    let keyCode = e.keyCode,
      len = this.props.filtered_items.length,
      { highlighted } = this.props,
      next;

    if (keyCode === 38 || keyCode === 40) {
      switch (e.keyCode) {
        case 38: // up
          next = highlighted - 1;
          if (next < 0) {
            highlighted = len;
          } else if (next > len) {
            highlighted = len;
          } else {
            highlighted = next;
          }
          break;
        case 40: // down
          next = highlighted + 1;
          if (next < 0) {
            highlighted = 0;
          } else if (next > len) {
            highlighted = 0;
          } else {
            highlighted = next;
          }
          break;
      }

      this.props.onHighlightChange(highlighted);
    } else if (keyCode === 27) {
      this.props.handleEsc();
    }
  },

  searchFocus() {
    if (this.RInput) {
      this.RInput.focus();
    }
  },

  handleLocalSubmit(e) {
    const { highlighted } = this.props;
    let item;

    if (highlighted) {
      item = this.props.filtered_items[highlighted - 1];
    }

    this.props.handleSubmit(e, item);
  },

  handleLocalChange(e) {
    this.props.handleInputChange(e);
  },

  handleClickSelect(value) {
    return () => this.props.handleClickSelect(value);
  },

  render() {
    const {
      filtered_items,
      opened_top,
      opened_left,
      value,
      handleInputChange,
      highlighted,
      free
    } = this.props;

    if (Utils.is_finite(opened_top) && Utils.is_finite(opened_left)) {
      return (
        <div
          className="p-abs flex-col filter-window"
          style={{ left: opened_left + 'px', top: opened_top + 'px' }}
          onClick={this.stopClick}
        >
          <form className="p-025em" onSubmit={this.handleLocalSubmit} autoComplete="off">
            <input
              name="input"
              type="text"
              value={value}
              autoComplete="off"
              onKeyDown={this.handleKeyDown}
              ref={element => (this.RInput = element)}
              onChange={this.handleLocalChange}
            />
          </form>
          {filtered_items.map((item, index) => {
            const className = 'item-border' + (highlighted - 1 === index ? ' active' : '');
            return (
              <div className={className} key={item} onClick={this.handleClickSelect(item)}>
                <button className="button-small-secondary t-a-l w-100p" onClick={handleInputChange}>
                  {item}
                </button>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  }
});
