import Logger from 'js-logger';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import LinearProgress from 'material-ui/LinearProgress';
import TextField from 'material-ui/TextField';
import { action, observable } from 'mobx';
import { Observer } from 'mobx-react';
import { FULFILLED, PENDING, REJECTED } from 'mobx-utils';
import React, { Component } from 'react';
import constants from '../../../json/constants.json';
import endpoints from '../../../json/endpoints.json';
import ajax from '../../ajax';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
import Section from '../../components/section';
import dateTime from '../../date_time';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import localization from '../../localization';
import { KeyboardShortcuts } from './KeyboardShortcuts';

function NotesFetchingError({ alarmT }) {
  return <div>{alarmT('ERORR_OCCURRED_DURING_FETCHING_NOTES')}</div>;
}

function NotesFetchingSpinner({ status }) {
  return (
    <Observer>
      {() => (
        <div style={{ visibility: status.get() === PENDING ? 'visible' : 'hidden', width: '100%' }}>
          <LinearProgress mode="indeterminate" />
        </div>
      )}
    </Observer>
  );
}

function NoteItem({ note }) {
  const { date, time } = dateTime.toLocaleDateAndTime(note.Created);

  return (
    <div style={{ width: '100%' }}>
      <h4>
        {`${date} ${time}`} – {note.AdminName}
      </h4>
      <p>{note.Note}</p>
    </div>
  );
}

function NotesActions({ alarmT, handleAddClick, handleFetchMoreClick, status, canLoadMore }) {
  return (
    <Observer>
      {() => (
        <div className="flex-row-wrap p-05em">
          <button
            className="button-small-blue"
            disabled={status.get() === PENDING}
            onClick={handleAddClick}
          >
            {alarmT('ADD')}
          </button>
          {canLoadMore.get() && (
            <button
              className="button-small-blue"
              style={{ marginLeft: '15px' }}
              disabled={status.get() === PENDING}
              onClick={handleFetchMoreClick}
            >
              {alarmT('FETCH_MORE')}
            </button>
          )}
        </div>
      )}
    </Observer>
  );
}

class NotesList extends Component {
  render() {
    const { status, alarmT, notes } = this.props;

    return (
      <Observer>
        {() => {
          if (status.get() === REJECTED) {
            return <NotesFetchingError alarmT={alarmT} />;
          }

          return (
            <div
              className="flex-row-wrap p-05em"
              style={{ maxHeight: '200px', overflowY: 'auto' }}
              ref={node => this.props.innerRef(node)}
            >
              {notes.map(note => (
                <NoteItem key={note.UID} note={note} />
              ))}
              <NotesFetchingSpinner status={status} />
            </div>
          );
        }}
      </Observer>
    );
  }
}

class NotesWidget extends Component {
  constructor(props, context) {
    super(props, context);
    this.pageSize = 5;
    this.page = observable(1);
    this.notes = observable([]);
    this.addNewNoteText = observable.box('');
    this.notesStatus = observable.box(PENDING);
    this.canLoadMore = observable.box(true);

    this.isAddNewMode = observable(false);
    this.isAddingNewInProgress = observable(false);
    this.isAddingNewFailed = observable(false);

    this.notesContainerRef = undefined;
  }

  componentDidMount() {
    this.fetchNotes();
  }

  saveNotesRef = node => (this.notesContainerRef = node);

  fetchNotes = (config = {}) => {
    const { addToTheTop = false, page = this.page } = config;
    const { userId, alarmId, noteType } = this.props;
    const params = {
      noteType: noteType || '0',
      user_id: userId,
      alarmId,
      page,
      pageSize: this.pageSize
    };
    let endpoint = endpoints.FETCH_NOTES;

    switch (params.noteType) {
      case '0':
        endpoint = endpoints.FETCH_MEDICAL_NOTES;
        break;
      case '1':
        endpoint = endpoints.FETCH_TECHNICAL_NOTES;
        break;
    }
    this.notesStatus.set(PENDING);

    ajax
      .getByDescWithDataPromise(endpoint, params)
      .then(
        action(notes => {
          this.notesStatus.set(FULFILLED);

          notes.forEach(note => {
            const existingNote = this.notes.find(existingNote => existingNote.UID === note.UID);

            if (!existingNote) {
              addToTheTop ? this.notes.unshift(note) : this.notes.push(note);
            }
          });

          if (!notes.length) {
            this.canLoadMore.set(false);
          }
        })
      )
      .catch(error => {
        Logger.error(error);
        this.notesStatus.set(REJECTED);
      });
  };

  fetchMore = () => {
    this.page++;
    this.fetchNotes();
  };

  enableAddNewMode = () => this.isAddNewMode.set(true);
  disableAddNewMode = () => {
    this.isAddNewMode.set(false);
    this.addNewNoteText.set('');
  };

  addNewNote = () => {
    const { userId, alarmId, noteType } = this.props;
    const payload = {
      NoteType: noteType || '0',
      AlarmId: alarmId,
      Note: this.addNewNoteText.get(),
      user_id: userId
    };

    this.isAddingNewInProgress.set(true);
    this.isAddingNewFailed.set(false);
    let endpoint = endpoints.ADD_NOTE;

    switch (payload.noteType) {
      case '0':
        endpoint = endpoints.ADD_MEDICAL_NOTE;
        break;
      case '1':
        endpoint = endpoints.ADD_TECHNICAL_NOTE;
        break;
    }

    ajax
      .postByDescPromise(endpoint, payload)
      // hide modal
      .then(this.disableAddNewMode)
      // fetch new notes and add new one to the top
      .then(() => this.fetchNotes({ addToTheTop: true, page: 1 }))
      // scroll container to the top
      .then(() => (this.notesContainerRef.scrollTop = 0))
      // hide spinner
      .then(() => this.isAddingNewInProgress.set(false))
      // handle error
      .catch(error => {
        Logger.error(error.err);

        this.isAddingNewInProgress.set(false);
        this.isAddingNewFailed.set(true);
        this.disableAddNewMode();
      });
  };

  render() {
    const { alarmT, toggleType, toggleState, widget, title, fullscreenMode } = this.props;

    const evaluatedToggleState = evalToggleState(
      !!this.notes.length,
      toggleState,
      widget ? widget.widgetName : 'Notes',
      true
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        widget={widget}
        addSectionClassName="flex-0-0-auto"
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        fullscreenMode={fullscreenMode}
        headerLocKey={title ? title : 'NOTES_SECTION_TITLE'}
        headerLocOptions={{ ns: constants.NS_ALARM }}
      >
        <div className="w-100p">
          <NotesList
            innerRef={this.saveNotesRef}
            status={this.notesStatus}
            alarmT={alarmT}
            notes={this.notes}
          />
        </div>
        <div className="w-100p">
          <NotesActions
            handleAddClick={this.enableAddNewMode}
            handleFetchMoreClick={this.fetchMore}
            alarmT={alarmT}
            canLoadMore={this.canLoadMore}
            status={this.notesStatus}
          />
        </div>
        <Observer>
          {() => {
            const isAddMode = this.isAddNewMode.get();
            const isAddingNewInProgress = this.isAddingNewInProgress.get();
            const addNewNoteText = this.addNewNoteText.get();

            const dialogActions = [
              <FlatButton
                label={alarmT('CANCEL')}
                secondary={true}
                onClick={this.disableAddNewMode}
              />,
              <FlatButton
                label={alarmT('SAVE')}
                disabled={isAddingNewInProgress || !this.addNewNoteText.get()}
                primary={true}
                onClick={this.addNewNote}
              />
            ];

            return (
              <KeyboardShortcuts
                isActive={isAddMode}
                onEvent={event => {
                  if (event.code === 'Escape') {
                    this.disableAddNewMode();
                  }
                }}
              >
                <Dialog
                  open={this.isAddNewMode.get()}
                  title={alarmT('NEW_NODE_DIALOG_TITLE')}
                  actions={dialogActions}
                >
                  <Observer>
                    {() => (
                      <TextField
                        disabled={isAddingNewInProgress}
                        autoFocus
                        fullWidth={true}
                        hintText={alarmT('NOTES_ADD_HINT')}
                        multiLine={true}
                        rows={1}
                        rowsMax={15}
                        value={this.addNewNoteText.get()}
                        onChange={({ currentTarget }) => {
                          const writingNewNoteText = currentTarget.value;

                          if (writingNewNoteText.length <= 800) {
                            this.addNewNoteText.set(writingNewNoteText);
                          } else {
                            this.isAddingNewInProgress.set(false);
                          }
                        }}
                        autoComplete="off"
                        floatingLabelText={`${alarmT('NOTES_ADD_LABEL')} / ${800 -
                          addNewNoteText.length} ${localization.t('CALL_NOTES_CHARACTERS_LEFT')}`}
                      />
                    )}
                  </Observer>
                  {isAddingNewInProgress && <LinearProgress mode="indeterminate" />}
                </Dialog>
              </KeyboardShortcuts>
            );
          }}
        </Observer>
        <Observer>
          {() => {
            const isAddingNewFailed = this.isAddingNewFailed.get();

            const dialogActions = [
              <FlatButton
                label={alarmT('OK')}
                primary={true}
                onClick={() => this.isAddingNewFailed.set(false)}
              />
            ];

            return (
              <Dialog
                open={isAddingNewFailed}
                title={alarmT('ADD_NEW_NOTE_DIALOG_TITLE_FAILED')}
                actions={dialogActions}
              >
                {alarmT('ERORR_OCCURRED_DURING_ADDING_NEW_NOTE')}
              </Dialog>
            );
          }}
        </Observer>
      </Section>
    );
  }
}

export class Notes extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <NotesWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
