import React from 'react';

export default class IconBan extends React.Component {
  render() {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="black"/>
        <path d="M11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645Z" fill="black"/>
      </svg>
    );
  }
}
