import React from 'react';

export default class IconLogout extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-logout"
        x="0px"
        y="0px"
        width="70px"
        height="70px"
        viewBox="0 0 70 70"
      >
        <path
          d={`M60.5,69h-51C4.805,69,1,65.194,1,60.5V46.9h7.65V61.35h52.7V8.65
          H8.65V23.1H1V9.5C1,4.806,4.805,1,9.5,1h51C65.194,1,69,4.806,69,9.5v51C69,65.194,65.194,69,60.5,69z M28.794,21.4l4.675-4.675
          L51.744,35L33.469,53.275L28.794,48.6l10.2-10.199H1v-6.8h37.994L28.794,21.4z`}
        />
      </svg>
    );
  }
}
