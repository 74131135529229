import React from 'react';
import IconClock from '../../../js/components/icon_clock';
import date_time from '../../../js/date_time';
import { LinkModel, Widget } from '../../../js/interfaces/backend_model';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
const constants = require('../../../json/constants.json');

interface LinksSectionProps {
  links: LinkModel[];
  alarmT: Function;
  toggleType: string;
  toggleState: string;
  widget: Widget;
  fullscreenMode?: boolean;
}

function LinksWidget(props: LinksSectionProps) {
  const { links, alarmT, toggleType, toggleState, widget, fullscreenMode } = props;
  const renderRows = (link: LinkModel) => {
    const dateAndTime = date_time.toLocaleDateAndTime(link.LastUpdated);
    return (
      <tr key={link.LinkUrl}>
        <td>
          <a href={link.LinkUrl} target="_blank" style={{ color: 'blue' }}>
            {link.LinkDescription || link.LinkUrl}
          </a>
        </td>
        <td>
          <div className="flex-row-wrap date-and-time flex-1-1-auto p-05em">
            <div className="flex-col-around">
              <IconClock />
            </div>
            <div className="flex-col-around flex-0-0-auto date p-l-05em" style={{ lineHeight: 1 }}>
              {dateAndTime.date}
            </div>
            <div className="flex-col-around time flex-0-0-auto p-l-05em" style={{ lineHeight: 1 }}>
              {dateAndTime.time}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const hasContent = (links && links.length) ? true : false;
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "LinksSection");
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      headerLocKey="LINKS"
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocOptions={{ ns: constants.NS_ALARM }}
    >
      <div className="w-100p">
        <div>
          <table className="form-table w-100p">
            <thead className="grid-header">
              <tr>
                <th>
                  <div className="flex-row">
                    <div className="flex-col-around">{alarmT('Link')}</div>
                  </div>
                </th>
                <th>
                  <div className="flex-row">
                    <div className="flex-col-around">{alarmT('Timestamp')}</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="grid-body">{links.map(renderRows)}</tbody>
          </table>
        </div>
      </div>
    </Section>
  );
}

export default function LinksSection (props: LinksSectionProps) {
  return (
    <WidgetErrorBoundary>
      <LinksWidget {...props} />
    </WidgetErrorBoundary>
  );
};
