import * as Logger from 'js-logger';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import React from 'react';
import ajax from '../ajax';
import { useEnterKey, useEscapeKey } from '../hooks';
import localization from '../localization';
import CallsStore from '../stores/calls/calls_store';
import TwilioStore from '../stores/twillio_store';
import usersStore from '../stores/users/users_store_actions';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

interface CallNotesProps {
  params: { alarm_id: string; template_type: string };
}

interface IData {
  callSid: string;
  alarmId: string;
}

export default function CallNotes(props: CallNotesProps) {
  const [error, setError] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState<IData>();

  useEscapeKey(() => {
    setIsOpen(false);
    setMessage('');
    return;
  });

  useEnterKey(() => sendResource());

  const onTwilioStatusUpdate = (payload: { type: string }) => {
    switch (payload.type) {
      case constants.SHOW_CALL_NOTES_MODAL:
        const call = CallsStore.calls.find(
          call => call.CallSid === TwilioStore.getCallDetails().sid
        );
        Logger.info('CALL_NOTES:', call);
        if (call && call.CallSid && call.GlobalAlarmId) {
          const userDetails = usersStore.userDetails;
          let name = '';
          Object.keys(userDetails)
            .map(key => userDetails[key])
            .forEach(user => {
              const foundContact =
                user && user.Contacts
                  ? user.Contacts.find(
                    (u: any) =>
                      u.Telephone1.indexOf(call.CallTo) >= 0 ||
                      u.Telephone2.indexOf(call.CallTo) >= 0 ||
                      u.Telephone3.indexOf(call.CallTo) >= 0 ||
                      u.Telephone1.indexOf(call.CallFrom) >= 0 ||
                      u.Telephone2.indexOf(call.CallFrom) >= 0 ||
                      u.Telephone3.indexOf(call.CallFrom) >= 0
                  )
                  : null;
              const foundAddress =
                user && user.Addresses
                  ? user.Addresses.find(
                    (u: any) =>
                      u.Telephone1.indexOf(call.CallTo) >= 0 ||
                      u.Telephone2.indexOf(call.CallTo) >= 0 ||
                      u.Telephone3.indexOf(call.CallTo) >= 0 ||
                      u.Telephone1.indexOf(call.CallFrom) >= 0 ||
                      u.Telephone2.indexOf(call.CallFrom) >= 0 ||
                      u.Telephone3.indexOf(call.CallFrom) >= 0
                  )
                  : null;
              if (foundContact) {
                name = foundContact.Name;
              } else if (
                foundAddress ||
                (user && user.TelephoneNumber && user.TelephoneNumber.indexOf(call.CallTo) >= 0) ||
                (user && user.TelephoneNumber && user.TelephoneNumber.indexOf(call.CallFrom) >= 0)
              ) {
                name = user.Name;
              } else if (call.CallFrom.indexOf('TW') === -1) {
                name = call.CallFrom;
              } else {
                name = call.CallTo;
              }
            });
          name = name.replace('+', '');
          Logger.info('CALL_NOTES Name or CallSid:', name);
          setData({ callSid: name, alarmId: call.GlobalAlarmId });
          setIsOpen(true);
          return;
        }
        break;
    }
  };

  React.useEffect(
    () => {
      TwilioStore.addEventListener(onTwilioStatusUpdate);
      return () => {
        TwilioStore.removeEventListener(onTwilioStatusUpdate);
      };
    },
    [onTwilioStatusUpdate]
  );

  const sendResource = () => {
    if (message && message.length <= 900 && data && data.alarmId && data.callSid) {
      ajax
        .postByDescPromise(endpoints.CALL_NOTE, {
          item: message,
          alarm_id: data.alarmId,
          sid: data.callSid
        })
        .then(() => {
          setIsOpen(false);
          setError('');
          setData(undefined);
          setMessage('');
        })
        .catch(e => {
          setError(localization.t('CALL_NOTES_ERROR'));
          Logger.error(e);
        });
    } else {
      setError(localization.t('CALL_NOTES_INPUT_VALIDATION_ERROR'));
    }
  };

  const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(false);
    setMessage('');
  };

  const onSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendResource();
  };

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const newValue = e.currentTarget.value;

    if (newValue.length <= 900) {
      setMessage(newValue);
      setError('');
    } else {
      setError(localization.t('CALL_NOTES_INPUT_VALIDATION_ERROR'));
    }
  };

  return (
    <div>
      <Dialog
        title={localization.t('CALL_NOTES')}
        actions={[
          <FlatButton
            label={localization.t('OK')}
            disabled={message.length === 0}
            primary={true}
            onClick={() => sendResource()}
          />,
          <FlatButton label={localization.t('CLOSE')} primary={false} onClick={onClose} />
        ]}
        modal={true}
        open={isOpen}
      >
        <form onSubmit={message.length === 0 ? undefined : onSubmit}>
          <TextField
            multiLine={true}
            rows={1}
            autoFocus
            style={{ width: '600px' }}
            value={message}
            required={true}
            onChange={handleChange}
            floatingLabelText={`${localization.t('CALL_NOTES_INPUT_LABEL')} (${900 -
              message.length} ${localization.t('CALL_NOTES_CHARACTERS_LEFT')})`}
            errorText={error}
            autoComplete="off"
          />
        </form>
      </Dialog>
    </div>
  );
}
