import { PasswordComplexity } from '../components/forcePassResetModal';

export const validatePassword = (
  inputValue: string,
  passwordConstraints: PasswordComplexity
): string|boolean => {
  const findNumbersRegex = /\d+/g;
  const findLowercaseRegex = /[a-z]+/g;
  const findUppercaseRegex = /[A-Z]+/g;

  const countNumbers = inputValue.match(findNumbersRegex)
    ? inputValue.match(findNumbersRegex)?.join("").length!
    : 0;

  const countLowercase = inputValue.match(findLowercaseRegex)
    ? inputValue.match(findLowercaseRegex)?.join("").length!
    : 0;

  const countUppercase = inputValue.match(findUppercaseRegex)
    ? inputValue.match(findUppercaseRegex)?.join("").length!
    : 0;

  if (inputValue.length < passwordConstraints.MinLength) return "MinLength";
  if (countNumbers < passwordConstraints.MinNumbers) return "MinNumbers";
  if (countLowercase < passwordConstraints.MinLowerCase) return "MinLowerCase";
  if (countUppercase < passwordConstraints.MinUpperCase) return "MinUpperCase";

  const forbiddenChars: string[] =
    passwordConstraints.ForbiddenChars.toLowerCase().split("");
  if (forbiddenChars.some((char) => inputValue.toLowerCase().includes(char)))
    return "ForbiddenChars";

  return false;
};
