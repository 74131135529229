import React from 'react';
import endpoints from '../../../json/endpoints.json';
import ajax from '../../ajax';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import ResourcesSection from './ResourcesSection';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
class ResourcesSectionDoronorwayWidget extends React.PureComponent {
  state = {
    resources: []
  };

  componentDidMount() {
    this.getResourcesWorkflow();
  }

  componentWillUnmount() {
    // clear resource state when unmounting the template (i.e. when switching to another alarm)
    this.state.resources = undefined;
  }

  getResourcesWorkflow = () => {
    const alarm_id = this.props.alarmId;
    this.setState({ pendingGetResources: true, resources: [] });
    ajax.getByDescWithData(true, endpoints.RESOURCES, { alarm_id }, (err, xhr, data) => {
      if (err) {
        this.setState({
          getResourcesError: err,
          pendingGetResources: null
        });
      } else {
        this.setState({
          getResourcesError: err,
          pendingGetResources: null,
          resources: data
        });
      }
    });
  };

  render() {
    const {
      toggleType,
      toggleState,
      instance,
      alarmT,
      commonT,
      alarmId,
      readonly,
      widget
    } = this.props;

    const resources = this.state.resources
      ? this.state.resources.filter(resource => resource.ContactId < 0)
      : [];

    const hasContent = resources && resources.length ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'ResourcesSectionDoronorway'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <ResourcesSection
        widget={widget}
        instance={instance}
        alarmT={alarmT}
        commonT={commonT}
        alarmId={alarmId}
        readonly={readonly}
        resources={resources}
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
      />
    );
  }
}

export default class ResourcesSectionDoronorway extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <ResourcesSectionDoronorwayWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
