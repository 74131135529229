import * as Logger from 'js-logger';
import { holdCurrentCall } from '../actions/twilio';
import ajax from '../ajax';
import appHistory from '../app_history';
import authentication from '../authentication';
import websocket from '../websocket';
import { logoutWhenReferrer } from './redirects';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

export const logoutFlow = (errorHandleFunction: Function) => {
  ajax.postByDesc(false, endpoints.UNREGISTER, {}, (err: string | null) => {
    if (err) {
      !!errorHandleFunction && errorHandleFunction(err);
    } else {
      websocket.send(constants.WEB_SOCKET_USER_LOGOUT);
      authentication.clearLocalStorage();
      websocket.destroy();
      Logger.info('logoutWorkflow and holdCurrentCall');
      holdCurrentCall();

      if (logoutWhenReferrer()) {
        return;
      }

      appHistory.replace(constants.PATH_LOGIN);
      window.location.reload();
    }
  });
};
