import React from 'react';

export default class IconClock extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-clock"
        x="0px"
        y="0px"
        width="70px"
        height="70px"
        viewBox="0 0 70 70"
      >
        <path
          d={`M35,70C15.7,70,0,54.3,0,35C0,15.7,15.7,0,35,0c19.3,0,35,15.7,35,35C70,54.3,54.3,70,35,70z M35,5
          C18.5,5,5,18.5,5,35s13.5,30,30,30s30-13.5,30-30S51.5,5,35,5z M32.5,37.5v-5v-20h5v20h15v5h-15H32.5z`}
        />
      </svg>
    );
  }
}
