import axios, { AxiosError } from 'axios';
import AxiosHelper from '../../utilities/axiosApiHelper';

const endpoints = require('../../../json/endpoints.json');

const API = {
  /**
   * Post log to our backend
   */
  log: (log: string) =>
    axios
      .post(
        `${endpoints.EXTERNAL_LOG['url']}`,
        log,
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader(),
            'content-type': 'application/json'
          },

        }
      )
      .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err)))
};

export default API;
