import moment from 'moment';
import React from 'react';
import appDispatcher from '../app_dispatcher';
import dateTime from '../date_time';
import {
  AlarmModel,
  HeaderModel,
  ResourceModel,
  SortingByTabModel
} from '../interfaces/backend_model';
import localization from '../localization';
import logEvent from '../log_event';
import alarmListStore from '../stores/alarms/alarm_list_store_actions';
import settingsStore from '../stores/settings_store';
import { sortAlarms, defaultAlarmSorting } from '../utilities/sortAlarms';
import Utils from '../utils';
import IconArrow from './icon_arrow';
import IconClock from './icon_clock';
import TelephoneNumber from './telephone_number';

const constants = require('../../json/constants.json');

interface GridState {
  state: {
    active_tab: string;
    sorting_by_tab: {} | SortingByTabModel;
  };
}

interface Props {
  activeTab?: string;
  table?: boolean;
  renderHeaderCell?: (header: HeaderModel) => JSX.Element;
  sortable?: boolean;
  renderRow?: (alarm: AlarmModel) => JSX.Element | any;
  scroll?: boolean;
  mobileScroll?: boolean;
  headers?: HeaderModel[];
  rowKey?: string;
  rows: AlarmModel[] | any[] | null;
  wrapperClassName?: string;
  tableClassName?: string;
  header?: HeaderModel;
  isSingleListMode?: boolean;
  template?: string;
  sortOnDefault?: boolean;
  maxWidthCol?: string[];
}

interface State {
  filter: boolean | null;
  filter_top: number | string | null;
  filter_left: number | string | null;
  opened_filter_header: string | null;
  active_tab?: string;
  sorting_by_tab: SortingByTabModel | {};
}

export default class Grid extends React.PureComponent<Props, State> {
  private filter_window_ref: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      filter: null,
      opened_filter_header: null,
      sorting_by_tab: alarmListStore.getDefaultGridSorting(),
      active_tab: this.props.activeTab,
      filter_top: null,
      filter_left: null
    };
    this.filter_window_ref = React.createRef();
  }

  handleClickOutside = (e: { target: any }) => {
    if (this.filter_window_ref !== null && this.filter_window_ref.current !== null) {
      if (!this.filter_window_ref.current.contains(e.target)) {
        // Mouse click registered outside of the component where filter_window_ref is registered
        // Remove unecessary event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
        // All windows should be closed
        this.setState({ opened_filter_header: null, filter: null });
      }
    }
  };

  /**
   * render header
   */
  renderHeader = (headers: HeaderModel[]) => {
    let { renderHeaderCell, table } = this.props;
    let useRenderHeader = renderHeaderCell ? renderHeaderCell : this.renderHeaderCell;
    if (table) {
      return (
        <thead className="grid-header">
          <tr>{headers.map(useRenderHeader)}</tr>
        </thead>
      );
    }
    return (
      <div className="grid-header flex-row-around flex-0-0-auto">
        {headers.map(useRenderHeader)}
      </div>
    );
  };

  /**
   * toggle header filter
   */
  toggleHeaderFilter = (e: React.FormEvent<HTMLButtonElement>) => {
    let { table } = this.props;
    let target = e.currentTarget,
      header_key = target.dataset.header_key;
    e.stopPropagation();
    if (header_key) {
      let newState;
      if (table) {
        target = Utils.find_parent(target, 'th');
        newState = {
          filter: !this.state.filter,
          filter_top: target.offsetTop + target.offsetHeight,
          filter_left: target.offsetLeft,
          opened_filter_header: ''
        };
      } else {
        newState = {
          filter: !this.state.filter,
          filter_top: target.offsetTop + target.offsetHeight,
          filter_left: target.offsetLeft,
          opened_filter_header: ''
        };
      }
      if (this.state.opened_filter_header === header_key) {
        newState.filter = !this.state.filter;
      } else {
        newState.opened_filter_header = header_key;
        newState.filter = true;
      }

      if (newState.filter) {
        // We are about to open the filter window.
        // Lets add a listener to know when to close the window
        document.addEventListener('mousedown', this.handleClickOutside);
      }
      this.setState(newState);
    }
  };

  /**
   * get sort icon
   */
  getSortIcon(sort_value: string) {
    switch (sort_value) {
      case constants.ASCENDING:
        return <IconArrow icon="down" />;
      case constants.DESCENDING:
        return <IconArrow icon="up" />;
      default:
        return null;
    }
  }

  renderNBSP() {
    return <div className="flex-col-around">&nbsp;</div>;
  }

  /**
   * render header cell
   */
  renderHeaderCell = (header: HeaderModel, ind: number) => {
    let { sorting_by_tab, active_tab } = this.state;
    let active_sorting = active_tab ? sorting_by_tab[active_tab] : [];
    let { table, sortable } = this.props;
    let template = this.props.template ? this.props.template : null;
    let styleCell = {};

    let header_key = header.key ? `${header.key}` : ind.toString(),
      found_sort = Utils.find_obj(active_sorting, header_key, undefined, true),
      index = found_sort ? active_sorting.indexOf(found_sort) : -1,
      header_button_filter = (
        <div className="flex-col-around">
          <button
            className="button-small-secondary"
            type="button"
            onClick={this.toggleHeaderFilter}
            data-header_key={header_key}
          >
            <div className="flex-row sort-status">
              {index >= 0 ? <div className="flex-col-around">{index + 1}</div> : this.renderNBSP()}
              <div className="flex-col-around">
                {found_sort && header_key ? this.getSortIcon(found_sort[`${header.key}`]) : null}
              </div>
            </div>
          </button>
        </div>
      ),
      header_title = <div className="flex-col-around">{header.content}</div>,
      header_button_title = (
        <div className="flex-col-around">
          <button
            className="button-small-secondary"
            type="button"
            onClick={this.toggleHeaderSort}
            data-header_key={header_key}
          >
            {header.content}
          </button>
        </div>
      );

    switch (template) {
      case 'goteborg':
      case 'goteborg_v2':
        if (header_key === 'LogEventCode') {
          styleCell = { width: '100%' };
        }
        break;
    }

    if (table) {
      return (
        <th key={`${header.key}`} className={header_key} style={styleCell}>
          <div className="flex-row">
            {sortable ? header_button_filter : null}
            {sortable ? header_button_title : header_title}
          </div>
        </th>
      );
    }
    return (
      <div key={`${header.key}`} className="flex-row form-list-header-cell">
        {sortable ? header_button_filter : null}
        {sortable ? header_button_title : header_title}
      </div>
    );
  };

  /**
   * compare each item by multiple sort keys
   */
  static sortWorkflow = (gridState: GridState, a: AlarmModel, b: AlarmModel) => {
    let not_equal = false,
      result = 0;
    gridState.state.sorting_by_tab[gridState.state.active_tab].some(
      (sorter: { [key: string]: string }) => {
        for (let sort_key in sorter) {
          if ({}.hasOwnProperty.call(sorter, sort_key)) {
            let sort_value = sorter[sort_key];
            let multiplicator = sort_value === constants.DESCENDING ? -1 : 1;

            if (a[sort_key] < b[sort_key]) {
              result = -1 * multiplicator;
              not_equal = true;
            } else if (a[sort_key] > b[sort_key]) {
              result = multiplicator;
              not_equal = true;
            }
          }
        }
        return not_equal;
      }
    );
    return result;
  };

  /**
   * render body
   */
  renderBody = (rows: AlarmModel[]) => {
    let { renderRow, scroll, mobileScroll, table, sortOnDefault } = this.props;
    let active_sorting = this.state.active_tab
      ? this.state.sorting_by_tab[this.state.active_tab]
      : [];
    let useRenderRow = renderRow ? renderRow : this.renderRow,
      bodyClassName,
      rowsData = Array.prototype.slice.call(rows);

    if (sortOnDefault && (this.state.active_tab && this.state.active_tab === 'TAB_ACTIVE')) {
      rowsData = sortAlarms(rowsData);
    }

    if (table) {
      bodyClassName = 'grid-body' + (scroll && !mobileScroll ? ' y-scroll' : '');
    } else {
      bodyClassName =
        'grid-body flex-col' + (scroll && !mobileScroll ? ' y-scroll flex-1-1-0px' : '');
    }
    if (active_sorting.length) {
      const [sortKey, sortValue] = Object.entries(active_sorting[0])[0];
      if (this.state.active_tab === "TAB_ACTIVE") {
        rowsData = sortAlarms(rowsData, sortKey, sortValue === constants.DESCENDING);
      } else {
        rowsData = defaultAlarmSorting(rowsData, sortKey, sortValue === constants.DESCENDING);
      }
    }
    if (table) {
      return <tbody className={bodyClassName}>{rowsData.map(useRenderRow)}</tbody>;
    }

    return <div className={bodyClassName}>{rowsData.map(useRenderRow)}</div>;
  };

  static formatValue(
    options: HeaderModel,
    full: ResourceModel,
    value: string,
    template?: string | null
  ) {
    if (options.date_and_time) {
      let dateAndTime = dateTime.toLocaleDateAndTime(value);
      let iconClock;
      const settingTimestamp = settingsStore.getValueByKey('TIMESTAMPS', 'string');
      let date = dateAndTime.date;
      let styleWrap = {};

      switch (settingTimestamp) {
        case 'TODAY':
          date = Utils.dateToShow(value, dateAndTime.date);
      }

      switch (template) {
        case 'goteborg':
        case 'goteborg_v2':
          iconClock = null;
          styleWrap = { whiteSpace: 'nowrap', flexWrap: 'nowrap' };
          break;
        default:
          iconClock = (
            <div className="flex-col-around flex-0-0-auto">
              <IconClock />
            </div>
          );
      }

      return (
        <div className="flex-row-wrap date-and-time flex-1-1-auto" style={styleWrap}>
          {iconClock}
          &nbsp;
          <div className="flex-col-around date flex-0-0-auto">{date}</div>
          &nbsp;
          <div className="flex-col-around time flex-0-0-auto">{dateAndTime.time}</div>
        </div>
      );
    } else if (options.time_format) {
      let dateAndTime = dateTime.toLocaleDateAndTime(value);
      return dateAndTime.time;
    } else if (options.log_format) {
      const isDeactivated = full.PredefinedId === 0 && full.LogEventCode === 0;
      return <span className={isDeactivated ? 'logs-deactivated' : ''}>{logEvent[options.log_format](full)}</span>;
    } else if (options.telephone_format) {
      return (
        <TelephoneNumber
          value={value}
          onPhoneClick={options.onPhoneClick}
          contactId={full.ContactId}
          resource={full.ContactId > 0 ? full : null}
        />
      );
    }
    return value;
  }

  /**
   * get value of cell
   */
  getValue(header: HeaderModel, data: ResourceModel) {
    let value: any;
    let template = this.props && this.props.template ? this.props.template : null;

    if (Array.isArray(header.key) && header.join_key) {
      let values: any[] = [];
      header.key.forEach((key: string, ind: number) => {
        let v = Grid.formatValue(header, data, data[key], template);
        if (header.skip_key && header.skip_key[ind] && !v) {
          // skip
        } else {
          let wrap;
          if (header.wrap_key && (wrap = header.wrap_key[ind])) {
            v = wrap.replace('{' + key + '}', v);
          }
          values.push(v);
        }
      });
      if (values.filter(item => !!item).length) {
        value = values.join(header.join_key);
      }
    } else {
      value = Grid.formatValue(header, data, data[`${header.key}`], template);
      if (Array.isArray(header.loc_value)) {
        header.loc_value.some(l_v => {
          if (value === l_v[0]) {
            value = l_v[1];
            return true;
          }
          return false;
        });
      }
      if (header.wrap_key) {
        value = header.wrap_key.replace('{' + header.key + '}', value);
      }
    }
    return value;
  }

  /**
   * render row
   */
  renderRow = (row: ResourceModel, ind: number) => {
    let { headers, rowKey, table } = this.props;
    let render_keys;
    let template = this.props && this.props.template ? this.props.template : null;
    let styleCell = {};

    if (Array.isArray(headers)) {
      render_keys = headers;
    } else {
      throw new Error('NOT IMPLEMENTED!');
      // render_keys = Object.keys(row);
    }

    switch (template) {
      case 'goteborg':
      case 'goteborg_v2':
        styleCell = { maxWidth: '150px' };
        break;
    }

    if (table) {
      let classStyle = '';

      //checking if the alarm is "new" by it's event code and id
      if (row.LogEventCode === 29 && row.PredefinedId === 7) {
        const lastUpdated = moment(row.LastUpdated);
        const currentTime = moment();
        let difference = currentTime.diff(lastUpdated, 'seconds');

        if (difference < 15) {
          classStyle = 'red-background';
        } else {
          classStyle = '';
        }
      }
      return (
        <tr key={rowKey ? row[rowKey] : ind} className="">
          {render_keys.map(header => {
            let value = this.getValue(header, row);
            const fullWidth = (this.props.maxWidthCol || []).includes(header.key.toString());
            if (fullWidth) {
              styleCell = { minWidth: '15vw' };
            }
            return (
              <td key={`${header.key}`} className={classStyle} style={styleCell}>
                {value}
              </td>
            );
          })}
        </tr>
      );
    }
    return (
      <div key={rowKey ? row[rowKey] : ind} className="flex-row">
        {render_keys.map(header => {
          let value = this.getValue(header, row);
          return (
            <div key={`${header.key}`} className="flex-0-0-auto">
              {value}
            </div>
          );
        })}
      </div>
    );
  };

  /**
   * render filter window
   */
  renderFilterWindow = () => {
    let { filter_left, filter_top } = this.state;
    let active_sorting = this.state.active_tab
      ? this.state.sorting_by_tab[this.state.active_tab]
      : [];
    let header = Utils.find_obj(this.props.headers, 'key', this.state.opened_filter_header);
    if (
      filter_left &&
      Utils.is_finite(filter_left) &&
      filter_top &&
      Utils.is_finite(filter_top) &&
      header
    ) {
      let header_key = header.key,
        found_sort = Utils.find_obj(active_sorting, header_key, undefined, true);
      let sort_order_changed = 'none';
      if (found_sort && found_sort[header_key] === constants.DESCENDING) {
        sort_order_changed = 'descending';
      } else if (found_sort && found_sort[header_key] === constants.ASCENDING) {
        sort_order_changed = 'ascending';
      }
      return (
        <div
          ref={this.filter_window_ref}
          className="p-abs flex-col filter-window"
          style={{ left: filter_left + 'px', top: filter_top + 'px' }}
          onClick={this.stopClick}
        >
          <div>{header.content}</div>
          <legend>{localization.t('SORT')}:</legend>
          <div className="flex-row">
            <input
              type="radio"
              id="none"
              name={header_key}
              onChange={this.handleSortInputChange}
              checked={sort_order_changed === 'none'}
              value=""
            />
            <label htmlFor="none">{localization.t('NONE')}</label>
          </div>
          <div className="flex-row">
            <input
              type="radio"
              id="ascending"
              name={header_key}
              onChange={this.handleSortInputChange}
              checked={sort_order_changed === 'ascending'}
              value={constants.ASCENDING}
            />
            <label htmlFor="ascending">{localization.t('ASCENDING')}</label>
          </div>
          <div className="flex-row">
            <input
              type="radio"
              id="descending"
              name={header_key}
              onChange={this.handleSortInputChange}
              checked={sort_order_changed === 'descending'}
              value={constants.DESCENDING}
            />
            <label htmlFor="descending">{localization.t('DESCENDING')}</label>
          </div>
        </div>
      );
    }
    return null;
  };

  /**
   * stop click event propagation
   */
  stopClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  /**
   * toggle header sort
   */
  toggleHeaderSort = (e: React.FormEvent<HTMLButtonElement>) => {
    // TODO merge with handleSortInputChange()
    let { sorting_by_tab, active_tab } = this.state; // TODO clone array for pure new state
    let target = e.currentTarget,
      header_key = target.dataset.header_key;
    if (header_key && active_tab) {
      let found_sort = Utils.find_obj(sorting_by_tab[active_tab], header_key, undefined, true),
        index = found_sort ? sorting_by_tab[active_tab].indexOf(found_sort) : -1,
        sortValue = found_sort ? found_sort[header_key] : '',
        newSortValue = '';
      switch (sortValue) {
        case '':
          newSortValue = constants.ASCENDING;
          break;
        case constants.ASCENDING:
          newSortValue = constants.DESCENDING;
          break;
        case constants.DESCENDING:
          newSortValue = '';
          break;
      }
      if (newSortValue) {
        // on sort
        if (found_sort) {
          found_sort[header_key] = newSortValue;
        } else {
          sorting_by_tab[active_tab].push({ [header_key]: newSortValue });
        }
      } else if (found_sort && index >= 0) {
        // off sort
        sorting_by_tab[active_tab].splice(index, 1);
      }

      appDispatcher.dispatch({
        action: {
          type: constants.ACTION_CHANGE_SORTING,
          sorting_by_tab
        }
      });
      this.setState({ sorting_by_tab });
      // Need to force the component to render since it extends
      // React.PureComponent that only does a shallow compare for the objects
      // we send to setState and no changes are detected
      this.forceUpdate();
    }
  };

  /**
   * handle sort change
   */
  handleSortInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO merge with toggleHeaderSort()
    let { sorting_by_tab, active_tab } = this.state; // TODO clone array for pure new state
    let target = e.target;
    if (target.name && active_tab) {
      // TODO clone array for pure new state
      let found_sort = Utils.find_obj(sorting_by_tab[active_tab], target.name, undefined, true),
        index = found_sort ? sorting_by_tab[active_tab].indexOf(found_sort) : -1;
      if (target.value) {
        // on sort
        if (found_sort) {
          found_sort[target.name] = target.value;
        } else {
          sorting_by_tab[active_tab].push({ [target.name]: target.value });
        }
      } else if (found_sort && index >= 0) {
        // off sort
        sorting_by_tab[active_tab].splice(index, 1);
      }

      appDispatcher.dispatch({
        action: {
          type: constants.ACTION_CHANGE_SORTING,
          sorting_by_tab
        }
      });
      this.setState({ sorting_by_tab });
      // Need to force the component to render since it extends
      // React.PureComponent that only does a shallow compare for the objects
      // we send to setState and no changes are detected
      this.forceUpdate();
    }
  };

  componentWillReceiveProps(nextProps: Props) {
    let { activeTab } = nextProps;
    let { active_tab } = this.state;
    if (activeTab) {
      active_tab = activeTab;
      this.setState({
        active_tab
      });
    }
  }

  render() {
    const { headers, rows, scroll, table, wrapperClassName, tableClassName, header } = this.props;

    let has_headers = Array.isArray(headers),
      has_rows = Array.isArray(rows),
      defaultClassName,
      className;
    if (table) {
      defaultClassName = 'form-table ';
      className = tableClassName || defaultClassName;
      className += scroll ? ' y-scroll scrollableWidget' : ''; // actually make a table widget scrollable
    } else {
      defaultClassName = 'form-list p-rel flex-col ';
      className = scroll ? defaultClassName + 'flex-1-1-0px' : defaultClassName + 'flex-0-0-auto';
    }
    if (!(has_headers || has_rows)) {
      return null;
    }
    if (table) {
      return (
        <div className={wrapperClassName}>
          <table className={className}>
            {header ? header : has_headers ? headers && this.renderHeader(headers) : null}
            {has_rows && rows ? this.renderBody(rows) : null}
          </table>
          {this.state.filter ? this.renderFilterWindow() : null}
        </div>
      );
    }

    return (
      <div className={className}>
        {has_headers ? headers && this.renderHeader(headers) : null}
        {has_rows && rows ? this.renderBody(rows) : null}
        {this.state.filter ? this.renderFilterWindow() : null}
      </div>
    );
  }
}
