import React from 'react';

export default class IconBuilding extends React.Component {
  render() {
    return (
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 2.375C3 2.16789 3.16789 2 3.375 2H4.125C4.33211 2 4.5 2.16789 4.5 2.375V3.125C4.5 3.33211 4.33211 3.5 4.125 3.5H3.375C3.16789 3.5 3 3.33211 3 3.125V2.375Z" fill="#B2BFD9"/>
        <path d="M5.25 2.375C5.25 2.16789 5.41789 2 5.625 2H6.375C6.58211 2 6.75 2.16789 6.75 2.375V3.125C6.75 3.33211 6.58211 3.5 6.375 3.5H5.625C5.41789 3.5 5.25 3.33211 5.25 3.125V2.375Z" fill="#B2BFD9"/>
        <path d="M7.875 2C7.66789 2 7.5 2.16789 7.5 2.375V3.125C7.5 3.33211 7.66789 3.5 7.875 3.5H8.625C8.83211 3.5 9 3.33211 9 3.125V2.375C9 2.16789 8.83211 2 8.625 2H7.875Z" fill="#B2BFD9"/>
        <path d="M3 4.625C3 4.41789 3.16789 4.25 3.375 4.25H4.125C4.33211 4.25 4.5 4.41789 4.5 4.625V5.375C4.5 5.58211 4.33211 5.75 4.125 5.75H3.375C3.16789 5.75 3 5.58211 3 5.375V4.625Z" fill="#B2BFD9"/>
        <path d="M5.625 4.25C5.41789 4.25 5.25 4.41789 5.25 4.625V5.375C5.25 5.58211 5.41789 5.75 5.625 5.75H6.375C6.58211 5.75 6.75 5.58211 6.75 5.375V4.625C6.75 4.41789 6.58211 4.25 6.375 4.25H5.625Z" fill="#B2BFD9"/>
        <path d="M7.5 4.625C7.5 4.41789 7.66789 4.25 7.875 4.25H8.625C8.83211 4.25 9 4.41789 9 4.625V5.375C9 5.58211 8.83211 5.75 8.625 5.75H7.875C7.66789 5.75 7.5 5.58211 7.5 5.375V4.625Z" fill="#B2BFD9"/>
        <path d="M3.375 6.5C3.16789 6.5 3 6.66789 3 6.875V7.625C3 7.83211 3.16789 8 3.375 8H4.125C4.33211 8 4.5 7.83211 4.5 7.625V6.875C4.5 6.66789 4.33211 6.5 4.125 6.5H3.375Z" fill="#B2BFD9"/>
        <path d="M5.25 6.875C5.25 6.66789 5.41789 6.5 5.625 6.5H6.375C6.58211 6.5 6.75 6.66789 6.75 6.875V7.625C6.75 7.83211 6.58211 8 6.375 8H5.625C5.41789 8 5.25 7.83211 5.25 7.625V6.875Z" fill="#B2BFD9"/>
        <path d="M7.875 6.5C7.66789 6.5 7.5 6.66789 7.5 6.875V7.625C7.5 7.83211 7.66789 8 7.875 8H8.625C8.83211 8 9 7.83211 9 7.625V6.875C9 6.66789 8.83211 6.5 8.625 6.5H7.875Z" fill="#B2BFD9"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 1.25C1.5 0.835787 1.83579 0.5 2.25 0.5H9.75C10.1642 0.5 10.5 0.835786 10.5 1.25V11.75C10.5 12.1642 10.1642 12.5 9.75 12.5H2.25C1.83579 12.5 1.5 12.1642 1.5 11.75V1.25ZM9.75 1.25V11.75H7.5V9.875C7.5 9.66789 7.33211 9.5 7.125 9.5H4.875C4.66789 9.5 4.5 9.66789 4.5 9.875V11.75H2.25V1.25L9.75 1.25Z" fill="#B2BFD9"/>
      </svg>

    );
  }
}
