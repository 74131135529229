import * as Logger from 'js-logger';
import LinearProgress from 'material-ui/LinearProgress';
import RaisedButton from 'material-ui/RaisedButton';
import * as PropTypes from 'prop-types';
import React from 'react';

import ajax from '../ajax';
import appHistory from '../app_history';
import authentication, { username } from '../authentication';
import localization from '../localization';
import TemplatesStore from '../stores/templates_store';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

interface DataRegisterModel {
  expires_in: number;
  access_token: string;
  token_type: string;
  telephone: string;
  template: string;
  alarmid: string;
  read_only: string;
  authorized: string;
  admin_id: number;
}

interface Props {
  location: {
    query: {
      expires_in: number;
      telephone: string;
      telephony: string;
      token: string;
      token_type: string;
      url: string;
      username: string;
      p: string;
    };
  };
}

class AutoRegisterSimple extends React.Component<Props> {
  state = {
    isError: false,
    isLoading: true,
    errorMsg: ''
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    window.onbeforeunload = () => {
      authentication.clearLocalStorage(username);
    };
    this.register();
  }

  retry = () => {
    this.setState({ isLoading: true, isError: false, errorMsg: '' });
    this.register();
  };

  register() {
    const { p } = this.props.location.query; // password

    ajax.postByDesc(
      true,
      endpoints.URL_LOGIN,
      { password: p },
      (err: string | null, xhr: XMLHttpRequest, response: { data: DataRegisterModel }) => {
        if (err || !response) {
          this.setState({
            isError: true,
            errorMsg: `${xhr.status} - ${err}`,
            isLoading: false
          });
          Logger.error(`${xhr.status} - ${err}`);
          return;
        }

        const { data } = response;

        // Ensure that we do not keep previous state of logged in user
        // This works as Simplified Admin always are called 'temporary-user-from-autoregister'
        authentication.clearLocalStorage(username);

        TemplatesStore.isTemplateOnly = true;
        authentication.currentUserId = username;
        authentication.ls_set(
          [username, constants.EXPIRES_INITIAL_KEY],
          Date.now() + data.expires_in * 1000
        );
        authentication.ls_set([username, constants.ACCESS_TOKEN_KEY], data.access_token);
        authentication.ls_set([username, constants.EXPIRES_IN_KEY], data.expires_in);
        authentication.ls_set([username, constants.TOKEN_TYPE_KEY], data.token_type);
        authentication.ls_set([username, constants.PHONE_NUMBER_KEY], data.telephone);

        if (data.admin_id == 0) {
          // We have a SimplifiedAdmin that does not exist, 
          // In other words no idea to post anything to the backend that requires an admin to exist
          console.log("Logged in as a Simplified Admin");
        }

        authentication.ls_set([username, constants.ME_ADMIN_ID], String(data.admin_id));
        if (data.read_only) {
          authentication.ls_set([username, constants.ME_READ_ONLY], 'true');

          authentication.ls_set([username, constants.PHONE_SELECTED_KEY], 'false');
          authentication.ls_set([username, constants.TELEPHONY_KEY], constants.TELEPHONY_NONE);
        }
        else {
          authentication.ls_set([username, constants.PHONE_SELECTED_KEY], 'true');
          authentication.ls_set([username, constants.TELEPHONY_KEY], constants.TELEPHONY_BASIC_TEL);
        }

        if (data.authorized) {
          // Simple login and authorized to clear alarms
          authentication.ls_set([username, constants.ME_SIMPLE_LOGIN_AUTH], 'true');
        }

        authentication.ls_set([username, constants.ME_SIMPLE_LOGIN], 'true');

        localization.changeLanguage(localization.getLanguage()); // save selected language for current user
        localization.rebuildCache();

        appHistory.replace(constants.PATH_TEMPLATE + '/' + data.template + '/' + data.alarmid);
      }
    );
  }

  render() {
    let content;

    if (this.state.isLoading) {
      content = <LinearProgress />;
    }

    if (this.state.isError) {
      content = (
        <div>
          <p>{localization.t('AUTOLOGIN_ERROR_HEADING')}</p>
          <p>{localization.t('AUTOLOGIN_ERROR_BODY')}</p>
          <RaisedButton primary label={localization.t('RETRY')} onClick={this.retry} />
        </div>
      );
    }

    return <div className="flex-center">{content}</div>;
  }
}

export default AutoRegisterSimple;
