import dateTime from '../../date_time';
import moment from 'moment';
import { de } from 'date-fns/locale';

export const calcContactClassName = function(contact) {
  let className = '',
    darkenClassName = 'bg-c-gray-primary',
    now_date = new Date(),
    now_hours = now_date.getHours(),
    now_minutes = now_date.getMinutes(),
    startTime = dateTime.parseHoursMinutes(contact.StartTime),
    endTime = dateTime.parseHoursMinutes(contact.EndTime);

  if (startTime || endTime) {
    if (!endTime) {
      endTime = { hours: 0, minutes: 0 };
    } else if (!startTime) {
      startTime = { hours: 0, minutes: 0 };
    }

    if (
      startTime.hours < endTime.hours ||
      (startTime.hours == endTime.hours && startTime.minutes < endTime.minutes)
    ) {
      if (startTime.hours > now_hours) {
        className = darkenClassName;
      } else if (endTime.hours < now_hours) {
        className = darkenClassName;
      } else if (startTime.hours === now_hours && startTime.minutes > now_minutes) {
        className = darkenClassName;
      } else if (endTime.hours === now_hours && endTime.minutes < now_minutes) {
        className = darkenClassName;
      }
    } else if (
      startTime.hours > endTime.hours ||
      (startTime.hours == endTime.hours && startTime.minutes > endTime.minutes)
    ) {
      if (now_hours > endTime.hours && now_hours < startTime.hours) {
        className = darkenClassName;
      } else if (
        endTime.hours === now_hours &&
        endTime.minutes < now_minutes &&
        now_hours < startTime.hours
      ) {
        className = darkenClassName;
      }
    } else if (startTime.hours === 0 && endTime.hours === 0) {
      // Special case for grey out logic, if time is given as exactly StartTime = "0000" AND EndTime = "0000" make it gray
      if (startTime.minutes === 0 && endTime.minutes === 0) {
        className = darkenClassName;
      }
    }
  }
  return className;
};

export const isContactActive = (contact) => {
  if (!contact.StartTime && !contact.EndTime && (!contact.ScheduleItems || !contact.ScheduleItems.length)) {
    return true;
  }

  if (contact.StartTime && contact.EndTime && (!contact.ScheduleItems || !contact.ScheduleItems.length)) {
    return contact.StartTime <= moment().format('HH:mm') && contact.EndTime >= moment().format('HH:mm');
  }

  const todayDay = new Date().getDay() - 1 >= 0 ? new Date().getDay() - 1 : 6;

  const todayScheduleItems = (contact.ScheduleItems || []).filter((item) => {
    return !item.ExceptionTime &&
      new Date(item.StartDate).getTime() <= new Date().getTime() &&
      (!item.EndDate || new Date(item.EndDate).getTime() >= new Date().getTime()) &&
      (!item.RepeatDays || (item.RepeatDays || []).includes(todayDay));
  });

  const todayExceptionItems = (contact.ScheduleItems || []).filter((item) => {
    return item.ExceptionTime &&
      new Date(item.StartDate).getTime() <= new Date().getTime() &&
      (!item.EndDate || new Date(item.EndDate).getTime() >= new Date().getTime()) &&
      (!item.RepeatDays || (item.RepeatDays || []).includes(todayDay));
  });

  const isExceptionNow = todayExceptionItems.find((item) => {
    return item.StartTime <= moment().format('HH:mm') && item.EndTime >= moment().format('HH:mm');
  });

  if (isExceptionNow) {
    return false;
  }

  if (todayScheduleItems.length) {
    return todayScheduleItems.find((item) => {
      return item.StartTime <= moment().format('HH:mm') && item.EndTime >= moment().format('HH:mm');
    });
  }

  return false;
}
