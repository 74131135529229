import React from 'react';

export function useEscapeKey(onClose: () => any) {
  React.useEffect(
    () => {
      function onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
          onClose();
        }
      }

      window.addEventListener('keydown', onKeyDown);

      return () => window.removeEventListener('keydown', onKeyDown);
    },
    [onClose]
  );
}

export function useEnterKey(onEnter: () => any) {
  React.useEffect(
    () => {
      function onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
          onEnter();
        }
      }

      window.addEventListener('keydown', onKeyDown);

      return () => window.removeEventListener('keydown', onKeyDown);
    },
    [onEnter]
  );
}
