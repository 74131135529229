import React from 'react';

import localization from '../localization';
import TwilioStore from '../stores/twillio_store';

interface Props {
  name: string;
}

interface State {
  name: string;
}

export default class CallerName extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { name: props.name };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    TwilioStore.addEventListener(this.onChange);
  }

  componentWillUnmount() {
    TwilioStore.removeEventListener(this.onChange);
  }

  onChange() {
    this.setState({ name: TwilioStore.getCallDetails().from! });
  }

  render() {
    return <span>{localization.t('TWILIO_CALL_FROM') + `: ${this.state.name}`}</span>;
  }
}
