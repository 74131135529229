import * as Logger from 'js-logger';
import AppDispatcher from '../app_dispatcher';
import { CallModel, MessageModel } from '../interfaces/backend_model';

const constants = require('../../json/constants.json');

export function unHoldCall(callSid: string) {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_UNHOLD_CALL,
      callSid
    }
  });
}

export function joinCall(call: CallModel) {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_JOIN_CALL,
      call
    }
  });
}

export function leaveCall(call: CallModel) {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_LEAVE_CALL,
      call
    }
  });
}

export function holdCurrentCall() {
  Logger.info('Dispatcher holdCurrentCall');
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_HOLD_CURRENT_CALL
    }
  });
}

export function acceptCurrentCall() {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_ACCEPT_CURRENT_CALL
    }
  });
}

export function declineCurrentCall() {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_DECLINE_CURRENT_CALL
    }
  });
}

export function takeAlarmCall(alarmId: string) {
  AppDispatcher.dispatch({
    action: {
      type: constants.TAKE_ALARM_CALL,
      alarmId
    }
  });
}

export function startTwilioCallToNumber(number?: string, globalAlarmId = '') {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_CALL_START,
      number,
      globalAlarmId
    }
  });
}

export function startTwilioCallToUser(userId?: string, globalAlarmId = '') {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_CALL_START,
      userId,
      globalAlarmId
    }
  });
}

export function hangupTwilioCall() {
  Logger.info('Dispatcher hangupTwilioCall');
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_CALL_STOP
    }
  });
}

export function completedTwilioCall() {
  Logger.info('Dispatcher completedTwilioCall');
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_CALL_COMPLETED
    }
  });
}

export function sendDTMF(dtmf: number | string) {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_SEND_DTMF,
      dtmf
    }
  });
}

export function sendMessage(dtmf: MessageModel, alarmId: string) {
  AppDispatcher.dispatch({
    action: {
      type: constants.TWILIO_SEND_SMS,
      dtmf,
      alarmId
    }
  });
}
