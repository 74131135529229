import { Dispatcher } from 'flux';
import { ActionModel } from './interfaces/backend_model';

const flux = new Dispatcher<any>();

interface AppDispatcherAdditionalProps {
  ajax_unauthorized: boolean;
  unauthorizedErrorMessage?: string | null;
}

const appDispatcherAdditionalProps: AppDispatcherAdditionalProps = {
  ajax_unauthorized: false,
  unauthorizedErrorMessage: ''
};

const appDispatcher = Object.assign(flux, appDispatcherAdditionalProps, {
  handleServerAction(action: ActionModel) {
    let payload = {
      source: 'SERVER_ACTION',
      action
    };
    flux.dispatch(payload);
  },

  handleViewAction(action: ActionModel) {
    let payload = {
      source: 'VIEW_ACTION',
      action
    };
    flux.dispatch(payload);
  }
});

export default appDispatcher;
