import { da, de, enGB, es, fi, fr, it, nb, pt, sv } from 'date-fns/locale';
import * as i18next from 'i18next';
import * as i18nextCachePlugin from 'i18next-localstorage-cache';
import * as Logger from 'js-logger';
import { observable } from 'mobx';
import { registerLocale } from 'react-datepicker';
import ajax from './ajax';
import authStore from './authentication';
import { MeModel } from './interfaces/backend_model';
import resources, { availablesNamespaces, getFallbackLanguages, performRequests } from './nls';
import planningViewStore from './stores/alarms/planning_view_store_actions';
import settingsStore from './stores/settings_store';
import utils from './utils';

const endpoints = require('../json/endpoints.json');
const constants = require('../json/constants.json');

export const i18nextCachePrefix = 'rc-i18next_res_';
const locizeProjectId = 'acd71ac8-3bbd-4efc-aadc-fe8b7f9e35cd';
let version = 'production';

class Localization {
  readFromMemoryPerformed: boolean;
  isForceUpdate: boolean;
  i18nOptions: {
    lng: string;
    ns: string[];
    fallbackNS: string[];
    cache: {
      enabled: boolean;
      expirationTime: number;
      prefix: string;
    };
    debug: boolean;
  };
  i18n: i18next.i18n;
  @observable isLoaded = false;
  @observable hasError = false;
  @observable errorMessage = '';

  constructor() {
    // FIXME: ignored for now
    // this.isForceUpdate = window.location.hash.includes('forceLangUpdate=true');
    this.isForceUpdate = false;

    if (this.isForceUpdate) {
      version = 'latest';
    }

    const cacheSettings = {
      enabled: true,
      prefix: i18nextCachePrefix,
      expirationTime: this.isForceUpdate ? 0 : 1 * 24 * 60 * 60 * 1000
    };

    // patch cache plugin since he updating cache on every init
    i18nextCachePlugin.prototype.store = function (storeParam: any) {
      const store = storeParam;
      const nowMS = new Date().getTime();

      function writeToStorage(lng: string, options?: any) {
        // timestamp
        store[lng].i18nStamp = new Date().getTime();

        // language version (if set)
        if (options.versions[lng]) {
          store[lng].i18nVersion = options.versions[lng];
        }

        // save
        window.localStorage.setItem(options.prefix + lng, JSON.stringify(store[lng]));
      }

      Object.keys(store).forEach(lng => {
        const existingStamp = window.localStorage.getItem(this.options.prefix + lng);

        // when no cache — write
        if (!existingStamp) {
          writeToStorage(lng, this.options);
        }

        // when cache — check expiration time
        if (
          existingStamp &&
          JSON.parse(existingStamp).i18nStamp + this.options.expirationTime < nowMS
        ) {
          // expired
          writeToStorage(lng, this.options);
        }
      });
    };

    this.i18nOptions = {
      lng: constants.DEFAULT_LANGUAGE,

      ns: availablesNamespaces,
      fallbackNS: availablesNamespaces,

      cache: cacheSettings,

      debug: false
    };

    const i18nextBackendPlugin = {
      type: 'backend',
      readMulti() { },
      create() { },
      init() { },
      read: (lng: string, namespace: string, callback: any) => {
        // case: read from bundle and use it as last fallback
        this.readFromMemoryPerformed = true;
        if (resources[lng]) {
          callback(null, resources[lng][namespace]);
        } else {
          callback(null);
        }
      }
    };
    this.i18n = i18next
      .use(i18nextCachePlugin)
      .use(i18nextBackendPlugin)
      .init(this.i18nOptions, this.initializeI18n);
  }

  initializeI18n = (err: string | null) => {
    if (err) {
      Logger.error('i18next error during initialization', err);
      return;
    }

    (window as any).__localeId__ = this.getLanguage();

    this.processI18nLoading(false);
  };

  /**
   * Register the local for the Date Picker (Calendar) package depending on language
   * @private
   * @returns {void}
   */
  registerLocaleForDatePicker() {
    if (this.getLanguage().startsWith('sv-SE')) {
      registerLocale(this.getLanguage(), sv);
    } else if (this.getLanguage().startsWith('nb-NO')) {
      registerLocale(this.getLanguage(), nb);
    } else if (this.getLanguage().startsWith('de-DE')) {
      registerLocale(this.getLanguage(), de);
    } else if (this.getLanguage().startsWith('fi-FI')) {
      registerLocale(this.getLanguage(), fi);
    } else if (this.getLanguage().startsWith('fr-FR')) {
      registerLocale(this.getLanguage(), fr);
    } else if (this.getLanguage().startsWith('it-IT')) {
      registerLocale(this.getLanguage(), it);
    } else if (this.getLanguage().startsWith('es-ES')) {
      registerLocale(this.getLanguage(), es);
    } else if (this.getLanguage().startsWith('pt-PT')) {
      registerLocale(this.getLanguage(), pt);
    } else if (this.getLanguage().startsWith('da-DK')) {
      registerLocale(this.getLanguage(), da);
    } else {
      // Lets default to english
      registerLocale(this.getLanguage(), enGB);
    }
  }

  /**
   * @private
   * Used in initializeI18n and rebuildCache
   * Main propose — code re-usage
   */
  processI18nLoading = async (withForce: boolean) => {
    planningViewStore; // this line is only here to force load of the dependency, otherwise things crash and i dont know why

    if (authStore.isLoggedIn()) {
      try {
        await utils.registerWorkflow();
        const settings = await ajax.getByDescPromise(endpoints.SETTINGS);
        let me = await ajax.getByDescPromise(endpoints.ME) as MeModel;

        me.Language = this.calculateLanguage(me);
        console.log('Language to be used: ', me.Language);

        settingsStore.settings = settings;
        settingsStore.me = me;

        settingsStore.trigger(constants.SETTINGS_CHANGE);
        settingsStore.trigger(constants.ME_CHANGE);

        version = settingsStore.getValueByKey('ENV_DEV_OR_STAGE', 'boolean')
          ? 'latest'
          : 'production';

        // Register this once initially and then in changeLanguage() whenever language is changed during runtime
        this.registerLocaleForDatePicker();
        // @ts-ignore
        this.changeLanguage(me.Language);
      } catch (e) {
        Logger.error('Error occurred during fetching', e);

        if (typeof e === 'string' && e.length) {
          this.errorMessage = e;
        }
        this.hasError = true;
        return;
      }

      if (!this.readFromMemoryPerformed && !withForce) {
        this.isLoaded = true;
        return;
      }

      // FIXME: Ignored checking for now
      // const isEnvDevOrStage = settingsStore.getKey('ENV_DEV_OR_STAGE');
      // if setting is not set, use locize by default
      // const useLocize = isEnvDevOrStage ? isEnvDevOrStage.defaultvalue === 'true' : false;

      // FIXME: Ignored checking for now
      // case: load from backend
      // return Promise.all(
      //   performRequests({
      //     useLocize: useLocize && this.isForceUpdate,
      //     locizeProjectId,
      //     version
      //   })
      // );

      // directly fetch always from backend
      return Promise.all(
        performRequests({
          useLocize: false,
          locizeProjectId,
          version,
          languages: [this.getLanguage()]
        })
      )

        .catch(error => {
          // FIXME: Ignored checking for now
          // case: fallback when locize is unavailable
          // if (useLocize) {
          //   return Promise.all(performRequests({ useLocize: false, locizeProjectId, version }));
          // }

          throw error;
        })
        .then(responses => {
          responses.forEach(response => {
            this.i18n.addResourceBundle(
              response.lang,
              response.namespace,
              response.data,
              true,
              true
            );
          });

          this.isLoaded = true;
          Logger.log('i18next initialized, with language', this.getLanguage());
        })
        .catch(error => {
          Logger.error('Error occurred during fetching', error);
          this.isLoaded = true;
        });
    } else {
      this.isLoaded = true;
    }
  };

  getLanguage() {
    return this.i18n.language;
  }

  /**
   * If we are logged in with a SimplifiedOperator, let the browser decide the language
   * @returns string
   */
  calculateLanguage(me: MeModel) {
    if (me.RespondentId === 0 && me.Language === 'en-GB') {
      // We have the default language for a SimplifiedOperator
      // I.e. there exist no real admin to control the language
      // Lets use the language from the browser.
      console.log('navigator.languages: ', navigator.languages);

      const navigatorLanguage = navigator.language;
      if (navigatorLanguage.startsWith('sv')) {
        return 'sv-SE';
      } else if (navigatorLanguage.startsWith('no')
        || navigatorLanguage.startsWith('nb')
        || navigatorLanguage.startsWith('nn')) {
        return 'nb-NO';
      } else if (navigatorLanguage.startsWith('de')) {
        return 'de-DE';
      } else if (navigatorLanguage.startsWith('fi')) {
        return 'fi-FI';
      } else if (navigatorLanguage.startsWith('fr')) {
        return 'fr-FR';
      } else if (navigatorLanguage.startsWith('it')) {
        return 'it-IT';
      } else if (navigatorLanguage.startsWith('es')) {
        return 'es-ES';
      } else if (navigatorLanguage.startsWith('pt')) {
        return 'pt-PT';
      } else if (navigatorLanguage.startsWith('da')) {
        return 'da-DK';
      }
    }
    return me.Language;
  }

  getOptions = (
    options?: { count?: number; fallbackLng: string[] } | i18next.TranslationOptions<object>
  ) => {
    const fallbackLanguages = getFallbackLanguages(this.getLanguage());

    if (options) {
      options.fallbackLng = fallbackLanguages;
    } else {
      options = {
        fallbackLng: fallbackLanguages
      };
    }

    return options;
  };

  t(key: string | string[], options?: i18next.TranslationOptions<object>) {
    return this.i18n.t(key, options);
  }

  useNSt(ns: string) {
    const t = this.i18n.getFixedT(null, ns);

    return (key: string | string[], options?: i18next.TranslationOptions<object>) => {
      const opts = this.getOptions(options);
      return t(key, opts);
    };
  }

  changeLanguage(lang: string) {
    // avoid cache recomputation
    if (lang !== this.getLanguage()) {
      this.i18n.changeLanguage(lang, (err: string | null) => {
        if (err) {
          Logger.error('Error when setting language', lang, err);
        } else {
          this.registerLocaleForDatePicker();
        }
      });
    }
  }

  rebuildCache() {
    this.isLoaded = false;
    this.processI18nLoading(true);
  }
}

export default new Localization();
