import { format } from 'date-fns';
import { TimeDifferenceModel } from './interfaces/backend_model';
import localization from './localization';
import settingsStore from './stores/settings_store';
import Utils from './utils';

const constants = require('../json/constants.json');

class DateTime {
  /**
   * get time difference between two dates
   */
  getTimeDifference(date_point: Date, date_compare: Date) {
    const datetime_point = date_point.getTime();
    const datetime_compare = date_compare.getTime();
    let difference = datetime_compare - datetime_point;

    const sign = Utils.sign(difference);

    if (datetime_point === datetime_compare) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        difference: 0,
        sign: 0
      };
    }

    difference = Math.abs(difference);
    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

    difference -= daysDifference * 1000 * 60 * 60 * 24;
    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);

    difference -= hoursDifference * 1000 * 60 * 60;
    const minutesDifference = Math.floor(difference / 1000 / 60);

    difference -= minutesDifference * 1000 * 60;
    const secondsDifference = Math.floor(difference / 1000);

    return {
      days: daysDifference,
      hours: hoursDifference,
      minutes: minutesDifference,
      seconds: secondsDifference,
      isBefore: date_point > date_compare,
      sign
    };
  }

  /**
   * render string to show time difference
   */
  renderTimeDifference(time_difference: TimeDifferenceModel) {
    const datetimeT = localization.useNSt(constants.NS_DATETIME);
    let message = '';

    if (time_difference.difference === 0) {
      return datetimeT('AT_ALARM_TIME');
    }

    if (time_difference.days) {
      message +=
        time_difference.days + ' ' + datetimeT('DAY', { count: time_difference.days }) + ' ';
    }

    if (time_difference.hours) {
      message +=
        time_difference.hours + ' ' + datetimeT('HOUR', { count: time_difference.hours }) + ' ';
    }

    if (time_difference.minutes) {
      message +=
        time_difference.minutes +
        ' ' +
        datetimeT('MINUTE', { count: time_difference.minutes }) +
        ' ';
    }

    if (time_difference.seconds) {
      message +=
        time_difference.seconds +
        ' ' +
        datetimeT('SECOND', { count: time_difference.seconds }) +
        ' ';
    }

    if (time_difference.isBefore) {
      return message + ' ' + datetimeT('BEFORE_ALARM_TIME');
    }

    return message + ' ' + datetimeT('AFTER_ALARM_TIME');
  }

  /**
   * convert two string time and render difference
   */
  renderUTCTimeDifference(time_point: string, time_compare: string) {
    return this.renderTimeDifference(
      this.getTimeDifference(new Date(time_point), new Date(time_compare))
    );
  }

  /**
   * check leading zero
   */
  checkZero(number: number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  /**
   * convert string datetime to locale datetime
   */
  toLocaleDateTime(time_point: string | null) {
    if (!time_point) {
      return;
    }
    let date = new Date(time_point);

    return (
      format(date, settingsStore.getDateFormat()) +
      ' ' +
      this.checkZero(date.getHours()) +
      ':' +
      this.checkZero(date.getMinutes()) +
      ':' +
      this.checkZero(date.getSeconds())
    );
  }

  /**
   * convert string datetime to locale date and time
   */
  toLocaleDateAndTime(time_point: string) {
    let datetime = new Date(time_point),
      date = format(datetime, settingsStore.getDateFormat()),
      time =
        this.checkZero(datetime.getHours()) +
        ':' +
        this.checkZero(datetime.getMinutes()) +
        ':' +
        this.checkZero(datetime.getSeconds());
    return { date, time };
  }

  /**
   * convert string datetime to locale date
   */
  toLocaleDate(time_point: string) {
    if (time_point) {
      let date = new Date(time_point);
      return format(date, settingsStore.getDateFormat());
    }

    return null;
  }

  /**
   * returns full age of person
   */
  getAge(date_of_birth?: string) {
    if (date_of_birth) {
      let today = new Date(),
        birthDate = new Date(date_of_birth),
        age = today.getFullYear() - birthDate.getFullYear(),
        m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
    return null;
  }

  /**
   * check if current time bigger than time point
   */
  isTime(time_point?: string) {
    if (time_point) {
      let date = new Date(time_point),
        date_now = new Date();
      return date_now > date;
    }
    return false;
  }

  /**
   * extract hours and minutes from HHMM
   */
  parseHoursMinutes(str: string) {
    if (str) {
      let str_hours = str.slice(0, 2),
        str_minutes = str.slice(2);
      if (str_hours && str_minutes) {
        let hours = parseInt(str_hours, 10),
          minutes = parseInt(str_minutes, 10);
        if (
          Utils.is_finite(hours) &&
          hours >= 0 &&
          hours < 24 &&
          Utils.is_finite(minutes) &&
          minutes >= 0 &&
          minutes < 60
        ) {
          return { hours, minutes };
        }
      }
    }
    return null;
  }
}

export default new DateTime();
