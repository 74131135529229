import React from 'react';
import { sendDTMF } from '../../actions/twilio';
import appDispatcher from '../../app_dispatcher';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { AlarmDetailsModel, UserDetailsModel, Widget } from '../../interfaces/backend_model';
import localization from '../../localization';
import TwilioStore from '../../stores/twillio_store';
import AssociateWithObject from './AssociateWithObject';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
const constants = require('../../../json/constants.json');

const messageEnum = {
  emailLog: 'E-mail log'
};

interface ManageAlarmSectionProps {
  toggleType?: string;
  toggleState?: string;
  userDetails: UserDetailsModel;
  alarmDetails: AlarmDetailsModel;
  onShowSearchUser: () => void;
  errorText: string;
  widget: Widget;
  templateType: string;
  fullscreenMode?: boolean;
}

const DTMF_Commands = {
  DTMF_0: 0,
  DTMF_1: 1,
  DTMF_3: 3,
  DTMF_4: 4,
  DTMF_7: 7,
  DTMF_8: 8
};

const alarmT = localization.useNSt(constants.NS_ALARM);

function ManageAlarmWidget(props: ManageAlarmSectionProps) {
  const { toggleType, toggleState, widget, fullscreenMode } = props;
  const [currentMode, setMode] = React.useState(DTMF_Commands.DTMF_8);

  React.useEffect(
    () => {
      TwilioStore.addEventListener(changeState);
      return () => {
        TwilioStore.removeEventListener(changeState);
      };
    },
    [changeState]
  );

  function changeState() {
    const twilioState = TwilioStore.getState();
    if (!twilioState.isbusy) {
      setMode(DTMF_Commands.DTMF_8);
      return;
    }
  }

  const onCommandClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    signal: number
  ) => {
    event.preventDefault();
    // only when call is running
    if (TwilioStore.getState().isbusy) {
      sendDTMF(signal);
    }
    return;
  };

  const onClickPrintout = (event: Event) => {
    event.preventDefault();

    if (props.userDetails.ExternalId) {
      appDispatcher.dispatch({
        action: {
          type: constants.ACTION_ADD_ALARM_ACTION_TEXT,
          alarm_id: props.alarmDetails.AlarmId,
          user_id: props.userDetails.UserId,
          message: messageEnum.emailLog
        }
      });
    }
  };

  const onDetectButtonMode = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!TwilioStore.getState().isbusy) {
      return;
    }

    switch (currentMode) {
      case DTMF_Commands.DTMF_7:
        onCommandClick(event, DTMF_Commands.DTMF_7);
        setMode(DTMF_Commands.DTMF_8);
        break;
      case DTMF_Commands.DTMF_8:
        onCommandClick(event, DTMF_Commands.DTMF_8);
        setMode(DTMF_Commands.DTMF_7);
        break;
      default:
        setMode(DTMF_Commands.DTMF_7);
        break;
    }
  };

  const getButtonMode = () => {
    if (currentMode === DTMF_Commands.DTMF_8) {
      return alarmT('PUSH_TO_TALK');
    }
    if (currentMode === DTMF_Commands.DTMF_7) {
      return alarmT('PUSH_TO_HEAR');
    }
    return alarmT('PUSH_TO_TALK');
  };

  const getContent = () => {
    return (
      <div className="w-100p">
        <div className="flex-row-wrap" style={{ padding: '3px' }}>
          <div className="flex-row-center">
            <div className="p-rel flex-row p-05em">
              <button
                className="button-small-blue"
                onClick={e => onCommandClick(e, DTMF_Commands.DTMF_0)}
              >
                {alarmT('SEPARATE')}
              </button>
            </div>
          </div>
          <div className="flex-row-center">
            <div className="p-rel flex-row p-05em">
              <button className="button-small-blue" onClick={e => onDetectButtonMode(e)}>
                {getButtonMode()}
              </button>
            </div>
          </div>
          <div className="flex-row-center">
            <div className="p-rel flex-row p-05em">
              <button
                className="button-small-blue"
                onClick={e => onCommandClick(e, DTMF_Commands.DTMF_4)}
              >
                {alarmT('DUPLEX')}
              </button>
            </div>
          </div>
          <div className="flex-row-center">
            <div className="p-rel flex-row p-05em">
              <button
                className="button-small-blue"
                onClick={e => onCommandClick(e, DTMF_Commands.DTMF_1)}
                style={{ padding: '0.25em' }}
              >
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="18px"
                  viewBox="0 0 65.125 65.125"
                  fill="#1976d2"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <g>
                    <g>
                      <path d="M19.032,64.337l-0.004-30.995H0L32.562,0.786l32.563,32.556H46.096v30.997H19.032V64.337z M5.641,31.007h15.723 l0.004,30.996l22.392-0.002l0.005-30.994h15.721L32.563,4.089L5.641,31.007z" />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div className="flex-row-center">
            <div className="p-rel flex-row p-05em">
              <button
                className="button-small-blue"
                onClick={e => onCommandClick(e, DTMF_Commands.DTMF_3)}
                style={{ padding: '0.25em' }}
              >
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="18px"
                  viewBox="0 0 65.125 65.125"
                  fill="#1976d2"
                >
                  <g>
                    <g>
                      <path d="M19.032,64.337l-0.004-30.995H0L32.562,0.786l32.563,32.556H46.096v30.997H19.032V64.337z M5.641,31.007h15.723 l0.004,30.996l22.392-0.002l0.005-30.994h15.721L32.563,4.089L5.641,31.007z" />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <AssociateWithObject
            alarmDetails={props.alarmDetails}
            onShowSearchUser={props.onShowSearchUser}
            errorText={props.errorText}
          />
          <div className="flex-1-1-0px flex-row-end">
            <div className="p-rel flex-row p-05em">
              <button
                className={'flex-row button-small-blue'}
                onClick={(e: any) => onClickPrintout(e)}
              >
                {alarmT('MESSAGE_PRINTOUT')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const hasContent = true; //Alawys have content
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "ManageAlarmSection", true);
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      headerLocKey={alarmT('MANAGE_ALARM_SECTION')}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
      fullscreenMode={fullscreenMode}
    >
      {getContent()}
    </Section>
  );
}

export default function ManageAlarmSection (props: ManageAlarmSectionProps) {
  return (
    <WidgetErrorBoundary>
      <ManageAlarmWidget {...props} />
    </WidgetErrorBoundary>
  );
};
