import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import * as ReactPortal from 'react-portal';

interface Props {
  show: boolean;
  onClose?: Function;
  title?: { className: string; content: string | JSX.Element };
  body: { className: string; content: string | JSX.Element | boolean };
  footer: { className: string; content: string | JSX.Element };
  onClick?: any;
}

export class Popup extends React.Component<Props> {
  popupEl: HTMLElement | null = null;
  prevFocusedElement: HTMLElement | null = null;

  componentDidMount() {
    this.prevFocusedElement = null;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.show !== prevProps.show) {
      if (this.props.show) {
        this.attachGlobalHandler();
        this.prevFocusedElement = document.activeElement as HTMLElement;

        if (this.popupEl) {
          let el = this.popupEl.querySelector('[data-focus="1"]') as HTMLElement;
          el && el.focus();
        }
      } else {
        this.deattachGlobalHandler();
        if (this.prevFocusedElement) {
          this.prevFocusedElement.focus();
          if (document.activeElement) {
            (document.activeElement as HTMLElement).blur();
          }
        }
      }
    }
  }

  attachGlobalHandler = () => document.addEventListener('keydown', this.onKeyPress, false);
  deattachGlobalHandler = () => document.removeEventListener('keydown', this.onKeyPress);

  onKeyPress = (ev: KeyboardEvent) => {
    if (ev.code === "Tab") {
      ev.preventDefault();
    } else if (ev.code === "Escape") {
      ev.preventDefault();
      this.props?.onClose?.();
    }
  };

  render() {
    let titleClassName = 'title-line-center flex-center ',
      bodyClassName = 'w-100p p-t-b-05em ',
      footerClassName = 'flex-center p-t-b-05em border-top-footer w-100p ',
      title = this.props.title ? (
        <div className={titleClassName + this.props.title.className}>
          <div className="title-line-wrapper title-popup">
            <label className="title-line-text">{this.props.title.content}</label>
          </div>
        </div>
      ) : null,
      body = (
        <div className={bodyClassName + this.props.body.className}>
          <div className="p-r-l-1em">{this.props.body.content}</div>
        </div>
      ),
      footer = (
        <div className={footerClassName + this.props.footer.className}>
          {this.props.footer.content}
        </div>
      ),
      commonClassName = 'flex-outer-container fx-wh-100vwh popup ',
      fullClassName = commonClassName + (this.props.show ? 'in' : 'hidden-popup');

    // need to check what cause rendering
    return this.props.show ? (
      <ReactPortal.Portal>
        <MuiThemeProvider muiTheme={getMuiTheme()}>
          <div
            className={fullClassName}
            onClick={this.props.onClick}
            ref={node => (this.popupEl = node)}
          >
            <div className="flex-center flex-col popup-message">
              {title}
              {body}
              {footer}
            </div>
          </div>
        </MuiThemeProvider>
      </ReactPortal.Portal>
    ) : null;
  }
}
