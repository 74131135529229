import { LinkModel, Widget } from 'development/js/interfaces/backend_model';
import React, { useEffect } from 'react';
import { WidgetErrorBoundary } from '../../../components/errorBoundaries/widgetErrorBoundary';
import Section from '../../../components/section';
import { evalToggleState, hideWidget } from '../../../enums/VisibilityState';
import localization from '../../../localization';
import { filterWidgetSettings } from '../../../utilities/filterWidgetSettingsHelper';
import { ExternalInformation } from "./ExternalInformation";
import { IntegrationInformation } from "./IntegrationInformation";

const constants = require('../../../../json/constants.json');

interface ExternalInformationProps {
    widget: Widget;
    attachedLinks: LinkModel[];
    toggleType?: string;
    toggleState?: string;
    fullscreenMode?: boolean;
    settings: string[];
    notificationSystem: any;
    headerLocKey?: string;
}

/**
 * Search for a match against matchLinkRegex starting with link added last
 */
function getLinkUrl(matchLinkRegex: string, attachedLinks?: LinkModel[]): string {
    if (!attachedLinks) { return ''; }

    for (let i = attachedLinks.length - 1; i >= 0; i--) {
        if (attachedLinks[i].LinkDescription?.match(matchLinkRegex)) {
            // Either matchLinkRegex is not defined in the template or found a match
            // Lets return the url so be used as the external information
            return attachedLinks[i].LinkUrl;
        }
    }

    return '';
}

const alarmT = localization.useNSt(constants.NS_ALARM);

// This widget supports the following settings
const handledSettings = [
    'matchLinkText',
    'allow',
    'height',
    'sandbox',
    'seamless',
    'srcDoc',
    'width',
    'frameBorder',
    'scrolling'
];

function ExternalInformationWidget(props: ExternalInformationProps) {
    let {
        widget,
        attachedLinks,
        toggleType,
        toggleState,
        settings,
        fullscreenMode,
        notificationSystem,
        headerLocKey,
    } = props;

    const widgetSettings = filterWidgetSettings(settings, handledSettings, widget.widgetName);

    const [externalSrc, setExternalSrc] = React.useState(getLinkUrl(widgetSettings.matchLinkText, attachedLinks));
    const hasContent = Boolean(externalSrc?.trim());
    const isIntegrationInformation = externalSrc.includes('externalinformation');
    const [isFullscreen, setIsFullscreen] = React.useState(false);

    const onFullscreenChange = (fullscreenState: boolean) => {
        setIsFullscreen(fullscreenState);
    }

    const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "ExternalInformation");
    if (hideWidget(evaluatedToggleState)) return null;

    const getContent = () => {
        if (isIntegrationInformation) {
            return <IntegrationInformation link={externalSrc} notificationSystem={notificationSystem} />
        } else {
            return <ExternalInformation link={externalSrc} hasContent={hasContent} widgetSettings={widgetSettings} isFullscreen={isFullscreen} />
        }
    }

    useEffect(() => {
        if (!externalSrc) {
            const newLink = getLinkUrl(widgetSettings.matchLinkText, attachedLinks);
            setExternalSrc(newLink);
        }
    }, [attachedLinks]);

    return (
        <Section
            widget={widget}
            addSectionClassName="flex-0-0-auto"
            toggleType={toggleType}
            toggleState={evaluatedToggleState}
            onFullscreenToggle={onFullscreenChange}
            fullscreenMode={fullscreenMode}
            headerLocKey={alarmT(headerLocKey || 'EXTERNAL_INFORMATION_SECTION')}
            bodyClassName="flex-row-wrap bg-c-white-primary"
            headerLocOptions={{ ns: constants.NS_MAP }}
            childrenLocOptions={{ ns: constants.NS_MAP }}
        >
            <div className={`${isIntegrationInformation ? "p-05em" : ""} w-100p`}>{getContent()}</div>
        </Section >
    );
}

export default function ExternalInformationWrapper(props: ExternalInformationProps) {
    return (
        <WidgetErrorBoundary>
            <ExternalInformationWidget {...props} />
        </WidgetErrorBoundary>
    );
};
