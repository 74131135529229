import { isToday, isYesterday, parseISO } from 'date-fns';
import * as Logger from 'js-logger';
import React from 'react';
import ajax from './ajax';
import authentication from './authentication';
import { Event } from './event';
import localization from './localization';
import TwilioStore from './stores/twillio_store';
import websocket from './websocket';

const constants = require('../json/constants');
const endpoints = require('../json/endpoints');

/**
 * TODO: refactor this to not include any stores
 */
export class Utils extends Event {
  /**
   * trigger sync error instead of server error
   */
  isObjError<T, K>(
    previous: K,
    err_server_event: string,
    err_sync_event: string,
    ok_sync_event: string,
    err: string | null,
    xhr: XMLHttpRequest,
    data: T
  ) {
    let _err = err || !data;
    if (_err) {
      if (previous) {
        this.trigger(err_sync_event, _err, xhr.status);
      } else {
        this.trigger(err_server_event, _err, xhr.status);
      }
      return true;
    }

    this.trigger(ok_sync_event);
    return false;
  }

  /**
   * opposite to isError
   */
  isNotObjError<T, K>(
    previous: K,
    err_server_event: string,
    err_sync_event: string,
    ok_sync_event: string,
    err: string | null,
    xhr: XMLHttpRequest,
    data: T
  ) {
    return !this.isObjError(
      previous,
      err_server_event,
      err_sync_event,
      ok_sync_event,
      err,
      xhr,
      data
    );
  }

  /**
   * trigger sync error instead of server error
   */
  isArrayError<T, K>(
    previous: K,
    err_server_event: string,
    err_sync_event: string,
    ok_sync_event: string,
    err: string | null,
    xhr: XMLHttpRequest,
    data: T
  ) {
    let _err = err || !data;
    if (_err) {
      if (Array.isArray(previous) && previous.length) {
        this.trigger(err_sync_event, _err, xhr);
      } else {
        this.trigger(err_server_event, _err, xhr);
      }
      return true;
    }

    this.trigger(ok_sync_event);
    return false;
  }

  /**
   * opposite to isError
   */
  isNotArrayError<T, P>(
    previous: P,
    err_server_event: string,
    err_sync_event: string,
    ok_sync_event: string,
    err: string | null,
    xhr: XMLHttpRequest,
    data: T
  ) {
    return !this.isArrayError(
      previous,
      err_server_event,
      err_sync_event,
      ok_sync_event,
      err,
      xhr,
      data
    );
  }

  render_table_obj(obj: any): any {
    if (obj !== null && typeof obj === 'object') {
      let keys = Object.keys(obj);
      return (
        <table className="info-table">
          <tbody>
            {keys.map(key => {
              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{this.render_table_obj(obj[key])}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else if (obj === null) {
      return 'null';
    } else if (obj === true) {
      return 'true';
    } else if (obj === false) {
      return 'false';
    }

    return obj;
  }

  print_obj(obj: {} | string | null | any) {
    if (obj !== null && typeof obj === 'object') {
      const result = [' { '];
      const keys = Object.keys(obj);
      const key_length = keys.length;

      keys.forEach((key, index) => {
        result.push(' "' + key + '" : ');
        result.push(this.print_obj(obj[key]));
        if (index < key_length - 1) {
          result.push(' ,');
        }
      });
      result.push(' } ');
      return result.join('');
    } else if (typeof obj === 'string') {
      return '"' + obj + '"';
    } else if (obj === null) {
      return 'null';
    }
    return obj.toString ? obj.toString() : obj;
  }

  number_is_NaN(x: number | string) {
    return x !== x;
  }

  /**
   * check that passed parameter is finite number
   */
  is_finite(val: string | number) {
    return !(
      typeof val !== 'number' ||
      Utils.prototype.number_is_NaN(val) ||
      val === Infinity ||
      val === -Infinity
    );
  }

  /**
   * get sign of number
   */
  sign(x: number | string) {
    return typeof x === 'number' ? (x ? (x < 0 ? -1 : 1) : x === x ? 0 : NaN) : NaN;
  }

  /**
   * find object in array of objects by key and value
   */
  find_obj<T, K>(objects: T, key: string | string[], value: string | undefined | null, soft?: K) {
    let found_obj: any = null;
    if (objects && Array.isArray(objects)) {
      objects.some(obj => {
        const findKey = key as any;

        if (obj[findKey]) {
          if (
            // eslint-disable-next-line
            (value !== undefined && (soft ? obj[findKey] == value : obj[findKey] === value)) ||
            (soft && value === undefined && obj[findKey] !== undefined)
          ) {
            found_obj = obj;
          }
        }
        return !!found_obj;
      });
    }
    return found_obj;
  }

  /**
   * find parent node by selector
   */
  find_parent(
    startElement: any | HTMLElement | Document | DocumentFragment | Element | HTMLScriptElement,
    selector: string | any
  ): void | null | any {
    let parentNode: Element = startElement.parentNode;
    if (parentNode) {
      if (parentNode.matches(selector)) {
        return parentNode;
      }
      return this.find_parent(parentNode, selector);
    }
    return null;
  }

  renderRef(childRef?: any) {
    if (childRef) {
      return { ref: childRef };
    }
    return {};
  }

  renderDisabled(disabled: boolean) {
    if (disabled) {
      return { disabled: true };
    }
    return {};
  }

  sortByOrder = (a: any, b: any) => {
    if (a.Order === b.Order) {
      return 0;
    }
    return a.Order < b.Order ? -1 : 1;
  };

  dateToShow = (serverAlarmTime: string, date: string) => {
    if (isToday(parseISO(serverAlarmTime))) {
      return localization.t('TODAY');
    } else if (isYesterday(parseISO(serverAlarmTime))) {
      return localization.t('YESTERDAY');
    }
    return date;
  };

  /**
   * extract hash query for template loading to prevent cashing
   */
  extractHash() {
    let script: HTMLScriptElement | null = document.querySelector('script[src*="bundle"]');
    if (script && script.src) {
      let index = script.src.indexOf('?');
      if (index > -1) {
        return script.src.substring(index + 1);
      }
    }
    return null;
  }

  registerWorkflow() {
    return new Promise<void>((resolve, reject) => {
      const phoneSelected =
        authentication.ls_get([authentication.getUserId(), constants.PHONE_SELECTED_KEY]) ===
        'true';
      const phoneNumber = authentication.ls_get([
        authentication.getUserId(),
        constants.PHONE_NUMBER_KEY
      ]);
      const isTwilio =
        authentication.ls_get([authentication.getUserId(), constants.TELEPHONY_KEY]) ===
        constants.TELEPHONY_CLOUD;
      const isNone =
        authentication.ls_get([authentication.getUserId(), constants.TELEPHONY_KEY]) ===
        constants.TELEPHONY_NONE;

      if (phoneSelected || isTwilio || isNone) {
        ajax.postByDesc(
          false,
          endpoints.REGISTER,
          {
            register: JSON.stringify({
              type: 'SkygdWebApp',
              telephone: phoneSelected ? phoneNumber : '',
              devicetoken: 'do',
              UsingTwilio: isTwilio || isNone
            })
          },
          (
            err: string | null,
            xhr: XMLHttpRequest,
            data: { data: { DeviceToken: string; UsingPush: boolean }; errors: any }
          ) => {
            if (err) {
              Logger.error(err);
              TwilioStore.setAsDenied();
              reject(err);
            } else {
              TwilioStore.setAsAllowed();
              authentication.setUserLSData(
                constants.LS_DEVICE_TOKEN,
                data.data[constants.LS_DEVICE_TOKEN]
              );
              if (authentication.isValidAdmin()) {
                websocket.initWebsocket(); // entrypoint for setting up websocket
              }
              resolve();
            }
          }
        );
      }
      // Otherwise no push notifications
    });
  }
}

export default new Utils();
