import cx from 'classnames';
import React from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';

export function dlv(obj, key, def = null, p) {
  p = 0;
  key = key.split ? key.split('.') : key;
  while (obj && p < key.length) obj = obj[key[p++]];
  return obj === undefined ? def : obj;
}

export default class VeoliaUserInfoSection extends React.Component {
  render() {
    const { alarmT, userDetails, alarmDetails, readonly, instance, widget } = this.props;
    return (
      <Section
        widget={widget}
        addBodyClassName="p-t0-l0-1"
        headerLocKey="USER_INFO"
        headerLocOptions={{ ns: constants.NS_ALARM }}
        childrenLocOptions={{ ns: constants.NS_ALARM }}
        headerContent={
          <div
            className="flex-row-wrap section-header"
            style={{ backgroundColor: (widget && widget.headerBackgroundColor) ? widget.headerBackgroundColor : '' }}
          >
            <div className="p-1em f-15 bold flex-1-1-0px">
              {alarmT('USER_INFO')} {dlv(userDetails, 'Customer.Name')}
            </div>
            <div className="flex-row p-05em flex-0-0-auto">
              <div className="flex-row">
                <div className="flex-col-around flex-0-0-auto">
                  <button
                    type="button"
                    className={cx('button-small-blue', { ['readonly-hidden']: readonly })}
                    onClick={this.props.onChangeUserClick}
                  >
                    {alarmT('CHANGE_OBJECT')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <div className="flex-row-wrap">
          <Section
            widget={widget}
            sectionClassName="flex-row-wrap"
            bodyClassName="flex-row-wrap"
            childrenLocOptions={{ ns: constants.NS_ALARM }}
          >
            {[
              {
                label: 'CUSTOMER_NUMBER',
                value: instance.linkifyValue(dlv(userDetails, 'Customer.Number')),
                skip: true
              },
              {
                label: 'NATIONAL_ID_NUMBER',
                value: instance.linkifyValue(dlv(userDetails, 'NationalIdNumber')),
                skip: true
              },
              {
                label: 'CONTRACT_NUMBER',
                value: instance.linkifyValue(dlv(userDetails, 'ContractNumber')),
                skip: true
              },
              { label: 'NAME', value: instance.linkifyValue(dlv(userDetails, 'Name')), skip: true },
              {
                label: 'CALLBACK_TELEPHONE_NUMBER',
                value: instance.linkifyValue(
                  dlv(alarmDetails, 'DynamicDetails.CallbackTelephoneNumber')
                ),
                skip: true
              },
              {
                label: 'KEY_PLACEMENT',
                value: instance.linkifyValue(dlv(userDetails, 'KeyPlacement')),
                skip: true
              }
            ]}
          </Section>
        </div>
      </Section>
    );
  }
}
