import React from 'react';

export class KeyboardShortcuts extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress);
  }

  onKeyPress = event => {
    if (this.props.isActive) {
      this.props.onEvent(event);
    }
  };

  render() {
    return this.props.children;
  }
}
