import React from 'react';
import { evalToggleState, hideWidget } from '../enums/VisibilityState';
import settingsStore from '../stores/settings_store';
import { Notes } from './components';
import { WidgetErrorBoundary } from '../components/errorBoundaries/widgetErrorBoundary';
class MedicalNotesWidget extends React.Component {
    shouldShowMedicalNotes = (settingsStore, alarmDetails) => {
        const allowMedicalNotes = settingsStore.getValueByKey('ALLOW_MEDICAL_NOTES');
        let showMedicalNotes = false;
        if (allowMedicalNotes) {
            let arr = eval(allowMedicalNotes);
            if (Array.isArray(arr)) {
                arr = arr.map(e => e.toString());
                if (arr.indexOf(alarmDetails.DynamicDetails.RespondentGroup) > -1) {
                    showMedicalNotes = true;
                }
            }
        }
        return showMedicalNotes;
    }

    render() {
        const { widget, userDetails, alarmDetails, alarmT, toggleType, toggleState, fullscreenMode } = this.props;

        const hasContent = true; //Always have content
        const evaluatedToggleState = evalToggleState(
          hasContent,
          toggleState,
          widget ? widget.widgetName : 'MedicalNotesSection',
          true
        );
        if (hideWidget(evaluatedToggleState)) return null;

        return this.shouldShowMedicalNotes(settingsStore, alarmDetails) ? (
            <Notes
                widget={widget}
                title="MEDICAL_NOTES"
                noteType="0"
                userId={userDetails.UserId}
                alarmId={alarmDetails.AlarmId}
                alarmT={alarmT}
                toggleType={toggleType}
                toggleState={evaluatedToggleState}
                fullscreenMode={fullscreenMode}
            />
        ) : null;
    }
}

export default class MedicalNotesSection extends React.Component {
    render() {
        return (
          <WidgetErrorBoundary>
              <MedicalNotesWidget {...this.props} />
          </WidgetErrorBoundary>
        );
    }
};
