import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import { Event } from '../event';
import { ActionModel, TemplateModel } from '../interfaces/backend_model';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

class TemplatesStore extends Event {
  templates: { [key: string]: TemplateModel };
  isTemplateOnly: boolean;
  currentTemplate: TemplateModel;
  currentTemplateType: string;

  constructor() {
    super();
    this.isTemplateOnly = false;
    this.getInitialData();
  }

  getInitialData() {
    this.templates = {};
  }

  getCurrentTemplate(): TemplateModel {
    return this.currentTemplate;
  }

  getCurrentTemplateType(): string {
    return this.currentTemplateType;
  }

  loadTemplate(template_type: string = 'default', template_source: string = 'desktop') {
    // Check if the same template
    if (this.currentTemplateType == template_type) { return }
    // Check if the template are already cached
    if (this.templates[template_type]) {
      this.currentTemplate = this.templates[template_type];
      this.currentTemplateType = template_type
      this.trigger(constants.TEMPLATE_CHANGE);
      return
    }
    ajax.getByDescWithData(
      true,
      endpoints.TEMPLATE_GET,
      {
        template_type,
        template_source
      },
      (err: string | null, xhr: XMLHttpRequest, data: TemplateModel) => {
        this.currentTemplate = data;
        this.templates[template_type] = data;
        this.currentTemplateType = template_type
        this.trigger(constants.TEMPLATE_CHANGE);
      }
    );
  }
}

const templatesStore = new TemplatesStore();

appDispatcher.register((payload: { action: ActionModel; source?: string }) => {
  let action = payload.action;

  switch (action.type) {
    case constants.LOGOUT_CLEAR:
      templatesStore.getInitialData();
      break;
    case constants.TEMPLATE_GET:
      templatesStore.loadTemplate(action.template_type, action.template_source);
      break;
  }
});

export default templatesStore;
