import settingsStore from '../settings_store';
import LogAPI from './api';

class ExternalLogStore {
  /**
   * Store log in Papertrail through our backend
   * @param log
   */
  log(log: string) {
    LogAPI.log(log)
      .then(() => {
        // console.log('Sent log \'' + log + '\'');
      })
      .catch(err => {
        console.error("Failed to send log '" + log + "' with error ", err);
      });
  }

  loggingEnabled() {
    return settingsStore.getValueByKey('EXTERNAL_LOGGING', 'boolean');
  }
  enhancedLoggingEnabled() {
    return settingsStore.getValueByKey('EXTERNAL_LOGGING_ENHANCED', 'boolean');
  }
}

export default ExternalLogStore;
