import { Widget } from 'development/js/interfaces/backend_model';
import React from 'react';
import ajax from '../../ajax';
import appDispatcher from '../../app_dispatcher';
import Section from '../../components/section';
import Spinner from '../../components/spinner';
import dateTime from '../../date_time';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import localization from '../../localization';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

const constants = require('../../../json/constants.json');
const endpoints = require('../../../json/endpoints.json');

const alarmT = localization.useNSt(constants.NS_ALARM);

interface InternalMessengerProps {
  alarmId: string;
  userId: string;
  responseCenterComment?: string;
  responseCenterCommentTimestamp?: string;
  responseCenterCommentAllowUpdate?: boolean;
  readonly?: boolean;
  widget: Widget;
  toggleType?: string;
  toggleState?: string;
  fullscreenMode?: boolean;
}

function InternalMessengerWidget(props: InternalMessengerProps) {
  const needFocus = React.useRef(false);
  const [mode, setMode] = React.useState(constants.VIEWABLE);
  const [responseCenterCommentError, setResponseCenterCommentError] = React.useState(null);
  const [pendingResponseCenterComment, setPendingResponseCenterComment] = React.useState(false);
  const [value, setValue] = React.useState('');

  const {
    responseCenterComment,
    responseCenterCommentTimestamp,
    userId,
    responseCenterCommentAllowUpdate,
    alarmId,
    readonly,
    widget,
    toggleType,
    toggleState,
    fullscreenMode,
  } = props;
  function toggleEditMode() {
    needFocus.current = true;

    setMode(constants.EDITABLE);
    setValue(responseCenterComment ? responseCenterComment : '');
  }

  function handleEditButtonsClick(e: any) {
    const element = e.target;

    switch (element.dataset.click) {
      case 'cancel':
        setMode(constants.VIEWABLE);
        break;
      case 'save':
        responseCenterCommentWorkflow();
        break;
    }
  }

  function closeResponseCenterCommentError() {
    setResponseCenterCommentError(null);
  }

  function responseCenterCommentWorkflow() {
    setPendingResponseCenterComment(true);

    ajax.postByDesc(
      false,
      endpoints.RESPONSE_CENTER_COMMENT,
      {
        user_id: userId,
        alarm_id: alarmId,
        comment: value
      },
      (err: any) => {
        setPendingResponseCenterComment(false);

        if (err) {
          setResponseCenterCommentError(err);
        } else {
          setMode(constants.VIEWABLE);

          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_USER_DETAILS,
            user_id: +userId,
            alarm_id: alarmId
          });
        }
      }
    );
  }

  function handleChange(e: React.FormEvent<HTMLTextAreaElement>) {
    const newValue = e.currentTarget.value;

    if (newValue.length <= 200) {
      setValue(newValue);
    }
  }

  let content = null;

  switch (mode) {
    case constants.VIEWABLE:
      content = (
        <div onClick={responseCenterCommentAllowUpdate && !readonly ? toggleEditMode : undefined}>
          {responseCenterComment ? (
            <div>{responseCenterComment}</div>
          ) : responseCenterCommentAllowUpdate ? (
            <div>{alarmT('CLICK_TO_ENTER_COMMENT')}</div>
          ) : null}
          {responseCenterCommentTimestamp ? (
            <div className="flex-row">
              <div className="flex-col-around">{alarmT('LAST_CHANGED')}:&nbsp;</div>
              <div className="flex-col-around">
                {dateTime.toLocaleDateTime(responseCenterCommentTimestamp)}
              </div>
            </div>
          ) : null}
        </div>
      );
      break;
    case constants.EDITABLE:
      content = (
        <div className="w-100p">
          <textarea
            style={{ width: 'calc( 100% - 6px )', resize: 'vertical' }}
            value={value}
            ref={element => {
              if (element && needFocus.current) {
                element.focus();
                needFocus.current = false;
              }
            }}
            onChange={handleChange}
            autoComplete="off"
          />
          <div className="flex-row-around w-100p" onClick={handleEditButtonsClick}>
            <div className="flex-col-around">
              <button className="button-small-secondary" type="button" data-click="cancel">
                {localization.t('CANCEL')}
              </button>
            </div>
            {responseCenterCommentError ? (
              <div className="flex-col-around flex-0-0-auto">
                <button
                  type="button"
                  className="button-small-red"
                  onClick={closeResponseCenterCommentError}
                >
                  {responseCenterCommentError}
                </button>
              </div>
            ) : (
              <div className="flex-row">
                <div className="flex-col-around flex-0-0-auto">
                  <button className="button-small-green" type="button" data-click="save">
                    {localization.t('SAVE')}
                  </button>
                </div>
                {pendingResponseCenterComment ? (
                  <div className="flex-col-around">
                    <Spinner width={1} height={1} widthUnits="em" heightUnits="em" />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      );
      break;
  }

  const hasContent = true;//Always have content by design either the user view or edit/Add
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "InternalMessengerSection", true);
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      addBodyClassName="p-05em"
      headerLocKey="INTERNAL_MESSAGE"
      headerLocOptions={{ ns: constants.NS_ALARM }}
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
    >
      {content}
    </Section>
  );
}

function InternalMessenger(props: InternalMessengerProps) {
  return (
    <WidgetErrorBoundary>
      <InternalMessengerWidget {...props} />
    </WidgetErrorBoundary>
  );
};

export default React.memo(InternalMessenger);
