import createReactClass from 'create-react-class';
import React from 'react';
import constants from '../../../json/constants.json';
import appDispatcher from '../../app_dispatcher';
import Spinner from '../../components/spinner';
import alarmActiveStore from '../../stores/alarms/alarm_active_store_actions';
import { isCommandUsed, setCommandsState } from '../../utilities/commandButtonStateManager';
import Utils from '../../utils';
import Items from './Items';

export default createReactClass({
  getInitialState() {
    this.userName = localStorage.getItem('user_id');

    return {
      highlighted: null,
      errorMessage: null,
      pendingItem: null,
      submitted: '',
      value: '',
      regExp: null,
      opened: false,
      opened_top: 0,
      opened_left: 0,
      overlay: false,
    };
  },

  componentDidMount() {
    alarmActiveStore.on(constants.ALARM_ADD_ACTION_ERROR, this._onError);
    alarmActiveStore.on(constants.ALARM_ADD_ACTION_SUCCESS, this._onSuccess);
    alarmActiveStore.on(constants.ALARM_ADD_REASON_ERROR, this._onError);
    alarmActiveStore.on(constants.ALARM_ADD_REASON_SUCCESS, this._onSuccess);
  },

  componentWillUnmount() {
    alarmActiveStore.off(constants.ALARM_ADD_ACTION_ERROR, this._onError);
    alarmActiveStore.off(constants.ALARM_ADD_ACTION_SUCCESS, this._onSuccess);
    alarmActiveStore.off(constants.ALARM_ADD_REASON_ERROR, this._onError);
    alarmActiveStore.off(constants.ALARM_ADD_REASON_SUCCESS, this._onSuccess);
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetStateOn && this.props.resetStateOn !== nextProps.resetStateOn) {
      this.setState(this.getInitialState());
    }
  },

  _onError(alarmCode, alarmId, errorText) {
    this.setState({
      pendingItem: null,
      overlay: false,
      opened: false,
      errorMessage: errorText
    });
  },

  _onSuccess() {
    this.setState(this.getInitialState());
  },

  toggleItems(e) {
    let target = e.currentTarget;
    e.stopPropagation();
    this.setState({
      ...this.getInitialState(),
      opened: !this.state.opened,
      opened_top: target.offsetTop + target.offsetHeight,
      opened_left: target.offsetLeft,
      errorMessage: null
    });
  },

  handleEsc() {
    this.setState(this.getInitialState());
  },

  asteriskString: '[\\w|\\W]{0,}',

  sendWorkflow(value, force) {
    if (value) {
      if (!isCommandUsed(this.userName, this.props.alarm_id, this.props.name)) {
        setCommandsState(this.userName, this.props.alarm_id, this.props.name);
      }
      let { items, free, actionType, alarm_id, user_id } = this.props;
      let isEqualItem = items.indexOf(value) >= 0;
      if (free || isEqualItem || force) {
        this.setState({ pendingItem: value, overlay: true, opened: false });

        appDispatcher.dispatch({
          action: {
            type: actionType,
            alarm_id,
            user_id,
            message: value
          }
        });
      }
    }
  },

  quote(value) {
    return value.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  },

  createRegExp(value) {
    return new RegExp(
      '^' + this.asteriskString + this.quote(value) + this.asteriskString + '$',
      'i'
    );
  },

  handleInputChange({ currentTarget }) {
    const { value } = currentTarget;
    let futureValue;

    // Some value submitted and press backspace (business logic: do not delete submitted)
    if (this.state.submitted && value.length < this.state.submitted.length) {
      futureValue = this.state.submitted;
      // If submitted (business logic: user can only add text to submitted
    } else if (this.state.submitted) {
      futureValue = this.state.submitted + value.replace(this.state.submitted, '');
      // Just normal typing
    } else {
      futureValue = value;
    }

    this.setState({
      value: futureValue,
      regExp: this.createRegExp(futureValue),
      highlighted: null
    });
  },

  handleBusinessLogic(value, submitted) {
    if (!this.state.submitted && this.props.free) {
      this.setState({
        value: value + ' ',
        regExp: this.createRegExp(value),
        highlighted: null,
        submitted
      });
    } else {
      this.sendWorkflow(value);
    }
  },

  handleSubmit(e, item) {
    e.preventDefault();
    e.stopPropagation();

    const value = item ? item : e.currentTarget.input.value;

    if (this.state.submitted || (this.items.props.filtered_items.length === 0 && this.props.free)) {
      this.sendWorkflow(value, true);
      return;
    }

    this.handleBusinessLogic(value, this.state.highlighted ? value : '');
  },

  handleClickSelect(value) {
    this.handleBusinessLogic(value, value);
    this.items.searchFocus();
  },

  onHighlightChange(idx) {
    this.setState({ highlighted: idx });
  },

  handleGlobalClick() {
    this.setState(this.getInitialState());
  },

  render() {
    let { name, items, childRef, free, withArrow = false } = this.props;
    let {
      pendingItem,
      errorMessage,
      regExp,
      value,
      opened,
      opened_top,
      opened_left,
      highlighted,
    } = this.state;
    
    const isUsed = isCommandUsed(this.userName, this.props.alarm_id, name) ? 'used-command' : ''; 

    return (
      <div className="p-rel flex-row" onClick={this.handleGlobalClick}>
        {errorMessage ? (
          <button
            className="form-btn-bg-red"
            type="button"
            {...Utils.renderRef(childRef)}
            onClick={this.toggleItems}
          >
            {errorMessage}
          </button>
        ) : (
          <button
            className={`button-small-blue ${isUsed}`}
            type="button"
            {...Utils.renderRef(childRef)}
            {...Utils.renderDisabled()}
            onClick={this.toggleItems}
          >
            {pendingItem ? pendingItem : name}
            {withArrow ? <span className="arrow-down" /> : null}
          </button>
        )}
        {pendingItem ? (
          <div className="flex-col-around">
            <Spinner width="1" height="1" widthUnits="em" heightUnits="em" />
          </div>
        ) : null}
        {opened ? (
          <Items
            ref={items => (this.items = items)}
            filtered_items={
              Array.isArray(items)
                ? items.filter(item => {
                    if (regExp) {
                      regExp.lastIndex = 0;
                      return regExp.test(item);
                    }
                    return true;
                  })
                : []
            }
            handleEsc={this.handleEsc}
            highlighted={highlighted}
            onHighlightChange={this.onHighlightChange}
            handleClickSelect={this.handleClickSelect}
            value={value}
            handleSubmit={this.handleSubmit}
            opened_top={opened_top}
            opened_left={opened_left}
            handleInputChange={this.handleInputChange}
            free={free}
          />
        ) : null}
      </div>
    );
  }
});
