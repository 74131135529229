/**
 * Check that phone number is username
 * @param numberOrUsername
 * @returns {boolean}
 */
export function isUsername(numberOrUsername = '') {
    return (numberOrUsername || '').toString().indexOf('TW') !== -1;
}

/**
 * Check that phone number is Sip user
 * @param numberOrUsername
 * @returns {boolean}
 */
export function isSipUsername(numberOrUsername = '') {
    // If numberOrUsername starts with either 'sip:' or 'sips:'
    return (numberOrUsername && numberOrUsername.match(/^sip[s]?\:/));
}

/**
 * Extract twilio username from client:tw123 from twilio callback
 * @param username
 * @returns {*}
 */
export function pruneUsername(username = '') {
    if (username.indexOf('client:') !== -1) {
        return username.split(':')[1];
    }
    return username;
}

/**
 * Extract Sip username from sip:username@adress.com from twilio callback
 * @param sipusername
 * @returns {*}
 */
export function pruneSipUsername(sipusername = '') {
    // Replace everything from the first '@' with an empty string
    return sipusername.replace(/@.*/s, '');
}

/**
 * Display a shortened number/caller id in the GUI
 * @param {string} caller - Name or number of the caller
 * @param {number} [limitLenghtOfCaller] - Possibility to limit the lenght of the caller
 * @returns {string}
 */
export function pruneCaller(caller = '', limitLenghtOfCaller?: number) {
    if (limitLenghtOfCaller) {
        caller = caller.substring(0, limitLenghtOfCaller);
    }
    if (isSipUsername(caller)) {
        return pruneSipUsername(caller);
    }
    return caller;
}
