import appDispatcher from '../app_dispatcher';
import { AlarmModel } from '../interfaces/backend_model';
import { SendAlarmCommand } from '../stores/alarms/alarm_active_store_actions';

const constants = require('../../json/constants.json');

function sendCommand(
  alarmId: string,
  commandId: number | string,
  commandArgs: string,
  commandMessage?: string,
  id?: number
) {
  appDispatcher.handleServerAction({
    type: constants.SEND_ALARM_COMMAND,
    alarmId,
    commandId,
    commandArgs,
    commandMessage,
    id
  } as SendAlarmCommand);
}

function loadAlarmInfo(alarm: AlarmModel) {
  appDispatcher.handleServerAction({
    type: constants.LOAD_SERVER_ALARM_DETAILS,
    alarm_id: alarm.AlarmId
  });
  appDispatcher.handleServerAction({
    type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
    alarm_id: alarm.AlarmId
  });
  appDispatcher.handleServerAction({
    type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
    alarm_id: alarm.AlarmId
  });

  if (alarm.UserId) {
    appDispatcher.handleServerAction({
      type: constants.LOAD_SERVER_USER_DETAILS,
      user_id: alarm.UserId,
      alarm_id: alarm.AlarmId
    });
    appDispatcher.handleServerAction({
      type: constants.LOAD_SERVER_USER_HISTORY,
      user_id: alarm.UserId,
      alarm_id: alarm.AlarmId,
      force: true
    });
  }

  if (alarm.Active) {
    appDispatcher.handleServerAction({
      type: constants.FETCH_ACTIVE_CALLS
    });
  }
}

export { loadAlarmInfo, sendCommand };

