import React, { ReactNode } from 'react';
import Popup from 'reactjs-popup';
import IconModalClose from './icons/icon_modal_close';
import classNames from 'classnames';

export const ModalWindow = ({
  title,
  header = null,
  body,
  footer,
  isOpen,
  onClose,
  closeOnDocumentClick = false,
  footerClassName,
  popupClassName,
  contentStyle = {},
  withCloseButton = true,
  closeOnEscape = true,
}: {
  title?: string;
  header?: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  footerClassName?: string;
  isOpen: boolean;
  onClose: () => void;
  closeOnDocumentClick?: boolean;
  popupClassName?: string;
  contentStyle?: React.CSSProperties;
  withCloseButton?: boolean;
  closeOnEscape?: boolean;
}) => {
  return (
    <Popup
      open={isOpen}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
      className={`modal-windows ${popupClassName}`}
      contentStyle={contentStyle}
      closeOnEscape={closeOnEscape}
    >
      <div className='modal-wrapper'>
        <div className={`${(title || header) ? 'flex-row-between' : 'flex-row-end'} p-t-b-05em modal-header`}>
          {title && ( <h3 className='modal-title'>{title}</h3> )}
          {header}
          {withCloseButton && (
            <button
              className='modal-close-x'
              autoFocus={true}
              onClick={onClose}
              style={{ backgroundColor: 'transparent' }}
            >
            <IconModalClose />
          </button>
          )}
        </div>
        <div className='popup-content-wrapper'>
          {body}
        </div>
        {footer && <div className={classNames({
          'modal-footer': true,
          [footerClassName || '']: !!footerClassName,
        })}>{footer}</div>}
      </div>
    </Popup>
  );
}
