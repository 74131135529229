import Logger from 'js-logger';
import { logToPapertrail } from '../actions/externalLogging';

export const lazyRetry = function (componentImport: any, name: string): any {
  return new Promise((resolve, reject) => {
    const storageKey = `retry-${name}-refreshed`;
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || 'false'
    );
    // try to import the component
    componentImport().then((component: any) => {
      window.sessionStorage.setItem(storageKey, 'false'); // success so reset the refresh
      Logger.info(`Successfully loaded ${name}`);
      resolve(component);
    }).catch((error: any) => {
      logToPapertrail(`Failed to load ${name}.`);
      logToPapertrail(JSON.stringify(error));
      if (!hasRefreshed) { // not been refreshed yet
        Logger.info(`Failed to load ${name} - reloading page`);
        window.sessionStorage.setItem(storageKey, 'true'); // we are now going to refresh
        // alert('We are sorry - The window needs to be reloaded');
        // return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};
