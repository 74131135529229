import React from 'react';
import { AlarmDetailsModel } from '../../../js/interfaces/backend_model';
import localization from '../../../js/localization';
import TelephoneNumber from '../../components/telephone_number';
import { dlv } from './VeoliaUserInfoSection';

export const telephone_regex = /^\+[1-9]{1}[0-9]{5,14}$/;
export const sip_regex = /^sip(s)?:(\+?[\w_\-\.]+)@([\w_\-\.]+)\.([a-zA-Z]{2,5})$/;

const constants = require('../../../json/constants.json');

interface Props {
  alarmT: any;
  alarmDetails: AlarmDetailsModel;
  template: string;
  type: any;
  instance: any;
}
export default class CallType extends React.Component<Props> {

  /**
   * Get type of call
   * Returns a bool if the user should be able to click (interact with the call) 
   * Returns a span that informs the user about the type of call
   * @returns {boolean, span}
   */
  getType(shouldPhoneNumberBeVisible: boolean) {
    const { alarmT, alarmDetails, template, type } = this.props;
    const commonT = localization.useNSt(constants.NS_DEFAULT);

    // If call is established for current alarm and Curena template
    if (this.isCurenaTemplate(template) && alarmDetails?.DynamicDetails?.CallConnected) {
      return [false, <span key='CALL_CONNECTED'>{alarmT('CALL_CONNECTED')}&nbsp;</span>];
    } else if (alarmDetails?.DynamicDetails?.CallConnected) { // If call is established for current alarm
      return [false, <span key='TWILIO_INFO_CALL_STARTED'>{commonT('TWILIO_INFO_CALL_STARTED')}&nbsp;</span>];
    } else if (alarmDetails?.DynamicDetails?.CallOnHold) { // If call is on hold for current alarm
      return [false, <span key='TWILIO_INFO_CALL_HOLDED'>{commonT('TWILIO_INFO_CALL_HOLDED')}&nbsp;</span>];
    }

    if (!shouldPhoneNumberBeVisible && type !== ('conference' || 'queue' || 'none')) {
      // There is no call waiting to be answered and the callback number is invalid
      return [shouldPhoneNumberBeVisible, <span key='CALLBACK_TELEPHONE_NUMBER_INVALID'>{alarmT('CALLBACK_TELEPHONE_NUMBER_INVALID')}&nbsp;</span>];
    }

    switch (type) {
      case 'callback': // Allow the user to make a callback to the number connected with the alarm
        return [shouldPhoneNumberBeVisible, <span key='CALLBACK'>{alarmT('CALLBACK')}&nbsp;</span>];
      case 'conference':
      case 'queue': // Someone is calling us, allow the user to answer the call
        return [true, <span key='INCOMING_CALL_FROM'>{alarmT('INCOMING_CALL_FROM')}&nbsp;</span>];
      case 'incoming': // "incoming" is a bit missleading, in practise we are waiting for an incomming call to be established
        return [shouldPhoneNumberBeVisible, <span key='INCOMING_CALL_WAITING'>{alarmT('INCOMING_CALL_WAITING')}&nbsp;</span>];
      default: // Clean up scenario
        return [shouldPhoneNumberBeVisible, <span key='NO_VOICE_CALL'>{alarmT('NO_VOICE_CALL')}&nbsp;</span>];
    }
  }

  isCurenaTemplate(template: string) {
    return template === 'curena' || template === 'curena-callcenter' || template === 'curena-alarm';
  }

  render() {
    const { alarmDetails, type, instance } = this.props;
    const number = dlv(alarmDetails, 'DynamicDetails.CallbackTelephoneNumber');

    let shouldPhoneNumberBeVisible = telephone_regex.test(number) || sip_regex.test(number);

    let [canBeClicked, typeSpan] = this.getType(shouldPhoneNumberBeVisible);

    const typeDiv = <div className="flex-0-0-auto flex-col-around">{typeSpan}</div>;
    return (
      <div className="flex-row-wrap">
        {!canBeClicked && typeDiv}
        <div className="flex-0-0-auto flex-col-around hover-transparency">
          {canBeClicked && (
            <TelephoneNumber
              isQueueType={type === 'queue' || type === 'conference'}
              alarmId={alarmDetails.AlarmId}
              childRef={(obj: any) => (instance.callbackRHref = obj)}
              value={shouldPhoneNumberBeVisible ? number : ''}
              showMessageButton={false}
              typeDiv={typeDiv}
            />
          )}
        </div>
      </div>
    );
  }
}
