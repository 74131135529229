import { isAfter, isEqual, parseISO } from 'date-fns';
import React from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import dateTime from '../../date_time';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

const CoordinateTypeEnum = {
  Home: 'Home'
};

class LatestKnownPositionWidget extends React.Component {
  render() {
    const { alarmDetails, coordinates, widget, roundDigit, toggleType, toggleState, fullscreenMode } = this.props;
    let latestCoordinate;

    if (coordinates && coordinates.length) {
      // Business logic regarding the task SR-4347
      let filteredCoordinates = coordinates.filter(el => el.Type !== CoordinateTypeEnum.Home);
      const sortedCoordinates = filteredCoordinates.sort((a, b) => {
        if (isEqual(parseISO(a.ServerTime), parseISO(b.ServerTime))) {
          return 0;
        }
        if (isAfter(parseISO(a.ServerTime), parseISO(b.ServerTime))) {
          return 1;
        }
        return -1;
      });

      latestCoordinate = sortedCoordinates && sortedCoordinates[sortedCoordinates.length - 1];
      if (latestCoordinate) {
        latestCoordinate.Longitude = roundDigit
          ? parseFloat(latestCoordinate.Longitude.toFixed(parseInt(roundDigit)))
          : latestCoordinate.Longitude;
        latestCoordinate.Latitude = roundDigit
          ? parseFloat(latestCoordinate.Latitude.toFixed(parseInt(roundDigit)))
          : latestCoordinate.Latitude;
      }
    }

    const hasContent = latestCoordinate ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      toggleState,
      widget ? widget.widgetName : 'LatestKnownPositionSection'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    return (
      <Section
        widget={widget}
        addSectionClassName="flex-0-0-auto"
        toggleType={toggleType}
        toggleState={evaluatedToggleState}
        fullscreenMode={fullscreenMode}
        headerLocKey="LATEST_KNOWN_POSITION"
        bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
        headerLocOptions={{ ns: constants.NS_MAP }}
        childrenLocOptions={{ ns: constants.NS_MAP }}
      >
        {latestCoordinate ? [
          { label: 'LATITUDE', value: latestCoordinate.Latitude },
          { label: 'LONGITUDE', value: latestCoordinate.Longitude },
          {
            label: 'TIMESTAMP',
            value: dateTime.renderUTCTimeDifference(
              alarmDetails.ServerAlarmTime,
              latestCoordinate.ServerTime
            )
          },
          {
            label: 'REPORTED_TIME',
            value: dateTime.toLocaleDateTime(latestCoordinate.ServerTime)
          }
        ] : null}
      </Section>
    );
  }
}

export default class LatestKnownPositionSection extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <LatestKnownPositionWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
