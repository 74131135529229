import { observable } from 'mobx';
import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import authStore from '../authentication';
import { Event } from '../event';
import { MeModel, SettingModel } from '../interfaces/backend_model';
import { adaptToUnicodeTokens } from '../utilities/time_format';
import { pruneCaller } from '../utilities/user';
import Utils from '../utils';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

export class SettingsStore extends Event {
  settings: SettingModel[] | any;
  me: MeModel | any;
  sendReportHandler: number;
  @observable userApproved = false;
  @observable initialized = false;
  constructor() {
    super();
    this.settings = [];
    this.me = null;
    this.sendReportHandler = 0;
  }

  getInitialData() {
    /**
     * store list settings
     */
    this.settings = [];
    this.userApproved = false;
    this.initialized = false;
    /**
     * store logged in information
     */
    this.me = null;
  }

  /**
   * returns logged in information
   */
  getMe() {
    return this.me;
  }

  getSettingInterval(setting_key: string) {
    let setting = Utils.find_obj(this.settings, 'key', setting_key);
    if (setting) {
      let setting_interval = parseInt(setting.defaultvalue, 10);
      if (Utils.is_finite(setting_interval)) {
        return setting_interval * 1000;
      }
    }
    return null;
  }

  getSettings() {
    return this.settings;
  }

  /**
   * Do not use this method directly. Prefer getValueByKey instead.
   * @deprecated
   *
   * @param {string} key
   * @returns
   */
  getKey(key: string) {
    if (this.getSettings().length) {
      return this.getSettings().find((item: any) => item.key === key);
    }
    return null;
  }

  /**
   * Used to get setting value by key.
   * Pay attention, that settings are available only after fetching from server
   * Probably you need to subscribe to SettingsStore on change method like:
   * `SettingsStore.on(constants.SETTINGS_CHANGE, this.myHandler.bind(this));`
   *
   * @param {*} key key to lookup
   * @param {string} [as='string' | 'number' | 'boolean'] return type
   * @returns null or key by specified type
   */
  getValueByKey(key?: string, as = 'string') {
    if (!key) {
      return null;
    }

    const result = (this.getKey(key) || {}).defaultvalue;
    // strict check for undefined. since valid value is '' (empty string)
    if (result === undefined) {
      return null;
    }

    switch (as) {
      case 'string':
        return result;
      case 'number':
        return parseInt(result, 10);
      case 'boolean':
        return result === 'True' || result === 'true';
      default:
        return result;
    }
  }

  setValueByKey(key: string, value: string | boolean | number) {
    const setting = this.settings.find((item: any) => item.key === key);
    setting.defaultvalue = value;
  }

  isLoadSettingsSuccess(err: string | null, xhr: XMLHttpRequest, data: SettingModel[]) {
    return Utils.isNotArrayError(
      this.settings,
      constants.SETTINGS_SERVER_ERROR,
      constants.SETTINGS_SYNC_ERROR,
      constants.SETTINGS_SYNC_SUCCESS,
      err,
      xhr,
      data
    );
  }

  /**
   * load settings
   */
  loadSettings() {
    ajax.getByDesc(
      false,
      endpoints.SETTINGS,
      (err: string | null, xhr: XMLHttpRequest, data: SettingModel[]) => {
        if (this.isLoadSettingsSuccess(err, xhr, data)) {
          this.settings = data;
          this.trigger(constants.SETTINGS_CHANGE);
        }
      }
    );
  }

  /**
   * load logged in information
   */
  loadMe() {
    ajax.getByDesc(
      false,
      endpoints.ME,
      (err: string | null, xhr: XMLHttpRequest, data: MeModel) => {
        if (err) {
          this.trigger(constants.ME_SERVER_ERROR, err, xhr);
        } else if (!data) {
          this.trigger(constants.ME_SERVER_ERROR, !data, xhr);
        } else {
          this.me = data;
          this.trigger(constants.ME_CHANGE);
        }
      }
    );
  }

  sendReport() {
    if (authStore.isValidAdmin()) {
      ajax.postByDesc(false, endpoints.REPORT, {}, (err: string | null, xhr: XMLHttpRequest) => {
        if (err) {
          this.subscribeForSendReport(30000);
          this.trigger(constants.PENDING_REPORT_SERVER_ERROR, err, xhr);
        } else {
          this.subscribeForSendReport();
          this.trigger(constants.PENDING_REPORT_SERVER_SUCCESS);
        }
      });
    }
    else {
      console.log('Not sending any report as not logged in with an existing admin');
    }
  }

  subscribeForSendReport(setting_interval?: number) {
    let _setting_interval = setting_interval || this.getSettingInterval('REPORT');
    if (_setting_interval !== null) {
      this.unSubscribeForSendReport();
      this.sendReportHandler = window.setInterval(this.sendReport.bind(this), _setting_interval);
    }
  }

  unSubscribeForSendReport() {
    clearInterval(this.sendReportHandler);
  }

  getDateFormat() {
    return adaptToUnicodeTokens(this.getValueByKey('DATE_FORMAT')) || 'yyyy-MM-dd';
  }

  registerTelephoneNumber(telephone_number: string, alarm_id: string, contactId?: number) {
    const contact_id = typeof contactId === 'number' ? `?contactId=${contactId}` : '';

    ajax.postByDesc(
      false,
      endpoints.REGISTER_TELEPHONE,
      { telephone_number, alarm_id, contact_id },
      (err: string | null, xhr: XMLHttpRequest, data: any) => {
        if (err) {
          this.trigger(constants.REGISTER_TELEPHONE_SERVER_ERROR, err, xhr);
        } else {
          this.trigger(constants.REGISTER_TELEPHONE_SERVER_SUCCESS);
        }
      }
    );
  }
}

interface PayloadModel {
  sorce: string;
  action: {
    alarm_id: string;
    type: string;
    user_id: string;
    telephone_number?: string;
    contactId?: number;
  };
}

const settingsStore = new SettingsStore();
appDispatcher.register((payload: PayloadModel) => {
  let action = payload.action;

  switch (action.type) {
    case constants.LOAD_SERVER_SETTINGS:
      settingsStore.loadSettings();
      break;
    case constants.PING_REPORT:
      settingsStore.sendReport();
      break;
    case constants.START_PENDING_REPORT:
      settingsStore.subscribeForSendReport();
      break;
    case constants.STOP_PENDING_REPORT:
      settingsStore.unSubscribeForSendReport();
      break;
    case constants.LOAD_SERVER_ME:
      settingsStore.loadMe();
      break;
    case constants.LOGOUT_CLEAR:
      settingsStore.getInitialData();
      break;
    case constants.REGISTER_TELEPHONE_NUMBER:
      if (action.telephone_number && action.alarm_id) {
        // The register endpoint only accepts numbers that are max 30 characters
        // Thougth it is better to chop of the number than getting an error from the request
        // Especially since it seems to only log the number in the BE (alarmFacade.addOperatorInfo())
        const prunedNumber = pruneCaller(action.telephone_number, 30);
        settingsStore.registerTelephoneNumber(
          prunedNumber,
          action.alarm_id,
          action.contactId
        );
      }
      break;
  }
});

export default settingsStore;
