import React from 'react';
import { Link } from 'react-router';
import localization from '../localization';
import settingsStore from '../stores/settings_store';
import IconPen from './icons/icon_pen';

const PlanningButtonRenderer = (rowData: { data: { AlarmId: string } }) => {
  const alarmTemplate = settingsStore.getValueByKey('PLANNING_VIEW_ALARM_TEMPLATE', 'string') || 'defaultPlanning';
  return (
    <div>
      <span
        className="table-btn extension-button"
      >
        <Link
          to={`dashboard/template/${alarmTemplate}/${(rowData.data.AlarmId)}/edit`}
        >
          <IconPen /> {localization.t("PLANNING_VIEW_EDIT_BUTTON")}
        </Link>
      </span>
    </div>
  )
};

export default PlanningButtonRenderer;
