import appDispatcher from '../../app_dispatcher';
import authentication from './../../authentication';
import ExternalLogStore from './externalLogStore';

const externalLogStore = new ExternalLogStore();

export interface SendExternalLog {
  type: 'SEND_EXTERNAL_LOG';
  logMessage: string;
}
export type Actions =
  | SendExternalLog

appDispatcher.register((payload: { source: string; action: Actions }) => {
  let action = payload.action;

  switch (action.type) {
    case 'SEND_EXTERNAL_LOG':
      if (externalLogStore?.loggingEnabled()) {
        const userId = authentication.getUserId();
        let logContext = '';
        if (userId) {
          logContext = 'CentralUI (' + userId + '): ';
        }
        else {
          logContext = 'CentralUI (N/A): ';
        }
        externalLogStore.log(logContext + action.logMessage);
      }
      break;
  }
});

export default externalLogStore;
