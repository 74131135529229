import * as PropTypes from 'prop-types';
import React from 'react';
import appHistory from '../app_history';
import authentication from '../authentication';
import localization from '../localization';

const constants = require('../../json/constants.json');

interface Props {
  location: {
    query: {
      expires_in: number;
      telephone: string;
      telephony: string;
      token: string;
      token_type: string;
      url: string;
      username: string;
    };
  };
}

class AutoRegister extends React.Component<Props> {
  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    const {
      expires_in,
      telephone,
      telephony,
      token,
      token_type,
      url,
      username
    } = this.props.location.query;

    authentication.setUserId(username);
    authentication.ls_set([username, constants.TELEPHONY_KEY], telephony);
    authentication.ls_set(
      [username, constants.EXPIRES_INITIAL_KEY],
      Date.now() + expires_in * 1000
    );
    authentication.ls_set([username, constants.ACCESS_TOKEN_KEY], token);
    authentication.ls_set([username, constants.EXPIRES_IN_KEY], expires_in);
    authentication.ls_set([username, constants.TOKEN_TYPE_KEY], token_type);

    if (telephone) {
      authentication.ls_set([username, constants.PHONE_NUMBER_KEY], '+' + telephone);
      authentication.ls_set([username, constants.PHONE_SELECTED_KEY], 'true');
    } else {
      authentication.ls_set([username, constants.PHONE_SELECTED_KEY], 'false');
    }

    localization.changeLanguage(localization.getLanguage()); // save selected language for current user
    localization.rebuildCache();

    if (url) {
      appHistory.replace(url);
    } else {
      appHistory.replace(constants.PATH_GROUPS);
    }
  }

  render() {
    return null;
  }
}

export default AutoRegister;
