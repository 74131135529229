import React from 'react';

export default class IconUser extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-user"
        x="0px"
        y="0px"
        width="70px"
        height="70px"
        viewBox="0 0 70 70"
      >
        <g>
          <g transform="translate(-401.000000, -135.000000)">
            <g transform="translate(401.000000, 135.171631)">
              <path d="M49.8,14.8c0,8.3-6.7,14.9-14.9,14.9S19.9,23,19.9,14.8S26.6-0.2,34.9-0.2S49.8,6.5,49.8,14.8" />
              <g transform="translate(0.000000, 6.246002)">
                <defs>
                  <filter filterUnits="userSpaceOnUse" x="11" y="23.9" width="47.9" height="39.7">
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="11"
                  y="23.9"
                  width="47.9"
                  height="39.7"
                  id="mask-2"
                >
                  <g>
                    <polygon points="59,63.6 59,23.9 11,23.9 11,63.6" />
                  </g>
                </mask>
                <path
                  d={`M50.9,23.9c-0.2,0.2-0.2,0.3-0.3,0.5c-2.1,2.1-4.4,3.7-7.1,4.8c-2.8,1.2-5.7,1.8-8.7,1.8
                s-5.9-0.6-8.7-1.8c-2.7-1.1-5.1-2.7-7.1-4.8c-0.2-0.2,0.1-0.3,0-0.5c-5.1,3.8-7.9,9.8-7.9,16.6v23.1H59V40.5
                C59,33.7,56,27.7,50.9,23.9`}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
