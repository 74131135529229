import React from 'react';

export default class IconCheckMark extends React.Component {
  render() {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13.125C3.61726 13.125 0.875 10.3827 0.875 7C0.875 3.61726 3.61726 0.875 7 0.875C10.3827 0.875 13.125 3.61726 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#6C6C6C"/>
        <path d="M9.59845 4.34846C9.59227 4.35465 9.58645 4.36121 9.58105 4.36809L6.54272 8.23965L4.71089 6.40782C4.45461 6.15154 4.0391 6.15154 3.78281 6.40782C3.52653 6.6641 3.52653 7.07962 3.78281 7.3359L6.09846 9.65154C6.35474 9.90782 6.77025 9.90782 7.02653 9.65154C7.03224 9.64583 7.03763 9.63981 7.04267 9.63351L10.5358 5.26704C10.7828 5.01009 10.7797 4.6016 10.5265 4.34846C10.2703 4.09218 9.85474 4.09218 9.59845 4.34846Z" fill="#6C6C6C"/>
      </svg>
    );
  }
}
