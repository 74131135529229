import React from 'react';
// @ts-ignore
import axios from 'axios';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import localization from '../localization';
import "../styles/forcePassResetModal.css";
import { validatePassword } from '../utilities/validatePassword';


export interface PasswordComplexity {
  ForbiddenChars: string;
  MinLength: number;
  MinLowerCase: number;
  MinNumbers: number;
  MinUpperCase: number;
}

interface PasswordResponse {
  PasswordResetToken: string;
  passwordConstraints: PasswordComplexity;
}

const constants = require('../../json/constants.json');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '460px',
    minWidth: '325px',
    boxShadow: '0px 0px 10px rgba(45, 57, 79, 0.14)',
  },
  overlay: {
    backgroundColor: 'rgba(26, 27, 29, .4)',
  },
};

export const ForcePassResetModal = (
  {
    isOpen,
    token,
    onSubmit,
  }: {
    isOpen: boolean;
    token?: string;
    onSubmit: () => void;
  }) => {
  const [userPass, setUserPass] = useState("");
  const [userRepeatPass, setUserRepeatPass] = useState("");
  const loginT = localization.useNSt(constants.NS_LOGIN);
  const [passwordResponse, setPasswordResponse] = useState<PasswordResponse>();
  const [errors, setErrors] = useState<{ userPass?: string, userRepeatPass?: string } | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const errorsTranslations = {
    "MinLength": loginT("PASSWORD_VALIDATION_MIN_LENGTH"),
    "MinNumbers": loginT("PASSWORD_VALIDATION_MIN_NUMBERS"),
    "MinLowerCase": loginT("PASSWORD_VALIDATION_MIN_LOWER"),
    "MinUpperCase": loginT("PASSWORD_VALIDATION_MIN_UPPER"),
    "ForbiddenChars": loginT("PASSWORD_VALIDATION_FORBIDDEN_CHARS"),
    "NotMatch": loginT("PASSWORD_VALIDATION_NOT_MATCH"),
  }

  const fetchTokenNConstrain = async () => {
    const result = await axios.get("api/me/forceresetpassword", {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    setPasswordResponse(result.data.data);
    setIsLoading(false);
  }

  const onInternalSubmit = async () => {
    try {
      const dataToSend = {
        PasswordResetToken: passwordResponse?.PasswordResetToken,
        Password: userPass,
      }

      await axios.post(
        "api/me/resetpassword",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      onSubmit();
    } catch (error: any) {

    }
  }

  const validateInputs = () => {
    if (userPass !== userRepeatPass) {
      setErrors({ userRepeatPass: "NotMatch" });
      return;
    }

    const invalidPass = validatePassword(userPass, passwordResponse?.passwordConstraints!);
    if (typeof invalidPass === "boolean") {
      setErrors(undefined);
      onInternalSubmit();
      return;
    }
    setErrors({ userPass: invalidPass });
  }

  const renderRequirementList = () => {
    if (passwordResponse?.passwordConstraints) {
      return (
        <ul className="list-wrapper">
          {Object.keys(passwordResponse.passwordConstraints).map(key => {
            const val = passwordResponse?.passwordConstraints[key];
            if (typeof val === "number" && val !== 0 || typeof val === "string" && val.length > 0) {
              return (
                <li className="list-item">
                  {
                    loginT(`PASSWORD_REQUIREMENT_${key}`).replace("{0}", `${val}`)
                  }
                </li>
              )
            }
            return null;
          })}
        </ul>
      );
    }
    return null;
  }

  useEffect(() => {
    if (isOpen) {
      fetchTokenNConstrain();
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={modalStyles}
      >
        <h2>{loginT("RESET_MODAL_HEADER")}</h2>
        <hr />
        <p className="label-text">{loginT("RESET_MODAL_REQUIREMENTS")}</p>
        {renderRequirementList()}
        <form>
          <label className="label-text">{loginT("NEW_PASSWORD")}</label>
          <input
            type="password"
            value={userPass}
            onChange={(event) => { setUserPass(event.target.value) }}
            placeholder={loginT("NEW_PASSWORD_PLACEHOLDER")}
            className="form-input"
          />
          {errors?.userPass &&
            <p
              className="validation-issue"
            >
              {errorsTranslations[errors.userPass].replace("{0}", passwordResponse?.passwordConstraints[errors.userPass])}
            </p>
          }
          <br />
          <label className="label-text">{loginT("CONFIRM_PASSWORD")}</label>
          <input
            type="password"
            value={userRepeatPass}
            onChange={(event) => { setUserRepeatPass(event.target.value) }}
            placeholder={loginT("CONFIRM_PASSWORD_PLACEHOLDER")}
            className="form-input"
          />
          {errors?.userRepeatPass &&
            <p
              className="validation-issue"
            >
              {errorsTranslations[errors.userRepeatPass]}
            </p>
          }
          <br />
          <br />
          <div className="footer-wrapper">
            <button
              onClick={(event) => {
                event.preventDefault();
                validateInputs();
              }}
              className="form-button-primary"
              disabled={isLoading}
            >
              {loginT("CHANGE_PASSWORD")}
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}
