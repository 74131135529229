import Checkbox from 'material-ui/Checkbox';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router';
import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import localization from '../localization';
import groupsStore from '../stores/groups_store';
import IconDoor from './icon_door';
import PopupError from './popups/popup_error';
import SpinnerOverlay from './spinner_overlay';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

interface State {
  overlay: boolean;
  setGroupsError: string | boolean;
  respondent_groups:
  | [
    {
      RespondentGroupId: number;
      Description: string;
      Forced: boolean;
      Selected: boolean;
      ActiveRespondents: number;
      TotalRespondents: number;
    }
  ]
  | any;
  selected_respondent_groups: object;
  invalidate: boolean;
}

interface Props { }

export default class GroupsList extends React.Component<Props, State> {
  groupsRForm: HTMLFormElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      overlay: false,
      setGroupsError: false,
      respondent_groups: groupsStore.getGroups(),
      selected_respondent_groups: {},
      invalidate: false
    };
  }

  componentDidMount() {
    groupsStore.on(constants.RESPONDENT_GROUPS_CHANGE, this._onChange);
    appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_RESPONDENT_GROUPS });
    appDispatcher.handleServerAction({
      type: constants.START_UPDATING_SERVER_RESPONDENT_GROUPS
    });
    if (!this.state.respondent_groups.length) {
      this.setState({ overlay: true });
    }
  }

  componentWillUnmount() {
    groupsStore.off(constants.RESPONDENT_GROUPS_CHANGE, this._onChange);
    appDispatcher.handleServerAction({
      type: constants.STOP_UPDATING_SERVER_RESPONDENT_GROUPS
    });
  }

  calcRespondentGroupLink(respondentGroup: { RespondentGroupId: number }) {
    return `/dashboard/groups/${respondentGroup.RespondentGroupId}`;
  }

  /**
   * render each respondent group
   */
  renderRespondentGroup = (respondentGroup: {
    RespondentGroupId: number;
    Description: string;
    Forced: boolean;
    Selected: boolean;
    ActiveRespondents: number;
    TotalRespondents: number;
  }) => {
    let to = this.calcRespondentGroupLink(respondentGroup),
      group_id = respondentGroup.RespondentGroupId.toString(),
      userHandled = this.state.selected_respondent_groups[group_id];
    return (
      <div
        className="group-list-item flex-row-between form-list-item"
        data-cy="groupList"
        key={group_id}
      >
        <Link to={to} activeClassName="active" className="flex-1-1-auto flex-col-around p-1em">
          {respondentGroup.Description}
        </Link>
        <div className="flex-0-0-auto flex-col-around">
          <Checkbox
            data-group_id={group_id}
            onCheck={this.toggleRespondentGroup}
            disabled={respondentGroup.Forced}
            checked={userHandled ? userHandled.checked : respondentGroup.Selected}
          />
        </div>
      </div>
    );
  };

  toggleRespondentGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    let element = e.target,
      group_id = element.dataset.group_id;
    if (group_id) {
      let new_selected_respondent_groups = Object.assign({}, this.state.selected_respondent_groups);

      new_selected_respondent_groups[group_id] = { checked: element.checked };
      this.setState({
        selected_respondent_groups: new_selected_respondent_groups
      });
    }
  };

  setGroupsWorkflow = (groups_ids: string) => {
    this.setState({ overlay: true, invalidate: true });
    ajax.postByDesc(
      false,
      endpoints.SET_RESPONDENT_GROUPS,
      { groups_ids },
      (err: string | null, xhr: {}, data: any) => {
        if (err) {
          this.setState({ overlay: false, setGroupsError: err });
        } else {
          this.setState({ overlay: false, invalidate: true });
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARMS,
            keep_unique_alarms: false
          });
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_RESPONDENT_GROUPS
          });
          appHistory.push(constants.PATH_DASHBOARD);
        }
      }
    );
  };

  handleSetGroupsErrorClick = () => {
    this.setState({ setGroupsError: false });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.groupsRForm) {
      return;
    }

    let groups_ids = Array.prototype.slice
      .call(this.groupsRForm.querySelectorAll('input:checked'))
      .map((input: { dataset: { group_id: string } }) => {
        return parseInt(input.dataset.group_id, 10);
      });

    this.setGroupsWorkflow(JSON.stringify(groups_ids));
  };

  close() {
    appHistory.push(constants.PATH_DASHBOARD);
  }

  _onChange = () => {
    let newState = {
      respondent_groups: groupsStore.getGroups(),
      overlay: false
    },
      invalidate = this.state.invalidate;
    if (invalidate) {
      newState['selected_respondent_groups'] = {};
      newState['invalidate'] = false;
    }
    this.setState(newState);
  };

  render() {
    return (
      <MediaQuery maxDeviceWidth={1224}>
        {matched => {
          if (!matched) {
            return (
              <div className="flex-1-1-auto flex-row">
                <form
                  className="flex-0-0-auto p-rel"
                  onSubmit={this.handleSubmit}
                  ref={obj => (this.groupsRForm = obj)}
                >
                  <div className="form-list flex-col">
                    <Paper zDepth={1} className="flex-row p-05em">
                      <div className="flex-1-1-auto flex-center-row">
                        <div className="flex-col-around">{localization.t('AVAILABLE_GROUPS')}</div>
                      </div>
                      <div className="flex-col-around flex-0-0-auto">
                        <button type="button" className="form-btn-transparent" onClick={this.close}>
                          <IconDoor />
                        </button>
                      </div>
                    </Paper>
                    {this.state.respondent_groups.map(this.renderRespondentGroup)}
                    <div className="flex-center">
                      <div className="flex-col-around w-100p">
                        <RaisedButton
                          type="submit"
                          label={localization.t('JOIN_SELECTED_GROUPS')}
                          secondary={true}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.overlay ? <SpinnerOverlay /> : null}
                </form>
                <PopupError
                  onClick={this.handleSetGroupsErrorClick}
                  message={this.state.setGroupsError}
                  show={this.state.setGroupsError ? true : false}
                />
                {this.props.children}
              </div>
            );
          }

          return null;
        }}
      </MediaQuery>
    );
  }
}
