import React from 'react';

interface Props {
  symbol: string;
  alias?: string;
  icon?: JSX.Element;
}

interface State {
  active: boolean;
}

export default class DialButton extends React.Component<Props, State> {
  button: HTMLDivElement | null = null;

  state = {
    active: false
  };

  componentDidMount() {
    const element = this.button;
    if (element) {
      element.addEventListener('mousedown', this.handleMouseDown);
    }
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    const element = this.button;

    if (element) {
      element.removeEventListener('mousedown', this.handleMouseDown);
    }
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = () => {
    this.setState({ active: true });
  };

  handleMouseUp = () => {
    if (this.state.active) {
      this.setState({ active: false });
    }
  };

  render() {
    const { symbol, icon } = this.props;
    const { active } = this.state;

    return (
      <div
        ref={node => (this.button = node)}
        style={{
          borderWidth: '1px ',
          borderStyle: 'solid',
          borderColor: '#475366',
          borderRadius: '0.5em',
          color: '#fff',
          fontSize: '0.9375rem',
          padding: '5px',
          margin: 0,
          textAlign: 'center',
          backgroundColor: active ? 'hsla(127, 23%, 49%, 0.9)' : '#778796'
        }}
      >
        <strong>
          {icon}
          {!icon && <span>{symbol}</span>}
        </strong>
      </div>
    );
  }
}
