import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import constants from '../../../../json/constants.json';
import { ModalWindow } from '../../../components/modal_window';
import { Spacer } from '../../../components/spacer';
import {
  AbsenceDatasetModel,
  AbsenceReasonsModel,
} from '../../../interfaces/backend_model';
import localization from '../../../localization';
import settingsStore from '../../../stores/settings_store';
import API from '../../../stores/users/api';
import AxiosHelper from '../../../utilities/axiosApiHelper';

export const CreateAbsenceModal = (
  {
    isOpen,
    onClose,
    userId,
    userName,
    alarmId,
    notificationSystem,
  }: {
    isOpen: boolean;
    onClose: () => void;
    userId: string;
    userName: string;
    alarmId: string;
    notificationSystem: any;
  }) => {
  const alarmT = localization.useNSt(constants.NS_ALARM);
  const dateFormat = settingsStore.getDateFormat();
  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reasons, setReasons] = useState<{
    id: string;
    name: string
  }[]>([]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<AbsenceReasonsModel>({
    defaultValues: {
      HasEndDate: false,
      ReasonId: '-1',
    }
  });

  const onSubmit = async (data: AbsenceReasonsModel) => {
    const startDate = data.StartDate;
    data.StartTime = moment(startDate).format("HH:mm");
    data.StartDate = moment(startDate).format("YYYY-MM-DD");
    if (data.EndDate) {
      const endDate = data.EndDate;
      data.EndTime = moment(endDate).format("HH:mm");
      data.EndDate = moment(endDate).format("YYYY-MM-DD");
    }
    delete data.ReasonId;
    delete data.HasEndDate;

    const result = await API.createAbsence(userId, alarmId, data, onClose) as any;
    if (result?.errors.length) {
      notificationSystem.addNotification({
        title: alarmT(result.errors[0].description.toUpperCase()),
        level: 'error',
        autoDismiss: 5, // seconds
        position: 'br'
      });
    }
  };

  const fetchReasons = () => {
    const dataSetName =
      settingsStore.getValueByKey('USER_ABSENCES_SET', 'string') || 'default';
    setIsLoading(true);
    axios
      .get(
        `api/dataset/absencereason/${dataSetName}`,
        {
          headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
          }
        },
      )
      .then((res) => {
        const reasons = res.data.Attributes.map((r: AbsenceDatasetModel, index: number) => ({
          id: `${index}`,
          name: r.Name,
        }));
        setReasons([{
          id: "-1",
          name: `--${alarmT('CHOOSE_REASON')}--`,
        }, ...reasons]);
      })
      .catch((err: AxiosError) => {
        Promise.reject(AxiosHelper.errorHandler(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchReasons();
  }, []);

  useEffect(() => {
    if (isOpen && reasons?.length && !getValues('Reason')) {
      setValue('Reason', reasons[0].name);
    }
  }, [isOpen]);

  const validateEndDate = (value: Date) =>
    new Date(value).getTime() > new Date(getValues("StartDate")).getTime();

  const validateReason = (value: string) => value !== reasons[0].name;

  return (
    <ModalWindow
      popupClassName='absence-modal'
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      title={userName ?
        alarmT('CREATE_ABSENCE').replace('{object_name}', userName) :
        alarmT('CREATE_ABSENCE')}
      body={isLoading ? null : (
        <form onSubmit={handleSubmit(onSubmit)} id='create-absence'>
          <Spacer size={16} />
          <div>
            <label className='input-form-label input-required'>{alarmT('START_TIME_DATE')}:</label>
            <Controller
              control={control}
              name='StartDate'
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  onChange={field.onChange}
                  ref={startDateRef}
                  selected={field.value}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat={dateFormat + ' HH:mm'}
                  className={classNames({
                    ['input-form-error']: !!errors.StartDate,
                    ['input-form']: true,
                  })}
                  focus={false}
                />
              )}
            />
            {errors.StartDate &&
              <span className="error-message">{alarmT('FIELD_REQUIRED')}</span>}
          </div>
          <Spacer size={16} />
          <div>
            <label className='input-form-label'>
              <Controller
                control={control}
                name='HasEndDate'
                defaultValue={false}
                render={({ field }) =>
                  <input className='input-form-checkbox' type="checkbox" value={field.value} onChange={(e) => {
                    field.onChange(e);
                  }} />}
              />
              {alarmT('END_TIME_DATE')}:
            </label>
          </div>
          <Spacer size={16} />
          {!!watch('HasEndDate') && (
            <>
              <Controller
                control={control}
                name='EndDate'
                defaultValue={null}
                rules={{ validate: validateEndDate }}
                render={({ field }) =>
                  watch('HasEndDate') && (
                    <div>
                      <label className='input-form-label'>{alarmT('END_TIME_DATE')}:</label>
                      <DatePicker
                        {...field}
                        ref={endDateRef}
                        selected={field.value}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={5}
                        dateFormat={dateFormat + ' HH:mm'}
                        className={classNames({
                          ['input-form-error']: !!errors.EndDate,
                          ['input-form']: true,
                        })}
                      />
                      {errors.EndDate &&
                        <span className="error-message">{alarmT('START_BIGGER_END')}</span>}
                    </div>
                  )
                }
              />
              <Spacer size={16} />
            </>
          )}
          <div>
            <label className='input-form-label input-required'>{alarmT('ABSENCE_REASON')}:</label>
            <Controller
              control={control}
              name='Reason'
              rules={{ required: true, validate: validateReason }}
              render={({ field }) => (
                <>
                  <select
                    {...field}
                    className={classNames({
                      ['input-form-error']: !!errors.ReasonId,
                      ['input-form']: true,
                    })}
                  >
                    {reasons.map((reason, index) => (
                      <option key={reason.id} value={reason.name}>{reason.name}</option>
                    ))}
                  </select>
                  {errors.Reason &&
                    <span className="error-message">{alarmT('FIELD_REQUIRED')}</span>}
                </>
              )}
            />
            {errors.ReasonId &&
              <span className="error-message">{alarmT('FIELD_REQUIRED')}</span>}
          </div>
          <Spacer size={16} />
          <div>
            <label className='input-form-label'>{alarmT('COMMENTS')}:</label>
            <Controller
              control={control}
              name='Comment'
              render={({ field }) => (
                <textarea
                  className="input-form"
                  onChange={(e) => {
                    field.onChange(e);
                    e.target.style.height = 'inherit';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
                  value={field.value}
                />
              )}
            />
          </div>
          <Spacer size={16} />
          {/* for the next release */}
          {/* <div> */}
          {/*  <label className='input-form-label'> */}
          {/*    <Controller */}
          {/*      control={control} */}
          {/*      name="sendAlarm" */}
          {/*      defaultValue={false} */}
          {/*      render={({ field }) => */}
          {/*        <input className='input-form-checkbox' type="checkbox" {...field} />} */}
          {/*    /> */}
          {/*    {alarmT('SEND_ALARM')} */}
          {/*  </label> */}
          {/* </div> */}
          {/* <Spacer size={16} /> */}
        </form>
      )}
      footer={(
        <>
          <button
            type='submit'
            className='button-large button-large-primary'
            form='create-absence'
          >
            {alarmT('CREATE_NEW_ABSENCE')}
          </button>
          <Spacer size={8} />
          <button
            className='button-large button-large-secondary'
            onClick={onClose}
          >
            {alarmT('CANCEL')}
          </button>
        </>
      )}
      footerClassName='flex-row-end p-1em p-r-125em'
    />
  );
}
