import { action, autorun, observable } from 'mobx';
import { inject, observer, Observer } from 'mobx-react';
import { fromPromise, IBasePromiseBasedObservable } from 'mobx-utils';
import React from 'react';

import ajax from '../../ajax';
import appHistory from '../../app_history';
import l from '../../localization';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { List, ListItem } from 'material-ui/List';
import MenuItem from 'material-ui/MenuItem';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import SelectField from 'material-ui/SelectField';
import Subheader from 'material-ui/Subheader';
import PlayCircle from 'material-ui/svg-icons/av/play-circle-filled';
import { PermissionsStore } from '../../stores/mobx/PermissionsStore';

const endpoints = require('../../../json/endpoints.json');
const constants = require('../../../json/constants.json');

const t = l.t.bind(l);

const style = {
  refresh: {
    display: 'inline-block',
    position: 'relative'
  }
};

interface PropsTestAlarmsContainer {
  permissions: PermissionsStore;
}

@inject('permissions')
export default class TestAlarmsContainer extends React.Component<PropsTestAlarmsContainer> {
  testAlarmRequest: IBasePromiseBasedObservable<{ data: any; hasData: boolean }>;
  dispose: () => void;
  @observable localState = { configureStatus: 'waiting', description: '' };

  static deriveDescriptionFromStatus(configureStatus: string) {
    switch (configureStatus) {
      case 'waiting':
        return '';
      case 'grant':
        return 'ALARM_TEST_DESCRIPTION';
      case 'deny':
        return 'TEST_ALARMS_NOT_CONFIGURED';
      default:
        return 'NOT_DETECTED';
    }
  }

  componentWillMount() {
    // execute request
    this.testAlarmRequest = fromPromise(
      ajax.getByDescPromise(endpoints.TEST_ALARMS_LIST).then((data: any) => {
        const hasData = data.Routines.length > 0 && data.Users.length > 0;

        // check if user configured to use test functionality
        this.detectConfigurationStatus(hasData);

        // map response
        return { data, hasData };
      })
    );
  }

  componentDidMount() {
    const map = this.props.permissions.getMap();

    this.dispose = autorun(() => {
      if (!this.props.permissions.checkPermission(map.centerTestalarm)) {
        appHistory.replace(constants.PATH_DASHBOARD);
      }
    });
  }

  componentWillUnmount() {
    this.dispose();
  }

  @action
  detectConfigurationStatus = (hasData: boolean) => {
    const newStatus = hasData ? 'grant' : 'deny';

    this.localState.configureStatus = newStatus;
    this.localState.description = TestAlarmsContainer.deriveDescriptionFromStatus(newStatus);
  };

  render() {
    return (
      <div className="flex-1-1-auto ">
        <div className="flex-center-w">
          <h1 style={{ paddingLeft: '16px' }}>{t('ALARM_TEST_HEADING')}</h1>
        </div>
        <Observer>
          {() => <p style={{ paddingLeft: '16px', margin: 0 }}>{t(this.localState.description)}</p>}
        </Observer>
        <AlarmsList testAlarmRequest={this.testAlarmRequest} />
      </div>
    );
  }
}

interface PropsAlarmsList {
  testAlarmRequest: IBasePromiseBasedObservable<{ data: any; hasData: boolean }>;
}

@observer
class AlarmsList extends React.Component<PropsAlarmsList> {
  @observable selectedUser: number;

  state = {
    open: false,
    text: ''
  };

  handleOpen = (text: string) => {
    this.setState({ open: true, text });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  triggerAlarm = (TestRoutineId: number, UserId: number | null) => {
    return () => {
      UserId = UserId || this.selectedUser;
      ajax
        .postByDescPromise(endpoints.TEST_ALARMS_START, { TestRoutineId, UserId })
        .then(() => this.handleOpen('ALARM_EXECUTION_SUCCESS'))
        .catch(() => this.handleOpen('ALARM_EXECUTION_FAIL'));
    };
  };

  @action
  handleChange = (e: React.ChangeEvent<HTMLSelectElement>, i: number, v: number) =>
    (this.selectedUser = v);

  getAlarmsList() {
    return this.props.testAlarmRequest.case({
      pending: () => (
        <div style={{ paddingTop: '15px' }} className="flex-center">
          <RefreshIndicator style={style.refresh} size={40} left={10} top={0} status="loading" />
        </div>
      ),
      fulfilled: ({ data, hasData }) => {
        if (!hasData) {
          // just render nothing, since parent view shows description
          return null;
        }

        return (
          <div>
            {data.Users.length === 1 ? (
              <div style={{ paddingTop: '15px', marginLeft: '16px' }}>
                {t('USERS')}:{' '}
                {data.Users.map((user: { UserId: number; Name: string }) => user.Name).join(', ')}
              </div>
            ) : (
              <SelectField
                floatingLabelText={l.t('SELECT_USER')}
                value={this.selectedUser}
                style={{ marginLeft: '16px' }}
                onChange={this.handleChange}
              >
                {data.Users.map((user: { UserId: number; Name: string }) => (
                  <MenuItem key={user.UserId} value={user.UserId} primaryText={user.Name} />
                ))}
              </SelectField>
            )}
            <List>
              <Subheader>{t('TEST_ALARMS_TYPES_HEADER')}:</Subheader>
              {data.Routines.map((routine: { Routine: string; TestRoutineId: number }) => (
                <ListItem
                  onClick={this.triggerAlarm(
                    routine.TestRoutineId,
                    data.Users.length === 1 ? data.Users[0].UserId : null
                  )}
                  key={routine.TestRoutineId}
                  primaryText={routine.Routine}
                  rightIcon={<PlayCircle />}
                />
              ))}
            </List>
          </div>
        );
      },
      rejected: (err: any) => <div>{JSON.stringify(err)}</div>
    });
  }

  render() {
    return (
      <div>
        <Dialog
          title={t('ALARM_TEXT_EXECUTION_STATUS')}
          actions={[<FlatButton label="OK" primary={true} onClick={this.handleClose} />]}
          modal={true}
          open={this.state.open}
        >
          {t(this.state.text)}
        </Dialog>
        {this.getAlarmsList()}
      </div>
    );
  }
}
