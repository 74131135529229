export class Event {
  listeners = {};

  initializeListeners() {
    if (!this.listeners) {
      this.listeners = {};
    }
  }

  on(event: string, handler: any, context?: {} | any) {
    this.initializeListeners();
    if (!Array.isArray(this.listeners[event])) {
      this.listeners[event] = [];
    }
    if (this.listeners[event].indexOf(handler) === -1) {
      this.listeners[event].push({
        handler,
        context: context || this
      });
    }
    return this;
  }

  off(event: string, handler?: any) {
    this.initializeListeners();
    if (!event) {
      this.listeners = {};
      return this;
    }
    if (!this.listeners[event]) {
      return this;
    }

    if (!handler) {
      delete this.listeners[event];
    } else {
      let idx = -1;
      this.listeners[event].some((listener: { handler: Function; context?: {} }, index: number) => {
        let equality = listener.handler === handler;
        if (equality) {
          idx = index;
        }
        return equality;
      });
      if (idx !== -1) {
        this.listeners[event].splice(idx, 1);
      }
    }
    return this;
  }

  trigger(name: string, ...rest: any[]) {
    this.initializeListeners();
    const args = Array.prototype.slice.call(arguments);
    const listeners = this.listeners[name];

    if (Array.isArray(listeners)) {
      listeners.forEach(listener => {
        listener.handler.apply(listener.context, args);
      });
    }
    return this;
  }
}
