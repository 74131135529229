import axios from 'axios';
import * as Logger from 'js-logger';
import { hangupTwilioCall } from '../../actions/twilio';
import ajax from '../../ajax';
import appDispatcher from '../../app_dispatcher';
import appHistory from '../../app_history';
import authStore from '../../authentication';
import IdGenerator from '../../id_generator';
import {
  AlarmAttachments, AlarmDetailsModel, CommandResponseModel, LogModel, ObjectTransmitterModel, PlannedAlarm
} from '../../interfaces/backend_model';
import AxiosHelper from '../../utilities/axiosApiHelper';
import { Utils } from '../../utils';
import websocket from '../../websocket';
import settingsStore from '../settings_store';
import usersStore from '../users/users_store_actions';
import alarmListStore from './alarm_list_store_actions';
import AlarmAPI from './api';

const constants = require('../../../json/constants.json');
const endpoints = require('../../../json/endpoints.json');

class AlarmActiveStore extends Utils {
  alarmDetails: { [key: string]: AlarmDetailsModel } | {};
  alarmAttachments: { [key: string]: AlarmAttachments } | {};
  currentAlarmId: string;
  alarmIntervalHandler: number;
  commandActionFinishTrigger: boolean;

  constructor() {
    super();
    this.getInitialData();
    this.alarmIntervalHandler = 0;
    this.commandActionFinishTrigger = false;
  }

  getInitialData() {
    /**
     * store detail information about each alarm
     */
    this.alarmDetails = {};
    /**
     * store updates
     */
    this.alarmAttachments = {};
    /**
     * current selected alarm
     */
    this.currentAlarmId = '';

  }

  pruneAlarmById(alarmId: string) {
    this.clearAlarmDetails(alarmId);
    this.clearAlarmAttachments(alarmId);
  }

  getCurrentAlarmId(): string {
    return this.currentAlarmId;
  }

  setCurrentAlarmId(id: string) {
    this.currentAlarmId = id;
  }

  getAlarmDetails(alarm_id: string): AlarmDetailsModel {
    return this.alarmDetails[alarm_id];
  }

  /**
   * clear alarm details data
   */
  clearAlarmDetails(alarm_id: string) {
    this.alarmDetails[alarm_id] = null;
  }

  /**
   * 
   * @param alarm_id 
   * @returns ObjectTransmitter of alarming transmitter
   */
  getAlarmingTransmitterByAlarmId(alarm_id: string): ObjectTransmitterModel {
    return this.alarmDetails[alarm_id]?.ObjectTransmitters.find(
      (tr: ObjectTransmitterModel) => tr.IsAlarmingTransmitter
    );
  }

  /**
   * Loads base alarm information for displaying the alarm
   * @param alarmId
   */
  loadAlarmDetails(alarmId: string) {
    AlarmAPI.getAlarmDetails(alarmId).then((alarmDetails: AlarmDetailsModel) => {
      alarmDetails.TaskType = !!alarmDetails.TaskType ? alarmDetails.TaskType.split(',').join(', ') : '';
      this.alarmDetails[alarmId] = alarmDetails;

      this.trigger(constants.ALARM_DETAILS_SYNC_SUCCESS);
      this.trigger(constants.ALARM_DETAILS_CHANGE);
    }).catch(err => {
      if (err !== constants.REQUEST_BLOCKED) {
        Logger.error(err);
        const errorMessage = err.data?.errors[0]?.description;

        if (err.status !== 404) {
          usersStore.stopRequestPending();
        }

        // if the alarm details doesn't already exist then we trigger a server error,
        // else we trigger a sync error, the server error will force a popup 
        if (!this.alarmDetails[alarmId]) {
          this.trigger(constants.ALARM_DETAILS_SERVER_ERROR, errorMessage, err.status);
        } else {
          this.trigger(constants.ALARM_DETAILS_SYNC_ERROR, errorMessage, err.request, alarmId);
        }
      }
    });
  }

  updateAlarm = (alarmId: string, data: AlarmDetailsModel) => {
    const alarm = this.getAlarmDetails(alarmId);
    if (alarm && data) {
      Object.assign(alarm, data);
    }
  };

  /**
   * get array of all alarms attachments (logs, locations, images, etcetc)
   */
  getAlarmAttachments(alarmId: string): AlarmAttachments {
    return this.alarmAttachments[alarmId];
  }

  /**
   * get array of specific data from attachment (logs, locations, images, etc)
   */
  getAttachmentData(dataName: string, alarmId?: string) {
    return this.alarmAttachments[alarmId || '']?.[dataName] || [];
  }

  /**
   * get Log of alarm reason
   */
  getAlarmReason(alarmId?: string) {
    const logs: LogModel[] = this.getAttachmentData('Logs', alarmId);
    const reasons = logs.filter((log) => log.PredefinedId === 2 && log.LogEventCode === 1);
    if (reasons.length === 0) return null;
    if (reasons.length === 1) return reasons[0];

    reasons.sort((a, b) => {
      if (!a.Created || !b.Created) return 0;
      return b.Created?.localeCompare(a.Created);
    });
    return reasons[0];
  }

  /**
   * get Log of alarm action
   */
  getAlarmAction(alarmId?: string) {
    const logs: LogModel[] = this.getAttachmentData('Logs', alarmId);
    return logs.find((log) => log.PredefinedId === 2 && log.LogEventCode === 2);
  }

  /**
   * clear array of all alarms updates
   */
  clearAlarmAttachments(alarmId: string) {
    this.alarmAttachments[alarmId] = null;
  }

  filterExistingElements(data: AlarmAttachments, elementName: string, alarmId: string) {
    if (!this.alarmAttachments[alarmId]) return data[elementName];
    return data[elementName].filter(
      (element: any) => !this.alarmAttachments[alarmId][elementName].some((el: any) => el['Id'] == element['Id'])
    );
  }

  generateUID(attachments: AlarmAttachments, elementName: string) {
    if (Array.isArray(attachments[elementName])) {
      attachments[elementName].forEach((element: any) => {
        element.uid = IdGenerator.generateId();
      });
    }
    return attachments[elementName];
  }

  /**
   * Loads operator logs, cooridnates, locations and dynamic details for an alarm
   * @param alarmId
   */
  loadAlarmAttachments(alarmId: string) {
    const existingAlarmAttachments: AlarmAttachments = this.alarmAttachments[alarmId];

    if (!alarmId) {
      Logger.error("Trying to load alarm attachments without a alarm id");
    } else {
      AlarmAPI.getAlarmAttachments(alarmId, existingAlarmAttachments ? existingAlarmAttachments.Latest : '')
        .then((attachments: AlarmAttachments) => {
          if (!existingAlarmAttachments) {
            attachments.Locations = this.generateUID(attachments, "Locations");
            attachments.Coordinates = this.generateUID(attachments, "Coordinates");

            this.alarmAttachments[alarmId] = attachments;
          } else {
            // there is a risk of fetching duplicates depending on that data.Latest only handles whole seconds
            attachments.Locations = this.filterExistingElements(attachments, 'Locations', alarmId);
            attachments.Logs = this.filterExistingElements(attachments, 'Logs', alarmId);
            attachments.Images = this.filterExistingElements(attachments, 'Images', alarmId);
            attachments.Sounds = this.filterExistingElements(attachments, 'Sounds', alarmId);
            attachments.Videos = this.filterExistingElements(attachments, 'Videos', alarmId);

            attachments.Locations = this.generateUID(attachments, "Locations");
            attachments.Coordinates = this.generateUID(attachments, "Coordinates");

            existingAlarmAttachments.Coordinates.push(...attachments.Coordinates);
            existingAlarmAttachments.Logs.push(...attachments.Logs);
            existingAlarmAttachments.Images.push(...attachments.Images);
            existingAlarmAttachments.Links.push(...attachments.Links);
            existingAlarmAttachments.Images.push(...attachments.Images);
            existingAlarmAttachments.Videos.push(...attachments.Videos);
            existingAlarmAttachments.Sounds.push(...attachments.Sounds);
            existingAlarmAttachments.Realarms.push(...attachments.Realarms);
            existingAlarmAttachments.Latest = attachments.Latest;
            existingAlarmAttachments.Locations.push(...attachments.Locations);
          }
          this.trigger(constants.ALARM_ATTACHMENTS_SYNC_SUCCESS);
          this.trigger(constants.ALARM_ATTACHMENTS_CHANGE);
        }).catch(err => {
          if (err !== constants.REQUEST_BLOCKED) {
            Logger.error(err);
            const errorMessage = err.data?.errors[0]?.description;
            if (!this.alarmAttachments[alarmId]) {
              this.trigger(constants.ALARM_ATTACHMENTS_SERVER_ERROR, errorMessage, err.status);
            } else {
              this.trigger(constants.ALARM_ATTACHMENTS_SYNC_ERROR, errorMessage, err.xhr);
            }
          }
        });
    }
  }

  updateAlarmDetailsForPlanningView(alarmId: string, data: PlannedAlarm) {
    if (this.alarmDetails[alarmId]) {
      this.alarmDetails[alarmId].PlannedTime = data.PlannedTime;
      this.alarmDetails[alarmId].TaskType = data.TaskType;
      this.trigger(constants.ALARM_DETAILS_CHANGE);
    }
  }

  // Fetch alarm details and attachments on an interval when websocket is down
  subscribeForAlarmUpdates() {
    let setting_interval = settingsStore.getSettingInterval('ALARM_REFRESH_FAST');
    if (setting_interval !== null) {
      this.unSubscribeForAlarmUpdates();
      this.alarmIntervalHandler = window.setInterval(() => {
        // checkWebSocket returns a function and thus we need to call it
        if (authStore.getUserId()) { // If user is logged in
          if (this.currentAlarmId) {
            websocket.checkWebSocket(this, this.loadAlarmAttachments, this.currentAlarmId)();
            websocket.checkWebSocket(this, this.loadAlarmDetails, this.currentAlarmId)();
          }
        } else {
          console.warn('No user logged in, will not run \'loadAlarmAttachments\' and \'loadAlarmDetails\'');
          this.unSubscribeForAlarmUpdates();
        }
      }, setting_interval);
    }
  }

  unSubscribeForAlarmUpdates() {
    clearInterval(this.alarmIntervalHandler);
  }

  triggerCommandActionFinisher() {
    this.commandActionFinishTrigger = !this.commandActionFinishTrigger;
  }

  sendCommand(
    alarmId: string,
    commandId: number | string,
    commandArgs: string,
    commandMessage?: string,
    id?: number
  ) {
    ajax.postByDesc(
      false,
      endpoints.ALARM_COMMAND,
      { alarmId, commandId, commandArgs, commandMessage: commandMessage || null, id: id || null },
      (err: string | null, xhr: XMLHttpRequest, response: { data: CommandResponseModel }) => {
        if (err || !response) {
          this.triggerCommandActionFinisher();
          // if we get a 404 we dont have access to this alarm anymore
          if (xhr.status == 404) {
            this.trigger(constants.ALARMS_SYNC_ERROR, xhr.statusText, xhr);
            return;
          } else {
            this.trigger(constants.ALARM_COMMAND_ERROR, 'ERROR_EXECUTE_COMMAND', xhr);
            return;
          }
        }

        // there might be cases where the websocket push don't arrive to the respondent
        // who initiated the command, thus we need to do an update of the alarm just in case
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
          alarm_id: alarmId
        });
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
          alarm_id: alarmId
        });
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_DETAILS,
          alarm_id: alarmId
        });
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_USER_HISTORY,
          user_id: +this.alarmDetails[alarmId].UserId,
          alarm_id: alarmId
        });

        const { data } = response;

        if (data.HangUpVoiceCall) {
          hangupTwilioCall();
        }

        if (data.ExitAlarmView) {
          appHistory.push(constants.PATH_DASHBOARD);
        }

        if (!!data.Clipboard?.length && data.OpenUrl) {
          navigator.clipboard.writeText(data.Clipboard).then(() => {
            this.trigger(constants.COPY_TO_CLIPBOARD, alarmId, xhr);
            // @ts-ignore
            window.open(data.OpenUrl, '_blank').focus();
          });
        }

        if (!data.Clipboard?.length && data.OpenUrl) {
          // @ts-ignore
          window.open(data.OpenUrl, '_blank').focus();
        }

        if (data.Message && !data.Success) {
          this.trigger(constants.ALARM_COMMAND_ERROR_MESSAGE, data.Message, xhr);
        } else {
          this.trigger(constants.ALARM_COMMAND_SUCCESS, alarmId, xhr);
        }

        this.triggerCommandActionFinisher();
      }
    );
  }

  _clearAlarm(alarmId: string) {
    this.trigger(constants.ALARM_CLEAR_POSTING);
    Logger.info('trigger ALARM_CLEAR_POSTING and post request CLEAR');

    AlarmAPI.clearAlarm(alarmId).then(() => {
      Logger.info('post request success, ALARM_DETAILS_FETCHING');
      this.trigger(constants.ALARM_DETAILS_FETCHING);
      alarmListStore._clearAlarm(alarmId);    // TODO: this should probably be done via push notification
      this.loadAlarmDetails(alarmId);         // TODO: Loading of alarm details
      this.loadAlarmAttachments(alarmId);  // TODO: Loading of alarm attachemnets
    }).catch((err: string) => {
      Logger.info('post request error, ALARM_CLEAR_ERROR');
      this.trigger(constants.ALARM_CLEAR_ERROR, alarmId, err);
    })
  }

  _addReasonTextToAlarmLog(alarm_id: string, user_id: string, message: string) {
    const url = endpoints.REASON["url"].replace("{alarm_id}", alarm_id);
    axios.post(url,
      { message },
      {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      }).then(() => {
        this.trigger(constants.ALARM_ADD_REASON_SUCCESS);
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
          alarm_id
        });
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_DETAILS,
          alarm_id
        });
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_USER_HISTORY,
          user_id: +user_id,
          alarm_id: alarm_id
        });
      }).catch(err => {
        this.trigger(constants.ALARM_ADD_REASON_ERROR, alarm_id, err);
      });
  }

  _addActionTextToAlarmLog(alarm_id: string, user_id: string, message: string) {
    const url = endpoints.ACTION["url"].replace("{alarm_id}", alarm_id);
    axios.post(url,
      { message },
      {
        headers: {
          Authorization: AxiosHelper.getAuthenticationHeader()
        }
      }).then(() => {
        this.trigger(constants.ALARM_ADD_ACTION_SUCCESS);
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
          alarm_id
        });
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_DETAILS,
          alarm_id
        });
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_USER_HISTORY,
          user_id: +user_id,
          alarm_id: alarm_id
        });
      })
      .catch(err => {
        this.trigger(constants.ALARM_ADD_ACTION_ERROR, alarm_id, err);
      });
  }
}

export default AlarmActiveStore;
