import { inject } from 'mobx-react';
import React from 'react';
import { logToPapertrail } from '../actions/externalLogging';
import appDispatcher from '../app_dispatcher';
import event_bus from '../event_bus';
import { PushDataModel } from '../interfaces/backend_model';
import AlarmHelpers from '../stores/alarms/alarm_helpers';
import alarmListStore from '../stores/alarms/alarm_list_store_actions';
import externalLogStore from '../stores/externalLogging/externalLogActions';
import { StorageStore } from '../stores/mobx/StorageStore';
import settingsStore from '../stores/settings_store';

const constants = require('../../json/constants.json');
const audioReminder = require('../../audio/beepbeep.mp3');

interface State { }

interface Props {
  storage?: StorageStore;
}

/* */
@inject('storage')
export default class AlarmReminder extends React.Component<Props> {
  node: HTMLAudioElement;
  timeout: boolean; // Prevent more than one running intervall (playReminder())
  playInterval: number | undefined; // Reference to the running intervall
  constructor(props: Props) {
    super(props);
    this.timeout = false;
    this.playInterval = 0;
  }

  componentDidMount() {
    const isSoundForNotifyEnabled = this.props.storage!.getItem('isSoundForNotifyEnabled')

    event_bus.on(constants.PLAY_REMIND_SOUND, this.playRemindWS, this);
    this.props.storage!.addEventListener(this.onChange);
    if (!this.timeout && isSoundForNotifyEnabled) {
      this.playReminder(this.node, this.props.storage!.getItem('notifyReminderInterval'));
    }
  }

  clearPlayInterval = () => {
    this.timeout = false;
    clearInterval(this.playInterval);
    this.playInterval = undefined;
  }

  onChange = (data: any | { key: string; value: number | boolean }) => {
    switch (data.key) {
      case 'isSoundForNotifyEnabled':
        if (data.value) {
          this.clearPlayInterval();
          this.playReminder(this.node, this.props.storage!.getItem('notifyReminderInterval'));
        }
        break;
      case 'notifyReminderInterval':
        if (this.props.storage!.getItem('isSoundForNotifyEnabled')) {
          this.clearPlayInterval();
          this.playReminder(this.node, data.value);
        }
        break;
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isNotifyReminderEnabled = this.props.storage!.getItem('isNotifyReminderEnabled');
    const notifyReminderInterval = this.props.storage!.getItem('notifyReminderInterval');

    if (!this.timeout && isNotifyReminderEnabled) {
      this.playReminder(this.node, notifyReminderInterval);
    }
  }

  componentWillUnmount() {
    this.timeout = false;
    window.clearInterval(this.playInterval);
    this.props.storage!.removeEventListener(this.onChange);
    event_bus.off(constants.PLAY_REMIND_SOUND, this.playRemindWS);
  }

  // Play reminders for alarms if alarm.NotifyAfter is set
  // Can be for different reasons but one is if 'alarm.AEmergencycenter.NotifyAfter' exists when accepting the alarm
  playReminder = (node: HTMLAudioElement, interval: number | boolean) => {
    this.timeout = true;
    if (typeof interval === 'boolean' || !!this.playInterval) {
      return;
    }
    this.playInterval = window.setInterval(() => {
      // This is not a beautiful solution as setInterval is executing from the moment Central is rendered..
      if (node && alarmListStore.isActiveAlarmsToNotifyAfter()) {
        // Force the alarm list to re-render by trigger ALARMS_CHANGE, if not the alarm will not ' box-pulse'
        alarmListStore.trigger(constants.ALARMS_CHANGE);
        if (AlarmHelpers.playAlarmSound() && this.props.storage!.getItem('isSoundForNotifyEnabled')) {
          node.play();
        }
      }
    }, interval * 1000);
  };

  // Play reminders when we get push of type 'WS_PUSH_REMIND'
  // Is true usually for alarms not taken, i.e. alarms in the active tab
  playRemindWS(e: Event, data?: PushDataModel) {
    if (this.node && this.props.storage!.getItem('isSoundForNotifyEnabled') && AlarmHelpers.playAlarmSound()) {
      this.node.play();
    }
    if (data) {
      const reminderAlarmId = data.globalAlarmId?.trim();
      if (reminderAlarmId) {// Not empty
        let fetchAlarm = false;
        if (!alarmListStore.alarmExist(reminderAlarmId)) {
          // We have a globalAlarmId that received an reminder but it looks like it is not visible in the UI!
          logToPapertrail('Got a reminder for ' + reminderAlarmId + ' but could not find it');
          fetchAlarm = true;
        }
        else if (externalLogStore.enhancedLoggingEnabled()) {
          const reminderAlarm = alarmListStore.getAlarm(reminderAlarmId);
          const reminderAlarmAsString = JSON.stringify(reminderAlarm);
          logToPapertrail('Got a reminder for  ' + reminderAlarmId + ': ' + reminderAlarmAsString);
        }

        if (fetchAlarm || settingsStore.getValueByKey('ENABLE_FETCH_ON_REMINDER', 'boolean')) {
          // Fetch the alarm if not available in the list of alarms OR we have enabled this feature on the ARC
          // https://skyresponse.atlassian.net/browse/SIS-24191
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARM_DETAILS_BASIC,
            alarm_id: reminderAlarmId
          });
        }
      }
    }
  }

  render() {
    return (
      <div>
        <audio ref={node => (this.node = node!)} preload="auto" id={constants.ID_AUDIO_ALARM_5}>
          <source src={audioReminder} type="audio/mpeg" />
        </audio>
      </div>
    );
  }
}
