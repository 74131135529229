import AppDispatcher from '../app_dispatcher';
const constants = require('../../json/constants.json');

export function fetchActiveCalls() {
  AppDispatcher.dispatch({
    action: {
      type: constants.FETCH_ACTIVE_CALLS
    }
  });
}
