import React from 'react';
import { loadAlarmInfo } from '../../actions/alarm';
import appDispatcher from '../../app_dispatcher';
import { AlarmModel, MeModel, SettingModel } from '../../interfaces/backend_model';
import alarmActiveStore from '../../stores/alarms/alarm_active_store_actions';
import settingsStore from '../../stores/settings_store';
import usersStore from '../../stores/users/users_store_actions';
import Utils from '../../utils';
import Grid from '../grid';
import SpinnerOverlay from '../spinner_overlay';
import { AlarmListHeader } from "./alarm_list_header";
import { AlarmHistoryItem } from "./history_item";

const constants = require('../../../json/constants.json');

interface Props {
  mobile?: boolean;
  params: { template_type: string; alarm_id: string };
  onAlarmSelect?: Function;
}

interface State {
  invisible: boolean;
  user_id: string | null;
  alarm_id: string | null;
  userHistory: string | null | any;
  admin: string | null;
  settings: SettingModel[];
  me: MeModel;
  overlay: boolean;
}

export class UserHistory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      invisible: false,
      user_id: null,
      alarm_id: null,
      userHistory: null,
      admin: null,
      settings: settingsStore.getSettings(),
      me: settingsStore.getMe(),
      overlay: false
    };
  }

  /**
   * calculate data from stores
   */
  calcState(props: Props) {
    const { alarm_id } = props.params;
    const alarmDetails = alarmActiveStore.getAlarmDetails(alarm_id);
    const user_id = alarmDetails && alarmDetails.UserId.toString();
    const userHistory = user_id ? usersStore.getUserHistory(user_id) : null;

    return {
      alarmDetails,
      userHistory,
      user_id
    };
  }

  /**
   * pending server only if data is not avaliable
   */
  pendingByState(state: any, alarm_id: string) {
    const { user_id, userHistory } = state;
    const has_data = user_id && userHistory;

    if (usersStore.forceStopRequestPending) {
      return;
    }

    if (!has_data) {
      if (!user_id) {
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARM_DETAILS,
          alarm_id
        });
      } else if (!userHistory) {
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_USER_HISTORY,
          user_id,
          alarm_id
        });
      }
    }
    return has_data;
  }

  componentDidMount() {
    usersStore.on(constants.USER_HISTORY_CHANGE, this.onChange);
    alarmActiveStore.on(constants.ALARM_DETAILS_CHANGE, this.onChange);
    alarmActiveStore.on(constants.ALARM_ATTACHMENTS_CHANGE, this.onChange);

    appDispatcher.handleServerAction({
      type: constants.START_UPDATING_USER_HISTORY
    });
  }

  componentWillUnmount() {
    usersStore.off(constants.USER_HISTORY_CHANGE, this.onChange);
    alarmActiveStore.off(constants.ALARM_DETAILS_CHANGE, this.onChange);
    alarmActiveStore.off(constants.ALARM_ATTACHMENTS_CHANGE, this.onChange);

    appDispatcher.handleServerAction({ type: constants.STOP_UPDATING_USER_HISTORY });
  }

  componentWillReceiveProps(nextProps: Props) {
    const { user_id, alarm_id, userHistory, invisible } = this.state;

    if (!nextProps.params.alarm_id) {
      appDispatcher.handleServerAction({ type: constants.STOP_UPDATING_USER_HISTORY });
      this.setState({ invisible: true });
    } else {
      const next_alarm_id = nextProps.params.alarm_id;
      const state = this.calcState(nextProps);
      const next_user_id = state.user_id;
      const has_data = this.pendingByState(state, next_alarm_id);

      if (
        (next_user_id && user_id !== next_user_id) ||
        (next_alarm_id && alarm_id !== next_alarm_id)
      ) {
        const newState = {
          user_id: next_user_id,
          alarm_id: next_alarm_id,
          invisible: false,
          overlay: !has_data,
          userHistory: null
        };

        if (next_user_id) {
          newState.userHistory = state.userHistory;
        } else if (userHistory && Utils.find_obj(userHistory, 'AlarmId', alarm_id, undefined)) {
          // hack to save scroll position when click on user history list
          newState.userHistory = userHistory;
        }
        this.setState(newState);
      } else if (invisible) {
        this.setState({ invisible: false });
      }
    }
  }



  /**
   * render each respondent group
   */
  renderHistoryAlarm = (alarm: AlarmModel) => {
    // item in list
    return (
      <AlarmHistoryItem
        key={alarm.AlarmId}
        alarm={alarm}
        alarmId={this.props.params.alarm_id}
        templateType={this.props.params.template_type}
        onLinkClick={this.onLinkClick}
      />
    )
  };

  onLinkClick = (newAlarm: AlarmModel) => {
    const state = this.calcState(this.props);

    // Checks if need update user history when clicks on the link in the history tab
    if (state.user_id && this.props.params.alarm_id && this.state.alarm_id !== newAlarm.AlarmId) {
      loadAlarmInfo(newAlarm);
    }
    this.props.onAlarmSelect && this.props.onAlarmSelect();
  };

  onChange = () => {
    const { alarm_id } = this.props.params;
    const state = this.calcState(this.props);
    const has_data = this.pendingByState(state, alarm_id);

    this.setState({
      userHistory: state.userHistory,
      overlay: !has_data
    });
  };

  render() {
    const { userHistory, overlay, settings, me, invisible } = this.state;
    if (invisible) {
      return null;
    }

    const useOldAdmin = Utils.find_obj(settings, 'key', 'UseOldAdmin')?.defaultvalue === "true" ?? false;

    const user_id = this.calcState(this.props).user_id;
    const grid = (
      <Grid
        scroll={true}
        mobileScroll={this.props.mobile}
        rows={
          Array.isArray(userHistory)
            ? userHistory.sort((alarm_a, alarm_b) => {
              if (alarm_a.ServerAlarmTime === alarm_b.ServerAlarmTime) {
                return 0;
              }
              return alarm_a.ServerAlarmTime < alarm_b.ServerAlarmTime ? 1 : -1;
            })
            : null
        }
        renderRow={this.renderHistoryAlarm}
      />
    );

    return (
      <div className="p-rel flex-1-1-0px flex-col bg-c-gray donotprint" style={{ width: '100%' }}>
        <AlarmListHeader
          path={useOldAdmin ? `alarms?user=${user_id}` : `objects/${user_id}/alarm-history` }
          me={me}
          settings={settings}
          userHistory={userHistory}
          alarmId={this.props.params.alarm_id}
        />
        {grid}
        {!!overlay && <SpinnerOverlay />}
      </div>
    );
  }
}
