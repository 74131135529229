// import * as Sentry from '@sentry/browser';
import * as Logger from 'js-logger';
import debounce from 'lodash.debounce';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { observer, Provider } from 'mobx-react';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { IndexRedirect, Route, Router } from 'react-router';
import '../less/index.less';
import ajax from './ajax';
import AppDispatcher from './app_dispatcher';
import appHistory from './app_history';
import authentication from './authentication';
import Dashboard from './components/dashboard';
import GroupsList from './components/groups_list';
import LoginCredentials from './components/login_credentials';
import PlanningView from './components/planning_view';
import { RespondentGroup } from './components/respondent_group';
import SettingsList from './components/settings_list';
import SpinnerOverlay from './components/spinner_overlay';
import Template from './components/template';
import UsersList from './components/users_list';
import Support from './containers/admin/Support';
import TestAlarms from './containers/admin/TestAlarms';
import AutoRegister from './containers/AutoRegister';
import AutoRegisterSimple from './containers/AutoRegisterSimple';
import UserSearch from './containers/UserSearchContainer';
import localization from './localization';
import PermissionsStore from './stores/mobx/PermissionsStore';
import StorageStore from './stores/mobx/StorageStore';
import UIStore from './stores/mobx/UIStore';
import UsersSearchStore from './stores/mobx/UsersSearchStore';
import SettingsStore from './stores/settings_store';
import UserInactivity from './user_inactivity';
import { logoutWhenReferrer } from './utilities/redirects';
require('./utilities/vxgplayer-1.8.20.min.css');

const constants = require('../json/constants.json');
const endpoints = require('../json/endpoints.json');
let hasSendLogsSetting;

const storesToInject = {
  usersSearch: UsersSearchStore,
  uiStore: UIStore,
  permissions: PermissionsStore,
  settings: SettingsStore,
  storage: StorageStore,
  constants
};

function redirectToLogin(nextState, replace) {
  appHistory._pathname = nextState.location.pathname;

  if (logoutWhenReferrer()) {
    return;
  }

  replace(constants.PATH_LOGIN);

  AppDispatcher.dispatch({
    action: { type: constants.LOGOUT_CLEAR }
  });
}

function requireAuth(nextState, replace) {
  if (!authentication.isLoggedIn()) {
    redirectToLogin(nextState, replace);
  } else {
    const userId = authentication.getUserId();
    let expires_initial_datetime = authentication.ls_get([userId, constants.EXPIRES_INITIAL_KEY]);
    let timeToRefreshToken = (expires_initial_datetime - new Date().getTime()) / 2;
    const renewalToken = authentication.ls_get([userId, constants.RENEWAL_KEY]);
    if (
      authentication.isValidAdmin() &&
      (!renewalToken || timeToRefreshToken < 300000) /* 5 min in milliseconds*/
    ) {
      // if the renewal token is about to expire, renew it before refreshing access token
      authentication.renewToken(userId).then(() => authentication.refreshToken(userId));
    } else {
      // if the renewal token is not about to expire use it to refresh the access token
      setTimeout(
        () => authentication.refreshToken(userId),
        timeToRefreshToken - 20000 /* delta time to process requests */
      );
    }
  }
}

function requireUnAuth(nextState, replace) {
  if (authentication.isLoggedIn()) {
    replace(constants.PATH_GROUPS);
  }
}

function noMatchRedirect(nextState, replace) {
  if (nextState.location.pathname === constants.PATH_COGNITO) {
    let cognitoParams;
    try {
      cognitoParams = JSON.stringify(nextState.location.query);
      const logoutURL = document.referrer;
      // do not set logout url if the refferer are the current page
      if (logoutURL && !document.URL.startsWith(logoutURL)) {
        authentication.ls_set('logoutURL', logoutURL);
      }
    } catch (e) {
      cognitoParams = '';
      console.error(e);
    }
    cognitoParams && authentication.ls_set(constants.COGNITO, cognitoParams);
  }
  if (authentication.isLoggedIn()) {
    replace(constants.PATH_GROUPS);
  } else {
    replace(constants.PATH_LOGIN);
  }
}

function configureAutoSendingLogs() {
  let logs = [];

  const sendLogsToServer = debounce(() => {
    const userName = authentication.ls_get('user_id');

    let objectToSend;

    try {
      objectToSend = `'${JSON.stringify(logs)}'`;
    } catch (error) {
      logs = [];
      return;
    }

    const data = {
      username: userName || '',
      data: objectToSend
    };

    ajax.postByDesc(false, endpoints.UPLOAD_BROWSER_LOG, data, (err, xhr, response) => {
      if (err) {
        console.error(err);
        return;
      }

      logs = [];
    });
  }, 5000);

  Logger.useDefaults();
  const defaultLogger = Logger.createDefaultHandler();

  Logger.setHandler((messages, context) => {
    defaultLogger(messages, context);
    logs.push({ messages, context, timestamp: new Date() });

    if (window.location.href.includes('UPLOAD_BROWSER_LOG=true') || hasSendLogsSetting) {
      sendLogsToServer();
    }
  });
}

const Routes = () => {
  const isPlannigViewEnabled = React.useMemo(() => SettingsStore.getValueByKey('USING_PLANNING_VIEW', 'boolean'), []);

  return (
  <Provider {...storesToInject}>
    <MuiThemeProvider muiTheme={getMuiTheme()}>
      <Router history={appHistory}>
        <Route path="/">
          <IndexRedirect to="login" />
          <Route path="autoregister" component={AutoRegister} />
          <Route path="al" component={AutoRegisterSimple} />
          <Route path="login" component={LoginCredentials} onEnter={requireUnAuth} />
          <Route path="dashboard" component={Dashboard} onEnter={requireAuth}>
            {!authentication.isSimpleLogin() && (
              <>
                <Route path="groups" component={GroupsList}>
                  <Route path=":respondent_group_id" component={RespondentGroup} />
                </Route>
                <Route path="settings" component={SettingsList} />
                <Route path="users" component={UsersList} />
                <Route path="users/search" component={UserSearch} />
                <Route path="admin/support" component={Support} />
                <Route path="admin/test" component={TestAlarms} />
                {isPlannigViewEnabled && (
                  <Route path="planning-view" component={props => <PlanningView {...props} />} />
                )}
              </>
            )}
            <Route path="template/:template_type/:alarm_id(/:open_type)" component={Template} />
          </Route>
        </Route>
        <Route path="*" onEnter={noMatchRedirect} />
      </Router>
    </MuiThemeProvider>
  </Provider>
)};

@observer
class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleUnload = this.handleUnload.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
    // if (process.env.NODE_ENV === 'production') {
    //   const sentryUrlSetting = 'https://482fabef186d48f8ac11d298ae290d62@sentry.skyresponse.com/2';
    //   Sentry.init({
    //     dsn: sentryUrlSetting,
    //     /*beforeSend(event, hint) {
    //       // Check if it is an exception, and if so, show the report dialog
    //       if (event.exception) {
    //         Sentry.showReportDialog({ eventId: event.event_id });
    //       }
    //       return event;
    //     }*/
    //     integrations: [new Integrations.BrowserTracing()],
    //     tracesSampleRate: 1.0
    //   });
    // }
    configureAutoSendingLogs();
    SettingsStore.on(constants.SETTINGS_CHANGE, this.handleSettingsChange);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
    SettingsStore.off(constants.SETTINGS_CHANGE, this.handleSettingsChange);
  }

  handleUnload(e) {
    const message = '';
    e.preventDefault();
    e.returnValue = '';
    (e || window.event).returnValue = message;
    return message;
  }

  handleSettingsChange = () => {
    hasSendLogsSetting = SettingsStore.getValueByKey('UPLOAD_BROWSER_LOG', 'boolean');
  };

  onClickReload = () => {
    authentication.clearLocalStorage();
    window.location.reload();
  };

  render() {
    if (localization.isLoaded) {
      return (
        // <SentryErrorBoundary>
        <>
          <Routes />
          <UserInactivity />
        </>
        // </SentryErrorBoundary>
      );
    }

    if (localization.hasError) {
      return (
        <div className="btn-reload-page" data-cy="btnReloadPage">
          <p className="error-text">{localization.errorMessage}</p>
          <button className="button-small-blue" type="button" onClick={this.onClickReload}>
            Internal error occured, please reload the page
          </button>
        </div>
      );
    }

    return <SpinnerOverlay />;
  }
}

ReactDOM.render(<App />, document.getElementById('app'));
