import React, { useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';

export const TruncatedText = ({
  text,
  trigger,
  maxlength = Infinity,
  position = 'top center',
}: {
  text: string;
  trigger?: JSX.Element;
  maxlength?: number;
  position?: PopupPosition;
}) => {
  const popupRef = useRef<any>(null);

  let textToUse = text || '';
  if (textToUse.length > maxlength) {
    textToUse = textToUse.slice(0, maxlength) + '...';
  }

  if (!trigger && !textToUse) {
    return null;
  }

  useEffect(() => {
    if (popupRef.current) {
      const popup = document.getElementById('popup-root');
      if (popup) {
        const parent = popup.parentElement
        popup.remove();
        parent?.append(popup);
      }
    }
  }, []);

  return (
    <Popup
      ref={popupRef}
      trigger={() => trigger ? trigger : <span style={{ cursor: 'pointer' }}>{textToUse}</span>}
      position={position}
      on={['hover', 'focus']}
      closeOnDocumentClick
      closeOnEscape
      repositionOnResize
      keepTooltipInside
      arrow={false}
      className='popup-tooltip-custom'
      contentStyle={{ zIndex: 1000 }}
    >
      <span className='truncated-text'>{text}</span>
    </Popup>
  )
}
