import appDispatcher from '../../app_dispatcher';
import authStore from '../../authentication';
import { ActionPruneLocalDataById } from '../alarms/alarm_active_store_actions';
import UserStore from './users_store';

const userStore = new UserStore();

export interface LoadServerUsers {
  type: 'LOAD_SERVER_USERS';
}

export interface LoadServerUserDetails {
  type: 'LOAD_SERVER_USER_DETAILS';
  user_id: string;
  alarm_id: string;
}

export interface LoadServerUserHistory {
  type: 'LOAD_SERVER_USER_HISTORY';
  user_id: string;
  alarm_id: string;
  force: boolean;
}

export interface LogoutClear {
  type: 'LOGOUT_CLEAR';
}

export interface StartUpdatingUserHistory {
  type: 'START_UPDATING_USER_HISTORY';
  user_id: string;
}

export interface StopUpdatingUserHistory {
  type: 'STOP_UPDATING_USER_HISTORY';
}

export type Actions =
  | LoadServerUsers
  | LoadServerUserDetails
  | LoadServerUserHistory
  | LogoutClear
  | StartUpdatingUserHistory
  | ActionPruneLocalDataById
  | StopUpdatingUserHistory;

appDispatcher.register((payload: { source: string; action: Actions }) => {
  const { action } = payload;

  switch (action.type) {
    case 'LOAD_SERVER_USERS':
      userStore.loadUsers();
      break;
    case 'LOAD_SERVER_USER_DETAILS':
      if (action.user_id) {
        userStore.loadUserDetails(action.user_id, action.alarm_id || '');
        userStore.loadUserAbsenceDetails(action.user_id, action.alarm_id || '');
      }
      break;
    case 'LOAD_SERVER_USER_HISTORY':
      if (action.user_id) {
        if (!authStore.isSimpleLogin()) {
          userStore.loadUserHistory(action.user_id, action.alarm_id || '');
        }
      }
      break;
    case 'LOGOUT_CLEAR':
      userStore.getInitialData();
      break;
    case 'START_UPDATING_USER_HISTORY':
      if (!authStore.isSimpleLogin()) {
        userStore.subscribeForUserHistoryUpdates();
      }
      break;
    case 'STOP_UPDATING_USER_HISTORY':
      userStore.unSubscribeForUserHistoryUpdates();
      break;
    case 'ACTION_PRUNE_LOCAL_DATA_BY_ID':
      userStore.clearUserDetails(action.user_id);
      break;
  }
});

export default userStore;
