import React from 'react';
import Linkify from 'react-linkify';
import constants from '../../../json/constants.json';
import Section from '../../components/section';

export default class UserInfoSection extends React.Component {
  render() {
    const { userDetails, widget } = this.props;

    return (
      <Section
        widget={widget}
        headerLocKey="ADDITIONAL_INFO"
        addBodyClassName="body-hor p-1em"
        headerLocOptions={{ ns: constants.NS_ALARM }}
        childrenLocOptions={{ ns: constants.NS_ALARM }}
      >
        <Linkify
          className="use-raw-text"
          properties={{ target: '_blank', style: { color: 'blue', fontWeight: 'bold' } }}
        >
          {userDetails.AdditionalInfo}
        </Linkify>
      </Section>
    );
  }
}
