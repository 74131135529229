import 'offline-js';
import React from 'react';
import NotificationSystem from 'react-notification-system';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import authentication from '../authentication';
import event_bus from '../event_bus';
import IdGenerator from '../id_generator';
import localization from '../localization';
import alarmActiveStore from '../stores/alarms/alarm_active_store_actions';
import alarmListStore from '../stores/alarms/alarm_list_store_actions';
import groupsStore from '../stores/groups_store';
import settingsStore from '../stores/settings_store';
import usersStore from '../stores/users/users_store_actions';
import Utils from '../utils';
import websocket from '../websocket';
import { Popup } from './popups/popup';
import PopupError from './popups/popup_error';

const bundle_hash = Utils.extractHash(),
  json_package = require('../../../package.json'),
  git_branch = process.env.GIT_BRANCH,
  git_long = process.env.GIT_LONG,
  key_combinations = require('../../json/key_combinations.json'),
  __package = Object.assign(
    {
      support: json_package.version + '?' + bundle_hash + '?' + git_branch + '?' + git_long,
      'git-branch': git_branch,
      'git-long': git_long,
      'bundle-hash': bundle_hash
    },
    json_package,
    { keybindings: key_combinations }
  );

const constants = require('../../json/constants.json'),
  _package = Utils.render_table_obj(__package);

interface Props { }

interface State {
  opened: boolean;
  opened_top: number;
  opened_left: number;
  showAppInfo: boolean;
  errors: any[];
  webSocket: boolean;
  offline: boolean;
  showConnectionInfo: boolean;
}

export default class SyncStatus extends React.Component<Props, State> {
  notificationSystem: NotificationSystem.System;

  constructor(props: Props) {
    super(props);
    this.state = {
      webSocket: !!websocket.webSocket,
      opened: false,
      errors: [],
      opened_top: 0,
      opened_left: 0,
      showAppInfo: false,
      offline: false,
      showConnectionInfo: false
    };
    //@ts-ignore
    window.Offline.check();
  }

  componentDidMount() {
    //@ts-ignore
    window.Offline.on('down', () => {
      this._onOffline(true);
    });
    //@ts-ignore
    window.Offline.on('up', () => {
      this._onOffline(false);
    });
    event_bus.on(constants.WEB_SOCKET_OPEN, this._onWebSocketOpen);
    event_bus.on(constants.WEB_SOCKET_CLOSE, this._onWebSocketClose);
    event_bus.on(constants.WEB_SOCKET_ERROR, this._onWebSocketError);

    settingsStore.on(constants.PENDING_REPORT_SERVER_ERROR, this._onSyncError);
    settingsStore.on(constants.REGISTER_TELEPHONE_SERVER_ERROR, this._onSyncError);

    alarmActiveStore.on(constants.COPY_TO_CLIPBOARD, this._onCopyToClipboard);

    alarmListStore.on(constants.ALARMS_SYNC_ERROR, this._onSyncError);
    alarmActiveStore.on(constants.ALARM_DETAILS_SYNC_ERROR, this._onSyncError);
    alarmActiveStore.on(constants.ALARM_ATTACHMENTS_SYNC_ERROR, this._onSyncError);
    groupsStore.on(constants.RESPONDENT_GROUPS_SYNC_ERROR, this._onSyncError);
    groupsStore.on(constants.RESPONDENT_GROUP_DETAILS_SYNC_ERROR, this._onSyncError);
    settingsStore.on(constants.SETTINGS_SYNC_ERROR, this._onSyncError);
    usersStore.on(constants.USERS_SYNC_ERROR, this._onSyncError);
    usersStore.on(constants.USER_DETAILS_SYNC_ERROR, this._onSyncError);
    usersStore.on(constants.USER_HISTORY_SYNC_ERROR, this._onSyncError);

    settingsStore.on(constants.PENDING_REPORT_SERVER_SUCCESS, this._onSyncSuccess);
    settingsStore.on(constants.REGISTER_TELEPHONE_SERVER_SUCCESS, this._onSyncSuccess);

    alarmListStore.on(constants.ALARMS_SYNC_SUCCESS, this._onSyncSuccess);
    alarmActiveStore.on(constants.ALARM_DETAILS_SYNC_SUCCESS, this._onSyncSuccess);
    alarmActiveStore.on(constants.ALARM_ATTACHMENTS_SYNC_SUCCESS, this._onSyncSuccess);
    groupsStore.on(constants.RESPONDENT_GROUPS_SYNC_SUCCESS, this._onSyncSuccess);
    groupsStore.on(constants.RESPONDENT_GROUP_DETAILS_SYNC_SUCCESS, this._onSyncSuccess);
    settingsStore.on(constants.SETTINGS_SYNC_SUCCESS, this._onSyncSuccess);
    usersStore.on(constants.USERS_SYNC_SUCCESS, this._onSyncSuccess);
    usersStore.on(constants.USER_DETAILS_SYNC_SUCCESS, this._onSyncSuccess);
    usersStore.on(constants.USER_HISTORY_SYNC_SUCCESS, this._onSyncSuccess);
  }

  componentWillUnmount() {
    //@ts-ignore
    window.Offline.off('down', this._onOffline);
    //@ts-ignore
    window.Offline.off('up', this._onOffline);

    event_bus.off(constants.WEB_SOCKET_OPEN, this._onWebSocketOpen);
    event_bus.off(constants.WEB_SOCKET_CLOSE, this._onWebSocketClose);
    event_bus.off(constants.WEB_SOCKET_ERROR, this._onWebSocketError);

    settingsStore.off(constants.PENDING_REPORT_SERVER_ERROR, this._onSyncError);
    settingsStore.off(constants.REGISTER_TELEPHONE_SERVER_ERROR, this._onSyncError);

    alarmActiveStore.off(constants.COPY_TO_CLIPBOARD, this._onCopyToClipboard);

    alarmListStore.off(constants.ALARMS_SYNC_ERROR, this._onSyncError);
    alarmActiveStore.off(constants.ALARM_DETAILS_SYNC_ERROR, this._onSyncError);
    alarmActiveStore.off(constants.ALARM_ATTACHMENTS_SYNC_ERROR, this._onSyncError);
    groupsStore.off(constants.RESPONDENT_GROUPS_SYNC_ERROR, this._onSyncError);
    groupsStore.off(constants.RESPONDENT_GROUP_DETAILS_SYNC_ERROR, this._onSyncError);
    settingsStore.off(constants.SETTINGS_SYNC_ERROR, this._onSyncError);
    usersStore.off(constants.USERS_SYNC_ERROR, this._onSyncError);
    usersStore.off(constants.USER_DETAILS_SYNC_ERROR, this._onSyncError);
    usersStore.off(constants.USER_HISTORY_SYNC_ERROR, this._onSyncError);

    settingsStore.off(constants.PENDING_REPORT_SERVER_SUCCESS, this._onSyncSuccess);
    settingsStore.off(constants.REGISTER_TELEPHONE_SERVER_SUCCESS, this._onSyncSuccess);

    alarmListStore.off(constants.ALARMS_SYNC_SUCCESS, this._onSyncSuccess);
    alarmActiveStore.off(constants.ALARM_DETAILS_SYNC_SUCCESS, this._onSyncSuccess);
    alarmActiveStore.off(constants.ALARM_ATTACHMENTS_SYNC_SUCCESS, this._onSyncSuccess);
    groupsStore.off(constants.RESPONDENT_GROUPS_SYNC_SUCCESS, this._onSyncSuccess);
    groupsStore.off(constants.RESPONDENT_GROUP_DETAILS_SYNC_SUCCESS, this._onSyncSuccess);
    settingsStore.off(constants.SETTINGS_SYNC_SUCCESS, this._onSyncSuccess);
    usersStore.off(constants.USERS_SYNC_SUCCESS, this._onSyncSuccess);
    usersStore.off(constants.USER_DETAILS_SYNC_SUCCESS, this._onSyncSuccess);
    usersStore.off(constants.USER_HISTORY_SYNC_SUCCESS, this._onSyncSuccess);
  }

  toggleStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    let target = e.currentTarget;
    let { opened } = this.state;
    this.setState({
      opened: !opened,
      opened_top: target.offsetTop,
      opened_left: target.offsetLeft + target.offsetWidth
    });
  };

  resetErrors = (e: React.MouseEvent<HTMLButtonElement>) => {
    let target = e.currentTarget;
    this.setState({
      opened: false,
      errors: [],
      opened_top: target.offsetTop,
      opened_left: target.offsetLeft + target.offsetWidth
    });
  };

  showAppInfo = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({ showAppInfo: true });
  };

  closeAppInfo = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset.click === 'close') {
      this.setState({ showAppInfo: false });
    }
  };

  closeConnectionInfo = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset.click === 'close') {
      this.setState({ showConnectionInfo: false });
    }
  };

  renderPackageContent() {
    return _package;
  }

  render() {
    let { opened, errors, webSocket, showAppInfo, offline } = this.state;
    let syncBtnClassName = 's-1em ',
      closeBtnClassName = 'w-100p',
      headerClassName = 'form-header-sm p-05em',
      headerText,
      notification =
        authentication.ls_get([authentication.getUserId(), constants.PHONE_SELECTED_KEY]) ===
        'true';
    // notification = true;
    if (errors.length || offline) {
      syncBtnClassName += ' form-btn-bg-dark-red';
      headerClassName += ' red';
      closeBtnClassName += ' button-small-red';
      headerText = localization.t('SERVICE_ARE_NOT_FULLY_FUNCTIONAL');
    } else if (notification && !webSocket) {
      syncBtnClassName += ' form-btn-bg-dark-yellow';
      headerClassName += ' yellow';
      closeBtnClassName += ' form-btn-yellow';
      headerText = localization.t('SERVICE_ARE_NOT_FULLY_FUNCTIONAL');
    } else {
      syncBtnClassName += ' form-btn-bg-dark-green';
      headerClassName += ' green';
      closeBtnClassName += ' button-small-green';
      headerText = localization.t('SERVICE_ARE_FULLY_FUNCTIONAL');
    }
    return (
      <div className="flex-0-0-auto flex-col-around p-rel p-025em">
        <button
          type="button"
          className={syncBtnClassName}
          onClick={this.toggleStatus}
          onContextMenu={this.showAppInfo}
        >
          &nbsp;
        </button>
        {opened ? (
          <div className="p-abs flex-col filter-window top-0-left-0">
            <div className={headerClassName}>{headerText}</div>
            {notification ? (
              webSocket ? (
                <div className="">{localization.t('WEB_SOCKET_IS_CONNECTED')}</div>
              ) : (
                <div className="">{localization.t('WEB_SOCKET_IS_DISCONNECTED')}!</div>
              )
            ) : null}
            {errors.length ? (
              <div className="">{localization.t('LAST_REPORTED_ERRORS')}:</div>
            ) : null}
            {errors.map(objErr => {
              return <div key={objErr.uid}>{objErr.err}</div>;
            })}
            <button className={closeBtnClassName} onClick={this.resetErrors}>
              {localization.t('GOT_IT')}
            </button>
          </div>
        ) : null}
        <Popup
          onClick={this.closeAppInfo}
          show={!!showAppInfo}
          body={{ className: 'y-scroll', content: this.renderPackageContent() }}
          footer={{
            className: '',
            content: (
              <div className="flex-row-around w-100p">
                <div className="flex-col-around">
                  <button className="button-small-secondary" type="button" data-click="close">
                    {localization.t('CLOSE')}
                  </button>
                </div>
              </div>
            )
          }}
        />
        <PopupError
          onClick={this.closeConnectionInfo}
          message={localization.t('INTERNET_CONNECTION_ABORT')}
          show={this.state.showConnectionInfo}
        />

        <NotificationSystem
          ref={(node: NotificationSystem.System) => (this.notificationSystem = node)}
        />
      </div>
    );
  }

  _onOffline = (flag: boolean) => {
    this.setState({ offline: flag, showConnectionInfo: flag });
  };

  _onSyncError = (event: Event, err: string | null, xhr: XMLHttpRequest, alarmId: string | null) => {
    let { errors } = this.state;

    errors = [{ event, err, xhr, uid: IdGenerator.generateId() }];
    this.setState({
      errors
    });

    // if we try to sync an alarm that we dont have access to anymore we want to delete it from the list
    if (xhr?.status === 404 && alarmId) {
      appHistory.replace(constants.PATH_DASHBOARD);
      alarmListStore.removeAlarm(alarmId);
      this.notificationSystem.addNotification({
        title: localization.t('ALARM_HAS_BEEN_TRANSFERRED'),
        level: 'info',
        autoDismiss: 5, // seconds
        position: 'br'
      });
    }
  };

  _onSyncSuccess = () => {
    let { opened } = this.state;
    if (opened) {
      // do nothing. Perhaps user is coping error text now...
    } else {
      this.setState({
        errors: []
      });
    }
  };

  _onWebSocketOpen = () => {
    this.setState({
      webSocket: !!websocket.webSocket
    });

    // force load the alarms once when the websocket is initiated
    appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_ALARMS });
    appDispatcher.handleServerAction({ type: constants.FETCH_ACTIVE_CALLS });

    if (alarmActiveStore && typeof alarmActiveStore.getAlarmDetails === "function") {
      appDispatcher.handleServerAction({
        type: constants.LOAD_SERVER_ALARM_DETAILS,
        alarm_id: alarmActiveStore?.currentAlarmId
      });

      const userId = alarmActiveStore?.getAlarmDetails(alarmActiveStore?.currentAlarmId)?.UserId;
      appDispatcher.handleServerAction({
        type: constants.LOAD_SERVER_USER_HISTORY,
        alarm_id: alarmActiveStore?.currentAlarmId,
        user_id: userId
      });
    }
  };

  _onWebSocketClose = () => {
    this.setState({
      webSocket: !!websocket.webSocket
    });
  };

  _onWebSocketError = (e: Event, err: string | null) => {
    // this.setState({
    //   errors: [{ event: e, err: Utils.prototype.print_obj(err)}]
    // });
  };

  _onCopyToClipboard = (e: Event, data: string) => {
    this.notificationSystem.addNotification({
      title: localization.t('COPIED_TO_CLIPBOARD'),
      level: 'info',
      autoDismiss: 10, // seconds
      position: 'br'
    });
  };
}
