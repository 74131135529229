import { LogModel } from "development/js/interfaces/backend_model";
import React from "react";
import Grid from '../../../components/grid';
import { LogsHeaders } from "./LogsSection";

interface LogTableWrapperProps {
    logData: {
        date: string;
        logs: LogModel[];
    },
    headers: LogsHeaders[];
    filterType: string;
}

export const LogTableWrapper = ({
    logData,
    headers,
    filterType
}: LogTableWrapperProps) => (
    <>
        <div className="logs-date-header">{logData.date}</div>
        <Grid
            headers={headers}
            table={true}
            rowKey={'Id'}
            tableClassName="form-table w-100p"
            template={filterType}
            rows={logData.logs}
            maxWidthCol={['LogEventCode']}
        />
    </>
);