import * as Logger from 'js-logger';
import appDispatcher from '../../app_dispatcher';
import AlarmActiveStore from './alarm_active_store';

const alarmActiveStore = new AlarmActiveStore();
export interface ActionClearAlarm {
  type: 'ACTION_CLEAR_ALARM';
  alarm_id: string;
}

export interface ActionPruneLocalDataById {
  type: 'ACTION_PRUNE_LOCAL_DATA_BY_ID';
  alarm_id: string;
  user_id: string;
}

export interface ActionAddAlarmReasonText {
  type: 'ACTION_ADD_ALARM_REASON_TEXT';
  alarm_id: string;
  user_id: string;
  message: string;
}
export interface ActionAddAlarmActionText {
  type: 'ACTION_ADD_ALARM_ACTION_TEXT';
  alarm_id: string;
  user_id: string;
  message: string;
}

export interface LoadServerAlarmDetails {
  type: 'LOAD_SERVER_ALARM_DETAILS';
  alarm_id: string;
}
export interface ClearServerAlarmDetails {
  type: 'CLEAR_SERVER_ALARM_DETAILS';
  alarm_id: string;
}
export interface ClearServerAlarmDetailsUpdates {
  type: 'CLEAR_SERVER_ALARM_DETAILS_UPDATES';
  alarm_id: string;
}

export interface StopUpdatingServerAlarms {
  type: 'STOP_UPDATING_SERVER_ALARMS';
}

export interface LoadServerAlarmDetailsUpdates {
  type: 'LOAD_SERVER_ALARM_ATTACHMENTS';
  alarm_id: string;
}

export interface StartUpdatingServerAlarmDetails {
  type: 'START_UPDATING_SERVER_ALARM_DETAILS';
  alarm_id: string;
}
export interface StopUpdatingServerAlarmDetails {
  type: 'STOP_UPDATING_SERVER_ALARM_DETAILS';
}

export interface SendAlarmCommand {
  type: 'SEND_ALARM_COMMAND';
  alarmId: string;
  commandId: string;
  commandArgs: string;
  commandMessage?: string;
  id?: number;
}
export interface LogoutClear {
  type: 'LOGOUT_CLEAR';
}
export type Actions =
  | ActionClearAlarm
  | ActionAddAlarmReasonText
  | ActionAddAlarmActionText
  | LoadServerAlarmDetails
  | ClearServerAlarmDetails
  | ClearServerAlarmDetailsUpdates
  | StopUpdatingServerAlarms
  | LoadServerAlarmDetailsUpdates
  | StartUpdatingServerAlarmDetails
  | StopUpdatingServerAlarmDetails
  | ActionPruneLocalDataById
  | SendAlarmCommand
  | LogoutClear;

appDispatcher.register((payload: { source: string; action: Actions }) => {
  let action = payload.action;

  switch (action.type) {
    case 'ACTION_PRUNE_LOCAL_DATA_BY_ID':
      alarmActiveStore.pruneAlarmById(action.alarm_id);
      break;

    case 'ACTION_CLEAR_ALARM':
      Logger.info('alarms_store => ACTION_CLEAR_ALARM => _clearAlarm');
      alarmActiveStore._clearAlarm(action.alarm_id);

      break;

    case 'ACTION_ADD_ALARM_REASON_TEXT':
      alarmActiveStore._addReasonTextToAlarmLog(action.alarm_id, action.user_id, action.message);
      break;

    case 'ACTION_ADD_ALARM_ACTION_TEXT':
      alarmActiveStore._addActionTextToAlarmLog(action.alarm_id, action.user_id, action.message);
      break;

    case 'LOAD_SERVER_ALARM_DETAILS':
      if (action.alarm_id) {
        alarmActiveStore.loadAlarmDetails(action.alarm_id);
      }
      break;

    case 'CLEAR_SERVER_ALARM_DETAILS':
      if (action.alarm_id) {
        alarmActiveStore.clearAlarmDetails(action.alarm_id);
      }
      break;

    case 'CLEAR_SERVER_ALARM_DETAILS_UPDATES':
      if (action.alarm_id) {
        alarmActiveStore.clearAlarmAttachments(action.alarm_id);
      }
      break;

    case 'LOAD_SERVER_ALARM_ATTACHMENTS':
      if (action.alarm_id) {
        alarmActiveStore.loadAlarmAttachments(action.alarm_id);
      }
      break;

    case 'START_UPDATING_SERVER_ALARM_DETAILS':
      alarmActiveStore.subscribeForAlarmUpdates();
      break;

    case 'STOP_UPDATING_SERVER_ALARM_DETAILS':
      alarmActiveStore.unSubscribeForAlarmUpdates();
      break;

    case 'SEND_ALARM_COMMAND':
      alarmActiveStore.sendCommand(
        action.alarmId,
        action.commandId,
        action.commandArgs,
        action.commandMessage,
        action.id
      );
      break;

    case 'LOGOUT_CLEAR':
      alarmActiveStore.getInitialData();
      break;
  }
});

export default alarmActiveStore;
