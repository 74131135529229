import React from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import LabelValue from './LabelValue';

export default class VeoliaAlarmText extends React.Component {
  render() {
    const { alarmT, userDetails, alarmDetails, instance, widget } = this.props;

    return (
      <Section
        widget={widget}
        addBodyClassName="p-t0-l0-1"
        headerLocKey="ALARM_TEXT"
        headerLocOptions={{ ns: constants.NS_ALARM }}
      >
        <LabelValue
          alarmT={alarmT}
          position="col"
          value={alarmDetails.TextMessage}
          linkify={instance.linkifyValue}
        />
      </Section>
    );
  }
}
