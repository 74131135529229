import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ObservableArray } from 'mobx/lib/types/observablearray';
import React from 'react';
import localization from '../localization';
import Utils from '../utils';
import IconArrow from './icon_arrow';
import PopupConfirmPreset from './popups/popup_confirm_preset';

export interface PresetModel {
  AllowConfigure: boolean;
  CameraPresetId: number;
  Order: number;
  TitleKey: string;
}

interface Props {
  presets: ObservableArray<PresetModel>;
  fullscreen: boolean;
  alarmId: string;
  cameraId: number;
  disabled: boolean;
  onSendPresetPosition: (
    alarmId: string,
    cameraId: number,
    currentCommand: [number, string]
  ) => void;
}

interface State {
  showContent: boolean;
}

@observer
export default class PresetPosition extends React.Component<Props, State> {
  @observable showContent = observable.box(false);
  @observable showConfirmModal = observable.box(false);
  currentCommand: [number, string] | null = null;

  toggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.showContent.set(!this.showContent.get());
  };

  resetPosition = (commandId: number) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      this.showConfirmModal.set(true);
      this.currentCommand = [commandId, 'reset'];
    };
  };

  applyPosition = (commandId: number) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      this.props.onSendPresetPosition(this.props.alarmId, this.props.cameraId, [
        commandId,
        'apply'
      ]);
    };
  };

  handlePopupConfirmClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset.click === 'close') {
      this.currentCommand = null;
    } else if (element.dataset.click === 'continue') {
      if (this.currentCommand) {
        this.props.onSendPresetPosition(
          this.props.alarmId,
          this.props.cameraId,
          this.currentCommand
        );
        this.currentCommand = null;
      }
    }
    this.showConfirmModal.set(false);
  };

  renderCommands = (preset: PresetModel) => {
    return (
      <div key={preset.CameraPresetId + preset.Order} className="m-tb-02em-rl-05em flex-center-w">
        <div style={{ wordBreak: 'break-all' }}>{localization.t(preset.TitleKey)}</div>
        {preset.AllowConfigure && (
          <button
            key={preset.CameraPresetId}
            className="m-tb-02em-rl-05em button-small-blue"
            style={{ whiteSpace: 'nowrap' }}
            onClick={this.applyPosition(preset.CameraPresetId)}
            disabled={this.props.disabled}
          >
            {localization.t('APPLY_POSITION')}
          </button>
        )}
        {Number.isInteger(preset.CameraPresetId) && preset.CameraPresetId >= 0 ? (
          <button
            key={preset.CameraPresetId + 'reset'}
            className="m-tb-02em-rl-05em button-small-blue"
            style={{ whiteSpace: 'nowrap' }}
            onClick={this.resetPosition(preset.CameraPresetId)}
            disabled={this.props.disabled}
          >
            {localization.t('RESET_POSITION')}
          </button>
        ) : null}
      </div>
    );
  };

  render() {
    const { presets, fullscreen } = this.props;
    const fullscreenMode = fullscreen ? 'fullscreen-presets' : '';

    return (
      <div className={`section ${fullscreenMode}`} style={{ marginBottom: '5px' }}>
        <div className="flex-row flex-row-between section-header bold">
          <div className="p-1em flex-1-1-0px">{localization.t('PRESET_POSITIONS')}</div>
          <div className="flex-row flex-0-0-auto section-icon">
            <button type="button" className="button-small-blue" onClick={this.toggle}>
              {this.showContent.get() ? <IconArrow icon="up" /> : <IconArrow icon="down" />}
            </button>
          </div>
        </div>
        {this.showContent.get() && (
          <div className="p-1em flex-row-around flex-row-wrap  bg-c-white-primary">
            {presets.sort(Utils.sortByOrder).map(preset => this.renderCommands(preset))}
          </div>
        )}
        <PopupConfirmPreset
          onClick={this.handlePopupConfirmClick}
          message={localization.t('CONFIRM_RESET_POSITION')}
          show={this.showConfirmModal.get()}
        />
      </div>
    );
  }
}
