import { fetchActiveCalls } from './actions/calls';
import appDispatcher from './app_dispatcher';
import authStore from './authentication';
import event_bus from './event_bus';

const constants = require('../json/constants.json');
const endpoints = require('../json/endpoints.json');

class WebSocketController {
  in_use: boolean;
  connectionPing: number | undefined;
  connectionPingTime: number = 20000;
  webSocket: WebSocket | null;
  answerFromServerReceived = false;
  answerFromServerTime: number = 10000;
  checkServerAnswerTimeout: number | undefined;
  retryWebsocketConnection: number = 5000;
  /**
   * use this in one-directional data workflow
   */
  initWebsocket() {
    this.in_use = true;
    this.create();
    this.addSocketListeners();
    this.createPingInterval();
  }

  send(message: any) {
    this.webSocket && this.webSocket.send(message);
  }

  create() {
    try {
      let protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://',
        token = authStore.getUserLSData(constants.ACCESS_TOKEN_KEY),
        url = `${protocol}${window.location.host}/${endpoints.WEB_SOCKET.url}?token=${token}`;
      this.webSocket = new WebSocket(url);
      appDispatcher.handleServerAction({
        type: constants.STOP_UPDATING_SERVER_ALARM_DETAILS
      });
      appDispatcher.handleServerAction({
        type: constants.STOP_UPDATING_SERVER_ALARMS
      });
      appDispatcher.handleServerAction({ type: constants.STOP_UPDATING_USER_HISTORY });
      appDispatcher.handleServerAction({ type: constants.STOP_UPDATING_CALLS });
    } catch (error) {
      console.log('create() error!', error);
    }
  }

  close() {
    if (this.webSocket) {
      this.webSocket.close();
    }
    appDispatcher.handleServerAction({ type: constants.START_UPDATING_SERVER_ALARM_DETAILS });
    if (!authStore.isSimpleLogin()) {
      appDispatcher.handleServerAction({ type: constants.START_UPDATING_SERVER_ALARMS });
      appDispatcher.handleServerAction({ type: constants.START_UPDATING_USER_HISTORY });
      appDispatcher.handleServerAction({ type: constants.START_UPDATING_CALLS });
    }
  }

  clean() {
    this.removeSocketListeners();
    if (this.webSocket) {
      this.webSocket = null;
    }
    window.clearInterval(this.connectionPing);
    window.clearTimeout(this.checkServerAnswerTimeout);
    // Clear values as clearInterval only cancels the timer and without clearing the value
    this.connectionPing = undefined;
    this.checkServerAnswerTimeout = undefined;
  }

  destroy(clearIntervals = true) {
    console.info('Websocket destroy');
    this.in_use = false;
    this.close();
    this.clean();
    if (clearIntervals) {
      window.clearInterval(this.connectionPing);
      window.clearTimeout(this.checkServerAnswerTimeout);
      // Clear values as clearInterval only cancels the timer and without clearing the value
      this.connectionPing = undefined;
      this.checkServerAnswerTimeout = undefined;
    }
  }

  createPingInterval() {
    this.answerFromServerReceived = false;

    this.connectionPing = window.setInterval(() => {
      if (this.webSocket && this.webSocket.readyState === this.webSocket.OPEN) {
        this.webSocket.send(constants.WEB_SOCKET_PING);
        this.createServerAnswerTimeout();
      }
    }, this.connectionPingTime);
  }

  createServerAnswerTimeout() {

    this.checkServerAnswerTimeout = window.setTimeout(() => {
      if (!this.answerFromServerReceived) {
        this.close();
      }
      this.answerFromServerReceived = false;
    }, this.answerFromServerTime);
  }

  addSocketListeners() {
    let socket = this.webSocket;
    if (!socket) {
      return;
    }
    this.removeSocketListeners();
    socket.onopen = this.onOpen;
    socket.onclose = this.onClose;
    socket.onmessage = this.onMessage;
    socket.onerror = this.onError;
  }

  removeSocketListeners() {
    let socket = this.webSocket;
    if (!socket) {
      return;
    }
    socket.onopen = null;
    socket.onclose = null;
    socket.onmessage = null;
    socket.onerror = null;
  }

  onOpen = (e: Event) => {
    event_bus.trigger(constants.WEB_SOCKET_OPEN, e);
  };

  onClose = (e: Event) => {
    console.info('Websocket closed');
    this.clean();

    appDispatcher.handleServerAction({ type: constants.START_UPDATING_SERVER_ALARM_DETAILS });
    if (!authStore.isSimpleLogin()) {
      appDispatcher.handleServerAction({ type: constants.START_UPDATING_SERVER_ALARMS });
      appDispatcher.handleServerAction({ type: constants.START_UPDATING_USER_HISTORY });
      appDispatcher.handleServerAction({ type: constants.START_UPDATING_CALLS });
    }

    if (this.in_use) {
      event_bus.trigger(constants.WEB_SOCKET_CLOSE, e);

      setTimeout(() => {
        this.initWebsocket();
      }, this.retryWebsocketConnection);
    }
  };

  onMessage = (e: MessageEvent) => {
    let parsedMessageData;

    if (e.data) {
      try {
        parsedMessageData = JSON.parse(e.data);
      } catch (err) {
        return event_bus.trigger(constants.WEB_SOCKET_ERROR, err);
      }
    }
    // we dont need to ping the backend to check for a websocket connection if we know
    // we are recieving messages, thus reset the ping on message
    window.clearInterval(this.connectionPing);
    this.createPingInterval();
    this.answerFromServerReceived = true;

    switch (parsedMessageData.type) {
      case constants.WS_PUSH_ALARM_FULL_REFRESH:
        event_bus.trigger(constants.WS_PUSH_ALARM_FULL_REFRESH, parsedMessageData);
        break;
      case constants.WS_PUSH_ALARM_UPDATE:
        event_bus.trigger(constants.WS_PUSH_ALARM_UPDATE, parsedMessageData);
        break;
      case constants.WS_PUSH_ALARM_REMOVE:
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_ALARMS,
          keep_unique_alarms: false
        });
        event_bus.trigger(constants.WS_PUSH_ALARM_REMOVE, parsedMessageData);
        break;
      case constants.WS_PUSH_PING:
        appDispatcher.handleServerAction({ type: constants.PING_REPORT });
        break;
      case constants.WS_PUSH_USER_UPDATE:
        appDispatcher.handleServerAction({
          type: constants.LOAD_SERVER_USER_DETAILS,
          user_id: parsedMessageData.userId,
          alarm_id: parsedMessageData.alarmId
        });
        break;
      case constants.WS_PUSH_LOGGED_IN_ON_OTHER: {
        const currentDeviceToken = authStore.getUserLSData(constants.LS_DEVICE_TOKEN);
        if (!currentDeviceToken) {
          return;
        }
        if (parsedMessageData.deviceToken !== currentDeviceToken) {
          event_bus.trigger(constants.WS_PUSH_LOGGED_IN_ON_OTHER, parsedMessageData);
        }
        break;
      }
      case constants.WS_PUSH_ACCOUNT_REVOKED:
        event_bus.trigger(constants.WS_PUSH_ACCOUNT_REVOKED, parsedMessageData);
        break;
      case constants.WS_CALL_UPDATE:
        fetchActiveCalls();
        break;
      case constants.WS_PUSH_REMIND:
        event_bus.trigger(constants.PLAY_REMIND_SOUND, parsedMessageData);
        break;
      case constants.WEB_SOCKET_PONG:
        break;
      default:
    }

    console.log(constants.WEB_SOCKET_MESSAGE, parsedMessageData);

    this.ackMessage(parsedMessageData.pushId);
    return;
  };

  ackMessage = (pushId: string) => {
    console.debug(`send ACK message for push id: ${pushId}`);
    this.send(JSON.stringify({ frontendevent: 'ack', pushId }));
  };

  onError = (err: any) => {
    console.error('Websocket throw an error: ', err);
    event_bus.trigger(constants.WEB_SOCKET_ERROR, err);
  };

  checkWebSocket(context: any, update: any, ...data: any[]) {
    if (!update || typeof update !== 'function') {
      return () => { };
    }
    return () => {
      let ws = this.webSocket;
      if (ws && ws.readyState === ws.OPEN) {
        // skip update
      } else if (data) {
        update.apply(context, data);
      } else {
        update.apply(context);
      }
    };
  }
}

export default new WebSocketController();
