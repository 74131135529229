import React from 'react';

interface Props {
  width?: number;
  height?: number;
  widthUnits?: string;
  heightUnits?: string;
  static?: boolean;
}

export default class Spinner extends React.Component<Props> {
  render() {
    let defaultWidth = 65,
      defaultWidthUnits = 'px',
      defaultHeight = 65,
      defaultHeightUnits = 'px',
      width = this.props.width ? this.props.width : defaultWidth,
      height = this.props.height ? this.props.height : defaultHeight,
      widthUnits = this.props.widthUnits ? this.props.widthUnits : defaultWidthUnits,
      heightUnits = this.props.heightUnits ? this.props.heightUnits : defaultHeightUnits,
      svgClassName = this.props.static ? '' : 'spinner',
      circleClassName = this.props.static ? 'path-static' : 'path';
    return (
      <svg
        className={svgClassName}
        width={width + widthUnits}
        height={height + heightUnits}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={circleClassName}
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    );
  }
}
