import React from 'react';
import { ModalWindow } from '../components/modal_window';
import localization from '../localization';
import constants from '../../json/constants.json';

export const SectionFullscreenModal = (
  {
    isOpen,
    onClose,
    title,
    body
  } : {
    isOpen: boolean,
    onClose: () => void,
    title: string,
    body: React.ReactNode
  }) => {
  const alarmT = localization.useNSt(constants.NS_ALARM);

  if (!isOpen) return null;

  return (
    <ModalWindow
      popupClassName='section-fullscreen-modal'
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      footer={(
        <button
          className='button-large button-large-secondary'
          onClick={onClose}
        >
          {alarmT('CANCEL')}
        </button>
      )}
      footerClassName='flex-row-center p-1em p-r-125em'
    />
  );
}
