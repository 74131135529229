import React from 'react';

interface Props {
  icon: string;
}

export default class IconArrow extends React.Component<Props> {
  render() {
    const { icon } = this.props;
    if (icon === 'up') {
      return (
        <svg
          className="icon-arrow up"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="-177 178.2 29.9 41.8"
        >
          <path d="M-162.8,178.2l15.7,14.9h-11.2V220h-7.5v-26.9H-177L-162.8,178.2z" />
        </svg>
      );
    }

    if (icon === 'down') {
      return (
        <svg
          className="icon-arrow down"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="-177 178.2 29.9 41.8"
        >
          <path d="M-161.3,220l-15.7-14.9h11.2v-26.9h7.5v26.9h11.2L-161.3,220z" />
        </svg>
      );
    }

    return null;
  }
}
