import React from 'react';
import createReactClass from 'create-react-class';

import constants from '../../../json/constants.json';
import dateTime from '../../date_time';
import localization from '../../localization';
import validation from '../../validation';

export default createReactClass({
  parseNotifyAfter(notifyAfter) {
    if (notifyAfter) {
      let now_date = new Date(),
        after_date = new Date(notifyAfter),
        difference = dateTime.getTimeDifference(after_date, now_date);
      if (now_date < after_date && difference.days < 1) {
        return difference;
      }
    }
    return false;
  },

  getInitialState(notifyAfter) {
    let parsed = this.parseNotifyAfter(notifyAfter || this.props.notifyAfter);
    return {
      is_hours_invalid: false,
      is_minutes_invalid: false,
      hoursValue: parsed ? parsed.hours : 0,
      minutesValue: parsed ? parsed.minutes : 30,
      resetStateOn: notifyAfter ? notifyAfter.resetStateOn : null
    };
  },

  componentDidMount() {
    this.setState({ resetStateOn: this.props.resetStateOn });
  },

  componentWillReceiveProps(nextProps) {
    let { resetStateOn } = nextProps;
    if (resetStateOn && this.state.resetStateOn !== resetStateOn) {
      this.setState(this.getInitialState(nextProps.notifyAfter));
    }
  },

  handleChangeTime(e) {
    let target = e.currentTarget;
    if (target && target.name) {
      let name = target.name,
        validator = validation[`validate_${name}_value`],
        value_key = `${name}Value`,
        invalid_value_key = `is_${name}_invalid`,
        is_invalid = validator(target.value),
        newState = {
          [invalid_value_key]: is_invalid,
          [value_key]: target.value
        };
      this.setState(newState);
    }
  },

  render() {
    const minute_ticks = 1000 * 60;
    let { notifyAfter } = this.props;
    let { hoursValue, minutesValue, is_hours_invalid, is_minutes_invalid } = this.state;
    let dateTimeT = localization.useNSt(constants.NS_DATETIME),
      alarmT = localization.useNSt(constants.NS_ALARM),
      now_ticks = Date.now();
    return (
      <div className="flex-col">
        {notifyAfter ? (
          <div className="flex-row-between item-05em">
            <div className="flex-col-around bold">{alarmT('NOTIFICATION_IS_SET_AT')}:</div>
            <div className="flex-col-around p-l-1em">{dateTime.toLocaleDateTime(notifyAfter)}</div>
          </div>
        ) : null}
        <div className="flex-row-between item-05em">
          <div className="flex-col-around bold">{alarmT('NOTIFY_ME_ABOUT_THIS_ALARM')}:</div>
          <div className="flex-col-around p-l-1em">
            {dateTime.toLocaleDateTime(
              now_ticks + minutesValue * minute_ticks + hoursValue * minute_ticks * 60
            )}
          </div>
        </div>
        <div className="flex-row-center item-05em">
          <div className="flex-col-around bold">{dateTimeT('HOUR_plural')}:</div>
          <div className="flex-col-around p-l-1em">
            <input
              type="number"
              min="0"
              max="23"
              step="1"
              name="hours"
              maxLength="2"
              value={hoursValue}
              onChange={this.handleChangeTime}
            />
          </div>
          {is_hours_invalid ? (
            <label className="error-text p-l-1em">{alarmT('HOURS_INVALID_MESSAGE')}!</label>
          ) : null}
        </div>
        <div className="flex-row-center item-05em">
          <div className="flex-col-around bold">{dateTimeT('MINUTE_plural')}:</div>
          <div className="flex-col-around p-l-1em">
            <input
              type="number"
              min="0"
              max="59"
              step="1"
              name="minutes"
              maxLength="2"
              value={minutesValue}
              onChange={this.handleChangeTime}
            />
          </div>
          {is_minutes_invalid ? (
            <label className="error-text p-l-1em">{alarmT('MINUTES_INVALID_MESSAGE')}!</label>
          ) : null}
        </div>
      </div>
    );
  }
});
