import authentication from '../authentication';

export function logoutWhenReferrer() {
  const logoutURL = authentication.ls_get('logoutURL');

  if (logoutURL) {
    authentication.ls_remove('logoutURL');
    window.location.assign(logoutURL);
    return true;
  }

  return false;
}
