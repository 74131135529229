import { AlarmDetailsModel, UserDetailsModel, Widget } from 'development/js/interfaces/backend_model';
import React from 'react';
import Linkify from 'react-linkify';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
import IconBell from '../../components/icons/icon_bell';
import IconCheckMark from '../../components/icons/icon_check_mark';
import IconExpand from '../../components/icons/icon_expand';
import Section from '../../components/section';
import Spinner from '../../components/spinner';
import { TruncatedText } from '../../components/truncatedText';
import enumDictionary from '../../enum_dictionary';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import localization from '../../localization';
import AlarmHelpers from '../../stores/alarms/alarm_helpers';
import planningViewStore from '../../stores/alarms/planning_view_store_actions';
import { isCommandUsed } from '../../utilities/commandButtonStateManager';
import { filterWidgetSettings } from '../../utilities/filterWidgetSettingsHelper';
import AssociateWithObject from './AssociateWithObject';
import CallType from './CallType';
import { CommandButton } from './CommandButton';
import FreeDropDown from './FreeDropDown';
import LabelValue from './LabelValue';

const constants = require('../../../json/constants.json');

const handledSettings = ['dividedCommandsView', 'includeAssociateWithObject', 'hideReminder', 'hideModalOnClear'];

interface Props {
  alarmDetails: AlarmDetailsModel;
  userDetails: UserDetailsModel;

  instance: any;
  readonly: boolean;
  isPlanningView: boolean;
  open_type: any;
  widget: Widget;
  withTextMessage: boolean;
  withInfoAboutAlarm: boolean;
  withTransmitterModel: boolean;
  templateType: any;
  headerLocOptions: any;
  onShowSearchUser: any;
  errorText: string;
  settings: string[];
  toggleType?: string;
  toggleState?: string
  fullscreenMode?: boolean;
}

class AlarmActionWidget extends React.Component<Props> {
  widgetSettings;
  fullscreenRef;
  constructor(props: Props) {
    super(props);

    // @ts-ignore
    this.fullscreenRef = React.createRef(null);

    this.widgetSettings =
      filterWidgetSettings(this.props.settings, handledSettings, this.props.widget?.widgetName);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keydownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keydownHandler);
  }

  keydownHandler = (e: any) => {
    if (e.ctrlKey && e.keyCode === 89) {
      // CTRL+Y
      const buttonsWrapper = document.getElementsByClassName('alarm-actions-wrapper');
      const buttonsToHighlight = buttonsWrapper[0].querySelector('button');
      buttonsToHighlight?.focus();
    }
  };

  updatePlanningViewNotes = (note: string) => {
    const {
      alarmDetails: { AlarmId },
    } = this.props;

    planningViewStore.updatePlannedAlarmNote(AlarmId, note);
  };

  render() {

    const { instance, readonly, isPlanningView = false, open_type } = this.props;
    const {
      widget,
      alarmDetails,
      userDetails,
      withTextMessage = true,
      withInfoAboutAlarm = true,
      withTransmitterModel = true,
      templateType,
      headerLocOptions,
      onShowSearchUser,
      errorText,
      toggleType,
      toggleState,
      fullscreenMode,
    } = this.props;
    let titleTicket = headerLocOptions
      ? localization.t(headerLocOptions, { ns: constants.NS_ALARM })
      : localization.t('ALARM_ACTION', { ns: constants.NS_ALARM });

    let { clearError, pendingClear, alarm_id, notifyMeError, pendingNotifyMe } = instance.state;
    let alarmT = localization.useNSt(constants.NS_ALARM);
    let canMakeAction = false;
    let canMakeReason = false;

    let canClear = false;
    let canNotifyMe = false;

    let { DynamicDetails } = alarmDetails;
    if (DynamicDetails) {
      canClear = AlarmHelpers.canClearAlarm(readonly, DynamicDetails);
      canNotifyMe = !readonly ? DynamicDetails.SetNotifyAllowed : false;

      if (DynamicDetails.ReasonAllowed) {
        canMakeReason = !readonly;
      }

      if (DynamicDetails.ActionAllowed) {
        canMakeAction = !readonly;
      }
    }
    canNotifyMe = this.widgetSettings.hideReminder ? false : canNotifyMe;

    const clearButton = (
      <button
        type="button"
        className={(canClear ? 'button-small-green' : "button-small-secondary") + " button-with-icon"}
        ref={obj => (instance.clearRButton = obj)}
        onClick={() => instance.askClear(!!this.widgetSettings.hideModalOnClear)}
        disabled={!canClear}
      >
        <span className='icon'>
          <IconCheckMark />
        </span>
        {alarmT('CLEAR')}
      </button>
    );
    const hasContent = true; //Alawys have content
    const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "AlarmActionSection", true);
    if (hideWidget(evaluatedToggleState)) return null;
    const user = localStorage.getItem('user_id');

    return (
      <>
        <div className="flex-col flex-0-0-auto">
          <Section
            widget={widget}
            addSectionClassName="flex-0-0-auto"
            addBodyClassName="p-t0-l0-05"
            toggleType={toggleType}
            toggleState={evaluatedToggleState}
            fullscreenMode={fullscreenMode}
            fullscreenRef={this.fullscreenRef}
            headerContent={
              <div
                className="section-header-colored f-15 bold"
                style={{ backgroundColor: (widget && widget.headerBackgroundColor) ? widget.headerBackgroundColor : '' }}
              >
                <div className="flex-row-wrap flex-row-between">
                  <div className="flex-1-1-0px p-05em">
                    <div>{titleTicket}</div>
                  </div>
                  {!readonly && (
                    <div className="flex-row-wrap flex-row-between">
                      <div className="p-05em callback-section">
                        <CallType
                          type={DynamicDetails.CallType}
                          instance={instance}
                          alarmDetails={alarmDetails}
                          alarmT={alarmT}
                          template={templateType}
                        />
                      </div>
                    </div>
                  )}
                  {fullscreenMode && (
                    <div className="flex-row p-05em flex-0-0-auto">
                      <button
                        type="button"
                        className="button-small-blue small-button-arrow-icon"
                        // @ts-ignore
                        ref={this.fullscreenRef}
                      >
                        <IconExpand />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            }
            bodyClassName="flex-0-0-auto bg-c-white-primary alarm-actions-wrapper"
          >
            <LabelValue
              alarmT={alarmT}
              position="col"
              loc_key={'ALARM_RESPONSE_ACTIONS'}
              value={userDetails.AlarmResponseAction}
              formatKey={'AlarmResponseAction'}
              formatValue={enumDictionary.formatEnum}
              linkify={instance.linkifyValue}
            />
            {withTextMessage ? (
              <LabelValue
                alarmT={alarmT}
                position="col"
                loc_key={'MESSAGE'}
                value={alarmDetails.TextMessage}
                linkify={instance.linkifyValue}
              />
            ) : null}
            {withInfoAboutAlarm ? (
              <LabelValue
                alarmT={alarmT}
                position="col"
                loc_key={'INFORMATION_ABOUT_THE_ALARM'}
                value={alarmDetails.Flags}
                wrapValue={(v: any, key: any) => <div key={key}>{v}</div>}
                formatKey={'AlarmFlagEnum'}
                formatValue={enumDictionary.formatEnum}
                linkify={instance.linkifyValue}
              />
            ) : null}

            {templateType === 'heproalarm' ? (
              <LabelValue
                alarmT={alarmT}
                position="col"
                loc_key={'ALARM_HANDLING'}
                value={userDetails.AlarmHandling}
              />
            ) : null}
            {withTransmitterModel ? (
              <LabelValue
                alarmT={alarmT}
                position="col"
                loc_key={'TRANSMITTER_MODEL'}
                value={alarmDetails.Model}
                linkify={instance.linkifyValue}
              />
            ) : null}
            <LabelValue
              alarmT={alarmT}
              position="col"
              loc_key={'DETAILS_FOR_RESPONSE'}
              value={
                <Linkify
                  properties={{ target: '_blank', style: { color: 'blue', fontWeight: 'bold' } }}
                >
                  {userDetails.AlarmAction}
                </Linkify>
              }
            />
            <LabelValue
              alarmT={alarmT}
              position="col"
              loc_key={'ALARM_RECALL_ACTION'}
              value={userDetails.AlarmRecallAction}
              formatKey={'AlarmRecallAction'}
              formatValue={enumDictionary.formatEnum}
              linkify={instance.linkifyValue}
            />
            <LabelValue
              alarmT={alarmT}
              position="col"
              loc_key={'ADDITIONAL_INFO_ACTION'}
              value={alarmDetails.AdditionalInfo}
              linkify={instance.linkifyValue}
            />

            <div className="flex-row-wrap justify-left p-r-l-05em">
              {(
                templateType === 'caverion-se' ||
                this.widgetSettings.includeAssociateWithObject
              ) ? (
                <div className="flex-col-around command-spacer">
                  <AssociateWithObject
                    alarmDetails={alarmDetails}
                    onShowSearchUser={onShowSearchUser}
                    errorText={errorText}
                  />
                </div>
              ) : null}
              {canMakeReason ? (
                <div className="flex-col-aroundv command-spacer">
                  <FreeDropDown
                    items={DynamicDetails.PossibleReasons}
                    free={DynamicDetails.ReasonFreeText}
                    childRef={(obj: any) => (instance.reasonRButton = obj)}
                    resetStateOn={alarm_id}
                    actionType={constants.ACTION_ADD_ALARM_REASON_TEXT}
                    alarm_id={alarmDetails.AlarmId}
                    user_id={userDetails.UserId}
                    name={alarmT('CHOOSE_REASON')}
                    withArrow={true}
                  />
                </div>
              ) : null}
              {canMakeAction ? (
                <div className="flex-col-around command-spacer">
                  <FreeDropDown
                    items={DynamicDetails.PossibleActions}
                    free={DynamicDetails.ActionFreeText}
                    childRef={(obj: any) => (instance.actionRButton = obj)}
                    resetStateOn={alarm_id}
                    actionType={constants.ACTION_ADD_ALARM_ACTION_TEXT}
                    alarm_id={alarmDetails.AlarmId}
                    user_id={userDetails.UserId}
                    name={alarmT('CHOOSE_ACTION')}
                    withArrow={true}
                  />
                </div>
              ) : null}
              {notifyMeError ? (
                <div className="flex-col-around command-spacer">
                  <button
                    type="button"
                    className="button-small-secondary"
                    onClick={instance.closeNotifyMeError}
                  >
                    {notifyMeError}
                  </button>
                </div>
              ) : canNotifyMe ? (
                <>
                  <div className="flex-col-around command-spacer">
                    <button
                      type="button"
                      className={`button-small-green button-with-icon ${isCommandUsed(user!, alarmDetails.AlarmId, 'NotifyMe') ? 'used-command' : ''}`}
                      onClick={instance.askNotifyMe}
                    >
                      <span className='icon'>
                        <IconBell />
                      </span>
                      {alarmT('NOTIFY_AT')}
                    </button>
                  </div>
                  {pendingNotifyMe ? (
                    <div className="flex-col-around">
                      <Spinner width={1} height={1} widthUnits="em" heightUnits="em" />
                    </div>
                  ) : null}
                </>
              ) : null}
              {clearError ? (
                <div className="flex-col-around command-spacer">
                  <button
                    type="button"
                    className="button-small-red"
                    ref={obj => (instance.clearRButton = obj)}
                    onClick={instance.closeClearError}
                  >
                    {clearError}
                  </button>
                </div>
              ) : DynamicDetails.Active && !AlarmHelpers.hideClearButton() && (
                <>
                  <div className="flex-col-around command-spacer">
                    {!canClear ? (
                      <TruncatedText
                        position='bottom center'
                        text={alarmT('CONDITIONS_NOT_MET')}
                        trigger={<span>{clearButton}</span>}
                      />
                    ) : clearButton}
                  </div>
                  {pendingClear ? (
                    <div className="flex-col-around command-spacer">
                      <Spinner width={1} height={1} widthUnits="em" heightUnits="em" />
                    </div>
                  ) : null}
                </>
              )}
              {isPlanningView && open_type === 'edit' ? (
                <CommandButton
                  className="'flex-row-center flex-1-1-0px'"
                  alarmId={alarmDetails.AlarmId}  // this one is not used!
                  command={{
                    TriggerIdentifier: localization.t('PLANNING_VIEW_ADD_LOG'),
                    InputType: 3
                  }}
                  saveFunction={this.updatePlanningViewNotes} // the updatePlanningViewNotes fetches the stored AlarmId
                  dropdown={null}
                  changeStateOnAction
                />
              ) : null}
            </div>
            {!this.widgetSettings.dividedCommandsView ? (
              <>
                <div className='command-horizontal-rule' />
                {this.props.children}
              </>
            ) : null}
          </Section>
        </div>
        {this.widgetSettings.dividedCommandsView ? (
          <>
            <div className='command-horizontal-rule' />
            {this.props.children}
          </>
        ) : null}
      </>
    );
  }
}

export default class AlarmActionSection extends React.Component<Props> {
  render() {
    return (
      <WidgetErrorBoundary>
        <AlarmActionWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
