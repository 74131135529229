import { DynamicDetailsModel } from 'development/js/interfaces/backend_model';
import authStore from '../../authentication';
import { AlarmState } from '../../enums/alarm';
import CallsStore from '../calls/calls_store';
import settingsStore from '../settings_store';
const constants = require('../../../json/constants.json');

const alarmClassDictionary = {
  [constants.ALARM_STATE_NOT_RESPONDED]: 'alarm-not-responded',
  [constants.ALARM_STATE_ASSIGNED]: 'alarm-assigned',
  [constants.ALARM_STATE_MY]: 'alarm-my',
  [constants.ALARM_STATE_RESPONDED]: 'alarm-responded',
  [constants.ALARM_STATE_DEACTIVATED]: 'alarm-deactivated'
};

interface AlarmStateDefinition {
  active: boolean;
  respondentId: string;
  respondentOwn: boolean;
}

const AlarmHelpers = {
  defineAlarmState: (alarm: AlarmStateDefinition): AlarmState => {
    if (!alarm.active) {
      return AlarmState.Deactivated;
    } else if (alarm.respondentId && !alarm.respondentOwn) {
      return AlarmState.Respondent;
    } else if (alarm.respondentId && alarm.respondentOwn) {
      return AlarmState.My;
    }

    return AlarmState.NotRespondent;
  },

  /**
   * define alarm tab
   */
  defineAlarmTab: (alarm: AlarmStateDefinition): string => {
    const isSingleListMode = settingsStore.getValueByKey('SINGLELIST', 'boolean');
    const alarmState = AlarmHelpers.defineAlarmState(alarm);

    if (alarmState === AlarmState.Assigned || alarmState === AlarmState.NotRespondent) {
      return constants.TAB_ACTIVE;
    } else if (alarmState === AlarmState.My) {
      return isSingleListMode ? constants.TAB_ACTIVE : constants.TAB_MY;
    } else if (alarmState === AlarmState.Respondent) {
      return isSingleListMode ? constants.TAB_ACTIVE : constants.TAB_RESPONDED;
    } else if (alarmState === AlarmState.Deactivated) {
      return constants.TAB_DEACTIVATED;
    }

    throw new Error('Alarm does not have a tab to be in');
  },

  getAlarmClass: (alarm: AlarmStateDefinition): string => {
    return alarmClassDictionary[
      AlarmHelpers.defineAlarmState({
        active: alarm.active,
        respondentId: alarm.respondentId,
        respondentOwn: alarm.respondentOwn
      })
    ];
  },

  /**
   * Determine if it is OK to play alarm sound
   * @returns boolean
   */
  playAlarmSound: (): boolean => {
    const muteAlarmsounds = settingsStore.getValueByKey('MUTE_ALARMSOUNDS_ON_CALL', 'boolean');
    if (muteAlarmsounds && CallsStore.getActiveCall()) {
      return false;
    }
    return true;
  },

  /**
   * Determine if the current user are allowed to clear the alarm
   * @returns boolean
   */
  canClearAlarm: (readonly: boolean, dynamicDetails: DynamicDetailsModel): boolean => {
    if (!dynamicDetails) {
      return false;
    } else {
      return dynamicDetails?.ClearAllowed && (authStore.isAuthenticated() || !readonly);
    }
  },

  /**
   * Determine if the clear button should be hidden
   * @returns boolean
   */
  hideClearButton: (): boolean => {
    return authStore.isSimpleLogin() && !authStore.isAuthenticated();
  }
};

export default AlarmHelpers;
