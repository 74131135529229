import React from 'react';

export default class IconClose extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-close"
        x="0px"
        y="0px"
        width="70px"
        height="70px"
        viewBox="0 0 70 70"
      >
        <g>
          <rect
            x="0"
            y="28.3"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 34.5 -14.7046)"
            width="70"
            height="12"
          />
          <rect
            x="0"
            y="29.7"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 84.9975 36.2071)"
            width="70"
            height="12"
          />
        </g>
      </svg>
    );
  }
}
