import MedicalNotesSection from '../MedicalNotesSection';
import AdditionalUserInfoSection from './AdditionalUserInfoSection';
import AlarmActionSection from './AlarmActionSection';
import AlarmRepeatedSection from './AlarmRepeatedSection';
import AssociateWithObject from './AssociateWithObject';
import BetaMediaSection from './BetaMediaSection';
import CameraCareSection from './CameraCareSection';
import CameraSection from './CameraSection';
import CommandsSection from './CommandsSection';
import ContactsSection from './ContactSection/ContactsSection';
import CurenaPersonalDetailsSection from './CurenaPersonalDetailsSection';
import CustomerInfoSection from './CustomerInfoSection';
import DeviceInfoSection from './DeviceInfoSection';
import DeviceMaintenanceSection from './DeviceMaintenanceSection';
import DynamicsUserInfoSection from './DynamicsUserInfoSection';
import ExternalInformation from './ExternalInformation/ExternalInformationWrapper';
import ExternalSection from './ExternalSection';
import FreeDropDown from './FreeDropDown';
import InCallCommandsSection from './InCallCommandsSection';
import InternalMessengerSection from './InternalMessenger';
import Items from './Items';
import LabelValue from './LabelValue';
import LatestKnownPositionSection from './LatestKnownPosition';
import LinksSection from './LinksSection';
import LogsSection from './LogsSection/LogsSection';
import ManageAlarmSection from './ManageAlarmSection';
import { Notes } from './Notes';
import NotifyUI from './NotifyUI';
import OrganizationNotes from './OrganizationNotesSection';
import ProductMeasurementsSection from './ProductMeasurementsSection';
import { RedBox } from './RedBox';
import ResourcesSection from './ResourcesSection';
import ResourcesSectionDoronorway from './ResourcesSectionDoronorway';
import ScheduledReminderPopup from './ScheduledReminderPopup';
import StanleyAlarmInfoSection from './StanleyAlarmInfoSection';
import UserInfoSection from './UserInfoSection';
import UserInfoSectionUK from './UserInfoSectionUK';
import VeoliaAdditionalInfo from './VeoliaAdditionalInfoSection';
import VeoliaAddressSection from './VeoliaAddressSection';
import VeoliaAlarmText from './VeoliaAlarmTextSection';
import VeoliaUserInfoSection from './VeoliaUserInfoSection';
import { calcContactClassName } from './calcContactClassName';
export {
  calcContactClassName,
  LabelValue,
  Items,
  FreeDropDown,
  InternalMessengerSection,
  NotifyUI,
  CameraSection,
  AlarmActionSection,
  ResourcesSection,
  ContactsSection,
  ExternalSection,
  LogsSection,
  LinksSection,
  UserInfoSection,
  UserInfoSectionUK,
  AdditionalUserInfoSection,
  CameraCareSection,
  AlarmRepeatedSection,
  CommandsSection,
  VeoliaUserInfoSection,
  VeoliaAlarmText,
  VeoliaAdditionalInfo,
  VeoliaAddressSection,
  StanleyAlarmInfoSection,
  LatestKnownPositionSection,
  BetaMediaSection,
  CurenaPersonalDetailsSection,
  CustomerInfoSection,
  ManageAlarmSection,
  InCallCommandsSection,
  DynamicsUserInfoSection,
  DeviceInfoSection,
  AssociateWithObject,
  ScheduledReminderPopup,
  DeviceMaintenanceSection,
  ProductMeasurementsSection,
  Notes,
  MedicalNotesSection,
  OrganizationNotes,
  RedBox,
  ResourcesSectionDoronorway,
  ExternalInformation
};
