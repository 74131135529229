import React from 'react';

export default class IconBoxArrowRight extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_456_2107)">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.7155 3.9375C9.7155 3.78832 9.65624 3.64524 9.55075 3.53975C9.44526 3.43426 9.30218 3.375 9.153 3.375H1.6875C1.23995 3.375 0.810725 3.55279 0.494257 3.86926C0.17779 4.18572 0 4.61495 0 5.0625L0 16.3125C0 16.7601 0.17779 17.1893 0.494257 17.5057C0.810725 17.8222 1.23995 18 1.6875 18H12.9375C13.3851 18 13.8143 17.8222 14.1307 17.5057C14.4472 17.1893 14.625 16.7601 14.625 16.3125V8.847C14.625 8.69782 14.5657 8.55474 14.4602 8.44925C14.3548 8.34376 14.2117 8.2845 14.0625 8.2845C13.9133 8.2845 13.7702 8.34376 13.6648 8.44925C13.5593 8.55474 13.5 8.69782 13.5 8.847V16.3125C13.5 16.4617 13.4407 16.6048 13.3352 16.7102C13.2298 16.8157 13.0867 16.875 12.9375 16.875H1.6875C1.53832 16.875 1.39524 16.8157 1.28975 16.7102C1.18426 16.6048 1.125 16.4617 1.125 16.3125V5.0625C1.125 4.91332 1.18426 4.77024 1.28975 4.66475C1.39524 4.55926 1.53832 4.5 1.6875 4.5H9.153C9.30218 4.5 9.44526 4.44074 9.55075 4.33525C9.65624 4.22976 9.7155 4.08668 9.7155 3.9375Z" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M17.9997 0.5625C17.9997 0.413316 17.9405 0.270242 17.835 0.164752C17.7295 0.0592632 17.5864 0 17.4372 0L11.8122 0C11.6631 0 11.52 0.0592632 11.4145 0.164752C11.309 0.270242 11.2497 0.413316 11.2497 0.5625C11.2497 0.711684 11.309 0.854758 11.4145 0.960248C11.52 1.06574 11.6631 1.125 11.8122 1.125H16.0794L6.91398 10.2892C6.86169 10.3415 6.8202 10.4036 6.7919 10.472C6.76359 10.5403 6.74902 10.6135 6.74902 10.6875C6.74902 10.7615 6.76359 10.8347 6.7919 10.903C6.8202 10.9714 6.86169 11.0335 6.91398 11.0858C6.96628 11.138 7.02837 11.1795 7.0967 11.2078C7.16503 11.2361 7.23827 11.2507 7.31223 11.2507C7.3862 11.2507 7.45943 11.2361 7.52777 11.2078C7.5961 11.1795 7.65819 11.138 7.71048 11.0858L16.8747 1.92037V6.1875C16.8747 6.33668 16.934 6.47976 17.0395 6.58525C17.145 6.69074 17.288 6.75 17.4372 6.75C17.5864 6.75 17.7295 6.69074 17.835 6.58525C17.9405 6.47976 17.9997 6.33668 17.9997 6.1875V0.5625Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_456_2107">
            <rect width="18" height="18" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );
  }
}
