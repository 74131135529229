import * as Logger from 'js-logger';

enum VisibilityState {
  Opened = "OPENED",
  Closed = "CLOSED",
  OpenOnContent = "OPEN_ON_CONTENT",
  HiddenIfNoContent = "HIDDEN_IF_NO_CONTENT",
  HideWidget = "HIDE_THE_WIDGET"
}

/**
 * Evaluate if the widget should be Opened, Closed or Hidden based on hasContent and toggleState
 * Should be called by widgets that support OpenOnContent and HiddenIfNoContent Visibility State
 */
export function evalToggleState(hasContent: boolean, toggleState?: string, widgetName?: string, isAlwaysHaveContent: boolean = false): VisibilityState {
  const visibility = toggleState as VisibilityState;
  switch (visibility) {
    case VisibilityState.Opened:
    case VisibilityState.Closed:
      return visibility
    case VisibilityState.OpenOnContent:
      if (hasContent) {
        if (isAlwaysHaveContent) {
          Logger.warn(visibility, "Visibility is not supported for widget", widgetName);
        }
        return VisibilityState.Opened;
      } else {
        return VisibilityState.Closed;
      }
    case VisibilityState.HiddenIfNoContent:
      if (hasContent) {
        if (isAlwaysHaveContent) {
          Logger.warn(visibility, "Visibility is not supported for widget", widgetName);
        }
        return VisibilityState.Opened;
      } else {
        return VisibilityState.HideWidget;
      }
    default:
      Logger.warn(visibility, "Visibility is not supported for widget", widgetName);
      return VisibilityState.Opened;
  }
}

/**
 * Evaluate if the widget should be hidden or not
 */
export function hideWidget(toggleState?: string): boolean {
  if (toggleState === VisibilityState.HideWidget) {
    return true;
  }
  return false;
}

export { VisibilityState };

