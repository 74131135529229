import React from 'react';

import DialButton from './dial_button';

interface Props {
  onClick: (button: { symbol: string; alias?: string }) => void;
  compact?: any;
}

interface State { }

export class TwilioDialpad extends React.Component<Props, State> {
  static defaultProps = {
    onClick: () => { }
  };

  render() {
    const { onClick } = this.props;

    const buttons = [
      {
        symbol: '1'
      },
      {
        symbol: '2',
        alias: 'abc'
      },
      {
        symbol: '3',
        alias: 'def'
      },
      {
        symbol: '4',
        alias: 'ghi'
      },
      {
        symbol: '5',
        alias: 'jkl'
      },
      {
        symbol: '6',
        alias: 'mno'
      },
      {
        symbol: '7',
        alias: 'pqrs'
      },
      {
        symbol: '8',
        alias: 'tuv'
      },
      {
        symbol: '9',
        alias: 'wxyz'
      },
      {
        symbol: '*'
      },
      {
        symbol: '0'
      },
      {
        symbol: '#'
      }
    ];
    return (
      <div>
        <div
          style={{
            float: 'left',
            display: 'block',
            width: '100%',
            userSelect: 'none',
            padding: '10px 0',
            fontFamily: '"Lucida Grande", Tahoma, Arial, Verdana, sans-serif'
          }}
        >
          <ol
            style={{
              margin: 0,
              padding: 0,
              listStyle: 'none'
            }}
          >
            {buttons.map((button, i) => (
              <li
                onClick={() => onClick(button)}
                style={{
                  float: 'left',
                  cursor: 'pointer',
                  width: 'calc(100%/3)',
                  backgroundColor: '#475366'
                }}
                key={i}
              >
                <DialButton {...button} />
              </li>
            ))}
          </ol>
        </div>
      </div>
    );
  }
}
