import axios from 'axios';
import * as Logger from 'js-logger';
import { inject } from 'mobx-react';
import React from 'react';
import ajax from '../ajax';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import authentication from '../authentication';
import { CognitoResponseModel, ResponseModel } from '../interfaces/backend_model';
import localization, { i18nextCachePrefix } from '../localization';
import { StorageStore } from '../stores/mobx/StorageStore';
import { clearAllCommandsState } from '../utilities/commandButtonStateManager';
import { logoutWhenReferrer } from '../utilities/redirects';
import Validation from '../validation';
import { ForcePassResetModal } from './forcePassResetModal';
import LanguageSelect from './language_select';
import { PopupConfirm } from './popups/popup_confirm';
import PopupError from './popups/popup_error';
import SpinnerOverlay from './spinner_overlay';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

const forgotPW =
  process.env.PASSWORD_RESET_LINK || 'https://admin.skyresponse.com/adminportal/forgotpassword';

const OAuthType = {
  none: 'none',
  sms: 'sms',
  google: 'google'
};

interface Props {
  storage: StorageStore;
}

interface State {
  errorMessage: boolean | string;
  confirmMessage: string | boolean;
  overlay: boolean;
  is_cognitoRedirect: string;
  is_userName_required: boolean;
  is_userPassword_required: boolean;
  is_phoneNumber_invalid: boolean;
  is_authCode_required: boolean;
  authCode: string;
  rememberMe: boolean;
  twoFactorAuth: boolean;
  selectedIntegration: string;
  phoneNumber: string;
  authState: any | null;
  resetModalVisible: boolean;
  token: string;
}

interface Context { }

@inject('storage')
export default class LoginCredentials extends React.Component<Props, State, Context> {
  authCodeInputEl: HTMLInputElement | null = null;
  userNameRInput: HTMLInputElement | null = null;
  userPasswordRInput: HTMLInputElement | null = null;
  telephonyRSelect: HTMLSelectElement | null = null;
  phoneNumberRInput: HTMLInputElement | null = null;

  constructor(props: Props, context: Context) {
    super(props, context);

    this.clearLoginData = this.clearLoginData.bind(this);
    this.loginWorkflow = this.loginWorkflow.bind(this);
    this.handleCredentialsSubmit = this.handleCredentialsSubmit.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handlePopupConfirmClick = this.handlePopupConfirmClick.bind(this);
    this.handlePopupErrorClick = this.handlePopupErrorClick.bind(this);
    this.checkEmptyValidation = this.checkEmptyValidation.bind(this);
    this.checkPhoneNumber = this.checkPhoneNumber.bind(this);

    this.state = {
      errorMessage: false,
      confirmMessage: false,
      overlay: false,
      is_cognitoRedirect: authentication.ls_get(constants.COGNITO) || false,
      is_userName_required: false,
      is_userPassword_required: false,
      is_phoneNumber_invalid: false,
      is_authCode_required: false,

      authState: null,

      resetModalVisible: false,
      token: '',

      // fields
      authCode: '',
      rememberMe: false,
      twoFactorAuth: false,
      selectedIntegration:
        props.storage.getItem('lastUsedTelephonyIntegration') || constants.TELEPHONY_EXTERNAL,
      phoneNumber: props.storage.getItem('lastUsedTelephone') || '+46',
    };
  }

  componentDidMount() {
    // reset all logged in information
    // so user won't be able to navigate forward/backward
    // even if the user occasionally went back to login page
    this.clearLoginData();
    appDispatcher.ajax_unauthorized = false;
    let err = appDispatcher.unauthorizedErrorMessage;
    if (err) {
      appDispatcher.unauthorizedErrorMessage = null;
      this.setState({
        errorMessage: err,
        overlay: false
      });
    }
  }

  /**
   * clears all store information about logged id user
   */
  clearLoginData() {
    authentication.clearLocalStorage();

    this.setState({
      ...this.state,
      resetModalVisible: false,
      token: "",
      overlay: false,
      twoFactorAuth: false,
      authState: undefined,
      authCode: ""
    });
  }

  handleTwoFactorAuthSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    return axios
      .post(
        '/api/2fa',
        { AuthCode: this.state.authCode, RememberMe: this.state.rememberMe },
        {
          headers: {
            Authorization: `bearer ${this.state.authState.response.access_token}`
          }
        }
      )
      .then((response: any) => {
        if (response.data.data.forcePasswordReset) {
          this.setState({ ...this.state, resetModalVisible: true, token: response.data.data.access_token });
        } else {
          this.processAuthentication(response.data.data, this.state.authState.data)
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 406) {
          // in case when backend have reson
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
          ) {
            const errorCode = error.response.data.errors[0].name;

            switch (errorCode) {
              case 'TwoFactorTooManyFailedAttempts':
                this.processLoginErrors({ err: 'AUTH_CODE_VALIDATION_FAILED_TOO_MANY_ATTEMPTS' });
                break;
              case 'TwoFactorInvalidOnetimeCode':
                this.processLoginErrors({
                  err: 'AUTH_CODE_VALIDATION_FAILED_INVALID_ONE_TIME_CODE'
                });
                break;
              default:
                this.processLoginErrors({ err: 'AUTH_CODE_VALIDATION_FAILED' });
            }
            return;
          }

          // some unexpected error
          this.processLoginErrors({ err: 'AUTH_CODE_VALIDATION_FAILED' });
        } else {
          this.processLoginErrors();
        }
      });
  };

  detectLoginWorkflow = (
    response: ResponseModel | any,
    data: {
      userName: string;
      userPassword: string;
      phoneNumber: string | null;
      telephonyIntegration: string;
    }
  ) => {
    switch (response.twoFactorAuthType) {
      case OAuthType.none:
        return this.processAuthentication(response, data);
      case OAuthType.sms:
      case OAuthType.google:
        return this.setState(
          {
            twoFactorAuth: true,
            overlay: false,
            authState: { response, data }
          },
          () => {
            if (this.authCodeInputEl) {
              this.authCodeInputEl.focus();
            }
          }
        );
      default:
        this.setState({
          errorMessage: 'Invalid twoFactorAuthType returned',
          overlay: false
        });
    }
  };

  processAuthentication = (
    response: ResponseModel | CognitoResponseModel,
    {
      userName,
      userPassword,
      phoneNumber,
      telephonyIntegration
    }: {
      userName: string;
      userPassword: string;
      phoneNumber: string | null;
      telephonyIntegration: string;
    }
  ) => {
    // cleanup localstorage from locales
    for (let i = 0; i < localStorage.length; i++) {
      const item = localStorage.key(i);

      if (item && item.indexOf(i18nextCachePrefix) !== -1) {
        localStorage.removeItem(item);
      }
      if (item && item === constants.COGNITO) {
        localStorage.removeItem(item);
      }
    }

    // login success
    authentication.ls_set([userName, constants.TELEPHONY_KEY], telephonyIntegration);
    authentication.setUserId(userName); // user name is unique
    // force to set without checking lifetime
    // save token_data from server response

    authentication.ls_set(
      [userName, constants.EXPIRES_INITIAL_KEY],
      Date.now() + response.expires_in * 1000
    );

    authentication.ls_set([userName, constants.ACCESS_TOKEN_KEY], response.access_token);
    authentication.ls_set([userName, constants.ADMIN_ACCESS_TOKEN_KEY], response.admin_access_token);
    authentication.ls_set([userName, constants.EXPIRES_IN_KEY], response.expires_in);
    authentication.ls_set([userName, constants.TOKEN_TYPE_KEY], response.token_type);

    // we need to remove this due to the old stored token is  possibly old and unusable
    // this is just in case it is still stored, shouldn't hopefully happen
    authentication.ls_remove([userName, constants.RENEWAL_KEY]);

    if (phoneNumber) {
      authentication.ls_set([userName, constants.PHONE_NUMBER_KEY], phoneNumber);
      authentication.ls_set([userName, constants.PHONE_SELECTED_KEY], 'true');
    } else {
      authentication.ls_set([userName, constants.PHONE_SELECTED_KEY], 'false');
    }

    localization.changeLanguage(localization.getLanguage()); // save selected language for current user
    localization.rebuildCache();

    if (phoneNumber) {
      this.props.storage.setItem('lastUsedTelephone', phoneNumber);
    }

    this.props.storage.setItem('lastUsedTelephonyIntegration', telephonyIntegration);

    if (appHistory._pathname) {
      appHistory.replace(appHistory._pathname);
      appHistory._pathname = null;
    } else {
      appHistory.replace(constants.PATH_GROUPS);
    }
  };

  loginWorkflow(
    userName: string,
    userPassword: string,
    phoneNumber: string | null,
    telephonyIntegration: string
  ) {
    clearAllCommandsState();
    this.setState({
      overlay: true,
      confirmMessage: false
    });
    ajax
      .postByDescPromise(endpoints.TOKEN, {
        userName: encodeURIComponent(userName),
        userPassword: encodeURIComponent(userPassword)
      })
      .then((response: any) => {
        if (response.forcePasswordReset && response.twoFactorAuthType === "none") {
          this.setState({ ...this.state, resetModalVisible: true, token: response.access_token });
        } else {
          this.detectLoginWorkflow(response, {
            userName,
            userPassword,
            phoneNumber,
            telephonyIntegration
          });
        }
      })
      .catch(this.processLoginErrors);
  }

  processLoginErrors = (error: { err?: boolean | string; xhr?: XMLHttpRequest } = {}) => {
    const { err } = error;
    if (typeof err === 'string') {
      this.setState({ errorMessage: err, overlay: false });
    } else {
      this.setState({
        errorMessage: 'Internal error occured during login process',
        overlay: false
      });
      Logger.error(err);
    }
  };

  handleCredentialsSubmit(e: React.FormEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.telephonyRSelect) {
      return;
    }

    const phoneNumber = (this.phoneNumberRInput || { value: this.state.selectedIntegration }).value;
    const telephonyIntegration = this.telephonyRSelect.value;
    const isTwilio = telephonyIntegration === constants.TELEPHONY_CLOUD;
    const isNone = telephonyIntegration === constants.TELEPHONY_NONE;

    if (this.state.is_cognitoRedirect) {
      const userAuthData = JSON.parse(this.state.is_cognitoRedirect);
      const userData = {
        userName: userAuthData.username,
        userPassword: '',
        phoneNumber: phoneNumber,
        telephonyIntegration
      };
      if (isTwilio || isNone) {
        userData.phoneNumber = '';
        this.processAuthentication(userAuthData, userData);
        return;
      }
      let loginT = localization.useNSt(constants.NS_LOGIN);

      if (phoneNumber) {
        let is_phoneNumber_invalid = Validation.validate_phone_number(phoneNumber);
        if (is_phoneNumber_invalid) {
          this.setState({ is_phoneNumber_invalid });
        } else {
          this.processAuthentication(userAuthData, userData);
        }
      } else {
        this.setState({ confirmMessage: loginT('PHONE_NUMBER_EMPTY_MESSAGE') });
      }
    }

    if (!this.userNameRInput || !this.userPasswordRInput || !this.telephonyRSelect) {
      return;
    }
    const userName = this.userNameRInput.value;
    const userPassword = this.userPasswordRInput.value;

    if (userName && userPassword) {
      if (isTwilio || isNone) {
        this.loginWorkflow(userName, userPassword, '', telephonyIntegration);
        return;
      }

      let loginT = localization.useNSt(constants.NS_LOGIN);

      if (phoneNumber) {
        let is_phoneNumber_invalid = Validation.validate_phone_number(phoneNumber);
        if (is_phoneNumber_invalid) {
          this.setState({ is_phoneNumber_invalid });
        } else {
          this.loginWorkflow(userName, userPassword, phoneNumber, telephonyIntegration);
        }
      } else {
        this.setState({ confirmMessage: loginT('PHONE_NUMBER_EMPTY_MESSAGE') });
      }
    } else {
      this.setState({
        is_userName_required: Validation.validate_empty_value(userName),
        is_userPassword_required: Validation.validate_empty_value(userPassword)
      });
    }
  }

  handleLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
    localization.changeLanguage(e.target.value);
    this.forceUpdate();
  }

  checkEmptyValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const element = e.target;
    const empty = Validation.validate_empty_value(element.value);
    const key = `is_${element.name}_required`;

    switch (key) {
      case 'is_userName_required':
        this.setState({ is_userName_required: empty });
        break;
      case 'is_userPassword_required':
        this.setState({ is_userPassword_required: empty });
        break;
    }
  }

  handlePopupErrorClick(e: React.ChangeEvent<HTMLButtonElement>) {
    let element = e.target;
    if (element.dataset.click === 'close') {
      if (logoutWhenReferrer()) {
        return;
      }

      this.setState(
        {
          errorMessage: false
        },
        () => {
          if (this.authCodeInputEl) {
            this.authCodeInputEl.focus();
          }
        }
      );
    }
  }

  checkPhoneNumber(e: React.ChangeEvent<HTMLInputElement>) {
    let element = e.target;
    if (element.value) {
      this.setState({
        is_phoneNumber_invalid: Validation.validate_phone_number(element.value),
        phoneNumber: element.value
      });
    } else {
      this.setState({
        is_phoneNumber_invalid: false
      });
    }
  }

  handlePopupConfirmClick(e: React.ChangeEvent<HTMLButtonElement>) {
    let element = e.target;
    if (element.dataset.click === 'close') {
      this.setState({
        confirmMessage: false
      });
    } else if (element.dataset.click === 'continue') {
      if (!this.userNameRInput || !this.userPasswordRInput || !this.telephonyRSelect) {
        return;
      }
      this.loginWorkflow(
        this.userNameRInput.value,
        this.userPasswordRInput.value,
        null,
        this.telephonyRSelect.value
      );
    }
  }

  getLoginForm(loginT: Function, selectOptions: JSX.Element[], blockLogin: boolean) {
    let renderNumberInput = true;
    switch (this.state.selectedIntegration) {
      case constants.TELEPHONY_CLOUD:
      case constants.TELEPHONY_NONE:
        renderNumberInput = false;
        break;
    }
    return (
      <div className="login-form-wrapper">
        <div className="login-form-part name-wrapper">
          <div className="login-name-wrapper">
            <div className="logo-wrapper" />
            <p className="login-title">{loginT('LOGIN_TITLE')}</p>
          </div>
        </div>
        <div className="login-form-part form-wrapper">
          {
            this.state.twoFactorAuth ?
              this.getTwoFactorForm(loginT)
              :
              (
                <form
                  className="flex-center login-form p-rel"
                  data-cy="formLogin"
                  onSubmit={this.handleCredentialsSubmit}
                >
                  <div className="flex-col flex-1-1-auto">
                    <div className="item-05em flex-center">
                      <div className="flex-col-around">
                        <label className="label">{loginT('LANGUAGE')}</label>
                      </div>
                      <div className="flex-col-around">
                        <LanguageSelect onChange={this.handleLanguageChange} />
                      </div>
                    </div>
                    {!this.state.is_cognitoRedirect ? (
                      <div>
                        <div className="item-05em flex-row-between">
                          <div className="flex-col-around flex-0-0-40p">
                            <label className="label">{loginT('USERNAME')}</label>
                          </div>
                          <div className="flex-col-around flex-0-1-60p">
                            <input
                              type="text"
                              onChange={this.checkEmptyValidation}
                              name="userName"
                              data-cy="userName"
                              className="c-input w-100p"
                              ref={obj => (this.userNameRInput = obj)}
                            />
                            {this.state.is_userName_required ? (
                              <label className="error-text">{loginT('USERNAME_REQUIRED_MESSAGE')}</label>
                            ) : null}
                          </div>
                        </div>
                        <div className="item-05em flex-row-between">
                          <div className="flex-col-around flex-0-0-40p">
                            <label className="label">{loginT('PASSWORD')}</label>
                          </div>
                          <div className="flex-col-around flex-0-1-60p">
                            <input
                              type="password"
                              onChange={this.checkEmptyValidation}
                              name="userPassword"
                              data-cy="userPassword"
                              className="c-input w-100p"
                              ref={obj => (this.userPasswordRInput = obj)}
                              autoComplete="off"
                            />
                            {this.state.is_userPassword_required ? (
                              <label className="error-text">{loginT('PASSWORD_REQUIRED_MESSAGE')}</label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="item-05em flex-row-between">
                      <div className="flex-col-around">
                        <label className="label">{loginT('TELEPHONY_INTEGRATION')}</label>
                      </div>
                      <div className="flex-col-around" data-cy="telIntegration">
                        <select
                          ref={obj => (this.telephonyRSelect = obj)}
                          value={this.state.selectedIntegration}
                          onChange={e => {
                            this.setState({ selectedIntegration: e.target.value });
                          }}
                        >
                          {selectOptions}
                        </select>
                      </div>
                    </div>
                    {renderNumberInput && (
                      <div className="item-05em flex-row-between">
                        <div className="flex-col-around flex-0-0-40p">
                          <label className="label">{loginT('PHONE_NUMBER')}</label>
                        </div>
                        <div className="flex-col-around flex-0-1-60p">
                          <input
                            type="text"
                            onChange={this.checkPhoneNumber}
                            name="phoneNumber"
                            data-cy="phoneNumber"
                            className="c-input w-100p"
                            value={this.state.phoneNumber}
                            ref={obj => (this.phoneNumberRInput = obj)}
                          />
                          {this.state.is_phoneNumber_invalid ? (
                            <label className="error-text-input">
                              {loginT('PHONE_NUMBER_INVALID_MESSAGE')}
                            </label>
                          ) : null}
                        </div>
                      </div>
                    )}
                    <div className="item-05em flex-center">
                      <div className="flex-col-around w-100p">
                        <button
                          disabled={
                            blockLogin ||
                            this.state.is_userName_required ||
                            this.state.is_userPassword_required
                          }
                          className="form-btn-bg-gray"
                          type="submit"
                          style={{
                            color: blockLogin ? 'black' : undefined,
                            borderRadius: blockLogin ? 0 : undefined
                          }}
                        >
                          {loginT('LOGIN')}
                        </button>
                        {blockLogin ? (
                          <label className="error-text-input">{loginT('YOUR_BROWSER_IS_UNSUPPORTED')}</label>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex-center forgot-pw">
                      <a href={forgotPW}>{loginT('FORGOT_PASSWORD')}</a>
                    </div>
                  </div>
                  {this.state.overlay ? <SpinnerOverlay /> : null}
                </form>
              )
          }
        </div>
      </div>
    );
  }

  getTwoFactorForm = (loginT: Function) => {
    let twoFactorMessage = '';

    switch (this.state.authState.response.twoFactorAuthType) {
      case OAuthType.sms:
        twoFactorMessage = 'INPUT_YOUR_CODE_SMS';
        break;
      case OAuthType.google:
        twoFactorMessage = 'INPUT_YOUR_CODE_GOOGLE';
        break;
    }

    return (
      <form className="flex-center login-form p-rel" onSubmit={this.handleTwoFactorAuthSubmit}>
        <div className="flex-col flex-1-1-auto">
          <div className="item-05em flex-center">
            <div className="flex-col-around">
              <label className="label">{loginT(twoFactorMessage)}</label>
            </div>
          </div>
          <div className="item-05em flex-row-between">
            <div className="flex-col-around flex-0-0-40p">
              <label className="label">{loginT('AUTHCODE')}</label>
            </div>
            <div className="flex-col-around flex-0-1-60p">
              <input
                ref={node => (this.authCodeInputEl = node)}
                autoFocus={true}
                type="text"
                onChange={({ currentTarget }) => {
                  if (currentTarget.value) {
                    this.setState({
                      authCode: currentTarget.value,
                      is_authCode_required: false
                    });
                  } else {
                    this.setState({ authCode: '', is_authCode_required: true });
                  }
                }}
                value={this.state.authCode}
                name="authCode"
                className="c-input w-100p"
                autoComplete="off"
              />
              {this.state.is_authCode_required ? (
                <label className="error-text">{loginT('AUTHCODE_REQUIRED_MESSAGE')}</label>
              ) : null}
            </div>
          </div>
          <div className="item-05em flex-row-between">
            <div className="flex-col-around flex-0-0-40p">
              <label className="label">{loginT('REMEMBER_ME')}</label>
            </div>
            <div className="flex-col-around flex-0-1-60p">
              <input
                type="checkbox"
                name="rememberMe"
                onChange={({ currentTarget }) =>
                  this.setState({ rememberMe: currentTarget.checked })
                }
                value=""
                checked={this.state.rememberMe}
                className="c-input w-100p"
              />
            </div>
          </div>
          <div className="item-05em flex-center">
            <div className="flex-col-around w-100p">
              <button
                disabled={this.state.is_authCode_required}
                className="form-btn-bg-gray"
                type="submit"
              >
                {loginT('LOGIN')}
              </button>
            </div>
          </div>
        </div>
        {this.state.overlay ? <SpinnerOverlay /> : null}
      </form>
    );
  };

  render() {
    const isIE = false;//detectIE() !== false;
    const loginT = localization.useNSt(constants.NS_LOGIN);

    const telephony_integration = authentication.ls_get([
      authentication.getUserId(),
      constants.TELEPHONY_KEY
    ]);

    // @ts-ignore
    let defaultValue;
    const selectOptions = [
      {
        text: loginT('NONE'),
        value: constants.TELEPHONY_NONE
      },
      {
        text: loginT('EXTERNAL'),
        value: constants.TELEPHONY_EXTERNAL
      },
      // Hidden integration for SR-1516
      // {
      //   text: loginT('LOCAL_INTEGRATION'),
      //   value: constants.TELEPHONY_LOCAL
      // },
      {
        text: loginT('BASIC_TEL'),
        value: constants.TELEPHONY_BASIC_TEL
      },
      {
        text: loginT('BASIC_CALL_TO'),
        value: constants.TELEPHONY_BASIC_CALL_TO
      },
      {
        text: loginT('CLOUD_CALL'),
        value: constants.TELEPHONY_CLOUD
      }
    ].map((option, idx) => {
      if (telephony_integration === option.value) {
        defaultValue = telephony_integration;
      }
      return (
        <option key={idx} value={option.value}>
          {option.text}
        </option>
      );
    });

    const blockLogin = isIE && this.state.selectedIntegration === constants.TELEPHONY_CLOUD;
    const content = this.getLoginForm(loginT, selectOptions, blockLogin);

    return (
      <div className="fx-wh-100vwh">
        <div>{content}</div>
        <PopupError
          onClick={this.handlePopupErrorClick}
          message={loginT(
            typeof this.state.errorMessage === 'string' ? this.state.errorMessage : ''
          )}
          show={!!this.state.errorMessage}
        />
        <PopupConfirm
          onClick={this.handlePopupConfirmClick}
          message={this.state.confirmMessage as string}
          show={!!this.state.confirmMessage}
        />
        <ForcePassResetModal isOpen={this.state.resetModalVisible} token={this.state?.token} onSubmit={this.clearLoginData} />
      </div>
    );
  }
}
