import ajax from '../ajax';
import AppDispatcher from '../app_dispatcher';
import UIStore from './mobx/UIStore';

import EventEmitter from 'events';

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');

interface PayloadModel {
  type: string;
  alarmId: string;
  message: string;
  resource: string;
  notifyOnUpdate: boolean;
  deactivateAlarmWhenAllDone: boolean;
}

class ResourcesStore extends EventEmitter {
  errors: Map<any, any>;
  loading: Map<any, any>;
  constructor() {
    super();

    this.errors = new Map();
    this.loading = new Map();

    // register to app dispatcher
    AppDispatcher.register(this.onDispatch.bind(this));
  }

  /**
   * Handler for AppDispatcher events
   * @param payload
   */
  onDispatch({ action: payload }: { action: PayloadModel }) {
    switch (payload.type) {
      case constants.CALL_RESOURCE:
        this.callResource(
          payload.alarmId,
          payload.message,
          payload.resource,
          payload.notifyOnUpdate,
          payload.deactivateAlarmWhenAllDone
        );
        break;
      case constants.CALL_RESOURCE_DELETE:
        this.deleteResource(payload.alarmId, payload.resource);
        break;
    }
  }

  /**
   * Retrieve store state
   * @returns {{errors: Map, loading: Map, calls: (*|Array)}}
   */
  getState() {
    return {
      errors: this.errors,
      loading: this.loading,
      lastAction: null
    };
  }

  deleteResource(alarmId: string, resource: string) {
    this.errors.set('delete', false);
    this.loading.set('delete', true);
    this.notify();

    const data = {
      alarmId,
      resource
    };

    ajax.deleteByDesc(
      false,
      endpoints.CALL_RESOURCE_DELETE,
      data,
      (err: string | null, xhr: XMLHttpRequest, data: any) => {
        this.loading.set('delete', false);

        if (err) {
          this.errors.set('delete', err);
          this.notify();
          UIStore.showSnackbar('RESOURCE_DELETE_ERROR');
          return;
        }

        if (!data) {
          this.errors.set('delete', 'unexpected error');
          this.notify();
          UIStore.showSnackbar('RESOURCE_DELETE_ERROR');
          return;
        }

        UIStore.showSnackbar('RESOURCE_DELETE_SUCCESS');
        this.notify();
      }
    );
  }

  /**
   * Call to share some resource
   */
  callResource(
    alarmId: string,
    message: string,
    resource: string,
    notifyOnUpdate: boolean,
    deactivateAlarmWhenAllDone: boolean
  ) {
    this.errors.set('call', false);
    this.loading.set('call', true);
    this.notify();

    const data = {
      alarmId,
      message,
      resource,
      notifyOnUpdate,
      deactivateAlarmWhenAllDone
    };

    ajax.postByDesc(
      false,
      endpoints.CALL_RESOURCE,
      data,
      (err: string | null, xhr: XMLHttpRequest, data: any) => {
        this.loading.set('call', false);

        if (err) {
          this.errors.set('call', err);
          this.notify();
          UIStore.showSnackbar('RESOURCE_ASSIGN_FAILED');
          return;
        }

        if (!data) {
          this.errors.set('call', 'unexpected error');
          UIStore.showSnackbar('RESOURCE_ASSIGN_FAILED');
          this.notify();
          return;
        }

        const { data: response } = data;
        switch (response.Successful) {
          case 0:
            UIStore.showSnackbar('RESOURCE_ASSIGN_NOT_CALLED');
            break;
          case 1:
            UIStore.showSnackbar('RESOURCE_ASSIGN_ONE_CALLED');
            break;
          default:
            UIStore.showSnackbar('RESOURCE_ASSIGN_SOME_CALLED');
            break;
        }

        this.notify();
      }
    );
  }

  /**
   * Subscribe for store updates
   * @param listener
   */
  addEventListener(listener: EventListener) {
    this.addListener(constants.RESOURCES_NOTIFY, listener);
  }

  /**
   * Un-subscribe listener from store update
   * @param listener
   */
  removeEventListener(listener: EventListener) {
    this.removeListener(constants.RESOURCES_NOTIFY, listener);
  }

  /**
   * Notify listeners about update
   */
  notify() {
    this.emit(constants.RESOURCES_NOTIFY);
  }
}

export default new ResourcesStore();
