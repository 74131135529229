import React from 'react';
import { isDeepEqual } from 'react-use/lib/util';
import { CallDetailsModel, DynamicDetailsModel, Participant } from '../interfaces/backend_model';
import localization from '../localization';
import alarmActiveStore from '../stores/alarms/alarm_active_store_actions';
import CallsStore from '../stores/calls/calls_store';
import TwilioStore from '../stores/twillio_store';
import { formatSecondsAsTime, getTime } from '../utilities/time_format';
interface Props { }

interface State {
  tick: number;
  isEnded: boolean;
  callers: Participant[];
  autoHandUpAt: any | null;
  onHold: boolean;
}

export default class CallInfo extends React.Component<Props, State> {
  interval: number;
  constructor(props: Props) {
    super(props);

    this.state = {
      tick: 0,
      isEnded: false,
      callers: [],
      autoHandUpAt: null,
      onHold: false
    };

    this.onTick = this.onTick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    TwilioStore.addEventListener(this.onChange);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      this.state.tick !== nextState.tick ||
      this.state.isEnded !== nextState.isEnded ||
      !isDeepEqual(this.state.callers, nextState.callers) ||
      this.state.autoHandUpAt !== nextState.autoHandUpAt
    );
  }

  componentWillUnmount() {
    TwilioStore.removeEventListener(this.onChange);
  }

  onChange() {
    const state = TwilioStore.getState();
    const callDetails = TwilioStore.getCallDetails();
    // Call started
    if (state.isbusy && callDetails.started && !state.onHold) {
      this.interval && clearInterval(this.interval);
      this.setState(
        {
          tick: 0,
          isEnded: false,
          callers: this.getPhoneNumber(),
          autoHandUpAt: callDetails.autoHangUpAt
        },
        () => (this.interval = window.setInterval(this.onTick, 1000))
      );
      return;
    }

    // Call ended
    if (!state.isbusy && callDetails.ended && !callDetails.isCurrent) {
      this.interval && clearInterval(this.interval);
      this.setState({
        isEnded: true,
        tick: callDetails.elapsedTime!,
        autoHandUpAt: callDetails.autoHangUpAt
      });
    }

    // Call on hold
    if (!state.isbusy && state.onHold && !callDetails.isCurrent) {
      this.interval && clearInterval(this.interval);
      this.setState({ onHold: state.onHold, isEnded: true });
    }
  }

  getPhoneNumber(): Participant[] {
    const activeCall = CallsStore.getActiveCall();
    if (activeCall && activeCall.Participants && activeCall.Participants.length) {
      return activeCall.Participants;
    }

    const callDetails: CallDetailsModel = TwilioStore.getCallDetails();
    const dynamidDetails: DynamicDetailsModel = alarmActiveStore.getAlarmDetails(alarmActiveStore.currentAlarmId)?.DynamicDetails;
    if (callDetails.destination !== callDetails.from || callDetails.isCurrent) {
      return [{ "TelephoneNumber": callDetails.destination! } as Participant];
    }
    if (dynamidDetails && dynamidDetails.CallbackTelephoneNumber) {
      return [{ "TelephoneNumber": dynamidDetails.CallbackTelephoneNumber } as Participant];

    }
    return [{ "TelephoneNumber": callDetails.destination || callDetails.from } as Participant];
  }

  onTick() {
    this.setState({ tick: this.state.tick + 1, callers: this.getPhoneNumber() });
  }

  render() {
    const { isEnded, tick, callers } = this.state;
    const formattedTime = formatSecondsAsTime(tick);


    return (
      <div className="flex-row-around p-05em">
        {tick > 0 && (
          <div>
            <div className="flex-1-1-100p">
              {callers.map((caller, i) => {
                const displayName = CallsStore.getParticipantDisplayName(caller);
                if (displayName.length > 1) { // Display name and telephone number is available
                  return <div className="participant" key={i}><span className="primary">{displayName[0]}</span> <span className='non-focus'>{displayName[1]}</span></div>;
                }
                else { // Only telephone number is available
                  return <div className="participant" key={i}>{displayName[0]}</div>;
                }
              })}
              <div className={'participant ' + (isEnded ? 'non-focus' : '')}>({formattedTime})</div>
            </div>
            {!!this.state.autoHandUpAt && (
              <p className="flex-row-center flex-1-1-100p f-13">
                <i>
                  {localization.t('WILL_HANG_UP_AT')}&nbsp;&nbsp;{getTime(this.state.autoHandUpAt)}
                </i>
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}
