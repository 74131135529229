import { GridApi } from 'ag-grid-community';
import React, { ReactChildren } from 'react';
import localization from '../localization';

interface Props {
  classes?: string | Array<string>;
  bgColor?: string;
  multiChildrenRenderer?: (data: any) => ReactChildren;
  useValueFormatter?: boolean;
  valueFormatter?: (data: object) => any;
  headerLocKey: string;
  fieldToDisplay: string;
  rowIndex: number;
  api: GridApi;
  getRowData?: (data: object) => void
}

export default React.forwardRef(
  ({
    classes,
    bgColor,
    rowIndex,
    api,
    multiChildrenRenderer,
    getRowData,
    useValueFormatter,
    valueFormatter,
    fieldToDisplay,
    headerLocKey
  }: Props,
    ref
  ) => {
    const rowAtIndex = api.getDisplayedRowAtIndex(rowIndex) || {} as any;

    (getRowData && rowAtIndex) ? getRowData(rowAtIndex.data) : null;

    const tooltipClasses = classes ? Array.isArray(classes) ? classes.join(' ') : classes : '';

    React.useImperativeHandle(ref, () => {
      return {
        getReactContainerClasses() {
          return ['ag-grid-tooltip'];
        },
      };
    });

    const renderContent = () => {
      const { data } = rowAtIndex;

      //data exist - we render cell tooltip
      if (data) {
        if (multiChildrenRenderer) {
          return multiChildrenRenderer(data);
        } else if (useValueFormatter && valueFormatter) {
          return valueFormatter(data[fieldToDisplay]);
        } else {
          return data[fieldToDisplay];
        }
      } else {
        //no data - we render header tooltip
        return localization.t(headerLocKey);
      }

    }

    return (
      <div
        className={`ag-grid-default-tooltip ${tooltipClasses}`}
      >
        {renderContent()}
      </div>
    );
  });
