const constants = require('../json/constants.json');

export function registerTelephoneAction(
  telephone_number: string,
  alarm_id: string,
  contactId?: number
) {
  return {
    type: constants.REGISTER_TELEPHONE_NUMBER,
    telephone_number,
    alarm_id,
    contactId
  };
}
