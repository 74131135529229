import dateTime from "../../date_time";
import React from "react";
import { LogModel } from "../../interfaces/backend_model";
import localization from "../../localization";
import constants from "../../../json/constants.json";

const maxTextLength = 25;

export const AlarmLogItem = ({
  alarmLog,
  iconClock,
}: {
  alarmLog: LogModel;
  iconClock: React.ReactNode;
}) => {
  const alarmT = localization.useNSt(constants.NS_ALARM);

  const [showAllText, setShowAllText] = React.useState(false);

  let dateAndTimeUpd = dateTime.toLocaleDateAndTime(alarmLog.LastUpdated);
  const isTextLarge = alarmLog.LogText.length > maxTextLength;

  const renderLogHeader = () => {
    let logHeaderText;
    switch (alarmLog.LogEventCode) {
      case 1:
        logHeaderText = alarmT('REASON');
        break;
      case 2:
        logHeaderText = alarmT('ACTION');
        break;
      default:
        logHeaderText = alarmT('NOTE');
        break;
    }
    return (
      <div className="alarm-log-header">{logHeaderText}:</div>
    );
  }

  const onShowMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowAllText(!showAllText);
  }

  const renderText = () => {
    if (!isTextLarge) {
      return alarmLog.LogText;
    }
    return (
      <>
        {showAllText ? `${alarmLog.LogText} ` : `${alarmLog.LogText.slice(0, maxTextLength)}... `}
        <span
          className="alarm-log-text-more"
          onClick={onShowMore}
        >
          {showAllText ? alarmT("LESS") : alarmT("MORE")}
        </span>
      </>
    );
  }

  return (
    <div key={alarmLog.Id} className="flex-row-between margin-bottom-05em">
      <div className="flex-col">
        {renderLogHeader()}
        <div className="alarm-log-text">
          {renderText()}
        </div>
      </div>
      <div className="flex-row alarm-log-date-time">
        {iconClock}
        <div className="alarm-history-time">{dateAndTimeUpd.time}</div>
      </div>
    </div>
  );
}