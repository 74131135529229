import Paper from 'material-ui/Paper';
import React from 'react';
import appDispatcher from '../app_dispatcher';
import authentication from '../authentication';
import { MeModel } from '../interfaces/backend_model';
import localization from '../localization';
import groupsStore from '../stores/groups_store';
import Grid from './grid';
import SpinnerOverlay from './spinner_overlay';

const constants = require('../../json/constants.json');

interface Props {
  params: { respondent_group_id: string };
}

interface State {
  respondents: MeModel[] | null;
  overlay: boolean;
}

interface CalculatedState {
  respondents: MeModel[] | null;
}

export class RespondentGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      overlay: false,
      respondents: null
    };
  }

  calcState(props: Props): CalculatedState {
    let { respondent_group_id } = props.params;
    let respondents = groupsStore.getRespondentGroupDetails(respondent_group_id);

    return { respondents };
  }

  pendingByState(state: CalculatedState, respondent_group_id: string) {
    let { respondents } = state;
    let has_data = respondents;
    if (!has_data) {
      if (!respondents) {
        appDispatcher.handleServerAction({
          type: constants.LOAD_RESPONDENT_GROUP_DETAILS,
          respondent_group_id
        });
      }
    }
    appDispatcher.handleServerAction({
      type: constants.START_UPDATING_RESPONDENT_GROUP_DETAILS,
      respondent_group_id
    });
    return has_data;
  }

  componentDidMount() {
    let state = this.calcState(this.props);
    let { respondent_group_id } = this.props.params;
    let has_data = this.pendingByState(state, respondent_group_id);
    this.setState({
      respondents: state.respondents,
      overlay: !has_data
    });
    groupsStore.on(constants.RESPONDENT_GROUP_DETAILS_CHANGE, this._onChange);
  }

  componentWillUnmount() {
    groupsStore.off(constants.RESPONDENT_GROUP_DETAILS_CHANGE, this._onChange);

    appDispatcher.handleServerAction({
      type: constants.STOP_UPDATING_RESPONDENT_GROUP_DETAILS
    });
  }

  componentWillReceiveProps(nextProps: Props) {
    let { respondent_group_id } = this.props.params;
    let next_respondent_group_id = nextProps.params.respondent_group_id,
      state = this.calcState(nextProps),
      has_data = this.pendingByState(state, next_respondent_group_id);
    if (!state.respondents || respondent_group_id !== next_respondent_group_id) {
      this.setState({
        respondents: state.respondents,
        overlay: !has_data
      });
    }
  }

  _onChange = () => {
    let { respondent_group_id } = this.props.params;
    let state = this.calcState(this.props),
      has_data = this.pendingByState(state, respondent_group_id);
    this.setState({
      respondents: state.respondents,
      overlay: !has_data
    });
  };

  render() {
    const isTwilio =
      authentication.ls_get([authentication.getUserId(), constants.TELEPHONY_KEY]) ===
      constants.TELEPHONY_CLOUD;

    let { respondents, overlay } = this.state;
    let respondentRowKey = 'RespondentId',
      alarmT = localization.useNSt(constants.NS_ALARM),
      respondentsHeaders = [
        {
          key: ['Name', 'Initials'],
          skip_key: [false, true],
          wrap_key: [false, '({Initials})'],
          join_key: ' ',
          content: alarmT('NAME')
        },
        {
          key: 'LoggedIn',
          loc_value: [[true, alarmT('YES')], [false, alarmT('NO')]],
          content: alarmT('LOGGED_IN')
        },
        {
          key: 'Active',
          loc_value: [[true, alarmT('YES')], [false, alarmT('NO')]],
          content: alarmT('ACTIVE_STATE')
        },
        {
          key: isTwilio ? 'TwilioUserName' : 'Telephone',
          telephone_format: 'yes',
          content: alarmT('TELEPHONE')
        }
      ];

    return (
      <div className="flex-col p-rel flex-1-1-0px">
        <Paper zDepth={1} className="flex-col flex-1-1-0px y-scroll">
          <Grid
            headers={respondentsHeaders}
            table={true}
            wrapperClassName="w-100p"
            tableClassName="form-table w-100p"
            rowKey={respondentRowKey}
            rows={respondents}
          />
        </Paper>
        {overlay ? <SpinnerOverlay /> : null}
      </div>
    );
  }
}
