import axios from "axios";
import PermissionsStore from "../stores/mobx/PermissionsStore";
import AxiosHelper from "./axiosApiHelper";

export const isNewUploadEnabled = (): boolean => {
    return PermissionsStore.checkPermission('AlarmOperator') || PermissionsStore.checkPermission('SimplifiedOperator');
}

export const getFileContent = async (fileName: string): Promise<string> => {
    if (!fileName) {
        return '';
    }
    const response = await axios.get(`api/media/file?filename=${fileName}`, {
        headers: {
            Authorization: AxiosHelper.getAuthenticationHeader()
        },
        responseType: 'blob'
    });

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            if (reader.result) {
                const base64Data = reader.result;
                resolve(base64Data as string);
            }
        }
        reader.onerror = reject;
        reader.readAsDataURL(response.data);
    });
}