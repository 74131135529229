import * as PropTypes from 'prop-types';
import React from 'react';
import {
  sendMessage,
  startTwilioCallToNumber,
  startTwilioCallToUser,
  takeAlarmCall
} from '../actions/twilio';
import authentication from '../authentication';
import event_bus from '../event_bus';
import { MessageModel } from '../interfaces/backend_model';
import { isUsername } from '../utilities/user';
import PhoneNumberRenderer from './phonenumber_renderer';
import { SendMessagePopup } from './send_message_popup';

const constants = require('../../json/constants.json');

const reg_exp_plus = /\+/gi;
const reg_skype_prefix = /^(?:skype:)/gi;
const reg_skype_postfix = /(\?chat|\?call|\?voicemail)$/gi;

interface State {
  showMessageModal: boolean;
}

interface Props {
  onPhoneClick?: Function;
  isQueueType?: boolean;
  alarmId?: string;
  value: string;
  childRef?: Function;
  contactId?: number | null;
  showMessageButton?: boolean;
  resource?: any;
  typeDiv?: JSX.Element;
}

interface Context {
  readonly: boolean;
  alarmId: string;
}

export default class TelephoneNumber extends React.PureComponent<Props, State, Context> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showMessageModal: false
    };

    this.handleTelephoneClick = this.handleTelephoneClick.bind(this);
    this.handleTwilioCall = this.handleTwilioCall.bind(this);
    this.handleSkypeClick = this.handleSkypeClick.bind(this);
    this.handleMessageClick = this.handleMessageClick.bind(this);
    this.messagePopupCallback = this.messagePopupCallback.bind(this);
  }

  static contextTypes = {
    readonly: PropTypes.bool,
    alarmId: PropTypes.string
  };

  handleTelephoneClick(e: React.MouseEvent<HTMLAnchorElement>, registerCall = true) {
    if (registerCall) {
      event_bus.trigger(constants.REGISTER_TELEPHONE_NUMBER, {
        telephone_number: e.currentTarget.dataset.tel,
        contactId: this.props.contactId
      });
    }

    if (this.props.onPhoneClick) {
      this.props.onPhoneClick();
    }
  }

  handleSkypeClick(e: React.MouseEvent<HTMLAnchorElement>) {
    return;
  }

  handleTwilioCall(e: React.MouseEvent<HTMLAnchorElement>) {
    this.handleTelephoneClick(e, false);

    const { tel } = e.currentTarget.dataset;
    const { isQueueType } = this.props;
    const alarmId = this.props.alarmId || this.context.alarmId;

    e.preventDefault();
    e.stopPropagation();

    if (isQueueType) {
      takeAlarmCall(alarmId);
      return;
    }

    if (isUsername(tel)) {
      startTwilioCallToUser(tel, alarmId);
    } else {
      startTwilioCallToNumber(tel, alarmId);
    }

    if (this.props.onPhoneClick) {
      this.props.onPhoneClick();
    }
  }

  handleMessageClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    e.stopPropagation();
    this.changeModalVisibility();
  }

  messagePopupCallback(e: React.ChangeEvent<HTMLButtonElement>, messageData?: MessageModel) {
    const element = e.target;
    const alarmId = this.props.alarmId || this.context.alarmId;

    if (!element.dataset) return;

    if (element.dataset.click === 'confirm' && messageData) {
      sendMessage(messageData, alarmId);
      this.changeModalVisibility();
    }

    if (element.dataset.click === 'close') {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility() {
    this.setState(prevState => {
      return { showMessageModal: !prevState.showMessageModal };
    });
  }

  renderTelephone() {
    let { value, childRef, isQueueType, showMessageButton, typeDiv } = this.props;
    let tel: string,
      href: string,
      handleOnPhoneClick: (e: React.MouseEvent<HTMLAnchorElement>) => void,
      handleOnMessageClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    const skype = value ? value.match(reg_skype_prefix) : false;
    const className = 'flex-row telephone';
    const access_token = authentication.getUserLSData(constants.ACCESS_TOKEN_KEY);
    const telephony_integration = authentication.ls_get([
      authentication.getUserId(),
      constants.TELEPHONY_KEY
    ]);
    const alarmId = this.props.alarmId || this.context.alarmId;
    const writeMessage =
      alarmId && (typeof showMessageButton === 'boolean' ? showMessageButton : !skype);
    handleOnMessageClick = writeMessage ? this.handleMessageClick : () => null;

    value = value || '';

    switch (this.context.readonly ? constants.TELEPHONY_EXTERNAL : telephony_integration) {
      case constants.TELEPHONY_LOCAL:
        tel = value.replace(reg_exp_plus, '');
        if (!tel && !isQueueType) {
          return null;
        }
        if (skype) {
          href = tel;
          tel = value.replace(reg_skype_prefix, '').replace(reg_skype_postfix, '');
          handleOnPhoneClick = this.handleSkypeClick;
        } else {
          href = 'skygd:call?dest=' + tel + '&token=' + access_token;
          handleOnPhoneClick = this.handleTelephoneClick;
        }

        return (
          <PhoneNumberRenderer
            tel={tel}
            href={href}
            childRef={childRef}
            handleOnPhoneClick={handleOnPhoneClick}
            handleOnMessageClick={handleOnMessageClick}
            className={className}
            showMessageButton={writeMessage}
            typeDiv={typeDiv}
          />
        );
      case constants.TELEPHONY_BASIC_TEL:
        tel = value;
        if (!tel && !isQueueType) {
          return null;
        }
        if (skype) {
          href = tel;
          tel = value.replace(reg_skype_prefix, '').replace(reg_skype_postfix, '');
          handleOnPhoneClick = this.handleSkypeClick;
        } else {
          href = 'tel:' + tel;
          handleOnPhoneClick = this.handleTelephoneClick;
        }
        return (
          <PhoneNumberRenderer
            tel={tel}
            href={href}
            childRef={childRef}
            handleOnPhoneClick={handleOnPhoneClick}
            handleOnMessageClick={handleOnMessageClick}
            className={className}
            showMessageButton={writeMessage}
            typeDiv={typeDiv}
          />
        );
      case constants.TELEPHONY_BASIC_CALL_TO:
        tel = value;
        if (!tel && !isQueueType) {
          return null;
        }
        if (skype) {
          href = tel;
          tel = value.replace(reg_skype_prefix, '').replace(reg_skype_postfix, '');
          handleOnPhoneClick = this.handleSkypeClick;
        } else {
          href = 'callto:' + tel;
          handleOnPhoneClick = this.handleTelephoneClick;
        }
        return (
          <PhoneNumberRenderer
            tel={tel}
            href={href}
            childRef={childRef}
            handleOnPhoneClick={handleOnPhoneClick}
            handleOnMessageClick={handleOnMessageClick}
            className={className}
            showMessageButton={writeMessage}
            typeDiv={typeDiv}
          />
        );
      case constants.TELEPHONY_CLOUD:
        tel = value;
        if (!tel && !isQueueType) {
          return null;
        }
        if (skype) {
          href = tel;
          tel = value.replace(reg_skype_prefix, '').replace(reg_skype_postfix, '');
          handleOnPhoneClick = this.handleSkypeClick;
        } else {
          href = 'callto:' + tel;
          handleOnPhoneClick = this.handleTwilioCall;
        }
        return (
          <PhoneNumberRenderer
            tel={tel}
            href={href}
            childRef={childRef}
            handleOnPhoneClick={handleOnPhoneClick}
            handleOnMessageClick={handleOnMessageClick}
            className={className}
            showMessageButton={writeMessage}
            typeDiv={typeDiv}
          />
        );
      case constants.TELEPHONY_EXTERNAL:
      case constants.TELEPHONY_NONE:
      default:
        return <span data-target="phone">{value}</span>;
    }
  }

  render() {
    const { showMessageButton, value, resource } = this.props;
    const showPopup = typeof showMessageButton === 'boolean' ? showMessageButton : true;
    return (
      <>
        {this.renderTelephone()}
        {showPopup && !!resource && (
          <SendMessagePopup
            onClick={this.messagePopupCallback}
            show={this.state.showMessageModal}
            resource={resource}
            selectedNumber={value}
          />
        )}
      </>
    );
  }
}
