import React from 'react';

export default class IconOpenWindow extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.0625 6.75C5.37316 6.75 5.625 6.49816 5.625 6.1875C5.625 5.87684 5.37316 5.625 5.0625 5.625C4.75184 5.625 4.5 5.87684 4.5 6.1875C4.5 6.49816 4.75184 6.75 5.0625 6.75Z" fill="white"/>
        <path d="M6.75 6.75C7.06066 6.75 7.3125 6.49816 7.3125 6.1875C7.3125 5.87684 7.06066 5.625 6.75 5.625C6.43934 5.625 6.1875 5.87684 6.1875 6.1875C6.1875 6.49816 6.43934 6.75 6.75 6.75Z" fill="white"/>
        <path d="M9 6.1875C9 6.49816 8.74816 6.75 8.4375 6.75C8.12684 6.75 7.875 6.49816 7.875 6.1875C7.875 5.87684 8.12684 5.625 8.4375 5.625C8.74816 5.625 9 5.87684 9 6.1875Z" fill="white"/>
        <path d="M13.5 1.125C14.7426 1.125 15.75 2.13236 15.75 3.375C16.9926 3.375 18 4.38236 18 5.625V14.625C18 15.8676 16.9926 16.875 15.75 16.875H4.5C3.25736 16.875 2.25 15.8676 2.25 14.625C1.00736 14.625 0 13.6176 0 12.375V3.375C0 2.13236 1.00736 1.125 2.25 1.125H13.5ZM2.25 13.5V5.625C2.25 4.38236 3.25736 3.375 4.5 3.375L14.625 3.375C14.625 2.75368 14.1213 2.25 13.5 2.25H2.25C1.62868 2.25 1.125 2.75368 1.125 3.375V12.375C1.125 12.9963 1.62868 13.5 2.25 13.5ZM3.375 9V14.625C3.375 15.2463 3.87868 15.75 4.5 15.75H15.75C16.3713 15.75 16.875 15.2463 16.875 14.625V9H3.375ZM16.875 7.875V5.625C16.875 5.00368 16.3713 4.5 15.75 4.5H4.5C3.87868 4.5 3.375 5.00368 3.375 5.625V7.875H16.875Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.8333 10.4167C10.8333 10.1865 11.0199 10 11.25 10H14.5833C14.8135 10 15 10.1865 15 10.4167V13.75C15 13.9801 14.8135 14.1667 14.5833 14.1667C14.3532 14.1667 14.1667 13.9801 14.1667 13.75V11.4226L10.7113 14.878C10.5486 15.0407 10.2848 15.0407 10.122 14.878C9.95932 14.7152 9.95932 14.4514 10.122 14.2887L13.5774 10.8333H11.25C11.0199 10.8333 10.8333 10.6468 10.8333 10.4167Z" fill="white"/>
      </svg>
    );
  }
}
