import React from 'react';
import constants from '../../../json/constants.json';
import Section from '../../components/section';
import enumDictionary from '../../enum_dictionary';
import localization from '../../localization';
import LabelValue from './LabelValue';

export default class StanleyAlarmInfoSection extends React.Component {
  render() {
    const { alarmDetails, instance, widget } = this.props;
    let alarmT = localization.useNSt(constants.NS_ALARM);

    return (
      <Section
        widget={widget}
        addSectionClassName="flex-col p-05em use-raw-text"
        addBodyClassName="p-t0-l0-1"
        headerLocKey="ALARM_INFO"
        headerLocOptions={{ ns: constants.NS_ALARM }}
      >
        <LabelValue
          alarmT={alarmT}
          position="col"
          loc_key={'INFORMATION_ABOUT_THE_ALARM'}
          value={alarmDetails.Flags}
          wrapValue={(v, key) => <div key={key}>{v}</div>}
          formatKey={'AlarmFlagEnum'}
          formatValue={enumDictionary.formatEnum}
          linkify={instance.linkifyValue}
        />
        <LabelValue
          alarmT={alarmT}
          position="col"
          loc_key={'TRANSMITTER_MODEL'}
          value={alarmDetails.Model}
          linkify={instance.linkifyValue}
        />
      </Section>
    );
  }
}
