import axios from 'axios';
import * as Logger from 'js-logger';
import React from 'react';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { LinkedModel, UserDetailsModel, Widget } from '../../interfaces/backend_model';
import localization from '../../localization';
import settingsStore from '../../stores/settings_store';
import { changeVarsInString } from './CurenaPersonalDetailsSection';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

const constants = require('../../../json/constants.json');

interface CustomerInfoSectionProps {
  toggleType?: string;
  toggleState?: string;
  userDetails: UserDetailsModel;
  Linked: LinkedModel;
  widget: Widget;
  template: string;
  fullscreenMode?: boolean;
}

const alarmT = localization.useNSt(constants.NS_ALARM);

function CustomerInfoWidget(props: CustomerInfoSectionProps) {
  const {
    toggleType,
    toggleState,
    userDetails,
    widget,
    template,
    fullscreenMode,
  } = props;
  const [customerInfo, setCustomerInfo] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  React.useEffect(
    () => {
      if (userDetails.Customer && userDetails.Customer.ExternalId) {
        loadCurenaCustomerInfo(userDetails.Customer.ExternalId);
      }
    },
    [userDetails]
  );

  const loadCurenaCustomerInfo = async (customerId: string) => {
    let url = `https://as-dynamics-api.azurewebsites.net/v1/skyresponse/customers/${customerId}`;
    if (process.env.NODE_ENV !== 'production') {
      url = `/v1/skyresponse/customers/${customerId}`;
    }
    const response = await getDetailsData({
      URL: url
    });

    setCustomerInfo(response);
    setIsLoading(false);
  };

  const getDetailsData = (data: { URL: string }) => {
    const { Linked } = props;
    let header = { 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*' };

    const curenaUserId = settingsStore.getValueByKey('CurenaUserId', 'string') || '';
    const curenaAuth = settingsStore.getValueByKey('CurenaAuth', 'string') || '';

    if (Linked && Linked.HeaderValue && Linked.Header) {
      header[Linked.Header] = changeVarsInString(Linked.HeaderValue);
    } else if (curenaUserId && curenaAuth) {
      header['Authorization'] = 'DYN-CUR-SHA256 ' + curenaUserId + ' ' + curenaAuth;
    }

    return axios
      .get(data.URL, {
        headers: header
      })
      .then(response => {
        return response.data;
      })
      .catch((error: any) => {
        setIsLoading(false);
        setHasError(true);
        Logger.error(error);
      });
  };

  if (!userDetails || !userDetails.Customer || !userDetails.Customer.ExternalId) {
    return null;
  }

  const callCenterName =
    userDetails.Customer && userDetails.Customer.Name ? userDetails.Customer.Name : '';
  const getContent = () => {
    if (isLoading) {
      return <div className="flex-col-start item-ver">{alarmT('LOADING_DATA')}</div>;
    }

    if (hasError || !customerInfo) {
      return <div className="flex-col-start item-ver">{alarmT('FAILED_LOAD_DATA')}</div>;
    }

    return (
      <div className="d-flex w-100p">
        <div className="p-r-l-1em item-ver" style={{ flex: '0 15%' }}>
          <div className="flex-col-start bold">{alarmT('CALL_CENTER_NAME')}</div>
          <div className="p-025em">{callCenterName}</div>
        </div>
        <div className="p-r-l-1em item-ver" style={{ flex: '1' }}>
          <div className="flex-col-start bold">{alarmT('WELCOME_TEXT')}</div>
          <div className="p-025em">{customerInfo.welcomeText || ''}</div>
        </div>
        <div className="p-r-l-1em item-ver" style={{ flex: '1' }}>
          <div className="flex-col-start bold">{alarmT('CUSTOMER_DOCUMENTATION')}</div>
          {customerInfo.documents.length
            ? customerInfo.documents.map(
              (el: { displayName: string; uri: string }, index: number) => (
                <div key={index} className="p-025em">
                  <a
                    href={el.uri}
                    style={{ color: 'rgb(0, 0, 238)', textDecoration: 'underline' }}
                    target="_blank"
                  >
                    {el.displayName}
                  </a>
                </div>
              )
            )
            : null}
        </div>
      </div>
    );
  };

  const hasContent = true; //Alawys have content
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "CustomerInfoSection", true);
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocKey={template === 'curena-alarm' ? ' ' : alarmT('CALL_CENTER_INFO_SECTION')}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      {getContent()}
    </Section>
  );
}

export default class CustomerInfoSection extends React.Component<CustomerInfoSectionProps> {
  render() {
    return (
      <WidgetErrorBoundary>
        <CustomerInfoWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
