import * as Logger from 'js-logger';
import { action, observable } from 'mobx';
import ajax from '../../ajax';

const endpoints = require('../../../json/endpoints.json');

export const permissionsMap = {
  alarmOperator: 'AlarmOperator',
  centerTestalarm: 'CenterTestalarm',
  centerUsers: 'CenterUsers',
  showAlarms: 'ShowAlarms',
  showRecent: 'ShowRecent',
  skygdSystemAdmin: 'SkygdSystemAdmin'
};

export class PermissionsStore {
  @observable list = [] as string[];

  @action.bound
  fetchPermissions() {
    ajax.getByDesc(
      true,
      endpoints.PERMISSIONS_LIST,
      action((err: string | null, xhr: XMLHttpRequest, data: { Actions: string[] }) => {
        if (err || !data) {
          Logger.error(err);
          Logger.error(data);
          return;
        }

        this.list = data.Actions;
      })
    );
  }

  checkPermission = (permission: string) => this.list.includes(permission);

  getMap() {
    return permissionsMap;
  }
}

export default new PermissionsStore();
