import React, { Component } from 'react';

export default class LabelValue extends Component {
  render() {
    const {
      loc_key,
      alarmT,
      value,
      formatKey,
      formatValue,
      wrapClassName,
      wrapValue,
      position,
      colon,
      userInfo,
      addTextClassName,
      linkify
    } = this.props;

    if (!value || (Array.isArray(value) && !value.length)) {
      return null;
    }

    let key = alarmT(loc_key),
      str_value = value;

    if (formatKey && formatValue) {
      if (Array.isArray(value) && wrapValue) {
        str_value = value.map(v => {
          return wrapValue(formatValue(formatKey, v), v);
        });
      } else {
        str_value = formatValue(formatKey, value);
      }
    }
    let content = null;
    let className = 'flex-row p-05em use-raw-text';
    let addClassName = 'b-b-c-gray p-05-0';
    let childClassName = 'flex-col-around flex-0-0-auto bold';
    let textClassName = 'flex-col-around ';

    if (userInfo) {
      className = 'flex-row use-raw-text ' + addClassName;
      childClassName = childClassName + ' p-l-05em';
    }

    if (addTextClassName) {
      textClassName = textClassName + addTextClassName;
    }

    switch (position) {
      case 'row':
        content = (
          <div className={className}>
            <div className={childClassName}>{colon ? key + ':' : key}</div>
            <div className={textClassName}>{linkify ? linkify(str_value) : str_value}</div>
          </div>
        );
        break;
      case 'col':
        content = (
          <div className="flex-col p-05em use-raw-text">
            <div className="flex-0-0-auto bold">{colon ? key + ':' : key}</div>
            <div className="flex-col-around">{linkify ? linkify(str_value) : str_value}</div>
          </div>
        );
        break;
    }

    if (wrapClassName) {
      return <div className={wrapClassName + ' use-raw-text'}>{content}</div>;
    }
    return content;
  }
}
