import { formatDistance } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-images';
import constants from '../../../json/constants.json';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { Widget } from '../../interfaces/backend_model';
import { getFileContent, isNewUploadEnabled } from '../../utilities/fileFetchHelper';

interface BetaMediaSectionProps {
  widget: Widget;
  media: any[];
  toggleType: string;
  toggleState: string;
  fullscreenMode: boolean;
  headerLocKey: string;
}

interface PreviewProps {
  newUploadEnabled: boolean;
}

interface PreviewImageProps extends PreviewProps {
  image: {
    Image: string;
    ThumbNail: string;
    LastUpdated: string;
  };
}

interface PreviewAudioProps extends PreviewProps {
  sound: {
    Sound: string;
    ContentType: string;
  };
}

interface PreviewVideoProps extends PreviewProps {
  video: {
    Video: string;
    ContentType: string;
  };
}

const PreviewImage = ({ image, newUploadEnabled }: PreviewImageProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [imageData, setImageData] = useState<string>();
  const [imageThumb, setImageThumb] = useState<string>();

  const fetchImage = async () => {
    const imageSrc = await getFileContent(image.Image);
    const thumbSrc = await getFileContent(image.ThumbNail);
    setImageData(imageSrc);
    setImageThumb(thumbSrc);
  }

  useEffect(() => {
    if (newUploadEnabled) {
      fetchImage();
    }
  }, []);

  return (
    <div className="flex-center-w" style={{ paddingBottom: '5px' }}>
      {(!newUploadEnabled || imageData) && (
        <>
          <Lightbox
            isOpen={isPreviewOpen}
            backdropClosesModal={true}
            showImageCount={false}
            onClose={() => setIsPreviewOpen(false)}
            images={[{ src: imageData ?? '/media/file?filename=' + image.Image }]}
          />
          <div className="round">
            <img
              className="image"
              src={imageThumb ?? `/media/file?filename=${image.ThumbNail}`}
              onClick={() => setIsPreviewOpen(true)}
              onKeyDown={() => setIsPreviewOpen(true)}
              alt={image.Image}
            />
          </div>
        </>
      )}
      <p>
        {formatDistance(new Date(), new Date(image.LastUpdated), {
          includeSeconds: true,
          addSuffix: true,
          locale: (window.navigator.language || '').includes('en') ? enGB : sv
        })}{' '}
      </p>
    </div>
  );
}

const PreviewAudio = ({ sound, newUploadEnabled }: PreviewAudioProps) => {
  const [soundData, setSoundData] = useState<string>();

  const fetchAudio = async () => {
    const audioSrc = await getFileContent(sound.Sound);
    setSoundData(audioSrc);
  };

  useEffect(() => {
    if (newUploadEnabled) {
      fetchAudio();
    }
  }, []);

  return (
    <div className="p-05em">
      {(!newUploadEnabled || soundData) && (
        <audio preload="auto" controls id={sound.Sound}>
          <track kind="captions" />
          <source
            src={soundData ?? `/media/file?filename=${sound.Sound}`}
            type={sound.ContentType || 'audio/mp3'}
          />
        </audio>
      )}
    </div>
  );
}

const PreviewVideo = ({ video, newUploadEnabled }: PreviewVideoProps) => {
  const [videoData, setVideoData] = useState<string>();

  const fetchVideo = async () => {
    const videoSrc = await getFileContent(video.Video);
    setVideoData(videoSrc);
  };

  useEffect(() => {
    if (newUploadEnabled) {
      fetchVideo();
    }
  }, []);

  return (
    <div>
      <div className="p-05em">
        <video
          src={newUploadEnabled ? videoData : `/media/file?filename=${video.Video}`}
          preload="auto"
          controls
          id={video.Video}
          width="350px"
        >
          <track kind="captions" />
        </video>
      </div>
    </div>
  );
}

const MediaWidget = ({ media, toggleType, toggleState, widget, fullscreenMode, headerLocKey }: BetaMediaSectionProps) => {
  const newUploadEnabled = isNewUploadEnabled();

  const getItems = () =>
    [...media]
      .sort((a, b) => {
        if (a.LastUpdated === b.LastUpdated) return 0;
        return a.LastUpdated < b.LastUpdated ? 1 : -1;
      })
      .map(item => {
        if (item.Sound) {
          return <PreviewAudio key={item.Sound} sound={item} newUploadEnabled={newUploadEnabled} />;
        }
        if (item.Video) {
          return <PreviewVideo key={item.Video} video={item} newUploadEnabled={newUploadEnabled} />;
        }
        if (item.Image) {
          return <PreviewImage key={item.Image} image={item} newUploadEnabled={newUploadEnabled} />;
        }
        if (item.LiveVideo) {
          return null;
        }
        return null;
      })
      .filter(Boolean);


  const items = getItems();

  const hasContent = !!items?.length;
  const evaluatedToggleState = evalToggleState(
    hasContent,
    toggleState,
    widget ? widget.widgetName : 'BetaMediaSection'
  );
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      headerLocKey={headerLocKey}
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocOptions={{ ns: constants.NS_ALARM }}
    >
      <div className="w-100p flex-center-with-justify" style={{ padding: '10px' }}>
        {items}
      </div>
    </Section>
  );
}

export default class MediaSection extends React.Component<BetaMediaSectionProps> {
  render() {
    return (
      <WidgetErrorBoundary>
        <MediaWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
