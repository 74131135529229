import debounce from 'lodash.debounce';
import { action, computed, observable, when } from 'mobx';
import ajax from '../../ajax';
import appDispatcher from '../../app_dispatcher';
import AppHistory from '../../app_history';
import SettingsStore from '../settings_store';
import UIStore from './UIStore';

const endpoints = require('../../../json/endpoints.json');
const constants = require('../../../json/constants.json');

export interface UsersDataModel {
  AdditionalInformation: { Key: string; Value: string }[];
  ExchangeTelephoneNumber: string;
  Name: string;
  PositioningEnabled: boolean;
  TelephoneNumber: string;
  UserId: number;
}

export interface LocationDataModel {
  AlarmId: string;
  Template: string;
}

export class UserModel {
  @observable exchangeTelephoneNumber: string;
  @observable name: string;
  @observable positioningEnabled: boolean;
  @observable telephoneNumber: string;
  @observable additionalInformation = [];
  userId: number;

  constructor(model: any) {
    this.exchangeTelephoneNumber = model.ExchangeTelephoneNumber;
    this.name = model.Name;
    this.positioningEnabled = model.PositioningEnabled;
    this.telephoneNumber = model.TelephoneNumber;
    this.userId = model.UserId;
    this.additionalInformation = model.AdditionalInformation || [];
  }
}

export class UsersSearchStore {
  errors = observable.map();
  loading = observable.map();

  @observable usersData = [] as any;
  fetchUsersDebounced = debounce(this.fetchUsers, 250); // in ms

  @observable searchField = '';
  searchType = observable.box(null);

  constructor() {
    SettingsStore.on(constants.SETTINGS_CHANGE, this.setSearchType);
    this.setSearchType();
  }

  @action
  setSearchType = () => {
    const type = SettingsStore.getValueByKey('USERSEARCHTYPE');

    if (type) {
      this.searchType.set(type);
    }
  };

  isFreeSearchType() {
    return this.searchType.get() !== 'NATIONALID';
  }

  @action
  searchFor(text = '') {
    if (text.length === 0) {
      this.resetSearch();
      return;
    }

    this.searchField = text;

    const validation = text.length >= (this.isFreeSearchType() ? 2 : 6);

    if (validation) {
      this.fetchUsersDebounced(text);
    }
  }

  @action
  assignUser(user: UserModel, alarmId: string, reloadOnlyAlarmView?: boolean) {
    return ajax
      .postByDescPromise(endpoints.ASSIGN_USER, { userId: user.userId, alarmId })
      .then(() => {
        if (reloadOnlyAlarmView) {
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARM_DETAILS,
            alarm_id: alarmId
          });
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_ALARM_ATTACHMENTS,
            alarm_id: alarmId
          });
          return;
        }
        window.location.reload();
      });
  }

  @action
  resetSearch() {
    this.fetchUsersDebounced.cancel();
    this.usersData = [];
    this.searchField = '';
  }

  @action
  fetchUsers(numberId: string) {
    this.errors.set('list', false);
    this.loading.set('list', true);

    if (this.isFreeSearchType()) {
      ajax.getByDescWithData(
        false,
        endpoints.USERS_SEARCH_FREE,
        { q: numberId },
        this.fetchUsersDone
      );
    } else {
      ajax.getByDescWithData(false, endpoints.USERS_SEARCH, { numberId }, this.fetchUsersDone);
    }
  }

  @action
  fetchUsersDone = (err: string, xhr: XMLHttpRequest, data: UsersDataModel[]) => {
    this.loading.set('list', false);

    if (err) {
      this.errors.set('list', err || true);
      return;
    }

    // as the fetching of uses is asynchronous a fetching call can be in flight while the search has been reset
    // thus check if the search field is empty so that we dont show search results when there shouldn't be any
    if (this.searchField === '') {
      this.usersData = [];
    } else {
      this.usersData = (data || []).map(user => new UserModel(user));
    }
  };

  @action
  requestUserInfo(id: number, flagId?: number, textMessage?: string) {
    this.errors.set('userInfo', false);
    this.loading.set('userInfo', true);

    when(() => this.isUserInfoError, () => UIStore.showSnackbar('GENERIC_ERROR_MESSAGE'));

    const request = { user_id: id, payload: {} as any };

    if (flagId) {
      request.payload.FlagId = flagId;
    }

    if (textMessage) {
      request.payload.TextMessage = textMessage;
    }

    request.payload = JSON.stringify(request.payload);

    ajax.postByDesc(false, endpoints.USER_INFO, request, this.userInfoRequested);
  }

  @action
  requestUserLocation<T>(id: number, cb: T) {
    ajax.postByDesc(false, endpoints.LOCATE_USER_POSITION, { user_id: id }, cb);
  }

  @action
  userInfoRequested = (
    err: string | null,
    xhr: XMLHttpRequest,
    data: { AlarmId: string; Template: string }
  ) => {
    this.loading.set('userInfo', false);

    if (err) {
      this.errors.set('userInfo', err || true);
      return;
    }

    AppHistory.push(`${constants.PATH_TEMPLATE}/${data.Template}/${data.AlarmId}`);
  };

  @computed
  get isUserInfoLoading() {
    return this.isLoading('userInfo');
  }

  @computed
  get isUserInfoError() {
    return !!this.isError('userInfo');
  }

  @computed
  get isListLoading() {
    return this.isLoading('list');
  }

  @computed
  get isListError() {
    return this.isError('list');
  }

  /**
   * Generic loading getter for computeds
   * @private
   * @param type
   * @returns {boolean}
   */
  isLoading(type: string) {
    return !this.errors.get(type) && this.loading.get(type);
  }

  /**
   * Generic getter for errors computeds
   * @private
   * @param type
   * @returns {boolean}
   */
  isError(type: string) {
    return this.errors.get(type) && !this.loading.get(type);
  }

  @computed
  get users() {
    return this.usersData;
  }
}

export default new UsersSearchStore();
