import Logger from 'js-logger';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import React from 'react';
import constants from '../../../json/constants.json';
import endpoints from '../../../json/endpoints.json';
import ajax from '../../ajax';
import ExtraSettings from '../../components/extra_settings';
import * as playIcons from '../../components/play_icon';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { CameraType } from '../../enums/camera';
import localization from '../../localization';
import { filterWidgetSettings } from '../../utilities/filterWidgetSettingsHelper';
import HlsVideo from './HlsVideo';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

// This widget supports the following settings
const handledSettings = [
  'allow',
  'height',
  'sandbox',
  'seamless',
  'srcDoc',
  'width',
  'frameBorder',
  'scrolling'
];
let widgetSettings;
let allow;
let height;
let sandbox;
let seamless;
let srcDoc;
let width;
let frameBorder;
let scrolling;
let allowFullScreen; // Is deprecated but needed to allow fullscreen for Nattugla camera

class VideoBlock extends React.Component {
  @observable cameras = observable.map();
  @observable onPlaying = false;
  @observable videoHasError = false;
  @observable errorText = '';
  @observable isWaitingResponse = false;
  @observable isCameraRestarting = false;
  @observable commandSending = false;
  @observable extraOpened = observable.box(false);

  videoError = errorKey => {
    if (errorKey) {
      this.errorText = localization.t(errorKey);
    } else {
      this.errorText = localization.t('VIDEO_CANT_PLAYED');
    }
    this.videoHasError = true;
  };

  onPlay = id => {
    this.videoHasError = false;
    this.errorText = '';

    const params = { alarmId: this.props.alarmId, cameraId: id };
    const endpoint = endpoints.FETCH_CAMERA;
    let camera = this.props.cameras.find(camera => camera.Id === id);

    if (!camera.ViewingPermitted) {
      this.videoHasError = true;
      this.errorText = localization.t('VIEWING_PERMITTED_FALSE');
      return;
    }

    if (!camera.ViewingWindowShortPeriod) {
      this.videoHasError = true;
      this.errorText = localization.t('NOT_ALLOWED_TO_VIEW_CAMERA');
      return;
    }

    this.isWaitingResponse = true;

    ajax
      .postByDescPromise(endpoint, params)
      .then(response => {
        if (!response) {
          this.videoHasError = true;
          this.errorText = localization.t('STREAM_NOT_AVAILABLE');
          return;
        }

        this.cameras.set(id, response);
        this.onPlaying = true;
      })
      .catch(error => {
        let errorKey;
        try {
          const data = JSON.parse(error.xhr.response);
          errorKey = data.errors[0].name;
        } catch (error) {
          this.videoHasError = true;
          this.errorText = localization.t('STREAM_NOT_AVAILABLE');
          return;
        }

        this.videoHasError = true;
        this.errorText = errorKey
          ? localization.t(`${errorKey}`)
          : localization.t('STREAM_NOT_AVAILABLE');
        Logger.error(`[Camera (${id})] cannot get" ${JSON.stringify(error.err)}`);
      })
      .finally(() => {
        this.isWaitingResponse = false;
      });
  };

  onVideoEnd = id => (commands, cameraType) => {
    this.onPlaying = false;

    if (commands) {
      if (commands.DisableVideoPlayback || commands.DisableCameraRestart) {
        this.isCameraRestarting = true;
        this.videoHasError = true;
        this.errorText = localization.t('CAMERA_RESTARTING');
      }
      if (cameraType) {
        this.errorText.replace('{0}', cameraType);
      }

      if (commands.DisableTimeOut) {
        setTimeout(() => {
          if (commands.DisableVideoPlayback || commands.DisableCameraRestart) {
            this.isCameraRestarting = false;
            this.videoHasError = false;
            this.errorText = '';
          }
        }, commands.DisableTimeOut * 1000);
      }
    }
  };

  onaSendExtraCommand = (commandId, Id) => {
    this.commandSending = true;

    const { alarmId } = this.props;

    ajax
      .postByDescPromise(endpoints.CAMERA_COMMANDS, { alarmId, Id, commandId })
      .then(() => {
        this.commandSending = false;
      })
      .catch(error => {
        this.commandSending = false;
        Logger.error(error.err);
      });
  };

  toggleExtra = () => {
    this.extraOpened.set(!this.extraOpened.get());
  };

  //Blocked mouse right click on the video
  onContextMenu = e => {
    e.preventDefault();
  };

  // Lets start the stream!
  renderVideoBlock = cam => {
    const camera = this.cameras.get(cam.Id);

    if (camera.CameraType === CameraType.CAMERAPROXY_SECURITY) {
      // Camera of type CAMERAPROXY_SECURITY should use iFrame as a renderer
      const externalSrc = camera.CameraAddress + camera.MjpegPath;
      return (
        <div className="w-100p flex-col" style={{ padding: '10px' }}>
          <iframe
            key={cam.Id + this.props.alarmId}
            name={cam.Title}
            title={cam.Title}
            id={cam.Id}
            src={externalSrc}
            allow={allow}
            height={height}
            sandbox={sandbox}
            seamless={seamless}
            srcDoc={srcDoc}
            width={width}
            frameBorder={frameBorder}
            scrolling={scrolling}
            allowFullScreen={allowFullScreen}
          />
        </div>
      );
    } else {
      return (
        <div className="w-100p flex-col" style={{ padding: '10px' }}>
          <HlsVideo
            key={cam.Id + this.props.alarmId}
            name={cam.Title}
            title={cam.Title}
            Id={cam.Id}
            ViewingPermitted={cam.ViewingPermitted}
            ViewingWindowLong={cam.ViewingWindowLong}
            ViewingWindowLongEnd={cam.ViewingWindowLongEnd}
            ViewingWindowShortPeriod={cam.ViewingWindowShortPeriod}
            VideoPlaybackTimeout={cam.VideoPlaybackTimeout}
            VideoPlaybackNumRetries={cam.VideoPlaybackNumRetries}
            alarmId={this.props.alarmId}
            videoError={this.videoError}
            onVideoEnd={this.onVideoEnd(cam.Id)}
            {...camera}
            alarmDeactivated={this.props.alarmDeactivated}
            onContextMenu={this.onContextMenu}
            extraOpened={this.extraOpened.get()}
            toggleExtra={this.toggleExtra}
          />
        </div>
      );
    }
  };

  renderPreviewBlock = cam => {
    return (
      <div className="w-100p flex-col" style={{ padding: '10px' }}>
        <div
          key={cam.Title + cam.Id}
          className="player-area"
          ref={node => (this.container = node)}
          onContextMenu={this.onContextMenu}
        >
          <Observer>
            {() => this.videoHasError && <p className="error-text-input">{this.errorText}</p>}
          </Observer>
          <div
            className="scale-video"
            style={{ backgroundColor: '#000000' }}
            onClick={() =>
              !this.isCameraRestarting && !this.isWaitingResponse && this.onPlay(cam.Id)
            }
          >
            <Observer>
              {() =>
                this.videoHasError ? (
                  <playIcons.PlayBannedIcon
                    className="fullscreen-play-btn c-p"
                    strokeColor="#000"
                  />
                ) : (
                  <playIcons.PlayIcon className="fullscreen-play-btn c-p" />
                )
              }
            </Observer>
          </div>
          <div className="video-toolbar">
            <button
              type="button"
              onClick={() => this.onPlay(cam.Id)}
              title="Play stream"
              disabled={this.videoHasError || this.isWaitingResponse || this.isCameraRestarting}
            >
              <Observer>
                {() =>
                  this.videoHasError ? (
                    <playIcons.PlayBannedIcon strokeColor="#C1C1C1" />
                  ) : (
                    <playIcons.PlayIcon />
                  )
                }
              </Observer>
            </button>
          </div>
          <Observer>
            {() =>
              this.cameras.get(cam.Id) && !this.props.alarmDeactivated ? (
                <div style={{ marginTop: '5px' }}>
                  <ExtraSettings
                    extraOpened={this.extraOpened}
                    fullscreen={false}
                    alarmId={this.props.alarmId}
                    cameraId={cam.Id}
                    disabled={this.commandSending || this.isCameraRestarting}
                    onCommandSend={this.onaSendExtraCommand}
                    camera={this.cameras.get(cam.Id)}
                    onVideoEnd={this.onVideoEnd(cam.Id)}
                    toggleExtra={this.toggleExtra}
                  />
                </div>
              ) : null
            }
          </Observer>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Section
        widget={this.props.widget}
        addSectionClassName="flex-0-0-auto"
        headerLocKey={this.props.cam.Title}
        toggleType={this.props.toggleType}
        toggleState={this.props.toggleState}
        fullscreenMode={this.props.fullscreenMode}
        toggleDisplay={true}
        headerLocOptions={{ ns: constants.NS_ALARM }}
        key={this.props.cam.Id}
        withoutTranslation={true}
      >
        <Observer>
          {() =>
            !this.onPlaying || this.videoHasError
              ? this.renderPreviewBlock(this.props.cam)
              : this.renderVideoBlock(this.props.cam)
          }
        </Observer>
      </Section>
    );
  }
}

class CameraCareWidget extends React.Component {
  constructor(props) {
    super(props);

    // Check if we get widget settings that are supported
    widgetSettings = filterWidgetSettings(
      this.props.settings,
      handledSettings,
      this.props.widget ? this.props.widget.widgetName : 'CameraCareSection'
    );

    // Lets get the widget specific settings for how to render the iFrame
    allow = widgetSettings.allow ? widgetSettings.allow : 'camera *;microphone *;';
    height = widgetSettings.height ? widgetSettings.height : '400';
    sandbox = widgetSettings.sandbox ? widgetSettings.sandbox : undefined;
    seamless = widgetSettings.seamless ? widgetSettings.seamless : undefined;
    srcDoc = widgetSettings.srcDoc ? widgetSettings.srcDoc : undefined;
    width = widgetSettings.width ? widgetSettings.width : '100%';
    frameBorder = widgetSettings.frameBorder ? widgetSettings.frameBorder : '0';
    scrolling = widgetSettings.scrolling ? widgetSettings.scrolling : 'auto';
    if (widgetSettings.allowFullScreen === undefined) {
      allowFullScreen = true;
    } else {
      allowFullScreen = widgetSettings.allowFullScreen;
    }
  }

  render() {
    const widget = this.props.widget;

    const hasContent = this.props.cameras && this.props.cameras.length ? true : false;
    const evaluatedToggleState = evalToggleState(
      hasContent,
      this.props.toggleState,
      widget ? widget.widgetName : 'CameraCareSection'
    );
    if (hideWidget(evaluatedToggleState)) return null;

    if (this.props.cameras.length) {
      return (
        <React.Fragment>
          <div>
            {this.props.cameras.map((cam, index) => (
              <VideoBlock
                key={cam.Id + index}
                cam={cam}
                cameras={this.props.cameras}
                {...this.props}
                toggleType={this.props.toggleType}
                toggleState={evaluatedToggleState}
                fullscreenMode={this.props.fullscreenMode}
              />
            ))}
          </div>
        </React.Fragment>
      );
    }
    return <div />;
  }
}

export default class CameraCareSection extends React.Component {
  render() {
    return (
      <WidgetErrorBoundary>
        <CameraCareWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
