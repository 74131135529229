import React from 'react';
import Lightbox from 'react-images';
import Linkify from 'react-linkify';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { AlarmDetailsModel, ObjectTransmitterModel, Widget } from '../../interfaces/backend_model';
import localization from '../../localization';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

const constants = require('../../../json/constants.json');

const alarmT = localization.useNSt(constants.NS_ALARM);

interface DeviceInfoSectionProps {
  toggleType?: string;
  toggleState?: string;
  fullscreenMode?: boolean;
  alarmDetails: AlarmDetailsModel;
  widget: Widget;
}

function DeviceInfoWidget(props: DeviceInfoSectionProps) {
  const [deviceInfo, setDeviceInfo] = React.useState<ObjectTransmitterModel>();
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

  React.useEffect(
    () => {
      let mounted = true;
      const { alarmDetails } = props;

      if (alarmDetails.ObjectTransmitters) {
        const transmitter =
          alarmDetails.ObjectTransmitters.filter(el => el.IsAlarmingTransmitter) || [];

        if (mounted && transmitter.length) {
          // Only one transmitter for each alarm
          setDeviceInfo(transmitter[0]);
        }
      }
      return () => {
        mounted = false;
      };
    },
    [props.alarmDetails.ObjectTransmitters]
  );

  const { toggleType, toggleState, widget, fullscreenMode } = props;

  // NOTE: The size of the small image will possibly change when uploading to S3. No need to set the size to an image.
  const getContent = () => {
    if (!deviceInfo) {
      return
    }
    return deviceInfo ? (
      <div className="d-flex w-100p">
        <div className="p-r-l-1em item-ver flex-row-between" style={{ flex: '1' }}>
          <div className="p-trb-05em">
            <div className="flex-col-start bold">
              <Linkify
                properties={{ target: '_blank', style: { color: 'blue', fontWeight: 'bold' } }}
              >
                {deviceInfo.ProductName}
              </Linkify>
            </div>
            <div className="use-raw-text">
              {deviceInfo.AdditionalInfo && alarmT(deviceInfo.AdditionalInfo.InfoTranslationKey)}
            </div>
          </div>
          {deviceInfo.AdditionalInfo && deviceInfo.AdditionalInfo.SmallImageUrl ? (
            <div className="flex-col-around flex-0-0-auto">
              <div className="p-05em">
                <Lightbox
                  isOpen={isPreviewOpen}
                  backdropClosesModal={true}
                  showImageCount={false}
                  onClose={() => setIsPreviewOpen(false)}
                  images={[
                    {
                      src:
                        deviceInfo.AdditionalInfo.ImageUrl ||
                        deviceInfo.AdditionalInfo.SmallImageUrl
                    }
                  ]}
                />
                <img
                  className="image c-p"
                  src={deviceInfo.AdditionalInfo.SmallImageUrl}
                  onClick={() => setIsPreviewOpen(true)}
                  alt="Image of device"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  };

  const hasContent = deviceInfo ? true : false;
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "DeviceInfoSection");
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocKey={alarmT('DEVICE_INFO_SECTION')}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      {deviceInfo && getContent()}
    </Section>
  );
}

export default class DeviceInfoSection extends React.Component<DeviceInfoSectionProps> {
  render() {
    return (
      <WidgetErrorBoundary>
        <DeviceInfoWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
