import React from 'react';
import { Widget } from '../interfaces/backend_model';
import localization from '../localization';
import IconArrow from './icon_arrow';
import IconExpand from './icons/icon_expand';
import { SectionFullscreenModal } from './section_fullscreen_modal';
import TelephoneNumber from './telephone_number';

const constants = require('../../json/constants.json');

interface UserDataSource {
  Name: string;
  Telephone1: string;
  Telephone2: string;
  Telephone3: string;
}

interface Props {
  onChangeDisplay?: (displayState?: 'CLOSED' | 'OPENED') => void;
  onToggleStart?: (state?: 'CLOSED' | 'OPENED') => boolean | void;
  onToggleDone?: (state?: 'CLOSED' | 'OPENED') => boolean | void;
  onFullscreenToggle?: (isOpened: boolean) => void;
  toggleType?: string;
  toggleState?: string;
  fullscreenMode?: boolean;
  fullscreenRef?: any;
  toggleDisplay?: boolean | null;
  widget: Widget;
  headerLocKey?: string;
  headerStatus?: string;
  headerLocOptions?: object;
  headerContent?: JSX.Element;
  children: any;
  childrenLocOptions?: { ns: string };
  sectionClassName?: string;
  addSectionClassName?: string;
  headerClassName?: string;
  addHeaderClassName?: string;
  bodyClassName?: string;
  addBodyClassName?: string;
  onClickShow?: () => void;
  showHideButton?: boolean;
  showHideButtonLocKey?: string;
  showExtraButton?: boolean;
  showExtraButtonLocKey?: string;
  onExtraButtonClick?: () => void;
  childrenClassName?: string;
  alarmDeactivated?: boolean;
  alarmId?: string;
  mapSection?: boolean;
  checkbox?: JSX.Element;
  withoutTranslation?: boolean;
  dataSource?: UserDataSource;
}

interface State {
  toggleState?: string;
  toggleDisplay: boolean | null;
  toggleType: string;
  isFullscreenOpened: boolean;
}

export default class Section extends React.Component<Props, State> {
  static defaultProps = {
    checkbox: null
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      toggleType: this.props.toggleType ? this.props.toggleType : constants.DISABLED_TOGGLE,
      toggleState: this.props.toggleState ? this.props.toggleState : constants.OPENED,
      toggleDisplay: this.props.toggleDisplay ? this.props.toggleDisplay : null,
      isFullscreenOpened: false
    };
  }

  toggle = () => {
    let { toggleState, toggleDisplay } = this.state;
    let newToggleState: 'OPENED' | 'CLOSED' | undefined = undefined;

    if (toggleState === constants.OPENED) {
      newToggleState = constants.CLOSED;
    } else if (toggleState === constants.CLOSED) {
      newToggleState = constants.OPENED;
    }

    if (toggleDisplay && this.props.onChangeDisplay) {
      this.props.onChangeDisplay(newToggleState);
    }

    if (!!this.props.onToggleStart) {
      this.props.onToggleStart(newToggleState);
    }

    this.setState(
      { toggleState: newToggleState },
      () => !!this.props.onToggleDone && this.props.onToggleDone(newToggleState)
    );
  };

  // For close the map section in the alarm history and re-center map when opening the section
  componentDidUpdate(prevProps: Props) {
    if (this.props.toggleState !== prevProps.toggleState) {
      this.setState({ toggleState: this.props.toggleState });
    }
    if (this.props.alarmDeactivated || this.props.mapSection) {
      if (prevProps.alarmId != this.props.alarmId) {
        this.setState({
          toggleType: this.props.toggleType ? this.props.toggleType : constants.DISABLED_TOGGLE,
          toggleState: this.props.toggleState ? this.props.toggleState : constants.OPENED,
          toggleDisplay: this.props.toggleDisplay ? this.props.toggleDisplay : null
        });
        if (this.props.toggleDisplay && this.props.onChangeDisplay) {
          this.props.onChangeDisplay(
            this.props.toggleState ? this.props.toggleState : constants.OPENED
          );
        }
      }
    }
  }

  renderContent(
    _bodyClassName: string,
    _children: JSX.Element[],
    childrenLocOptions?: { ns: string },
    toggleState?: string,
    dataSource?: UserDataSource,
    backgroundColor?: string
  ) {
    if (toggleState && toggleState !== 'OPENED') {
      return null;
    }
    return (
      <div
        className={
          _bodyClassName + (toggleState ? ' d-flex' : '')
        }
        style={{ backgroundColor: backgroundColor }}
      >
        {_children
          .filter((child: any) => {
            if (child && child.skip) {
              return !(child.value === undefined || child.value === null || child.value === '');
            } else if (child && child.rendered === null) {
              return false;
            }
            return true;
          })
          .map((child: any, index: number, array) => {
            if (!child) {
              return null;
            }
            if (child.$$typeof) {
              return child;
            } else if (child.rendered) {
              return child.rendered;
            }

            const { childrenClassName } = this.props;
            let childrenLabelClassName = 'bold';
            let childrenTextClassName = '';
            let className = child.horizontal ? 'flex-row item-hor ' : 'flex-col-start item-ver ';

            if (childrenClassName) {
              childrenLabelClassName = childrenLabelClassName + ' p-l-05em';
              className = childrenClassName;
              childrenTextClassName = ' p-r-l-05em';
            }

            return (
              <div className={className} key={index}>
                {child.label ? (
                  <div className={childrenLabelClassName}>
                    {child.icon && (
                      <span className='icon-image'>{child.icon}</span>
                    )}
                    {localization.t(
                      child.label,
                      child.locOptions ? child.locOptions : childrenLocOptions
                    )}
                    :
                  </div>
                ) : null}
                <div
                  className={
                    (child.horizontal ? 'p-r-l-05em' : '') +
                    ' use-raw-text ' +
                    childrenTextClassName +
                    (child.contentWrapperClassName ? child.contentWrapperClassName : '')
                  }
                >
                  {child.telephone_format ? (
                    <TelephoneNumber value={child.value} resource={dataSource} />
                  ) : (
                    child.value
                  )}
                  {child.additionalReactElement &&
                    child.additionalReactElement
                  }
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  renderHeader = (
    headerContent: JSX.Element | undefined,
    _headerClassName: string,
    toggleType: string,
    toggleState: string | undefined,
    headerLocOptions: any,
    widget: Widget,
    headerLocKey?: string,
    headerStatus?: string,
    showHideButton?: boolean,
    showHideButtonLocKey?: string,
    showExtraButton?: boolean,
    showExtraButtonLocKey?: string,
  ) => {
    let fullScreenButton = null;
    if (this.props.fullscreenMode) {
      fullScreenButton = (
        <button
          type="button"
          className="button-small-blue small-button-arrow-icon margin-right-05"
          onClick={() => {
            this.setState({ isFullscreenOpened: true });
            if (this.props.onFullscreenToggle) {
              this.props.onFullscreenToggle(true);
            }
          }}>
          <IconExpand />
        </button>
      )
    }
    if (headerContent && headerLocKey && showExtraButton) {
      return (
        <div
          className="flex-row-wrap section-header"
          style={{ backgroundColor: (widget && widget.headerBackgroundColor) ? widget.headerBackgroundColor : '' }}
        >
          <div className="p-1em f-15 bold flex-1-1-0px flex-row-between">
            <div>
              {localization.t(headerLocKey, headerLocOptions)}
            </div>
            <div className="note">{headerStatus}</div>
          </div>
          <div className="flex-col-around flex-0-0-auto">
            {fullScreenButton}
            <button type="button" className="button-small-blue" onClick={this.props.onExtraButtonClick}>
              {showExtraButtonLocKey
                ? localization.t(showExtraButtonLocKey)
                : localization.t('SHOW_ALL')}
            </button>
          </div>
          {
            toggleType === constants.ENABLED_TOGGLE ? (
              <div className="flex-row flex-0-0-auto">
                {this.props.checkbox}
                <button type="button" className="button-small-blue small-button-arrow-icon" onClick={this.toggle}>
                  {toggleState === constants.OPENED ? (
                    <IconArrow icon="up" />
                  ) : (
                    <IconArrow icon="down" />
                  )}
                </button>
              </div>
            ) : (
              <>
                {this.props.checkbox && (
                  <div className="flex-row flex-0-0-auto section-icon">
                    {this.props.checkbox}
                  </div>
                )}
              </>
            )
          }
        </div>
      );
    }
    if (headerContent && headerLocKey && showHideButton) {
      return (
        <div
          className="flex-row-wrap section-header"
          style={{ backgroundColor: (widget && widget.headerBackgroundColor) ? widget.headerBackgroundColor : '' }}
        >
          <div className="p-1em f-15 bold flex-1-1-0px flex-row-between">
            <div>
              {localization.t(headerLocKey, headerLocOptions)}
            </div>
            <div className="note">{headerStatus}</div>
          </div>
          <div className="flex-col-around flex-0-0-auto">
            {fullScreenButton}
            <button type="button" className="button-small-blue" onClick={this.props.onClickShow}>
              {showHideButtonLocKey
                ? localization.t(showHideButtonLocKey)
                : localization.t('SHOW_ALL')}
            </button>
          </div>
          {
            toggleType === constants.ENABLED_TOGGLE ? (
              <div className="flex-row flex-0-0-auto section-icon">
                {this.props.checkbox}
                <button type="button" className="button-small-blue small-button-arrow-icon" onClick={this.toggle}>
                  {toggleState === constants.OPENED ? (
                    <IconArrow icon="up" />
                  ) : (
                    <IconArrow icon="down" />
                  )}
                </button>
              </div>
            ) : (
              <>
                {this.props.checkbox && (
                  <div className="flex-row flex-0-0-auto section-icon">
                    {this.props.checkbox}
                  </div>
                )}
              </>
            )
          }
        </div>
      );
    } else if (headerContent) {
      if (this.props.fullscreenRef?.current) {
        this.props.fullscreenRef.current.addEventListener('click', () => {
          this.setState({ isFullscreenOpened: true });
          if (this.props.onFullscreenToggle) {
            this.props.onFullscreenToggle(true);
          }
        });
      }
      return headerContent;
    } else if (headerLocKey) {
      return (
        <div
          className={widget.headerClassName || _headerClassName}
          style={{ backgroundColor: (widget && widget.headerBackgroundColor) ? widget.headerBackgroundColor : '' }}
        >
          <div className="p-1em flex-1-1-0px flex-row-between">
            <div>
              {!this.props.withoutTranslation
                ? localization.t(widget.headerLocKey || headerLocKey, headerLocOptions)
                : headerLocKey}
            </div>
            <div className="note">{headerStatus}</div>
          </div>
          {
            toggleType === constants.ENABLED_TOGGLE ? (
              <div className="flex-row flex-0-0-auto section-icon">
                {fullScreenButton}
                {this.props.checkbox}
                <button type="button" className="button-small-blue small-button-arrow-icon" onClick={this.toggle}>
                  {toggleState === constants.OPENED ? (
                    <IconArrow icon="up" />
                  ) : (
                    <IconArrow icon="down" />
                  )}
                </button>
              </div>
            ) : (
              <>
                {fullScreenButton && (
                  <div className="flex-col-around flex-0-0-auto">
                    {fullScreenButton}
                  </div>
                )}
                {this.props.checkbox && (
                  <div className="flex-row flex-0-0-auto section-icon">
                    {this.props.checkbox}
                  </div>
                )}
              </>
            )
          }
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let { toggleType, toggleState } = this.state;
    let {
      headerLocKey,
      headerStatus,
      headerLocOptions,
      headerContent,
      children,
      childrenLocOptions,
      sectionClassName,
      addSectionClassName,
      headerClassName,
      addHeaderClassName,
      bodyClassName,
      addBodyClassName,
      showHideButton,
      showHideButtonLocKey,
      showExtraButton,
      showExtraButtonLocKey,
      widget,
      dataSource
    } = this.props;

    if (!widget) {
      // Has to define widget because of default_v2.jsx
      widget = {
        headerLocKey: null,
        headerStatus: '',
        headerBackgroundColor: null,
        headerClassName: null,
        headerEnabled: true,
        backgroundColor: null,
        bodyClassName: null,
        sectionClassName: null,
        toggleState: null,
        toggleType: null,
        widgetName: null,
        settings: [],
      } as Widget;
    }

    headerLocKey = widget.headerLocKey ? widget.headerLocKey : headerLocKey;
    headerStatus = widget.headerStatus ?? headerStatus;
    const headerEnabled = widget.headerEnabled ?? true;

    let _children: JSX.Element[] = [];
    let _sectionClassName = sectionClassName ? sectionClassName : 'flex-col section item-05em';

    let _headerClassName = headerClassName
      ? headerClassName
      : 'flex-row-wrap section-header f-15 bold';
    let _bodyClassName = bodyClassName ? bodyClassName : 'flex-row-wrap bg-c-white-primary';

    if (addSectionClassName) {
      _sectionClassName = _sectionClassName + ' ' + addSectionClassName;
    }
    if (widget.sectionClassName) {
      _sectionClassName = _sectionClassName + ' ' + widget.sectionClassName;
    }
    if (addHeaderClassName) {
      _headerClassName = _headerClassName + ' ' + addHeaderClassName;
    }
    //Check if styles were sent from template JSON and apply them if yes.
    if (widget.headerClassName) {
      _bodyClassName = _bodyClassName + ' ' + widget.headerClassName;
    }
    if (addBodyClassName) {
      _bodyClassName = _bodyClassName + ' ' + addBodyClassName;
    }
    if (widget.bodyClassName) {
      _bodyClassName = _bodyClassName + ' ' + widget.bodyClassName;
    }

    toggleState = widget.toggleState ? widget.toggleState : toggleState;
    if (widget.toggleState) {
      toggleType = constants.ENABLED_TOGGLE;
    }
    if (Array.isArray(children)) {
      children.forEach(child => {
        if (Array.isArray(child)) {
          Array.prototype.push.apply(_children, child);
        } else {
          _children.push(child);
        }
      });
    } else if (children) {
      // one react element child
      _children.push(children);
    }

    let id = widget?.widgetName ?? headerLocKey;
    return (
      <>
        <SectionFullscreenModal
          isOpen={this.state.isFullscreenOpened}
          onClose={() => {
            this.setState({ isFullscreenOpened: false });
            if (this.props.onFullscreenToggle) {
              this.props.onFullscreenToggle(false);
            }
          }}
          title={localization.t(widget.headerLocKey || headerLocKey, headerLocOptions)}
          body={this.state.toggleDisplay
            ? this.renderContent(
              _bodyClassName,
              _children,
              childrenLocOptions,
              toggleState,
              dataSource,
              (widget && widget.backgroundColor) ? widget.backgroundColor : ''
            )
            : toggleState === constants.CLOSED
              ? null
              : this.renderContent(
                _bodyClassName,
                _children,
                childrenLocOptions,
                undefined,
                dataSource,
                (widget && widget.backgroundColor) ? widget.backgroundColor : ''
              )}
        />
        <div className={_sectionClassName} id={id}>
          {(headerEnabled)
            ? this.renderHeader(
              headerContent,
              _headerClassName,
              toggleType,
              toggleState,
              headerLocOptions,
              widget,
              headerLocKey,
              headerStatus,
              showHideButton,
              showHideButtonLocKey,
              showExtraButton,
              showExtraButtonLocKey,
            ) : null}

          {this.state.toggleDisplay
            ? this.renderContent(
              _bodyClassName,
              _children,
              childrenLocOptions,
              toggleState,
              dataSource,
              (widget && widget.backgroundColor) ? widget.backgroundColor : ''
            )
            : toggleState === constants.CLOSED
              ? null
              : this.renderContent(
                _bodyClassName,
                _children,
                childrenLocOptions,
                undefined,
                dataSource,
                (widget && widget.backgroundColor) ? widget.backgroundColor : ''
              )}
        </div>
      </>
    );
  }
}
