export default class Storage {
  __class_name: string;
  separator: string;
  global: {};
  constructor() {
    this.__class_name = 'Storage';
    this.separator = '_';
    this.global = window;
  }

  /**
   * get full key for storage
   */
  get_full_key(key: string | [string, string | string[]] | [string, ...(string | number)[]]) {
    if (Array.isArray(key)) {
      return key.join(this.separator);
    }
    return Array.prototype.slice.call(arguments).join('_');
  }

  /**
   * get parameter from global storage
   */
  gs_get(
    type: string,
    key: string | [string, string | string[]] | [string, ...(string | number)[]]
  ) {
    return this.global[type + 'Storage'].getItem([this.get_full_key(key)]);
  }

  /**
   * get and remove parameter from global storage
   */
  gs_get_once(type: string, key: [string, string]) {
    const value = this.gs_get(type, key);
    this.gs_remove(type, key);
    return value;
  }

  /**
   * set parameter in global storage
   */
  gs_set(
    type: string,
    key: string | [string, string] | [string, ...string[]],
    value: number | string
  ) {
    return this.global[type + 'Storage'].setItem(this.get_full_key(key), value || '');
  }

  /**
   * remove parameter in global storage
   */
  gs_remove(type: string, key: [string, string] | string) {
    return this.global[type + 'Storage'].removeItem(this.get_full_key(key));
  }

  /**
   * get parameter from local storage
   */
  ls_get(key: [string, string | string[]] | string | [string, ...(string | number)[]]) {
    return this.gs_get('local', key);
  }

  /**
   * get and remove parameter from local storage
   */
  ls_get_once(key: [string, string]) {
    return this.gs_get_once('local', key);
  }

  /**
   * set parameter in local storage
   */
  ls_set(key: string | [string, string] | [string, ...string[]], value: string | number) {
    return this.gs_set('local', key, value);
  }

  /**
   * remove parameter in local storage
   */
  ls_remove(key: [string, string] | string) {
    return this.gs_remove('local', key);
  }

  /**
   * get parameter from session storage
   */
  ss_get(key: string) {
    return this.gs_get('session', key);
  }

  /**
   * get and remove parameter from session storage
   */
  ss_get_once(key: [string, string]) {
    return this.gs_get_once('session', key);
  }

  /**
   * set parameter in session storage
   */
  ss_set(key: string | [string, string], value: string | number) {
    return this.gs_set('session', key, value);
  }

  /**
   * remove parameter in session storage
   */
  ss_remove(key: [string, string]) {
    return this.gs_remove('session', key);
  }
}
