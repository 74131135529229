import { AlarmDetailsModel, Widget } from 'development/js/interfaces/backend_model';
import React from 'react';
import Section from '../../components/section';
import { filterWidgetSettings } from '../../utilities/filterWidgetSettingsHelper';
import { CommandButton } from './CommandButton';
import FreeDropDownCommand from './FreeDropDownCommand';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

interface CommandsSectionProps {
  template: string;
  widget: Widget;
  alarmDetails: AlarmDetailsModel;
  readonly: boolean;
  instance: any;
  settings: string[];
}

const handledSettings = ['dividedCommandsView'];
function CommandsWidget(props: CommandsSectionProps) {
  const {
    readonly,
    alarmDetails,
    template,
    widget,
    settings
  } = props;
  const widgetSettings =
    filterWidgetSettings(settings, handledSettings, widget?.widgetName);
  const { DynamicDetails } = alarmDetails;
  const canMakeCommands = !readonly;
  let addSectionClassName = '';
  let addBodyClassName = '';
  let bodyClassName = ' ';
  let sectionClassName = 'p-t-10 p-l-05em';
  let commandButtonClassName = 'flex-row-center flex-1-1-0px';
  let wrapButtonsClassName = 'flex-row-wrap justify-left flex-center';
  if (!DynamicDetails.Commands || DynamicDetails.Commands.length === 0) {
    return null;
  }

  switch (template) {
    case 'goteborg':
    case 'goteborg_v2':
      addSectionClassName = 'flex-0-0-auto';
      addBodyClassName = 'p-05em';
      bodyClassName = 'flex-0-0-auto bg-c-white-primary';
      sectionClassName = '';
      break;
    case 'kristiansund':
      wrapButtonsClassName = wrapButtonsClassName + ' flex-row-end';
      commandButtonClassName = 'flex-row-center';
      break;
    case 'doronorway':
      wrapButtonsClassName = wrapButtonsClassName + ' flex-row-end';
      commandButtonClassName = 'flex-row-center';
      break;
  }

  if (widgetSettings.dividedCommandsView) {
    addSectionClassName = 'flex-0-0-auto';
    addBodyClassName = 'p-05em';
    bodyClassName = 'flex-0-0-auto bg-c-white-primary';
    sectionClassName = '';
  }

  const commandsToShow = DynamicDetails.Commands.filter(
    command => command.TriggerType === 'BUTTON' || command.TriggerType === 'INLINEBUTTON'
  );

  const groupArrayOfObjects = (list: any, key: any) => {
    list = list.filter((l: any) => l[key] != null && l[key].trim() !== '');
    return list.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const getGroupedCommands = () => {
    const commandGroups = groupArrayOfObjects(DynamicDetails.Commands, 'GroupId');
    DynamicDetails.CommandGroups = commandGroups;
    const commandGroupControls = [];
    for (var prop in DynamicDetails.CommandGroups) {
      if (
        prop !== 'null' &&
        Object.prototype.hasOwnProperty.call(DynamicDetails.CommandGroups, prop)
      ) {
        const items = DynamicDetails.CommandGroups[prop].map(
          (command: any) => command.TriggerIdentifier
        );
        const commands = DynamicDetails.CommandGroups[prop];
        commandGroupControls.push(
          <div
            key={commands[0].CommandId + commands[0].TriggerArgs + commands[0].TriggerIdentifier}
            className="flex-row-center command-spacer"
          >
            <FreeDropDownCommand
              items={items}
              commands={commands}
              resetStateOn={(alarmDetails as any).AlarmId}
              alarm_id={(alarmDetails as any).AlarmId}
              name={prop}
            />
          </div>
        );
      }
    }
    return commandGroupControls;
  };

  const getCommands = () => {
    if (!alarmDetails) {
      return;
    }

    let singleCommands = commandsToShow.map(
      (command, i) =>
        !command.GroupId && (
          <CommandButton
            key={command.CommandId! + command.TriggerArgs! + command.TriggerIdentifier}
            className={commandButtonClassName}
            alarmId={props.instance.state.alarm_id}
            command={command}
            dropdown={null}
            alarmDetails={alarmDetails}
            changeStateOnAction
          />
        )
    );

    let groupedCommands = getGroupedCommands();
    let commands: Set<any> = new Set();

    // the list DynamicDetails.Commands is in the order that the command buttons should
    // be shown, thus fetch the commands from groupedCommands and singleCommands in that order
    DynamicDetails.Commands.forEach(command => {
      const id = command.CommandId! + command.TriggerArgs! + command.TriggerIdentifier;
      if (command.GroupId != null && command.GroupId !== '') {
        commands.add(groupedCommands.find(groupedCommand => groupedCommand.key == id));
      } else if (command.TriggerIdentifier) {
        commands.add(singleCommands.find((singleCommand: any) => singleCommand.key == id));
      }
    });

    return <div className={wrapButtonsClassName}>{commands}</div>;
  };

  return (
    <Section
      widget={widget}
      sectionClassName={sectionClassName}
      bodyClassName={bodyClassName}
      addSectionClassName={addSectionClassName}
      addBodyClassName={addBodyClassName}
    >
      {canMakeCommands && getCommands()}
    </Section>
  );
}

export default class CommandsSection extends React.Component<CommandsSectionProps> {
  render() {
    return (
      <WidgetErrorBoundary>
        <CommandsWidget {...this.props} />
      </WidgetErrorBoundary>
    );
  }
};
