import appDispatcher from '../../app_dispatcher';
import { ActionModel } from '../../interfaces/backend_model';
import PlanningViewStore from './planning_view_store';

const constants = require('../../../json/constants.json');

const planningViewStore = new PlanningViewStore();

appDispatcher.register((payload: { action: ActionModel; source?: string }) => {
  let action = payload.action;

  switch (action.type) {
    case constants.LOGOUT_CLEAR:
      planningViewStore.getInitialData();
      break;
    case constants.PLANNED_ALARMS_GET_ALL:
      planningViewStore.loadPlannedAlarms();
      break;
    case constants.PLANNED_ALARMS_GET_ONE:
      if (action.alarm_id) {
        planningViewStore.loadPlannedAlarm(action.alarm_id);
      }
      break;
  }
});

export default planningViewStore;
