import axios, { AxiosError } from 'axios';
import React from 'react';
import authentication from '../authentication';
import { Event } from '../event';
import event_bus from '../event_bus';
import { AdminModel, MeModel, SettingModel } from '../interfaces/backend_model';
import localization from '../localization';
import AxiosHelper from '../utilities/axiosApiHelper';
import Utils from '../utils';
import IconAlarm from './icon_alarm';
import IconExternal from './icon_external';
import IconLoopBack from './icon_loop_back';

/**
 * Example: { a: 1 } => &a=1
 * @param obj
 * @returns {string}
 */
function serializeObjToQuery(obj: {
  access_token: string;
  expires_in: number;
  token_type: string;
  username: string;
  refresh_token: string;
}) {
  const str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(p + '=' + obj[p]);
    }
  }
  return '&' + str.join('&');
}

class Tokenizer extends Event {
  admin: AdminModel | null;
  pendingAdminToken: boolean;
  adminTokenError: string | null;
  adminTokenTicks: number | null;
  instances: number;
  retryAttempts: number;
  constructor() {
    super();
    this.admin = null;
    this.pendingAdminToken = false;
    this.adminTokenError = null;
    this.adminTokenTicks = null;
    this.instances = 0;
    this.retryAttempts = 0;
  }

  /**
   * check if we need to request admin token
   */
  checkAdminToken() {
    if (
      !this.pendingAdminToken &&
      this.retryAttempts < 3 &&
      (!this.adminTokenTicks || Date.now() - this.adminTokenTicks > 1000 * 60 * 60)
    ) {
      this.adminTokenWorkflow();
    }
  }

  /**
   * retrieve admin token to auto log-in to admin portal
   */
  async adminTokenWorkflow() {
    this.trigger(constants.ADMIN_TOKEN_PENDING_START);
    this.pendingAdminToken = true;
    let data;
    try {

      const adminAccessToken = authentication.getUserLSData(constants.ADMIN_ACCESS_TOKEN_KEY);

      data = await axios
        .post(
          `${endpoints.ADMIN_TOKEN['url']}`,
          { AdminAccessToken: adminAccessToken },
          {
            headers: {
              Authorization: AxiosHelper.getAuthenticationHeader()
            }
          },
        )
        .then(({ data }: { data: AdminModel }) => {
          if (data.expires_in) {
            setTimeout(() => {
              this.adminTokenWorkflow();
            }, (data.expires_in * 1000) / 2);
          }

          return data;
        })
        .catch((err: AxiosError) => Promise.reject(AxiosHelper.errorHandler(err)));

      this.adminTokenTicks = Date.now();
      this.admin = data;
      authentication.setUserLSData(constants.ADMIN_ACCESS_TOKEN_KEY, data.access_token);
      this.adminTokenError = null;
      this.retryAttempts = 0;
    } catch (err) {
      this.admin = null;
      this.adminTokenError = typeof err === 'string' ? err : `${err}`;
      this.retryAttempts += 1;
    } finally {
      this.pendingAdminToken = false;
      this.trigger(constants.ADMIN_TOKEN_PENDING_STOP);
    }
    return data;
  }

  calcFullAdminURL(
    settings: SettingModel[],
    me: MeModel,
    adminPath: string,
    adminToken?: AdminModel
  ) {
    if (settings) {
      const admin = adminToken || this.admin;
      const adminURLObj = Utils.find_obj(settings, 'key', 'ADMINURL');
      const adminURL = adminURLObj ? adminURLObj.defaultvalue : null;

      const useOldAdmin: boolean = Utils.find_obj(settings, 'key', 'UseOldAdmin')?.defaultvalue === "true" ?? false;

      if (adminURL && admin && me) {
        if (admin.access_token) {
          let firstPart: string = "";
          if (useOldAdmin) {
            firstPart = `${adminURL}/admin/#mw?url=`;
          } else {
            firstPart = `${adminURL}/adminportal/mw?url=`;
          }

          const secondPart = serializeObjToQuery({
            username: me.Username,
            expires_in: admin.expires_in,
            token_type: admin.token_type,
            access_token: admin.access_token,
            refresh_token: admin.refresh_token ?? ""
          });

          return firstPart + encodeURIComponent(adminPath) + secondPart;
        }
      }
    }
    return null;
  }
}

const constants = require('../../json/constants.json');
const endpoints = require('../../json/endpoints.json');
const tokenizer = new Tokenizer();

function renderURL(
  href: string | null,
  pending: boolean,
  error: string | null,
  loc_id: string,
  wrapperClassName = 'flex-0-0-auto flex-row-around external-link p-025em',
  textClassName = 'flex-col-around p-05em f-15',
  onClick?: (e: React.MouseEvent) => void
) {
  if (!href) {
    return null;
  }

  return (
    <a target="_blank" href={href} className={wrapperClassName} onClick={onClick}>
      <div className={textClassName}>{localization.t(loc_id)}</div>
      <div className="flex-col-around f-13">
        {pending ? <IconLoopBack /> : error ? <IconAlarm icon={900} /> : <IconExternal />}
      </div>
    </a>
  );
}

interface Props {
  settings: SettingModel[];
  me: MeModel;
  path: string;
  loc_id: string;
  onClick?: () => void;
  wrapperClassName?: string;
  textClassName?: string;
}

interface State {
  pendingAdminToken: boolean;
  adminTokenError: string | null;
}

/**
 * each instance of this react component listen to "tokenizer" and changes its state accordingly
 */
class AdminURL extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pendingAdminToken: tokenizer.pendingAdminToken,
      adminTokenError: tokenizer.adminTokenError
    };
  }

  handleTokenizer = () => {
    this.setState({
      pendingAdminToken: tokenizer.pendingAdminToken,
      adminTokenError: tokenizer.adminTokenError
    });
  };

  componentDidMount() {
    tokenizer.on(constants.ADMIN_TOKEN_PENDING_START, this.handleTokenizer, this);
    tokenizer.on(constants.ADMIN_TOKEN_PENDING_STOP, this.handleTokenizer, this);
    tokenizer.checkAdminToken();
    tokenizer.instances++;
  }

  componentWillUnmount() {
    tokenizer.off(constants.ADMIN_TOKEN_PENDING_START, this.handleTokenizer);
    tokenizer.off(constants.ADMIN_TOKEN_PENDING_STOP, this.handleTokenizer);
    tokenizer.instances--;
    if (tokenizer.instances <= 0) {
      tokenizer.adminTokenTicks = null;
    }
  }

  componentWillReceiveProps() {
    tokenizer.checkAdminToken();
  }

  // Refresh admin-token before navigating to admin
  onClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    const { settings, me, path, onClick } = this.props;
    const token = await tokenizer.adminTokenWorkflow();

    if (token) {
      const url = tokenizer.calcFullAdminURL(settings, me, path, token);
      if (url) {
        if (onClick) {
          onClick();
        }
        window.open(url, '_blank');
        return;
      }
    }

    return event_bus.trigger(constants.ADMIN_TOKEN_NOT_RECEIVE);
  };

  render() {
    const { pendingAdminToken, adminTokenError } = this.state;
    const { settings, me, path, loc_id, wrapperClassName, textClassName } = this.props;
    const historyURL = tokenizer.calcFullAdminURL(settings, me, path);

    return renderURL(
      historyURL,
      pendingAdminToken,
      adminTokenError,
      loc_id,
      wrapperClassName,
      textClassName,
      this.onClick
    );
  }
}

export { AdminURL, Tokenizer, tokenizer };

