import * as Logger from 'js-logger';
import React from 'react';
import { sendDTMF } from '../../actions/twilio';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { AlarmDetailsModel, InCallCommandsModel, Widget } from '../../interfaces/backend_model';
import localization from '../../localization';
import CallsStore from '../../stores/calls/calls_store';
import TwilioStore from '../../stores/twillio_store';
import GroupedButtons from './../../../js/components/groupedButtons/groupedButtons';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

const constants = require('../../../json/constants.json');
let inCallCommands: InCallCommandsModel[];

interface InCallCommandsSectionProps {
  toggleState?: string;
  toggleType?: string;
  alarmDetails: AlarmDetailsModel;
  widget: Widget;
  fullscreenMode?: boolean;
}

interface CommandArray {
  [index: number]: InCallCommandsModel[];
}

const alarmT = localization.useNSt(constants.NS_ALARM);

function InCallCommandsWidget(props: InCallCommandsSectionProps) {
  const { toggleState, alarmDetails, widget, fullscreenMode } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [cmdStatus, setcmdStatus] = React.useState('');
  const [inCallCommandsExistInCall, setInCallCommandsExistInCall] = React.useState(false);
  const callStoreState = (payload: { type: string }) => {
    switch (payload.type) {
      case constants.CALL_CMD_NOTIFY:
        setcmdStatus(CallsStore.getCustomTransmitterCommandStatus());
        break;
      case constants.CALLS_NOTIFY:
        inCallCommands = CallsStore.getFilteredInCallCommands();

        if (!inCallCommands || (Array.isArray(inCallCommands) && !inCallCommands.length)) {
          setcmdStatus(''); // Remove info in header if we loose the call
          setInCallCommandsExistInCall(false);
        } else {
          setInCallCommandsExistInCall(true);
        }
        break;
      default:
    }
  }

  React.useEffect(
    () => {
      CallsStore.addEventListener(callStoreState);
      return () => {
        CallsStore.removeEventListener(callStoreState);
      };
    },
    [callStoreState]
  );

  const onCommandClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cmd: InCallCommandsModel
  ) => {
    event.preventDefault();

    if (cmd.DTMF) {
      // only when call is running
      if (TwilioStore.getState().isbusy) {
        sendDTMF(cmd.CommandId);
      }
    } else {
      const commandId = cmd.CommandId;
      const commandMessage = cmd.Command;
      if (!commandMessage.length || typeof commandId !== 'number') {
        setErrorMessage(alarmT('PRODUCT_COMMAND_ERROR'));
        Logger.error('onCommandClick missing input: ', cmd);
        return;
      }
      setErrorMessage('');
      setIsLoading(true);
      await CallsStore.sendCustomTransmitterCommand(alarmDetails.AlarmId, commandId, commandMessage)
        .then((response) => {
          Logger.log('onCommandClick:', response);
        }).catch((e: string) => {
          setErrorMessage(alarmT('NO_TRANSMITTER_ID_ERROR'));
          Logger.error('onCommandClick failed: ', cmd, ' Error:', e);
        }).finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getContent = () => {
    const additionalCmdArray: CommandArray = [];
    const ordinaryCmdArray: CommandArray = [];

    // Sort each command into groups based on group order and if it should be grouped under "More commands"
    inCallCommands?.forEach((cmd) => {
      // Use order as the index -> Groups will be display based on order
      const index = cmd.CommandGroup.Order;
      if (cmd.Type === 'custom_additional') {
        // Create an array if not exist with current index
        if (!additionalCmdArray[index]) {
          additionalCmdArray[index] = [];
        }
        additionalCmdArray[index].push(cmd);
      } else {
        if (!ordinaryCmdArray[index]) {
          ordinaryCmdArray[index] = [];
        }
        ordinaryCmdArray[index].push(cmd);
      }
    })

    // Create groups that should be displayed in the widget
    const groupsOrdinary: JSX.Element[] = [];
    for (let group in ordinaryCmdArray) {
      const cmds = ordinaryCmdArray[group];
      groupsOrdinary.push(getGroupElement(group + '_ordinary', cmds));
    }

    // Create groups that should be grouped under "More commands" button in the widget
    const groupsAdditional: JSX.Element[] = [];
    for (let group in additionalCmdArray) {
      const additionalCmds = additionalCmdArray[group];
      groupsAdditional.push(getGroupElement(group + '_additional', additionalCmds, true));
    }

    return (
      <span>
        <div className="flex-row-wrap">
          {groupsOrdinary}
          {groupsAdditional?.length ? (
            <div className="flex-row p-05em w-100p grouped-commands">
              <GroupedButtons
                groupedButtons={groupsAdditional}
              />
            </div>
          ) : null}
        </div>
      </span>
    );
  };

  function getGroupElement(key: string, cmds: InCallCommandsModel[], popup?: boolean): JSX.Element {
    // All cmds should belong to the same group so take the name of the first one
    const groupName = cmds[0]?.CommandGroup?.TranslationKey;

    // Create the buttons
    const buttons: JSX.Element[] = [];
    cmds.forEach(cmd => {
      buttons.push(getButtonElement(key, cmd));
    });

    // Return the group of buttons
    return (
      <div className={`flex-row-wrap w-100p ${popup ? 'popup-content' : 'grouped-commands'}`} key={key}>
        {groupName ? (<div className='flex-center-w p-05em bold'> {alarmT(groupName)}:</div>) : null}
        {buttons}
      </div >
    );
  }

  function getButtonElement(key: string, cmd: InCallCommandsModel): JSX.Element {
    // Return button that can show error if the command fails
    return (
      <div className="flex-row p-05em" key={key + '_' + cmd.TranslationKey + '_' + cmd.CommandId}>
        {errorMessage ? (
          <button
            className="form-btn-bg-red"
            type="button"
            onClick={e => onCommandClick(e, cmd)}
          >
            {errorMessage}
          </button>
        ) : (
          <button
            className="button-small-blue"
            onClick={e => onCommandClick(e, cmd)}
            disabled={isLoading}
          >
            {alarmT(cmd.TranslationKey)}
          </button>
        )}
      </div>
    );
  }

  const hasContent = inCallCommandsExistInCall;
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "InCallCommandsSection");
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={props.toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocKey={alarmT('IN_CALL_COMMANDS_SECTION')}
      headerStatus={alarmT(cmdStatus)}
      bodyClassName="flex-row-wrap bg-c-white-primary p-t0-l0-05"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      {inCallCommandsExistInCall ? getContent() : (
        <div className="d-flex w-100p">
          <div className="p-r-l-1em item-ver flex-row-between">
            <div className="note">
              {alarmT('IN_CALL_COMMANDS_NOT_AVAILABLE')}
            </div>
          </div>
        </div>
      )}

    </Section >
  );
}

export default function InCallCommandsSection (props: InCallCommandsSectionProps) {
  return (
    <WidgetErrorBoundary>
      <InCallCommandsWidget {...props} />
    </WidgetErrorBoundary>
  );
};
