import AppDispatcher from '../app_dispatcher';
const constants = require('../../json/constants.json');

export function callResource(
  alarmId: string,
  message: string,
  resource: string,
  notifyOnUpdate: boolean,
  deactivateAlarmWhenAllDone: boolean
) {
  AppDispatcher.dispatch({
    action: {
      type: constants.CALL_RESOURCE,
      alarmId,
      message,
      resource,
      notifyOnUpdate,
      deactivateAlarmWhenAllDone
    }
  });
}

export function deleteResource(alarmId: string, resource: string) {
  AppDispatcher.dispatch({
    action: {
      type: constants.CALL_RESOURCE_DELETE,
      alarmId,
      resource
    }
  });
}
