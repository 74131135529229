import Badge from 'material-ui/Badge';
import { Tab, Tabs } from 'material-ui/Tabs';
import { inject } from 'mobx-react';
import * as PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import authentication from '../authentication';
import TwilioNotifications from '../components/twilio_notifications';
import event_bus from '../event_bus';
import { MeModel, SettingModel } from '../interfaces/backend_model';
import localization from '../localization';
import alarmActiveStore from '../stores/alarms/alarm_active_store_actions';
import alarmListStore from '../stores/alarms/alarm_list_store_actions';
import dictionariesStore from '../stores/dictionaries_store';
import groupsStore from '../stores/groups_store';
import { PermissionsStore } from '../stores/mobx/PermissionsStore';
import settingsStore from '../stores/settings_store';
import templatesStore from '../stores/templates_store';
import TwilioStore from '../stores/twillio_store';
import usersStore from '../stores/users/users_store_actions';
import { logoutFlow } from '../utilities/logoutFlow';
import { logoutWhenReferrer } from '../utilities/redirects';
import websocket from '../websocket';
import AlarmReminder from './alarm_reminder';
import AlarmsList from './alarms_list';
import CallNotes from './call_notes';
import MenuPanel from './menu_panel';
import { PopupConfirm } from './popups/popup_confirm';
import PopupError from './popups/popup_error';
import SpinnerOverlay from './spinner_overlay';
import SyncStatus from './sync_status';
import Telephony from './telephony';
import { UserHistory } from './userAlarmHistory/user_history';

const styles = {
  headline: {
    fontSize: '1.5rem',
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  slide: {
    padding: 0
  }
};

const constants = require('../../json/constants.json');

interface Props {
  permissions: PermissionsStore;
  params: { alarm_id: string; template_type: string };
  location: {
    action: string;
    hash: string;
    key: any | null;
    pathname: string;
    query: any | {};
    search: string;
    state: any | null;
  };
}

interface State {
  readonly: boolean;
  slideIndex: number;
  confirmTakeCareMessage: string | boolean | any;
  confirmLogoutMessage: string | boolean | any;
  settings: SettingModel[];
  dictionary: { [key: string]: string };
  me: MeModel;
  errorCallback: any;
  errorMessage: any;
  overlay: boolean;
  alarmsCounter: number;
  unauthorizedErrorMessage: any;
  showApprovedModal: boolean;
  showNotesModal: boolean;
}

class Dashboard extends React.Component<Props, State> {
  static childContextTypes = {
    readonly: PropTypes.bool
  };
  RChildren: any;
  getChildContext() {
    return {
      readonly: this.state.readonly
    };
  }
  constructor(props: Props) {
    super(props);

    // Needs telephony approval by the user
    const show = authentication.ls_get([authentication.getUserId(), constants.TELEPHONY_KEY]) !== constants.TELEPHONY_NONE;

    this.state = {
      dictionary: dictionariesStore.getLocalUserLogsDictionary(),
      settings: settingsStore.getSettings(),
      me: settingsStore.getMe(),
      errorMessage: false,
      confirmLogoutMessage: false,
      overlay: true,
      readonly: authentication.isReadOnly(),
      errorCallback: null,
      slideIndex: 0,
      alarmsCounter: 0,
      confirmTakeCareMessage: false,
      unauthorizedErrorMessage: null,
      showApprovedModal: show,
      showNotesModal: false
    };

  }

  handleTabChange = (value: number) => {
    this.setState({
      slideIndex: value
    });
  };

  handleLanguageChange(language: string) {
    localization.changeLanguage(language);
    this.forceUpdate();
    if (this.RChildren) {
      this.RChildren.forceUpdate();
    }
  }

  onLogoutClick = (e: React.MouseEvent) => {
    const alarmsByTabs = alarmListStore.getAlarmsByTabs();

    // check for my tab unhandled alarms (it can not exist if we have SINGLELIST=true setting)
    if (alarmsByTabs[constants.TAB_MY] && alarmsByTabs[constants.TAB_MY].length) {
      this.setState({
        confirmTakeCareMessage: localization.t('LOGOUT_TAKE_CARE_CONFIRM', {
          count: alarmsByTabs[constants.TAB_MY].length
        })
      });
    } else {
      this.setState({
        confirmLogoutMessage: localization.t('LOGOUT_CONFIRM')
      });
    }
  };

  handlePopupErrorClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;

    // Reset flag to allow fetching from cwm in UserHistory
    usersStore.allowRequestPending();

    if (element.dataset.click === 'close') {
      let { settings, dictionary, me, errorCallback } = this.state;

      if (errorCallback) {
        errorCallback();
        this.setState({
          errorMessage: false,
          errorCallback: null,
          overlay: false
        });
        return;
      }

      if (!settings.length || !dictionary) {
        this.setState({
          errorMessage: false,
          errorCallback: null,
          overlay: true
        });
        if (!settings.length) {
          appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_SETTINGS });
        } else if (!me) {
          appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_ME });
        } else if (!dictionary) {
          appDispatcher.handleServerAction({
            type: constants.LOAD_SERVER_LOG_EVENT_DICTIONARY
          });
        }
      } else {
        this.setState({
          errorMessage: false,
          errorCallback: null
        });
      }
    }
  };

  handleLogoutConfirmClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset) {
      this.setState({
        confirmLogoutMessage: false
      });
      if (element.dataset.click === 'continue') {
        this.logoutWorkflow();
      }
    }
  };

  handleTakeCareConfirmClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset) {
      this.setState({
        confirmTakeCareMessage: false
      });
      if (element.dataset.click === 'continue') {
        this.logoutWorkflow();
      }
    }
  };

  approvedAudio = (e: any) => {
    let element = e.target;
    if (element.dataset.click === 'continue') {
      TwilioStore.userApprovedInteraction();
      settingsStore.userApproved = true;
      this.setState({ showApprovedModal: false });
    }
  };

  logoutWorkflow = () => {
    this.setState({
      overlay: true
    });
    logoutFlow(this.showLogoutError);
  };

  showLogoutError = (err: String) => {
    this.setState({
      errorMessage: err,
      overlay: false
    });
  };

  componentDidMount() {
    TwilioStore.addEventListener(this.onTwilioError);
    alarmListStore.on(constants.ALARMS_SERVER_ERROR, this._onServerError);
    alarmActiveStore.on(constants.ALARM_DETAILS_SERVER_ERROR, this._onServerError);
    alarmActiveStore.on(constants.ALARM_ATTACHMENTS_SERVER_ERROR, this._onServerError);
    alarmActiveStore.on(constants.ALARM_COMMAND_ERROR, this._onServerError);
    alarmListStore.on(constants.ALARMS_CHANGE, this.onNewAlarms);
    templatesStore.on(constants.TEMPLATE_SERVER_ERROR, this._onServerError);
    templatesStore.on(constants.TEMPLATE_PARSE_ERROR, this._onServerError);
    groupsStore.on(constants.RESPONDENT_GROUPS_SERVER_ERROR, this._onServerError);
    usersStore.on(constants.USERS_SERVER_ERROR, this._onServerError);
    settingsStore.on(constants.SETTINGS_SERVER_ERROR, this._onServerError);
    settingsStore.on(constants.ME_SERVER_ERROR, this._onServerError);
    settingsStore.on(constants.DICTIONARY_SERVER_ERROR, this._onServerError);

    settingsStore.on(constants.SETTINGS_CHANGE, this.handleSettingsChange);
    settingsStore.on(constants.ME_CHANGE, this._onChange);
    dictionariesStore.on(constants.DICTIONARY_CHANGE, this._onChange);

    appDispatcher.handleServerAction({
      type: constants.LOAD_SERVER_LOG_EVENT_DICTIONARY
    });

    this.props.permissions.fetchPermissions();

    event_bus.on(constants.UNAUTHORIZED, this.handleUnauthorized, this);
    event_bus.on(constants.WS_PUSH_LOGGED_IN_ON_OTHER, this.handleLoggedInOnOther, this);
    event_bus.on(constants.WS_PUSH_ACCOUNT_REVOKED, this.handleAccountRevoked, this);
    event_bus.on(constants.ADMIN_TOKEN_NOT_RECEIVE, this.handleAuthorizationAdmin, this);
    event_bus.on(constants.UNTRUSTED_SOURCE, this.handleUntrusted, this);
    event_bus.on(constants.SERVICE_UNAVAILABLE, this.handleServerError, this);
    event_bus.on(constants.INTERNET_CONNECTION_ABORT, this.handleInternetConnectionAbort, this);

    this.handleSettingsChange();

    if (TwilioStore.isCloudIntegration()) {
      const twilioState = TwilioStore.getState();

      window.onbeforeunload = (e: any) => {
        if (twilioState.isbusy && !twilioState.isHoldPending) {
          if (websocket.webSocket) {
            const message = JSON.stringify({ frontendevent: 'forcedclosed', twiliocall: 'active' });
            websocket.send(message);
          }
        }
      };
    }
  }

  componentWillUnmount() {
    TwilioStore.removeEventListener(this.onTwilioError);
    alarmListStore.off(constants.ALARMS_SERVER_ERROR, this._onServerError);
    alarmActiveStore.off(constants.ALARM_DETAILS_SERVER_ERROR, this._onServerError);
    alarmActiveStore.off(constants.ALARM_ATTACHMENTS_SERVER_ERROR, this._onServerError);
    alarmActiveStore.off(constants.ALARM_COMMAND_ERROR, this._onServerError);
    alarmListStore.off(constants.ALARMS_CHANGE, this.onNewAlarms);
    templatesStore.off(constants.TEMPLATE_SERVER_ERROR, this._onServerError);
    templatesStore.off(constants.TEMPLATE_PARSE_ERROR, this._onServerError);
    groupsStore.off(constants.RESPONDENT_GROUPS_SERVER_ERROR, this._onServerError);
    usersStore.off(constants.USERS_SERVER_ERROR, this._onServerError);
    settingsStore.off(constants.SETTINGS_SERVER_ERROR, this._onServerError);
    settingsStore.off(constants.ME_SERVER_ERROR, this._onServerError);
    settingsStore.off(constants.DICTIONARY_SERVER_ERROR, this._onServerError);

    settingsStore.off(constants.SETTINGS_CHANGE, this.handleSettingsChange);
    settingsStore.off(constants.ME_CHANGE, this._onChange);
    dictionariesStore.off(constants.DICTIONARY_CHANGE, this._onChange);
    appDispatcher.handleServerAction({ type: constants.STOP_PENDING_REPORT });
    websocket.destroy();

    event_bus.off(constants.UNAUTHORIZED, this.handleUnauthorized);
    event_bus.off(constants.WS_PUSH_LOGGED_IN_ON_OTHER, this.handleLoggedInOnOther);
    event_bus.off(constants.WS_PUSH_ACCOUNT_REVOKED, this.handleAccountRevoked);
    event_bus.off(constants.ADMIN_TOKEN_NOT_RECEIVE, this.handleAuthorizationAdmin);
    event_bus.off(constants.UNTRUSTED_SOURCE, this.handleUntrusted);
    event_bus.off(constants.SERVICE_UNAVAILABLE, this.handleServerError);
    event_bus.off(constants.INTERNET_CONNECTION_ABORT, this.handleInternetConnectionAbort);

    appDispatcher.handleServerAction({ type: constants.LOGOUT_CLEAR });
  }

  onNewAlarms = () => {
    this.setState({
      alarmsCounter: alarmListStore.getAlarmsByTabs()[constants.TAB_ACTIVE].length
    });
  };

  getTabLabel = () => {
    const text = localization.t('TABS_LIST');

    if (this.state.alarmsCounter) {
      return (
        <Badge
          secondary={true}
          badgeContent={`${this.state.alarmsCounter}`}
          style={{ padding: '12px 24px 12px 12px' }}
        >
          {text}
        </Badge>
      );
    }

    return text;
  };

  render() {
    let {
      errorMessage,
      settings,
      me,
      dictionary,
      overlay,
      unauthorizedErrorMessage,
      confirmLogoutMessage,
      confirmTakeCareMessage
    } = this.state;

    const { isTemplateOnly } = templatesStore;

    this.RChildren = null;
    let has_data = settings.length && me && dictionary,
      childrenWithProps = React.Children.map(this.props.children, child => {
        return React.cloneElement(child as JSX.Element, {
          ref: (obj: any) => {
            this.RChildren = obj;
          },
          errorMessage,
          unauthorizedErrorMessage
        });
      }),
      fullInfo = '';
    if (me) {
      fullInfo += me.Username;
      if (me.Initials) {
        fullInfo += ' (' + me.Initials + ')';
      }
    }

    const slideIdx = isTemplateOnly ? 1 : this.state.slideIndex;

    let content = (
      <div>
        <MediaQuery minDeviceWidth={1224}>
          <div className="flex-row h-resp-100">
            {!isTemplateOnly && (
              <div className="flex-col flex-0-0-auto w-25em bg-c-gray">
                <div className="flex-row dashboard-panel" data-cy="dashboardPanel">
                  <SyncStatus />
                  <div className="flex-1-1-auto flex-col-around p-l-1em f-15 c-white text-wrap">
                    {localization.t('LOGGED_IN')}:&nbsp;{fullInfo}&nbsp;
                  </div>
                  <MenuPanel
                    me={me}
                    handleLanguageChange={this.handleLanguageChange}
                    onLogoutClick={this.onLogoutClick}
                  />
                </div>
                <AlarmsList params={this.props.params} />

                <AlarmReminder />
              </div>
            )}
            {has_data ? (
              childrenWithProps ? (
                childrenWithProps
              ) : (
                <div className="flex-1-1-auto" />
              )
            ) : (
              <div className="flex-1-1-auto" />
            )}
            {has_data && !isTemplateOnly ? (
              <div className="flex-0-0-auto w-25em flex-col">
                <Telephony />
                <UserHistory params={this.props.params} />
              </div>
            ) : null}
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <Tabs
            onChange={this.handleTabChange}
            tabItemContainerStyle={{
              backgroundColor: '#475366'
            }}
            value={slideIdx}
          >
            {!isTemplateOnly && (
              <Tab label={this.getTabLabel()} value={0}>
                <div style={styles.slide}>
                  <div className="flex-col flex-0-0-auto bg-c-gray ">
                    <div>
                      <div className="flex-row dashboard-panel" data-cy="dashboardPanel">
                        <SyncStatus />
                        <div className="flex-1-1-auto flex-col-around p-l-1em f-15 c-white text-wrap">
                          {localization.t('LOGGED_IN')}:&nbsp;{fullInfo}&nbsp;
                        </div>
                        <div>
                          <MenuPanel
                            me={me}
                            mobile={true}
                            handleLanguageChange={this.handleLanguageChange}
                            onLogoutClick={this.onLogoutClick}
                          />
                        </div>
                      </div>
                      <AlarmsList
                        params={this.props.params}
                        mobile={true}
                        onAlarmSelect={() => this.setState({ slideIndex: 1 })}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
            )}
            <Tab id="SEARCH_USER" label={localization.t('TABS_DETAILS')} value={1}>
              <div style={{ overflowY: 'scroll', height: 'calc(100vh - 48px)' }}>
                {has_data ? (
                  childrenWithProps ? (
                    childrenWithProps
                  ) : (
                    <div className="flex-1-1-auto" />
                  )
                ) : (
                  <div className="flex-1-1-auto" />
                )}
              </div>
            </Tab>
            {has_data && !isTemplateOnly ? (
              <Tab label={localization.t('TABS_HISTORY')} value={2}>
                <div style={{ overflowY: 'scroll', height: 'calc(100vh - 48px)' }}>
                  <div className="flex-0-0-auto flex-col">
                    <Telephony />
                    <UserHistory
                      params={this.props.params}
                      mobile={true}
                      onAlarmSelect={() => this.setState({ slideIndex: 1 })}
                    />
                  </div>
                </div>
              </Tab>
            ) : null}
          </Tabs>
        </MediaQuery>
      </div>
    );
    return (
      <div>
        {settings.length && me ? content : <div className="wh-100vwh" />}
        {overlay ? <SpinnerOverlay /> : null}
        <PopupError
          onClick={this.handlePopupErrorClick}
          message={errorMessage}
          show={!!errorMessage}
        />
        <PopupError
          onClick={this.handleUnauthorizedClose}
          message={unauthorizedErrorMessage}
          show={!!unauthorizedErrorMessage}
        />
        <PopupConfirm
          onClick={this.handleLogoutConfirmClick}
          message={confirmLogoutMessage}
          yes="CONTINUE_TO_LOGOUT"
          no="CANCEL"
          show={!!confirmLogoutMessage}
        />
        <PopupConfirm
          onClick={this.handleTakeCareConfirmClick}
          message={confirmTakeCareMessage}
          yes="CONTINUE_TO_LOGOUT"
          no="CANCEL"
          show={!!confirmTakeCareMessage}
        />
        <PopupConfirm
          onClick={this.approvedAudio}
          message={localization.t('CONFIRM_AUDIO_ON_PAGE')}
          yes="APPROVED"
          withoutCancel={true}
          show={this.state.showApprovedModal}
        />
        {this.state.showNotesModal && <CallNotes params={this.props.params} />}
        <TwilioNotifications />
      </div>
    );
  }

  handleUnauthorizedClose = (e: React.ChangeEvent<HTMLButtonElement>) => {
    let element = e.target;
    if (element.dataset.click === 'close') {
      appHistory._pathname = this.props.location.pathname;

      if (logoutWhenReferrer()) {
        return;
      }

      appHistory.replace(constants.PATH_LOGIN);
    }
  };

  pruneStore() {
    authentication.clearLocalStorage();
  }

  handleUnauthorized(e: Event, err: string | null) {
    appDispatcher.unauthorizedErrorMessage = localization.t('LOGGED_OUT_OR_NOT_AUTHORIZED');
    appHistory._pathname = this.props.location.pathname;
    this.pruneStore();
    appHistory.replace(constants.PATH_LOGIN);
  }

  handleLoggedInOnOther() {
    appDispatcher.unauthorizedErrorMessage = localization.t('LOGGED_IN_ON_OTHER_DEVICE');
    appHistory._pathname = this.props.location.pathname;
    this.pruneStore();
    appHistory.replace(constants.PATH_LOGIN);
  }

  handleAccountRevoked() {
    appDispatcher.unauthorizedErrorMessage = localization.t('ACCOUNT_REVOKED');
    appHistory._pathname = this.props.location.pathname;
    this.pruneStore();
    appHistory.replace(constants.PATH_LOGIN);
  }

  handleAuthorizationAdmin() {
    this.setState({
      errorMessage: localization.t('CANT_AUTHENTICATE'),
      overlay: false
    });
  }

  handleUntrusted() {
    appDispatcher.unauthorizedErrorMessage = localization.t('UNTRUSTED_SOURCE');
    appHistory._pathname = this.props.location.pathname;
    this.pruneStore();
    appHistory.replace(constants.PATH_LOGIN);
  }

  handleServerError() {
    this.setState({
      errorMessage: localization.t('SERVICE_UNAVAILABLE'),
      errorCallback: null,
      overlay: false
    });
  }

  handleInternetConnectionAbort() {
    this.setState({
      errorMessage: localization.t('INTERNET_CONNECTION_ABORT'),
      errorCallback: null,
      overlay: false
    });
  }

  onTwilioError = () => {
    const state = TwilioStore.getState();

    if (state.error) {
      this.setState({
        errorMessage: localization.t(state.errorMsg),
        errorCallback: () =>
          appDispatcher.dispatch({
            action: {
              type: constants.TWILIO_CLOSE_ERROR_POPUP
            }
          }),
        overlay: false
      });
    }
  };

  /**
   * handle ajax error from stores
   */
  _onServerError = (event: Event, err: string | null, status: number) => {
    if (status >= 500) {
      this.setState({
        errorMessage: localization.t('SERVICE_UNAVAILABLE'),
        errorCallback: null,
        overlay: false
      });
      return;
    }

    if (status !== 401) {
      switch (status) {
        case 404:
          // Set flag to avoid infinity data fetching by UserHistory component
          usersStore.stopRequestPending();
          this.setState({
            errorMessage: err,
            errorCallback: () => {
              let fromId;
              let fromTemplate;
              if (this.props.location && this.props.location.query) {
                fromId = this.props.location.query.fromId;
                fromTemplate = this.props.location.query.fromTemplate;
              }

              if (fromId && fromTemplate) {
                appHistory.replace(`/dashboard/template/${fromTemplate}/${fromId}`);
              } else {
                appHistory.replace(constants.PATH_DASHBOARD);
              }
            },
            overlay: false
          });
          break;
        default:
          err = err === undefined ? 'Undefined error' : err; // if we dont set any error message then the popup will just dissapear
          this.setState({
            errorMessage: typeof err === 'string' ? localization.t(err) : err,
            errorCallback: null,
            overlay: false
          });
          break;
      }
    }
  };

  _onChange = () => {
    const settings = settingsStore.getSettings();
    const me = settingsStore.getMe();
    const dictionary = dictionariesStore.getLocalUserLogsDictionary();

    if (me) {
      this.handleLanguageChange(me.Language);
    }

    if (!dictionary && !this.state.dictionary) {
      appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_LOG_EVENT_DICTIONARY });
    }

    const showNotesModal = settingsStore.getValueByKey('MAKE_CALL_NOTES', 'boolean');

    this.setState({
      settings,
      me,
      dictionary,
      overlay: settings.length && me && dictionary ? false : true,
      showNotesModal: showNotesModal || false
    });
  };

  handleSettingsChange = () => {
    appDispatcher.handleServerAction({ type: constants.PING_REPORT });
    const isReadonly = settingsStore.getValueByKey('READONLY', 'boolean');
    // Set readonly UI (will affect all buttons and inputs)
    if (isReadonly) {
      this.setState({ readonly: true });
    }

    this._onChange();
  };
}

export default inject('permissions')(Dashboard);
