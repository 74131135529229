import React from 'react';

export default class IconTelephone extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-telephone"
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        viewBox="0 0 17 17"
        fill="none"
      >
        <circle cx="8" cy="8" r="7.5" />
        <path d="M5.48936 7.43538C6.16136 8.75605 7.24403 9.83405 8.56469 10.5107L9.59136 9.48405C9.71736 9.35805 9.90403 9.31605 10.0674 9.37205C10.59 9.54472 11.1547 9.63805 11.7334 9.63805C11.99 9.63805 12.2 9.84805 12.2 10.1047V11.7334C12.2 11.9901 11.99 12.2001 11.7334 12.2001C7.35136 12.2001 3.80003 8.64872 3.80003 4.26672C3.80003 4.01005 4.01003 3.80005 4.26669 3.80005H5.90003C6.15669 3.80005 6.36669 4.01005 6.36669 4.26672C6.36669 4.85005 6.46003 5.41005 6.63269 5.93272C6.68403 6.09605 6.64669 6.27805 6.51603 6.40872L5.48936 7.43538Z" />
      </svg>
    );
  }
}
