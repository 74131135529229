import React from 'react';
import Overlay from './overlay';
import Spinner from './spinner';

export default class SpinnerOverlay extends React.Component {
  render() {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }
}
