import EventEmitter from 'events';
import * as Logger from 'js-logger';
const storageKeyPrefix = 'rc-persist';

const constants = require('../../../json/constants.json');

export class StorageStore extends EventEmitter {
  storage: Storage;

  fallbackMap = {
    isCustomAlarmSoundEnabled: true,
    isNotifyReminderEnabled: false,
    notifyReminderInterval: null,
    lastUsedTelephonyIntegration: null,
    lastUsedTelephone: null,
    twillioDisconnectSoundEnabled: true,
    isExtendedLogsEnabled: true
  };

  constructor(storage: Storage) {
    super();
    this.storage = storage;
  }

  addEventListener(listener: EventListener) {
    this.addListener(constants.SETTINGS_CHANGE, listener);
  }

  removeEventListener(listener: EventListener) {
    this.removeListener(constants.SETTINGS_CHANGE, listener);
  }

  notify(key: string, value: number | string | boolean) {
    this.emit(constants.SETTINGS_CHANGE, { key, value });
  }

  setItem(key: string, value: number | string | boolean) {
    this.storage.setItem(this.getPrefixedKey(key), JSON.stringify({ data: value }));
    this.notify(key, value);
  }

  getItem(key: string) {
    try {
      const storageItem = this.storage.getItem(this.getPrefixedKey(key));

      if (storageItem === null) {
        throw new Error(`Data is null by key=${this.getPrefixedKey(key)}`);
      }

      return JSON.parse(storageItem).data;
    } catch (e) {
      return this.fallbackMap[key] || null;
    }
  }

  removeItem(key: string) {
    try {
      this.storage.removeItem(this.getPrefixedKey(key));
    } catch (e) {
      Logger.error('Could not remove item \'', this.getPrefixedKey(key), '\' from storage: ', e);
    }
  }

  getPrefixedKey(key: string) {
    return `${storageKeyPrefix}-${key}`;
  }
}

export default new StorageStore(window.localStorage);
