import React from 'react';

export default class IconCalendarWeek extends React.Component {
  render() {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.25 4.875C8.25 4.66789 8.41789 4.5 8.625 4.5H9.375C9.58211 4.5 9.75 4.66789 9.75 4.875V5.625C9.75 5.83211 9.58211 6 9.375 6H8.625C8.41789 6 8.25 5.83211 8.25 5.625V4.875Z" fill="#333333"/>
        <path d="M6 4.875C6 4.66789 6.16789 4.5 6.375 4.5H7.125C7.33211 4.5 7.5 4.66789 7.5 4.875V5.625C7.5 5.83211 7.33211 6 7.125 6H6.375C6.16789 6 6 5.83211 6 5.625V4.875Z" fill="#333333"/>
        <path d="M2.25 7.125C2.25 6.91789 2.41789 6.75 2.625 6.75H3.375C3.58211 6.75 3.75 6.91789 3.75 7.125V7.875C3.75 8.08211 3.58211 8.25 3.375 8.25H2.625C2.41789 8.25 2.25 8.08211 2.25 7.875V7.125Z" fill="#333333"/>
        <path d="M4.5 7.125C4.5 6.91789 4.66789 6.75 4.875 6.75H5.625C5.83211 6.75 6 6.91789 6 7.125V7.875C6 8.08211 5.83211 8.25 5.625 8.25H4.875C4.66789 8.25 4.5 8.08211 4.5 7.875V7.125Z" fill="#333333"/>
        <path d="M2.625 0C2.83211 0 3 0.167893 3 0.375V0.75H9V0.375C9 0.167893 9.16789 0 9.375 0C9.58211 0 9.75 0.167893 9.75 0.375V0.75H10.5C11.3284 0.75 12 1.42157 12 2.25V10.5C12 11.3284 11.3284 12 10.5 12H1.5C0.671573 12 0 11.3284 0 10.5V2.25C0 1.42157 0.671573 0.75 1.5 0.75H2.25V0.375C2.25 0.167893 2.41789 0 2.625 0ZM0.75 3V10.5C0.75 10.9142 1.08579 11.25 1.5 11.25H10.5C10.9142 11.25 11.25 10.9142 11.25 10.5V3H0.75Z" fill="#333333"/>
      </svg>
    );
  }
}
