import * as Logger from 'js-logger';
import { IObservableValue } from 'mobx';
import appDispatcher from './app_dispatcher';
import authentication from './authentication';
import { Event } from './event';
import event_bus from './event_bus';
import localization from './localization';
import Utils from './utils';

const constants = require('../json/constants.json');

interface CustomXMLHttpRequest extends XMLHttpRequest {
  __url: string;
  __method: string;
}

interface DescriptionModel {
  body: string;
  contentType: string;
  method: string;
  url: string;
  query_if_data: string;
  secure: boolean;
}

class Ajax extends Event {
  active_requests: CustomXMLHttpRequest[] = [];
  queued_requests: Map<string, Object> = new Map();

  /**
   * serialize form data by input object
   */
  objectToFormData<T>(objectData: T | {}, body: string) {
    let data = body;
    Object.keys(objectData).forEach(key => {
      data = data.replace('{' + key + '}', objectData[key]);
    });
    return data;
  }

  /**
   * prepare url by input object
   */
  objectToUrl<T>(objectData: T | {}, initial_query: string, initial_url: string) {
    let url = initial_url,
      query = initial_query;
    Object.keys(objectData).forEach(key => {
      let str_key = '{' + key + '}';
      if (url.indexOf(str_key) >= 0) {
        url = url.replace(str_key, objectData[key]);
      } else if (query && query.indexOf(str_key) >= 0) {
        let value = objectData[key],
          type = typeof value;
        if (type === 'number' || type === 'boolean') {
          query = query.replace(str_key, value);
        } else if (type === 'string' && value) {
          query = query.replace(str_key, value);
        }
      }
    });
    return url + (query !== initial_query ? '?' + query : '');
  }

  async sendRequest(
    force: boolean,
    method: string,
    url: string,
    data?: any | string | null,
    contentType?: string | null,
    callback?: any,
    secure: boolean = true
  ) {
    if (appDispatcher.ajax_unauthorized) {
      if (process.env.NODE_ENV !== 'production') {
        return Logger.log('ajax_unauthorized');
      }
      return;
    }
    if (
      !force &&
      Utils.find_obj(this.active_requests, '__url', url) &&
      Utils.find_obj(this.active_requests, '__method', method)
    ) {
      if (process.env.NODE_ENV !== 'production') {
        Logger.log(`Active request: ${method}:${url}`);
      }

      if (method === 'GET') {
        // if the same request is already running then we want to queue a request
        // to run after the current one, this is due to the possibility of changes being made
        // after the current request has fetched the information but before it is finished.
        // in the old solution the new requests was just skipped which led to timing issues where
        // new information did not appear in the client
        if (!this.queued_requests.has(`${method}:${url}`)) {
          this.queued_requests.set(`${method}:${url}`, {
            force,
            method,
            url,
            data,
            contentType,
            callback,
            secure
          });
        }

        // only one of each request needs to be queued at a time
        return;
      }
    }
    let xhr = new XMLHttpRequest() as CustomXMLHttpRequest,
      cb =
        callback && typeof callback === 'function'
          ? callback
          : (err: string[] | string | null) => {
              Logger.error(err);
            };
    xhr.__url = url;
    xhr.__method = method;

    this.active_requests.push(xhr);
    if (this.active_requests.length) {
      this.trigger('requests-on', this.active_requests);
    }
    xhr.open(method, url, true);
    let accessToken = authentication.getUserLSData(constants.ACCESS_TOKEN_KEY),
      tokenType = authentication.getUserLSData(constants.TOKEN_TYPE_KEY);
    if (authentication.getUserId()) {
      if (secure !== false) {
        xhr.setRequestHeader('Authorization', tokenType + ' ' + accessToken);
      }
    }
    xhr.setRequestHeader('Accept', '*/*');
    if (method === 'POST') {
      if (contentType) {
        xhr.setRequestHeader('Content-Type', contentType);
      } else {
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      }
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let index = this.active_requests.indexOf(xhr);
        if (index > -1) {
          this.active_requests.splice(index, 1);
        }
        if (!this.active_requests.length) {
          this.trigger('requests-off', this.active_requests);
        }
        let contentType = xhr.getResponseHeader('Content-Type'),
          message: string | any = xhr.responseText;
        if (contentType && contentType.indexOf('application/json') >= 0) {
          this.parse_JSON(message, (err: string | string[] | any, parsed: any) => {
            if (err) {
              cb(err);
            } else {
              message = parsed;
            }
          });
        }

        if (xhr.status === 0) {
          cb(localization.t('INTERNET_CONNECTION_ABORT'), xhr);
        } else if (xhr.status >= 500) {
          cb(localization.t('SERVICE_UNAVAILABLE'), xhr);
        } else if (xhr.status >= 200 && xhr.status < 300) {
          cb(null, xhr, message);
        } else if (
          xhr.status === 400 &&
          message &&
          message.error &&
          message.error === constants.IP_UNTRUSTED
        ) {
          if (authentication.isLoggedIn()) {
            event_bus.trigger(constants.UNTRUSTED_SOURCE, (message && message.error) || '', xhr);
          } else {
            cb(constants.UNTRUSTED_SOURCE, xhr);
          }
        } else if (Array.isArray(message.errors) && message.errors.length) {
          cb(message.errors.map((err: any) => err.description).join(' '), xhr);
        } else if (message.error_description) {
          cb(message.error_description, xhr);
        } else if (message.Message) {
          cb(message.Message, xhr);
        } else if (message.data) {
          cb(Utils.print_obj(message.data), xhr);
        } else {
          cb(Utils.print_obj(message), xhr);
        }

        if (this.queued_requests.has(`${method}:${url}`)) {
          let req = this.queued_requests.get(`${method}:${url}`);
          this.queued_requests.delete(`${method}:${url}`);

          !!req &&
            this.sendRequest(
              req['force'],
              req['method'],
              req['url'],
              req['data'],
              req['contentType'],
              req['callback'],
              req['secure']
            );
        }

        if (xhr.status === 401) {
          event_bus.trigger(constants.UNAUTHORIZED, message.Message || message);
        }
      }
    };
    xhr.send(data);
  }

  parse_JSON<K>(text: string, callback: (err: string | string[] | any, parsed?: K) => void) {
    let parsed;
    try {
      parsed = JSON.parse(text);
    } catch (e) {
      callback(e);
      return;
    }
    callback(null, parsed);
  }

  getRequest<K>(force: boolean, url: string, callback: K, secure: boolean = true) {
    this.sendRequest(force, 'GET', url, null, null, callback, secure);
  }

  postRequest<T, K>(force: boolean, url: string, data: T, contentType: string, callback: K) {
    this.sendRequest(force, 'POST', url, data, contentType, callback);
  }

  deleteRequest<K>(force: boolean, url: string, callback: K) {
    this.sendRequest(force, 'DELETE', url, null, null, callback);
  }

  /**
   * POST by endpoint description
   */
  postByDesc<T, K>(force: boolean, description: DescriptionModel, objectData: T, callback: K) {
    this.postRequest(
      force,
      this.objectToUrl(objectData, description.query_if_data, description.url),
      this.objectToFormData(objectData, description.body),
      description.contentType,
      callback
    );
  }

  /**
   * DELETE by endpoint description
   */
  deleteByDesc<K, U>(
    force: boolean,
    description: { query_if_data: string; url: string },
    objectData: K,
    callback: U
  ) {
    this.deleteRequest(
      force,
      this.objectToUrl(objectData, description.query_if_data, description.url),
      callback
    );
  }

  /**
   * GET by endpoint description
   */
  getByDesc<K>(force: boolean, description: { url: string; method: string }, callback: K) {
    this.getRequest(force, description.url, callback);
  }

  /**
   * GET by endpoint description send additional data
   */
  getByDescWithData<T, K>(
    force: boolean,
    description: { url: string; method: string; query_if_data: string; secure: boolean },
    objectData: T,
    callback: K
  ) {
    this.getRequest(
      force,
      this.objectToUrl(objectData, description.query_if_data, description.url),
      callback,
      description.secure
    );
  }

  getByDescPromise<T>(description: { url: string; method: string }) {
    return new Promise((resolve, reject) =>
      this.getRequest(
        true,
        description.url,
        (err: string[] | string | null, xhr: XMLHttpRequest, data: T) => {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        }
      )
    );
  }

  getByDescWithDataPromise<T>(
    description: { url: string; method: string; query_if_data: string },
    data: {
      user_id?: string | number;
      alarmId?: string;
      page?: IObservableValue<number>;
      pageSize?: number;
      date?: string;
      identifier?: string;
    }
  ) {
    return new Promise((resolve, reject) =>
      this.getRequest(
        true,
        this.objectToUrl(data, description.query_if_data, description.url),
        (err: string[] | string | null, xhr: XMLHttpRequest, data: T) => {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        }
      )
    );
  }

  postByDescPromise<T, K, U>(
    description: { query_if_data: string; url: string; body: string; contentType: string },
    objectData?: T,
    headers?: K
  ) {
    return new Promise((resolve, reject) =>
      this.postRequest(
        true,
        this.objectToUrl(objectData, description.query_if_data, description.url),
        this.objectToFormData(objectData, description.body),
        description.contentType,
        (err: string[] | string | null, xhr: XMLHttpRequest, data: U) => {
          if (err) {
            return reject({ err, xhr });
          }
          return resolve(data);
        }
      )
    );
  }
}

export default new Ajax();
