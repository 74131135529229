import React from 'react';

export default class IconModalClose extends React.Component {
  render() {
    return (
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1.50024L8.50583 9.00024" stroke="#CCCCCC" strokeWidth="2"/>
        <path d="M1 9L8.50583 1.5" stroke="#CCCCCC" strokeWidth="2"/>
      </svg>
    )
  }
}
