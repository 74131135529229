import * as Logger from 'js-logger';
import { LogModel } from './interfaces/backend_model';
import dictionariesStore from './stores/dictionaries_store';
import { isSipUsername } from './utilities/user';

class LogEvent {
  reg_exp_0 = /\{0}/gi;
  reg_exp_1 = /\{1}/gi;
  reg_exp_2 = /\{2}/gi;

  safeGetFromArray(idx: number, arr: string[]) {
    if (arr.length >= idx) {
      return arr[idx];
    }

    // Returned undefined to use ES6 default params in `format` method
    return undefined;
  }

  splitLogText(logText: string): string[] {
    const strArr = logText.split(':');

    // Need to handle the following logTexts:
    // 'Client commanded to call to sip:username'
    // 'Incoming voice call with id:callsid from:sip:username'

    if (isSipUsername(`${strArr[0]}:${strArr[1]}`)) {
      // 'sip:username'
      strArr[0] = `${strArr[0]}:${strArr[1]}`;
      strArr.splice(1, 1);
    } else if (isSipUsername(`${strArr[1]}:${strArr[2]}`)) {
      // 'callsid:sip:username'
      strArr[1] = `${strArr[1]}:${strArr[2]}`;
      strArr.splice(2, 1);
    }
    return strArr;
  }

  format(str: string, value_0 = '', value_1 = '', value_2 = '') {
    let formatted = str;
    formatted = formatted.replace(this.reg_exp_0, value_0);
    formatted = formatted.replace(this.reg_exp_1, value_1);
    formatted = formatted.replace(this.reg_exp_2, value_2);
    return formatted;
  }

  formatLogText(log: LogModel) {
    const dictionary = dictionariesStore.getLocalUserLogsDictionary();

    if (!dictionary) {
      Logger.error('Dictionary missing!');
      return log.LogText || '';
    }

    const { LogText: logText, PredefinedId: logType, LogEventCode: logSubType } = log;
    const details =
      logType === 2 ? [logText] : logType !== 5 ? this.splitLogText(logText) : logText.split('\n');
    const resolvedEventText = dictionary[`LogEvent_${logType}_${logSubType}`];

    return resolvedEventText
      ? this.format(
        resolvedEventText,
        this.safeGetFromArray(0, details),
        this.safeGetFromArray(1, details),
        this.safeGetFromArray(2, details)
      )
      : this.format(
        dictionary.LogEventUndefined,
        logType.toString(),
        logSubType.toString(),
        logText
      );
  }
}

export default new LogEvent();
