import * as he from 'he';
import React from 'react';
import Linkify from 'react-linkify';
import Section from '../../components/section';
import { evalToggleState, hideWidget } from '../../enums/VisibilityState';
import { UserDetailsModel, Widget } from '../../interfaces/backend_model';
import localization from '../../localization';
import { WidgetErrorBoundary } from '../../components/errorBoundaries/widgetErrorBoundary';

const constants = require('../../../json/constants.json');

interface OrganizationNotesSectionProps {
  widget: Widget;
  userDetails: UserDetailsModel;
  toggleType?: string;
  toggleState?: string;
  fullscreenMode?: boolean;
}

const alarmT = localization.useNSt(constants.NS_ALARM);

function OrganizationNotesWidget(props: OrganizationNotesSectionProps) {
  let { widget, userDetails, toggleType, toggleState, fullscreenMode } = props;

  const orgNote = he.decode(userDetails?.Customer?.Telefax || '');

  const hasContent = orgNote ? true : false;
  const evaluatedToggleState = evalToggleState(hasContent, toggleState, widget ? widget.widgetName : "OrganizationNotesSection");
  if (hideWidget(evaluatedToggleState)) return null;

  return (
    <Section
      widget={widget}
      addSectionClassName="flex-0-0-auto"
      toggleType={toggleType}
      toggleState={evaluatedToggleState}
      fullscreenMode={fullscreenMode}
      headerLocKey={alarmT('ORGANIZATION_NOTES_SECTION')}
      bodyClassName="flex-row-wrap bg-c-white-primary"
      headerLocOptions={{ ns: constants.NS_MAP }}
      childrenLocOptions={{ ns: constants.NS_MAP }}
    >
      <div className="d-flex w-100p">
        <div className='y-scroll scrollableWidget-medium w-100p'>
          <div className="p-r-l-1em item-ver flex-row-between">
            <div className="pre-line">
              <Linkify
                properties={{ target: '_blank', style: { color: 'blue', fontWeight: 'bold' } }}
              >
                {orgNote}
              </Linkify>
            </div>
          </div>
        </div>
      </div>
    </Section >
  );
}

export default function OrganizationNotesSection (props: OrganizationNotesSectionProps) {
  return (
    <WidgetErrorBoundary>
      <OrganizationNotesWidget {...props} />
    </WidgetErrorBoundary>
  );
};
