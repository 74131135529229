import React from 'react';

export default class IconLoopBack extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-loop-back"
        x="0px"
        y="0px"
        viewBox="0 0 70 70"
      >
        <path
          className="st0"
          d="M35,9.5V0L22.3,12.7L35,25.5v-9.5c10.5,0,19.1,8.6,19.1,19.1c0,3.2-0.8,6.3-2.2,8.9l4.6,4.6
          C59,44.6,60.5,40,60.5,35C60.5,20.9,49.1,9.5,35,9.5z M35,54.1c-10.5,0-19.1-8.6-19.1-19.1c0-3.2,0.8-6.3,2.2-8.9l-4.6-4.6
          C11,25.4,9.5,30,9.5,35c0,14.1,11.4,25.5,25.5,25.5V70l12.7-12.7L35,44.5V54.1z"
        />
        <path className="st1" d="M101,17h70v70h-70V17z" />
      </svg>
    );
  }
}
