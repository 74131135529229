import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { AlarmDetailsModel, UserAbsenceModel, UserDetailsModel } from '../../../interfaces/backend_model';
import localization from '../../../localization';
import { AbsenceHistory } from './AbsenceHistory';
import { CancelAbsenceModal } from './CancelAbsenceModal';
import { CreateAbsenceModal } from './CreateAbsenceModal';

export declare type AbsenceControllerActions = {
  openCreateAbsence: () => void;
  openAbsenceHistory: () => void;
  setAbsenceIdToDelete: (absenceId: number | undefined) => void;
  closeAbsenceHistory: () => void;
}

declare interface AbsenceControllerProps {
  notificationSystem: any;
  userDetails: UserDetailsModel;
  alarmDetails: AlarmDetailsModel;
  absenceData: UserAbsenceModel;
}

export const AbsenceController = forwardRef<
  AbsenceControllerActions,
  AbsenceControllerProps
>((props: AbsenceControllerProps, ref) => {
  const [absenceHistoryOpen, setAbsenceHistoryOpen] = React.useState<boolean>(false);
  const [absenceCreateOpen, setAbsenceCreateOpen] = React.useState<boolean>(false);
  const [absenceDeleteOpen, setAbsenceDeleteOpen] = React.useState<boolean>(false);
  const [idToDelete, setIdToDelete] = React.useState<number | undefined>(undefined);

  const fromHistoryToDelete = (absenceId?: number) => {
    setIdToDelete(absenceId);
    setAbsenceHistoryOpen(false);
  }

  useEffect(() => {
    if (idToDelete) {
      setAbsenceDeleteOpen(true);
    }
  }, [idToDelete]);

  const closeAbsenceModal = (variant: string, type?: string) => {
    if (variant === 'create') {
      setAbsenceCreateOpen(false);
    } else {
      setIdToDelete(undefined);
      setAbsenceDeleteOpen(false);
    }

    if (variant !== "close") {
      if (typeof type === 'string') {
        props.notificationSystem.addNotification({
          title: localization.t(`ABSENCE_${type.toLocaleUpperCase()}`),
          level: 'info',
          autoDismiss: 5, // seconds
          position: 'br'
        });
      }
    }
  }

  useImperativeHandle<AbsenceControllerActions, AbsenceControllerActions>(
    ref,
    () => {
      const actions: AbsenceControllerActions = {
        openCreateAbsence: function openCreateAbsence() {
          setAbsenceCreateOpen(true);
        },
        openAbsenceHistory: function openAbsenceHistory() {
          setAbsenceHistoryOpen(true);
        },
        setAbsenceIdToDelete: function setAbsenceIdToDelete(absenceId?: number) {
          setIdToDelete(absenceId);
        },
        closeAbsenceHistory: function closeAbsenceHistory() {
          setAbsenceHistoryOpen(false);
        }
      };
      return actions;
    }
  )

  return (
    <>
      <AbsenceHistory
        isOpen={absenceHistoryOpen}
        onClose={() => {
          setAbsenceHistoryOpen(false);
        }}
        absenceData={props.absenceData}
        setAbsenceIdToDelete={fromHistoryToDelete}
      />
      <CreateAbsenceModal
        isOpen={absenceCreateOpen}
        onClose={(type?: string) => {
          closeAbsenceModal('create', type);
        }}
        userName={props.userDetails?.Name}
        userId={props.userDetails?.UserId}
        alarmId={props.alarmDetails?.AlarmId}
        notificationSystem={props.notificationSystem}
      />
      <CancelAbsenceModal
        isEnd={
          props.absenceData?.Ongoing[0]?.Id === idToDelete
        }
        isOpen={absenceDeleteOpen}
        onClose={(type: string) => {
          closeAbsenceModal(type, type);
        }}
        userName={props.userDetails?.Name}
        userId={props.userDetails?.UserId}
        alarmId={props.alarmDetails?.AlarmId}
        absenceId={idToDelete!}
      />
    </>
  )
});
