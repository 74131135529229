import * as Logger from 'js-logger';
import { Card, CardHeader } from 'material-ui/Card';
import Checkbox from 'material-ui/Checkbox';
import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import { observable, when } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import React from 'react';
import appDispatcher from '../app_dispatcher';
import appHistory from '../app_history';
import authentication from '../authentication';
import { DeviceModel } from '../interfaces/backend_model';
import localization from '../localization';
import alarmListStore from '../stores/alarms/alarm_list_store_actions';
import { StorageStore } from '../stores/mobx/StorageStore';
import settingsStore, { SettingsStore } from '../stores/settings_store';
import twillioStore, { storageKeysMap } from '../stores/twillio_store';
import validation from '../validation';
import IconDoor from './icon_door';

const constants = require('../../json/constants.json');

interface Props {
  storage: StorageStore;
  settings: SettingsStore;
}

interface State {
  is_volume_1_invalid: boolean;
  is_volume_2_invalid: boolean;
  isCustomAlarmSoundEnabled: boolean;
  isNotifyReminderEnabled: boolean;
  isSoundForNotifyEnabled: boolean;
  autoGainControl: boolean;
  noiseSuppression: boolean;
  audioAlarm_1: { [key: string]: string | number };
  audioAlarm_2: { [key: string]: string | number };
  settings: SettingModel[];

  intervalReminderSound: number[];
  notifyReminderInterval: number;
  twillioDisconnectSoundEnabled: boolean;
  twillioCodecList: string;
  twilioDisconnectSoundsChanging: boolean;
}

interface SettingModel {
  key: string;
  defaultvalue: string;
  modify: boolean;
  visible: boolean;
}

@inject('storage', 'settings')
@observer
export default class SettingsList extends React.Component<Props, State> {
  static contextTypes = {
    readonly: PropTypes.bool
  };

  audioAlarm_1: HTMLAudioElement;
  audioAlarm_2: HTMLAudioElement;

  @observable twilioDevices: DeviceModel[] | null;
  @observable currentTwilioRingtoneDevice: DeviceModel | null;
  @observable currentTwilioSpeakerDevice: DeviceModel | null;

  state = {
    is_volume_1_invalid: false,
    is_volume_2_invalid: false,
    isCustomAlarmSoundEnabled: this.props.storage.getItem('isCustomAlarmSoundEnabled'),
    isNotifyReminderEnabled: this.props.storage.getItem('isNotifyReminderEnabled'),
    isSoundForNotifyEnabled: this.props.storage.getItem('isSoundForNotifyEnabled'),
    noiseSuppression:
      this.props.storage.getItem('NOISE_SUPPRESSION') === null
        ? true
        : this.props.storage.getItem('NOISE_SUPPRESSION'),
    autoGainControl:
      this.props.storage.getItem('AUTO_GAIN_CONTROL') === null
        ? true
        : this.props.storage.getItem('AUTO_GAIN_CONTROL'),
    twillioCodecList: settingsStore.getValueByKey('TWILLIO_CODEC_LIST', 'string') || 'opus',
    audioAlarm_1: authentication.getAudioSettings('1'),
    audioAlarm_2: authentication.getAudioSettings('2'),
    settings: settingsStore.getSettings(),

    intervalReminderSound: [5, 10, 20, 30, 40, 50, 60, 120, 240],
    notifyReminderInterval: this.props.storage.getItem('notifyReminderInterval'),
    twillioDisconnectSoundEnabled: this.props.storage.getItem(
      storageKeysMap.TWILIO_DISCONNECT_SOUND_ENABLED
    ),
    twilioDisconnectSoundsChanging: false
  };

  componentDidMount() {
    settingsStore.on(constants.SETTINGS_CHANGE, this._onChange);
    appDispatcher.handleServerAction({ type: constants.LOAD_SERVER_SETTINGS });

    // Set default interval for reminder sound to 30 seconds
    if (!this.props.storage.getItem('notifyReminderInterval')) {
      this.onChangeIntervalReminderSoundTime({} as any, {} as any, 30);
    }

    when(
      () => {
        return this.props.settings.userApproved && this.props.settings.initialized;
      },
      () => {
        this.twilioDevices = twillioStore.getAvailableDevices();
        this.currentTwilioRingtoneDevice = twillioStore.getCurrentRingtoneDevice();
        this.currentTwilioSpeakerDevice = twillioStore.getCurrentSpeakerDevice();
      }
    );

    this.audioAlarm_1 = document.getElementById(constants.ID_AUDIO_ALARM_1) as HTMLAudioElement;
    this.audioAlarm_2 = document.getElementById(constants.ID_AUDIO_ALARM_2) as HTMLAudioElement;
  }

  componentWillUnmount() {
    settingsStore.off(constants.SETTINGS_CHANGE, this._onChange);

    this.audioAlarm_1?.pause();
    this.audioAlarm_2?.pause();
  }

  renderSetting(setting: SettingModel) {
    return (
      <div className="flex-row-between form-list-item p-1em f-s-08em" key={setting.key}>
        <div className="flex-0-0-auto flex-col-around">{setting.key}</div>
        <div className="flex-0-0-auto flex-col-around">{setting.defaultvalue}</div>
      </div>
    );
  }

  hideSettings(setting: SettingModel) {
    return setting.visible;
  }

  togglePlay = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target && target.dataset.alarm_level) {
      switch (target.dataset.alarm_level) {
        case '1':
          authentication.setUserLSData(
            [constants.AUDIO_SETTING_PLAY, '1'],
            target.checked ? 'true' : 'false'
          );
          this.setState({
            audioAlarm_1: authentication.getAudioSettings('1')
          });
          break;
        case '2':
          authentication.setUserLSData(
            [constants.AUDIO_SETTING_PLAY, '2'],
            target.checked ? 'true' : 'false'
          );
          this.setState({
            audioAlarm_2: authentication.getAudioSettings('2')
          });
          break;
        default:
          break;
      }
    }
  };

  toggleSetting = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    switch (target.value) {
      case 'AUTO_GAIN_CONTROL':
        this.state.autoGainControl = !this.state.autoGainControl;
        this.setState({ autoGainControl: this.state.autoGainControl });
        this.props.storage.setItem('AUTO_GAIN_CONTROL', this.state.autoGainControl);
        break;
      case 'NOISE_SUPPRESSION':
        this.state.noiseSuppression = !this.state.noiseSuppression;
        this.setState({ noiseSuppression: this.state.noiseSuppression });
        this.props.storage.setItem('NOISE_SUPPRESSION', this.state.noiseSuppression);
        break;
    }

    twillioStore.setup();
  };

  handleChangeVolume1 = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    if (currentTarget && currentTarget.name) {
      const audio = this.audioAlarm_1;
      const value = currentTarget.value;
      const is_invalid = validation.validate_volume_value(value);
      if (audio) {
        try {
          audio.volume = Number(value);
        } catch (e) {
          Logger.error(e);
        }
      }
      authentication.setUserLSData([constants.AUDIO_SETTING_VOLUME, '1'], value);
      this.setState({
        is_volume_1_invalid: is_invalid,
        audioAlarm_1: authentication.getAudioSettings('1')
      });
    }
  };

  handleChangeVolume2 = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    if (currentTarget && currentTarget.name) {
      const audio = this.audioAlarm_2;
      const value = currentTarget.value;
      const is_invalid = validation.validate_volume_value(value);
      if (audio) {
        try {
          audio.volume = Number(value);
        } catch (e) {
          Logger.error(e);
        }
      }
      authentication.setUserLSData([constants.AUDIO_SETTING_VOLUME, '2'], value);
      this.setState({
        is_volume_2_invalid: is_invalid,
        audioAlarm_2: authentication.getAudioSettings('2')
      });
    }
  };

  checkAlarmSound1 = () => {
    const level = '1';
    const audio: HTMLAudioElement = this['audioAlarm_' + level];

    if (audio) {
      audio.play();
    }
  };

  checkAlarmSound2 = () => {
    const level = '2';
    const audio: HTMLAudioElement = this['audioAlarm_' + level];

    if (audio) {
      audio.play();
    }
  };

  close() {
    appHistory.push(constants.PATH_DASHBOARD);
  }

  onChangeCustomAlarmSound = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) =>
    this.setState(prevState => {
      const invertedState = !prevState.isCustomAlarmSoundEnabled;

      this.props.storage.setItem('isCustomAlarmSoundEnabled', invertedState);

      return {
        isCustomAlarmSoundEnabled: invertedState
      };
    });

  onChangeTwillioDisconnectSounds = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => {
      const invertedState = !prevState.twillioDisconnectSoundEnabled;
      this.props.storage.setItem(storageKeysMap.TWILIO_DISCONNECT_SOUND_ENABLED, invertedState);

      const [
        currentRingtoneDeviceId,
        currentSpeakerDeviceId
      ] = twillioStore.toggleDisconnectSound();
      // TODO: Remove this ugly peace of code when Twilio has added support for disconnect sound in a call:
      // https://github.com/twilio/twilio-voice.js/issues/14
      setTimeout(() => {
        twillioStore.setRingtoneDevice(currentRingtoneDeviceId);
        twillioStore.setSpeakerDevice(currentSpeakerDeviceId);
        this.setState({ twilioDisconnectSoundsChanging: false });
      }, 500);
      return {
        twilioDisconnectSoundsChanging: true,
        twillioDisconnectSoundEnabled: invertedState
      };
    });
  };

  onChangeReminderNotify = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => {
      const invertedState = !prevState.isNotifyReminderEnabled;

      this.props.storage.setItem('isNotifyReminderEnabled', invertedState);
      alarmListStore.trigger(constants.ALARMS_CHANGE);
      return {
        isNotifyReminderEnabled: invertedState
      };
    });
  };

  onChangeSoundReminderNotify = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => {
      const invertedState = !prevState.isSoundForNotifyEnabled;

      this.props.storage.setItem('isSoundForNotifyEnabled', invertedState);

      return {
        isSoundForNotifyEnabled: invertedState
      };
    });
  };

  onChangeIntervalReminderSoundTime = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number,
    interval: number
  ) => {
    this.setState(prevState => {
      this.props.storage.setItem('notifyReminderInterval', interval);

      return {
        notifyReminderInterval: interval
      };
    });
  };

  onTwilioRingtoneDeviceChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number,
    deviceId: string
  ) => {
    if (!this.twilioDevices) {
      return;
    }

    const currentDevice = this.twilioDevices.find(device => device.id === deviceId);
    if (currentDevice) {
      // side effect imperative API
      twillioStore.setRingtoneDevice(deviceId);

      this.currentTwilioRingtoneDevice = {
        id: deviceId,
        label: currentDevice.label
      };
    }
  };

  onTwilioSpeakerDeviceChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number,
    deviceId: string
  ) => {
    if (!this.twilioDevices) {
      return;
    }

    const currentDevice = this.twilioDevices.find(device => device.id === deviceId);

    if (currentDevice) {
      // side effect imperative API
      twillioStore.setSpeakerDevice(deviceId);

      this.currentTwilioSpeakerDevice = {
        id: deviceId,
        label: currentDevice.label
      };
    }
  };

  getAmountDevices = (devices: DeviceModel[] | null) => {
    if (!devices) {
      return 0;
    }

    return devices.length;
  };

  render() {
    const isAudio1Enabled = this.audioAlarm_1;
    const isAudio2Enabled = this.audioAlarm_2;
    const {
      audioAlarm_1,
      audioAlarm_2,
      settings,
      is_volume_1_invalid,
      is_volume_2_invalid
    } = this.state;

    const isCloudIntegration = twillioStore.isCloudIntegration();
    const amountDevices = this.getAmountDevices(this.twilioDevices);
    const { isDeniedOnBackend } = twillioStore.getState();
    const twilioAvialable = isCloudIntegration && !isDeniedOnBackend;

    return (
      <div className="flex-1-1-auto form-list flex-col">
        <Paper zDepth={1}>
          <div className="flex-col flex-0-0-auto">
            <div className="p-05em flex-row">
              <div className="bold flex-1-1-auto flex-center-row">
                <div className="flex-col-around">{localization.t('SETTINGS')}</div>
              </div>
              <div className="flex-col-around flex-0-0-auto">
                <button type="button" className="form-btn-transparent" onClick={this.close}>
                  <IconDoor />
                </button>
              </div>
            </div>
            <div className="flex-row">
              <Card style={{ width: '250px', marginRight: '20px', marginLeft: '20px' }}>
                <CardHeader
                  subtitle={localization.t('LOW_PRIORITY_ALARMS')}
                  subtitleStyle={{ color: '#000000', fontWeight: 'bold' }}
                />
                <div className="flex-col">
                  <div className="flex-row-between">
                    <div className="flex-1-1-auto flex-col-around p-1em">
                      {localization.t('PLAY_SOUND_ON_NEW_ALARM')}
                    </div>
                    <div className="flex-0-0-auto flex-col-around">
                      <Checkbox
                        data-alarm_level="1"
                        disabled={!isAudio1Enabled || this.context.readonly}
                        checked={
                          audioAlarm_1[constants.AUDIO_SETTING_PLAY]
                            ? audioAlarm_1[constants.AUDIO_SETTING_PLAY] === 'true'
                            : !!isAudio1Enabled
                        }
                        onCheck={this.togglePlay}
                      />
                    </div>
                  </div>
                  <div className="flex-row-center">
                    <div className="flex-col-around bold">{localization.t('VOLUME')}:</div>
                    <div className="flex-col-around p-l-1em">
                      <input
                        type="number"
                        min="0"
                        max="1"
                        step="0.1"
                        name="1"
                        disabled={!isAudio1Enabled || this.context.readonly}
                        value={
                          audioAlarm_1[constants.AUDIO_SETTING_VOLUME]
                            ? parseFloat(audioAlarm_1[constants.AUDIO_SETTING_VOLUME])
                            : 1
                        }
                        onChange={this.handleChangeVolume1}
                      />
                    </div>
                    {is_volume_1_invalid ? (
                      <label className="error-text p-l-1em">
                        {localization.t('VOLUME_INVALID_MESSAGE')}!
                      </label>
                    ) : null}
                  </div>
                  <div className="flex-center-row p-1em">
                    <RaisedButton
                      disabled={!isAudio1Enabled || this.context.readonly}
                      onClick={this.checkAlarmSound1}
                      type="button"
                      label={localization.t('TEST_ALARM_SOUND')}
                    />
                  </div>
                </div>
              </Card>
              <Card style={{ width: '250px', marginRight: '20px' }}>
                <CardHeader
                  subtitle={localization.t('HIGH_PRIORITY_ALARMS')}
                  subtitleStyle={{ color: '#000000', fontWeight: 'bold' }}
                />
                <div className="flex-col">
                  <div className="flex-row-between">
                    <div className="flex-1-1-auto flex-col-around p-1em">
                      {localization.t('PLAY_SOUND_ON_NEW_ALARM')}
                    </div>
                    <div className="flex-0-0-auto flex-col-around">
                      <Checkbox
                        data-alarm_level="2"
                        disabled={!isAudio2Enabled || this.context.readonly}
                        checked={
                          audioAlarm_2[constants.AUDIO_SETTING_PLAY]
                            ? audioAlarm_2[constants.AUDIO_SETTING_PLAY] === 'true'
                            : !!isAudio2Enabled
                        }
                        onCheck={this.togglePlay}
                      />
                    </div>
                  </div>
                  <div className="flex-row-center">
                    <div className="flex-col-around bold">{localization.t('VOLUME')}:</div>
                    <div className="flex-col-around p-l-1em">
                      <input
                        type="number"
                        min="0"
                        max="1"
                        step="0.1"
                        name="2"
                        disabled={!isAudio2Enabled || this.context.readonly}
                        value={
                          audioAlarm_2[constants.AUDIO_SETTING_VOLUME]
                            ? parseFloat(audioAlarm_2[constants.AUDIO_SETTING_VOLUME])
                            : 1
                        }
                        onChange={this.handleChangeVolume2}
                      />
                    </div>
                    {is_volume_2_invalid ? (
                      <label className="error-text p-l-1em">
                        {localization.t('VOLUME_INVALID_MESSAGE')}!
                      </label>
                    ) : null}
                  </div>
                  <div className="flex-center-row p-1em">
                    <RaisedButton
                      disabled={!isAudio2Enabled || this.context.readonly}
                      onClick={this.checkAlarmSound2}
                      type="button"
                      label={localization.t('TEST_ALARM_SOUND')}
                    />
                  </div>
                </div>
              </Card>
              <Card style={{ width: '250px', marginRight: '20px' }}>
                <CardHeader
                  subtitle={localization.t('CUSTOM_ALARM_SOUND_HEADER')}
                  subtitleStyle={{ color: '#000000', fontWeight: 'bold' }}
                />
                <div className="flex-col">
                  <div className="flex-row-between">
                    <div className="flex-1-1-auto flex-col-around p-1em">
                      {localization.t('CUSTOM_ALARM_SOUND')}
                    </div>
                    <div className="flex-0-0-auto flex-col-around">
                      <Checkbox
                        disabled={this.context.readonly}
                        checked={this.state.isCustomAlarmSoundEnabled}
                        onCheck={this.onChangeCustomAlarmSound}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div
              className="flex-row"
              style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}
            >
              <Card style={{ width: '250px', marginRight: '20px' }}>
                <CardHeader
                  subtitle={localization.t('TWILIO_AUDIO_DEVICE_SETTINGS')}
                  subtitleStyle={{ color: '#000000', fontWeight: 'bold' }}
                />
                <div className="flex-col" style={{ padding: 16 }}>
                  <Observer>
                    {() => (
                      <SelectField
                        floatingLabelText={
                          amountDevices === 0
                            ? localization.t('NO_AVAILABLE_DEVICE')
                            : localization.t('TWILIO_RINGTONE_DEVICE')
                        }
                        floatingLabelStyle={{
                          color: amountDevices === 0 ? '#ff0000' : '#000000',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap'
                        }}
                        value={
                          this.currentTwilioRingtoneDevice
                            ? this.currentTwilioRingtoneDevice.id ||
                            (amountDevices <= 1 && this.currentTwilioRingtoneDevice.label) ||
                            null
                            : null
                        }
                        disabled={
                          this.context.readonly || !isCloudIntegration || amountDevices <= 1
                        }
                        fullWidth
                        onChange={this.onTwilioRingtoneDeviceChange}
                      >
                        {this.twilioDevices
                          ? this.twilioDevices.map(device => (
                            <MenuItem
                              key={device.id}
                              value={amountDevices <= 1 ? device.id || device.label : device.id}
                              primaryText={device.label}
                            />
                          ))
                          : null}
                      </SelectField>
                    )}
                  </Observer>
                  <RaisedButton
                    disabled={this.context.readonly || !isCloudIntegration || amountDevices < 1}
                    onClick={() => twillioStore.testRingtoneDevice()}
                    type="button"
                    label={localization.t('TEST_RINGTONE_DEVICE')}
                  />
                </div>
                <div className="flex-col" style={{ padding: 16 }}>
                  <Observer>
                    {() => (
                      <SelectField
                        floatingLabelText={
                          amountDevices === 0
                            ? localization.t('NO_AVAILABLE_DEVICE')
                            : localization.t('TWILIO_SPEAKER_DEVICE')
                        }
                        floatingLabelStyle={{
                          color: amountDevices === 0 ? '#ff0000' : '#000000',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap'
                        }}
                        value={
                          this.currentTwilioSpeakerDevice
                            ? this.currentTwilioSpeakerDevice.id ||
                            (amountDevices <= 1 && this.currentTwilioSpeakerDevice.label) ||
                            null
                            : null
                        }
                        disabled={
                          this.context.readonly || !isCloudIntegration || amountDevices <= 1
                        }
                        fullWidth
                        onChange={this.onTwilioSpeakerDeviceChange}
                      >
                        {this.twilioDevices
                          ? this.twilioDevices.map(device => (
                            <MenuItem
                              key={device.id}
                              value={amountDevices <= 1 ? device.id || device.label : device.id}
                              primaryText={device.label}
                            />
                          ))
                          : null}
                      </SelectField>
                    )}
                  </Observer>
                  <RaisedButton
                    disabled={this.context.readonly || !isCloudIntegration || amountDevices < 1}
                    onClick={() => twillioStore.testSpeakerDevice()}
                    type="button"
                    label={localization.t('TEST_SPEAKER_DEVICE')}
                  />
                </div>
                <div className="flex-col">
                  <div className="flex-row-between">
                    <div className="flex-1-1-auto flex-col-around p-1em">
                      {localization.t('AUTO_GAIN_CONTROL')}
                    </div>
                    <div className="flex-0-0-auto flex-col-around">
                      <Checkbox
                        checked={this.state.autoGainControl}
                        onCheck={this.toggleSetting}
                        value="AUTO_GAIN_CONTROL"
                      />
                    </div>
                  </div>
                  <div className="flex-row-between">
                    <div className="flex-1-1-auto flex-col-around p-1em">
                      {localization.t('NOISE_SUPPRESSION')}
                    </div>
                    <div className="flex-0-0-auto flex-col-around">
                      <Checkbox
                        checked={this.state.noiseSuppression}
                        onCheck={this.toggleSetting}
                        value="NOISE_SUPPRESSION"
                      />
                    </div>
                  </div>
                </div>
              </Card>
              <Card style={{ width: '250px', marginRight: '20px', display: 'none' }}>
                <CardHeader
                  subtitle={localization.t('SYSTEM_SETTINGS')}
                  subtitleStyle={{ color: '#000000', fontWeight: 'bold' }}
                />
                <div className="flex-col">
                  {settings.filter(this.hideSettings).map(this.renderSetting)}
                </div>
              </Card>
              <Card style={{ width: '250px', marginRight: '20px' }}>
                <CardHeader
                  subtitle={localization.t('PLAY_REMINDER_SOUNDS')}
                  subtitleStyle={{ color: '#000000', fontWeight: 'bold' }}
                />
                <div className="flex-col">
                  <div className="flex-row-between">
                    <div className="flex-1-1-auto flex-col-around p-1em">
                      {localization.t('ENABLE_REMINDER')}
                    </div>
                    <div className="flex-0-0-auto flex-col-around">
                      <Checkbox
                        disabled={this.context.readonly}
                        checked={this.state.isNotifyReminderEnabled}
                        onCheck={this.onChangeReminderNotify}
                      />
                    </div>
                  </div>
                  {this.state.isNotifyReminderEnabled && (
                    <div className="flex-row-between">
                      <div className="flex-1-1-auto flex-col-around p-1em">
                        {localization.t('ENABLE_SOUND_REMINDER')}
                      </div>
                      <div className="flex-0-0-auto flex-col-around">
                        <Checkbox
                          disabled={this.context.readonly}
                          checked={this.state.isSoundForNotifyEnabled}
                          onCheck={this.onChangeSoundReminderNotify}
                        />
                      </div>
                    </div>
                  )}
                  <div className="flex-col" style={{ padding: 16 }}>
                    <SelectField
                      floatingLabelText={localization.t('INTERVAL')}
                      floatingLabelStyle={{
                        color: '#000000',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}
                      value={
                        this.state.notifyReminderInterval ? this.state.notifyReminderInterval : null
                      }
                      disabled={this.context.readonly}
                      fullWidth
                      onChange={this.onChangeIntervalReminderSoundTime}
                    >
                      {this.state.intervalReminderSound.map(interval => (
                        <MenuItem
                          key={'interval' + interval}
                          value={interval}
                          primaryText={interval + ' s'}
                        />
                      ))}
                    </SelectField>
                  </div>
                </div>
              </Card>
              {twilioAvialable && (
                <Card style={{ width: '250px' }}>
                  <CardHeader
                    subtitle={localization.t('TWILLIO_SOUNDS')}
                    subtitleStyle={{ color: '#000000', fontWeight: 'bold' }}
                  />
                  <div className="flex-col">
                    <div className="flex-row-between">
                      <div className="flex-1-1-auto flex-col-around p-1em">
                        {localization.t('TWILLIO_DISCONNECT_SOUND')}
                      </div>
                      <div className="flex-0-0-auto flex-col-around">
                        <Checkbox
                          disabled={
                            this.context.readonly || this.state.twilioDisconnectSoundsChanging
                          }
                          checked={this.state.twillioDisconnectSoundEnabled}
                          onCheck={this.onChangeTwillioDisconnectSounds}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </Paper>
      </div>
    );
  }

  _onChange = () => {
    this.setState({ settings: settingsStore.getSettings() });
  };
}
