import { format } from 'date-fns';
import React from 'react';
import NotificationSystem from 'react-notification-system';
import { acceptCurrentCall, declineCurrentCall } from '../actions/twilio';
import localization from '../localization';
import settingsStore from '../stores/settings_store';
import TwilioStore from '../stores/twillio_store';
import CallerName from './caller_name';

const constants = require('../../json/constants.json');
const CURRENT_CALL_UID = 'current-pending-call';
const busySound = require('../../audio/phonebusy.mp3');

export default class TwilioNotifications extends React.Component {
  notificationSystem: NotificationSystem.System;

  componentDidMount() {
    TwilioStore.addEventListener(this.onTwilioStatusUpdate);
  }

  componentWillUnmount() {
    TwilioStore.removeEventListener(this.onTwilioStatusUpdate);
  }

  onAcceptCall = () => {
    acceptCurrentCall();
  };

  onRejectCall = () => {
    declineCurrentCall();
    this.notificationSystem.removeNotification(CURRENT_CALL_UID);
  };

  onTwilioStatusUpdate = (payload: { type: string }) => {
    const state = TwilioStore.getState();
    const callDetails = TwilioStore.getCallDetails();

    switch (payload.type) {
      case constants.TWILIO_INIT_READY:
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_INFO_READY'),
          level: 'info',
          autoDismiss: 3, // seconds
          position: 'br'
        });
        break;
      case constants.TWILIO_ACCEPT_CURRENT_CALL:
        this.notificationSystem.removeNotification(CURRENT_CALL_UID);
        break;
      case constants.TWILIO_INIT_CONNECTION_ERROR:
        // When error while connecting to twilio
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_ERROR_GENERAL_TITLE'),
          message: localization.t('TWILIO_ERROR_GENERAL_BODY'),
          level: 'error',
          autoDismiss: 10, // seconds
          position: 'br'
        });
        break;
      case constants.TWILIO_CALL_STARTED: {
        const dateStarted = format(
          callDetails.started!,
          settingsStore.getDateFormat() + ' HH:mm:ss'
        );

        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_INFO_CALL_STARTED'),
          message: `${localization.t('CALL_STARTED_AT')} ${dateStarted} ${localization.t('WITH')} ${callDetails.destination
            }`,
          level: 'info',
          autoDismiss: 3, // seconds
          position: 'br'
        });
        break;
      }
      case constants.TWILIO_CALL_HOLDED:
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_INFO_CALL_HOLDED'),
          message: `${localization.t('TWILIO_INFO_CALL_HOLDED')} ${localization.t('WITH')} ${callDetails.destination
            }`,
          level: 'info',
          autoDismiss: 3, // seconds
          position: 'br'
        });
        break;
      case constants.TWILIO_CALL_RESUMED:
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_INFO_CALL_RESUMED'),
          message: `${localization.t('TWILIO_INFO_CALL_RESUMED')} ${localization.t('WITH')} ${callDetails.destination
            }`,
          level: 'info',
          autoDismiss: 3, // seconds
          position: 'br'
        });
        break;
      case constants.TWILIO_CALL_STOPPED:
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_INFO_CALL_ENDED'),
          message: `${localization.t('TWILIO_INFO_CALL_ENDED')} ${localization.t('WITH')} ${callDetails.destination
            }. ${localization.t('CALL_DURATION')}: ${callDetails.elapsedTime} ${localization.t(
              'SECONDS'
            )}`,
          level: 'info',
          autoDismiss: 3, // seconds
          position: 'br'
        });
        break;
      case constants.TWILIO_CALL_PENDING_ACCEPT:
        const message: any = <CallerName name={callDetails.from!} />;
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_INCOMING_CALL_TITLE'),
          message: message,
          level: 'success',
          dismissible: false,
          children: (
            <div className="notification-telephony">
              <button className="form-btn-bg-green" onClick={this.onAcceptCall}>
                {localization.t('ACCEPT')}
              </button>
              <button className="form-btn-bg-red" onClick={this.onRejectCall}>
                {localization.t('REJECT')}
              </button>
            </div>
          ),
          autoDismiss: 0, // Infinity
          uid: CURRENT_CALL_UID,
          position: 'br'
        });
        break;
      case constants.TWILIO_CALL_CANCELED:
        this.notificationSystem.removeNotification(CURRENT_CALL_UID);
        break;
      case constants.TWILIO_CALL_ERROR:
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_ERROR_IN_CALL_TITLE'),
          message: localization.t(state.errorMsg),
          level: 'error',
          autoDismiss: 10, // seconds
          position: 'br'
        });
        break;
      case constants.TWILIO_CALL_BUSY:
        this.notificationSystem.addNotification({
          title: localization.t('TWILIO_INFO_CALL_BUSY'),
          children: (
            <audio preload="auto" autoPlay id={constants.ID_AUDIO_CALL_BUSY}>
              <source src={busySound} type="audio/mpeg" />
            </audio>
          ),
          message: `${localization.t('TWILIO_INFO_CALL_BUSY')} ${localization.t('WITH')} ${callDetails.destination
            }. ${localization.t('CALL_DURATION')}: ${callDetails.elapsedTime} ${localization.t(
              'SECONDS'
            )}`,
          level: 'info',
          autoDismiss: 3, // seconds
          position: 'br'
        });
        break;
    }
  };

  render() {
    return (
      <NotificationSystem
        ref={(node: NotificationSystem.System) => (this.notificationSystem = node)}
      />
    );
  }
}
