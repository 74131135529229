import React from 'react';

export default class IconExternal extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-external"
        x="0px"
        y="0px"
        viewBox="0 0 70 70"
      >
        <path
          d={`M70,28.5L70,28.5c-0.1,0.8-0.4,1.6-1,2.2c-1.2,1.2-3.2,1.2-4.4,0c0,0-0.1-0.1-0.1-0.1l0,0l-8.7-8.7L33.5,44.2
        c-2.2,2.2-5.7,2.2-7.9,0s-2.2-5.7,0-7.9L47.8,14l-8.5-8.5l0,0c0,0-0.1-0.1-0.1-0.1c-1.2-1.2-1.2-3.2,0-4.4c0.7-0.7,1.7-1,2.7-0.9
        c0,0,0.1,0,0.1,0l21.3,0.2c0.5-0.1,1-0.1,1.5,0l0.3,0c0.3,0,0.6,0,0.9,0c0.9,0.1,1.8,0.4,2.5,1.1l0,0l0,0c0,0,0.1,0.1,0.1,0.1
        c0.9,0.9,1.3,2.1,1.2,3.2h0.1L70,28.5z M8.8,61.3h43.7V35.8l8.7,8.7v17.2c0,4.5-3.7,8.2-8.2,8.2H8.2C3.7,70,0,66.3,0,61.8V17
        c0-4.5,3.7-8.2,8.2-8.2h17.2l8.7,8.7H8.8V61.3z`}
        />
      </svg>
    );
  }
}
